import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Datatable from "../../../../components/ui/tables/Datatable";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";

const WebsiteTechnologyDeviceTrafficType = ({ viewId }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Website"
  let pagePlatform = "Technology"
  let pagePrimaryTab = "Device Traffic Type"

  const authCtx = useContext(AuthContext);

  const { setFullPageExport, setExportPageName, exportSummary, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);

  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableDataError, setTableDataError] = useState(null);

  const [apiData, setApiData] = useState({});
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [apiDataLoading, setApiDataLoading] = useState(false);
  const [apiDataError, setApiDataError] = useState(null);

  // TO FETCH DATA FROM API
  const fetchTableDataHandler = async () => {
    setTableDataLoaded(false);
    setTableDataLoading(true);
    setTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/website/ga-desktop-channel/", config);
      setTableData(res?.data?.data ?? []);
      setTableHeaders(res?.data?.label ?? []);

      setTableDataLoaded(true);
      setTableDataLoading(false);
      setTableDataError(null);
    } catch (error) {
      setTableDataLoaded(true);
      setTableDataLoading(false);
      setTableDataError(error.code);
    }
  };

  // TO FETCH DATA FROM API
  const fetchMetricsListHandler = async () => {
    setApiDataLoaded(false);
    setApiDataLoading(true);
    setApiDataError(null);
    setApiData([]);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("website/ga-mobile-brand/", config);

      setApiData(res?.data ?? []);
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError(null);
    } catch (error) {
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError(error.code);
    }
  };

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    viewId === undefined ?
      (
        <NoDataAvailableLoader chartType="cardChartType" />
      ) :
      viewId.length === 0 ?
        (
          <NoDataAvailableLoader chartType="cardChartType" />
        ) :
        (
          fetchTableDataHandler()
        )
  }, [BRAND_ID, viewId, selectedDates]);

  useEffect(() => {
    viewId === undefined ?
      (
        <NoDataAvailableLoader chartType="tableChartType" />
      ) :
      viewId.length === 0 ?
        (
          <NoDataAvailableLoader chartType="tableChartType" />
        ) :
        (
          fetchMetricsListHandler()
        )
  }, [BRAND_ID, viewId, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE DATA
  let summaryTable =
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Summary
            <SectionDefinationsBadge
              title={"Summary"}
              module="website"
              platform="technology"
              section="device traffic"
            />
          </h2>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">

            {viewId === undefined ? (
              <NoDataAvailableLoader chartType="tableChartType" />
            ) : viewId.length === 0 ? (
              <NoDataAvailableLoader chartType="tableChartType" />
            ) : (
              <Loader loaderType="smallTableLoader" />
            )}


          </div>
        </div>
      </div>
    </Wrapper>

  if (tableDataLoaded && !tableDataLoading) {
    if (NoDataAvailableChecker(tableData) || NoDataAvailableChecker(tableHeaders)) {
      summaryTable = <Wrapper>
        <div className="section_card fullpage_section_card grid grid_margin_bottom">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="technology"
                section="device traffic"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <NoDataAvailableLoader chartType="tableChartType" />
            </div>
          </div>
        </div>
      </Wrapper>
    } else {
      summaryTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={"Summary"}
              tablePageSubModule="website"
              tablePagePlatform="technology"
              tablePagePrimaryTab="device traffic"
              tableHeader={tableHeaders}
              tableData={tableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search Devices"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"summary_table"}
                  sectionName="Summary Table"
                  sectionData={tableData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }


  // IF THERE IS SOME ERROR FROM SERVER
  if (tableDataError) {
    summaryTable =
      <Wrapper>
        <div className="section_card fullpage_section_card grid grid_margin_bottom">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="technology"
                section="device traffic"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={tableDataError} />
            </div>
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (tableDataLoading) {
    summaryTable =
      <Wrapper>
        <div className="section_card fullpage_section_card grid grid_margin_bottom">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module="website"
                platform="technology"
                section="device traffic"
              />
            </h2>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              {viewId === undefined ? (
                <NoDataAvailableLoader chartType="tableChartType" />
              ) : viewId.length === 0 ? (
                <NoDataAvailableLoader chartType="tableChartType" />
              ) : (
                <Loader loaderType="smallTableLoader" />
              )}

            </div>
          </div>
        </div>
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // OVERVIEW METRICS CARDS SLIDER

  let overviewData = (
    <Wrapper>
      <div className="grid grid_cols_4 ">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Device Type
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          {viewId === undefined ? (
            <NoDataAvailableLoader chartType="cardChartType" />
          ) : viewId.length === 0 ? (
            <NoDataAvailableLoader chartType="cardChartType" />
          ) : (
            <Loader loaderType="cardLoader" />
          )}
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (apiDataLoaded && !apiDataLoading) {
    if (NoDataAvailableChecker(apiData)) {
      overviewData =
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Device Type
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
    } else {
      overviewData = (
        <Wrapper>
          <ErrorBoundary>
            {apiData.map((data, dataIndex) => (
              <Slider key={dataIndex} sliderTitle={data.title}>
                {data.sub_data.map((data, index) => (
                  <SwiperSlide key={index}>
                    <Card
                      module="website"
                      platform="technology"
                      section="device_traffic_type"
                      cardTitle={data.label}
                      currentValue={data.currentValue}
                      rowOneTitle={"Previous"}
                      previousValue={data.previousValue}
                      numberVariant={"number"}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            ))}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (apiDataError) {
    overviewData = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Device Type
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader chartType="cardChartType" error={apiDataError} />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (apiDataLoading) {
    overviewData = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Device Type
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            {viewId === undefined ? (
              <NoDataAvailableLoader chartType="cardChartType" />
            ) : viewId.length === 0 ? (
              <NoDataAvailableLoader chartType="cardChartType" />
            ) : (
              <Loader loaderType="cardLoader" />
            )}
          </div>
        </div>
      </Wrapper>
    );
  }

  var allData = { "Summary": exportSummary, "Summary Table": tableData, 'WebsiteCards_Device Type': apiData };

  useEffect(() => {
    setExportPageName('Website Technology Device Traffic Type')
    setFullPageExport(allData);
  }, [tableData, apiData]);

  useEffect(() => {
    if (
      (tableDataError !== null ? true : tableDataLoaded && (tableDataLoading === false)) &&
      (apiDataError !== null ? true : apiDataLoaded && (apiDataLoading === false))) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [

    tableDataError, tableDataLoaded, tableDataLoading,
    apiDataError, apiDataLoaded, apiDataLoading,
  ])


  return (
    <Wrapper>
      <div className="grid_margin_bottom">
        {summaryTable}
      </div>
      {overviewData}
    </Wrapper>
  );
};

export default WebsiteTechnologyDeviceTrafficType;
