import { useContext, useEffect, useState } from "react";

// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import { useSelector } from "react-redux";
import Loader from "../../../../components/ui/loaders/Loader";
import Datatable from "../../../../components/ui/tables/Datatable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

const CampaignList = () => {
    const authCtx = useContext(AuthContext);

    const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

    // SELECTED BRANDS FROM DROPDOWN
    const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

    let BRAND_ID = null;
    if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
        BRAND_ID = null
    } else {
        BRAND_ID = globalSelectedBrandIds.join(',')
    }

    // currentDate / endDate / prevCurrentDate / prevEndDate
    const selectedDates = useSelector((state) => state.date)

    // METRIC CARDS
    const [campaignList, setCampaignList] = useState([]);
    const [isCampaignListLoading, setCampaignListIsLoading] = useState(false);
    const [isCampaignListLoaded, setCampaignListIsLoaded] = useState(false);
    const [campaignListerror, setCampaignListError] = useState(null);

    const campaignListTableHeaders = [
        { name: "Campaign Name", field: "platform_campaign_name" },
        { name: "Brand Name", field: "brand_name" },
        { name: "Campaign Bucket", field: "bucket" },
        { name: "Objective", field: "objective" },
        { name: "Platform", field: "platform" },
        { name: "Start Date", field: "start_date" },
        { name: "End Date", field: "end_date" }
    ];

    // TO FETCH METRIC CARDS FROM API
    const fetchCampaignList = async () => {
        setCampaignListIsLoading(true);
        setCampaignListIsLoaded(false);
        setCampaignListError(null);

        const config = {
            params: {
                brand_id: BRAND_ID,
                start_date: selectedDates.startDate,
                end_date: selectedDates.endDate,
                previous_start_date: selectedDates.prevStartDate,
                previous_end_date: selectedDates.prevEndDate,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
        };

        try {
            const res = await axios.get(
                "performance/google/campaign-list/", config);

            setCampaignList(res?.data ?? []);
            setCampaignListIsLoading(false);
            setCampaignListIsLoaded(true);
            setCampaignListError(null);
        } catch (error) {
            setCampaignList([]);
            setCampaignListIsLoading(false);
            setCampaignListIsLoaded(false);
            setCampaignListError(error.code);
        }
    };

    let campaignListTable =
        <Wrapper>
            <div className="grid grid_cols_4 fullpage_section_card section_card">
                <div className="grid col_span_4">
                    <div className="section_card_header">
                        <h2 className="section_card_title">Summary</h2>
                    </div>
                </div>
                <div className="grid col_span_4">
                    <div className="section_card_body">
                        <Loader loaderType="smallTableLoader" />
                    </div>
                </div>
            </div>
        </Wrapper>

    // IF DATA IS LOADED
    if (isCampaignListLoaded && !isCampaignListLoading) {
        if (NoDataAvailableChecker(campaignList)) {
            campaignListTable = <Wrapper>
                <div className="grid grid_cols_4 fullpage_section_card section_card">
                    <div className="grid col_span_4">
                        <div className="section_card_header">
                            <h2 className="section_card_title">Summary</h2>
                        </div>
                    </div>
                    <div className="grid col_span_4">
                        <div className="section_card_body">
                            <NoDataAvailableLoader chartType="tableChartType" />
                        </div>
                    </div>
                </div>
            </Wrapper>
        } else {
            campaignListTable = (
                <ErrorBoundary chartType="tableChartType">
                    <Datatable
                        tableTitle={"Summary"}
                        tableHeader={campaignListTableHeaders}
                        tableData={campaignList}
                        tableLength={10}
                        isFooterShow={true}
                        searchPlaceHolder={"Search Campaign"}
                        tableID={"summary_table"}
                        tableExportDropdown={
                            <ExportDropdown
                                sectionId={"summary_table"}
                                sectionName={"Summary Table"}
                                sectionData={campaignList}
                            />
                        }
                        isCreatableDropdown={true}
                    />
                </ErrorBoundary>
            );
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (campaignListerror) {
        campaignListTable =
            <Wrapper>
                <div className="grid grid_cols_4 fullpage_section_card section_card">
                    <div className="grid col_span_4">
                        <div className="section_card_header">
                            <h2 className="section_card_title">Summary</h2>
                        </div>
                    </div>
                    <div className="grid col_span_4">
                        <div className="section_card_body">
                            <ServerErrorsLoader chartType="tableChartType" error={campaignListerror} />
                        </div>
                    </div>
                </div>
            </Wrapper>
    }

    // IF DATA LOADING
    if (isCampaignListLoading) {
        campaignListTable =
            <Wrapper>
                <div className="grid grid_cols_4 fullpage_section_card section_card">
                    <div className="grid col_span_4">
                        <div className="section_card_header">
                            <h2 className="section_card_title">Summary</h2>
                        </div>
                    </div>
                    <div className="grid col_span_4">
                        <div className="section_card_body">
                            <Loader loaderType="smallTableLoader" />
                        </div>
                    </div>
                </div>
            </Wrapper>
    }

    // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
    useEffect(() => {
        fetchCampaignList();
    }, [selectedDates]);

    useEffect(() => {
        setFullPageExport(campaignList)
    }, [campaignList]);

    useEffect(() => {
        if (campaignListerror !== null ? true : isCampaignListLoaded && (isCampaignListLoading === false)) {
            setIsExportDisabled(false)
        } else {
            setIsExportDisabled(true)
        }
    }, [campaignListerror, isCampaignListLoaded, isCampaignListLoading])



    return (
        <Wrapper>
            <div className="grid_margin_bottom">
                {campaignListTable}
            </div>
        </Wrapper>
    )
}
export default CampaignList;