import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import Datatable from "../../../../components/ui/tables/Datatable";
import BarChart from "../../../../components/ui/charts/BarChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { COKE_COMPANY_ID } from "../../../../constants/constants";

const CampaignTwitterOverall = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Twitter"
  let pagePrimaryTab = "Overall"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? '';

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }
  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // // CAMPAIGN OBJECTIVE WISE DISTRIBUTION
  // const [
  //   campaignObjectivewiseDistribution,
  //   setCampaignObjectivewiseDistribution,
  // ] = useState([]);
  // const [
  //   campaignObjectivewiseDistributionDataLabels,
  //   setCampaignObjectivewiseDistributionDataLabels,
  // ] = useState([]);
  // const [
  //   isCampaignObjectivewiseDistributionIsLoading,
  //   setCampaignObjectivewiseDistributionIsLoading,
  // ] = useState(false);
  // const [
  //   isCampaignObjectivewiseDistributionIsLoaded,
  //   setCampaignObjectivewiseDistributionIsLoaded,
  // ] = useState(false);
  // const [
  //   campaignObjectivewiseDistributionerror,
  //   setCampaignObjectivewiseDistributionError,
  // ] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "engagements", label: "Engagements" },
    { value: "spends", label: "Spends" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState(midSectionDropdonwOptions[0]);


  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Twitter", "Overall", null, "Performance By KPI", e.label)

  };

  /*   // DROPDOWN DEFAULT STATE
    const [
      performanceSourceDropdownOption,
      setPerformanceSourceDropdownDropdownOption,
    ] = useState({ value: "All on twitter", label: "All on twitter" });
  
    // DROPDOWN OPTIONS
    const performanceSourceDropdonwOptions = [
      { value: "All on twitter", label: "All on twitter" },
      { value: "publisher network", label: "publisher Network" },
    ];
  
    // DROPDOWN OPTIONS SELECTION HANDLER
    const performanceSourceDropdownSelectionHandler = (e) => {
      if (e === null) {
        setPerformanceSourceDropdownDropdownOption("");
      } else {
        setPerformanceSourceDropdownDropdownOption(e);
      }
    }; */

  // // PERFORMANCE
  // const [performance, setPerformance] = useState([]);
  // const [performanceAllDropdownsData, setPerformanceAllDropdownsData] =
  //   useState([]);
  // const [performanceDataLabels, setPerformanceDataLabels] = useState([]);
  // const [
  //   performanceAllDropdownsDataLabels,
  //   setPerformanceAllDropdownsDataLabels,
  // ] = useState([]);
  // const [isPerformanceLoading, setPerformanceIsLoading] = useState(false);
  // const [isPerformanceLoaded, setPerformanceIsLoaded] = useState(false);
  // const [performanceerror, setPerformanceError] = useState(null);

  // PERFORMANCE BY SOURCE DETAILS
  /*   const [performanceDetails, setPerformanceDetails] = useState([]);
    const [isPerformanceDetailsLoading, setPerformanceDetailsIsLoading] =
      useState(false);
    const [isPerformanceDetailsLoaded, setPerformanceDetailsIsLoaded] =
      useState(false);
    const [performanceDetailserror, setPerformanceDetailsError] = useState(null); */

  // DEVICE DISTRIBUTION
  const [deviceDistribution, setDeviceDistribution] = useState([]);
  const [
    deviceDistributionAllDropdownsData,
    setDeviceDistributionAllDropdownsData,
  ] = useState([]);
  const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] =
    useState(false);
  const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] =
    useState(false);
  const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

  // AGE COUNT
  const [ageCount, setAgeCount] = useState([]);
  const [ageCountAllDropdownsData, setAgeCountAllDropdownsData] = useState([]);
  const [ageCountLabels, setAgeCountLabels] = useState([]);
  const [ageCountAllDropdownsDataLabels, setAgeCountAllDropdownsDataLabels] =
    useState([]);
  const [isAgeCountLoading, setAgeCountIsLoading] = useState(false);
  const [isAgeCountLoaded, setAgeCountIsLoaded] = useState(false);
  const [ageCounterror, setAgeCountError] = useState(null);

  /*  // GENDER COUNT
   const [genderCount, setGenderCount] = useState([]);
   const [genderCountAllDropdownsData, setGenderCountAllDropdownsData] =
     useState([]);
   const [genderCountLabels, setGenderCountLabels] = useState([]);
   const [
     genderCountAllDropdownsDataLabels,
     setGenderCountAllDropdownsDataLabels,
   ] = useState([]);
   const [isGenderCountLoading, setGenderCountIsLoading] = useState(false);
   const [isGenderCountLoaded, setGenderCountIsLoaded] = useState(false);
   const [genderCounterror, setGenderCountError] = useState(null); */

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // // CITY
  // const [cityData, setCityData] = useState([]);
  // const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
  // const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  // const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  // const [cityDataerror, setCityDataError] = useState(null);

  // const CityTableDataHeaders = [
  //   { name: "City", field: "name" },
  //   { name: selectedMidSectionDropdownOption.label, field: "value" },
  //   { name: "Change", field: "percentage_growth" },
  // ];

  // MALE COUNT
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [totalUnknownCount, setTotalUnknownCount] = useState(0);
  const [
    totalUnknownCountAllDropdownsData,
    setTotalUnknownCountAllDropdownsData,
  ] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] =
    useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  // TABLE HEADERS FOR COKE
  let cokeSummaryTableHeaders = [
    { name: "Name", field: "name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engagement_rate" }
  ]

  let defaultTableHeaders = [
    { name: "Name", field: "name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "Engagement Rate", field: "engagement_rate" }
  ]

  const campaignSummaryTableHeaders = companyID === COKE_COMPANY_ID ? cokeSummaryTableHeaders : defaultTableHeaders;

  // DEVICE DISTRIBUTION DROPDOWN


  // DEVICE DISTRIBUTION DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad Group" },
  ];

  // DEVICE DISTRIBUTION DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState(campaignSummaryOptions[0]);

  // DEVICE DISTRIBUTION DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Twitter", "Overall", null, "Summary", e.label)

  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.ad ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.ad_group ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.campaign ?? []);
    } else {
      setCampaignSummary([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchDeviceDistributionHandler();
    fetchAudienceDistributionAgeHandler();
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    fetchObjectiveWiseDistributionDonutHandler();
    fetchCampaignSummaryHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {

      setDeviceDistribution(deviceDistributionAllDropdownsData?.clicks ?? []);
      setAgeCount(ageCountAllDropdownsData?.clicks ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.clicks ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.clicks ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.clicks ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.clicks ?? []);
      setCountryData(countryAllDropdownsData?.clicks ?? []);

    } else if (selectedMidSectionDropdownOption.value === "conversions") {

      setDeviceDistribution(deviceDistributionAllDropdownsData?.conversions ?? []);
      setAgeCount(ageCountAllDropdownsData?.conversions ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.conversions ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.conversions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.conversions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.conversions ?? []);
      setCountryData(countryAllDropdownsData?.conversions ?? []);

    } else if (selectedMidSectionDropdownOption.value === "engagements") {

      setDeviceDistribution(deviceDistributionAllDropdownsData?.engagement ?? []);
      setAgeCount(ageCountAllDropdownsData?.engagement ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.engagement ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.engagements ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.engagements ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.engagements ?? []);
      setCountryData(countryAllDropdownsData?.engagements ?? []);

    } else if (selectedMidSectionDropdownOption.value === "impressions") {

      setDeviceDistribution(deviceDistributionAllDropdownsData?.impressions ?? []);
      setAgeCount(ageCountAllDropdownsData?.impressions ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.impressions ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.impressions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.impressions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.impressions ?? []);
      setCountryData(countryAllDropdownsData?.impressions ?? []);

    } else if (selectedMidSectionDropdownOption.value === "spends") {

      setDeviceDistribution(deviceDistributionAllDropdownsData?.spends ?? []);
      setAgeCount(ageCountAllDropdownsData?.spends ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.spends ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.spends ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.spends ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.spends ?? []);
      setCountryData(countryAllDropdownsData?.spends ?? []);

    } else {

      setDeviceDistribution([]);
      setAgeCount([]);
      setAgeCountLabels([]);
      setTotalMaleCount(0);
      setTotalFemaleCount(0);
      setTotalUnknownCount(0);
      setCountryData([]);

    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/twitter/metric-card/", config);

      setMetricCards(res?.data?.data ?? []);

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      </Wrapper>;
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="twitter"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  /*  const fetchCampaignObjectivewiseDistributionHandler = async () => {
     setCampaignObjectivewiseDistributionIsLoading(true);
     setCampaignObjectivewiseDistributionIsLoaded(false);
     setCampaignObjectivewiseDistributionError(null);
 
     const config = {
       params: {
         brand_id: BRAND_ID,
         start_date: selectedDates.startDate,
         end_date: selectedDates.endDate,
         previous_start_date: selectedDates.prevStartDate,
         previous_end_date: selectedDates.prevEndDate,
         objective: selectedObjectives,
         platform_campaign:selectedIdandPlatform,
         platform: selectedPlatform,
         campaign: selectedCampaigns,
           campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
       },
       headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer " + authCtx.token,
       },
     };
 
     try {
       const res = await axios.get(
         "performance/twitter/campaign-objective/",
         config
       );
 
 
       setCampaignObjectivewiseDistribution(
         res.data.length === 0 || res.data === undefined ? [] :
         res.data[1].length === 0 || res.data[1] === undefined ? [] : 
         res.data[1].impressions.data.length === 0 || res.data[1].impressions.data === undefined ? [] : 
         res.data[1].impressions.data
       )
     
    
      setCampaignObjectivewiseDistributionDataLabels(
       res.data.length === 0 || res.data === undefined ? [] :
       res.data[1].length === 0 || res.data[1] === undefined ? [] : 
       res.data[1].impressions.label.length === 0 || res.data[1].impressions.label === undefined ? [] : 
       res.data[1].impressions.label
     )
     
 
 
       // setCampaignObjectivewiseDistribution(TwitterCampaignObjectivewiseDistributionSampleData);
       // setCampaignObjectivewiseDistributionDataLabels(TwitterCampaignObjectivewiseDistributionSampleDataLabels);
       setCampaignObjectivewiseDistributionIsLoading(false);
       setCampaignObjectivewiseDistributionIsLoaded(true);
       setCampaignObjectivewiseDistributionError(null);
     } catch (error) {
       setCampaignObjectivewiseDistribution([]);
       setCampaignObjectivewiseDistributionDataLabels([]);
       setCampaignObjectivewiseDistributionIsLoading(false);
       setCampaignObjectivewiseDistributionIsLoaded(false);
       setCampaignObjectivewiseDistributionError(error.code);
     }
   };
 
   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
   let campaignObjectivewiseDistributionPie = (
     <Wrapper> 
     <Loader loaderType="pieChartLoader" />
     </Wrapper>
   );
 
   // IF DATA IS LOADED
   if (isCampaignObjectivewiseDistributionIsLoaded && !isCampaignObjectivewiseDistributionIsLoading) {
     if (
       NoDataAvailableChecker(campaignObjectivewiseDistribution) ||
       NoDataAvailableChecker(campaignObjectivewiseDistributionDataLabels)
     ) {
       campaignObjectivewiseDistributionPie = (
         <Wrapper> 
         <NoDataAvailableLoader chartType="pieChartType" />
         </Wrapper>
       );
     } else {
       campaignObjectivewiseDistributionPie = (
         <Wrapper>
           <ErrorBoundary chartType="pieChartType">
             <PieofaPieChart
               chartId="content_analysis"
               chartClass="section_card_chart"
               chartData={campaignObjectivewiseDistribution}
               chartLabels={campaignObjectivewiseDistributionDataLabels}
             />
           </ErrorBoundary>
         </Wrapper>
       );
     }
   }
 
   // IF THERE IS SOME ERROR FROM SERVER
   if (campaignObjectivewiseDistributionerror) {
     campaignObjectivewiseDistributionPie = (
       <Wrapper> 
       <ServerErrorsLoader
         chartType="pieChartType"
         error={campaignObjectivewiseDistributionerror}
       />
       </Wrapper>
     );
   }
 
   // IF DATA LOADING
   if (isCampaignObjectivewiseDistributionIsLoading) {
     campaignObjectivewiseDistributionPie =  <Wrapper> <Loader loaderType="pieChartLoader" /></Wrapper>;
   }
  */


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE

  // TO FETCH DATA FROM API
  /*  const fetchPerformanceHandler = async () => {
     setPerformanceIsLoading(true);
     setPerformanceIsLoaded(false);
     setPerformanceError(null);
 
     const config = {
       params: {
         brand_id: BRAND_ID,
         start_date: selectedDates.startDate,
         end_date: selectedDates.endDate,
         previous_start_date: selectedDates.prevStartDate,
         previous_end_date: selectedDates.prevEndDate,
         objective: selectedObjectives,
         platform_campaign:selectedIdandPlatform,
         platform: selectedPlatform,
         campaign: selectedCampaigns,
           campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
       },
       headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer " + authCtx.token,
       },
     };
 
     try {
       const res = await axios.get(
         "performance/twitter/performance-graph/",
         config
       );
       setPerformance(
         res.data.length === 0 || res.data === undefined ? [] :
         res.data.graphdata.length === 0 || res.data.graphdata === undefined ? [] : 
         res.data.graphdata.data.length === 0 || res.data.graphdata.data === undefined ? [] : 
         res.data.graphdata.data.impressions.length === 0 || res.data.graphdata.data.impressions === undefined ? [] : 
         res.data.graphdata.data.impressions
       )
     
 
 
       setPerformance(
         res.data.length === 0 || res.data === undefined ? [] :
         res.data.graphdata.length === 0 || res.data.graphdata === undefined ? [] : 
         res.data.graphdata.label.length === 0 || res.data.graphdata.label === undefined ? [] : 
         res.data.graphdata.label.impressions.length === 0 || res.data.graphdata.label.impressions === undefined ? [] : 
         res.data.graphdata.label.impressions
       )
     
      setPerformanceAllDropdownsData(
       res.data.length === 0 || res.data === undefined ? [] :
       res.data.graphdata.length === 0 || res.data.graphdata === undefined ? [] : 
       res.data.graphdata.data.length === 0 || res.data.graphdata.data === undefined ? [] :
       res.data.graphdata.data
     )
      
 
       setPerformanceAllDropdownsDataLabels(
         res.data.length === 0 || res.data === undefined ? [] :
         res.data.graphdata.length === 0 || res.data.graphdata === undefined ? [] : 
         res.data.graphdata.label.length === 0 || res.data.graphdata.label === undefined ? [] :
         res.data.graphdata.label
       )
    
       // setPerformanceAllDropdownsData(TwitterPerformanceSampleData);
       // setPerformanceAllDropdownsDataLabels(TwitterPerformanceSampleDataLabels);
       setPerformanceIsLoading(false);
       setPerformanceIsLoaded(true);
       setPerformanceError(null);
     } catch (error) {
       setPerformanceAllDropdownsData([]);
       setPerformanceAllDropdownsDataLabels([]);
       setPerformanceIsLoading(false);
       setPerformanceIsLoaded(false);
       setPerformanceError(error.code);
     }
   };
 
   // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
   let performanceChart =  <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
 
   // IF DATA IS LOADED
   if (isPerformanceLoaded && !isPerformanceLoading) {
     if (
       NoDataAvailableChecker(performance) ||
       NoDataAvailableChecker(performanceDataLabels)
     ) {
       performanceChart =  <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>;
     } else {
       performanceChart = (
         <Wrapper>
           <ErrorBoundary chartType="lineChartType">
             <LineChart
               chartId="performance_by_source"
               chartClass="section_card_chart"
               chartData={performance}
               chartLabels={performanceDataLabels}
               showLegend={true}
               showLabels={true}
               platform="Twitter"
             />
           </ErrorBoundary>
         </Wrapper>
       );
     }
   }
 
   // IF THERE IS SOME ERROR FROM SERVER
   if (performanceerror) {
     performanceChart = (
       <Wrapper> 
       <ServerErrorsLoader chartType="lineChartType" error={performanceerror} />
       </Wrapper>
     );
   }
 
   // IF DATA LOADING
   if (isPerformanceLoading) {
     performanceChart =  <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>; 
   }
 */
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE DETAILS

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  /* let performanceDetailsData = (
    <Wrapper> <Loader loaderType="cardLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (NoDataAvailableChecker(performanceDetails)) {
      performanceDetailsData = (
        <Wrapper>  <NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
      );
    } else {
      performanceDetailsData = (
        <Wrapper>
          <table className="table table_striped table_bordered">
            <tbody>
              {performanceDetails.map((details, postImpressionIndex) => {
                return (
                  <Wrapper key={postImpressionIndex}>
                    <tr>
                      <td>{details.label}</td>
                      <td className="bold">
                        <NumberFormatter number={details.value} />
                      </td>
                    </tr>
                  </Wrapper>
                );
              })}
            </tbody>
          </table>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceDetailsData = (
      <Wrapper>  <ServerErrorsLoader chartType="cardChartType" error={performanceerror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceDetailsData =  <Wrapper> <Loader loaderType="cardLoader" /></Wrapper>;
  }
 */
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DEVICE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchDeviceDistributionHandler = async () => {
    setDeviceDistributionIsLoading(true);
    setDeviceDistributionIsLoaded(false);
    setDeviceDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/twitter/device-wise/", config);
      setDeviceDistribution(res?.data?.data?.impressions ?? [])
      setDeviceDistributionAllDropdownsData(res?.data?.data ?? [])

      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(true);
      setDeviceDistributionError(null);
    } catch (error) {
      setDeviceDistributionAllDropdownsData([]);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(false);
      setDeviceDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let deviceDistributionChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
    if (NoDataAvailableChecker(deviceDistribution)) {
      deviceDistributionChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      deviceDistributionChart = (
        <Wrapper>
          <DonutPieChart
            chartId={"device_distribution"}
            chartClass={"section_card_chart"}
            chartData={deviceDistribution}
            showLegend={true}
            showLabels={true}
            showVerticalLabels={true}
          />
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceDistributionerror) {
    deviceDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={deviceDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceDistributionLoading) {
    deviceDistributionChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: AGE
  const fetchAudienceDistributionAgeHandler = async () => {
    setAgeCountIsLoading(true);
    setAgeCountIsLoaded(false);
    setAgeCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/twitter/age/", config);

      setAgeCount(res?.data?.data?.impressions ?? []);
      setAgeCountLabels(res?.data?.label?.impressions ?? []);

      setAgeCountAllDropdownsData(res?.data?.data ?? []);
      setAgeCountAllDropdownsDataLabels(res?.data?.label ?? []);

      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(true);
      setAgeCountError(null);
    } catch (error) {
      setAgeCountAllDropdownsData([]);
      setAgeCountAllDropdownsDataLabels([]);
      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(false);
      setAgeCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: AGE
  let ageAudienceDistribution = (
    <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAgeCountLoaded && !isAgeCountLoading) {
    if (
      NoDataAvailableChecker(ageCount) ||
      NoDataAvailableChecker(ageCountLabels)
    ) {
      ageAudienceDistribution = (
        <Wrapper>  <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      ageAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary>
            <BarChart
              chartId="audience_age"
              chartClass="section_card_chart"
              chartData={ageCount}
              chartLabels={ageCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (ageCounterror) {
    ageAudienceDistribution = (
      <Wrapper> <ServerErrorsLoader chartType="barChartType" error={ageCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAgeCountLoading) {
    ageAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/twitter/gender/", config);

      setTotalMaleCount(res?.data?.male_count?.impressions ?? [])
      setTotalFemaleCount(res?.data?.female_count?.impressions ?? [])

      setTotalUnknownCount(res?.data?.unknown?.impressions ?? [])

      setTotalMaleCountAllDropdownsData(res?.data?.male_count ?? [])

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setTotalFemaleCountAllDropdownsData(res?.data?.female_count ?? [])

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);


      setTotalUnknownCountAllDropdownsData(res?.data?.unknown ?? [])

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      setTotalMaleCount(0);
      setTotalFemaleCount(0);
      setTotalUnknownCount(0);

      setTotalMaleCountAllDropdownsData([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setTotalFemaleCountAllDropdownsData([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setTotalUnknownCountAllDropdownsData([]);
      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistributionIcon = (
    <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>
  );

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (NoDataAvailableChecker(totalMaleCount)) {
      maleAudienceDistributionIcon = (
        <Wrapper> <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalMaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Male</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistributionIcon = (
      <Wrapper> <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistributionIcon = (
    <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (NoDataAvailableChecker(totalFemaleCount)) {
      femaleAudienceDistributionIcon = (
        <Wrapper>  <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalFemaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Female</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistributionIcon = (
      <Wrapper>  <ServerErrorsLoader
        chartType="genderChartType"
        error={femaleCounterror}
      />
      </Wrapper>
    );

    // unknownAudienceDistributionIcon = (
    //   <ServerErrorsLoader error={femaleCounterror} />
    // );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // AUDIENCE DISTRIBUTION: UNKNOWN
  let unknownAudienceDistributionIcon = (
    <Wrapper>  <Loader loaderType="genderLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (NoDataAvailableChecker(totalUnknownCount)) {
      unknownAudienceDistributionIcon = (
        <Wrapper>  <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      unknownAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalUnknownCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Others</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/twitter/country-distribution/",
        config
      );

      setCountryData(res?.data?.data?.impressions ?? []);
      setCountryAllDropdownsData(res?.data?.data ?? []);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: COUNTRY
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By Country"
              hideBoxShadow={true}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  /* // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign:selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/poll/", config);

      setCityData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data.impressions === undefined || res.data.data.impressions.length === 0 ? [] :
              res.data.data.impressions
      );

      setCityAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityAllDropdownsData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let cityMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  } */

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/twitter/performance-table/",
        config
      );
      setCampaignSummary(res?.data?.campaign ?? [])
      setCampaignSummaryAllDropdownsData(res?.data ?? [])

      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    if (NoDataAvailableChecker(campaignSummary) || NoDataAvailableChecker(campaignSummaryTableHeaders)) {
      campaignSummaryTable =
        (
          <Wrapper>
            <div className="grid grid_cols_4 fullpage_section_card section_card">
              <div className="grid col_span_4">
                <div className="section_card_header">
                  <h2 className="section_card_title">Summary
                    <SectionDefinationsBadge
                      title={"Summary"}
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pagePrimaryTab}
                    />
                  </h2>
                </div>
              </div>
              <div className="grid col_span_4">
                <div className="section_card_body">
                  <NoDataAvailableLoader chartType="tableChartType" />
                </div>
              </div>
            </div>
          </Wrapper>
        )
    } else {
      campaignSummaryTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="Summary"
              tablePagePlatform={pagePlatform}
              tablePageSubModule={pageSubModule}
              tablePagePrimaryTab={pagePrimaryTab}
              tablePlatform="Twitter"
              tableHeader={campaignSummaryTableHeaders}
              tableData={campaignSummary}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search Campaign"}
              tableDropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={campaignSummaryOptions}
                  className="form_dropdown section_dropdown"
                  value={selectedCampaignSummaryDropdownOption}
                  selectedOptions={selectedCampaignSummaryDropdownOption}
                  setStatedropdown={campaignSummaryDropdownSelectionHandler}
                />
              }
              tableID={"search_campaign_section"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"search_campaign_section"}
                  sectionName={`CampaignTableDynaLabels_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`}
                  sectionData={campaignSummary}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );

    }

  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable =
      (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">Summary
                  <SectionDefinationsBadge
                    title={"Summary"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <Loader loaderType="smallTableLoader" />
              </div>
            </div>
          </div>
        </Wrapper>
      )
  }

  // OBJECTIVE WISE DISTRIBUTION DONUT CHART
  const [objectiveWiseDistributionDonut, setObjectiveWiseDistributionDonut] =
    useState([]);

  const [parentDonutSliceToggle, setParentDonutSliceToggle] = useState(null);

  const [
    objectiveWiseDistributionDonutLoading,
    setObjectiveWiseDistributionDonutLoading,
  ] = useState([]);
  const [
    objectiveWiseDistributionDonutLoaded,
    setObjectiveWiseDistributionDonutLoaded,
  ] = useState([]);
  const [
    objectiveWiseDistributionDonutError,
    setObjectiveWiseDistributionDonutError,
  ] = useState([]);

  // FETCH OBJECTIVE WISE DATA

  const fetchObjectiveWiseDistributionDonutHandler = async () => {
    setObjectiveWiseDistributionDonutLoading(true);
    setObjectiveWiseDistributionDonutLoaded(false);
    setObjectiveWiseDistributionDonutError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/twitter/campaign-objective/",
        config
      );
      setObjectiveWiseDistributionDonut(res?.data ?? []);

      setObjectiveWiseDistributionDonut(res?.data ?? []);

      setObjectiveWiseDistributionDonutLoading(false);
      setObjectiveWiseDistributionDonutLoaded(true);
      setObjectiveWiseDistributionDonutError(null);
    } catch (error) {
      setObjectiveWiseDistributionDonut([]);
      setObjectiveWiseDistributionDonutLoading(false);
      setObjectiveWiseDistributionDonutLoaded(false);
      setObjectiveWiseDistributionDonutError(error.code);
    }
  };

  let objectiveWiseDistributionDonutChart = (
    <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (
    objectiveWiseDistributionDonutLoaded &&
    !objectiveWiseDistributionDonutLoading
  ) {
    if (NoDataAvailableChecker(objectiveWiseDistributionDonut)) {
      objectiveWiseDistributionDonutChart = (
        <Wrapper> <NoDataAvailableLoader chartType="donutChartType" /></Wrapper>
      );
    } else {
      objectiveWiseDistributionDonutChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={objectiveWiseDistributionDonut}
              chartId="CampaignDonut"
              onSliceClick={(data) => setParentDonutSliceToggle(data.object_key)}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (objectiveWiseDistributionDonutError) {
    objectiveWiseDistributionDonutChart = (
      <Wrapper>  <ServerErrorsLoader
        chartType="donutChartType"
        error={objectiveWiseDistributionDonutError}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (objectiveWiseDistributionDonutLoading) {
    objectiveWiseDistributionDonutChart = <Wrapper> <Loader loaderType="donutChartLoader" /></Wrapper>;
  }

  useEffect(() => {
    setParentDonutSliceToggle(null)
  }, [selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  useEffect(() => {
    objectiveWiseDistributionLineChartHandler();
  }, [BRAND_ID, parentDonutSliceToggle, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);


  //  OBJECTIVE WISE DISTRIBUTION LINE CHART
  const [objectiveWiseDistributionLineChart, setObjectiveWiseDistributionLineChart] = useState(
    []
  );
  const [objectiveWiseDistributionLineChartLabel, setObjectiveWiseDistributionLineChartLabel] =
    useState([]);
  const [
    objectiveWiseDistributionLineChartLoading,
    setObjectiveWiseDistributionLineChartLoading,
  ] = useState([]);
  const [
    objectiveWiseDistributionLineChartLoaded,
    setObjectiveWiseDistributionLineChartLoaded,
  ] = useState([]);
  const [objectiveWiseDistributionLineChartError, setObjectiveWiseDistributionLineChartError] =
    useState([]);

  const objectiveWiseDistributionLineChartHandler = async () => {
    setObjectiveWiseDistributionLineChartLoading(true);
    setObjectiveWiseDistributionLineChartLoaded(false);
    setObjectiveWiseDistributionLineChartError(null);

    var objectiveConfig = (function () {
      if (selectedObjectives === null && (parentDonutSliceToggle === null || parentDonutSliceToggle === undefined)) {
        return { "objective": null }
      } else if (selectedObjectives !== null && (parentDonutSliceToggle === undefined || parentDonutSliceToggle === null)) {
        return { "objective": selectedObjectives }
      } else {
        return { "objective": parentDonutSliceToggle }
      }
    })()

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        /*  objective: parentDonutSliceToggle, */
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        ...objectiveConfig
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/twitter/campaign-objective-linechart/",
        config
      );

      setObjectiveWiseDistributionLineChart(res?.data?.data ?? []);
      setObjectiveWiseDistributionLineChartLabel(res?.data?.dimension ?? []);

      setObjectiveWiseDistributionLineChartLoading(false);
      setObjectiveWiseDistributionLineChartLoaded(true);
      setObjectiveWiseDistributionLineChartError(null);
    } catch (error) {
      setObjectiveWiseDistributionLineChart([]);
      setObjectiveWiseDistributionLineChartLabel([]);
      setObjectiveWiseDistributionLineChartLoading(false);
      setObjectiveWiseDistributionLineChartLoaded(false);
      setObjectiveWiseDistributionLineChartError(error.code);
    }
  };

  let objectiveWiseDistributionLineChartSection = (
    <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (objectiveWiseDistributionLineChartLoaded && !objectiveWiseDistributionLineChartLoading) {
    if (
      NoDataAvailableChecker(objectiveWiseDistributionLineChart) ||
      NoDataAvailableChecker(objectiveWiseDistributionLineChartLabel)
    ) {
      objectiveWiseDistributionLineChartSection = (
        <Wrapper>   <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      objectiveWiseDistributionLineChartSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              platform="Twitter"
              chartClass="section_card_chart"
              chartData={objectiveWiseDistributionLineChart}
              chartId="multiLineChart"
              chartLabels={objectiveWiseDistributionLineChartLabel}
              onBulletsClick={function noRefCheck() { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (objectiveWiseDistributionLineChartError) {
    objectiveWiseDistributionLineChartSection = (
      <Wrapper>   <ServerErrorsLoader
        chartType="lineChartType"
        error={objectiveWiseDistributionLineChartError}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (objectiveWiseDistributionLineChartLoading) {
    objectiveWiseDistributionLineChartSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  useEffect(() => {
    objectiveWiseDistributionLineChartHandler();
  }, [BRAND_ID, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  var campaignObjectCSV = {
    "CampaignObjDonut_Campaign Objective Donut": objectiveWiseDistributionDonut,
    [`CampObjectiveDynaLabels_Campaign Objective Line_${parentDonutSliceToggle === undefined || parentDonutSliceToggle === null ? "empty" : parentDonutSliceToggle.replace("_", "-")}`]: objectiveWiseDistributionLineChart,
  };

  var cityCountryData = {
    // "CityChart_Audience Distribution - City": NoDataAvailableChecker(cityData) ? [] : cityData,
    [`CountryChartDynaLabels_Audience Distribution - Country_${selectedMidSectionDropdownOption.value}`]: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var audienceCount = {
    "Total Audience Count": [
      {
        [`Male_${selectedMidSectionDropdownOption.label}`]: totalMaleCount,
        [`Female_${selectedMidSectionDropdownOption.label}`]: totalFemaleCount,
        [`Unknown_${selectedMidSectionDropdownOption.label}`]: totalUnknownCount,
      },
    ],
  }

  var deviceDistributionExport = {
    [`CampaignDeviceDistDynaLabels_Device Distribution_${selectedMidSectionDropdownOption.value}`]: deviceDistribution
  }

  var ageCountExport = {
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Age_${selectedMidSectionDropdownOption.value}`]: NoDataAvailableChecker(ageCount) ? [] : ageCount,
  }

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    ...campaignObjectCSV,
    ...audienceCount,
    ...deviceDistributionExport,
    ...ageCountExport,
    ...cityCountryData,
    [`CampaignTableDynaLabels_Summary Table_${selectedCampaignSummaryDropdownOption.label.toLowerCase()}`]: campaignSummary,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    objectiveWiseDistributionDonut,
    objectiveWiseDistributionLineChart,
    countryData,
    totalUnknownCount,
    deviceDistribution,
    ageCount,
    campaignSummary,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (objectiveWiseDistributionDonutError !== null ? true : objectiveWiseDistributionDonutLoaded && (objectiveWiseDistributionDonutLoading === false)) &&
      (objectiveWiseDistributionLineChartError !== null ? true : objectiveWiseDistributionLineChartLoaded && (objectiveWiseDistributionLineChartLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (unknownCounterror !== null ? true : isUnknownCountLoaded && (isUnknownCountLoading === false)) &&
      (deviceDistributionerror !== null ? true : isDeviceDistributionLoaded && (isDeviceDistributionLoading === false)) &&
      (ageCounterror !== null ? true : isAgeCountLoaded && (isAgeCountLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    objectiveWiseDistributionDonutError, objectiveWiseDistributionDonutLoaded, objectiveWiseDistributionDonutLoading,
    objectiveWiseDistributionLineChartError, objectiveWiseDistributionLineChartLoaded, objectiveWiseDistributionLineChartLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    unknownCounterror, isUnknownCountLoaded, isUnknownCountLoading,
    deviceDistributionerror, isDeviceDistributionLoaded, isDeviceDistributionLoading,
    ageCounterror, isAgeCountLoaded, isAgeCountLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="Campaign_Objective_Wise_distribution_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">Objective Wise Distribution
            </h2> <SectionDefinationsBadge
                title={"Objective Wise Distribution"}
                module="campaign"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"Campaign_Objective_Wise_distribution_section"}
                sectionName={"Objective Wise Distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {objectiveWiseDistributionDonutChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {objectiveWiseDistributionLineChartSection}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid cols_span_1">
                <h2 className="section_card_title">Performance By KPI</h2>
              </div>
              <div className="grid cols_span_3 flex justify_end">
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={midSectionDropdonwOptions}
                  className="form_dropdown section_dropdown"
                  value={selectedMidSectionDropdownOption}
                  selectedOptions={selectedMidSectionDropdownOption}
                  setStatedropdown={midSectionDropdownSelectionHandler}
                />
                {/* <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={performanceSourceDropdonwOptions}
                  className="form_dropdown section_dropdown"
                  value={performanceSourceDropdownOption}
                  selectedOptions={performanceSourceDropdownOption}
                  setStatedropdown={performanceSourceDropdownSelectionHandler}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="gender_export_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Gender: {selectedMidSectionDropdownOption.label}
            </h2> <SectionDefinationsBadge
                title={"Audience Distribution By Gender"}
                module="campaign"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"gender_export_section"}
                sectionName={"Gender"}
                sectionData={audienceCount}
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="grid col_span_3">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  <div className="grid col_span_4 section_card_chart_wrapper">
                    <div className="grid col_span_1 flex row justify_center align_center">
                      <div>{maleAudienceDistributionIcon}</div>
                      <div>{femaleAudienceDistributionIcon}</div>
                      <div>{unknownAudienceDistributionIcon}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="device_distribution_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Device Distribution: {selectedMidSectionDropdownOption.label}
            </h2><SectionDefinationsBadge
                title={"Device Distribution"}
                module="campaign"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_distribution_section"}
                sectionName={"CampaignDeviceDist_Device Distribution"}
                sectionData={deviceDistribution}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {deviceDistributionChart}
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_age_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Audience Distribution: {selectedMidSectionDropdownOption.label}
            </h2> <SectionDefinationsBadge
                title={"Audience Distribution By Age"}
                module="campaign"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_age_section"}
                sectionName={"AgeBarChartCampaignDynalabels_Audience Distribution"}
                sectionData={ageCountExport}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_4  flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Age</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_4 section_card_chart_wrapper">
                {ageAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_place_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Audience Distribution: {selectedMidSectionDropdownOption.label}
            </h2> <SectionDefinationsBadge
                title={"Audience Distribution By Country"}
                module="campaign"
                platform="twitter"
                section="overall"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_place_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          {/* <div className="grid col_span_2 card_border_right flex column"> */}
          {/* <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
          <div className="section_card_body"> */}
          {countryMap}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {cityMap}
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
          <div className="section_info">    <h2 className="section_card_title">Summary
              </h2> <SectionDefinationsBadge
                title={"Summary"}
                module="campaign"
                platform="twitter"
                section="overall"
              />
           </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body"> */}
      {campaignSummaryTable}
      {/* </div>
        </div> */}
      {/* </div> */}
    </Wrapper>
  );
};

export default CampaignTwitterOverall;
