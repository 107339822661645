import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Datatable from "../../../../components/ui/tables/Datatable";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import LineChart from "../../../../components/ui/charts/LineChart";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import {
  BlueColorStart500
} from "../../../../constants/constants";
import Tab from "../../../../components/ui/Tab";
import BarChart from "../../../../components/ui/charts/BarChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import { campaignSummaryGetHeaderKeysWithLabels, concateTotals, getHeaderKeysWithLabels, getUniqueValuesFromArrofObj } from "../../../../utils/Utils";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

const CampaignOverallOverall = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (
    globalSelectedBrandIds.length === 0 ||
    globalSelectedBrandIds === undefined
  ) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // const isGa4SectionActive = useSelector((state) => state.ga4section.getGa4Section);
  // TO SHOW OR HIDE THE GA4 SECTION
  const isGa4SectionActive = true


  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedBuckets
  );

  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (
    selectedCampaignPlatformsFromFilter.length === 0 ||
    selectedCampaignPlatformsFromFilter === undefined
  ) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (
    selectedCampaignObjectivesFromFilter.length === 0 ||
    selectedCampaignObjectivesFromFilter === undefined
  ) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (
    selectedCampaignsFromFilter.length === 0 ||
    selectedCampaignsFromFilter === undefined
  ) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }


  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence";
  let pageSubModule = "Campaign";
  let pagePlatform = "Overall";
  let pagePrimaryTab = "Overall";

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // OBJECTIVE WISE DISTRIBUTION
  const [campaignObjectivewiseDistribution, setCampaignObjectivewiseDistribution] = useState([]);
  const [campaignObjectivewiseDistributionDataLabels, setCampaignObjectivewiseDistributionDataLabels] = useState([]);
  const [isContentAnalysisLoading, setCampaignObjectivewiseDistributionIsLoading] = useState(false);
  const [isContentAnalysisLoaded, setCampaignObjectivewiseDistributionIsLoaded] = useState(false);
  const [campaignObjectivewiseDistributionerror, setCampaignObjectivewiseDistributionError] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "spends", label: "Spends" },
    { value: "leads", label: "Leads" },
    { value: "app_install", label: "App Install" },
  ];

  // DROPDOWN DEFAULT STATE
  const [selectedMidSectionDropdownOption, setSelectedMidSectionDropdownDropdownOption] = useState(midSectionDropdonwOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Overall",
      "Overall",
      null,
      "Performance By KPI",
      e.label
    );
  };

  // PERFORMANCE BY SOURCE
  const [performanceBySource, setPerformanceBySource] = useState([]);
  const [performanceBySourceAllDropdownsData, setPerformanceBySourceAllDropdownsData] = useState([]);
  const [performanceBySourceDataLabels, setPerformanceBySourceDataLabels] = useState([]);
  const [performanceBySourceAllDropdownsLabels, setPerformanceBySourceAllDropdownsLabels] = useState([]);
  const [isPerformanceBySourceLoading, setPerformanceBySourceIsLoading] = useState(false);
  const [isPerformanceBySourceLoaded, setPerformanceBySourceIsLoaded] = useState(false);
  const [performanceBySourceerror, setPerformanceBySourceError] = useState(null);

  // PERFORMANCE BY SOURCE DETAILS
  const [performanceBySourceDetails, setPerformanceBySourceDetails] = useState([]);
  const [performanceBySourceDetailsAllDropdownsData, setPerformanceBySourceDetailsAllDropdownsData] = useState([]);
  const [performanceBySourceDetailsTotal, setPerformanceBySourceDetailsTotal] = useState([]);
  const [performanceBySourceDetailsTotalAllDropdownsData, setPerformanceBySourceDetailsTotalAllDropdownsData] = useState([]);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})

  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad Group" },
  ];

  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })

  // CAMPAIGN OVERVIEW DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState(campaignSummaryOptions[0]);


  // TABLE HEADERS FOR COKE
  let cokeSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Platform", field: "platform" },
    { name: "Objective", field: "objective" },
    // { name: "Impressions", field: "impressions" },
    { name: "Engagements", field: "total_engagement" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engagement_rate" },
    // { name: "Clicks / Conversion", field: "click_conversion" },
  ];

  let defaultTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Platform", field: "platform" },
    { name: "Objective", field: "objective" },
    // { name: "Engagements", field: "engagements" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Engagement Rate", field: "engagement_rate" },
  ];


  // CAMPAIGN OVERVIEW DROPDOWN
  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS

  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Overall",
      "Overall",
      null,
      "Summary",
      e.label
    );
  };

  useEffect(() => {
    // if (companyID === COKE_COMPANY_ID) {
    //   setCampaignSummaryTableHeaders(cokeSummaryTableHeaders)
    // } else {
    //   setCampaignSummaryTableHeaders(defaultTableHeaders)
    // }
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
  ])

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.ad ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.ad_group ?? []);
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData?.campaign ?? []);
    } else {
      setCampaignSummary([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
  ]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchCampaignObjectivewiseDistributionHandler();
    fetchPerformanceBySourceHandler();
    // fetchCampaignSummaryHandler();

    // fetchGAConversionDonutHandler();
    // fetchTop10EventHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
  ]);



  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {
      setPerformanceBySource(performanceBySourceAllDropdownsData?.clicks ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.clicks ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.clicks ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.clicks?.platform_total ?? []);
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      setPerformanceBySource(performanceBySourceAllDropdownsData?.conversions ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.conversions ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.conversions ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.conversions?.platform_total ?? []);
    } else if (selectedMidSectionDropdownOption.value === "engagements") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.engagements ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.engagements ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.engagements ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.engagements?.platform_total ?? []);

    } else if (selectedMidSectionDropdownOption.value === "impressions") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.impressions ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.impressions ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.impressions ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.impressions?.platform_total ?? []);

    } else if (selectedMidSectionDropdownOption.value === "app_install") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.app_install ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.app_install ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.app_install ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.app_install?.platform_total ?? []);

    } else if (selectedMidSectionDropdownOption.value === "spends") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.spends ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.spends ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.spends ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.spends?.platform_total ?? []);

    } else if (selectedMidSectionDropdownOption.value === "leads") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.leads ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.leads ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.leads ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.leads?.platform_total ?? []);

    } else {
      setPerformanceBySource([]);
      setPerformanceBySourceDataLabels([]);
      setPerformanceBySourceDetails([]);
      setPerformanceBySourceDetailsTotal([]);
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedMidSectionDropdownOption,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
  ]);


  // GA4 APIS

  const ga4GlobalDropdown = [
    { label: "Conversion", value: 'Conversion' },
    { label: "Non Conversion", value: 'Non Conversion' },
    { label: "All Event", value: "all" },
  ]

  const [ga4GlobalDropdownSelectedvalue, setGa4GlobalDropdownSelectedvalue] = useState(ga4GlobalDropdown[0]);

  const GA4GlobalDropdownSelectionHandler = (e) => {
    if (e === null) {
      setGa4GlobalDropdownSelectedvalue("");
    } else {
      setGa4GlobalDropdownSelectedvalue(e);
      setCountryTableCountConfig({ ...countryTableCountConfig, page_num: 1, search_value: null })
      setRegionCountConfig({ ...regionCountConfig, page_num: 1, search_value: null })
    }

    setgaConversionDonutSlice(null)
  };


  const [gaConversionDonut, setgaConversionDonut] = useState([])
  const [gaConversionDonutIsLoading, setgaConversionDonutIsLoading] = useState(false)
  const [gaConversionDonutIsLoaded, setgaConversionDonutIsLoaded] = useState(false)
  const [gaConversionError, setgaConversionError] = useState(null)

  const [gaConversionDonutSlice, setgaConversionDonutSlice] = useState(null)
  let formatSlice = gaConversionDonutSlice !== undefined && gaConversionDonutSlice !== null ? " : " + gaConversionDonutSlice : '';

  const gaDonutDropdownOptions = [
    { label: "Campaign Objective", value: 'objective' },
    { label: "Organic / Paid bifurcation", value: 'organic_paid' },
    { label: "Channel", value: 'channel' },
    { label: "Platform", value: 'platform' },
  ]

  // useEffect(() => {
  //   setCountryTableCountConfig({ ...countryTableCountConfig, page_num: 1, search_value: null })
  //   setRegionCountConfig({ ...regionCountConfig, page_num: 1, search_value: null })
  // }, [gaConversionDonutSlice])



  const [gaDonutDropdownSelectedValue, setGaDonutDropdownSelectedValue] = useState(gaDonutDropdownOptions[0])


  const GaDonutDropdownSelectionHandler = (e) => {
    if (e === null) {
      setGaDonutDropdownSelectedValue("");
    } else {
      setGaDonutDropdownSelectedValue(e);
    }
    setgaConversionDonutSlice(null);
  };



  const [top10EventData, setTop10EventData] = useState([])
  const [top10EventLabels, setTop10EventLabels] = useState([])
  const [top10EventIsLoading, setTop10EventIsLoading] = useState(false)
  const [top10EventIsLoaded, setTop10EventIsLoaded] = useState(false)
  const [top10EventError, setTop10EventError] = useState(null)


  const [countryTableCount, setCountryTableCount] = useState([])
  const [countryTableCountTableHeader, setCountryTableCountTableHeader] = useState([])

  const [isCountryTableCountLoading, setCountryTableCountIsLoading] = useState(false);
  const [isCountryTableCountLoaded, setCountryTableCountIsLoaded] = useState(false);
  const [countryTableCounterror, setCountryTableCountError] = useState(null);

  const [countryTableCountExportData, setCountryTableCountExportData] = useState([]);
  const [countryTableCountConfig, setCountryTableCountConfig] = useState({ page_num: 1, page_size: 5, search_value: null, order_by: 'total_event', order_type: 'desc' })

  const [countryTableCountPageData, setCountryTableCountPageData] = useState({})



  const [regionCount, setRegionCount] = useState([])
  const [regionCountTableHeader, setRegionCountTableHeader] = useState([])

  const [isRegionCountLoading, setRegionCountIsLoading] = useState(false);
  const [isRegionCountLoaded, setRegionCountIsLoaded] = useState(false);
  const [regionCounterror, setRegionCountError] = useState(null);

  const [regionCountExportData, setRegionCountExportData] = useState([]);
  const [regionCountConfig, setRegionCountConfig] = useState({ page_num: 1, page_size: 5, search_value: null, order_by: 'total_event', order_type: 'desc' })

  const [regionCountPageData, setRegionCountPageData] = useState({})


  // GA CONVERSION SUMMARY
  const [gaConversionSummary, setGaConversionSummary] = useState([]);
  const [gaConversionSummaryTableHeaders, setGaConversionSummaryTableHeaders] = useState([]);
  const [gaConversionSummaryDataTotal, setGaConversionSummaryDataTotal] = useState([]);
  const [gaConversionSummaryExportData, setGaConversionSummaryExportData] = useState([]);
  const [isGaConversionSummaryLoading, setGaConversionSummaryIsLoading] = useState(false);
  const [isGaConversionSummaryLoaded, setGaConversionSummaryIsLoaded] = useState(false);
  const [gaConversionSummaryerror, setGaConversionSummaryError] = useState(null);
  const [gaConversionSummaryTablePageData, setGaConversionSummaryTablePageData] = useState({})


  const [gaConversionSummaryConfig, setGaConversionSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "total_event", search_value: null })




  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/ad-metric/", config);

      setMetricCards(res?.data?.data ?? []);

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">
                <h2 className="section_card_title">Summary</h2>
              </div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="overall"
                    section="overall"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              {" "}
              <h2 className="section_card_title">Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchCampaignObjectivewiseDistributionHandler = async () => {
    setCampaignObjectivewiseDistributionIsLoading(true);
    setCampaignObjectivewiseDistributionIsLoaded(false);
    setCampaignObjectivewiseDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/objective-distribution/", config);

      setCampaignObjectivewiseDistribution(res?.data[0]?.total_campaign?.data ?? []);
      setCampaignObjectivewiseDistributionDataLabels(res?.data[0]?.total_campaign?.label ?? []);

      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(true);
      setCampaignObjectivewiseDistributionError(null);
    } catch (error) {
      setCampaignObjectivewiseDistribution([]);
      setCampaignObjectivewiseDistributionDataLabels([]);
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(false);
      setCampaignObjectivewiseDistributionError(error.code);
    }
  };

  // DATA HANDLING
  let campaignObjectivewiseDistributionPie = (
    <Wrapper>
      <Loader loaderType="pieChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (
      NoDataAvailableChecker(campaignObjectivewiseDistribution) ||
      NoDataAvailableChecker(campaignObjectivewiseDistributionDataLabels)
    ) {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <NoDataAvailableLoader chartType="pieChartType" />
        </Wrapper>
      );
    } else {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={campaignObjectivewiseDistribution}
              chartLabels={campaignObjectivewiseDistributionDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignObjectivewiseDistributionerror) {
    campaignObjectivewiseDistributionPie = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={campaignObjectivewiseDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    campaignObjectivewiseDistributionPie = (
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE
  const [isperformanceBySourceOpen, setIsPerformanceBySourceOpen] = useState(false)

  let defaultPerformanceBySourceDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPerformanceBySourceDisabledvalues = []
  } else {
    defaultPerformanceBySourceDisabledvalues = ["Instagram", "DV 360"]
  }

  const [performanceBySourceDisabledvalues, setPerformanceBySourceDisabledvalues] = useState(defaultPerformanceBySourceDisabledvalues)

  // TO FETCH DATA FROM API
  const fetchPerformanceBySourceHandler = async () => {
    setPerformanceBySourceIsLoading(true);
    setPerformanceBySourceIsLoaded(false);
    setPerformanceBySourceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",

        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/metric-by-date/",
        config
      );

      setPerformanceBySource(res?.data?.graph_metrics?.data?.impressions ?? []);
      setPerformanceBySourceDataLabels(res?.data?.graph_metrics?.dimension?.impressions ?? []);
      setPerformanceBySourceDetails(res?.data?.side_metrics?.impressions ?? []);

      setPerformanceBySourceAllDropdownsData(res?.data?.graph_metrics?.data ?? []);
      setPerformanceBySourceAllDropdownsLabels(res?.data?.graph_metrics?.dimension ?? []);

      setPerformanceBySourceDetailsAllDropdownsData(res?.data?.side_metrics ?? []);

      setPerformanceBySourceDetailsTotal(res?.data?.side_metrics_total?.impressions?.platform_total ?? []);

      setPerformanceBySourceDetailsTotalAllDropdownsData(res?.data?.side_metrics_total ?? []);
      setPerformanceBySourceIsLoading(false);
      setPerformanceBySourceIsLoaded(true);
      setPerformanceBySourceError(null);
    } catch (error) {
      setPerformanceBySourceAllDropdownsData([]);
      setPerformanceBySourceAllDropdownsLabels([]);
      setPerformanceBySourceDetailsAllDropdownsData([]);
      setPerformanceBySourceDetailsTotalAllDropdownsData([]);
      setPerformanceBySourceIsLoading(false);
      setPerformanceBySourceIsLoaded(false);
      setPerformanceBySourceError(error.code);
    }
  };

  //  DATA HANDLING
  let performanceBySourceChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let performanceBySourceChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceBySourceLoaded && !isPerformanceBySourceLoading) {
    if (
      NoDataAvailableChecker(performanceBySource) ||
      NoDataAvailableChecker(performanceBySourceAllDropdownsLabels)
    ) {
      performanceBySourceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      performanceBySourceChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

    } else {

      if (isMultiLineCluster) {
        performanceBySourceChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="performance_by_source"
                chartClass="section_card_chart"
                chartData={performanceBySource}
                chartLabels={performanceBySourceDataLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={performanceBySourceDisabledvalues}
                setDisabledValues={setPerformanceBySourceDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        performanceBySourceChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={performanceBySource}
                chartId="performance_by_source_2"
                chartLabels={performanceBySourceDataLabels}
                showLegend={true}
                disabledLegendArray={performanceBySourceDisabledvalues}
                setDisabledValues={setPerformanceBySourceDisabledvalues}
                showLabels={true}

              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        performanceBySourceChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="performance_by_source"
                chartClass="section_card_chart"
                chartData={performanceBySource}
                chartLabels={performanceBySourceDataLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceBySourceerror) {
    performanceBySourceChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );

    performanceBySourceChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceBySourceLoading) {
    performanceBySourceChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    performanceBySourceChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE DETAILS

  // DATA HANDLING
  let performanceBySourceDetailsData = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceBySourceLoaded && !isPerformanceBySourceLoading) {
    if (NoDataAvailableChecker(performanceBySourceDetails)) {
      performanceBySourceDetailsData = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      performanceBySourceDetailsData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="main_wrapper">
              <div className="body">
                {performanceBySourceDetails &&
                  performanceBySourceDetails.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">{details.label}</div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter title={selectedMidSectionDropdownOption.label} number={details.value} />
                            </ErrorBoundary>
                            {details.metric && (
                              <div className="metrics_wrapper">
                                <div className="title_wrapper">
                                  {details.metric[0].label + " : "}
                                </div>
                                <div className="value_wrapper">
                                  <ErrorBoundary>
                                    <NumberFormatter
                                      title={selectedMidSectionDropdownOption.label}
                                      number={details.metric[0].value}
                                    />
                                  </ErrorBoundary>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Wrapper>
                    )
                  )}
              </div>
              <div className="footer">
                <div className="title_wrapper">Total</div>
                <div className="value_wrapper">
                  <NumberFormatter title={selectedMidSectionDropdownOption.label} number={performanceBySourceDetailsTotal} />
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceBySourceerror) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceBySourceLoading) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY
  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {
      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1,

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ad-summary/v2/", config);

        var allData = res?.data?.data?.results ?? [];

        // var headerKeys = campaignSummaryTableHeaders.map((x) => x.field)
        // var formattedData = allData.map((row) => {

        //   var newData = {};
        //   Object.keys(row).forEach((x, y) => {
        //     if (headerKeys.includes(x)) {
        //       newData[x] = row[x]
        //     }
        //   })
        //   return newData
        // })
        setCampaignSummaryExportData(allData ?? []);

      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig.dropdown_value])



  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig
        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ad-summary/v2/", config);

        let allData = res.data.data.results;

        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)
        let formattedHeaders = []
        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {});

        setCampaignSummary(allData ?? []);

        setCampaignSummaryTableHeaders(formattedHeaders ?? [])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([]);
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };

    fetchCampaignSummaryHandler()
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    campaignSummaryConfig
  ])




  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {

    campaignSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle="Summary"
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={campaignSummaryTableHeaders}
            tableData={campaignSummary}
            tableLength={campaignSummaryConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Campaign"}
            tableConfigs={campaignSummaryConfig}
            setTableConfigs={setCampaignSummaryConfig}
            tablePaginationData={campaignSummaryTablePageData}
            removeImgExtensions={true}
            tableDropdown={
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignSummaryOptions}
                className="form_dropdown section_dropdown"
                value={selectedCampaignSummaryDropdownOption}
                selectedOptions={selectedCampaignSummaryDropdownOption}
                setStatedropdown={campaignSummaryDropdownSelectionHandler}
              />
            }
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"search_campaign_section"}
                sectionName={`TableDynaLabels_Summary Table _${":" + selectedCampaignSummaryDropdownOption.label}`}
                sectionData={campaignSummaryExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={campaignSummaryerror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // GA 4 APIS

  // GA CONVERSION DONUT

  useEffect(() => {

    const fetchGAConversionDonutHandler = async () => {
      setgaConversionDonutIsLoading(true);
      setgaConversionDonutIsLoaded(false);
      setgaConversionError(null);
      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          event_type: ga4GlobalDropdownSelectedvalue.value === "all" ? null : ga4GlobalDropdownSelectedvalue.value,
          dimension_name: gaDonutDropdownSelectedValue.value,
          order_by: ''
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ga4/event-conversion/", config);

        let formattedData = []

        if (res.data.data) {
          formattedData = res.data?.data.map((item) => {

            if (gaDonutDropdownSelectedValue.value === 'channel') {
              return ({
                label: item['channel'],
                value: item.total_event
              })

            } else {
              return ({
                label: item[gaDonutDropdownSelectedValue.value],
                value: item.total_event
              })
            }
          }) ?? []
        }


        setgaConversionDonut(formattedData ?? []);

        setgaConversionDonutIsLoading(false);
        setgaConversionDonutIsLoaded(true);
        setgaConversionError(null);
      } catch (error) {
        setgaConversionDonut([]);
        setgaConversionDonutIsLoading(false);
        setgaConversionDonutIsLoaded(false);
        setgaConversionError(error.code);
      }
    };

    isGa4SectionActive && fetchGAConversionDonutHandler()

  }, [
    // BRAND_ID,
    // selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    gaDonutDropdownSelectedValue,
    ga4GlobalDropdownSelectedvalue
  ])



  let gaConversionDonutSection = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    gaConversionDonutIsLoaded &&
    !gaConversionDonutIsLoading
  ) {
    if (NoDataAvailableChecker(gaConversionDonut)) {
      gaConversionDonutSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      gaConversionDonutSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={gaConversionDonut}
              chartId="CampaignDonutChild"
              onSliceClick={(data) => setgaConversionDonutSlice(data.label)}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gaConversionError) {
    gaConversionDonutSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={gaConversionError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (gaConversionDonutIsLoading) {
    gaConversionDonutSection = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP 10 EVENT


  useEffect(() => {
    const fetchTop10EventHandler = async () => {
      setTop10EventIsLoading(true);
      setTop10EventIsLoaded(false);
      setTop10EventError(null);
      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          event_type: ga4GlobalDropdownSelectedvalue.value === "all" ? null : ga4GlobalDropdownSelectedvalue.value,
          dimension_name: gaDonutDropdownSelectedValue.value,
          dimension_value: gaConversionDonutSlice,
          order_type: 'desc',
          order_by: 'total_event',
          page_num: 1,
          page_size: 10,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ga4/event/", config);

        const formattedData = res.data?.data?.results.map((item) => ({
          label: item.event_name,
          value: item.total_event
        })) ?? []

        setTop10EventData(formattedData ?? []);

        setTop10EventLabels([
          {
            label: 'Event Count',
            value: 'value'
          }
        ])

        setTop10EventIsLoading(false);
        setTop10EventIsLoaded(true);
        setTop10EventError(null);
      } catch (error) {
        setTop10EventData([]);
        setTop10EventLabels([])

        setTop10EventIsLoading(false);
        setTop10EventIsLoaded(false);
        setTop10EventError(error.code);
      }
    };

    isGa4SectionActive && fetchTop10EventHandler()
  }, [
    // BRAND_ID,
    // selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    gaConversionDonutSlice,
    ga4GlobalDropdownSelectedvalue
  ])

  let top10EventsSection = (<Wrapper>
    <Loader loaderType="barChartLoader" />
  </Wrapper>
  )

  // IF DATA IS LOADED
  if (top10EventIsLoaded && !top10EventIsLoading) {
    if (NoDataAvailableChecker(top10EventData) || NoDataAvailableChecker(top10EventLabels)
    ) {
      top10EventsSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      top10EventsSection = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId={'top_event_name'}
              chartClass={'section_card_chart'}
              chartLabels={top10EventLabels}
              chartData={top10EventData}
              showLabels={true}
              showShortVerticalLabels={true}
              minimumGridDistance={20}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (top10EventError) {
    top10EventsSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={top10EventError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (top10EventIsLoading) {
    top10EventsSection = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BY COUNTRY

  useEffect(() => {

    const fetchCountryTableCountHandler = async () => {
      setCountryTableCountIsLoading(true);
      setCountryTableCountIsLoaded(false);
      setCountryTableCountError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          event_type: ga4GlobalDropdownSelectedvalue.value === "all" ? null : ga4GlobalDropdownSelectedvalue.value,
          dimension_name: gaDonutDropdownSelectedValue.value,
          dimension_value: gaConversionDonutSlice,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...countryTableCountConfig,

          page_size: -1
        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ga4/country/", config);
        setCountryTableCountExportData(res?.data?.data?.results ?? [])

      } catch (error) {
        setCountryTableCountExportData([]);
      }
    };

    isGa4SectionActive && fetchCountryTableCountHandler()
  }, [
    // BRAND_ID,
    // selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    ga4GlobalDropdownSelectedvalue,
    gaConversionDonutSlice
  ])

  useEffect(() => {

    const fetchCountryTableCountHandler = async () => {
      setCountryTableCountIsLoading(true);
      setCountryTableCountIsLoaded(false);
      setCountryTableCountError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          event_type: ga4GlobalDropdownSelectedvalue.value === "all" ? null : ga4GlobalDropdownSelectedvalue.value,
          dimension_name: gaDonutDropdownSelectedValue.value,
          dimension_value: gaConversionDonutSlice,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...countryTableCountConfig,

        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ga4/country/", config);

        setCountryTableCountPageData(res?.data?.data?.page ?? {});

        setCountryTableCount(res?.data?.data?.results ?? []);

        setCountryTableCountTableHeader([
          { name: 'Country', field: 'country' },
          { name: 'Event Count', field: 'total_event' }
        ])
        setCountryTableCountIsLoading(false);
        setCountryTableCountIsLoaded(true);
        setCountryTableCountError(null);
      } catch (error) {
        setCountryTableCount([]);
        setCountryTableCountTableHeader([])
        setCountryTableCountIsLoading(false);
        setCountryTableCountIsLoaded(false);
        setCountryTableCountError(error.code);
      }
    };

    isGa4SectionActive && fetchCountryTableCountHandler()
  }, [
    // BRAND_ID,
    // selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    countryTableCountConfig,
    ga4GlobalDropdownSelectedvalue,
    gaConversionDonutSlice
  ])


  let countryTableCountSection = (
    <Wrapper>
      <div className="grid grid_cols_4 section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              By Country
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCountryTableCountLoaded && !isCountryTableCountLoading) {

    countryTableCountSection = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle={`By Country : ${ga4GlobalDropdownSelectedvalue.label + formatSlice} `}
            tableTitleClass={'impr_count_reg_title'}
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={countryTableCountTableHeader}
            tableData={countryTableCount}
            tableLength={countryTableCountConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Country"}
            tableConfigs={countryTableCountConfig}
            setTableConfigs={setCountryTableCountConfig}
            tablePaginationData={countryTableCountPageData}
            removeImgExtensions={true}
            tableID={"by_country"}
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"by_country"}
                sectionName={`Ga4Country_By Country_${ga4GlobalDropdownSelectedvalue.label + formatSlice}`}
                sectionData={countryTableCountExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryTableCounterror) {
    countryTableCountSection = (
      <Wrapper>
        <div className="grid grid_cols_4 section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                By Country
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={countryTableCounterror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryTableCountLoading) {
    countryTableCountSection = (
      <Wrapper>
        <div className="grid grid_cols_4 section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                By Country
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }



  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BY REGION
  useEffect(() => {

    const fetchRegionCountHandler = async () => {
      setRegionCountIsLoading(true);
      setRegionCountIsLoaded(false);
      setRegionCountError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          event_type: ga4GlobalDropdownSelectedvalue.value === "all" ? null : ga4GlobalDropdownSelectedvalue.value,
          dimension_name: gaDonutDropdownSelectedValue.value,
          dimension_value: gaConversionDonutSlice,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...regionCountConfig,

          page_size: -1
        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ga4/region/", config);
        setRegionCountExportData(res?.data?.data?.results ?? []);
      } catch (error) {
        setRegionCountExportData([]);
      }
    };

    isGa4SectionActive && fetchRegionCountHandler()
  }, [
    // BRAND_ID,
    // selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    ga4GlobalDropdownSelectedvalue,
    gaConversionDonutSlice
  ])
  useEffect(() => {
    const fetchRegionCountHandler = async () => {
      setRegionCountIsLoading(true);
      setRegionCountIsLoaded(false);
      setRegionCountError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          event_type: ga4GlobalDropdownSelectedvalue.value === "all" ? null : ga4GlobalDropdownSelectedvalue.value,
          dimension_name: gaDonutDropdownSelectedValue.value,
          dimension_value: gaConversionDonutSlice,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...regionCountConfig,

        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ga4/region/", config);

        setRegionCountPageData(res?.data?.data?.page ?? {});

        setRegionCount(res?.data?.data?.results ?? []);

        setRegionCountTableHeader([
          { name: 'Region', field: 'region' },
          { name: 'Event Count', field: 'total_event' }
        ])
        setRegionCountIsLoading(false);
        setRegionCountIsLoaded(true);
        setRegionCountError(null);
      } catch (error) {
        setRegionCount([]);
        setRegionCountTableHeader([])
        setRegionCountIsLoading(false);
        setRegionCountIsLoaded(false);
        setRegionCountError(error.code);
      }
    };

    isGa4SectionActive && fetchRegionCountHandler()
  }, [
    // BRAND_ID,
    // selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    regionCountConfig,
    ga4GlobalDropdownSelectedvalue,
    gaConversionDonutSlice
  ])

  let regionCountTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              By Region
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isRegionCountLoaded && !isRegionCountLoading) {

    regionCountTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle={`By Regions : ${ga4GlobalDropdownSelectedvalue.label + formatSlice}`}
            tableTitleClass={'impr_count_reg_title'}
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={regionCountTableHeader}
            tableData={regionCount}
            tableLength={regionCountConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Regions"}
            tableConfigs={regionCountConfig}
            setTableConfigs={setRegionCountConfig}
            tablePaginationData={regionCountPageData}
            removeImgExtensions={true}
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"search_campaign_section"}
                sectionName={`Ga4Region_By Region_${ga4GlobalDropdownSelectedvalue.label + formatSlice}`}
                sectionData={regionCountExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (regionCounterror) {
    regionCountTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                By Regions
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={regionCounterror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isRegionCountLoading) {
    regionCountTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                By Regions
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // GA CONVERSION SUMMARY
  useEffect(() => {

    const fetchGaConversionSummaryHandler = async () => {
      setGaConversionSummaryIsLoading(true);
      setGaConversionSummaryIsLoaded(false);
      setGaConversionSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...gaConversionSummaryConfig,
          page_size: -1
        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ga4/summary/", config);

        let allData = res.data.data.results;
        let alignedHeaders = allData.map(({
          session_default_channel_group,
          session_source,
          session_medium,
          objective,
          platform,
          event_name,
          event_tag,
          total_event
        }) => ({
          session_default_channel_group,
          session_source,
          session_medium,
          objective,
          platform,
          event_name,
          event_tag,
          total_event
        }))

        setGaConversionSummaryExportData(alignedHeaders ?? []);

      } catch (error) {
        setGaConversionSummaryExportData([]);
      }
    };

    isGa4SectionActive && fetchGaConversionSummaryHandler()
  }, [
    // BRAND_ID,
    // selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
  ])



  useEffect(() => {

    const fetchGaConversionSummaryHandler = async () => {
      setGaConversionSummaryIsLoading(true);
      setGaConversionSummaryIsLoaded(false);
      setGaConversionSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...gaConversionSummaryConfig
        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ga4/summary/", config);

        let allData = res.data.data.results;

        let alignedHeaders = allData.map(({
          session_default_channel_group,
          session_source,
          session_medium,
          objective,
          platform,
          event_name,
          event_tag,
          total_event
        }) => ({
          session_default_channel_group,
          session_source,
          session_medium,
          objective,
          platform,
          event_name,
          event_tag,
          total_event
        }))

        let uniqueHeaders = getUniqueValuesFromArrofObj(alignedHeaders);
        let formattedHeaders = []
        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: getHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setGaConversionSummaryTablePageData(res?.data?.data?.page ?? {});
        setGaConversionSummary(allData ?? []);

        let formattedRow = res?.data?.row_summary[0]?.total_event_count ?? 0;
        let rowSummaryData = res.data.row_summary ? [{ total_event: formattedRow }] : [];

        setGaConversionSummaryDataTotal(rowSummaryData)
        setGaConversionSummaryTableHeaders(formattedHeaders ?? [])
        setGaConversionSummaryIsLoading(false);
        setGaConversionSummaryIsLoaded(true);
        setGaConversionSummaryError(null);
      } catch (error) {
        setGaConversionSummary([]);
        setGaConversionSummaryDataTotal([])
        setGaConversionSummaryTableHeaders([])
        setGaConversionSummaryIsLoading(false);
        setGaConversionSummaryIsLoaded(false);
        setGaConversionSummaryError(error.code);
      }
    };

    isGa4SectionActive && fetchGaConversionSummaryHandler()
  }, [
    // BRAND_ID,
    // selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    gaConversionSummaryConfig
  ])




  let gaConversionSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              GA Summary
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isGaConversionSummaryLoaded && !isGaConversionSummaryLoading) {

    gaConversionSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle="GA Summary"
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            showTotalRow={gaConversionSummaryDataTotal}
            tableHeader={gaConversionSummaryTableHeaders}
            tableData={gaConversionSummary}
            tableLength={gaConversionSummaryConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Channel"}
            tableConfigs={gaConversionSummaryConfig}
            setTableConfigs={setGaConversionSummaryConfig}
            tablePaginationData={gaConversionSummaryTablePageData}
            removeImgExtensions={true}
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"search_campaign_section"}
                sectionName={`TableDynaLabels_GA Conversion Table`}
                sectionData={concateTotals(gaConversionSummaryExportData, gaConversionSummaryDataTotal)}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (gaConversionSummaryerror) {
    gaConversionSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                GA Summary
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={gaConversionSummaryerror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isGaConversionSummaryLoading) {
    gaConversionSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                GA Summary
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }






  var summaryTableExport = {
    [`TableDynaLabels_Summary Table _${":" + selectedCampaignSummaryDropdownOption.label}`]: campaignSummaryExportData,
  };

  var performanceSourceCSV = {
    [`CampPerfSourceDynLabels_Performance Source_${selectedMidSectionDropdownOption.label}`]: performanceBySource,
  };

  let gaConversionCSV = {
    [`DonutWithActiveDropdown_GA Donut_${ga4GlobalDropdownSelectedvalue.label + ":" + gaDonutDropdownSelectedValue.label}`]: gaConversionDonut,
    [`DonutWithNonActiveDropdown_Top 10 Events_${":" + ga4GlobalDropdownSelectedvalue.label + formatSlice}`]: top10EventData

  }

  var allData = {};


  if (isGa4SectionActive) {
    allData = {
      ...topCardData,
      "SummaryCard_Summary Cards": metricCards,
      "CampaignObjectivePie_Objective Wise Distribution":
        campaignObjectivewiseDistribution,
      ...performanceSourceCSV,
      ...gaConversionCSV,
      [`Ga4Country_By Country_${ga4GlobalDropdownSelectedvalue.label + formatSlice}`]: countryTableCountExportData,
      [`Ga4Region_By Region_${ga4GlobalDropdownSelectedvalue.label + formatSlice}`]: regionCountExportData,
      "TableDynaLabels_GA Conversion Table": concateTotals(gaConversionSummaryExportData, gaConversionSummaryDataTotal),
      ...summaryTableExport,
    };
  } else {
    allData = {
      ...topCardData,
      "SummaryCard_Summary Cards": metricCards,
      "CampaignObjectivePie_Objective Wise Distribution":
        campaignObjectivewiseDistribution,
      ...performanceSourceCSV,
      ...summaryTableExport,
    };
  }


  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    campaignObjectivewiseDistribution,
    performanceBySource,
    performanceBySourceDetails,
    campaignSummary,
    campaignSummaryExportData,

    gaConversionDonut,
    top10EventData,
    countryTableCountExportData,
    regionCountExportData,
    gaConversionSummaryExportData,
  ]);

  useEffect(() => {

    if (isGa4SectionActive) {
      if (
        (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
        (campaignObjectivewiseDistributionerror !== null ? true : isContentAnalysisLoaded && (isContentAnalysisLoading === false)) &&

        (gaConversionError !== null ? true : gaConversionDonutIsLoaded && (gaConversionDonutIsLoading === false)) &&
        (top10EventError !== null ? true : top10EventIsLoaded && (top10EventIsLoading === false)) &&
        (regionCounterror !== null ? true : isRegionCountLoaded && (isRegionCountLoading === false)) &&
        (countryTableCounterror !== null ? true : isCountryTableCountLoaded && (isCountryTableCountLoading === false)) &&
        (gaConversionSummaryerror !== null ? true : isGaConversionSummaryLoaded && (isGaConversionSummaryLoading === false)) &&


        (performanceBySourceerror !== null ? true : isPerformanceBySourceLoaded && (isPerformanceBySourceLoading === false)) &&
        (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
      ) {
        setIsExportDisabled(false)
      } else {
        setIsExportDisabled(true)
      }
    } else {

      if (
        (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
        (campaignObjectivewiseDistributionerror !== null ? true : isContentAnalysisLoaded && (isContentAnalysisLoading === false)) &&
        (performanceBySourceerror !== null ? true : isPerformanceBySourceLoaded && (isPerformanceBySourceLoading === false)) &&
        (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
      ) {
        setIsExportDisabled(false)
      } else {
        setIsExportDisabled(true)
      }


    }



  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    campaignObjectivewiseDistributionerror, isContentAnalysisLoaded, isContentAnalysisLoading,
    performanceBySourceerror, isPerformanceBySourceLoaded, isPerformanceBySourceLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
    gaConversionError, gaConversionDonutIsLoaded, gaConversionDonutIsLoading,
    top10EventError, top10EventIsLoaded, top10EventIsLoading,
    regionCounterror, isRegionCountLoaded, isRegionCountLoading,
    countryTableCounterror, isCountryTableCountLoaded, isCountryTableCountLoading,
    gaConversionSummaryerror, isGaConversionSummaryLoaded, isGaConversionSummaryLoading,
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Objective Wise Distribution
              </h2>
              <SectionDefinationsBadge
                title={"Objective Wise Distribution"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <Wrapper>
                <ExportDropdown
                  sectionId={"campaign_objective_section"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={
                    "CampaignObjectivePie_Objective Wise distribution"
                  }
                  sectionData={campaignObjectivewiseDistribution}
                />
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper p_t_0 p_b_0">
              {campaignObjectivewiseDistributionPie}
            </div>
          </div>
        </div>
      </div>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">
              {" "}
              <h2 className="section_card_title">Performance By KPI</h2>{" "}
              <SectionDefinationsBadge
                title={"Performance By KPI"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <ErrorBoundary>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={midSectionDropdonwOptions}
                className="form_dropdown section_dropdown"
                value={selectedMidSectionDropdownOption}
                selectedOptions={selectedMidSectionDropdownOption}
                setStatedropdown={midSectionDropdownSelectionHandler}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
      <div
        id="performance_source_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Performance By Source : {selectedMidSectionDropdownOption.label}
              </h2>
              <SectionDefinationsBadge
                title={"Performance By Source:" + selectedMidSectionDropdownOption.label}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <Modal
              open={isperformanceBySourceOpen}
              setOpen={setIsPerformanceBySourceOpen}
              exportDropdown={
                <ExportDropdown
                  sectionId={"performance_by_source_2"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={"Performance By Source"}
                  sectionData={performanceSourceCSV}
                />}
              parentId={"performance_source_section"}
              title={`Performance By Source : ${selectedMidSectionDropdownOption.label}`}
              chart={performanceBySourceChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsPerformanceBySourceOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Wrapper>
                <ExportDropdown
                  sectionId={"performance_source_section"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={"Performance By Source"}
                  sectionData={performanceSourceCSV}
                />
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {performanceBySourceChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="chart_details_wrapper">
                {performanceBySourceDetailsData}
              </div>
            </div>
          </div>
        </div>
      </div>


      {isGa4SectionActive &&
        <Wrapper>
          <div className="section_card section_dropdown_header">
            <div className="grid grid_cols_1">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">GA Conversion</h2>
                  <SectionDefinationsBadge
                    title={"GA Conversion"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>
                <ErrorBoundary>
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={ga4GlobalDropdown}
                    className="form_dropdown section_dropdown"
                    value={ga4GlobalDropdownSelectedvalue}
                    selectedOptions={ga4GlobalDropdownSelectedvalue}
                    setStatedropdown={GA4GlobalDropdownSelectionHandler}
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
          <div id="campaign_objective_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">

            <div className="grid grid_cols_4">

              <div className="grid col_span_2 flex column">
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">

                    </h2>
                  </div>
                  <div data-html2canvas-ignore={true}>
                    <Dropdown
                      ismulti={false}
                      placeholder={"Select"}
                      options={gaDonutDropdownOptions}
                      className="form_dropdown section_dropdown"
                      value={gaDonutDropdownSelectedValue}
                      selectedOptions={gaDonutDropdownSelectedValue}
                      setStatedropdown={GaDonutDropdownSelectionHandler}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1  section_card_body">
                  <div className="section_card_chart_wrapper p_t_0 p_b_0">
                    {gaConversionDonutSection}
                  </div>
                </div>
              </div>
              <div className="grid col_span_2 flex column ">
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Top 10 Events : {ga4GlobalDropdownSelectedvalue.label + formatSlice}
                    </h2>
                    <SectionDefinationsBadge
                      title={"Top 10 Events"}
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pagePrimaryTab}
                    />
                  </div>
                  <div data-html2canvas-ignore={true}>
                    <Wrapper>
                      <ExportDropdown
                        sectionId={"campaign_objective_section"}
                        moduleName={pageModule}
                        subModuleName={pageSubModule}
                        platform={pagePlatform}
                        tab={pagePrimaryTab}
                        sectionName={"GA Conversion"}
                        sectionData={gaConversionCSV}
                      />
                    </Wrapper>
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper p_t_0 p_b_0">
                    {top10EventsSection}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </Wrapper>
      }


      {isGa4SectionActive && <div id="audience_distribution_place" className="grid grid_cols_1 grid_margin_bottom">
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 flex column section_card">
            {countryTableCountSection}
          </div>
          <div className="grid col_span_2 flex column section_card">
            {regionCountTable}
          </div>
        </div>
      </div>}


      {isGa4SectionActive && <div className="grid_margin_bottom">
        {gaConversionSummaryTable}
      </div>}

      {campaignSummaryTable}
    </Wrapper>
  );
};

export const CampaignOverallOverallCoke = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)


  let BRAND_ID = null;
  if (
    globalSelectedBrandIds.length === 0 ||
    globalSelectedBrandIds === undefined
  ) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedBuckets
  );

  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );


  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (
    selectedCampaignPlatformsFromFilter.length === 0 ||
    selectedCampaignPlatformsFromFilter === undefined
  ) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (
    selectedCampaignObjectivesFromFilter.length === 0 ||
    selectedCampaignObjectivesFromFilter === undefined
  ) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (
    selectedCampaignsFromFilter.length === 0 ||
    selectedCampaignsFromFilter === undefined
  ) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }


  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);

  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence";
  let pageSubModule = "Campaign";
  let pagePlatform = "Overall";
  let pagePrimaryTab = "Overall";

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // OBJECTIVE WISE DISTRIBUTION
  const [campaignObjectivewiseDistribution, setCampaignObjectivewiseDistribution] = useState([]);
  const [campaignObjectivewiseDistributionDataLabels, setCampaignObjectivewiseDistributionDataLabels] = useState([]);
  const [isContentAnalysisLoading, setCampaignObjectivewiseDistributionIsLoading] = useState(false);
  const [isContentAnalysisLoaded, setCampaignObjectivewiseDistributionIsLoaded] = useState(false);
  const [campaignObjectivewiseDistributionerror, setCampaignObjectivewiseDistributionError] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "spends", label: "Spends" },
    { value: "leads", label: "Leads" },
    { value: "app_install", label: "App Install" },
  ];

  // DROPDOWN DEFAULT STATE
  const [selectedMidSectionDropdownOption, setSelectedMidSectionDropdownDropdownOption] = useState(midSectionDropdonwOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Overall",
      "Overall",
      null,
      "Performance By KPI",
      e.label
    );
  };

  // PERFORMANCE BY SOURCE
  const [performanceBySource, setPerformanceBySource] = useState([]);
  const [
    performanceBySourceAllDropdownsData,
    setPerformanceBySourceAllDropdownsData,
  ] = useState([]);
  const [performanceBySourceDataLabels, setPerformanceBySourceDataLabels] =
    useState([]);
  const [
    performanceBySourceAllDropdownsLabels,
    setPerformanceBySourceAllDropdownsLabels,
  ] = useState([]);
  const [isPerformanceBySourceLoading, setPerformanceBySourceIsLoading] =
    useState(false);
  const [isPerformanceBySourceLoaded, setPerformanceBySourceIsLoaded] =
    useState(false);
  const [performanceBySourceerror, setPerformanceBySourceError] =
    useState(null);

  // PERFORMANCE BY SOURCE DETAILS
  const [performanceBySourceDetails, setPerformanceBySourceDetails] = useState(
    []
  );
  const [
    performanceBySourceDetailsAllDropdownsData,
    setPerformanceBySourceDetailsAllDropdownsData,
  ] = useState([]);
  const [performanceBySourceDetailsTotal, setPerformanceBySourceDetailsTotal] =
    useState([]);
  const [
    performanceBySourceDetailsTotalAllDropdownsData,
    setPerformanceBySourceDetailsTotalAllDropdownsData,
  ] = useState([]);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([]);
  const [campaignSummaryExportData, setCampaignSummaryExportData] = useState([]);

  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);
  const [campaignSummaryTablePageData, setCampaignSummaryTablePageData] = useState({})

  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad Group" },
  ];

  const [campaignSummaryConfig, setCampaignSummaryConfig] = useState({ page_num: 1, page_size: 10, order_type: "desc", order_by: "clicks", search_value: null, dropdown_value: campaignSummaryOptions[0].value })


  // CAMPAIGN OVERVIEW DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState(campaignSummaryOptions[0]);

  // TABLE HEADERS FOR COKE
  let cokeSummaryTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Platform", field: "platform" },
    { name: "Objective", field: "objective" },
    // { name: "Impressions", field: "impressions" },
    { name: "Engagements", field: "total_engagement" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engagement_rate" },
    // { name: "Clicks / Conversion", field: "click_conversion" },
  ];

  let defaultTableHeaders = [
    { name: `${selectedCampaignSummaryDropdownOption.label + " Name"}`, field: "campaign_name" },
    { name: "Platform", field: "platform" },
    { name: "Objective", field: "objective" },
    // { name: "Engagements", field: "engagements" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Engagement Rate", field: "engagement_rate" },
  ];


  // CAMPAIGN OVERVIEW DROPDOWN
  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS


  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
      setCampaignSummaryConfig({ ...campaignSummaryConfig, dropdown_value: e.value, page_num: 1, search_value: null })
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Overall",
      "Overall",
      null,
      "Summary",
      e.label
    );
  };



  useEffect(() => {
    // if (companyID === COKE_COMPANY_ID) {
    //   setCampaignSummaryTableHeaders(cokeSummaryTableHeaders)
    // } else {
    //   setCampaignSummaryTableHeaders(defaultTableHeaders)
    // }
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
  ])

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.ad ?? []);

    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.ad_group ?? []);

    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.campaign ?? []);

    } else {
      setCampaignSummary([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
  ]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchCampaignObjectivewiseDistributionHandler();
    fetchPerformanceBySourceHandler();
    setSelectedCampaignSummaryDropdownOption(campaignSummaryOptions[0])
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
  ]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {

      setPerformanceBySource(performanceBySourceAllDropdownsData.clicks);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels.clicks);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData.clicks);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData.clicks.platform_total);

    } else if (selectedMidSectionDropdownOption.value === "conversions") {

      setPerformanceBySource(performanceBySourceAllDropdownsData.conversions);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels.conversions);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData.conversions);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData.conversions.platform_total);

    } else if (selectedMidSectionDropdownOption.value === "engagements") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.engagements ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.engagements ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.engagements ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.engagements?.platform_total ?? []);

    } else if (selectedMidSectionDropdownOption.value === "impressions") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.impressions ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.impressions ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.impressions ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.impressions?.platform_total ?? []);

    } else if (selectedMidSectionDropdownOption.value === "app_install") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.app_install ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.app_install ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.app_install ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.app_install?.platform_total ?? []);

    } else if (selectedMidSectionDropdownOption.value === "spends") {

      setPerformanceBySource(performanceBySourceAllDropdownsData?.spends ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.spends ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.spends ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.spends?.platform_total ?? []);

    } else if (selectedMidSectionDropdownOption.value === "leads") {
      setPerformanceBySource(performanceBySourceAllDropdownsData?.leads ?? []);
      setPerformanceBySourceDataLabels(performanceBySourceAllDropdownsLabels?.leads ?? []);
      setPerformanceBySourceDetails(performanceBySourceDetailsAllDropdownsData?.leads ?? []);
      setPerformanceBySourceDetailsTotal(performanceBySourceDetailsTotalAllDropdownsData?.leads?.platform_total ?? []);
    } else {
      setPerformanceBySource([]);
      setPerformanceBySourceDataLabels([]);
      setPerformanceBySourceDetails([]);
      setPerformanceBySourceDetailsTotal([]);
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedMidSectionDropdownOption,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
  ]);

  // PLANNED VS DELIVERED TABLE
  /* 
    const plannedVsDeliveredTableHeaders = [
      { name: "Campaign Name", field: "campaign_names" },
      { name: "Start date", field: "start_date" },
      { name: "End date", field: "end_date" },
  
      { name: "Market/TG", field: "type" },
      { name: "Markets(State)", field: "region" }, 
  
      { name: "Planned Reach", field: "planned_reach" },
      { name: "Delivered Reach", field: "delivered_reach" },
      { name: "% achieved", field: "achieved_reach" },
  
      { name: "Planned Impressions", field: "planned_impressions" },
      { name: "Delivered Impressions", field: "delivered_impressions" },
      { name: "% achieved", field: "achieved_impressions" },
  
      { name: "Planned Frequency", field: "planned_frequency" },
      { name: "Delivered Frequency", field: "delivered_frequency" },
      { name: "% achieved", field: "achieved_frequency" },
  
      { name: "Planned CTR %", field: "planned_ctr" },
      { name: "Delivered CTR %", field: "delivered_ctr" },
      { name: "% achieved", field: "achieved_ctr" },
  
      { name: "Planned VTR %", field: "planned_vtr" },
      { name: "Delivered VTR %", field: "delivered_vtr" },
      { name: "% achieved", field: "achieved_vtr" },
  
      { name: "Planned Spends", field: "planned_spends" },
      { name: "Delivered Spends", field: "delivered_vtr" },
      { name: "% achieved", field: "achieved_spend" },
    ]; */

  const PlannedVsDeliveredTabOptions = ["Meta", "DV360"];
  //const PlannedVsDeliveredTabOptions = ["Meta"];
  const [selectedPlannedVsDeliveredTab, setselectedPlannedVsDeliveredTab] =
    useState(PlannedVsDeliveredTabOptions[0]);
  const [unCheck, setUnCheck] = useState([false]);
  const tabsOnClickHandler = (event) => {
    setselectedPlannedVsDeliveredTab(event);
    setUnCheck(!unCheck);
  };

  // PLANNED VS DELIVERED METRICS DATA
  const [plannedVsDeliveredMetricsData, setPlannedVsDeliveredMetricsData] = useState([]);
  const [plannedVsDeliveredMetricsDataLabels, setPlannedVsDeliveredMetricsDataLabels] = useState([]);

  const [isplannedVsDeliveredMetricsLoading, setPlannedVsDeliveredMetricsIsLoading] = useState(false);
  const [isplannedVsDeliveredMetricsLoaded, setPlannedVsDeliveredMetricsIsLoaded] = useState(false);
  const [plannedVsDeliveredMetricsError, setPlannedVsDeliveredMetricsError] = useState(null);

  const [plannedVsDeliveredTableData, setPlannedVsDeliveredTableData] = useState([]);
  const [plannedVsDeliveredTableDataTotal, setPlannedVsDeliveredTableDataTotal] = useState([]);
  const [plannedVsDeliveredTableDataHeaders, setPlannedVsDeliveredTableDataHaeders] = useState([]);
  const [isPlannedVsDeliveredTableDataLoading, setPlannedVsDeliveredTableDataIsLoading] = useState(false);
  const [isPlannedVsDeliveredTableDataLoaded, setPlannedVsDeliveredTableDataIsLoaded] = useState(false);
  const [plannedVsDeliveredTableDataerror, setPlannedVsDeliveredTableDataError] = useState(null);

  let dropdownOptionsMarketTg = [
    { value: "market", label: "Market" },
    { value: "tg", label: "TG" },
  ];

  let tgDropdownOptions = [
    { value: "18-34 male female", label: "18-34 Male / Female" },
    // { value: "18-64 male female", label: "18-64 Male / Female" },
  ];

  // DROPDOWN STATE
  const [selectedMarketTgDropdownOption, setSelectedMarketTgDropdownOption] =
    useState(dropdownOptionsMarketTg[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMainSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMarketTgDropdownOption("");
    } else {
      setSelectedMarketTgDropdownOption(e);
    }
  };

  // PLANNED VS DELIVERED METRICS DATA
  const [plannedVsDeliveredMetricsDataDv, setPlannedVsDeliveredMetricsDataDv] =
    useState([]);
  const [
    plannedVsDeliveredMetricsDataLabelsDv,
    setPlannedVsDeliveredMetricsDataLabelsDv,
  ] = useState([]);

  const [
    isplannedVsDeliveredMetricsLoadingDv,
    setPlannedVsDeliveredMetricsIsLoadingDv,
  ] = useState(false);
  const [
    isplannedVsDeliveredMetricsLoadedDv,
    setPlannedVsDeliveredMetricsIsLoadedDv,
  ] = useState(false);
  const [
    plannedVsDeliveredMetricsErrorDv,
    setPlannedVsDeliveredMetricsErrorDv,
  ] = useState(null);

  const [plannedVsDeliveredTableDataDv, setPlannedVsDeliveredTableDataDv] = useState([]);
  const [plannedVsDeliveredTableDataTotalDv, setPlannedVsDeliveredTableDataTotalDv] = useState([]);
  const [plannedVsDeliveredTableDataHeadersDv, setPlannedVsDeliveredTableDataHaedersDv,] = useState([]);
  const [isPlannedVsDeliveredTableDataLoadingDv, setPlannedVsDeliveredTableDataIsLoadingDv,] = useState(false);
  const [
    isPlannedVsDeliveredTableDataLoadedDv,
    setPlannedVsDeliveredTableDataIsLoadedDv,
  ] = useState(false);
  const [
    plannedVsDeliveredTableDataerrorDv,
    setPlannedVsDeliveredTableDataErrorDv,
  ] = useState(null);

  let dropdownOptionsMarketTgDv = [
    { value: "market", label: "Market" },
    { value: "tg", label: "TG" },
  ];




  // DROPDOWN STATE
  const [
    selectedMarketTgDropdownOptionDv,
    setSelectedMarketTgDropdownOptionDv,
  ] = useState(dropdownOptionsMarketTgDv[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMainSelectionHandlerDv = (e) => {
    if (e === null) {
      setSelectedMarketTgDropdownOptionDv("");
    } else {
      setSelectedMarketTgDropdownOptionDv(e);
    }
  };

  const [regionDropdownOptionsOnLoad, setRegionDropdownOptionsOnLoad] =
    useState([]);
  const [regionDropdownOptions, setRegionDropdownOptions] = useState([]);
  const [regionDropdownOptionsIsLoading, setRegionDropdownOptionsIsLoading] =
    useState(true);
  const [regionDropdownOptionsIsLoaded, setRegionDropdownOptionsIsLoaded] =
    useState(false);

  const [regionDropdownOptionsOnLoadDv, setRegionDropdownOptionsOnLoadDv] =
    useState([]);
  const [regionDropdownOptionsDv, setRegionDropdownOptionsDv] = useState([]);
  const [
    regionDropdownOptionsIsLoadingDv,
    setRegionDropdownOptionsIsLoadingDv,
  ] = useState(true);
  const [regionDropdownOptionsIsLoadedDv, setRegionDropdownOptionsIsLoadedDv] =
    useState(false);

  const fetchRegionDropdownHandler = async () => {
    setRegionDropdownOptionsIsLoading(true);
    setRegionDropdownOptionsIsLoaded(false);

    let getDropdownType = selectedMarketTgDropdownOption.value === 'tg' ? 'tg' : 'market';
    let getIsTg = selectedMarketTgDropdownOption.value === 'tg' ? true : false;

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        drop_down_type: getDropdownType,
        is_tg: getIsTg
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-dropdown/",
        config
      );

      const regionsDDOpt = res?.data.map((item) => ({
        label: item.planned_label,
        value: item.mapping_id
      }))

      setRegionDropdownOptions(regionsDDOpt.length !== 0 ? regionsDDOpt : []);
      setRegionDropdownOptionsOnLoad(regionsDDOpt ?? []);
      setSelectedRegionDropdownOption(regionsDDOpt[0] ?? []);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(true);

    } catch (error) {
      setRegionDropdownOptions([]);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(false);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);

      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  const fetchRegionDropdownHandlerDv = async () => {
    setRegionDropdownOptionsIsLoadingDv(true);
    setRegionDropdownOptionsIsLoadedDv(false);

    let getDropdownType = selectedMarketTgDropdownOptionDv.value === 'tg' ? 'tg' : 'market';
    let getIsTg = selectedMarketTgDropdownOptionDv.value === 'tg' ? true : false;

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        drop_down_type: getDropdownType,
        is_tg: getIsTg
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-dropdown/",
        config
      );

      const regionsDDOpt = res?.data.map((item) => ({
        label: item.planned_label,
        value: item.mapping_id
      }))



      setRegionDropdownOptionsDv(regionsDDOpt ?? []);
      setRegionDropdownOptionsOnLoadDv(regionsDDOpt ?? []);
      setSelectedRegionDropdownOptionDv(regionsDDOpt[0] ?? []);
      setRegionDropdownOptionsIsLoadingDv(false);
      setRegionDropdownOptionsIsLoadedDv(true);
    } catch (error) {
      setRegionDropdownOptionsDv([]);
      setRegionDropdownOptionsIsLoadingDv(false);
      setRegionDropdownOptionsIsLoadedDv(false);

      setPlannedVsDeliveredMetricsIsLoadingDv(false);
      setPlannedVsDeliveredMetricsIsLoadedDv(false);
      setPlannedVsDeliveredMetricsErrorDv(error.code);

      setPlannedVsDeliveredTableDataIsLoadingDv(false);
      setPlannedVsDeliveredTableDataIsLoadedDv(false);
      setPlannedVsDeliveredTableDataErrorDv(error.code);
    }
  };

  useEffect(() => {
    fetchRegionDropdownHandler();
  }, [
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
    selectedMarketTgDropdownOption
  ]);

  useEffect(() => {
    fetchRegionDropdownHandlerDv();
  }, [
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
    selectedMarketTgDropdownOptionDv
  ]);


  // DROPDOWN STATE
  const [selectedRegionDropdownOption, setSelectedRegionDropdownOption] =
    useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownRegionSelectionHandler = (e) => {
    if (e === null) {
      setSelectedRegionDropdownOption("");
    } else {
      setSelectedRegionDropdownOption(e);
    }

    IAEvent_Dropdown_Visit(
      pageModule,
      pageSubModule,
      pagePlatform,
      pagePrimaryTab,
      null,
      "PlannedVsDelivered",
      e.label
    );
  };

  // DROPDOWN STATE

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  // const fetchMarketTgDropdownHandler = () => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   } else {
  //     setRegionDropdownOptionsOnLoad([]);
  //   }
  // };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  // useEffect(() => {
  //   fetchMarketTgDropdownHandler();
  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform,
  //   selectedMarketTgDropdownOption
  // ]);

  // useEffect(() => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   }
  // }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOption]);



  // DROPDOWN STATE
  const [selectedRegionDropdownOptionDv, setSelectedRegionDropdownOptionDv] =
    useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownRegionSelectionHandlerDv = (e) => {
    if (e === null) {
      setSelectedRegionDropdownOptionDv("");
    } else {
      setSelectedRegionDropdownOptionDv(e);
    }

    IAEvent_Dropdown_Visit(
      pageModule,
      pageSubModule,
      pagePlatform,
      pagePrimaryTab,
      null,
      "PlannedVsDelivered",
      e.label
    );
  };

  // DROPDOWN STATE

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  // const fetchMarketTgDropdownHandlerDv = () => {
  //   if (selectedMarketTgDropdownOptionDv.value === "market") {
  //     setRegionDropdownOptionsOnLoadDv(regionDropdownOptionsDv);
  //     setSelectedRegionDropdownOptionDv(regionDropdownOptionsDv[0]);
  //   } else if (selectedMarketTgDropdownOptionDv.value === "tg") {
  //     setRegionDropdownOptionsOnLoadDv(tgDropdownOptions);
  //     setSelectedRegionDropdownOptionDv(tgDropdownOptions[0]);
  //   } else {
  //     setRegionDropdownOptionsOnLoadDv([]);
  //   }
  // };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  // useEffect(() => {
  //   fetchMarketTgDropdownHandlerDv();
  // }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOptionDv]);
  // useEffect(() => {
  //   if (selectedMarketTgDropdownOptionDv.value === "market") {
  //     setRegionDropdownOptionsOnLoadDv(regionDropdownOptionsDv);

  //   } else if (selectedMarketTgDropdownOptionDv.value === "tg") {
  //     setRegionDropdownOptionsOnLoadDv(tgDropdownOptions);
  //     setSelectedRegionDropdownOptionDv(tgDropdownOptions[0]);
  //   }
  // }, [selectedMarketTgDropdownOptionDv]);

  useEffect(() => {
    if (!regionDropdownOptionsIsLoading && regionDropdownOptionsIsLoaded) {
      fetchPlannedVsDeliveredMetricsHandler();
      fetchPlannedVsDeliveredTableDataHandler();
    }
  }, [
    regionDropdownOptionsIsLoaded,
    regionDropdownOptionsIsLoading,
    selectedRegionDropdownOption
  ]);


  useEffect(() => {
    if (!regionDropdownOptionsIsLoadingDv && regionDropdownOptionsIsLoadedDv) {
      fetchPlannedVsDeliveredMetricsHandlerDv();
      fetchPlannedVsDeliveredTableDataHandlerDv();
    }
  }, [
    regionDropdownOptionsIsLoadedDv,
    regionDropdownOptionsIsLoadingDv,
    selectedRegionDropdownOptionDv
  ]);

  // useEffect(() => {
  // setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  // setSelectedRegionDropdownOption(regionDropdownOptions[0]);

  // setSelectedMarketTgDropdownOptionDv({ value: "market", label: "Market" });
  // setSelectedRegionDropdownOptionDv(regionDropdownOptionsDv[0]);
  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform
  // ]);

  // useEffect(() => {

  //   setSelectedMarketTgDropdownOptionDv({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOptionDv(regionDropdownOptionsDv[0]);
  // }, [
  //   BRAND_ID,
  //   selectedDates,
  // ]);



  // useEffect(() => {
  //   setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   if (selectedMarketTgDropdownOption?.value === "market" && selectedRegionDropdownOption?.value === "India") {
  //     fetchPlannedVsDeliveredMetricsHandler();
  //     fetchPlannedVsDeliveredTableDataHandler();
  //   }
  // }, [
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform
  // ]);


  // useEffect(() => {
  //   setSelectedMarketTgDropdownOptionDv({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOptionDv(regionDropdownOptions[0]);
  //   if (selectedMarketTgDropdownOptionDv?.value === "market" && selectedRegionDropdownOptionDv?.value === "India") {
  //     fetchPlannedVsDeliveredMetricsHandlerDv();
  //     fetchPlannedVsDeliveredTableDataHandlerDv();
  //   }
  // }, [
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform]);



  /* 
    useEffect(() => {
    if(selectedPlannedVsDeliveredTab === "Meta"){
      setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
      setSelectedRegionDropdownOption({ value: "India", label: "India" });
    }
    if(selectedPlannedVsDeliveredTab === "DV360"){
      setSelectedMarketTgDropdownOptionDv({ value: "market", label: "Market" });
      setSelectedRegionDropdownOptionDv({ value: "India", label: "India" });
    }
  }, [selectedPlannedVsDeliveredTab]);
 */

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/ad-metric/", config);
      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">
                <h2 className="section_card_title">Summary</h2>
              </div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="overall"
                    section="overall"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchCampaignObjectivewiseDistributionHandler = async () => {
    setCampaignObjectivewiseDistributionIsLoading(true);
    setCampaignObjectivewiseDistributionIsLoaded(false);
    setCampaignObjectivewiseDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/objective-distribution/",
        config
      );

      setCampaignObjectivewiseDistribution(res?.data[0]?.total_campaign?.data ?? []);
      setCampaignObjectivewiseDistributionDataLabels(res?.data[0]?.total_campaign?.label ?? []);

      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(true);
      setCampaignObjectivewiseDistributionError(null);
    } catch (error) {
      setCampaignObjectivewiseDistribution([]);
      setCampaignObjectivewiseDistributionDataLabels([]);
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(false);
      setCampaignObjectivewiseDistributionError(error.code);
    }
  };

  // DATA HANDLING
  let campaignObjectivewiseDistributionPie = (
    <Wrapper>
      <Loader loaderType="pieChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (
      NoDataAvailableChecker(campaignObjectivewiseDistribution) ||
      NoDataAvailableChecker(campaignObjectivewiseDistributionDataLabels)
    ) {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <NoDataAvailableLoader chartType="pieChartType" />
        </Wrapper>
      );
    } else {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={campaignObjectivewiseDistribution}
              chartLabels={campaignObjectivewiseDistributionDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignObjectivewiseDistributionerror) {
    campaignObjectivewiseDistributionPie = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={campaignObjectivewiseDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    campaignObjectivewiseDistributionPie = (
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE
  const [isperformanceBySourceOpen, setIsPerformanceBySourceOpen] = useState(false)

  let defaultPerformanceBySourceDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPerformanceBySourceDisabledvalues = []
  } else {
    defaultPerformanceBySourceDisabledvalues = ["Instagram", "DV 360"]
  }

  const [performanceBySourceDisabledvalues, setPerformanceBySourceDisabledvalues] = useState(defaultPerformanceBySourceDisabledvalues)

  // TO FETCH DATA FROM API
  const fetchPerformanceBySourceHandler = async () => {
    setPerformanceBySourceIsLoading(true);
    setPerformanceBySourceIsLoaded(false);
    setPerformanceBySourceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",

        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/metric-by-date/",
        config
      );

      setPerformanceBySource(res?.data?.graph_metrics?.data?.impressions ?? []);
      setPerformanceBySourceDataLabels(res?.data?.graph_metrics?.dimension?.impressions ?? []);
      setPerformanceBySourceDetails(res?.data?.side_metrics?.impressions ?? []);

      setPerformanceBySourceAllDropdownsData(res?.data?.graph_metrics?.data ?? []);
      setPerformanceBySourceAllDropdownsLabels(res?.data?.graph_metrics?.dimension ?? []);
      setPerformanceBySourceDetailsAllDropdownsData(res?.data?.side_metrics ?? []);

      setPerformanceBySourceDetailsTotal(res?.data?.side_metrics_total?.impressions?.platform_total ?? []);

      setPerformanceBySourceDetailsTotalAllDropdownsData(res?.data?.side_metrics_total ?? []);
      setPerformanceBySourceIsLoading(false);
      setPerformanceBySourceIsLoaded(true);
      setPerformanceBySourceError(null);
    } catch (error) {
      setPerformanceBySourceAllDropdownsData([]);
      setPerformanceBySourceAllDropdownsLabels([]);
      setPerformanceBySourceDetailsAllDropdownsData([]);
      setPerformanceBySourceDetailsTotalAllDropdownsData([]);
      setPerformanceBySourceIsLoading(false);
      setPerformanceBySourceIsLoaded(false);
      setPerformanceBySourceError(error.code);
    }
  };

  //  DATA HANDLING
  let performanceBySourceChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let performanceBySourceChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceBySourceLoaded && !isPerformanceBySourceLoading) {
    if (
      NoDataAvailableChecker(performanceBySource) ||
      NoDataAvailableChecker(performanceBySourceAllDropdownsLabels)
    ) {
      performanceBySourceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      performanceBySourceChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

    } else {

      if (isMultiLineCluster) {
        performanceBySourceChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="performance_by_source"
                chartClass="section_card_chart"
                chartData={performanceBySource}
                chartLabels={performanceBySourceDataLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={performanceBySourceDisabledvalues}
                setDisabledValues={setPerformanceBySourceDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        performanceBySourceChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={performanceBySource}
                chartId="performance_by_source_2"
                chartLabels={performanceBySourceDataLabels}
                showLegend={true}
                disabledLegendArray={performanceBySourceDisabledvalues}
                setDisabledValues={setPerformanceBySourceDisabledvalues}
                showLabels={true}

              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        performanceBySourceChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="performance_by_source"
                chartClass="section_card_chart"
                chartData={performanceBySource}
                chartLabels={performanceBySourceDataLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceBySourceerror) {
    performanceBySourceChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );

    performanceBySourceChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceBySourceLoading) {
    performanceBySourceChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    performanceBySourceChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE DETAILS

  // DATA HANDLING
  let performanceBySourceDetailsData = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceBySourceLoaded && !isPerformanceBySourceLoading) {
    if (NoDataAvailableChecker(performanceBySourceDetails)) {
      performanceBySourceDetailsData = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      performanceBySourceDetailsData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="main_wrapper">
              <div className="body">
                {performanceBySourceDetails &&
                  performanceBySourceDetails.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">{details.label}</div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter title={selectedMidSectionDropdownOption.label} number={details.value} />
                            </ErrorBoundary>
                            {details.metric && (
                              <div className="metrics_wrapper">
                                <div className="title_wrapper">
                                  {details.metric[0].label + " : "}
                                </div>
                                <div className="value_wrapper">
                                  <ErrorBoundary>
                                    <NumberFormatter
                                      title={selectedMidSectionDropdownOption.label}
                                      number={details.metric[0].value}
                                    />
                                  </ErrorBoundary>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Wrapper>
                    )
                  )}
              </div>
              <div className="footer">
                <div className="title_wrapper">Total</div>
                <div className="value_wrapper">
                  <NumberFormatter title={selectedMidSectionDropdownOption.label} number={performanceBySourceDetailsTotal} />
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceBySourceerror) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceBySourceLoading) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  useEffect(() => {

    const fetchCampaignSummaryHandler = async () => {


      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          dropdown_value: selectedCampaignSummaryDropdownOption?.value ?? '',
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig,
          page_size: -1,
          page_num: 1,

        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ad-summary/v2/", config);

        var allData = res?.data?.data?.results ?? [];

        // var headerKeys = campaignSummaryTableHeaders.map((x) => x.field)
        // var formattedData = allData.map((row) => {

        //   var newData = {};
        //   Object.keys(row).forEach((x, y) => {
        //     if (headerKeys.includes(x)) {
        //       newData[x] = row[x]
        //     }
        //   })
        //   return newData
        // })

        setCampaignSummaryExportData(allData ?? []);
      } catch (error) {
        setCampaignSummaryExportData([]);

      }
    };
    fetchCampaignSummaryHandler();

  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform, selectedCampaignSummaryDropdownOption, campaignSummaryConfig.dropdown_value])

  useEffect(() => {
    const fetchCampaignSummaryHandler = async () => {
      setCampaignSummaryIsLoading(true);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(null);

      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
          objective: selectedObjectives,
          platform_campaign: selectedIdandPlatform,
          platform: selectedPlatform,
          campaign: selectedCampaigns,
          campaign_tag:
            selectedBucketsFromFilter.length === 0 ||
              selectedBucketsFromFilter === undefined
              ? null
              : selectedBucketsFromFilter.join(","),
          ...campaignSummaryConfig
        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + authCtx.token,
        },
      };

      try {
        const res = await axios.get("performance/summary/ad-summary/v2/", config);

        let allData = res.data.data.results;

        let uniqueHeaders = getUniqueValuesFromArrofObj(allData)

        let formattedHeaders = []

        uniqueHeaders.map((header) => {
          formattedHeaders.push({
            name: campaignSummaryGetHeaderKeysWithLabels(header),
            field: header,
          })
        })

        setCampaignSummaryTablePageData(res?.data?.data?.page ?? {})

        setCampaignSummary(allData ?? []);
        setCampaignSummaryTableHeaders(formattedHeaders ?? [])

        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(true);
        setCampaignSummaryError(null);
      } catch (error) {
        setCampaignSummary([]);
        setCampaignSummaryTableHeaders([])
        setCampaignSummaryIsLoading(false);
        setCampaignSummaryIsLoaded(false);
        setCampaignSummaryError(error.code);
      }
    };

    fetchCampaignSummaryHandler();

  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    campaignSummaryConfig,
  ])



  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Summary
              <SectionDefinationsBadge
                title={"Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle="Summary"
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={campaignSummaryTableHeaders}
            tableData={campaignSummary}
            tableLength={campaignSummaryConfig?.page_size ?? 10}
            isFooterShow={true}
            searchPlaceHolder={"Search Campaign"}
            tableConfigs={campaignSummaryConfig}
            setTableConfigs={setCampaignSummaryConfig}
            tablePaginationData={campaignSummaryTablePageData}
            removeImgExtensions={true}
            tableDropdown={
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignSummaryOptions}
                className="form_dropdown section_dropdown"
                value={selectedCampaignSummaryDropdownOption}
                selectedOptions={selectedCampaignSummaryDropdownOption}
                setStatedropdown={campaignSummaryDropdownSelectionHandler}
              />
            }
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"search_campaign_section"}
                sectionName={`CampaignTableDynaLabels_Summary Table _${selectedCampaignSummaryDropdownOption.label}`}
                sectionData={campaignSummaryExportData}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={campaignSummaryerror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary
                <SectionDefinationsBadge
                  title={"Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLANNED VS METRICS

  // TO FETCH METRIC DATA FROM API
  const fetchPlannedVsDeliveredMetricsHandler = async () => {
    setPlannedVsDeliveredMetricsIsLoading(true);
    setPlannedVsDeliveredMetricsIsLoaded(false);
    setPlannedVsDeliveredMetricsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-chart/v3/summary",
        config
      );

      setPlannedVsDeliveredMetricsData(res?.data?.data ?? []);
      setPlannedVsDeliveredMetricsDataLabels(res?.data?.label ?? []);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(true);
      setPlannedVsDeliveredMetricsError(null);
    } catch (error) {
      setPlannedVsDeliveredMetricsData([]);
      setPlannedVsDeliveredMetricsDataLabels([]);
      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);
    }
  };

  let plannedVsDeliveredMetricsBarChart = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (
    isplannedVsDeliveredMetricsLoaded &&
    !isplannedVsDeliveredMetricsLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredMetricsData) ||
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabels)
    ) {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="plannedDeliveredBarChart"
              chartClass="section_card_chart"
              chartData={plannedVsDeliveredMetricsData}
              chartLabels={plannedVsDeliveredMetricsDataLabels}
              chartColorSet={BlueColorStart500}
              showLabels={true}
              minimumGridDistance={0}
              showLegend={false}
              percentageTooltip={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredMetricsError) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={plannedVsDeliveredMetricsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isplannedVsDeliveredMetricsLoading) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE

  // TO FETCH METRIC  FROM API
  const fetchPlannedVsDeliveredTableDataHandler = async () => {
    setPlannedVsDeliveredTableDataIsLoading(true);
    setPlannedVsDeliveredTableDataIsLoaded(false);
    setPlannedVsDeliveredTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-table/v3/summary",
        config
      );
      setPlannedVsDeliveredTableData(res?.data?.data ?? []);
      setPlannedVsDeliveredTableDataHaeders(res?.data?.label ?? []);
      setPlannedVsDeliveredTableDataTotal(res?.data?.row_summary ?? [])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(true);
      setPlannedVsDeliveredTableDataError(null);
    } catch (error) {
      setPlannedVsDeliveredTableData([]);
      setPlannedVsDeliveredTableDataHaeders([]);
      setPlannedVsDeliveredTableDataTotal([])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  let plannedVsDeliveredTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Campaign wise Planned v/s Delivered table
                {selectedMarketTgDropdownOption &&
                  " : " + selectedMarketTgDropdownOption.label}
                {selectedRegionDropdownOption &&
                  " : " + selectedRegionDropdownOption.label}

              </h2>
              <SectionDefinationsBadge
                title={"Campaign wise Planned v/s Delivered table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isPlannedVsDeliveredTableDataLoaded &&
    !isPlannedVsDeliveredTableDataLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredTableData) ||
      NoDataAvailableChecker(plannedVsDeliveredTableDataHeaders)
    ) {
      plannedVsDeliveredTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">
                    Campaign wise Planned v/s Delivered table
                    {selectedMarketTgDropdownOption &&
                      " : " + selectedMarketTgDropdownOption.label}
                    {selectedRegionDropdownOption &&
                      " : " + selectedRegionDropdownOption.label}

                  </h2>
                  <SectionDefinationsBadge
                    title={"Campaign wise Planned v/s Delivered table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      plannedVsDeliveredTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Campaign wise Planned v/s Delivered table" +
                `${selectedMarketTgDropdownOption &&
                " : " + selectedMarketTgDropdownOption.label
                }
            ${selectedRegionDropdownOption &&
                " : " + selectedRegionDropdownOption.label
                }`
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={plannedVsDeliveredTableDataHeaders}
              tableData={plannedVsDeliveredTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="planned_vs_delivered_table"
              showTotalRow={plannedVsDeliveredTableDataTotal}
              showFixedSpend={true}
              calculateTotal={true}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"planned_vs_delivered_table"}
                  sectionName={`TableDynaLabels_Meta Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
                    } ${selectedRegionDropdownOption !== undefined &&
                      selectedRegionDropdownOption !== null &&
                      selectedRegionDropdownOption !== ""
                      ? `: ` + selectedRegionDropdownOption.label
                      : ""
                    }`}
                  sectionData={concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal)}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredTableDataerror) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}

                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={plannedVsDeliveredTableDataerror}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlannedVsDeliveredTableDataLoading) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}

                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLANNED VS METRICS 2

  // TO FETCH METRIC DATA FROM API
  const fetchPlannedVsDeliveredMetricsHandlerDv = async () => {
    setPlannedVsDeliveredMetricsIsLoadingDv(true);
    setPlannedVsDeliveredMetricsIsLoadedDv(false);
    setPlannedVsDeliveredMetricsErrorDv(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOptionDv === undefined ||
            selectedMarketTgDropdownOptionDv === null ||
            selectedMarketTgDropdownOptionDv === ""
            ? ""
            : selectedMarketTgDropdownOptionDv.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOptionDv === undefined ||
            selectedRegionDropdownOptionDv === null ||
            selectedRegionDropdownOptionDv === ""
            ? ""
            : selectedRegionDropdownOptionDv.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/planned-delivered-chart/v3/dv360", config);

      setPlannedVsDeliveredMetricsDataDv(res?.data?.data ?? []);
      setPlannedVsDeliveredMetricsDataLabelsDv(res?.data?.label ?? []);

      setPlannedVsDeliveredMetricsIsLoadingDv(false);
      setPlannedVsDeliveredMetricsIsLoadedDv(true);
      setPlannedVsDeliveredMetricsErrorDv(null);
    } catch (error) {
      setPlannedVsDeliveredMetricsDataDv([]);
      setPlannedVsDeliveredMetricsDataLabelsDv([]);
      setPlannedVsDeliveredMetricsIsLoadingDv(false);
      setPlannedVsDeliveredMetricsIsLoadedDv(false);
      setPlannedVsDeliveredMetricsErrorDv(error.code);
    }
  };

  let plannedVsDeliveredMetricsBarChartDv = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (
    isplannedVsDeliveredMetricsLoadedDv &&
    !isplannedVsDeliveredMetricsLoadingDv
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataDv) ||
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabelsDv)
    ) {
      plannedVsDeliveredMetricsBarChartDv = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      plannedVsDeliveredMetricsBarChartDv = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="plannedDeliveredBarChart"
              chartClass="section_card_chart"
              chartData={plannedVsDeliveredMetricsDataDv}
              chartLabels={plannedVsDeliveredMetricsDataLabelsDv}
              chartColorSet={BlueColorStart500}
              showLabels={true}
              minimumGridDistance={0}
              showLegend={false}
              percentageTooltip={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredMetricsErrorDv) {
    plannedVsDeliveredMetricsBarChartDv = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={plannedVsDeliveredMetricsErrorDv}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isplannedVsDeliveredMetricsLoadingDv) {
    plannedVsDeliveredMetricsBarChartDv = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE

  // TO FETCH METRIC  FROM API
  const fetchPlannedVsDeliveredTableDataHandlerDv = async () => {
    setPlannedVsDeliveredTableDataIsLoadingDv(true);
    setPlannedVsDeliveredTableDataIsLoadedDv(false);
    setPlannedVsDeliveredTableDataErrorDv(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOptionDv === undefined ||
            selectedMarketTgDropdownOptionDv === null ||
            selectedMarketTgDropdownOptionDv === ""
            ? ""
            : selectedMarketTgDropdownOptionDv.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOptionDv === undefined ||
            selectedRegionDropdownOptionDv === null ||
            selectedRegionDropdownOptionDv === ""
            ? ""
            : selectedRegionDropdownOptionDv.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-table/v3/dv360",
        config
      );
      setPlannedVsDeliveredTableDataDv(res?.data?.data ?? []);
      setPlannedVsDeliveredTableDataHaedersDv(res?.data?.label ?? []);
      setPlannedVsDeliveredTableDataTotalDv(res?.data?.row_summary ?? [])
      setPlannedVsDeliveredTableDataIsLoadingDv(false);
      setPlannedVsDeliveredTableDataIsLoadedDv(true);
      setPlannedVsDeliveredTableDataErrorDv(null);
    } catch (error) {
      setPlannedVsDeliveredTableDataDv([]);
      setPlannedVsDeliveredTableDataTotalDv([])
      setPlannedVsDeliveredTableDataHaedersDv([]);
      setPlannedVsDeliveredTableDataIsLoadingDv(false);
      setPlannedVsDeliveredTableDataIsLoadedDv(false);
      setPlannedVsDeliveredTableDataErrorDv(error.code);
    }
  };

  let plannedVsDeliveredTableDv = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Campaign wise Planned v/s Delivered table
                {selectedMarketTgDropdownOption &&
                  " : " + selectedMarketTgDropdownOption.label}
                {selectedRegionDropdownOption &&
                  " : " + selectedRegionDropdownOption.label}

              </h2>
              <SectionDefinationsBadge
                title={"Campaign wise Planned v/s Delivered table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isPlannedVsDeliveredTableDataLoadedDv &&
    !isPlannedVsDeliveredTableDataLoadingDv
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredTableDataDv) ||
      NoDataAvailableChecker(plannedVsDeliveredTableDataHeadersDv)
    ) {
      plannedVsDeliveredTableDv = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">
                    Campaign wise Planned v/s Delivered table
                    {selectedMarketTgDropdownOption &&
                      " : " + selectedMarketTgDropdownOption.label}
                    {selectedRegionDropdownOption &&
                      " : " + selectedRegionDropdownOption.label}

                  </h2>
                  <SectionDefinationsBadge
                    title={"Campaign wise Planned v/s Delivered table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      plannedVsDeliveredTableDv = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Campaign wise Planned v/s Delivered table" +
                `${selectedMarketTgDropdownOptionDv &&
                " : " + selectedMarketTgDropdownOptionDv.label
                }
${selectedRegionDropdownOptionDv && " : " + selectedRegionDropdownOptionDv.label
                }`
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={plannedVsDeliveredTableDataHeadersDv}
              tableData={plannedVsDeliveredTableDataDv}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="planned_vs_delivered_table"
              showTotalRow={plannedVsDeliveredTableDataTotalDv}
              showFixedSpend={true}
              calculateTotal={true}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"planned_vs_delivered_table"}
                  sectionName={`TableDynaLabels_Meta Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
                    } ${selectedRegionDropdownOptionDv !== undefined &&
                      selectedRegionDropdownOptionDv !== null &&
                      selectedRegionDropdownOptionDv !== ""
                      ? `: ` + selectedRegionDropdownOptionDv.label
                      : ""
                    }`}
                  sectionData={concateTotals(plannedVsDeliveredTableDataDv, plannedVsDeliveredTableDataTotalDv)}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredTableDataerrorDv) {
    plannedVsDeliveredTableDv = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}

                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={plannedVsDeliveredTableDataerrorDv}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlannedVsDeliveredTableDataLoadingDv) {
    plannedVsDeliveredTableDv = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}

                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  var barChartCSV = {
    [`dvBar_Meta PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `: ` + selectedRegionDropdownOption.label
        : ""
      }`]: plannedVsDeliveredMetricsData,
  };

  var tableCSV = {
    [`TableDynaLabels_Meta PvsD Table_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `: ` + selectedRegionDropdownOption.label
        : ""
      }`]: concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal),
  };

  var barChartCSVDv = {
    [`dvBar_Dv PvsD BarChart_${` : ` + selectedMarketTgDropdownOptionDv.label
      } ${selectedRegionDropdownOptionDv !== undefined &&
        selectedRegionDropdownOptionDv !== null &&
        selectedRegionDropdownOptionDv !== ""
        ? `: ` + selectedRegionDropdownOptionDv.label
        : ""
      }`]: plannedVsDeliveredMetricsDataDv,
  };

  var tableCSVDv = {
    [`TableDynaLabels_Dv PvsD Table_${` : ` + selectedMarketTgDropdownOptionDv.label
      } ${selectedRegionDropdownOptionDv !== undefined &&
        selectedRegionDropdownOptionDv !== null &&
        selectedRegionDropdownOptionDv !== ""
        ? `: ` + selectedRegionDropdownOptionDv.label
        : ""
      }`]: concateTotals(plannedVsDeliveredTableDataDv, plannedVsDeliveredTableDataTotalDv),
  };

  var summaryTableExport = {
    [`CampaignTableDynaLabels_Summary Table _${selectedCampaignSummaryDropdownOption.label}`]: campaignSummaryExportData,
  };

  var performanceSourceCSV = {
    [`CampPerfSourceDynLabels_Performance Source_${selectedMidSectionDropdownOption.label}`]:
      performanceBySource,
    // "CampaignPerfSource_Performance Source Details": performanceBySourceDetails,
  };

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Cards": metricCards,
    "CampaignObjectivePie_Objective Wise Distribution":
      campaignObjectivewiseDistribution,
    ...performanceSourceCSV,
    ...summaryTableExport,
    ...barChartCSV,
    ...tableCSV,
    ...barChartCSVDv,
    ...tableCSVDv,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    campaignObjectivewiseDistribution,
    performanceBySource,
    performanceBySourceDetails,
    campaignSummary,
    plannedVsDeliveredMetricsData,
    plannedVsDeliveredTableData,
    plannedVsDeliveredMetricsDataDv,
    plannedVsDeliveredTableDataDv,
    campaignSummaryExportData,
    plannedVsDeliveredTableDataTotal,
    plannedVsDeliveredTableDataTotalDv
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (campaignObjectivewiseDistributionerror !== null ? true : isContentAnalysisLoaded && (isContentAnalysisLoading === false)) &&
      (performanceBySourceerror !== null ? true : isPerformanceBySourceLoaded && (isPerformanceBySourceLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false)) &&
      (plannedVsDeliveredMetricsError !== null ? true : isplannedVsDeliveredMetricsLoaded && (isplannedVsDeliveredMetricsLoading === false)) &&
      (plannedVsDeliveredTableDataerror !== null ? true : isPlannedVsDeliveredTableDataLoaded && (isPlannedVsDeliveredTableDataLoading === false)) &&
      (plannedVsDeliveredMetricsError !== null ? true : isplannedVsDeliveredMetricsLoaded && (isplannedVsDeliveredMetricsLoading === false)) &&
      (plannedVsDeliveredTableDataerrorDv !== null ? true : isPlannedVsDeliveredTableDataLoadedDv && (isPlannedVsDeliveredTableDataLoadingDv === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    campaignObjectivewiseDistributionerror, isContentAnalysisLoaded, isContentAnalysisLoading,
    performanceBySourceerror, isPerformanceBySourceLoaded, isPerformanceBySourceLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
    plannedVsDeliveredMetricsError, isplannedVsDeliveredMetricsLoaded, isplannedVsDeliveredMetricsLoading,
    plannedVsDeliveredTableDataerror, isPlannedVsDeliveredTableDataLoaded, isPlannedVsDeliveredTableDataLoading,
    plannedVsDeliveredMetricsError, isplannedVsDeliveredMetricsLoaded, isplannedVsDeliveredMetricsLoading,
    plannedVsDeliveredTableDataerrorDv, isPlannedVsDeliveredTableDataLoadedDv, isPlannedVsDeliveredTableDataLoadingDv,
  ])

  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Objective Wise Distribution
              </h2>
              <SectionDefinationsBadge
                title={"Objective Wise Distribution"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <Wrapper>
                <ExportDropdown
                  sectionId={"campaign_objective_section"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={
                    "CampaignObjectivePie_Objective Wise distribution"
                  }
                  sectionData={campaignObjectivewiseDistribution}
                />
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper p_t_0 p_b_0">
              {campaignObjectivewiseDistributionPie}
            </div>
          </div>
        </div>
      </div>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Performance By KPI</h2>
              <SectionDefinationsBadge
                title={"Performance By KPI"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <ErrorBoundary>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={midSectionDropdonwOptions}
                className="form_dropdown section_dropdown"
                value={selectedMidSectionDropdownOption}
                selectedOptions={selectedMidSectionDropdownOption}
                setStatedropdown={midSectionDropdownSelectionHandler}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
      <div id="performance_source_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Performance By Source : {selectedMidSectionDropdownOption.label}
              </h2>
              <SectionDefinationsBadge
                title={"Performance By Source:" + selectedMidSectionDropdownOption.label}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>

            <Modal
              open={isperformanceBySourceOpen}
              setOpen={setIsPerformanceBySourceOpen}
              exportDropdown={
                <ExportDropdown
                  sectionId={"performance_by_source_2"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={"Performance By Source"}
                  sectionData={performanceSourceCSV}
                />}
              parentId={"performance_source_section"}
              title={`Performance By Source: ${selectedMidSectionDropdownOption.label} `}
              chart={performanceBySourceChart2}
            />

            <div data-html2canvas-ignore={true}>

              {isMultiLineCluster && <button onClick={() => setIsPerformanceBySourceOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Wrapper>
                <ExportDropdown
                  sectionId={"performance_source_section"}
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionName={"Performance By Source"}
                  sectionData={performanceSourceCSV}
                />
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {performanceBySourceChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="chart_details_wrapper">
                {performanceBySourceDetailsData}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid_margin_bottom">{campaignSummaryTable}</div>

      <div className="grid grid_margin_bottom">
        <Tab
          varient={"primary"}
          tabData={PlannedVsDeliveredTabOptions}
          activeTab={selectedPlannedVsDeliveredTab}
          handleCallback={(event) => tabsOnClickHandler(event)}
        />
      </div>
      {selectedPlannedVsDeliveredTab === "Meta" ? (
        <Wrapper>
          <div className="section_card section_dropdown_header">
            <div className="grid grid_cols_1">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  {selectedPlannedVsDeliveredTab} Planned Vs Delivered
                </h2>
                <div data-html2canvas-ignore={true}>
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={dropdownOptionsMarketTg}
                    className="form_dropdown section_dropdown grid_margin_right"
                    value={selectedMarketTgDropdownOption}
                    selectedOptions={selectedMarketTgDropdownOption}
                    setStatedropdown={dropdownMainSelectionHandler}
                    secondaryTabName={selectedPlannedVsDeliveredTab}
                  />
                  <Dropdown
                    ismulti={false}
                    dropdownLoading={regionDropdownOptionsIsLoading}
                    placeholder={"Select"}
                    options={regionDropdownOptionsOnLoad}
                    className="form_dropdown section_dropdown"
                    value={selectedRegionDropdownOption}
                    selectedOptions={selectedRegionDropdownOption}
                    setStatedropdown={dropdownRegionSelectionHandler}
                    secondaryTabName={selectedPlannedVsDeliveredTab}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
            <div className="section_card_body">
              <div id="planned_vs_delivered_metrics_section">
                <div className="grid  section_card">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        Planned v/s Delivered Metrics Performance
                        {selectedMarketTgDropdownOption &&
                          " : " + selectedMarketTgDropdownOption.label}
                        {selectedRegionDropdownOption &&
                          " : " + selectedRegionDropdownOption.label}
                      </h2>
                      <SectionDefinationsBadge
                        title={"Planned vs Delivered Metrics Performance"}
                        module={pageSubModule}
                        platform={pagePlatform}
                        section={pagePrimaryTab}
                      />
                    </div>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}
                        subModuleName={pageSubModule}
                        secondaryTabName={selectedPlannedVsDeliveredTab}
                        sectionId={"planned_vs_delivered_metrics_section"}
                        sectionName={`dvBar_Meta PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
                          } ${selectedRegionDropdownOption !== undefined &&
                            selectedRegionDropdownOption !== null &&
                            selectedRegionDropdownOption !== ""
                            ? `: ` + selectedRegionDropdownOption.label
                            : ""
                          } `}
                        sectionData={barChartCSV}
                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {plannedVsDeliveredMetricsBarChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {plannedVsDeliveredTable}
        </Wrapper>
      ) : (
        <Wrapper>
          <div className="section_card section_dropdown_header">
            <div className="grid grid_cols_1">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  {selectedPlannedVsDeliveredTab} Planned Vs Delivered
                </h2>
                <div data-html2canvas-ignore={true}>
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={dropdownOptionsMarketTgDv}
                    className="form_dropdown section_dropdown grid_margin_right"
                    value={selectedMarketTgDropdownOptionDv}
                    selectedOptions={selectedMarketTgDropdownOptionDv}
                    setStatedropdown={dropdownMainSelectionHandlerDv}
                    secondaryTabName={selectedPlannedVsDeliveredTab}
                  />
                  <Dropdown
                    ismulti={false}
                    dropdownLoading={regionDropdownOptionsIsLoadingDv}
                    placeholder={"Select"}
                    options={regionDropdownOptionsOnLoadDv}
                    className="form_dropdown section_dropdown"
                    value={selectedRegionDropdownOptionDv}
                    selectedOptions={selectedRegionDropdownOptionDv}
                    setStatedropdown={dropdownRegionSelectionHandlerDv}
                    secondaryTabName={selectedPlannedVsDeliveredTab}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
            <div className="section_card_body">
              <div id="planned_vs_delivered_metrics_section">
                <div className="grid  section_card">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        Planned v/s Delivered Metrics Performance
                        {selectedMarketTgDropdownOptionDv &&
                          " : " + selectedMarketTgDropdownOptionDv.label}
                        {selectedRegionDropdownOptionDv &&
                          " : " + selectedRegionDropdownOptionDv.label}
                      </h2>
                      <SectionDefinationsBadge
                        title={"Planned vs Delivered Metrics Performance"}
                        module={pageSubModule}
                        platform={pagePlatform}
                        section={pagePrimaryTab}
                      />
                    </div>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}
                        subModuleName={pageSubModule}
                        secondaryTabName={selectedPlannedVsDeliveredTab}
                        sectionId={"planned_vs_delivered_metrics_section"}
                        sectionName={`dvBar_Dv PvsD BarChart_${` : ` + selectedMarketTgDropdownOptionDv.label
                          } ${selectedRegionDropdownOptionDv !== undefined &&
                            selectedRegionDropdownOptionDv !== null &&
                            selectedRegionDropdownOptionDv !== ""
                            ? `: ` + selectedRegionDropdownOptionDv.label
                            : ""
                          } `}
                        sectionData={barChartCSVDv}
                      />
                    </div>
                  </div>
                  <div className="grid grid_cols_1 section_card_body">
                    <div className="section_card_chart_wrapper">
                      {plannedVsDeliveredMetricsBarChartDv}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {plannedVsDeliveredTableDv}
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default CampaignOverallOverall;
