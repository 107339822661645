import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../store/authContext";
import FullPageExportContext from "../../store/fullPageExportContext";
// import { setSelectedAdsFromDropdown, setSelectedBrandsFromDropdown, setSelectedCampaignsFromDropdown } from "../../store/tvBrandsCampaignsSlice";

// COMPONENTS
import axios from "../../components/helpers/axios";
import Wrapper from "../../components/helpers/Wrapper";
import PageHeader from "../../components/layout/pageHeader";
import Card from "../../components/ui/Card";
import Slider from "../../components/ui/Slider";
import ExportDropdown from "../../components/ui/dropdown/ExportDropdown";
import CompareTvBrands from "../../components/ui/CompareTvBrands";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import IndiaMap from "../../components/ui/map/IndiaMap";

// LOADERS
import Loader from "../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import BarChart from "../../components/ui/charts/BarChart";
import Datatable from "../../components/ui/tables/Datatable";
import PieofaPieChart from "../../components/ui/charts/PieofaPieChart";
import CombinedBarLineMultipleAxesChart from "../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import SimpleTreeMap from "../../components/ui/charts/SimpleTreeMap";
import FunnelChart from "../../components/ui/charts/FunnelChart";
import DonutPieChart from "../../components/ui/charts/DonutPieChart";

// UITLS
import ErrorBoundary from "../../utils/ErrorBoundary";
import TextFormatter from "../../utils/TextFormatter";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import { IAEvent_Platform_Visit } from "../../utils/IAEvents";
import NumberFormatter from "../../utils/NumberFormatter";

// CONSTANTS
import { BlueColorStart500, PieOfPieSeriesColorSet, PieOfPieSubSeriesColorSet, PurpleColorStart500, } from "../../constants/constants";
import { BarChartDataLabelssample, BarChartDataSample, DonutPieChartDataSample } from "../../data/chartsDataSamples";
import { GrowthDegrowthTable, GrowthDegrowthTableHeader, TvHaleonNPTData } from "../../data/cardDataSamples";
import { useParams, useSearchParams } from "react-router-dom";

import PartitionedCombinedBarLineMultipleAxesChart from "../../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import CombinedBarLineChart from "../../components/ui/charts/CombinedBarLineChart";
import ExpandIcon from "../../assets/icons/ExpandIcon";
import Modal from "../../components/ui/Modal";

const TVHaleon = () => {
	// PAGE CONSTANTS
	let pageModule = "Retail Intelligence";
	let pageSubModule = "TV";

	const dispatch = useDispatch();
	const authCtx = useContext(AuthContext);
	const { setFullPageExport, setExportPageName, exportSummary, setexportSummary, setIsExportDisabled } = useContext(FullPageExportContext);

	const params = useParams();
	let [searchParams, setSearchParams] = useSearchParams();

	// currentDate / endDate / prevCurrentDate / prevEndDate
	const selectedDates = useSelector((state) => state.date);

	// SELECTED MODULE
	const selectedBrandIds = useSelector((state) => state.tvHaleonBrands.selectedBrands);
	const selectedAdNames = useSelector((state) => state.tvHaleonBrands.selectedAds);
	const selectedTagNames = useSelector((state) => state.tvHaleonBrands.selectedAgeGender)

	const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
	const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

	const [selectedBrandPlatform, setSelectedBrandPlatform] = useState(null);

	// INDIA MAP
	const [MapData, setMapData] = useState([]);
	const [isMapLoading, setMapIsLoading] = useState(false);
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const [MapError, setMapError] = useState(null);
	const [centerPoint, setCenterPoint] = useState(["82,22"]);
	const [northPoint, setNorthPoint] = useState(["72.6416015625, 37.71859032558816"]);
	const [southPoint, setSouthPoint] = useState(["93.1640625, 5.7908968128719565"]);
	let zoomLevel = 2.5;
	const [mapLevel, setMapLevel] = useState(0);
	const [mapBreadCrumbList, setMapBreadCrumbList] = useState(["India"]);

	const [mapZone, setMapZone] = useState(null);

	const [mapState, setMapState] = useState(null);
	const [mapStateCode, setMapStateCode] = useState(null);
	const [mapMarket, setMapMarket] = useState(null);



	// BRAND METRIC CARDS
	const [metricCards, setMetricCards] = useState([]);
	const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
	const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
	const [metricCardserror, setMetricCardsError] = useState(null);

	// PERFORMANCE METRIC CARDS
	const [performanceMetricCards, setPerformanceMetricCards] = useState([]);
	const [isPerformanceMetricCardsLoading, setPerformanceMetricCardsIsLoading] = useState(false);
	const [isPerformanceMetricCardsLoaded, setPerformanceMetricCardsIsLoaded] = useState(false);
	const [performanceMetricCardserror, setPerformanceMetricCardsError] = useState(null);

	// PERFORMANCE TREND
	const [perfTrend, setPerfTrend] = useState([]);
	const [performanceTableData, setPerformanceTableData] = useState([]);
	const [perfTrendDataLabels, setPerfTrendDataLabels] = useState([]);
	const [isPerfTrendLoading, setPerfTrendIsLoading] = useState(false);
	const [isPerfTrendLoaded, setPerfTrendIsLoaded] = useState(false);
	const [perfTrenderror, setPerfTrendError] = useState(null);

	// TOP GENRES AND GRPS
	const [TopGenreData, setTopGenreData] = useState([]);
	const [TopGenreDataLabels, setTopGenreDataLabels] = useState([]);
	const [isTopGenreLoading, setTopGenreIsLoading] = useState(false);
	const [isTopGenreLoaded, setTopGenreIsLoaded] = useState(false);
	const [TopGenreError, setTopGenreError] = useState(null);

	// AD DURATION
	const [AdDurationData, setAdDurationData] = useState([]);
	const [AdDurationDataLabels, setAdDurationDataLabels] = useState([]);
	const [isAdDurationLoading, setAdDurationIsLoading] = useState(false);
	const [isAdDurationLoaded, setAdDurationIsLoaded] = useState(false);
	const [AdDurationError, setAdDurationError] = useState(null);

	// BRAND WISE GRP AND SPENDS
	const [simpleTreemapData, setSimpleTreemapData] = useState([]);
	const [isSimpleTreemapDataLoading, setSimpleTreemapDataIsLoading] = useState(false);
	const [isSimpleTreemapDataLoaded, setSimpleTreemapDataIsLoaded] = useState(false);
	const [simpleTreemapDataError, setSimpleTreemapDataError] = useState(null);

	let treemapLevel = 1;
	const [treemapBrand, setTreemapBrand] = useState("");
	const [treemapBrandId, setTreemapBrandId] = useState("");
	const [treemapBrandCategory, setTreemapBrandCategory] = useState("");

	const [treemapParams, setTreemapParams] = useState({
		brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
		start_date: selectedDates.startDate,
		end_date: selectedDates.endDate,
		previous_start_date: selectedDates.prevStartDate,
		previous_end_date: selectedDates.prevEndDate,
		state_code: mapStateCode,
		market_code: mapMarket,
		ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
		level: 1,
		age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null
	});

	// ZERO VS NON ZERO GRP
	const [zeroGrp, setZeroGrp] = useState([]);
	const [isZeroGrpLoading, setZeroGrpIsLoading] = useState(false);
	const [isZeroGrpLoaded, setZeroGrpIsLoaded] = useState(false);
	const [zeroGrperror, setZeroGrpError] = useState(null);


	const [nonZeroGrp, setNonZeroGrp] = useState([]);
	const [isNonZeroGrpLoading, setNonZeroGrpIsLoading] = useState(false);
	const [isNonZeroGrpLoaded, setNonZeroGrpIsLoaded] = useState(false);
	const [NonZeroGrperror, setNonZeroGrpError] = useState(null);

	const [zeroNonZeroDropdownValue, setZeroNonZeroDropdownValue] = useState('');
	const [zeroNonZeroDropdownOptions, setZeroNonZeroDropdownOptions] = useState([]);
	const [zeroNonZeroDropdownOptionsLoading, setZeroNonZeroDropdownOptionsLoading] = useState(false);
	const [zeroNonZeroDropdownOptionsLoaded, setZeroNonZeroDropdownOptionsLoaded] = useState(false);

	const zeroNonZeroDropdownHandler = (e) => {
		if (e === null) {
			setZeroNonZeroDropdownValue("");
		} else {
			setZeroNonZeroDropdownValue(e);
		}
	};


	useEffect(() => {
		const fetchZeroNonZeroDropdownOptions = async () => {
			setZeroNonZeroDropdownOptionsLoading(true);
			setZeroNonZeroDropdownOptionsLoaded(false)
			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null

				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-spots-filter/", config);

				setZeroNonZeroDropdownOptions(res?.data?.data ?? []);
				setZeroNonZeroDropdownValue(res?.data?.data[0] ?? '')

				setZeroNonZeroDropdownOptionsLoading(false);
				setZeroNonZeroDropdownOptionsLoaded(true)
			} catch {
				setZeroNonZeroDropdownOptions([]);
				setZeroNonZeroDropdownValue("")
				setZeroNonZeroDropdownOptionsLoaded(false)
				setZeroNonZeroDropdownOptionsLoading(false);
			}
		};
		fetchZeroNonZeroDropdownOptions()
	}, [authCtx])

	// CAMPAIGN IMPACT 
	const [campaignImpact, setCampaignImpact] = useState([]);
	const [campaignImpactFirstTableData, setCampaignImpactFirstTableData] = useState([]);
	const [campaignImpactFirstTableHeader, setCampaignImpactFirstTableHeader] = useState([]);
	const [campaignImpactSecondTableData, setCampaignImpactSecondTableData] = useState([]);
	const [campaignImpactSecondTableHeader, setCampaignImpactSecondTableHeader] = useState([]);
	const [isCampaignImpactLoading, setCampaignImpactIsLoading] = useState(false);
	const [isCampaignImpactLoaded, setCampaignImpactIsLoaded] = useState(false);
	const [campaignImpacterror, setCampaignImpactError] = useState(null);

	// AD POSITION WISE GRP
	const [adPosition, setAdPosition] = useState([]);
	const [adPositionDataLabels, setAdPositionDataLabels] = useState([]);
	const [isAdPositionLoading, setAdPositionIsLoading] = useState(false);
	const [isAdPositionLoaded, setAdPositionIsLoaded] = useState(false);
	const [adPositionerror, setAdPositionError] = useState(null);

	// PT VS NON PT 
	const [ptNonPt, setPtNonPt] = useState([]);
	const [ptNonPtLabel, setPtNonPtLabel] = useState([]);

	const [ptNonPtTable, setPtNonPtTable] = useState([]);
	const [isPtNonPtLoading, setPtNonPtIsLoading] = useState(false);
	const [isPtNonPtLoaded, setPtNonPtIsLoaded] = useState(false);
	const [ptNonPterror, setPtNonPtError] = useState(null);


	// CUMULATIVE REACH ANALYSIS
	const [cumulativeReachAnalysis, setCumulativeReachAnalysis] = useState([]);
	const [iCumulativeReachAnalysisLoading, setCumulativeReachAnalysisIsLoading] = useState(false);
	const [iCumulativeReachAnalysisLoaded, setCumulativeReachAnalysisIsLoaded] = useState(false);
	const [cumulativeReachAnalysiserror, setCumulativeReachAnalysisError] = useState(null);

	// CAMPAIGN SUMMARY
	const [campaignSummary, setCampaignSummary] = useState([]);
	const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
	const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
	const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

	const campaignSummaryTableHeaders = [
		{ name: "Ad Name", field: "ad_name" },
		{ name: "GRP", field: "grp" },
		{ name: "Spends", field: "spend" },
		{ name: "Reach 1+", field: "reach_1" },
		{ name: "CPRP", field: "cprp" },
		{ name: "Prime Time Share", field: "prime_time" },
		{ name: "Premium Share", field: "premium_share" },
	];

	// TOP POSTS COMPARE
	const [topPostsCompare, setTopPostsCompare] = useState([]);
	const [isTopPostsCompareLoading, setTopPostsCompareIsLoading] = useState(false);
	const [isTopPostsCompareLoaded, setTopPostsCompareIsLoaded] = useState(false);
	const [topPostsCompareerror, setTopPostsCompareError] = useState(null);

	// RELOAD PAGE WHEN PLATFORM IS CHANGE
	useEffect(() => {
		setTreemapParams({
			brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
			start_date: selectedDates.startDate,
			end_date: selectedDates.endDate,
			previous_start_date: selectedDates.prevStartDate,
			previous_end_date: selectedDates.prevEndDate,
			state_code: mapStateCode,
			market_code: mapMarket,
			ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
			age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
			level: 1,
		});
		setTreemapBrand("");
		setTreemapBrandCategory("");
	}, [
		selectedBrandPlatform,
		selectedDates,
		mapStateCode,
		mapMarket,
		selectedAdNames,
		selectedTagNames
	]);

	// TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE

	var stateCodeLabel = mapState === undefined || mapState === null ? "" : " : " + mapState;
	var marketCodeLabel = mapMarket === undefined || mapMarket === null ? "" : " : " + mapMarket;
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// INDIA MAP

	// TO FETCH METRIC CARDS FROM API
	useEffect(() => {
		const fetchMapDataHandler = async () => {
			setMapIsLoading(true);
			setIsMapLoaded(false);
			setMapError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-tv-map-data/", config);
				setMapData(res?.data ?? []);
				setMapIsLoading(false);
				setIsMapLoaded(true);
				setMapError(null);
			} catch (error) {
				setMapIsLoading(false);
				setIsMapLoaded(false);
				setMapError(error.code);
			}
		};
		fetchMapDataHandler();
	}, [
		authCtx,
		selectedBrandPlatform,
		selectedDates,
		mapStateCode,
		mapMarket,
		selectedAdNames,
		selectedTagNames
	]);


	// TOP POSTS COMPARE
	let IndiaMapChart = (
		<Wrapper>
			<Loader loaderType="indiaMapLoader" />
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isMapLoaded && !isMapLoading) {
		if (NoDataAvailableChecker(MapData)) {
			IndiaMapChart = (
				<Wrapper>
					<NoDataAvailableLoader chartType="indiaChartType" />
				</Wrapper>
			);
		} else {
			IndiaMapChart = (
				<Wrapper>
					<ErrorBoundary chartType="indiaChartType">
						<IndiaMap
							showBreadcrumb={true}
							geoJson={MapData}
							northPoint={northPoint}
							southPoint={southPoint}
							centerPoint={centerPoint}
							zoomLevel={zoomLevel}
							mapLevel={mapLevel}
							onChange={handleIndiaMapChange}
							growthDegrowth={"grp"}
							toolTipValues={["grp", "spends", "monthly_sales_vol", "sov"]}
							toolTipLabels={["GRP", "Spends", "Sales Vol.", "SOV %"]}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (MapError) {
		IndiaMapChart = (
			<Wrapper>
				<ServerErrorsLoader chartType="indiaChartType" error={MapError} />
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isMapLoading) {
		IndiaMapChart = (
			<Wrapper>
				<Loader loaderType="indiaMapLoader" />
			</Wrapper>
		);
	}

	function mapBreadcrumbsHandler(index, value) {
		if (index === 0) {
			let a = ["INDIA"];
			setMapBreadCrumbList(a);
			setMapLevel(0);
			setMapState();
			setMapStateCode();
			setMapMarket(null)
			setCenterPoint(["82,22"]);
			setNorthPoint(["72.6416015625, 37.71859032558816"]);
			setSouthPoint(["93.1640625, 5.7908968128719565"]);
		}
		if (index === 1) {
			let a = ["INDIA", value];
			setMapBreadCrumbList(a);
			setMapLevel(1);
			setMapState();
			setMapStateCode();
		}
	}

	async function handleIndiaMapChange(value) {
		setMapLevel(value[0] + 1);
		setCenterPoint(value[1]);
		setNorthPoint(value[2]);
		setSouthPoint(value[3]);
		setMapMarket(value[7])

		if (value[0] === 1) {
			setMapState(value[5]);
			setMapStateCode(value[6]);
			setMapBreadCrumbList(["INDIA", value[8], value[5]]);
		} else {
			setMapBreadCrumbList(["INDIA", value[8]]);
		}
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// METRIC CARDS

	// TO FETCH METRIC CARDS FROM API

	useEffect(() => {
		const fetchMetricCardsHandler = async () => {
			setMetricCardsIsLoading(true);
			setMetricCardsIsLoaded(false);
			setMetricCardsError(null);

			const config = {
				params: {
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-brand-metric/", config);

				setMetricCards(res?.data ?? []);
				setSelectedBrandPlatform(res?.data[0]?.brand_id ?? [])
				setMetricCardsIsLoading(false);
				setMetricCardsIsLoaded(true);
				setMetricCardsError(null);
			} catch (error) {
				setMetricCards([]);
				setMetricCardsIsLoading(false);
				setMetricCardsIsLoaded(false);
				setMetricCardsError(error.code);
			}
		};
		fetchMetricCardsHandler();
	}, [
		authCtx,
		selectedDates,
		mapStateCode,
		mapMarket,
		selectedTagNames
	]);



	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let metricCardsSection = (
		<Wrapper>
			<div className="grid grid_cols_4 ">
				<div className="grid col_span_4 section_header">
					<div className="section_info">
						<h2 className="section_card_title">Brands</h2>
					</div>
				</div>
				<div className="grid col_span_4 grid_margin_bottom">
					<Loader loaderType="cardLoader" />
				</div>
			</div>
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isMetricCardsLoaded && !isMetricCardsLoading) {
		if (NoDataAvailableChecker(metricCards)) {
			metricCardsSection = (
				<Wrapper>
					<div className="grid grid_cols_4">
						<div className="grid col_span_4 section_header">
							<div className="section_info">
								<h2 className="section_card_title">Brands</h2>
							</div>
						</div>
						<div className="grid col_span_4 grid_margin_bottom">
							<NoDataAvailableLoader chartType="cardChartType" />
						</div>
					</div>
				</Wrapper>
			);
		} else {
			metricCardsSection = (
				<Wrapper>
					<div className="grid grid_margin_bottom">
						<div className="card_wrapper">
							<ErrorBoundary>
								<Slider
									sliderWrapperClass={"tab_card_slider"}
									sliderTitle={"Brands"}
								>
									{metricCards.map((data, index) => (
										<SwiperSlide
											key={index}
											onClick={() => activePlatform(data)}
										>
											<Card
												variant="compare"
												showPreviousCompare={true}
												key={index}
												cardClass={selectedBrandPlatform === data.brand_id ? "tabCard active" : "tabCard"}
												cardIconLink={data.brand_logo}
												cardTitle={data.cardTitle}
												currentValue={data.currentValue}
												previousValue={data.previousValue}
												second_currentValue={data.second_currentValue}
												second_previousValue={data.second_previousValue}
												rowOneTitle={data.rowOneTitle}
												rowTwoTitle={data.rowTwoTitle}
											/>
										</SwiperSlide>
									))}
								</Slider>
							</ErrorBoundary>
						</div>
					</div>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (metricCardserror) {
		metricCardsSection = (
			<Wrapper>
				<div className="grid grid_cols_4">
					<div className="grid col_span_4 section_header">
						<div className="section_info">
							<h2 className="section_card_title">Brands</h2>
						</div>
					</div>
					<div className="grid col_span_4 grid_margin_bottom">
						<ServerErrorsLoader error={metricCardserror} />
					</div>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isMetricCardsLoading) {
		metricCardsSection = (
			<Wrapper>
				<div className="grid grid_cols_4">
					<div className="grid col_span_4 section_header">
						<div className="section_info">
							<h2 className="section_card_title">Brands</h2>
						</div>
					</div>
					<div className="grid col_span_4 grid_margin_bottom">
						<Loader loaderType="cardLoader" />
					</div>
				</div>
			</Wrapper>
		);
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PERFORMANCE METRIC CARDS


	useEffect(() => {
		const fetchPerformanceMetricCardsHandler = async () => {
			setPerformanceMetricCardsIsLoading(true);
			setPerformanceMetricCardsIsLoaded(false);
			setPerformanceMetricCardsError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-performance-metric/", config);
				setPerformanceMetricCards(res?.data?.data ?? []);
				setPerformanceMetricCardsIsLoading(false);
				setPerformanceMetricCardsIsLoaded(true);
				setPerformanceMetricCardsError(null);
			} catch (error) {
				setPerformanceMetricCards([]);
				setPerformanceMetricCardsIsLoading(false);
				setPerformanceMetricCardsIsLoaded(false);
				setPerformanceMetricCardsError(error.code);
			}
		};
		fetchPerformanceMetricCardsHandler();
	}, [
		authCtx,
		selectedBrandPlatform,
		selectedDates,
		mapMarket,
		mapStateCode,
		selectedAdNames,
		selectedTagNames
	]);


	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let performanceMetricCardsSection = (
		<Wrapper>
			<div className="grid grid_cols_4 grid_margin_bottom">
				<div className="grid col_span_4 section_header">
					<div className="section_info">
						<h2 className="section_card_title">Performance Metrics</h2>
					</div>
				</div>
				<div className="grid col_span_4 grid_margin_bottom">
					<Loader loaderType="cardLoader" />
				</div>
			</div>
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isPerformanceMetricCardsLoaded && !isPerformanceMetricCardsLoading) {
		if (NoDataAvailableChecker(performanceMetricCards)) {
			performanceMetricCardsSection = (
				<Wrapper>
					<div className="grid grid_cols_4 grid_margin_bottom">
						<div className="grid col_span_4 section_header">
							<div className="section_info">
								<h2 className="section_card_title">Performance Metrics</h2>
							</div>
						</div>
						<div className="grid col_span_4 grid_margin_bottom">
							<NoDataAvailableLoader chartType="cardChartType" />
						</div>
					</div>
				</Wrapper>
			);
		} else {
			performanceMetricCardsSection = (
				<Wrapper>
					<ErrorBoundary chartType="cardChartType">
						<Slider sliderTitle={"Performance Metrics"}>
							{performanceMetricCards.map((data, index) => (
								<SwiperSlide key={index}>
									<Card
										module="tv"
										platform="tv_overall"
										section="tv_overall"
										key={index}
										variant={"chartCard"}
										cardTitle={data.label}
										currentValue={data.currentValue}
										previousValue={data.previousValue}
										chartType={"Line"}
										chartData={
											NoDataAvailableChecker(data.subData)
												? []
												: NoDataAvailableChecker(data.subData.data)
													? []
													: data.subData.data
										}
										chartDataLabels={
											NoDataAvailableChecker(data.subData.dimension)
												? []
												: NoDataAvailableChecker(data.subData.dimension)
													? []
													: data.subData.dimension
										}
									/>
								</SwiperSlide>
							))}
						</Slider>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (performanceMetricCardserror) {
		performanceMetricCardsSection = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom">
					<div className="grid col_span_4 section_header">
						<div className="section_info">
							<h2 className="section_card_title">Performance Metrics</h2>
						</div>
					</div>
					<div className="grid col_span_4 grid_margin_bottom">
						<ServerErrorsLoader
							chartType="cardChartType"
							error={performanceMetricCardserror}
						/>
					</div>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isPerformanceMetricCardsLoading) {
		performanceMetricCardsSection = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom">
					<div className="grid col_span_4 section_header">
						<div className="section_info">
							<h2 className="section_card_title">Performance Metrics</h2>
						</div>
					</div>
					<div className="grid col_span_4 grid_margin_bottom">
						<Loader loaderType="cardLoader" />
					</div>
				</div>
			</Wrapper>
		);
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PERFORMANCE TREND

		// TO FETCH PERFORMANCE METRIC CARDS FROM API
		const [isPerfTrendOpen, setIsPerfTrendOpen] = useState(false)

		let defaultPerfTrendDisabledValues = [];
	  
		if (showAllLegendsinMultiLineCluster) {
		  defaultPerfTrendDisabledValues = []
		} else {
		  defaultPerfTrendDisabledValues = ["Sales Volume"]
		}
	  
		const [perfTrendDisabledValues, setPerfTrendDisabledValues] = useState(defaultPerfTrendDisabledValues)
	  

		
	// TO FETCH DATA FROM API
	useEffect(() => {
		const fetchPerfTrendChartHandler = async () => {
			setPerfTrendIsLoading(true);
			setPerfTrendIsLoaded(false);
			setPerfTrendError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-performance-trend/", config);

				setPerfTrend(res?.data?.chart_data?.data ?? []);
				setPerfTrendDataLabels(
					res.data === undefined || res.data.length === 0 ? [] :
						res.data.chart_data === undefined || res.data.chart_data.length === 0 ? [] :
							res.data.chart_data.label === undefined || res.data.chart_data.label.length === 0 ? [] :
								res.data.chart_data.label[0].linechartLabels === undefined || res.data.chart_data.label[0].linechartLabels.length === 0 ? [] :
									res.data.chart_data.label[0].barchartLabels === undefined || res.data.chart_data.label[0].barchartLabels.length === 0 ? [] :
										res.data.chart_data.label
				);
				setPerformanceTableData(res?.data?.table_data ?? []);
				setPerfTrendIsLoading(false);
				setPerfTrendIsLoaded(true);
				setPerfTrendError(null);
			} catch (error) {
				setPerfTrend([]);
				setPerfTrendDataLabels([]);
				setPerfTrendIsLoading(false);
				setPerfTrendIsLoaded(false);
				setPerfTrendError(error.code);
			}
		};
		fetchPerfTrendChartHandler();
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapMarket,
			mapStateCode,
			selectedAdNames,
			selectedTagNames
		])


	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let perfTrendChart = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>
	let perfTrendChart2 = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>
	let perfTrendTable = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>

	// IF DATA IS LOADED
	if (isPerfTrendLoaded && !isPerfTrendLoading) {
		if (NoDataAvailableChecker(perfTrend) || NoDataAvailableChecker(perfTrendDataLabels)) {
			perfTrendChart = (
				<Wrapper>
					<NoDataAvailableLoader chartType="multiAxesBarLineChartType" />
				</Wrapper>
			);
			perfTrendChart2 = (
				<Wrapper>
					<NoDataAvailableLoader chartType="multiAxesBarLineChartType" />
				</Wrapper>
			);
		} else {
			if(isMultiLineCluster){
			perfTrendChart = (
				<Wrapper>
					<ErrorBoundary chartType="multiAxesBarLineChartType">
						<CombinedBarLineChart
							chartId="social_facebook_current_period_1"
							chartClass="section_card_chart"
							showBullets={false}
							chartData={perfTrend}
							chartLabels={perfTrendDataLabels}
							disabledLegendArray={perfTrendDisabledValues}
							setDisabledValues={setPerfTrendDisabledValues}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
			perfTrendChart2 = (
				<Wrapper>
					<ErrorBoundary chartType="multiAxesBarLineChartType">
						<PartitionedCombinedBarLineMultipleAxesChart
							chartId="social_facebook_current_period_2"
							chartClass="section_card_chart"
							showSecondY={true}
							showBullets={false}
							chartData={perfTrend}
							chartLabels={perfTrendDataLabels}
							disabledLegendArray={perfTrendDisabledValues}
							setDisabledValues={setPerfTrendDisabledValues}
							
						/>
					</ErrorBoundary>
				</Wrapper>
			);
			}
			else{
				<Wrapper>
					<ErrorBoundary chartType="multiAxesBarLineChartType">
						<CombinedBarLineMultipleAxesChart
							chartId="social_facebook_current_period"
							chartClass="section_card_chart"
							showSecondY={false}
							showBullets={false}
							chartData={perfTrend}
							chartLabels={perfTrendDataLabels}
						/>
					</ErrorBoundary>
				</Wrapper>
			}
		}
	}

	if (isPerfTrendLoaded && !isPerfTrendLoading) {
		if (NoDataAvailableChecker(performanceTableData)) {
			perfTrendTable = (
				<Wrapper>
					<NoDataAvailableLoader chartType="tableChartType" />
				</Wrapper>
			);
		} else {
			perfTrendTable = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="chart_details_wrapper">
							<div className="main_wrapper">
								<div className="body">
									{performanceTableData && performanceTableData.map(
										(details, postImpressionIndex) => (
											<Wrapper key={postImpressionIndex}>
												<div className="row">
													<div className="title_wrapper">
														{details.label}
													</div>
													<div className="value_wrapper">
														<ErrorBoundary>
															<NumberFormatter number={details.value} title={details.label} />
														</ErrorBoundary>
													</div>
												</div>
											</Wrapper>
										))}
								</div>
							</div>
						</div>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (perfTrenderror) {
		perfTrendChart = (
			<Wrapper>
				<ServerErrorsLoader chartType="multiAxesBarLineChartType" error={perfTrenderror} />
			</Wrapper>
		);
		perfTrendChart2 = (
			<Wrapper>
				<ServerErrorsLoader chartType="multiAxesBarLineChartType" error={perfTrenderror} />
			</Wrapper>
		);

		perfTrendTable = (
			<Wrapper>
				<ServerErrorsLoader chartType="tableChartType" error={perfTrenderror} />
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isPerfTrendLoading) {
		perfTrendChart = (
			<Wrapper>
				<Loader loaderType="combinedBarLineChartLoader" />
			</Wrapper>
		);
		perfTrendChart2 = (
			<Wrapper>
				<Loader loaderType="combinedBarLineChartLoader" />
			</Wrapper>
		);
	
		perfTrendTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// TOP GENRES AND GRPS

	// TO FETCH METRIC CARDS FROM API

	useEffect(() => {
		const fetchTopGenreHandler = async () => {
			setTopGenreIsLoading(true);
			setTopGenreIsLoaded(false);
			setTopGenreError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-top-genre/", config);
				setTopGenreData(res?.data?.data ?? []);
				setTopGenreDataLabels(res?.data?.label ?? []);
				setTopGenreIsLoading(false);
				setTopGenreIsLoaded(true);
				setTopGenreError(null);
			} catch (error) {
				setTopGenreData([]);
				setTopGenreDataLabels([]);
				setTopGenreIsLoading(false);
				setTopGenreIsLoaded(false);
				setTopGenreError(error.code);
			}
		};
		fetchTopGenreHandler()
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapMarket,
			mapStateCode,
			selectedAdNames,
			selectedTagNames
		])


	let topGenreBarChart = (
		<Wrapper>
			<Loader loaderType="barChartLoader" />
		</Wrapper>
	);

	// IF DATA IS AVAILABLE
	if (isTopGenreLoaded && !isTopGenreLoading) {
		if (NoDataAvailableChecker(TopGenreData) || NoDataAvailableChecker(TopGenreDataLabels)) {
			topGenreBarChart = (
				<Wrapper>
					<NoDataAvailableLoader chartType="barChartType" />
				</Wrapper>
			);
		} else {
			topGenreBarChart = (
				<Wrapper>
					<ErrorBoundary chartType="barChartType">
						<BarChart
							chartId="rpc"
							chartClass="section_card_chart"
							chartData={TopGenreData}
							chartLabels={TopGenreDataLabels}
							chartColorSet={BlueColorStart500}
							showShortVerticalLabels={true}
							minimumGridDistance={0}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (TopGenreError) {
		topGenreBarChart = (
			<Wrapper>
				<ServerErrorsLoader chartType="barChartType" error={TopGenreError} />
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isTopGenreLoading) {
		topGenreBarChart = (
			<Wrapper>
				<Loader loaderType="barChartLoader" />
			</Wrapper>
		);
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// AD DURATION WISE

	// TO FETCH METRIC CARDS FROM API

	useEffect(() => {
		const fetchAdDurationHandler = async () => {
			setAdDurationIsLoading(true);
			setAdDurationIsLoaded(false);
			setAdDurationError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-duration-wise-ad/", config);
				setAdDurationData(res?.data?.data ?? []);
				setAdDurationDataLabels(res?.data?.label ?? []);
				setAdDurationIsLoading(false);
				setAdDurationIsLoaded(true);
				setAdDurationError(null);
			} catch (error) {
				setAdDurationData([]);
				setAdDurationDataLabels([]);
				setAdDurationIsLoading(false);
				setAdDurationIsLoaded(false);
				setAdDurationError(error.code);
			}
		};
		fetchAdDurationHandler();
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapStateCode,
			mapMarket,
			selectedAdNames,
			selectedTagNames
		]
	)



	let adDurationBarChart = (
		<Wrapper>
			<Loader loaderType="barChartLoader" />
		</Wrapper>
	);

	// IF DATA IS AVAILABLE
	if (isAdDurationLoaded && !isAdDurationLoading) {
		if (
			NoDataAvailableChecker(AdDurationData) ||
			NoDataAvailableChecker(AdDurationDataLabels)
		) {
			adDurationBarChart = (
				<Wrapper>
					<NoDataAvailableLoader chartType="barChartType" />
				</Wrapper>
			);
		} else {
			adDurationBarChart = (
				<Wrapper>
					<ErrorBoundary chartType="barChartType">
						<BarChart
							chartId={"adDuration" + Math.random(10000)}
							chartClass="section_card_chart"
							chartData={AdDurationData}
							chartLabels={AdDurationDataLabels}
							chartColorSet={PurpleColorStart500}
							minimumGridDistance={0}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (AdDurationError) {
		adDurationBarChart = (
			<Wrapper>
				<ServerErrorsLoader chartType="barChartType" error={AdDurationError} />
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isAdDurationLoading) {
		adDurationBarChart = (
			<Wrapper>
				<Loader loaderType="barChartLoader" />
			</Wrapper>
		);
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// BRAND WISE GRP AND SPENDS
	const chartlevelHandler = (data) => {
		if (data.level === 1) {
			setTreemapParams({
				brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? data.brand_id : selectedBrandPlatform,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				state_code: mapStateCode,
				market_code: mapMarket,
				ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
				age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				level: 2,
			});
			setTreemapBrandId(data.brand_id);
			setTreemapBrand(data.brand_name);
		} else if (data.level === 2) {
			setTreemapParams({
				brand_id: treemapBrandId,
				start_date: selectedDates.startDate,
				end_date: selectedDates.endDate,
				previous_start_date: selectedDates.prevStartDate,
				previous_end_date: selectedDates.prevEndDate,
				state_code: mapStateCode,
				market_code: mapMarket,
				ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
				age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				level: 3,
				category: data.category,
			});
			setTreemapBrandCategory(data.category);
		} else {
			alert("No further drill down available");
		}
	};

	useEffect(() => {
		const fetchSampleTreemapHandler = async () => {
			setSimpleTreemapDataIsLoading(true);
			setSimpleTreemapDataIsLoaded(false);
			setSimpleTreemapDataError(null);

			const config = {
				params: treemapParams,
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-brand-grp-spend/", config);

				setSimpleTreemapData(res?.data ?? []);
				setSimpleTreemapDataIsLoading(false);
				setSimpleTreemapDataIsLoaded(true);
				setSimpleTreemapDataError(null);
			} catch (error) {
				setSimpleTreemapData([]);
				setSimpleTreemapDataIsLoading(false);
				setSimpleTreemapDataIsLoaded(false);
				setSimpleTreemapDataError(error.code);
			}
		};
		fetchSampleTreemapHandler();
	}, [authCtx, treemapParams]);


	let sampleTreemapChart = (
		<Wrapper>
			<Loader loaderType="treeChartLoader" />
		</Wrapper>
	);

	// IF DATA IS AVAILABLE
	if (isSimpleTreemapDataLoaded && !isSimpleTreemapDataLoading) {
		if (NoDataAvailableChecker(simpleTreemapData)) {
			sampleTreemapChart = (
				<Wrapper>
					<NoDataAvailableLoader chartType="treeChartType" />
				</Wrapper>
			);
		} else {
			if (treemapLevel > 3) {
				sampleTreemapChart = (
					<Wrapper>
						<NoDataAvailableLoader chartType="treeChartType" />
					</Wrapper>
				);
			} else {
				sampleTreemapChart = (
					<Wrapper>
						<ErrorBoundary chartType="treeChartType">
							<SimpleTreeMap
								chartId="simpleTreemap"
								chartClass="section_card_chart"
								chartData={simpleTreemapData}
								levelHandler={chartlevelHandler}
								colorChangeParameter={"growth_percentage"}
								chartLabels={[
									{
										name: "name",
										value: "value",
										toolTipvalue1: "grp",
										toolTipvalue1Label: "GRPs",
										toolTipvalue2: "spend",
										toolTipvalue2Label: "Spends",
									},
								]}
							/>
						</ErrorBoundary>
					</Wrapper>
				);
			}
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (simpleTreemapDataError) {
		sampleTreemapChart = (
			<Wrapper>
				<ServerErrorsLoader error={simpleTreemapDataError} />
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isSimpleTreemapDataLoading) {
		sampleTreemapChart = (
			<Wrapper>
				<Loader loaderType="treeChartLoader" />
			</Wrapper>
		);
	}


	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// ZERO VS NON ZERO GRP

	// TO FETCH DATA FROM API

	useEffect(() => {

		const fetchZeroGrpHandler = async () => {
			setZeroGrpIsLoading(true);
			setZeroGrpIsLoaded(false);
			setZeroGrpError(null);

			var filterVal = zeroNonZeroDropdownValue === undefined || zeroNonZeroDropdownValue === null ? "" : zeroNonZeroDropdownValue.value === undefined || zeroNonZeroDropdownValue.value === null ? "" : zeroNonZeroDropdownValue.value

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					spots_filter: filterVal,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-zero-grp/", config);
				setZeroGrp(res?.data ?? []);
				setZeroGrpIsLoading(false);
				setZeroGrpIsLoaded(true);
				setZeroGrpError(null);
			} catch (error) {
				setZeroGrp([]);
				setZeroGrpIsLoading(false);
				setZeroGrpIsLoaded(false);
				setZeroGrpError(error.code);
			}
		};
		(zeroNonZeroDropdownOptionsLoaded === true) && fetchZeroGrpHandler();
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapStateCode,
			mapMarket,
			zeroNonZeroDropdownValue,
			zeroNonZeroDropdownOptionsLoaded,
			selectedAdNames,
			selectedTagNames
		])


	useEffect(() => {

		const fetchNonZeroGrpHandler = async () => {
			setNonZeroGrpIsLoading(true);
			setNonZeroGrpIsLoaded(false);
			setNonZeroGrpError(null);

			var filterVal = zeroNonZeroDropdownValue === undefined || zeroNonZeroDropdownValue === null ? "" : zeroNonZeroDropdownValue.value === undefined || zeroNonZeroDropdownValue.value === null ? "" : zeroNonZeroDropdownValue.value

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					spots_filter: filterVal,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-non-zero-grp/", config);
				setNonZeroGrp(res?.data ?? []);
				setNonZeroGrpIsLoading(false);
				setNonZeroGrpIsLoaded(true);
				setNonZeroGrpError(null);
			} catch (error) {
				setNonZeroGrp([]);
				setNonZeroGrpIsLoading(false);
				setNonZeroGrpIsLoaded(false);
				setNonZeroGrpError(error.code);
			}
		};
		(zeroNonZeroDropdownOptionsLoaded === true) && fetchNonZeroGrpHandler();
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapMarket,
			mapStateCode,
			zeroNonZeroDropdownValue,
			zeroNonZeroDropdownOptionsLoaded,
			selectedAdNames,
			selectedTagNames
		])


	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let ZeroGrpFirstDonut = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>
	let NonZeroGrpSecondDonut = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>


	// IF DATA IS LOADED
	if (isZeroGrpLoaded && !isZeroGrpLoading) {
		if (NoDataAvailableChecker(zeroGrp)) {
			ZeroGrpFirstDonut = <Wrapper><NoDataAvailableLoader chartType="pieChartType" /></Wrapper>

		} else {
			ZeroGrpFirstDonut = (
				<Wrapper>
					<ErrorBoundary chartType="pieChartType">
						<DonutPieChart
							chartClass="section_card_chart"
							chartData={zeroGrp}
							chartId="ZeroGrpFirstDonut"
							showLabels
							showLegend
							showVerticalLabels
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (zeroGrperror) {
		ZeroGrpFirstDonut = <Wrapper><ServerErrorsLoader chartType="pieChartType" error={zeroGrperror} /></Wrapper>
	}

	// IF DATA LOADING
	if (isZeroGrpLoading) {
		ZeroGrpFirstDonut = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>

	}


	// IF DATA IS LOADED
	if (isNonZeroGrpLoaded && !isNonZeroGrpLoading) {
		if (NoDataAvailableChecker(nonZeroGrp)) {
			NonZeroGrpSecondDonut = <Wrapper><NoDataAvailableLoader chartType="pieChartType" /></Wrapper>

		} else {
			NonZeroGrpSecondDonut = (
				<Wrapper>
					<ErrorBoundary chartType="pieChartType">
						<DonutPieChart
							chartClass="section_card_chart"
							chartData={nonZeroGrp}
							chartId="NonZeroGrpSecondDonut"
							showLabels
							showLegend
							showVerticalLabels
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (NonZeroGrperror) {
		NonZeroGrpSecondDonut = <Wrapper><ServerErrorsLoader chartType="pieChartType" error={NonZeroGrperror} /></Wrapper>

	}

	// IF DATA LOADING
	if (isNonZeroGrpLoading) {
		NonZeroGrpSecondDonut = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// CAMPAIGN IMPACT VS REGULAR COMPARISON

	// TO FETCH DATA FROM API

	useEffect(() => {

		const fetchCampaignImpactHandler = async () => {
			setCampaignImpactIsLoading(true);
			setCampaignImpactIsLoaded(false);
			setCampaignImpactError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-impact-regular-comparison/", config);
				setCampaignImpact(res?.data?.chart_data ?? []);
				setCampaignImpactFirstTableData(res?.data?.table_data?.impact ?? [])
				setCampaignImpactSecondTableData(res?.data?.table_data?.regular ?? [])

				setCampaignImpactIsLoading(false);
				setCampaignImpactIsLoaded(true);
				setCampaignImpactError(null);
			} catch (error) {
				setCampaignImpact([]);
				setCampaignImpactFirstTableData([])
				setCampaignImpactSecondTableData([])
				setCampaignImpactIsLoading(false);
				setCampaignImpactIsLoaded(false);
				setCampaignImpactError(error.code);
			}
		};
		fetchCampaignImpactHandler();
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapMarket,
			mapStateCode,
			// selectedBrandIds,
			// selectedCampaignIds,
			selectedAdNames,
			selectedTagNames
		])

	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let campaignImpactSection = (
		<Wrapper>
			<Loader loaderType="donutChartLoader" />
		</Wrapper>
	);

	let campaignImpactTableFirst = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>
	let campaignImpactTableSecond = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>

	// IF DATA IS LOADED
	if (isCampaignImpactLoaded && !isCampaignImpactLoading) {
		if (NoDataAvailableChecker(campaignImpact)) {
			campaignImpactSection = (
				<Wrapper>
					<NoDataAvailableLoader chartType="donutChartType" />
				</Wrapper>
			);
		} else {
			campaignImpactSection = (
				<Wrapper>
					<ErrorBoundary chartType="donutChartType">
						<DonutPieChart
							chartClass="section_card_chart"
							chartData={campaignImpact}
							chartId="campaignImpact"
							showLabels
							showLegend
							showVerticalLabels
						/>
					</ErrorBoundary>
				</Wrapper>
			);


		}

		if (NoDataAvailableChecker(campaignImpactFirstTableData)) {

			campaignImpactTableFirst = (
				<Wrapper>
					<NoDataAvailableLoader chartType="tableChartType" />
				</Wrapper>
			);
		} else {
			campaignImpactTableFirst = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="chart_details_wrapper">
							<div className="main_wrapper">
								<div className="body">
									{campaignImpactFirstTableData && campaignImpactFirstTableData.map(
										(details, postImpressionIndex) => (
											<Wrapper key={postImpressionIndex}>
												<div className="row">
													<div className="title_wrapper">
														{details.label}
													</div>
													<div className="value_wrapper">
														<ErrorBoundary>
															<NumberFormatter number={details.value} />
														</ErrorBoundary>
													</div>
												</div>
											</Wrapper>
										))}
								</div>
							</div>
						</div>
					</ErrorBoundary>
				</Wrapper>
			);
		}

		if (NoDataAvailableChecker(campaignImpactSecondTableData)) {


			campaignImpactTableSecond = (
				<Wrapper>
					<NoDataAvailableLoader chartType="tableChartType" />
				</Wrapper>
			);
		} else {
			campaignImpactTableSecond = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="chart_details_wrapper">
							<div className="main_wrapper">
								<div className="body">
									{campaignImpactSecondTableData && campaignImpactSecondTableData.map(
										(details, postImpressionIndex) => (
											<Wrapper key={postImpressionIndex}>
												<div className="row">
													<div className="title_wrapper">
														{details.label}
													</div>
													<div className="value_wrapper">
														<ErrorBoundary>
															<NumberFormatter number={details.value} />
														</ErrorBoundary>
													</div>
												</div>
											</Wrapper>
										))}
								</div>
							</div>
						</div>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (campaignImpacterror) {
		campaignImpactSection = <Wrapper><ServerErrorsLoader chartType="donutChartType" error={campaignImpacterror} /></Wrapper>

		campaignImpactTableFirst = <Wrapper><ServerErrorsLoader chartType="tableChartType" error={campaignImpacterror} /></Wrapper>

		campaignImpactTableSecond = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="tableChartType"
					error={campaignImpacterror}
				/>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isCampaignImpactLoading) {
		campaignImpactSection = (
			<Wrapper>
				<Loader loaderType="donutChartLoader" />
			</Wrapper>
		);

		campaignImpactTableFirst = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
		campaignImpactTableSecond = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// AD POSITION WISE GRP

	// TO FETCH DATA FROM API

	useEffect(() => {

		const fetchAdPositionHandler = async () => {
			setAdPositionIsLoading(true);
			setAdPositionIsLoaded(false);
			setAdPositionError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-position-wise-grp/", config);
				setAdPosition(res?.data?.data ?? []);
				setAdPositionDataLabels(res?.data?.label ?? []);
				setAdPositionIsLoading(false);
				setAdPositionIsLoaded(true);
				setAdPositionError(null);
			} catch (error) {
				setAdPosition([]);
				setAdPositionDataLabels([]);
				setAdPositionIsLoading(false);
				setAdPositionIsLoaded(false);
				setAdPositionError(error.code);
			}
		};
		fetchAdPositionHandler();
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapStateCode,
			mapMarket,
			selectedAdNames,
			selectedTagNames
		])

	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let adPositionPie = (<Wrapper><Loader loaderType="pieChartLoader" /></Wrapper>);

	let adPositionTable = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>

	// IF DATA IS LOADED
	if (isAdPositionLoaded && !isAdPositionLoading) {
		if (NoDataAvailableChecker(adPosition)) {
			adPositionPie = (
				<Wrapper>
					<NoDataAvailableLoader chartType="pieChartType" />
				</Wrapper>
			);

			adPositionTable = (
				<Wrapper>
					<NoDataAvailableLoader chartType="tableChartType" />
				</Wrapper>
			);
		} else {
			adPositionPie = (
				<Wrapper>
					<ErrorBoundary chartType="pieChartType">
						<PieofaPieChart
							chartId="content_analysis"
							chartClass="section_card_chart"
							chartData={adPosition}
							chartLabels={adPositionDataLabels}
						// chartSeriesColorSet={PieOfPieSeriesColorSet}
						// chartSubSeriesColorSet={PieOfPieSubSeriesColorSet}
						/>
					</ErrorBoundary>
				</Wrapper>
			);

			adPositionTable = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="chart_details_wrapper">
							<div className="main_wrapper">
								<div className="body">
									{adPosition && adPosition.map(
										(details, postImpressionIndex) => (
											<Wrapper key={postImpressionIndex}>
												<div className="row">
													<div className="title_wrapper">
														{details.label}
													</div>
													<div className="value_wrapper">
														<ErrorBoundary>
															<NumberFormatter number={details.value} />
														</ErrorBoundary>
													</div>
												</div>
											</Wrapper>
										))}
								</div>
							</div>
						</div>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (adPositionerror) {
		adPositionPie = (
			<Wrapper>
				<ServerErrorsLoader chartType="pieChartType" error={adPositionerror} />
			</Wrapper>
		);

		adPositionTable = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="tableChartType"
					error={adPositionerror}
				/>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isAdPositionLoading) {
		adPositionPie = (
			<Wrapper>
				<Loader loaderType="pieChartLoader" />
			</Wrapper>
		);

		adPositionTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>

	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// PT VS NON PT

	// TO FETCH DATA FROM API

	useEffect(() => {

		const fetchPtNonPtHandler = async () => {
			setPtNonPtIsLoading(true);
			setPtNonPtIsLoaded(false);
			setPtNonPtError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-pt-npt-genre-analysis/", config);
				setPtNonPt(res?.data?.chart_data?.data ?? []);
				setPtNonPtLabel(res?.data?.chart_data?.label ?? [])
				setPtNonPtTable(res?.data?.table_data ?? [])

				setPtNonPtIsLoading(false);
				setPtNonPtIsLoaded(true);
				setPtNonPtError(null);
			} catch (error) {
				setPtNonPt([]);
				setPtNonPtTable([])
				setPtNonPtIsLoading(false);
				setPtNonPtIsLoaded(false);
				setPtNonPtError(error.code);
			}
		};
		fetchPtNonPtHandler();
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapStateCode,
			mapMarket,
			selectedAdNames,
			selectedTagNames
		])

	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let ptNonPtSection = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>

	let ptNonPtTableSection = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>

	// IF DATA IS LOADED
	if (isPtNonPtLoaded && !isPtNonPtLoading) {
		if (NoDataAvailableChecker(ptNonPt) || NoDataAvailableChecker(ptNonPtTable)) {
			ptNonPtSection = <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
			ptNonPtTableSection = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>

		} else {
			ptNonPtSection = (
				<Wrapper>
					<ErrorBoundary chartType="barChartType">
						<BarChart
							chartClass="section_card_chart"
							chartData={ptNonPt}
							chartId="ptNonPt"
							chartLabels={ptNonPtLabel}
						/>
					</ErrorBoundary>
				</Wrapper>
			);


			ptNonPtTableSection = (
				<Wrapper>
					<ErrorBoundary chartType="tableChartType">
						<div className="chart_details_wrapper">
							<div className="main_wrapper">
								<div className="body pt_npt_wrapper">
									{ptNonPtTable && ptNonPtTable.map(
										(item, index) => (
											<Wrapper key={index}>
												<div className="row">
													<div className="title_wrapper">
														{item.title}
													</div>
													<div className="value_wrapper">
														<div>
															<div className="value_number">
																<ErrorBoundary>
																	<NumberFormatter number={item.value1} />
																</ErrorBoundary>
															</div>
															<div className="value_text">{item.label1}</div>
														</div>
														<div>
															<div className="value_number">
																<ErrorBoundary>
																	<NumberFormatter number={item.value2} />
																</ErrorBoundary>
															</div>
															<div className="value_text">{item.label2}</div>
														</div>
													</div>
												</div>
											</Wrapper>
										))}
								</div>
							</div>
						</div>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (ptNonPterror) {
		ptNonPtSection = (
			<Wrapper>
				<ServerErrorsLoader chartType="barChartLoader" error={ptNonPterror} />
			</Wrapper>
		);

		ptNonPtTableSection = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="tableChartType"
					error={ptNonPterror}
				/>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isPtNonPtLoading) {
		ptNonPtSection = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
		ptNonPtTableSection = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// CUMULATIVE REACH ANALYSIS

	// TO FETCH DATA FROM API

	useEffect(() => {
		const fetchCumulativeReachAnalysisHandler = async () => {
			setCumulativeReachAnalysisIsLoading(true);
			setCumulativeReachAnalysisIsLoaded(false);
			setCumulativeReachAnalysisError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-cumulative-reach-analysis/", config);

				setCumulativeReachAnalysis(res?.data ?? []);
				setCumulativeReachAnalysisIsLoading(false);
				setCumulativeReachAnalysisIsLoaded(true);
				setCumulativeReachAnalysisError(null);
			} catch (error) {
				setCumulativeReachAnalysis([]);
				setCumulativeReachAnalysisIsLoading(false);
				setCumulativeReachAnalysisIsLoaded(false);
				setCumulativeReachAnalysisError(error.code);
			}
		};

		fetchCumulativeReachAnalysisHandler()
	}, [
		authCtx,
		selectedBrandPlatform,
		selectedDates,
		mapStateCode,
		mapMarket,
		selectedAdNames,
		selectedTagNames
	])



	// OVERVIEW METRICS CARDS SLIDER DATA HANDLING
	let cumulativeReachAnalysisChart = (<Wrapper><Loader loaderType="funnelChartLoader" /></Wrapper>);

	// IF DATA IS LOADED
	if (iCumulativeReachAnalysisLoaded && !iCumulativeReachAnalysisLoading) {
		if (NoDataAvailableChecker(cumulativeReachAnalysis) || cumulativeReachAnalysis.every((val, i, arr) => val.value === arr[0].value)) {
			cumulativeReachAnalysisChart = (
				<Wrapper><NoDataAvailableLoader chartType="funnelChartType" /></Wrapper>
			);
		} else {
			cumulativeReachAnalysisChart = (
				<Wrapper>
					<ErrorBoundary chartType="funnelChartType">
						<FunnelChart
							chartId="campaign_overview"
							chartClass="section_card_chart"
							chartData={cumulativeReachAnalysis}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (cumulativeReachAnalysiserror) {
		cumulativeReachAnalysisChart = (
			<Wrapper>
				<ServerErrorsLoader
					chartType="funnelChartType"
					error={cumulativeReachAnalysiserror}
				/>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (iCumulativeReachAnalysisLoading) {
		cumulativeReachAnalysisChart = <Wrapper><Loader loaderType="funnelChartLoader" /></Wrapper>;
	}


	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// CAMPAIGN SUMMARY

	// TO FETCH METRIC CARDS FROM API

	useEffect(() => {
		const fetchCampaignSummaryHandler = async () => {
			setCampaignSummaryIsLoading(true);
			setCampaignSummaryIsLoaded(false);
			setCampaignSummaryError(null);

			const config = {
				params: {
					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-campaign-summary/", config);

				setCampaignSummary(res?.data ?? []);
				setCampaignSummaryIsLoading(false);
				setCampaignSummaryIsLoaded(true);
				setCampaignSummaryError(null);
			} catch (error) {
				setCampaignSummary([]);
				setCampaignSummaryIsLoading(false);
				setCampaignSummaryIsLoaded(false);
				setCampaignSummaryError(error.code);
			}
		};
		fetchCampaignSummaryHandler()
	},
		[
			authCtx,
			selectedBrandPlatform,
			selectedDates,
			mapStateCode,
			mapMarket,
			selectedAdNames,
			selectedTagNames
		])


	let campaignSummaryTable = (
		<Wrapper>
			<div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
				<div className="grid col_span_4">
					<div className="section_card_header">
						<h2 className="section_card_title">
							Ad/ Campaign Summary
						</h2>
					</div>
				</div>
				<div className="grid col_span_4 section_card_body">
					<div className="section_card_body">
						<Loader loaderType="smallTableLoader" />
					</div>
				</div>
			</div>
		</Wrapper>
	);

	// IF DATA IS LOADED
	if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
		if (NoDataAvailableChecker(campaignSummary) || NoDataAvailableChecker(campaignSummaryTableHeaders)) {
			campaignSummaryTable = (
				<Wrapper>
					<div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
						<div className="grid col_span_4">
							<div className="section_card_header">
								<h2 className="section_card_title">
									Ad/ Campaign Summary
								</h2>
							</div>
						</div>
						<div className="grid col_span_4 section_card_body">
							<div className="section_card_body">
								<NoDataAvailableLoader chartType="tableChartType" />
							</div>
						</div>
					</div>
				</Wrapper>
			);
		} else {
			campaignSummaryTable = (
				<Wrapper>
					<ErrorBoundary>
						<Datatable
							tableTitle={"Ad / Campaign Summary"}
							tableHeader={campaignSummaryTableHeaders}
							tableData={campaignSummary}
							tableLength={10}
							isFooterShow={true}
							searchPlaceHolder={"Search Ads / Campaigns"}
							tableID="campaign_summary_table"
							showGrowthDegrowth={true}
							tableExportDropdown={
								<ExportDropdown
									sectionId={"campaign_summary_table"}
									sectionName={`TvHaleonSummaryTable_Ad Campaign Summary_${marketCodeLabel + stateCodeLabel}`}
									sectionData={campaignSummary}
								/>
							}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (campaignSummaryerror) {
		campaignSummaryTable = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
					<div className="grid col_span_4">
						<div className="section_card_header">
							<h2 className="section_card_title">
								Ad/ Campaign Summary
							</h2>
						</div>
					</div>
					<div className="grid col_span_4 section_card_body">
						<div className="section_card_body">
							<ServerErrorsLoader
								error={campaignSummaryerror}
								chartType="tableChartType"
							/>
						</div>
					</div>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isCampaignSummaryLoading) {
		campaignSummaryTable = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
					<div className="grid col_span_4">
						<div className="section_card_header">
							<h2 className="section_card_title">
								Ad/ Campaign Summary
							</h2>
						</div>
					</div>
					<div className="grid col_span_4 section_card_body">
						<div className="section_card_body">
							<Loader loaderType="smallTableLoader" />
						</div>
					</div>
				</div>
			</Wrapper>
		);
	}

	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// -----------------------------------------------------
	// TOP POST COMPARE

	// TO FETCH DATA FROM API

	useEffect(() => {
		const fetchTopPostsCompareHandler = async () => {
			setTopPostsCompareIsLoading(true);
			setTopPostsCompareIsLoaded(false);
			setTopPostsCompareError(null);

			const config = {
				params: {
					start_date: selectedDates.startDate,
					end_date: selectedDates.endDate,
					previous_start_date: selectedDates.prevStartDate,
					previous_end_date: selectedDates.prevEndDate,
					state_code: mapStateCode,
					market_code: mapMarket,
					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
				},
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authCtx.token,
				},
			};

			try {
				const res = await axios.get("ri/tv/haleon-tv-compare-brands/", config);
				setTopPostsCompare(res?.data ?? []);
				setTopPostsCompareIsLoading(false);
				setTopPostsCompareIsLoaded(true);
				setTopPostsCompareError(null);
			} catch (error) {
				setTopPostsCompare([]);
				setTopPostsCompareIsLoading(false);
				setTopPostsCompareIsLoaded(false);
				setTopPostsCompareError(error.code);
			}
		};
		fetchTopPostsCompareHandler();
	}, [
		authCtx,
		selectedDates,
		mapStateCode,
		mapMarket,
		selectedTagNames
	]);

	// TOP POSTS COMPARE
	let topPostsCompareFeat = (
		<Wrapper>
			<div className="grid col_span_4 section_card_header">
				<h2 className="section_card_title">Top Brands</h2>
			</div>
			<div className="grid col_span_4">
				<Loader loaderType="cardLoader" variantType="compare" />
			</div>
		</Wrapper>
	);

	let topPostsCompareHeaderTitle = "Brands";

	// IF DATA IS LOADED
	if (isTopPostsCompareLoaded && !isTopPostsCompareLoading) {
		if (NoDataAvailableChecker(topPostsCompare)) {
			topPostsCompareFeat = (
				<Wrapper>
					<div className="grid grid_cols_4 grid_margin_bottom">
						<div className="grid col_span_4 section_card_header">
							<h2 className="section_card_title">Top Brands</h2>
						</div>
						<div className="grid col_span_4 grid_margin_bottom">
							<NoDataAvailableLoader chartType="cardChartType" />
						</div>
					</div>
				</Wrapper>
			);
		} else {
			topPostsCompareFeat = (
				<Wrapper>
					<ErrorBoundary chartType="cardChartType">
						<CompareTvBrands
							mapStateCode={mapStateCode}
							mapMarket={mapMarket}
							products={topPostsCompare}
							isPaid={false}
							Title={topPostsCompareHeaderTitle}
							hasExportDropdown={true}
							isTVHaleon={true}
						/>
					</ErrorBoundary>
				</Wrapper>
			);
		}
	}

	// IF THERE IS SOME ERROR FROM SERVER
	if (topPostsCompareerror) {
		topPostsCompareFeat = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom">
					<div className="grid col_span_4 section_card_header">
						<h2 className="section_card_title">Top Brands</h2>
					</div>
					<div className="grid col_span_4 grid_margin_bottom">
						<ServerErrorsLoader
							chartType="cardChartType"
							error={topPostsCompareerror}
						/>
					</div>
				</div>
			</Wrapper>
		);
	}

	// IF DATA LOADING
	if (isTopPostsCompareLoading) {
		topPostsCompareFeat = (
			<Wrapper>
				<div className="grid grid_cols_4 grid_margin_bottom">
					<div className="grid col_span_4 section_card_header">
						<h2 className="section_card_title">Top Brands</h2>
					</div>
					<div className="grid col_span_4">
						<Loader loaderType="cardLoader" variantType="compare" />
					</div>
				</div>
			</Wrapper>
		);
	}




	const activePlatform = (platform) => {
		setSelectedBrandPlatform(platform.brand_id);
	};

	// TO ADD BRANDS SEARCH QUERRY IN URL
	useEffect(() => {
		setSearchParams({
			brands:
				selectedBrandPlatform === null
					? "overall"
					: selectedBrandPlatform,
		});
	}, [selectedBrandPlatform]);

	var combineCSV = function (data) {
		var temp =
			data === undefined || null
				? []
				: Object.values(data).map((item) => {
					var date = new Date(item.label);

					return { ...item, label: date.toLocaleDateString("en-GB") };
				});

		return temp;
	};



	var performanceTrendExport = {
		[`DateFirstNonActiveDropdownLabels_Performance Trend_${marketCodeLabel + stateCodeLabel}`]: combineCSV(perfTrend),
		[`TableDynaLabels_Performance Trend Table_${marketCodeLabel + stateCodeLabel}`]: performanceTableData
	}

	var zeroNonLabel = zeroNonZeroDropdownValue === undefined || zeroNonZeroDropdownValue === null ? "" : zeroNonZeroDropdownValue.label === undefined || zeroNonZeroDropdownValue.label === null ? "" : zeroNonZeroDropdownValue.label
	var zeroNonZeroExport = {
		[`DonutWithActiveDropdown_Zero GRP Spots_${zeroNonLabel + marketCodeLabel + stateCodeLabel}`]: zeroGrp,
		[`DonutWithActiveDropdown_Non Zero Spots_${zeroNonLabel + marketCodeLabel + stateCodeLabel}`]: nonZeroGrp,
	}

	var campaignImpactExport = {
		[`DonutWithNonActiveDropdown_Impact vs Regular_${marketCodeLabel + stateCodeLabel}`]: campaignImpact,
		[`TableDynaLabels_Impact_${marketCodeLabel + stateCodeLabel}`]: campaignImpactFirstTableData,
		[`TableDynaLabels_Regular_${marketCodeLabel + stateCodeLabel}`]: campaignImpactSecondTableData,
	}

	var adPositionExport = {
		[`PieofPieDynaLabels_Ad Position Wise_${marketCodeLabel + stateCodeLabel}`]: adPosition,
		[`TvHaleonAdPosition_Ad Position Table_${marketCodeLabel + stateCodeLabel}`]: adPosition,
	}

	var ptNptGenreExport = {
		[`TVHaleonPtNPTChart_PT vs NPT Genre_${marketCodeLabel + stateCodeLabel}`]: ptNonPt,
		[`TVHaleonPtNPTTable_Pt vs NPT Table_${marketCodeLabel + stateCodeLabel}`]: ptNonPtTable

	}

	var allData = {
		Summary: exportSummary,
		[`TVHaleonIndiaChart_India_India Map`]: MapData.features,
		[`TVHaleoncards_Brand Performance_${marketCodeLabel + stateCodeLabel}`]: metricCards,
		[`SummaryCardDynaLabels_Performance Metrics_${marketCodeLabel + stateCodeLabel}`]: performanceMetricCards,
		...performanceTrendExport,
		[`TopGenreTvHaleon_Top Genre and GRPs_${marketCodeLabel + stateCodeLabel}`]: TopGenreData,
		[`AdDurationWiseTVHaleon_AD Duration Wise GRP_${marketCodeLabel + stateCodeLabel}`]: AdDurationData,
		[`TVBrandWiseTreemapHaleon_Brand Wise GRP and Spends_${marketCodeLabel + stateCodeLabel}`]: simpleTreemapData,
		...zeroNonZeroExport,
		...campaignImpactExport,
		...adPositionExport,
		...ptNptGenreExport,
		[`CumuReachTvHaleon_Cumulative Reach Analysis_${marketCodeLabel + stateCodeLabel}`]: cumulativeReachAnalysis,
		[`TvHaleonSummaryTable_Ad Campaign Summary_${marketCodeLabel + stateCodeLabel}`]: campaignSummary,
		"TvHaleonCompare_Top Brands": topPostsCompare,
	};
	useEffect(() => {
		setExportPageName("TV");
		setFullPageExport(allData);
	}, [
		MapData,
		metricCards,
		perfTrend,
		performanceMetricCards,
		performanceTableData,
		TopGenreData,
		AdDurationData,
		simpleTreemapData,
		zeroGrp,
		nonZeroGrp,
		campaignImpact,
		campaignImpactFirstTableData,
		campaignImpactSecondTableData,
		adPosition,
		cumulativeReachAnalysis,
		campaignSummary,
		topPostsCompare,
	]);

	useEffect(() => {
		if (
			(MapError !== null ? true : isMapLoaded && (isMapLoading === false)) &&
			(metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
			(perfTrenderror !== null ? true : isPerfTrendLoaded && (isPerfTrendLoading === false)) &&
			(performanceMetricCardserror !== null ? true : isPerformanceMetricCardsLoaded && (isPerformanceMetricCardsLoading === false)) &&
			(TopGenreError !== null ? true : isTopGenreLoaded && (isTopGenreLoading === false)) &&
			(AdDurationError !== null ? true : isAdDurationLoaded && (isAdDurationLoading === false)) &&
			(simpleTreemapDataError !== null ? true : isSimpleTreemapDataLoaded && (isSimpleTreemapDataLoading === false)) &&
			(zeroGrperror !== null ? true : isZeroGrpLoaded && (isZeroGrpLoading === false)) &&
			(NonZeroGrperror !== null ? true : isNonZeroGrpLoaded && (isNonZeroGrpLoading === false)) &&
			(campaignImpacterror !== null ? true : isCampaignImpactLoaded && (isCampaignImpactLoading === false)) &&
			(adPositionerror !== null ? true : isAdPositionLoaded && (isAdPositionLoading === false)) &&
			(cumulativeReachAnalysiserror !== null ? true : iCumulativeReachAnalysisLoaded && (iCumulativeReachAnalysisLoading === false)) &&
			(campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false)) &&
			(topPostsCompareerror !== null ? true : isTopPostsCompareLoaded && (isTopPostsCompareLoading === false))
		) {
			setIsExportDisabled(false)
		} else {
			setIsExportDisabled(true)
		}
	}, [
		MapError, isMapLoaded, isMapLoading,
		metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
		perfTrenderror, isPerfTrendLoaded, isPerfTrendLoading,
		performanceMetricCardserror, isPerformanceMetricCardsLoaded, isPerformanceMetricCardsLoading,
		TopGenreError, isTopGenreLoaded, isTopGenreLoading,
		AdDurationError, isAdDurationLoaded, isAdDurationLoading,
		simpleTreemapDataError, isSimpleTreemapDataLoaded, isSimpleTreemapDataLoading,
		zeroGrperror, isZeroGrpLoaded, isZeroGrpLoading,
		NonZeroGrperror, isNonZeroGrpLoaded, isNonZeroGrpLoading,
		campaignImpacterror, isCampaignImpactLoaded, isCampaignImpactLoading,
		adPositionerror, isAdPositionLoaded, isAdPositionLoading,
		cumulativeReachAnalysiserror, iCumulativeReachAnalysisLoaded, iCumulativeReachAnalysisLoading,
		campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
		topPostsCompareerror, isTopPostsCompareLoaded, isTopPostsCompareLoading,
	])


	useEffect(() => {
		setexportSummary([
			selectedDates,
			{
				"Brand ID": selectedBrandPlatform === undefined || selectedBrandPlatform === null ? "" : selectedBrandPlatform,
				"AD Names": selectedAdNames.join(",\n"),
				"Tag Names": selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : "",
			},
		]);
	}, [
		selectedDates,
		selectedBrandPlatform,
		selectedAdNames,
		selectedTagNames
	]);

	return (
		<Wrapper>
			<div className="grid drawer_grid">
				<div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper" id="india_map">
					<div className="section_card">
						<div className="mapHeaderWrapper w_100">
							<div className="grid col_span_4">
								<div className="section_card_header mapHeader">
									<div className="section_info">
										<h2 className="section_card_title card_map_title">India</h2>
									</div>
									<div data-html2canvas-ignore={true}>
										<ExportDropdown
											sectionId={"india_map"}
											sectionName={`TVHaleonIndiaChart_India Map`}
											sectionData={MapData.features}
										/>
									</div>
								</div>
							</div>
							<div className="grid col_span_4">
								<div className="section_card_header map_breadcrumb_header">
									{mapBreadCrumbList.map((item, index) => (
										<div
											onClick={() => {
												mapBreadcrumbsHandler(index, item);
											}}
											key={index}
										>
											{index === 0 ? " " : "/ "}
											<TextFormatter string={item} />
										</div>
									))}
								</div>
							</div>
						</div>

						<div className="section_card_body mapBoxContainer">
							<div className="grid grid_cols_4 grid_margin_bottom">
								<div className="grid col_span_4">
									<div className="section_card_body">
										<div className="section_card_map_wrapper">
											{IndiaMapChart}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="mapBoxContainer">
							<div className="w_100">
								<ul className="icz-rilist-inline">
									<li>
										<div className="legend-circle icz-rilist-bullet1"></div>
										North
									</li>
									<li>
										<div className="legend-circle icz-rilist-bullet2"></div>
										West
									</li>
									<li>
										<div className="legend-circle icz-rilist-bullet3"></div>
										South
									</li>
									<li>
										<div className="legend-circle icz-rilist-bullet4"></div>
										East
									</li>
								</ul>
							</div>
							<div className="w_100">
								<ul className="icz-rilist-inline">
									<li>
										<div className="legend-circle circleGreen"></div> Growth
									</li>
									<li>
										<div className="legend-circle circleRed"></div> Degrowth
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="right_side col_span_3 relative-right-grid">
					<Wrapper>
						<div className="content">
							<div className="content_section">
								{selectedAdNames.length !== 0 && selectedAdNames !== undefined && (
									<div className="header_badges_wrapper">
										<div className="badges_list_wrapper">
											<div className="badge_wrapper">
												<div className="button_badge">
													{selectedBrandIds.length} Brands Selected
												</div>
											</div>
											<div className="badge_wrapper">
												<div className="button_badge">
													{selectedAdNames.length} Ads Selected
												</div>
											</div>
										</div>
									</div>
								)}
								<PageHeader pageTitle={"TV"} />
								<div className="page_content">
									<div className="dashboard_home">
										{metricCardsSection}

										<Wrapper>
											{performanceMetricCardsSection}
										</Wrapper>
										
										
										
										<div id="Performance_Trend_Section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
											<div className="grid col_span_4">
												<div className="section_card_header">
													<div className="section_info">
														<h2 className="section_card_title">
															Performance Trend
														</h2>
													</div>
													<Modal
                                open={isPerfTrendOpen}
                                setOpen={setIsPerfTrendOpen}
                                
                                exportDropdown={
                                  <ExportDropdown
                                    sectionId={
                                      "social_facebook_current_period_2"
                                    }
                                    sectionName={"Performance Trend"}
                                    sectionData={combineCSV(perfTrend)}
                                  />
                                }
                                parentId={"Performance_Trend_Section"}
                                title={`Performance Trend`}
                                chart={perfTrendChart2}
                              />


													<div data-html2canvas-ignore={true}>
													{isMultiLineCluster && (
                                  <button
                                    onClick={() => setIsPerfTrendOpen(true)}
                                    className="icon_btn btn-small-secondary expand_btn"
                                  >
                                    <ExpandIcon />
                                  </button>
                                )}
														<ExportDropdown
															sectionId={"Performance_Trend_Section"}
															sectionName={"Performance Trend"}
															sectionData={performanceTrendExport}
														/>
													</div>
												</div>
											</div>
											<div className="grid grid_cols_3">
												<div className="grid col_span_2">
													<div className="section_card_body">
														<div className="section_card_chart_wrapper">
															{perfTrendChart}
														</div>
													</div>
												</div>
												<div className="grid col_span_1">
													<div className="section_card_body">
														{perfTrendTable}
													</div>
												</div>
											</div>
										</div>






										<div id="top_genre_grp_section" className="grid grid_cols_2 grid_margin_bottom">
											<div className="grid col_span_1 section_card">
												<div className="section_card_header">
													<div className="section_info">
														<h2 className="section_card_title">
															Top Genre and GRPs
														</h2>
													</div>
													<div data-html2canvas-ignore={true}>
														<ExportDropdown
															sectionId={"top_genre_grp_section"}
															sectionName={`TopGenreTvHaleon_Top Genre and GRPs_${marketCodeLabel + stateCodeLabel}`}
															sectionData={TopGenreData}
														/>
													</div>
												</div>
												<div className="grid grid_cols_1 section_card_body">
													<div className="section_card_chart_wrapper">
														{topGenreBarChart}
													</div>
												</div>
											</div>

											<div id="ad_duration_wise_grp_section" className="grid col_span_1 section_card">
												<div className="section_card_header">
													<div className="section_info">
														<h2 className="section_card_title">
															AD Duration Wise GRP
														</h2>
													</div>
													<div data-html2canvas-ignore={true}>
														<ExportDropdown
															sectionId={"ad_duration_wise_grp_section"}
															sectionName={`AdDurationWiseTVHaleon_AD Duration Wise GRP_${marketCodeLabel + stateCodeLabel}`}
															sectionData={AdDurationData}
														/>
													</div>
												</div>
												<div className="grid grid_cols_1 section_card_body">
													<div className="section_card_chart_wrapper">
														{adDurationBarChart}
													</div>
												</div>
											</div>
										</div>

										<div id="Brand_Wise_GRP_and_Spends_Section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
											<div className="grid col_span_4">
												<div className="section_card_header p_b_0 ">
													<div className="flex column align_start">
														<div className="section_info">
															<h2 className="section_card_title">
																Brand Wise GRP and Spends
															</h2>
														</div>
														{Object.keys(simpleTreemapData).length !==
															0 && (
																<div className="treemap_breadcrumb">
																	<span
																		onClick={() => {
																			setTreemapParams({
																				brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
																				start_date: selectedDates.startDate,
																				end_date: selectedDates.endDate,
																				previous_start_date: selectedDates.prevStartDate,
																				previous_end_date: selectedDates.prevEndDate,
																				state_code: mapStateCode,
																				market_code: mapMarket,
																				ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
																				age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
																				level: 1,
																			});
																			setTreemapBrand("");
																			setTreemapBrandCategory("");
																		}}
																	>
																		Home
																	</span>
																	{treemapBrand && (
																		<span
																			onClick={() => {
																				setTreemapParams({
																					brand_id: selectedBrandPlatform === undefined || selectedBrandPlatform === null ? null : selectedBrandPlatform,
																					start_date: selectedDates.startDate,
																					end_date: selectedDates.endDate,
																					previous_start_date: selectedDates.prevStartDate,
																					previous_end_date: selectedDates.prevEndDate,
																					state_code: mapStateCode,
																					market_code: mapMarket,
																					ad_name: selectedAdNames.length !== 0 || selectedAdNames === undefined ? selectedAdNames.join(",") : null,
																					age_gender: selectedTagNames.length !== 0 || selectedTagNames === undefined ? selectedTagNames.value : null,
																					level: 2,
																				});
																				setTreemapBrandCategory("");
																			}}
																		>
																			{" "}
																			/{" "}
																			<TextFormatter string={treemapBrand} />
																		</span>
																	)}

																	{treemapBrandCategory && (
																		<span>
																			{" "}
																			/{" "}
																			<TextFormatter
																				string={treemapBrandCategory}
																			/>
																		</span>
																	)}
																</div>
															)}
													</div>
													<div data-html2canvas-ignore={true}>
														<ExportDropdown
															sectionId={"Brand_Wise_GRP_and_Spends_Section"}
															sectionName={`TVBrandWiseTreemapHaleon_Brand Wise GRP and Spends_${marketCodeLabel + stateCodeLabel}`}
															sectionData={simpleTreemapData}
														/>
													</div>
												</div>
											</div>
											<div className="grid col_span_4">
												<div className="section_card_body">
													<div className="section_card_chart_wrapper flex column">
														{sampleTreemapChart}
													</div>
													{Object.keys(simpleTreemapData).length !== 0 && (
														<div className="w_100">
															<ul className="icz-rilist-inline">
																<li>
																	<div className="legend-circle circleGreen"></div>
																	Growth
																</li>
																<li>
																	<div className="legend-circle circleOrange"></div> Neutral
																</li>
																<li>
																	<div className="legend-circle circleRed"></div>
																	Degrowth
																</li>
															</ul>
														</div>
													)}
												</div>
											</div>
										</div>

										<div id="zero_nonzero_grp" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
											<div className="grid col_span_4">
												<div className="section_card_header">
													<div className="section_info">
														<h2 className="section_card_title">
															Zero GRP v/s Non Zero GRP Spots
														</h2>
													</div>
													<div data-html2canvas-ignore={true}>
														<Dropdown
															ismulti={false}
															isClearable={false}
															placeholder={"Select Spots"}
															options={zeroNonZeroDropdownOptions}
															className="form_dropdown section_dropdown"
															value={zeroNonZeroDropdownValue}
															selectedOptions={zeroNonZeroDropdownValue}
															setStatedropdown={zeroNonZeroDropdownHandler}
															dropdownLoading={zeroNonZeroDropdownOptionsLoading}
														/>
														<ExportDropdown
															sectionId={"zero_nonzero_grp"}
															sectionName={"Zero GRP v/s Non Zero"}
															sectionData={zeroNonZeroExport}
														/>

													</div>
												</div>
											</div>
											<div className="grid grid_cols_4">
												<div className="grid col_span_2 section_card_body">
													<div className="section_card_chart_wrapper">
														{ZeroGrpFirstDonut}
													</div>
												</div>
												<div className="grid col_span_2 section_card_body">
													<div className="section_card_chart_wrapper">
														{NonZeroGrpSecondDonut}
													</div>
												</div>
											</div>
										</div>

										<div id="campaign_impact" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
											<div className="grid col_span_4">
												<div className="section_card_header">
													<div className="section_info">
														<h2 className="section_card_title">
															Campaign Impact v/s Regular Comparison
														</h2>
													</div>
													<div data-html2canvas-ignore={true}>
														<ExportDropdown
															sectionId={"campaign_impact"}
															sectionName={"Impact vs Regular"}
															sectionData={campaignImpactExport}
														/>

													</div>
												</div>
											</div>
											<div className="grid grid_cols_3">
												<div className="grid col_span_1 section_card_body">
													<div className="section_card_chart_wrapper ">
														{campaignImpactSection}
													</div>
												</div>
												<div className="grid col_span_1 section_card_body">
													<div className="section_card_chart_wrapper">
														<h3 className="section_sub_title">Impact</h3>
														{campaignImpactTableFirst}
													</div>
												</div>
												<div className="grid col_span_1 section_card_body">
													<div className="section_card_chart_wrapper">
														<h3 className="section_sub_title">Regular</h3>
														{campaignImpactTableSecond}
													</div>
												</div>
											</div>
										</div>

										<div id="Ad_Position_Wise_GRP_Section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
											<div className="grid col_span_4">
												<div className="section_card_header">
													<div className="section_info">
														<h2 className="section_card_title">
															Ad Position Wise GRP
														</h2>
													</div>
													<div data-html2canvas-ignore={true}>
														<ExportDropdown
															sectionId={"Ad_Position_Wise_GRP_Section"}
															sectionName={"PieofPie_Ad Position Wise GRP"}
															sectionData={adPositionExport}
														/>
													</div>
												</div>
											</div>
											<div className="grid grid_cols_3">
												<div className="grid col_span_2 section_card_body">
													<div className="section_card_chart_wrapper p_t_0 p_b_0">
														{adPositionPie}
													</div>
												</div>
												<div className="grid col_span_1">
													<div className="section_card_body">
														{adPositionTable}
													</div>
												</div>
											</div>
										</div>

										<div id="pt_non_pt" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
											<div className="section_card_header">
												<div className="section_info">
													<h2 className="section_card_title">
														PT v/s NPT Genre Analysis
													</h2>
												</div>
												<div data-html2canvas-ignore={true}>
													<ExportDropdown
														sectionId={"pt_non_pt"}
														sectionName={"PT v/s NPT Genre"}
														sectionData={ptNptGenreExport}
													/>
												</div>
											</div>
											<div className="grid grid_cols_5">
												<div className="grid col_span_3 section_card_body">
													<div className="section_card_chart_wrapper">
														{ptNonPtSection}
													</div>
												</div>
												<div className="grid col_span_2 section_card_body">
													<div className="section_card_chart_wrapper">
														{ptNonPtTableSection}
													</div>
												</div>
											</div>
										</div>

										<div id="cumulative_reach_analysis" className="grid grid_cols_4  fullpage_section_card section_card grid_margin_bottom">
											<div className="grid col_span_4">
												<div className="section_card_header">
													<div className="section_info">
														<h2 className="section_card_title">Cumulative Reach Analysis</h2>
													</div>
													<div data-html2canvas-ignore={true}>
														<ExportDropdown
															sectionId={"cumulative_reach_analysis"}
															sectionName={`CumuReachTvHaleon_Cumulative Reach Analysis_${marketCodeLabel + stateCodeLabel}`}
															sectionData={cumulativeReachAnalysis}
														/>
													</div>
												</div>
											</div>
											<div className="grid col_span_4">
												<div className="section_card_body">
													<div className="section_card_chart_wrapper">
														{cumulativeReachAnalysisChart}
													</div>
												</div>
											</div>
										</div>
										<div className="grid_margin_bottom">
											{campaignSummaryTable}
										</div>

										<div className="grid grid_cols_4 fullpage_section_card section_card riCompare">
											<div className="section_card_body">
												{topPostsCompareFeat}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Wrapper>
				</div>
			</div>
		</Wrapper>
	);
};
export default TVHaleon;
