// PACKAGES
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

// STORE
import AuthContext from "./store/authContext";
import FullPageExportContext from "./store/fullPageExportContext";

// COMPONENTS
import RouteUrls from "./components/helpers/RouteUrls";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/sidebar/Sidebar";
import Wrapper from "./components/helpers/Wrapper";
import ErrorBoundary from "./utils/ErrorBoundary";
import withClearCache from "./ClearCache";

// STYLE
import "./App.scss";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [fullPageExport, setFullPageExport] = useState({});
  const [fullPageExportHeader, setFullPageExportHeader] = useState({})
  const [exportSummary, setexportSummary] = useState(null)
  const [exportPageName, setExportPageName] = useState(null)
  const [isExportDisabled, setIsExportDisabled] = useState(true)

  let tempFoo = {};
  var arr1 = [];
  var arr2 = [];
  const fooTemp = {};

  const value = { fooTemp, arr1, arr2, tempFoo, exportPageName, fullPageExport, setFullPageExport, setFullPageExportHeader, exportSummary, setexportSummary, setExportPageName, isExportDisabled, setIsExportDisabled };
  return (
    <Wrapper>
      <div className="App">

        {/* <Provider store={store}> */}

        <div
          className="dashboard_container"
          id={`${splitLocation[splitLocation.length - 1] === '' ? 'Dashboard' : splitLocation[splitLocation.length - 1]}_section`}
        >
          {isLoggedIn && (
            <ErrorBoundary>
              <Header
                identity={`${splitLocation[splitLocation.length - 1] === '' ? 'Dashboard' : splitLocation[splitLocation.length - 1]}_section`}
                name={exportPageName !== null ? exportPageName : splitLocation[splitLocation.length - 1] === '' ? 'Dashboard' : splitLocation[splitLocation.length - 1]}
                data={fullPageExport}
                headers={fullPageExportHeader}
                disabled={isExportDisabled}
              />
            </ErrorBoundary>
          )}
          {isLoggedIn && (
            <ErrorBoundary>
              <Sidebar />
            </ErrorBoundary>
          )}
          <main
            role="main"
            className={isLoggedIn ? "main_page" : "main_page unauth_page"}
          >
            <FullPageExportContext.Provider value={value}>
              <ErrorBoundary>
                <RouteUrls />
              </ErrorBoundary>
            </FullPageExportContext.Provider>
          </main>
        </div>
        {/* </Provider> */}
      </div>
    </Wrapper>
  );
}

export default App;
