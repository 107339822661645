import { useContext, useEffect, useState } from "react";
import AuthContext from "../store/authContext";
import AXIOS from "../components/helpers/axios"

function useApi() {
    const authCtx = useContext(AuthContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const LOGIN_HEADERS = JSON.parse(process.env.REACT_APP_API_HEADERS_FORM)
    const HEADERS = JSON.parse(process.env.REACT_APP_API_HEADERS);

    if (authCtx.token !== undefined) {
        HEADERS["Authorization"] = `Bearer ${authCtx.token}`;
    }

    const [data, setData] = useState(null);
    const [apiStatus, setAPIStatus] = useState({
        isLoading: false,
        isLoaded: false,
        error: null,
    });

    // useEffect(() => {
    //     if (apiStatus.error !== null && apiStatus.error.error.status === 401 && apiStatus.error.error.message === "Unauthorized: The provided authentication token has expired.") {
    //         navigate("/login");
    //         dispatch(logout());
    //     }
    // }, [apiStatus.error]);

    const fetchData = async (url, params) => {
        if (params === undefined) {
            params = null;
        }

        setAPIStatus((prevStatus) => ({
            ...prevStatus,
            isLoading: true,
            isLoaded: false,
            error: null,
        }));

        try {
            // Construct URL with query parameters
            const queryParams = new URLSearchParams(params).toString();
            const URL = `${API_URL}${url}${params ? `?${queryParams}` : ""}`;

            const headers = HEADERS;

            const response = await fetch(URL, {
                method: "GET",
                headers: headers,
            });

            const data = await response.json();

            if (data.error) {
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: data,
                }));
            } else {
                setData(data);
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: null,
                }));
            }
        } catch (error) {
            setAPIStatus((prevStatus) => ({
                ...prevStatus,
                isLoading: false,
                isLoaded: true,
                error: error,
            }));
        }
    };

    const login = async (url, body) => {
        setAPIStatus((prevStatus) => ({
            ...prevStatus,
            isLoading: true,
            isLoaded: false,
            error: null,
        }));
        setData(null);

        try {
            const res = await AXIOS.post(url, body, {
                headers: LOGIN_HEADERS,
            });
            if (res.data) {
                setData(res.data);
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: null,
                }));
            } else {
                setData(null);
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: res.message,
                }));
            }
        } catch (error) {
            setData(null);
            setAPIStatus((prevStatus) => ({
                ...prevStatus,
                isLoading: false,
                isLoaded: true,
                error: error.response.data.error,
            }));
        }
    };

    const postData = async (url, body, successMessage) => {
        setAPIStatus((prevStatus) => ({
            ...prevStatus,
            isLoading: true,
            isLoaded: false,
            error: null,
        }));
        setData(null);

        try {
            const URL = `${API_URL}${url}`;

            const headers = HEADERS;

            const response = await fetch(URL, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            });

            const data = await response.json();

            if (data.error) {
                setData(null);
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: data,
                }));
            } else {
                setData("success");
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: null,
                }));
            }
        } catch (error) {
            setData(null);
            setAPIStatus((prevStatus) => ({
                ...prevStatus,
                isLoading: false,
                isLoaded: true,
                error: error,
            }));
        }
    };

    const putData = async (url, body, successMessage) => {
        setAPIStatus((prevStatus) => ({
            ...prevStatus,
            isLoading: true,
            isLoaded: false,
            error: null,
        }));
        setData(null);

        try {
            const URL = `${API_URL}${url}`;

            const headers = HEADERS;

            const response = await fetch(URL, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(body),
            });

            const data = await response.json();

            if (data.error) {
                setData(null);
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: data,
                }));
            } else {
                setData("success");
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: null,
                }));
            }
        } catch (error) {
            setData(null);
            setAPIStatus((prevStatus) => ({
                ...prevStatus,
                isLoading: false,
                isLoaded: true,
                error: error,
            }));
        }
    };

    const deleteData = async (url, successMessage) => {
        setData(null);
        setAPIStatus((prevStatus) => ({
            ...prevStatus,
            isLoading: true,
            isLoaded: false,
            error: null,
        }));
        try {
            const URL = `${API_URL}${url}`;

            const headers = HEADERS;

            const response = await fetch(URL, {
                method: "DELETE",
                headers: headers,
            });

            const data = await response.json();

            if (data.error) {
                setData(null);
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: true,
                    isLoaded: false,
                    error: data,
                }));
            } else {
                setData("success");
                setAPIStatus((prevStatus) => ({
                    ...prevStatus,
                    isLoading: false,
                    isLoaded: true,
                    error: null,
                }));
            }
        } catch (error) {
            setData(null);
            setAPIStatus((prevStatus) => ({
                ...prevStatus,
                isLoading: true,
                isLoaded: false,
                error: error,
            }));
        }
    };

    return {
        data,
        apiStatus,
        fetchData,
        login,
        postData,
        putData,
        deleteData,
    };
}

export default useApi;
