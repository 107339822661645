import Wrapper from "../helpers/Wrapper"
import IcogzLogo from "../../assets/brand/icogz-logo.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { isValidDomain, isValidEmail } from "../../utils/Utils";
import Button from "../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { setSSOData, setSSOEmail } from "../../store/ssoLogin";
import useApi from "../../hooks/useApi";
import { setUserData } from "../../store/userSlice";
import { setSidebarData } from "../../store/sidebarSlice";
import { setMultiLineCluster, setShowAllLegendsInMultiLineCluster } from "../../store/multiLineClusteredSlice";
import { API_URL, HALEON_COMPANY_ID } from "../../constants/constants";
import { getBrandsDropdownOptionsList } from "../../store/globalBrandsDropdownSlice";
import { setGlobalFilterCampaignBucket } from "../../store/userPermissionSlice";
import { setModuleData } from "../../store/moduleSlice";
import AuthContext from "../../store/authContext";
import axios from "axios";

const defaultFormData = {
  email: ""
}

const defaultFormErrors = {
  email: null
}

const SSOLoginForm = () => {
  const allDataRecordsAPI = useApi();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const formEmail = useSelector((state) => state.ssoLogin.email)

  let [searchParams, setSearchParams] = useSearchParams()

  const [formData, setFormData] = useState(defaultFormData)
  const [showSubmitBtn, setShowSubmitBtn] = useState(false)

  const [ssoLoginLoading, setssoLoginLoading] = useState(false)

  // UPDATE FORM DATA
  const updateFields = (fields) => {
    setFormData((prev) => {
      return { ...prev, ...fields };
    });
  };

  useEffect(() => {

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const fetchSSOLoginData = async () => {

      setssoLoginLoading(true)

      const res = await axios.post(API_URL + 'auth/azure/sso/callback/', {
        code: searchParams.get('code')
      }, config).catch(() => {
        setSearchParams({})
        navigate('/')
      })


      if (res !== undefined) {

        if (res.status === 200) {

          dispatch(setUserData(res.data.user_data)); // USER DATA
          dispatch(setSidebarData(res.data.side_bar)); // SIDEBAR DATA

          dispatch(setMultiLineCluster(API_URL === "https://uatapi.icogz.com/api/" ? HALEON_COMPANY_ID !== res?.data?.user_data[0]?.company?.id ? true : false : true))
          dispatch(setShowAllLegendsInMultiLineCluster(true))

          const expirationTime = new Date(new Date().getTime() + 14400 * 1000); // TO ADD AUTO LOGOUT IN SECONDS 1 SECOND = 1000
          authCtx.login(res.data.access, expirationTime.toISOString());

          window.icogz.trackEvent(
            JSON.stringify({
              email: formEmail ?? ''
            }), "User Logged In with SSO");

          fetchLoggedInData(res.data.access);

        } else {
          setSearchParams({})
        }
        navigate('/')
      }

      setssoLoginLoading(false)
    }


    if (searchParams.get('code')) {
      fetchSSOLoginData();
    }

  }, [searchParams])


  const fetchLoggedInData = async (authTok) => {
    const config = {
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authTok,
      },
    };

    try {
      const res = await axios.get(API_URL + "auth/permission-list/", config);

      if (res !== undefined) {
        let temp = [];
        res.data.brand_list.map((data) => {
          try {
            let a =
            {
              value: data.brand_id,
              label: data.brand_name,
              icon: data.brand_logo

            }

            temp.push(a);
          } catch (error) {
            console.log('error')
          }
        })

        let brandsDropdownOpts = res.data.brand_list === "" ? [] : temp;
        dispatch(getBrandsDropdownOptionsList(brandsDropdownOpts));  // BRANDS DROPWON DATA

        dispatch(setGlobalFilterCampaignBucket(res?.data?.user_permissions?.section?.global_filter_campaign_bucket ?? false)); // CAMPAIGN BUCKET FILTER 
        // dispatch(setGlobalFilterCampaignBucket(true)); // CAMPAIGN BUCKET FILTER 

        dispatch(setModuleData(res?.data?.module_data ?? [])); // MODULE DATA 

      }
    } catch (error) {
      console.log('error')
    }
  };



  const [formErrors, setFormErrors] = useState(defaultFormErrors);

  // UPDATE FORM ERRORS
  const updateErrors = (fields) => {
    setFormErrors((prev) => {
      return { ...prev, ...fields };
    });
  };

  const handleDomainNameInputChange = (e) => {
    const { value } = e.target;
    updateFields({ ...formData, email: value });
    if (value === "") {
      updateErrors({ ...formErrors, email: "Email is required" });
      setShowSubmitBtn(false)
    } else {
      if (!isValidEmail(value)) {
        updateErrors({ ...formErrors, email: "Please enter a valid email" });
        setShowSubmitBtn(false)
      } else {
        updateErrors({ ...formErrors, email: null });
        setShowSubmitBtn(true)
      }
    }
  };

  useEffect(() => {
    if (!allDataRecordsAPI.apiStatus.isLoading && allDataRecordsAPI.apiStatus.isLoaded && allDataRecordsAPI.apiStatus.error) {
      updateErrors({ ...formErrors, email: allDataRecordsAPI.apiStatus.error });
      setShowSubmitBtn(false)
    } else {
      updateErrors({ ...formErrors, email: null });
      setShowSubmitBtn(true)
    }
  }, [allDataRecordsAPI.apiStatus])

  useEffect(() => {
    if (formData.email !== "" && formErrors.email === null) {
      setShowSubmitBtn(true)
    }
  }, [formData, formErrors])

  const onFormSubmitBtnClickHandler = () => {
    checkEmailAPI()
  }

  const checkEmailAPI = async () => {
    allDataRecordsAPI.login("auth/validate/domain/azure/", formData);
  }

  useEffect(() => {
    if (!allDataRecordsAPI.apiStatus.isLoading && allDataRecordsAPI.apiStatus.isLoaded) {
      if (allDataRecordsAPI.apiStatus.error) {
        updateErrors({ ...formErrors, email: allDataRecordsAPI.apiStatus.error });
      } else {
        updateErrors({ ...formErrors, email: null });
        let tenant_id = allDataRecordsAPI?.data?.data?.tenant_id
        let client_id = allDataRecordsAPI?.data?.data?.client_id
        let redirect_url = allDataRecordsAPI?.data?.data?.redirect_url
        let authentication_url = allDataRecordsAPI?.data?.data?.authentication_url
        window.location.href = authentication_url;
        // window.open(authentication_url)
        // window.open(`https://login.microsoftonline.com/${tenant_id}/oauth2/v2.0/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_url}&scope=https://graph.microsoft.com/mail.read&grant_type=authorization_code`)
      }
    }
  }, [allDataRecordsAPI.apiStatus])

  useEffect(() => {
    if (allDataRecordsAPI.data !== null) {
      dispatch(setSSOEmail(formData.email))
      dispatch(setSSOData(allDataRecordsAPI.data.data))
    }
  }, [allDataRecordsAPI])



  return (
    <Wrapper>
      <div className={`form_wrapper`}>
        {ssoLoginLoading && <div className="form_backdrop">
          <span className="spinner-loader"></span>
        </div>}

        <div className="form_header flex column align_center p_10">
          <div className="form_header_logo">
            <img className="logo" src={IcogzLogo} alt="logo" />
          </div>
        </div>
        <div className="p_10 flex justify_center">
          <h6 className="error_text"></h6>
        </div>
        <form className="form">
          <div className="form_group">
            <input
              className={`${formErrors.email !== null && 'invalid'} form_control`}
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              placeholder="Please Enter Your Team Domain"
              value={formData.email}
              onChange={handleDomainNameInputChange}
              onBlur={handleDomainNameInputChange}
            />
            <div className="error_section">
              {formErrors.email !== null && (
                <h6 className="error_text">{formErrors.email}</h6>
              )}
            </div>
          </div>
          <div className="flex justify_between p_10">
            <Link to={"/login"}>
              <div className="help_text">Sign In with password</div>
            </Link>
            <Link to={"/register"}>
              <div className="help_text">Create new account</div>
            </Link>
          </div>
          <div className="flex justify_between">
            <div className="btn_wrapper btn_group_wrapper">
              <Button
                handleClick={onFormSubmitBtnClickHandler}
                className="primary_btn"
                disabled={!showSubmitBtn || ssoLoginLoading}
              >
                {ssoLoginLoading ? 'Loading' : 'Continue'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  )
}
export default SSOLoginForm