import { useContext, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Slider from "../../../../components/ui/Slider";
import AuthContext from "../../../../store/authContext";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import axios from "../../../../components/helpers/axios";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { useNavigate } from "react-router-dom";
import FullPageExportContext from "../../../../store/fullPageExportContext";

const BucketLists = ({ setAlertType, setAlertTypeValue }) => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // BUCKET CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  const [searchTerm, setSeachTerm] = useState("");

  // FILTER BUCKET LIST AS PER USER SEARCH
  const filteredBucketList = useMemo(() => {
    if (searchTerm === "") return metricCards;
    if (metricCards === "") return metricCards;

    const filteredList = metricCards.filter((bucket) => {
      return bucket.name.toLowerCase().includes(searchTerm.toLowerCase()) || bucket.start_date.toLowerCase().includes(searchTerm.toLowerCase()) || bucket.end_date.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, metricCards]);


  // DELETE BUCKET
  const [deleteBucketDone, setDeleteBucketDone] = useState(null);

  const editOnClickHandler = async (bucket) => {
    navigate("/admin/campaign/edit-bucket/" + bucket.id, { state: bucket });
  };

  // DELETE BUCKET
  const deleteOnClickHandler = async (bucket) => {
    setDeleteBucketDone(null)
    const config = {
      // params: {
      //   bucket: bucket,
      // },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.delete(
        "performance/google/campaign-tag/" + bucket.id,
        config
      )

      setAlertType("Admin Delete")
      setAlertTypeValue(bucket.name)
      setDeleteBucketDone(res.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  const [campaignBucketName, setCampaignBucketName] = useState([])


  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/campaign-tag/", config);
      setMetricCards(res?.data ?? []);
      setCampaignBucketName(res.data === undefined || res.data.length === 0 ? [] : res.data.map((el) => el.name))
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setCampaignBucketName([])
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(filteredBucketList)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType={"cardChartType"} />
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderWrapperClass={"tab_card_slider"}
              showSliderHeader={false}
              showNavigation={false}
              sliderTitle={""}
              slidestoshow={3}
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                426: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 4,
                },
                2100: {
                  slidesPerView: 5,
                },
              }}
            >
              {filteredBucketList.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardClass={"bucketListCard"}
                    variant={"AdminBucketList"}
                    camapaignBuckeData={data}
                    onEditButtonClick={editOnClickHandler}
                    onDeleteButtonClick={deleteOnClickHandler}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="cardChartType"
          error={metricCardserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <Loader loaderType="cardLoader" />
      </Wrapper>
    );
  }

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
  }, [selectedDates]);

  useEffect(() => {
    deleteBucketDone === "DELETED" && fetchMetricCardsHandler();
  }, [deleteBucketDone]);

  useEffect(() => {
    setFullPageExport({ "AdminBucketCard_Admin Bucket List": metricCards })
  }, [metricCards]);

  useEffect(() => {
    if (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [metricCardserror, isMetricCardsLoaded, isMetricCardsLoading])


  return (
    <Wrapper>
      <div className="flex column">
        <div style={{ "marginBottom": "20px" }}>
          <input
            className="form_control input"
            type="text"
            autoComplete="off"
            placeholder="Search Bucket"
            onChange={(event) => {
              setSeachTerm(event.target.value);
            }}
          />
        </div>
        <div className="card_wrapper">{metricCardsSection}</div>
        {searchTerm !== "" &&
          <div className="grid grid_cols_4 flex row justify_between" style={{ "marginTop": "10px" }}>
            <div className="grid col_span_2">
              <h4 className="info_text">
                Showing {filteredBucketList.length} from {metricCards.length}
              </h4>
            </div>
          </div>
        }
      </div>
    </Wrapper>
  );
};
export default BucketLists;
