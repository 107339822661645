import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// CHARTS
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import BarChart from "../../../../components/ui/charts/BarChart";
import Datatable from "../../../../components/ui/tables/Datatable";


// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import { HALEON_COMPANY_ID } from "../../../../constants/constants";



const CampaignFacebookVideo = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Facebook"
  let pagePrimaryTab = "Video"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  let AudienceDistributionSectionTitle = ""
  if (companyID === HALEON_COMPANY_ID) {
    AudienceDistributionSectionTitle = "Audience Distribution - Meta level";
  } else {
    AudienceDistributionSectionTitle = "Audience Distribution";
  }

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // MID SECTION DROPDOWN
  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "avg_time", label: "Avg. time" },
    { value: "video_play_actions", label: "Play Actions" },
    { value: "video_p25_watched_actions", label: "25% Watched Actions" },
    { value: "video_p50_watched_actions", label: "50% Watched Actions" },
    { value: "video_p75_watched_actions", label: "75% Watched Actions" },
    { value: "video_p95_watched_actions", label: "95% Watched Actions" },
    { value: "video_p100_watched_actions", label: "100% Watched Actions" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState(midSectionDropdonwOptions[0]);



  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Facebook", "Video", null, "Performance By KPI", e.label)
  };

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState(
    []
  );
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] =
    useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] =
    useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [
    femaleCountAllDropdownsDataLabels,
    setFemaleCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // STATE
  const [stateData, setStateData] = useState([]);
  const [stateAllDropdownsData, setStateAllDropdownsData] = useState([]);
  const [isStateDataLoading, setStateDataIsLoading] = useState(false);
  const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
  const [stateDataerror, setStateDataError] = useState(null);

  const StateTableDataHeaders = [
    { name: "State", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // VIDEO VIEWS ANALYSIS
  const [videoViewsAnalysis, setVideoViewsAnalysis] = useState([]);
  const [isContentAnalysisLoading, setVideoViewsAnalysisIsLoading] =
    useState(false);
  const [isContentAnalysisLoaded, setVideoViewsAnalysisIsLoaded] =
    useState(false);
  const [videoViewsAnalysiserror, setVideoViewsAnalysisError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    // fetchStateHandler();
    fetchVideoViewsAnalysisHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "avg_time") {
      setMaleCount(maleCountAllDropdownsData?.avg_time ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.avg_time ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.avg_time ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.avg_time ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.avg_time ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.avg_time ?? []);
      setCountryData(countryAllDropdownsData?.avg_time ?? []);

    } else if (
      selectedMidSectionDropdownOption.value === "video_play_actions"
    ) {

      setMaleCount(maleCountAllDropdownsData.video_play_actions);
      setMaleCountLabels(maleCountAllDropdownsDataLabels.video_play_actions);
      setTotalMaleCount(totalMaleCountAllDropdownsData.video_play_actions);
      setFemaleCount(femaleCountAllDropdownsData.video_play_actions);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels.video_play_actions);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.video_play_actions);
      setCountryData(countryAllDropdownsData.video_play_actions);

    } else if (
      selectedMidSectionDropdownOption.value === "video_p25_watched_actions"
    ) {
      setMaleCount(maleCountAllDropdownsData.video_p25_watched_actions);
      setMaleCountLabels(maleCountAllDropdownsDataLabels.video_p25_watched_actions);
      setTotalMaleCount(totalMaleCountAllDropdownsData.video_p25_watched_actions);
      setFemaleCount(femaleCountAllDropdownsData.video_p25_watched_actions);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels.video_p25_watched_actions);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.video_p25_watched_actions);
      setCountryData(countryAllDropdownsData.video_p25_watched_actions);

    } else if (
      selectedMidSectionDropdownOption.value === "video_p50_watched_actions"
    ) {
      setMaleCount(maleCountAllDropdownsData.video_p50_watched_actions);
      setMaleCountLabels(maleCountAllDropdownsDataLabels.video_p50_watched_actions);
      setTotalMaleCount(totalMaleCountAllDropdownsData.video_p50_watched_actions);
      setFemaleCount(femaleCountAllDropdownsData.video_p50_watched_actions);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels.video_p50_watched_actions);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.video_p50_watched_actions);
      setCountryData(countryAllDropdownsData.video_p50_watched_actions);

    } else if (
      selectedMidSectionDropdownOption.value === "video_p75_watched_actions"
    ) {
      setMaleCount(maleCountAllDropdownsData?.video_p75_watched_actions ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.video_p75_watched_actions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.video_p75_watched_actions ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.video_p75_watched_actions ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.video_p75_watched_actions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.video_p75_watched_actions ?? []);
      setCountryData(countryAllDropdownsData?.video_p75_watched_actions ?? []);

    } else if (
      selectedMidSectionDropdownOption.value === "video_p95_watched_actions"
    ) {
      setMaleCount(maleCountAllDropdownsData?.video_p95_watched_actions ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.video_p95_watched_actions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.video_p95_watched_actions ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.video_p95_watched_actions ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.video_p95_watched_actions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.video_p95_watched_actions ?? []);
      setCountryData(countryAllDropdownsData?.video_p95_watched_actions ?? []);

    } else if (
      selectedMidSectionDropdownOption.value === "video_p100_watched_actions"
    ) {
      setMaleCount(maleCountAllDropdownsData?.video_p100_watched_actions ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.video_p100_watched_actions ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.video_p100_watched_actions ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.video_p100_watched_actions ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.video_p100_watched_actions ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.video_p100_watched_actions ?? []);
      setCountryData(countryAllDropdownsData?.video_p100_watched_actions ?? []);

    } else {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
      setCountryData([]);
      // setStateData([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/video-metrics/",
        config
      );
      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (
      NoDataAvailableChecker(metricCards)
    ) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="facebook"
                    section="video"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/video-audience/",
        config
      );

      setMaleCount(res?.data?.male_list?.data?.avg_time ?? []);
      setMaleCountLabels(res?.data?.dimension?.avg_time ?? []);
      setTotalMaleCount(res?.data?.male_count?.avg_time ?? []);
      setFemaleCount(res?.data?.female_list?.data?.avg_time ?? []);
      setFemaleCountLabels(res?.data?.dimension?.avg_time ?? []);
      setTotalFemaleCount(res?.data?.female_count?.avg_time ?? []);

      setMaleCountAllDropdownsData(res?.data?.male_list?.data ?? []);
      setMaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalMaleCountAllDropdownsData(res?.data?.male_count ?? []);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountAllDropdownsData(res?.data?.female_list?.data ?? []);
      setFemaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalFemaleCountAllDropdownsData(res?.data?.female_count ?? []);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCount([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCount([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = (
    <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  );
  let maleAudienceDistributionIcon = (
    <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>
  );

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount / (totalMaleCount + totalFemaleCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (NoDataAvailableChecker(maleCount) || NoDataAvailableChecker(maleCountLabels)) {
      maleAudienceDistribution = (
        <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
                showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper><NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper><ServerErrorsLoader chartType="barChartType" error={maleCounterror} /></Wrapper>
    );
    maleAudienceDistributionIcon = (
      <Wrapper><ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
    maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = (
    <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  );
  let femaleAudienceDistributionIcon = (
    <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) || NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper><NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
                showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalFemaleCountPercentage)) {
      femaleAudienceDistributionIcon = (
        <Wrapper><NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper><ServerErrorsLoader chartType="barChartType" error={femaleCounterror} /></Wrapper>
    );
    femaleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;
    femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/video-audience-by-country/",
        config
      );
      setCountryData(res?.data?.data?.avg_time ?? []);
      setCountryAllDropdownsData(res?.data?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By Country"
              hideBoxShadow={true}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // // STATE MAP

  // const fetchStateHandler = async () => {
  //   setStateDataIsLoading(true);
  //   setStateDataIsLoaded(false);
  //   setStateDataError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       objective: selectedObjectives,
  //       platform_campaign: selectedIdandPlatform,
  //       platform: selectedPlatform,
  //       campaign: selectedCampaigns,
  //         campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "performance/facebook/video-audience-by-region/",
  //       config
  //     );
  //     setStateData(
  //       res.data === undefined || res.data.length === 0 ? [] :
  //         res.data.data === undefined || res.data.data.length === 0 ? [] :
  //           res.data.data.avg_time === undefined || res.data.data.avg_time.length === 0 ? [] :
  //             res.data.data.avg_time
  //     );
  //     setStateAllDropdownsData(res.data === undefined || res.data.length === 0 ? [] : res.data);
  //     setStateDataIsLoading(false);
  //     setStateDataIsLoaded(true);
  //     setStateDataError(null);
  //   } catch (error) {
  //     setStateAllDropdownsData([]);
  //     setStateDataIsLoading(false);
  //     setStateDataIsLoaded(false);
  //     setStateDataError(error.code);
  //   }
  // };

  // // AUDIENCE DISTRIBUTION: MALE
  // let stateMap = (
  //   <Wrapper>
  //     <Loader loaderType="smallTableLoader" />
  //   </Wrapper>
  // );

  // // IF DATA IS AVAILABLE
  // if (isStateDataLoaded && !isStateDataLoading) {
  //   if (
  //     NoDataAvailableChecker(stateData) ||
  //     NoDataAvailableChecker(StateTableDataHeaders)
  //   ) {
  //     stateMap = (
  //       <Wrapper>
  //         <NoDataAvailableLoader chartType="tableChartType" />
  //       </Wrapper>
  //     );
  //   } else {
  //     stateMap = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="tableChartType">
  //           <Datatable
  //             tableHeader={StateTableDataHeaders}
  //             tableData={stateData}
  //             tableLength={5}
  //             isFooterShow={true}
  //             searchPlaceHolder={"Search State"}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (stateDataerror) {
  //   stateMap = (
  //     <Wrapper>
  //       <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
  //     </Wrapper>
  //   );
  // }

  // // IF DATA LOADING
  // if (isStateDataLoading) {
  //   stateMap = (
  //     <Wrapper>
  //       <Loader loaderType="smallTableLoader" />
  //     </Wrapper>
  //   );
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS ANALYSIS

  // TO FETCH DATA FROM API
  const fetchVideoViewsAnalysisHandler = async () => {
    setVideoViewsAnalysisIsLoading(true);
    setVideoViewsAnalysisIsLoaded(false);
    setVideoViewsAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/facebook/video-funnel-chart/",
        config
      );

      setVideoViewsAnalysis(res?.data ?? []);
      setVideoViewsAnalysisIsLoading(false);
      setVideoViewsAnalysisIsLoaded(true);
      setVideoViewsAnalysisError(null);
    } catch (error) {
      setVideoViewsAnalysis([]);
      setVideoViewsAnalysisIsLoading(false);
      setVideoViewsAnalysisIsLoaded(false);
      setVideoViewsAnalysisError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsAnalysisChart = (
    <Wrapper><Loader loaderType="funnelChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (NoDataAvailableChecker(videoViewsAnalysis) || videoViewsAnalysis.every((val, i, arr) => val.value === arr[0].value)) {
      videoViewsAnalysisChart = (
        <Wrapper><NoDataAvailableLoader chartType="funnelChartType" /></Wrapper>
      );
    } else {
      videoViewsAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={videoViewsAnalysis}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewsAnalysiserror) {
    videoViewsAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={videoViewsAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    videoViewsAnalysisChart = <Wrapper><Loader loaderType="funnelChartLoader" /></Wrapper>;
  }

  var maleFemaleDataCSV = {
    "Audience Total Count": [{ ["Male - " + selectedMidSectionDropdownOption.label]: totalMaleCount, ["Female - " + selectedMidSectionDropdownOption.label]: totalFemaleCount }],
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Male_${selectedMidSectionDropdownOption.label}`]: maleCount === undefined ? [] : maleCount,
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Female_${selectedMidSectionDropdownOption.label}`]: femaleCount === undefined ? [] : femaleCount,
  };

  var cityCountryData = {
    // "CityChart_Audience Distribution - State": NoDataAvailableChecker(stateData) ? [] : stateData,
    [`CountryChartDynaLabels_Audience Distribution - Country_${selectedMidSectionDropdownOption.value.replaceAll("_", "-")}`]: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    "Video View Analysis": videoViewsAnalysis,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    maleCount,
    femaleCount,
    // stateData,
    countryData,
    videoViewsAnalysis,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
      (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
      (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (videoViewsAnalysiserror !== null ? true : isContentAnalysisLoaded && (isContentAnalysisLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded , isMetricCardsLoading,
    maleCounterror, isMaleCountLoaded , isMaleCountLoading,
    femaleCounterror, isFemaleCountLoaded , isFemaleCountLoading,
    stateDataerror, isStateDataLoaded , isStateDataLoading,
    countryDataerror, isCountryDataLoaded , isCountryDataLoading,
    videoViewsAnalysiserror, isContentAnalysisLoaded , isContentAnalysisLoading,
  
  ])
  return (
    <Wrapper>
      {metricCardsSection}
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Performance By KPI

            </h2>
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={midSectionDropdonwOptions}
              className="form_dropdown section_dropdown"
              value={selectedMidSectionDropdownOption}
              selectedOptions={selectedMidSectionDropdownOption}
              setStatedropdown={midSectionDropdownSelectionHandler}
            />
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              {AudienceDistributionSectionTitle} : {selectedMidSectionDropdownOption.label}
            </h2> <SectionDefinationsBadge
                title={AudienceDistributionSectionTitle + " By Gender"}
                module="campaign"
                platform="facebook"
                section="video"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_section"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}

              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male

              </h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female

              </h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_place"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              {AudienceDistributionSectionTitle} : {selectedMidSectionDropdownOption.label}
            </h2>  <SectionDefinationsBadge
                title={AudienceDistributionSectionTitle + " By Country"}
                module="campaign"
                platform="facebook"
                section="video"
              />
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}

              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          {/* <div className="grid col_span_2 card_border_right flex column"> */}
          {/* <div className="section_card_header">
            <h2 className="section_card_title">By Country

            </h2>
          </div> */}
          {/* <div className="grid grid_cols_1 section_card_body"> */}
          {countryMap}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By State

              </h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">{stateMap}</div>
          </div> */}
        </div>
      </div>
      <div
        id="video_views"
        className="grid grid_cols_4  fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Video Views Analysis
            </h2>  <SectionDefinationsBadge
                title={"Video Views Analysis"}
                module="campaign"
                platform="facebook"
                section="video"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_views"}
                sectionName={"Video View Analysis"}
                sectionData={videoViewsAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewsAnalysisChart}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CampaignFacebookVideo;
