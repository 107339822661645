import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import PageHeader from "../../../../components/layout/pageHeader";
import axios from "../../../../components/helpers/axios";
import Datatable from "../../../../components/ui/tables/Datatable";
import Button from "../../../../components/ui/Button";
import Tab from "../../../../components/ui/Tab";
import CampaignPlatformObjectiveDropdown from "../../../../components/ui/dropdown/CampaignPlatformObjectiveDropdown";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";


// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { RequiredField } from "../../../../constants/constants";
import CheckIcon from "../../../../assets/icons/CheckIcon";
import { setAdminCreateBucketCampaignObjectivesFilter } from "../../../../store/admin/campaignAdminSlice";
import CrossIcon from "../../../../assets/icons/CrossIcon";

const CreateBucket = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const { state } = useLocation();

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);
  const selectedCampaignIdandPlatform = useSelector((state) => state.campaignAdmin.adminBucketPlatformandObjective);

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }



  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // SELECTED OBJECTIVES FROM DROPDOWN
  const objectiveFilter = useSelector((state) => state.campaignAdmin.adminCreateBucketCampaignObjectivesFilter);

  // SELECTED CAMPAIGN IN BUCKET
  const [bucketCampigns, setBucketCampigns] = useState([]);

  // FILTER ACTIVE OR NOT
  const [isBucketFilterActive, setBucketFilterActive] = useState(false);

  const [isClearButtonClicked, setClearButtonClicked] = useState(false);

  const [isSaveEditButtonDisabled, setSaveEditButtonDisabled] = useState(true);
  const [isSaveBucketButtonDisabled, setSaveBucketButtonDisabled] = useState(true);

  // BUCKET NAME
  const [bucketName, setBucketName] = useState(state !== null ? state.name : "");
  const [bucketNameIsTouched, setBucketNameIsTouched] = useState(false);
  const [bucketNameIsValid, setBucketNameIsValid] = useState(false);
  const [bucketNameError, setBucketNameError] = useState();

  // BUCKET DESCRIPTION
  const [bucketDescription, setBucketDescription] = useState(state !== null ? state.description : "");
  const [bucketDescriptionIsTouched, setBucketDescriptionIsTouched] = useState(false);
  const [bucketDescriptionIsValid, setBucketDescriptionIsValid] = useState(false);
  const [bucketDescriptionError, setBucketDescriptionError] = useState();


  // CAMPAIGNS LIST
  const [selectedCampaignList, setSelectedCampaignList] = useState(state === null ? [] : state.items === null ? [] : state.items);
  const [selectedCampaignsToRemove, setSelectedCampaignsToRemove] = useState([]);

  const [selectedTab, setSelectedTab] = useState(state !== null ? "Selected Campaigns" : "All Campaigns");

  const tabData = state !== null ? ["Selected Campaigns", "All Campaigns"] : ["All Campaigns"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    setSelectedCampaignsToRemove([]);
    setBucketCampigns([]);
  };

  // CAMPAIGNS LIST
  const [campaignList, setCampaignList] = useState([]);
  const [isCampaignListLoading, setCampaignListIsLoading] = useState(false);
  const [isCampaignListLoaded, setCampaignListIsLoaded] = useState(false);
  const [campaignListerror, setCampaignListError] = useState(null);

  // EDIT BUCKET
  const [isEditBucketLoading, setEditBucketIsLoading] = useState(false);
  const [isEditBucketLoaded, setEditBucketIsLoaded] = useState(false);
  const [editBucketerror, setEditBucketError] = useState(null);

  // ADD BUCKET
  const [isAddBucketLoading, setAddBucketIsLoading] = useState(false);
  const [isAddBucketLoaded, setAddBucketIsLoaded] = useState(false);
  const [addBucketerror, setAddBucketError] = useState(null);

  const campaignListTableHeaders = [
    { name: "Campaign Name", field: "platform_campaign_name" },
    { name: "Brand Name", field: "brand_name" },
    { name: "Campaign Bucket", field: "bucket" },
    { name: "Objective", field: "objective" },
    { name: "Platform", field: "platform" },
    { name: "Start Date", field: "start_date" },
    { name: "End Date", field: "end_date" },
  ];

  const onCheckboxAddCampaignHandler = (data) => {
    if (bucketCampigns.some((a) => a === data)) {
      bucketCampigns.splice(bucketCampigns.findIndex((b) => b === data));
      setBucketCampigns([...bucketCampigns]);
    } else {
      bucketCampigns.push(data);
      setBucketCampigns([...bucketCampigns]);
    }
  };

  const onCheckboxRemoveCampaignHandler = (data) => {
    if (selectedCampaignsToRemove.some((a) => ((a.platform_campaign_id === data.platform_campaign_id) && (a.platform === data.platform)))) {
      selectedCampaignsToRemove.splice(selectedCampaignsToRemove.findIndex((b) => b === data), 1);
      setSelectedCampaignsToRemove([...selectedCampaignsToRemove]);
    } else {
      selectedCampaignsToRemove.push(data);
      setSelectedCampaignsToRemove([...selectedCampaignsToRemove]);
    }
  };

  // TO FETCH CAMPAIGNS FROM API
  const fetchCampaignList = async () => {
    setCampaignListIsLoading(true);
    setCampaignListIsLoaded(false);
    setCampaignListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: objectiveFilter.length === 0 || objectiveFilter === undefined ? null : [...new Set(objectiveFilter.map((item) => item.campaign_objective)),].join(","),
        platform: objectiveFilter.length === 0 || objectiveFilter === undefined ? null : [...new Set(objectiveFilter.map((item) => item.platform))].join(","),
        platform_campaign: selectedIdandPlatform
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/campaign-list/", config);
      setCampaignList(res?.data ?? []);
      setCampaignListIsLoading(false);
      setCampaignListIsLoaded(true);
      setCampaignListError(null);

    } catch (error) {
      setCampaignList([]);
      setCampaignListIsLoading(false);
      setCampaignListIsLoaded(false);
      setCampaignListError(error.code);
    }
  };

  let campaignListTable = (
    <Wrapper>
      <div className="grid grid_cols_4  fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Select campaigns to add in bucket
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignListLoaded && !isCampaignListLoading) {
    if (NoDataAvailableChecker(campaignList)) {
      campaignListTable = (
        <Wrapper>
          <div className="grid grid_cols_4  fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Select campaigns to add in bucket
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      )
    } else {
      campaignListTable = (
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle={"Select campaigns to add in bucket"}
            tableHeader={campaignListTableHeaders}
            tableData={campaignList}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder="Search Campaign"
            checkbox={true}
            onCheckboxClickHandler={onCheckboxAddCampaignHandler}
            clearSelectedItems={isClearButtonClicked}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignListerror) {
    campaignListTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Select campaigns to add in bucket
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignListerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignListLoading) {
    campaignListTable = (
      <Wrapper>
        <div className="grid grid_cols_4  fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Select campaigns to add in bucket
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchCampaignList();
    setBucketFilterActive(objectiveFilter.length !== 0 || objectiveFilter === undefined ? true : false);
  }, [selectedDates, objectiveFilter]);

  const bucketNameInputChangeHandler = (event) => {
    setBucketName(event.target.value);
  };

  const bucketNameInputBlurHandler = (event) => {
    setBucketNameIsTouched(true);
    if (event.target.value.length === 0) {
      setSaveEditButtonDisabled(true);
      setBucketNameIsValid(false);
      setBucketNameError(RequiredField);
    } else {
      setSaveEditButtonDisabled(false);
      setBucketNameIsValid(true);
      setBucketNameError(null);
    }
  }

  const bucketDescriptionInputChangeHandler = (event) => {
    setBucketDescription(event.target.value);
  };

  const formSubmissionHandler = async (e) => {

    e.preventDefault()
    if (state !== null) {

      if (showDeleteButton === false) {
        if (selectedTab === "All Campaigns") {
          var arr = [];
          bucketCampigns.forEach((campaign) => {
            if (selectedCampaignList === null) {
              arr.push(campaign)
              setSelectedCampaignList([...arr])
            } else {
              selectedCampaignList.push(campaign)
              setSelectedCampaignList([...selectedCampaignList])
            }
          })

          var singleArr = getSingleBucketData === undefined || getSingleBucketData === null || getSingleBucketData.length === 0 ? bucketCampigns : getSingleBucketData
          EditBucketHandler(false, [...singleArr])

        } else {
          // if (selectedCampaignsToRemove.length !== 0) {
          //   EditBucketHandler(false)
          //   setAlertType("Campaigns Removed")
          //   setAlertTypeValue(selectedCampaignsToRemove.length)
          //   setSelectedCampaignsToRemove([])
          // } else {

          setSelectedCampaignsToRemove([])

          // setAlertTypeValue(selectedCampaignsToRemove.length)

          EditBucketHandler(true)

          setAlertType("Campaigns Updated")
          triggerAlertBox()


          // }
        }
      } else {
        deleteOnClickHandler(state)

      }


    } else {
      CreateBucketHandler()
    }
  };

  const deleteOnClickHandler = async (bucket) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: "Bearer " + authCtx.token,
    }

    try {
      axios.delete(
        "performance/google/campaign-tag/" + bucket.id, { headers }
      ).then(res => {
        if (res.data.status === "DELETED") {
          navigate("/admin/campaign", { state: { alertType: "Admin Delete", alertTypeValue: bucketName } })
        }
      }

      )

    } catch (error) {
      console.log(error);
    }
  };

  const CreateBucketHandler = async () => {
    setAddBucketIsLoading(true);
    setAddBucketIsLoaded(false);
    setAddBucketError(null);
    const data = {
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      description: bucketDescription,
      name: bucketName,
      items: bucketCampigns
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authCtx.token,
    }

    try {
      await axios.post("performance/google/campaign-tag/", data, { headers }).then(() => {
        navigate("/admin/campaign/", { state: { alertType: "Admin Create", alertTypeValue: bucketName } });
        dispatch(setAdminCreateBucketCampaignObjectivesFilter([]));
      })

      setAddBucketIsLoading(false);
      setAddBucketIsLoaded(true);
      setAddBucketError(null);
    } catch (error) {
      setAddBucketIsLoading(false);
      setAddBucketIsLoaded(false);
      setAddBucketError(error.code);

      if (error.response.data.data.non_field_errors.includes('The fields company, name must make a unique set.') && error.response.data.status === "FAILED") {
        setAlertType("Unique Name");
        triggerAlertBox()
      }

    }
  };

  let addBucketButtonName = "Update Bucket"

  if (state === null) {
    if (isAddBucketLoading && !isAddBucketLoaded) {
      addBucketButtonName = "Creating Bucket"
    } else if (addBucketerror) {
      addBucketButtonName = "Retry"
    } else {
      addBucketButtonName = "Create Bucket"
    }
  } else {
    if (isAddBucketLoading && !isAddBucketLoaded) {
      addBucketButtonName = "Updating Bucket"
    } else if (addBucketerror) {
      addBucketButtonName = "Retry"
    } else {
      addBucketButtonName = "Update Bucket"
    }
  }

  const EditBucketHandler = async (bool, arr) => {
    setEditBucketIsLoading(true);
    setEditBucketIsLoaded(false);
    setEditBucketError(null);


    let tempArr = []

    var filterArr = selectedCampaignList === undefined || selectedCampaignList === null || selectedCampaignList.length === 0 ? [] : selectedCampaignList.filter((value) => {
      return !selectedCampaignsToRemove.includes(value)
    })

    tempArr = arr === undefined || arr === null || arr.length === 0 ? filterArr : arr;

    const data = {
      name: bucketName,
      items: tempArr,
      description: bucketDescription,
      objective: objectiveFilter.length === 0 || objectiveFilter === undefined ? null : [...new Set(objectiveFilter.map((item) => item.campaign_objective)),].join(","),
      platform: objectiveFilter.length === 0 || objectiveFilter === undefined ? null : [...new Set(objectiveFilter.map((item) => item.platform))].join(","),
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + authCtx.token,
    }

    try {
      await axios.patch(
        "performance/google/campaign-tag/" + state.id, data, { headers })
        .then(res => {
          if (res.data.status === "UPDATED" && bool !== true && (arr !== undefined || arr !== null || arr.length !== 0)) {
            setSelectedTab('Selected Campaigns');
            setAlertTypeValue(bucketCampigns.length)

          }

          if (res.data.status === "UPDATED" && bool === true) {
            dispatch(setAdminCreateBucketCampaignObjectivesFilter([]));

            setTimeout(() => {
              navigate('/admin/campaign')
            }, 1200)

          } else if (bool === false) {
            getSingleBucketHandler()
          }
        });

      setEditBucketIsLoading(false);
      setEditBucketIsLoaded(true);
      setEditBucketError(null);
    } catch (error) {
      setEditBucketIsLoading(false);
      setEditBucketIsLoaded(false);
      setEditBucketError(error.code);
    }
  };


  const [getSingleBucketData, setgetSingleBucketData] = useState([])
  const [getSingleBucketDataLoading, setgetSingleBucketDataLoading] = useState([])
  const [getSingleBucketDataLoaded, setgetSingleBucketDataLoaded] = useState([])
  const [getSingleBucketDataError, setgetSingleBucketDataError] = useState(null)


  const getSingleBucketHandler = async () => {
    setgetSingleBucketDataLoading(true);
    setgetSingleBucketDataLoaded(false);
    setgetSingleBucketDataError(null);

    const config = {
      params: {
        objective: objectiveFilter.length === 0 || objectiveFilter === undefined ? null : [...new Set(objectiveFilter.map((item) => item.campaign_objective)),].join(","),
        platform: objectiveFilter.length === 0 || objectiveFilter === undefined ? null : [...new Set(objectiveFilter.map((item) => item.platform))].join(","),
      }
      ,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      }
    }



    try {
      await axios.get("performance/google/campaign-tag/" + state.id, config).then(res => {
        setSelectedCampaignList(res.data[0].items)
        setgetSingleBucketData(res.data[0].items)
      });

      setgetSingleBucketDataLoading(false);
      setgetSingleBucketDataLoaded(true);
      setgetSingleBucketDataError(null);

    } catch (error) {
      setgetSingleBucketDataLoading(false);
      setgetSingleBucketDataLoaded(false);
      setgetSingleBucketDataError(error.code);
    }
  };
  useEffect(() => {
    (selectedTab === "Selected Campaigns") && getSingleBucketHandler();
  }, [selectedTab, selectedDates, objectiveFilter,])

  // EDIT BUCKET INFO TEXT
  let editBucketInfoText = ""
  if (selectedCampaignsToRemove.length === 1) {
    editBucketInfoText = selectedCampaignsToRemove.length + " campaign selected to remove from the bucket"
  } else {
    editBucketInfoText = selectedCampaignsToRemove.length + " campaigns selected to remove from the bucket"
  }

  const [showDeleteButton, setshowDeleteButton] = useState(false)

  const [editBucketButtonName, setEditBucketButtonName] = useState("")

  useEffect(() => {
    if (isEditBucketLoading && !isEditBucketLoaded) {
      setshowDeleteButton(false)
      setEditBucketButtonName("Updating Bucket")
    }
    else if (selectedCampaignList !== null && (selectedCampaignsToRemove.length === selectedCampaignList.length)) {
      setEditBucketButtonName("Update and and Delete Bucket")
      setshowDeleteButton(true)
    }
    //  else if (selectedCampaignsToRemove.length > 0) {
    //   setshowDeleteButton(false)
    //   setEditBucketButtonName("Remove selected campaigns from Bucket")
    // }
    else if (editBucketerror) {
      setshowDeleteButton(false)
      setEditBucketButtonName("Retry")
    } else if (selectedCampaignList === null || selectedCampaignList.length === 0) {
      setEditBucketButtonName("Delete Bucket")
      setshowDeleteButton(true)
      // deleteOnClickHandler(state)
    } else {
      setshowDeleteButton(false)
      setEditBucketButtonName("Update Bucket")
    }

  }, [selectedCampaignList, editBucketerror, isEditBucketLoading, isEditBucketLoaded, selectedCampaignsToRemove])

  useEffect(() => {
    if (bucketCampigns !== null || bucketCampigns.length !== 0) {
      setshowDeleteButton(false)
      setSaveBucketButtonDisabled(true)
    } else {
      setSaveBucketButtonDisabled(false)
    }
  }, [bucketCampigns])

  let selectedCampaignsListTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  let filteredSelectedCampaigns = selectedCampaignList === null ? [] : selectedCampaignList
  let existingArr = objectiveFilter.length === 0 || objectiveFilter === null ? [] : objectiveFilter

  const filteredCampains = objectiveFilter.length === 0 || objectiveFilter === null ? selectedCampaignList : filteredSelectedCampaigns.filter(x => existingArr.some(y => y.campaign_objective === x.objective));

  if (filteredCampains === null || NoDataAvailableChecker(filteredCampains)) {
    selectedCampaignsListTable = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
  } else {
    selectedCampaignsListTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle={"Select campaigns to remove from bucket"}
            tableHeader={campaignListTableHeaders}
            tableData={filteredCampains}
            tableLength={5}
            isFooterShow={true}
            searchPlaceHolder="Search Campaign"
            checkbox={true}
            onCheckboxClickHandler={onCheckboxRemoveCampaignHandler}
            clearSelectedItems={isClearButtonClicked}
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  if (getSingleBucketDataError) {
    selectedCampaignsListTable = <ServerErrorsLoader chartType="tableChartType" error={getSingleBucketDataError} />
  }

  if (getSingleBucketDataLoading || isEditBucketLoading) {
    selectedCampaignsListTable = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  }

  const onClickClearButtonHandler = () => {
    setClearButtonClicked(true);
    setSelectedCampaignsToRemove([]);
    setBucketCampigns([]);
  };

  useEffect(() => {
    setClearButtonClicked(false);
  }, [isClearButtonClicked]);

  useEffect(() => {
    setSaveEditButtonDisabled(false)
  }, [bucketName, selectedCampaignList, selectedCampaignsToRemove]);

  useEffect(() => {
    bucketName.length !== 0 &&
      bucketCampigns.length !== 0 &&
      setSaveBucketButtonDisabled(false);
  }, [bucketName, bucketCampigns]);

  const bucketNameInputIsInvalid = !bucketNameIsValid && bucketNameIsTouched;
  const bucketNameInputClasses = bucketNameInputIsInvalid ? "form_control invalid" : "form_control";

  const bucketDescriptionInputIsInvalid = !bucketDescriptionIsValid && bucketDescriptionIsTouched;
  const bucketDescriptionInputClasses = bucketDescriptionInputIsInvalid ? "form_control invalid" : "form_control";

  // ADD BUCKET INFO TEXT
  let addBucketInfoText = ""
  if (bucketCampigns.length === 1) {
    addBucketInfoText = bucketCampigns.length + " campaign selected to add in the bucket"
  } else {
    addBucketInfoText = bucketCampigns.length + " campaigns selected to add in the bucket"
  }

  var alertBoxSection;
  const [alertType, setAlertType] = useState("");
  const [alertTypeValue, setAlertTypeValue] = useState(0);

  if (alertType === "Campaigns Added") {
    alertBoxSection = (
      <Wrapper>
        <dialog open className={`admin_alert_box success`}>
          <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
          <div className="alert_main_wrap">
            <div className="check_icon_wrap">
              <CheckIcon className="alert_check_icon" />
            </div>
            <div>
              <h4 className="alert_title">Campaigns Added.</h4>
              <div className="alert_body"> {alertTypeValue} campaigns added in bucket succesfully!</div>
            </div>
          </div>
        </dialog>
      </Wrapper>
    )
  } else if (alertType === "Campaigns Removed") {
    alertBoxSection = (
      <Wrapper>
        <dialog open className={`admin_alert_box success`}>
          <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
          <div className="alert_main_wrap">
            <div className="check_icon_wrap">
              <CheckIcon className="alert_check_icon" />
            </div>
            <div>
              <h4 className="alert_title">Campaigns Removed.</h4>
              <div className="alert_body"> {alertTypeValue} campaigns removed from bucket succesfully!</div>
            </div>
          </div>
        </dialog>
      </Wrapper>
    )
  } else if (alertType === "Campaigns Updated") {

    if (editBucketerror) {
      alertBoxSection = (
        <Wrapper>
          <dialog open className={`admin_alert_box danger`}>
            <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
            <div className="alert_main_wrap">
              <div className="check_icon_wrap">
                <CrossIcon className="alert_check_icon" />
              </div>
              <div>
                <h4 className="alert_title">{editBucketerror}</h4>
                <div className="alert_body">Please try again later </div>
              </div>
            </div>
          </dialog>
        </Wrapper>
      )
    }
    else {
      alertBoxSection = (
        <Wrapper>
          <dialog open className={`admin_alert_box success`}>
            <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
            <div className="alert_main_wrap">
              <div className="check_icon_wrap">
                <CheckIcon className="alert_check_icon" />
              </div>
              <div>
                <h4 className="alert_title">Campaigns Updated.</h4>
                <div className="alert_body"> Updated bucket <b>{bucketName}</b> succesfully!</div>
              </div>
            </div>
          </dialog>
        </Wrapper>
      )
    }

  }
  else if (alertType === "Unique Name") {
    alertBoxSection = (
      <Wrapper>
        <dialog open className={`admin_alert_box danger`}>
          <button className="close_button" type="button" onClick={() => setAlertBoxValue("hide")}>&#x2715; </button>
          <div className="alert_main_wrap">
            <div className="check_icon_wrap">
              <CrossIcon className="alert_check_icon" />
            </div>
            <div>
              <h4 className="alert_title">Bucket <b>{bucketName}</b> already exists!</h4>
              <div className="alert_body"> Bucket with name <b>{bucketName}</b> already exists.</div>
            </div>
          </div>
        </dialog>
      </Wrapper>
    )
  }

  const [alertBoxValue, setAlertBoxValue] = useState("hide")
  function triggerAlertBox() {
    setAlertBoxValue("show")
    setTimeout(() => {
      setAlertBoxValue("hide")
    }, 4000)
  }

  useEffect(() => {
    triggerAlertBox()
  }, [alertType, alertTypeValue])

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <div className="flex justify_between align_center">
            <PageHeader
              pageTitle={
                state === null ? "Create New Bucket" : "Edit " + state.name + " Bucket"
              }
            />
          </div>
          <div className="page_content">
            <div className={`admin_alert_wrap ${alertBoxValue}`}>
              {alertBoxSection}
            </div>
            <form className="form" onSubmit={formSubmissionHandler}>
              <div className="grid grid_cols_3">
                <div className="grid grid_span_2 grid_margin_bottom">
                  <div className="form_group flex">
                    <div className="mr_20">
                      <input
                        className={bucketNameInputClasses}
                        type="text"
                        name="bucket_name"
                        id="bucket_name"
                        placeholder="Enter Bucket Name"
                        value={bucketName}
                        onChange={bucketNameInputChangeHandler}
                        onBlur={bucketNameInputBlurHandler}
                      />
                      <div className="error_section">
                        {bucketNameInputIsInvalid && (
                          <h6 className="error_text m_t_5">{bucketNameError}</h6>
                        )}
                      </div>
                    </div>
                    <div>
                      <input
                        className={bucketDescriptionInputClasses}
                        type="text"
                        name="bucket_desc"
                        id="bucket_desc"
                        placeholder="Enter Description"
                        maxLength={100}
                        value={bucketDescription ?? ''}
                        onChange={bucketDescriptionInputChangeHandler}
                      />
                      <div className="error_section">
                        {bucketDescription?.length === 100 && (
                          <h6 className="error_text m_t_5">Character Limit Reached!</h6>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="grid grid_span_1 grid_margin_bottom">
                  <div className="flex start top_nav_options">
                    <CampaignPlatformObjectiveDropdown
                      isFilterActive={isBucketFilterActive}
                      filterCount={objectiveFilter === undefined ? 0 : objectiveFilter.length}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid_margin_bottom">
                <Tab
                  varient={"primary"}
                  tabData={tabData}
                  activeTab={selectedTab}
                  handleCallback={(event) => handleCallback(event)}
                />
              </div>
              {selectedTab === "Selected Campaigns" && (
                <Wrapper>
                  <div className="grid_margin_bottom">
                    {selectedCampaignsListTable}
                  </div>
                  <div className="grid grid_cols_4 flex row justify_between">
                    <div className="grid col_span_2">
                      {selectedCampaignsToRemove.length !== 0 &&
                        <h4 className="info_text">
                          {editBucketInfoText}
                        </h4>
                      }
                    </div>
                    <div className="grid col_span_2 form_actions flex row justify_end">

                      {showDeleteButton === true ? (
                        <div className="btn_wrapper p_10">
                          <Button
                            disabled={isSaveEditButtonDisabled}
                            className="secondary_danger_btn"
                            type="submit"
                          >
                            {editBucketButtonName}
                          </Button>
                        </div>
                      ) : (
                        <>
                          <div className="btn_wrapper p_10">
                            <Button
                              handleClick={() => navigate("/admin/campaign/")}
                              className="secondary_danger_btn"
                              type="button"
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="btn_wrapper p_10">
                            <Button
                              handleClick={() => onClickClearButtonHandler()}
                              className="secondary_btn"
                              type="button"
                            >
                              Clear Selected
                            </Button>
                          </div>
                          <div className="btn_wrapper p_10">
                            <Button
                              disabled={isSaveEditButtonDisabled}
                              className="primary_btn"
                              type="submit"
                            >
                              {editBucketButtonName}
                            </Button>
                          </div>
                        </>
                      )}


                    </div>
                  </div>
                </Wrapper>
              )}
              {selectedTab === "All Campaigns" && (
                <Wrapper>
                  <div className="grid_margin_bottom">
                    {campaignListTable}
                  </div>
                  <div className="grid grid_cols_4 flex row justify_between">
                    <div className="grid col_span_2">
                      {bucketCampigns.length !== 0 &&
                        <h4 className="info_text">
                          {addBucketInfoText}
                        </h4>
                      }
                    </div>
                    <div className="grid col_span_2 form_actions flex row justify_end">
                      <div className="btn_wrapper p_10">
                        <Button
                          handleClick={() => navigate("/admin/campaign/")}
                          className="secondary_danger_btn"
                          type="button"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="btn_wrapper p_10">
                        <Button
                          handleClick={() => onClickClearButtonHandler()}
                          className="secondary_btn"
                          type="button"
                        >
                          Clear Selected
                        </Button>
                      </div>
                      <div className="btn_wrapper p_10">
                        <Button disabled={isSaveBucketButtonDisabled} className="primary_btn" type="submit">
                          {addBucketButtonName}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Wrapper>
              )}
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default CreateBucket;
