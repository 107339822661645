import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PerformanceIcon from "../../../assets/icons/PerformanceIcon";
import AuthContext from "../../../store/authContext";
import axios from "../../../components/helpers/axios";
import Wrapper from "../../helpers/Wrapper";
import Button from "../Button";
import TextFormatter from "../../../utils/TextFormatter";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import Loader from "../loaders/Loader";
import ServerErrorsLoader from "../loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import FilterIcon from "../../../assets/icons/FilterIcon";
import { setSelectedAdsFromDropdown, setSelectedBrandsFromDropdown, setSelectedCampaignsFromDropdown } from "../../../store/tvBrandsCampaignsSlice";

const TvBrandsDropdown = () => {
    const dispatch = useDispatch();

    const authCtx = useContext(AuthContext);

    // currentDate / endDate / prevCurrentDate / prevEndDate
    const selectedDates = useSelector((state) => state.date)

    // SELECTED MODULE
    const selectedBrandIds = useSelector((state) => state.tvBrandsCamapaigns.selectedBrands);
    const selectedCampaignIds = useSelector((state) => state.tvBrandsCamapaigns.selectedCampaigns);
    const selectedAdNames = useSelector((state) => state.tvBrandsCamapaigns.selectedAds);

    // TO SHOW AND HIDE THE DROPDOWN ON CLICK
    const [isDropdownVisible, setDropdownVisible] = useState(false)
    const buttonClickHandler = () => {
        setDropdownVisible(!isDropdownVisible)
    }

    // BRANDS DATA
    const [brandsData, setBrandsData] = useState([]);
    const [isBrandsDataLoading, setBrandsDataIsLoading] = useState(false);
    const [isBrandsDataLoaded, setBrandsDataIsLoaded] = useState(false);
    const [brandsDatasError, setBrandsDataError] = useState(null);



    // TO SET THE FIRST BRAND SELECTED BY DEFAULT
    const [selectedBrand, setSelectedBrand] = useState([]);

    // SELECTED CAMPAIGNS
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);

    // SELECTED CAMPAIGNS ADS
    const [selectedAds, setSelectedAds] = useState([]);

    // SELECTED IDS
    const [uniqueBrands, setUniqueBrands] = useState([]);
    const [uniqueCampaigns, setUniqueCampaigns] = useState([]);
    const [uniqueAds, setUniqueAds] = useState([]);


    // CAMPAIGNS DATA
    const [campaignsData, setCampaignsData] = useState([]);
    const [isCampaignsDataLoading, setCampaignsDataIsLoading] = useState(false);
    const [isCampaignsDataLoaded, setCampaignsDataIsLoaded] = useState(false);
    const [campaignsDataError, setCampaignsDataError] = useState(null);

    // CAMPAIGN SEARCH STATE
    const [campaignSearchTerm, setCampaignSearchTerm] = useState("");

    // FILTER CAMPAIGNS LIST AS PER USER SEARCH
    const filteredCampaignsList = useMemo(() => {
        if (campaignSearchTerm === "") return campaignsData;
        if (campaignsData === "") return campaignsData;

        const filteredList = campaignsData.filter((campaign) => {
            return campaign.brand_id === selectedBrand && campaign.campaign_name.toLowerCase().includes(campaignSearchTerm.toLowerCase());
        });
        return filteredList;

    }, [campaignSearchTerm, campaignsData]);




    // ADS DATA
    const [adsData, setAdsData] = useState([]);
    const [isAdsLoading, setAdsIsLoading] = useState(false);
    const [isAdsLoaded, setAdsIsLoaded] = useState(false);
    const [adsDataError, setAdsError] = useState(null);

    // CAMPAIGN SEARCH STATE
    const [adsSearchTerm, setAdsSearchTerm] = useState("");

    // FILTER ADS LIST AS PER USER SEARCH
    const filteredAdsList = useMemo(() => {
        if (adsSearchTerm === "") return adsData;
        if (adsData === "") return adsData;

        const filteredAdsList = adsData.filter((ad) => {
            return ad.ad_name.toLowerCase().includes(adsSearchTerm.toLowerCase());
        });
        return filteredAdsList;
    }, [adsSearchTerm, adsData]);



    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // TO FETCH BRANDS DATA FROM API
    const fetchBrandsDataHandler = async () => {
        setBrandsDataIsLoading(true);
        setBrandsDataIsLoaded(false);
        setBrandsDataError(null);

        const config = {
            params: {
                start_date: selectedDates.startDate,
                end_date: selectedDates.endDate,
                previous_start_date: selectedDates.prevStartDate,
                previous_end_date: selectedDates.prevEndDate,
                brands: uniqueBrands.length !== 0 ? uniqueBrands.join(",") : null,
                campaigns: uniqueCampaigns.length !== 0 ? uniqueCampaigns.join(",") : null,
                ads: uniqueAds.length !== 0 ? uniqueAds.join(",") : null,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
        };

        try {
            const res = await axios.get(
                "ri/brand-filter/",
                config
            );

            setSelectedBrand(
                res.data === undefined || res.data.length === 0 ? [] :
                    res.data[0] === undefined || res.data[0].length === 0 ? [] :
                        res.data[0].brand_id === undefined || res.data[0].brand_id.length === 0 ? [] :
                            res.data[0].brand_id
            )
            setBrandsData(res.data === undefined || res.data.length === 0 ? [] : res.data);
            setBrandsDataIsLoading(false);
            setBrandsDataIsLoaded(true);
            setBrandsDataError(null);
        } catch (error) {
            setBrandsData([]);
            setBrandsDataIsLoading(false);
            setBrandsDataIsLoaded(false);
            setBrandsDataError(error.code);
        }
    };

    // BRANDS LISTS
    let brandsList = <Loader />;

    if (isBrandsDataLoaded && !isBrandsDataLoading) {
        if (brandsData === [] || brandsData === undefined || brandsData.length === 0) {
            brandsList = <NoDataAvailableLoader />
        } else {

            brandsList =
                brandsData.map((brand, brandIndex) => {
                    return (
                        <div
                            key={brandIndex}
                            className={selectedBrand === brand.brand_id ? "tab active" : "tab"}
                            onClick={() => brandNameOnClickHandler(brand.brand_id)}
                        >
                            <ErrorBoundary>
                                <TextFormatter string={brand.brand_name} />
                            </ErrorBoundary>
                        </div>
                    )
                })
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (brandsDatasError) {
        brandsList = <ServerErrorsLoader error={brandsDatasError} />;
    }

    // IF DATA LOADING
    if (isBrandsDataLoading) {
        brandsList = <Loader />;
    }


    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // TO FETCH CAMPAIGNS DATA FROM API
    const fetchCampaignsDataHandler = async () => {
        setCampaignsDataIsLoading(true);
        setCampaignsDataIsLoaded(false);
        setCampaignsDataError(null);

        const config = {
            params: {
                start_date: selectedDates.startDate,
                end_date: selectedDates.endDate,
                previous_start_date: selectedDates.prevStartDate,
                previous_end_date: selectedDates.prevEndDate,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
        };

        try {
            const res = await axios.get(
                "ri/campaign-filter/",
                config
            );

            setCampaignsData(res.data === undefined || res.data.length === 0 ? [] : res.data);
            setCampaignsDataIsLoading(false);
            setCampaignsDataIsLoaded(true);
            setCampaignsDataError(null);
        } catch (error) {
            setCampaignsData([]);
            setCampaignsDataIsLoading(false);
            setCampaignsDataIsLoaded(false);
            setCampaignsDataError(error.code);
        }
    };

    // CAMPAIGNS LISTS
    let campaignsList = <Loader />;

    if (isCampaignsDataLoaded && !isCampaignsDataLoading) {
        if (filteredCampaignsList === [] || filteredCampaignsList === undefined || filteredCampaignsList.length === 0) {
            campaignsList = <NoDataAvailableLoader />
        } else {
            campaignsList =
                filteredCampaignsList.map((campaign, campaignIndex) => (
                    campaign.brand_id === selectedBrand && (
                        <div
                            key={campaignIndex}
                            onClick={() => campaignNameOnClickHandler(campaign)}
                            className={
                                selectedCampaigns.some(
                                    (selectedCampaigns) =>
                                        selectedCampaigns === campaign
                                ) === true
                                    ? "tab active"
                                    : "tab"
                            }
                        >
                            <ErrorBoundary>
                                <TextFormatter string={campaign.campaign_name} />
                            </ErrorBoundary>
                        </div>
                    )
                ))
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (campaignsDataError) {
        campaignsList = <ServerErrorsLoader error={campaignsDataError} />;
    }

    // IF DATA LOADING
    if (isCampaignsDataLoading) {
        campaignsList = <Loader />;
    }


    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // TO FETCH CAMPAIGNS DATA FROM API
    const fetchAdsDataHandler = async () => {
        setAdsIsLoading(true);
        setAdsIsLoaded(false);
        setAdsError(null);

        const config = {
            params: {
                start_date: selectedDates.startDate,
                end_date: selectedDates.endDate,
                previous_start_date: selectedDates.prevStartDate,
                previous_end_date: selectedDates.prevEndDate,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
        };

        try {
            const res = await axios.get(
                "ri/ad-filter/",
                config
            );

            setAdsData(res.data === undefined || res.data.length === 0 ? [] : res.data);
            setAdsIsLoading(false);
            setAdsIsLoaded(true);
            setAdsError(null);
        } catch (error) {
            setAdsData([]);
            setAdsIsLoading(false);
            setAdsIsLoaded(false);
            setAdsError(error.code);
        }
    };

    // ADS LISTS
    let adsList = <Loader />;

    if (isAdsLoaded && !isAdsLoading) {
        if (filteredAdsList === [] || filteredAdsList === undefined || filteredAdsList.length === 0) {
            adsList = <NoDataAvailableLoader />
        } else {

            if (selectedCampaigns.length === 0) {
                adsList = filteredAdsList.map((ad, adIndex) => {
                    return (
                        ad.brand_id === selectedBrand && (
                            <div
                                key={adIndex}
                                onClick={() => campaignAdsOnclickHandler(ad)}
                                className={
                                    selectedAds.some((selectedAds) => selectedAds === ad) === true
                                        ? "tab active"
                                        : "tab"
                                }
                            >
                                <ErrorBoundary>
                                    <TextFormatter string={ad.ad_name} />
                                </ErrorBoundary>
                            </div>
                        )
                    );
                });
            } else if (
                selectedCampaigns.length !== 0 &&
                selectedCampaigns.some(
                    (selectedCampaigns) => selectedCampaigns.brand_id === selectedBrand
                )
            ) {
                adsList = filteredAdsList.map((ad, adIndex) => {
                    return (
                        ad.brand_id === selectedBrand &&
                        selectedCampaigns.some(
                            (selectedCampaigns) =>
                                selectedCampaigns.campaign_id === ad.campaign_id &&
                                selectedCampaigns.brand_id === ad.brand_id
                        ) && (
                            <div
                                key={adIndex}
                                onClick={() => campaignAdsOnclickHandler(ad)}
                                className={
                                    selectedAds.some((selectedAds) => selectedAds === ad) === true
                                        ? "tab active"
                                        : "tab"
                                }
                            >
                                <ErrorBoundary>
                                    <TextFormatter string={ad.ad_name} />
                                </ErrorBoundary>
                            </div>
                        )
                    );
                });
            } else {
                adsList = filteredAdsList.map((ad, adIndex) => {
                    return (
                        ad.brand_id === selectedBrand && (
                            <div
                                key={adIndex}
                                onClick={() => campaignAdsOnclickHandler(ad)}
                                className={
                                    selectedAds.some((selectedAds) => selectedAds === ad) === true
                                        ? "tab active"
                                        : "tab"
                                }
                            >
                                <ErrorBoundary>
                                    <TextFormatter string={ad.ad_name} />
                                </ErrorBoundary>
                            </div>
                        )
                    );
                });
            }


            // adsList = filteredAdsList.map((ad, campaignIndex) => {
            //     return (
            //         <div
            //             key={campaignIndex}
            //             onClick={() => campaignAdsOnclickHandler(ad)}
            //             className="tab"
            //         >
            //             <ErrorBoundary>
            //                 <TextFormatter string={ad.ad_name} />
            //             </ErrorBoundary>
            //         </div>
            //     )
            // })
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (adsDataError) {
        adsList = <ServerErrorsLoader error={adsDataError} />;
    }

    // IF DATA LOADING
    if (isAdsLoading) {
        adsList = <Loader />;
    }



    // BRANDS ONCLICK HANDLER
    const brandNameOnClickHandler = (selectedBrand) => {
        setSelectedBrand(selectedBrand);
    };


    // CAMPAIGNS ONCLICK HANDLER
    const campaignNameOnClickHandler = (campaign) => {
        if (
            selectedCampaigns.some((selectedCampaigns) => selectedCampaigns === campaign)
        ) {
            selectedCampaigns.splice(
                selectedCampaigns.findIndex((a) => a.campaign_id === campaign.campaign_id),
                1
            );
            setSelectedCampaigns([...selectedCampaigns]);

            // selectedAds.splice(selectedAds.findIndex((a) => a.campaign_name === campaign.campaign_name && a.brand === campaign.brand ),1);
            // setSelectedAds([...selectedAds]);

            let temp = [];

            for (
                let selectedAdsIndex = 0;
                selectedAdsIndex < selectedAds.length;
                selectedAdsIndex++
            ) {
                if (
                    selectedAds[selectedAdsIndex].campaign_id === campaign.campaign_id &&
                    selectedAds[selectedAdsIndex].brand_id === campaign.brand_id
                ) {
                    temp.push(selectedAds[selectedAdsIndex]);
                }
            }

            for (let tempindex = 0; tempindex < temp.length; tempindex++) {
                selectedAds.splice(tempindex, 1);
                setSelectedAds([...selectedAds]);
            }
        } else {
            selectedCampaigns.push(campaign);
            setSelectedCampaigns([...selectedCampaigns]);
        }
    }


    // ADS ONCLICK HANDLER
    const campaignAdsOnclickHandler = (ad) => {
        if (selectedAds.some((selectedAds) => selectedAds === ad)) {
            selectedAds.splice(selectedAds.findIndex((b) => b === ad));
            setSelectedAds([...selectedAds]);
        } else {
            selectedAds.push(ad);
            setSelectedAds([...selectedAds]);
        }

        for (
            let BrandsCampaignDataIndex = 0;
            BrandsCampaignDataIndex < filteredCampaignsList.length;
            BrandsCampaignDataIndex++
        ) {
            if (
                filteredCampaignsList[BrandsCampaignDataIndex].campaign_id === ad.campaign_id &&
                filteredCampaignsList[BrandsCampaignDataIndex].brand_id === ad.brand_id
            ) {
                selectedCampaigns.push(filteredCampaignsList[BrandsCampaignDataIndex]);
                setSelectedCampaigns([...selectedCampaigns]);
            }
        }
    }

    const cancelButtonOnclickHandler = () => {
        setDropdownVisible(false)
    }

    const clearButtonOnclickHandler = () => {
        setDropdownVisible(false)
        setSelectedBrand([]);
        setSelectedCampaigns([]);
        setSelectedAds([]);

        dispatch(setSelectedBrandsFromDropdown([]));
        dispatch(setSelectedCampaignsFromDropdown([]));
        dispatch(setSelectedAdsFromDropdown([]));
    }

    const applyButtonOnclickHandler = () => {
        setDropdownVisible(false)

        setUniqueBrands([...new Set(selectedAds.map(item => item.brand_id))]);
        setUniqueCampaigns([...new Set(selectedAds.map(item => item.campaign_id))]);
        setUniqueAds([...new Set(selectedAds.map(item => item.ad_name))]);

        dispatch(setSelectedBrandsFromDropdown([...new Set(selectedAds.map(item => item.brand_id))]));
        dispatch(setSelectedCampaignsFromDropdown([...new Set(selectedAds.map(item => item.campaign_id))]));
        dispatch(setSelectedAdsFromDropdown([...new Set(selectedAds.map(item => item.ad_name))]));

    }

    // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
    useEffect(() => {
        fetchBrandsDataHandler();
        fetchCampaignsDataHandler();
        fetchAdsDataHandler();
    }, [selectedDates]);

    // useEffect(() => {
    //     if (selectedCampaignIds.length === 0 && selectedAdNames.length === 0) {
    //         clearButtonOnclickHandler();
    //     }
    // }, [selectedCampaignIds, selectedAdNames])

    return (
        <Wrapper>
            <div className="brands_dropdown_container">
                <div className="brands_dropdown">
                    <Button
                        icon={<FilterIcon className={"btn_icon"} />}
                        className={isDropdownVisible === true ? "secondary_btn active" : "secondary_btn"}
                        type="button"
                        handleClick={() => buttonClickHandler()}
                    ></Button>
                </div>
                {isDropdownVisible &&
                    <div className="brands_dropdown_wrapper">
                        <div className="grid grid_cols_3 brands_dropdown_section">
                            <div className="grid col_span_1 brands_container">
                                <div className="header">Brands</div>
                                <div className="body">
                                    {brandsList}
                                </div>
                            </div>
                            <div className="grid col_span_1 campaigns_container">
                                <div className="header">Campaigns</div>
                                <div className="body">
                                    <div className="filter">
                                        <input
                                            className="form_control input"
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Search Campaigns"
                                            onChange={(event) => {
                                                setCampaignSearchTerm(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="tabs_container">
                                        {campaignsList[0] === false || campaignsList[0] === true ?
                                            campaignsList.every(
                                                (val, i, arr) => val === arr[0]
                                            ) === true ? (
                                                <NoDataAvailableLoader />
                                            ) : (
                                                campaignsList
                                            )
                                            :
                                            campaignsList
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="grid col_span_1 adsname_container">
                                <div className="header">Ads</div>
                                <div className="body">
                                    <div className="filter">
                                        <input
                                            className="form_control input"
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Search Campaigns"
                                            onChange={(event) => {
                                                setAdsSearchTerm(event.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="tabs_container">
                                        {adsList[0] === false || adsList[0] === true ?
                                            adsList.every(
                                                (val, i, arr) => val === arr[0]
                                            ) === true ? (
                                                <NoDataAvailableLoader />
                                            ) : (
                                                adsList
                                            )
                                            :
                                            adsList
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="brands_dropdown_footer">
                            <div className="">
                                <div className="btn_wrapper">
                                    <Button
                                        handleClick={() => cancelButtonOnclickHandler()}
                                        className="secondary_danger_btn"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="btn_wrapper">
                                    <Button
                                        handleClick={() => clearButtonOnclickHandler()}
                                        className="secondary_btn"
                                    >
                                        Clear
                                    </Button>
                                </div>
                                <div className="btn_wrapper">
                                    <Button
                                        handleClick={() => applyButtonOnclickHandler()}
                                        className="primary_btn"
                                        disabled={selectedAds.length === 0 ? true : false}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Wrapper>
    )
}
export default TvBrandsDropdown;