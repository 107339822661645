import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import SimpleTreeMap from "../../../../components/ui/charts/SimpleTreeMap";
import Tab from "../../../../components/ui/Tab";
import Datatable from "../../../../components/ui/tables/Datatable";
import BarChart from "../../../../components/ui/charts/BarChart";

// CHARTS
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import {
  IAEvent_Dropdown_Visit,
  IAEvent_Secondary_Tab_Visit,
} from "../../../../utils/IAEvents";
import TextFormatter from "../../../../utils/TextFormatter";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";

// CONSTANTS
import { BlueColorStart500, HALEON_COMPANY_ID, COKE_COMPANY_ID } from "../../../../constants/constants";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import { concateTotals } from "../../../../utils/Utils";

const CampaignDv360Video = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? '';

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedBuckets
  );
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence";
  let pageSubModule = "Campaign";
  let pagePlatform = "Dv360";
  let pagePrimaryTab = "Video";

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // VIDEO VIEWS ANALYSIS
  const [videoViews, setVideoViews] = useState([]);
  const [isContentAnalysisLoading, setVideoViewsIsLoading] = useState(false);
  const [isContentAnalysisLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // TREND ANALYSIS
  const [trendAnalysis, setTrendAnalysis] = useState([]);
  const [trendAnalysisLabels, setTrendAnalysisLabels] = useState([]);
  const [isTrendAnalysisLoading, setTrendAnalysisIsLoading] = useState(false);
  const [isTrendAnalysisLoaded, setTrendAnalysisIsLoaded] = useState(false);
  const [trendAnalysiserror, setTrendAnalysisError] = useState(null);

  const [trendAnalysisAllData, setTrendAnalysisAllData] = useState([]);
  const [trendAnalysisAllLabels, setTrendAnalysisAllLabels] = useState([]);

  const trendAnalysisDropdownOptions = [
    { value: "overall", label: "Overall" },
    { value: "0_to_1_minutes", label: "0 to 1 minutes" },
    { value: "1_to_5_minutes", label: "1 to 5 minutes" },
    { value: "5_to_15_minutes", label: "5 to 15 minutes" },
    { value: "15_to_30_minutes", label: "15 to 30 minutes" },
    { value: "30_to_60_minutes", label: "30 to 60 minutes" },
    { value: "over_60_minutes", label: "Over 60 minutes" },
    { value: "unknown", label: "Unknown" },
  ];

  const [trendAnalysisDropdown, setTrendAnalysisDropdown] = useState(
    trendAnalysisDropdownOptions[0]
  );

  const trendAnalysisClickHandler = (e) => {
    if (e === null) {
      setTrendAnalysisDropdown("");
    } else {
      setTrendAnalysisDropdown(e);
    }
  };

  const fetchTrendAnalysisDropdownHandler = () => {
    if (trendAnalysisDropdown.value === "overall") {
      setTrendAnalysis(trendAnalysisAllData?.overall ?? []);
      setTrendAnalysisLabels(trendAnalysisAllLabels?.overall ?? []);
    } else if (trendAnalysisDropdown.value === "0_to_1_minutes") {
      setTrendAnalysis(trendAnalysisAllData["0_to_1_minutes"] ?? []);
      setTrendAnalysisLabels(trendAnalysisAllLabels["0_to_1_minutes"] ?? []);
    } else if (trendAnalysisDropdown.value === "1_to_5_minutes") {
      setTrendAnalysis(trendAnalysisAllData["1_to_5_minutes"] ?? []);
      setTrendAnalysisLabels(trendAnalysisAllLabels["1_to_5_minutes"] ?? []);
    } else if (trendAnalysisDropdown.value === "5_to_15_minutes") {
      setTrendAnalysis(trendAnalysisAllData["5_to_15_minutes"] ?? []);
      setTrendAnalysisLabels(trendAnalysisAllLabels["5_to_15_minutes"] ?? []);
    } else if (trendAnalysisDropdown.value === "15_to_30_minutes") {
      setTrendAnalysis(trendAnalysisAllData["15_to_30_minutes"] ?? []);
      setTrendAnalysisLabels(trendAnalysisAllLabels["15_to_30_minutes"] ?? []);
    } else if (trendAnalysisDropdown.value === "30_to_60_minutes") {
      setTrendAnalysis(trendAnalysisAllData["30_to_60_minutes"] ?? []);
      setTrendAnalysisLabels(trendAnalysisAllLabels["30_to_60_minutes"] ?? []);
    } else if (trendAnalysisDropdown.value === "over_60_minutes") {
      setTrendAnalysis(trendAnalysisAllData["Over_60_minutes"] ?? []);
      setTrendAnalysisLabels(trendAnalysisAllLabels["Over_60_minutes"] ?? []);
    } else if (trendAnalysisDropdown.value === "unknown") {
      setTrendAnalysis(trendAnalysisAllData?.Unknown ?? []);
      setTrendAnalysisLabels(trendAnalysisAllLabels?.Unknown ?? []);
    } else {
      setTrendAnalysis([]);
      setTrendAnalysisLabels([]);
    }
  };

  useEffect(() => {
    fetchTrendAnalysisDropdownHandler();
  }, [
    BRAND_ID,
    trendAnalysisDropdown,
    selectedDates,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  // SPENDS TREND
  const [kpiTrendAnalysisData, setKpiTrendAnalysisData] = useState([]);
  const [kpiTrendAnalysisDataLabels, setKpiTrendAnalysisDataLabels] = useState([]);
  const [kpiTrendAnalysisBenchmark, setKpiTrendAnalysisBenchmark] = useState([]);
  const [isKpiTrendAnalysisLoading, setKpiTrendAnalysisIsLoading] = useState(false);
  const [isKpiTrendAnalysisLoaded, setKpiTrendAnalysisIsLoaded] = useState(false);
  const [kpiTrendAnalysisError, setKpiTrendAnalysisError] = useState(null);

  const [kpiTrendAnalysisAllData, setKpiTrendAnalysisAllData] = useState([]);
  const [kpiTrendAnalysisAllLabels, setKpiTrendAnalysisAllLabels] = useState([]);
  const [kpiTrendAnalysisAllBenchmark, setKpiTrendAnalysisAllBenchmark] = useState([]);

  const firstKpiTrendAnalysisDropdownOptions = [
    { value: "vtr", label: "VTR" },
    { value: "cvtr", label: "CVTR" },
    { value: "cpv", label: "CPV" },
    { value: "cpcv", label: "CPCV" },

  ];

  const [firstKpiTrendAnalysisDropdown, setFirstKpiTrendAnalysisDropdown] =
    useState(firstKpiTrendAnalysisDropdownOptions[0]);

  const firstKpiTrendAnalysisClickHandler = (e) => {
    if (e === null) {
      setFirstKpiTrendAnalysisDropdown("");
    } else {
      setFirstKpiTrendAnalysisDropdown(e);
    }
  };

  const secondKpiTrendAnalysisDropdownOptions = [
    { value: "average", label: "Benchmarking 1:Average" },
    { value: "campaign_start_date", label: "Benchmarking 2:Campaign Start Date" },
  ];

  const [secondKpiTrendAnalysisDropdown, setSecondKpiTrendAnalysisDropdown] = useState(secondKpiTrendAnalysisDropdownOptions[0]);

  const secondKpiTrendAnalysisClickHandler = (e) => {
    if (e === null) {
      setSecondKpiTrendAnalysisDropdown("");
    } else {
      setSecondKpiTrendAnalysisDropdown(e);
    }
  };

  const fetchFirstKpiTrendAnalysisDropdownHandler = () => {
    let firstVal = firstKpiTrendAnalysisDropdown.value;

    setKpiTrendAnalysisData(kpiTrendAnalysisAllData[firstVal] ?? []);

    setKpiTrendAnalysisDataLabels(kpiTrendAnalysisAllLabels[firstVal] ?? []);
    setKpiTrendAnalysisBenchmark(kpiTrendAnalysisAllBenchmark[firstVal] !== undefined ? kpiTrendAnalysisAllBenchmark[firstVal][0] : []);
  };
  useEffect(() => {
    setFirstKpiTrendAnalysisDropdown(firstKpiTrendAnalysisDropdownOptions[0]);
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    secondKpiTrendAnalysisDropdown
  ]);
  useEffect(() => {
    fetchFirstKpiTrendAnalysisDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    firstKpiTrendAnalysisDropdown
  ]);


  // KPI TREND ANALYSIS HALEON
  const [haleonKpiTrendAnalysisData, setHaleonKpiTrendAnalysisData] = useState([]);
  const [haleonKpiTrendAnalysisDataLabels, setHaleonKpiTrendAnalysisDataLabels] = useState([]);
  const [haleonKpiTrendAnalysisAllData, setHaleonKpiTrendAnalysisAllData] = useState([]);
  const [haleonKpiTrendAnalysisAllLabels, setHaleonKpiTrendAnalysisAllLabels] = useState([]);
  const [isHaleonKpiTrendAnalysisLoading, setHaleonKpiTrendAnalysisIsLoading] = useState(false);
  const [isHaleonKpiTrendAnalysisLoaded, setHaleonKpiTrendAnalysisIsLoaded] = useState(false);
  const [haleonKpiTrendAnalysisError, setHaleonKpiTrendAnalysisError] = useState(null);

  var haleonKpiTrendAnalysisOptions = [
    { value: "vtr", label: "VTR" },
    { value: "cvtr", label: "CVTR" },
    { value: "cpv", label: "CPV" },
    { value: "cpcv", label: "CPCV" },
  ]

  const [haleonKpiTrendAnalysisDropdown, setHaleonKpiTrendAnalysisDropdown] = useState(haleonKpiTrendAnalysisOptions[0])

  const haleonKpiTrendAnalysisClickHandler = (e) => {
    if (e === null) {
      setHaleonKpiTrendAnalysisDropdown("");
    } else {
      setHaleonKpiTrendAnalysisDropdown(e);
    }
  };

  const haleonKPIdropdownHandler = () => {
    let firstVal = haleonKpiTrendAnalysisDropdown.value;

    setHaleonKpiTrendAnalysisData(haleonKpiTrendAnalysisAllData[firstVal] ?? []);
    setHaleonKpiTrendAnalysisDataLabels(haleonKpiTrendAnalysisAllLabels[firstVal] ?? []);

  }


  useEffect(() => {
    haleonKPIdropdownHandler();
  }, [haleonKpiTrendAnalysisDropdown])


  // CONTENT POSITION WISE DISTRIBUTION
  const [contentPositionDistribution, setContentPositionDistribution] =
    useState([]);
  const [
    contentPositionDistributionAllDropdownsData,
    setContentPositionDistributionAllDropdownsData,
  ] = useState([]);
  const [
    isContentPositionDistributionLoading,
    setContentPositionDistributionIsLoading,
  ] = useState(false);
  const [
    isContentPositionDistributionLoaded,
    setContentPositionDistributionIsLoaded,
  ] = useState(false);
  const [
    contentPositionDistributionerror,
    setContentPositionDistributionError,
  ] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const contentPositionDistributionDropdownOptions = [
    { value: "complete_views", label: "Complete Views" },
    { value: "views", label: "Views" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedContentPositionDistributionDropdownOption,
    setSelectedContentPositionDistributionDropdownOption,
  ] = useState(contentPositionDistributionDropdownOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const contentPositionDistributionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedContentPositionDistributionDropdownOption("");
    } else {
      setSelectedContentPositionDistributionDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Facebook",
      "Overall",
      null,
      "Performance By KPI",
      e.label
    );
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchContentPositionDistributionDropdownHandler = async () => {
    if (
      selectedContentPositionDistributionDropdownOption.value ===
      "complete_views"
    ) {
      setContentPositionDistribution(contentPositionDistributionAllDropdownsData?.complete_views ?? []);
    } else if (
      selectedContentPositionDistributionDropdownOption.value === "views"
    ) {
      setContentPositionDistribution(contentPositionDistributionAllDropdownsData?.views ?? []);
    } else {
      setContentPositionDistribution([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchContentPositionDistributionDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    selectedContentPositionDistributionDropdownOption,
  ]);

  // APP URL DISTRIBUTION
  const [appUrlDistribution, setAppUrlDistribution] = useState([]);
  const [appUrlDistributionLabels, setAppUrlDistributionLabels] = useState([]);
  const [isAppUrlDistributionLoading, setAppUrlDistributionIsLoading] =
    useState(false);
  const [isAppUrlDistributionLoaded, setAppUrlDistributionIsLoaded] =
    useState(false);
  const [appUrlDistributionerror, setAppUrlDistributionError] = useState(null);

  const [appUrlDistributionAllData, setAppUrlDistributionAllData] = useState(
    []
  );
  const [appUrlDistributionAllLabels, setAppUrlDistributionAllLabels] =
    useState([]);

  const appUrlDistributionDropdownOptions = [
    { value: "complete_views", label: "Complete Views" },
    { value: "views", label: "Views" },
  ];

  const [appUrlDistributionDropdown, setAppUrlDistributionDropdown] = useState(
    appUrlDistributionDropdownOptions[0]
  );

  const appUrlDistributionClickHandler = (e) => {
    if (e === null) {
      setAppUrlDistributionDropdown("");
    } else {
      setAppUrlDistributionDropdown(e);
    }
  };

  const fetchAppUrlDistributionDropdownHandler = () => {
    if (appUrlDistributionDropdown.value === "complete_views") {
      setAppUrlDistribution(appUrlDistributionAllData?.complete_views ?? []);
      setAppUrlDistributionLabels(appUrlDistributionAllLabels?.complete_views ?? []);
    } else if (appUrlDistributionDropdown.value === "views") {
      setAppUrlDistribution(appUrlDistributionAllData?.views ?? []);
      setAppUrlDistributionLabels(appUrlDistributionAllLabels?.views ?? []);
    } else {
      setAppUrlDistribution([]);
      setAppUrlDistributionLabels([]);
    }
  };

  useEffect(() => {
    fetchAppUrlDistributionDropdownHandler();
  }, [

    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    appUrlDistributionDropdown

  ]);

  //  STATE DATA
  const [stateData, setStateData] = useState([]);
  const [isStateDataLoading, setStateDataIsLoading] = useState(false);
  const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
  const [stateDataerror, setStateDataError] = useState(null);

  let stateTableDataHeaders = []

  let defaultStateTableDataHeaders = [
    { name: "State", field: "state" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  let haleonStateTableDataHeaders = [
    { name: "State", field: "state" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "First-Quartile Views", field: "first_quartile_views" },
    { name: "Midpoint Views", field: "mid_quartile_views" },
    { name: "Third-Quartile Views", field: "third_quartile_views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    stateTableDataHeaders = haleonStateTableDataHeaders
  } else {
    stateTableDataHeaders = defaultStateTableDataHeaders
  }

  // CITY DATA
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  let cityTableDataHeaders = [];

  let defaultCityTableDataHeaders = [
    { name: "City", field: "city" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  const haleonCityTableDataHeaders = [
    { name: "City", field: "city" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "First-Quartile Views", field: "first_quartile_views" },
    { name: "Midpoint Views", field: "mid_quartile_views" },
    { name: "Third-Quartile Views", field: "third_quartile_views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    cityTableDataHeaders = haleonCityTableDataHeaders
  } else {
    cityTableDataHeaders = defaultCityTableDataHeaders
  }

  // COUNTRY DATA
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  let countryTableDataHeaders = []

  let defaultCountryTableDataHeaders = [
    { name: "Country", field: "country" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  let haleonCountryTableDataHeaders = [
    { name: "Country", field: "country" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "First-Quartile Views", field: "first_quartile_views" },
    { name: "Midpoint Views", field: "mid_quartile_views" },
    { name: "Third-Quartile Views", field: "third_quartile_views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    countryTableDataHeaders = haleonCountryTableDataHeaders
  } else {
    countryTableDataHeaders = defaultCountryTableDataHeaders
  }

  // BRANDWISE PERFORMANCE
  const [deviceWiseDistribution, setDeviceWiseDistribution] = useState([]);
  const [isDeviceWiseDistributionLoading, setDeviceWiseDistributionIsLoading] =
    useState(false);
  const [isDeviceWiseDistributionLoaded, setDeviceWiseDistributionIsLoaded] =
    useState(false);
  const [deviceWiseDistributionerror, setDeviceWiseDistributionError] =
    useState(null);

  // AD TYPE PERFORMANCE
  const [adTypePerformance, setAdTypePerformance] = useState([]);
  const [adTypePerformanceLabels, setAdTypePerformanceLabels] = useState([]);
  const [isAdTypePerformanceLoading, setAdTypePerformanceIsLoading] =
    useState(false);
  const [isAdTypePerformanceLoaded, setAdTypePerformanceIsLoaded] =
    useState(false);
  const [adTypePerformanceerror, setAdTypePerformanceError] = useState(null);

  const [adTypePerformanceAllData, setAdTypePerformanceAllData] = useState([]);
  const [adTypePerformanceAllLabels, setAdTypePerformanceAllLabels] = useState(
    []
  );

  const adTypePerformanceDropdownOptions = [
    { value: "complete_views", label: "Complete Views" },
    { value: "views", label: "Views" },
    { value: "first_quartile", label: "First-Quartile Views (Video)" },
    { value: "mid_point", label: "Mid-Quartile Views (Video)" },
    { value: "third_quartile", label: "Third-Quartile Views (Video)" },
  ];

  const [adTypePerformanceDropdown, setAdTypePerformanceDropdown] = useState(
    adTypePerformanceDropdownOptions[0]
  );

  const adTypePerformanceClickHandler = (e) => {
    if (e === null) {
      setAdTypePerformanceDropdown("");
    } else {
      setAdTypePerformanceDropdown(e);
    }
  };

  const fetchAdTypePerformanceDropdownHandler = () => {
    if (adTypePerformanceDropdown.value === "complete_views") {
      setAdTypePerformance(adTypePerformanceAllData?.complete_views ?? []);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels?.complete_views ?? []);
    } else if (adTypePerformanceDropdown.value === "views") {
      setAdTypePerformance(adTypePerformanceAllData?.views ?? []);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels?.views ?? []);
    } else if (adTypePerformanceDropdown.value === "first_quartile") {
      setAdTypePerformance(adTypePerformanceAllData?.first_quartile ?? []);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels?.first_quartile ?? []);
    } else if (adTypePerformanceDropdown.value === "mid_point") {
      setAdTypePerformance(adTypePerformanceAllData?.mid_point ?? []);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels?.mid_point ?? []);
    } else if (adTypePerformanceDropdown.value === "third_quartile") {
      setAdTypePerformance(adTypePerformanceAllData?.third_quartile ?? []);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels?.third_quartile ?? []);
    } else {
      setAdTypePerformance([]);
      setAdTypePerformanceLabels([]);
    }
  };

  useEffect(() => {
    fetchAdTypePerformanceDropdownHandler();
  }, [

    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    adTypePerformanceDropdown

  ]);

  // VTR TREND ANALYSIS
  const [vtrTrendAnalysis, setVtrTrendAnalysis] = useState([]);
  const [vtrTrendAnalysisLabels, setVtrTrendAnalysisLabels] = useState([]);
  const [isVtrTrendAnalysisLoading, setVtrTrendAnalysisIsLoading] =
    useState(false);
  const [isVtrTrendAnalysisLoaded, setVtrTrendAnalysisIsLoaded] =
    useState(false);
  const [vtrTrendAnalysiserror, setVtrTrendAnalysisError] = useState(null);

  const [vtrTrendAnalysisAllData, setVtrTrendAnalysisAllData] = useState([]);
  const [vtrTrendAnalysisAllLabels, setVtrTrendAnalysisAllLabels] = useState(
    []
  );

  const vtrTrendAnalysisDropdownOptions = [
    { value: "skippable", label: "Skippable" },
    { value: "bumper_ad", label: "Bumper ad" },
    {
      value: "non_skippable",
      label: "Non-skippable",
    },
    { value: "masthead_ad", label: "Masthead ad" },
  ];

  const [vtrTrendAnalysisDropdown, setVtrTrendAnalysisDropdown] = useState(
    vtrTrendAnalysisDropdownOptions[0]
  );

  const vtrTrendAnalysisClickHandler = (e) => {
    if (e === null) {
      setVtrTrendAnalysisDropdown("");
    } else {
      setVtrTrendAnalysisDropdown(e);
    }
  };

  const fetchVtrTrendAnalysisDropdownHandler = () => {
    if (vtrTrendAnalysisDropdown.value === "skippable") {
      setVtrTrendAnalysis(vtrTrendAnalysisAllData?.Skippable ?? []);
      setVtrTrendAnalysisLabels(vtrTrendAnalysisAllLabels?.Skippable ?? []);
    } else if (vtrTrendAnalysisDropdown.value === "bumper_ad") {
      setVtrTrendAnalysis(vtrTrendAnalysisAllData?.Bumper_ad ?? []);
      setVtrTrendAnalysisLabels(vtrTrendAnalysisAllLabels?.Bumper_ad ?? []);
    } else if (vtrTrendAnalysisDropdown.value === "non_skippable") {
      setVtrTrendAnalysis(vtrTrendAnalysisAllData["Non-skippable"] ?? []);
      setVtrTrendAnalysisLabels(vtrTrendAnalysisAllLabels["Non-skippable"] ?? []);
    } else if (vtrTrendAnalysisDropdown.value === "masthead_ad") {
      setVtrTrendAnalysis(vtrTrendAnalysisAllData?.Masthead_ad ?? []);
      setVtrTrendAnalysisLabels(vtrTrendAnalysisAllLabels?.Masthead_ad ?? []);
    } else {
      setVtrTrendAnalysis([]);
      setVtrTrendAnalysisLabels([]);
    }
  };

  useEffect(() => {
    fetchVtrTrendAnalysisDropdownHandler();
  }, [

    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    vtrTrendAnalysisDropdown,

  ]);

  // YOUTUBE AD TYPE
  const [youtubeAdType, setYoutubeAdType] = useState([]);
  const [youtubeAdTypeLabels, setYoutubeAdTypeLabels] = useState([]);
  const [isYoutubeAdTypeLoading, setYoutubeAdTypeIsLoading] = useState(false);
  const [isYoutubeAdTypeLoaded, setYoutubeAdTypeIsLoaded] = useState(false);
  const [youtubeAdTypeerror, setYoutubeAdTypeError] = useState(null);

  const [youtubeAdTypeAllData, setYoutubeAdTypeAllData] = useState([]);
  const [youtubeAdTypeAllLabels, setYoutubeAdTypeAllLabels] = useState([]);

  const firstYoutubeAdTypeDropdownOptions = [
    { value: "Skippable", label: "Skippable" },
    { value: "Bumper ad", label: "Bumper Ad" },
    { value: "Non-skippable", label: "Non-Skippable" },
    { value: "Masthead ad", label: "Masthead Ad" },
  ];

  const [firstYoutubeAdTypeDropdown, setFirstYoutubeAdTypeDropdown] = useState(
    firstYoutubeAdTypeDropdownOptions[0]
  );

  const firstYoutubeAdTypeClickHandler = (e) => {
    if (e === null) {
      setFirstYoutubeAdTypeDropdown("");
    } else {
      setFirstYoutubeAdTypeDropdown(e);
    }
  };

  const secondYoutubeAdTypeDropdownOptions = [
    { value: "vtr", label: "VTR" },
    { value: "cpm", label: "CPM" },
    { value: "cvtr", label: "CVTR" },
    { value: "cpv", label: "CPV" },
    { value: "cpcv", label: "CPCV" },
  ];

  const [secondYoutubeAdTypeDropdown, setSecondYoutubeAdTypeDropdown] =
    useState(secondYoutubeAdTypeDropdownOptions[0]);

  const secondYoutubeAdTypeClickHandler = (e) => {
    if (e === null) {
      setSecondYoutubeAdTypeDropdown("");
    } else {
      setSecondYoutubeAdTypeDropdown(e);
    }
  };

  const fetchFirstYoutubeAdTypeDropdownHandler = () => {
    let secondVal = secondYoutubeAdTypeDropdown.value;

    setYoutubeAdType(youtubeAdTypeAllData[secondVal] ?? []);

    setYoutubeAdTypeLabels(youtubeAdTypeAllLabels[secondVal] ?? []);
  };
  useEffect(() => {
    setSecondYoutubeAdTypeDropdown(secondYoutubeAdTypeDropdownOptions[0]);
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    firstYoutubeAdTypeDropdown]);

  useEffect(() => {
    fetchFirstYoutubeAdTypeDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    secondYoutubeAdTypeDropdown]);
  // AD TYPE DISTRIBUTION
  const [adTypeDistribution, setAdTypeDistribution] = useState([]);
  const [
    adTypeDistributionAllDropdownsData,
    setAdTypeDistributionAllDropdownsData,
  ] = useState([]);
  const [isAdTypeDistributionLoading, setAdTypeDistributionIsLoading] =
    useState(false);
  const [isAdTypeDistributionLoaded, setAdTypeDistributionIsLoaded] =
    useState(false);
  const [adTypeDistributionerror, setAdTypeDistributionError] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const adTypeDistributionDropdownOptions = [
    { value: "complete_views", label: "Complete Views" },
    { value: "views", label: "Views" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedAdTypeDistributionDropdownOption,
    setSelectedAdTypeDistributionDropdownOption,
  ] = useState(adTypeDistributionDropdownOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const adTypeDistributionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedAdTypeDistributionDropdownOption("");
    } else {
      setSelectedAdTypeDistributionDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Facebook",
      "Overall",
      null,
      "Performance By KPI",
      e.label
    );
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchAdTypeDistributionDropdownHandler = async () => {
    if (selectedAdTypeDistributionDropdownOption.value === "complete_views") {
      setAdTypeDistribution(
        adTypeDistributionAllDropdownsData.length === 0
          ? []
          : adTypeDistributionAllDropdownsData.complete_views
      );
    } else if (selectedAdTypeDistributionDropdownOption.value === "views") {
      setAdTypeDistribution(
        adTypeDistributionAllDropdownsData.length === 0
          ? []
          : adTypeDistributionAllDropdownsData.views
      );
    } else {
      setAdTypeDistribution([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchAdTypeDistributionDropdownHandler();
  }, [

    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    selectedAdTypeDistributionDropdownOption,

  ]);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [femaleCountAllDropdownsDataLabels, setFemaleCountAllDropdownsDataLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [totalFemaleCountAllDropdownsData, setTotalFemaleCountAllDropdownsData] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [unknownCount, setUnknownCount] = useState([]);
  const [unknownCountAllDropdownsData, setUnknownCountAllDropdownsData] = useState([]);
  const [unknownCountLabels, setUnknownCountLabels] = useState([]);
  const [unknownCountAllDropdownsDataLabels, setUnknownCountAllDropdownsDataLabels] = useState([]);
  const [totalUnknownCount, setTotalUnknownCount] = useState([]);
  const [totalUnknownCountAllDropdownsData, setTotalUnknownCountAllDropdownsData] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  const audienceDistributionOptions = [
    { value: "trueview_views", label: "Views" },
    { value: "complete_views", label: "Complete Views" },
  ];

  // DROPDOWN STATE
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(
    audienceDistributionOptions[0]
  );

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }

    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Facebook", "Audience", null, "Audience Distribution: Male / Female / Unknown", e.label)
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const audienceDistributionMaleFemaleDropdownHandler = async () => {
    if (selectedDropdownOption.value === "trueview_views") {

      setMaleCount(maleCountAllDropdownsData?.trueview_views ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.trueview_views ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.trueview_views ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.trueview_views ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.trueview_views ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.trueview_views ?? []);
      setUnknownCount(unknownCountAllDropdownsData?.trueview_views ?? []);
      setUnknownCountLabels(unknownCountAllDropdownsDataLabels?.trueview_views ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.trueview_views ?? []);

    } else if (selectedDropdownOption.value === "complete_views") {

      setMaleCount(maleCountAllDropdownsData?.complete_views ?? []);
      setMaleCountLabels(maleCountAllDropdownsDataLabels?.complete_views ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.complete_views ?? []);
      setFemaleCount(femaleCountAllDropdownsData?.complete_views ?? []);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels?.complete_views ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.complete_views ?? []);
      setUnknownCount(unknownCountAllDropdownsData?.complete_views ?? []);
      setUnknownCountLabels(unknownCountAllDropdownsDataLabels?.complete_views ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.complete_views ?? []);

    } else {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
      setUnknownCount([]);
      setUnknownCountLabels([]);
      setTotalUnknownCount([]);
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    audienceDistributionMaleFemaleDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedDropdownOption]);



  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchVideoViewsHandler();
    fetchTrendAnalysisHandler();
    fetchContentPositionDistributionHandler();
    fetchStateHandler()
    fetchCityHandler();
    companyID === HALEON_COMPANY_ID && fetchCountryHandler()
    fetchAppUrlDistributionHandler();
    fetchDeviceWiseDistributionHandler();
    fetchAdTypeDistributionHandler();
    fetchAdTypePerformanceHandler();
    fetchVtrTrendAnalysisHandler();
    companyID === HALEON_COMPANY_ID && fetchAudienceDistributionHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter
  ]);

  useEffect(() => {
    fetchKpiTrendAnalysisHandler();
    companyID === HALEON_COMPANY_ID && fetchHaleonKpiTrendAnalysisHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    secondKpiTrendAnalysisDropdown,
  ]);

  useEffect(() => {
    fetchYoutubeAdTypeHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    firstYoutubeAdTypeDropdown]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/metric-card/",
        config
      );
      setMetricCards(res?.data?.metric_card ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <ErrorBoundary>
          <Slider sliderTitle={"Summary"}>
            {metricCards.map((data, index) => (
              <SwiperSlide key={index}>
                <Card
                  module="campaign"
                  platform="dv360"
                  section="video"
                  cardTitle={data.label}
                  currentValue={data.currentValue}
                  previousValue={data.previousValue}
                  rowOneTitle={"Previous"}
                  numberVariant={"number"}
                />
              </SwiperSlide>
            ))}
          </Slider>
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader error={metricCardserror} />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS

  // TO FETCH DATA FROM API
  const fetchVideoViewsHandler = async () => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/video-funnel/",
        config
      );

      setVideoViews(res?.data ?? []);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = (
    <Wrapper>
      <Loader loaderType="funnelChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      videoViews.every((val, i, arr) => val.value === arr[0].value) === true
    ) {
      videoViewsChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="funnelChartType" />
        </Wrapper>
      );
    } else {
      videoViewsChart = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={videoViews}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={videoViewserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    videoViewsChart = (
      <Wrapper>
        <Loader loaderType="funnelChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TREND ANALYSIS
  const [isTrendAnalysisOpen, setIsTrendAnalysisOpen] = useState(false)

  let defaultTrendAnalysisDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultTrendAnalysisDisabledvalues = []
  } else {
    defaultTrendAnalysisDisabledvalues = ["Mid Point", "Third Quartile"]
  }

  const [trendAnalysisDisabledvalues, setTrendAnalysisDisabledvalues] = useState(defaultTrendAnalysisDisabledvalues)

  const fetchTrendAnalysisHandler = async () => {
    setTrendAnalysisIsLoading(true);
    setTrendAnalysisIsLoaded(false);
    setTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/trend-analysis/",
        config
      );

      setTrendAnalysis(res?.data?.data["overall"] ?? []);
      setTrendAnalysisLabels(res?.data?.label["overall"] ?? []);
      setTrendAnalysisAllData(res?.data?.data ?? []);
      setTrendAnalysisAllLabels(res?.data?.label ?? []);

      setTrendAnalysisIsLoading(false);
      setTrendAnalysisIsLoaded(true);
      setTrendAnalysisError(null);
    } catch (error) {
      setTrendAnalysisAllLabels([]);
      setTrendAnalysisAllData([]);
      setTrendAnalysis([]);
      setTrendAnalysisLabels([]);
      setTrendAnalysisIsLoading(false);
      setTrendAnalysisIsLoaded(false);
      setTrendAnalysisError(error.code);
    }
  };

  let trendAnalysisChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let trendAnalysisChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isTrendAnalysisLoaded && !isTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(trendAnalysis) ||
      NoDataAvailableChecker(trendAnalysisLabels)
    ) {
      trendAnalysisChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      trendAnalysisChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      if (isMultiLineCluster) {

        trendAnalysisChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="social_twitter_profile_clicks"
                chartClass="section_card_chart"
                chartData={trendAnalysis}
                chartLabels={trendAnalysisLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={trendAnalysisDisabledvalues}
                setDisabledValues={setTrendAnalysisDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        trendAnalysisChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={trendAnalysis}
                chartId="trendAnalysisChart_2"
                chartLabels={trendAnalysisLabels}
                showLegend={true}
                disabledLegendArray={trendAnalysisDisabledvalues}
                setDisabledValues={setTrendAnalysisDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        )

      } else {
        trendAnalysisChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="social_twitter_profile_clicks"
                chartClass="section_card_chart"
                chartData={trendAnalysis}
                chartLabels={trendAnalysisLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trendAnalysiserror) {
    trendAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={trendAnalysiserror}
        />
      </Wrapper>
    );

    trendAnalysisChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={trendAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTrendAnalysisLoading) {
    trendAnalysisChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    trendAnalysisChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // KPI TREND ANALYSIS

  // TO FETCH METRIC CARDS FROM API
  const fetchKpiTrendAnalysisHandler = async () => {
    setKpiTrendAnalysisIsLoading(true);
    setKpiTrendAnalysisIsLoaded(false);
    setKpiTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        benchmark: secondKpiTrendAnalysisDropdown.value,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(

        "performance/dv360/video/kpi-trend-analysis/",
        config
      );

      setKpiTrendAnalysisData(res?.data?.data?.vtr ?? []);

      setKpiTrendAnalysisDataLabels(res?.data?.label?.vtr ?? []);

      setKpiTrendAnalysisBenchmark(res?.data?.benchmark?.vtr[0] ?? []);

      setKpiTrendAnalysisAllData(res?.data?.data ?? []);
      setKpiTrendAnalysisAllLabels(res?.data?.label ?? []);

      setKpiTrendAnalysisAllBenchmark(res?.data?.benchmark ?? []);

      setKpiTrendAnalysisIsLoading(false);
      setKpiTrendAnalysisIsLoaded(true);
      setKpiTrendAnalysisError(null);
    } catch (error) {
      setKpiTrendAnalysisData([]);
      setKpiTrendAnalysisDataLabels([]);
      setKpiTrendAnalysisBenchmark([]);
      setKpiTrendAnalysisAllData([]);
      setKpiTrendAnalysisAllLabels([]);
      setKpiTrendAnalysisAllBenchmark([]);
      setKpiTrendAnalysisIsLoading(false);
      setKpiTrendAnalysisIsLoaded(false);
      setKpiTrendAnalysisError(error.code);
    }
  };

  let kpiTrendAnalysisLineChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isKpiTrendAnalysisLoaded && !isKpiTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(kpiTrendAnalysisData) ||
      NoDataAvailableChecker(kpiTrendAnalysisDataLabels) ||
      NoDataAvailableChecker(kpiTrendAnalysisBenchmark)
    ) {
      kpiTrendAnalysisLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      kpiTrendAnalysisLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="kpiTrendAnalysis"
              chartClass="section_card_chart"
              showLegend={false}
              chartData={kpiTrendAnalysisData}
              chartLabels={kpiTrendAnalysisDataLabels}
              showTarget={true}
              targetValue={kpiTrendAnalysisBenchmark}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (kpiTrendAnalysisError) {
    kpiTrendAnalysisLineChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={kpiTrendAnalysisError}
          chartType="lineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isKpiTrendAnalysisLoading) {
    kpiTrendAnalysisLineChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // HALEON KPI TREND ANALYSIS
  const fetchHaleonKpiTrendAnalysisHandler = async () => {
    setHaleonKpiTrendAnalysisIsLoading(true);
    setHaleonKpiTrendAnalysisIsLoaded(false);
    setHaleonKpiTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        benchmark: secondKpiTrendAnalysisDropdown.value,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/video/kpi-trend-analysis-without-benchmark/", config);

      setHaleonKpiTrendAnalysisData(res?.data?.data?.vtr ?? []);

      setHaleonKpiTrendAnalysisDataLabels(res?.data?.label?.vtr ?? []);

      setHaleonKpiTrendAnalysisAllData(res?.data?.data ?? []);
      setHaleonKpiTrendAnalysisAllLabels(res?.data?.label ?? []);


      setHaleonKpiTrendAnalysisIsLoading(false);
      setHaleonKpiTrendAnalysisIsLoaded(true);
      setHaleonKpiTrendAnalysisError(null);
    } catch (error) {
      setHaleonKpiTrendAnalysisData([]);
      setHaleonKpiTrendAnalysisDataLabels([]);
      setHaleonKpiTrendAnalysisAllData([]);
      setHaleonKpiTrendAnalysisAllLabels([]);
      setHaleonKpiTrendAnalysisIsLoading(false);
      setHaleonKpiTrendAnalysisIsLoaded(false);
      setHaleonKpiTrendAnalysisError(error.code);
    }
  };

  let haleonKpiTrendAnalysisLineChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isHaleonKpiTrendAnalysisLoaded && !isHaleonKpiTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(haleonKpiTrendAnalysisData) || NoDataAvailableChecker(haleonKpiTrendAnalysisDataLabels)) {
      haleonKpiTrendAnalysisLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      haleonKpiTrendAnalysisLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="haleonKpiTrendAnalysis"
              chartClass="section_card_chart"
              showLegend={false}
              chartData={haleonKpiTrendAnalysisData}
              chartLabels={haleonKpiTrendAnalysisDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (haleonKpiTrendAnalysisError) {
    haleonKpiTrendAnalysisLineChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={haleonKpiTrendAnalysisError}
          chartType="lineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHaleonKpiTrendAnalysisLoading) {
    haleonKpiTrendAnalysisLineChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CONTENT POSITION WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchContentPositionDistributionHandler = async () => {
    setContentPositionDistributionIsLoading(true);
    setContentPositionDistributionIsLoaded(false);
    setContentPositionDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/content-position-wise-distribution/",
        config
      );

      setContentPositionDistribution(res?.data?.data?.complete_views ?? []);
      setContentPositionDistributionAllDropdownsData(res?.data?.data ?? []);

      setContentPositionDistributionIsLoading(false);
      setContentPositionDistributionIsLoaded(true);
      setContentPositionDistributionError(null);
    } catch (error) {
      setContentPositionDistributionAllDropdownsData([]);
      setContentPositionDistribution([]);
      setContentPositionDistributionIsLoading(false);
      setContentPositionDistributionIsLoaded(false);
      setContentPositionDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let contentPositionDistributionChart = (
    <Wrapper>
      <NoDataAvailableLoader chartType="donutChartType" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isContentPositionDistributionLoaded &&
    !isContentPositionDistributionLoading
  ) {
    if (NoDataAvailableChecker(contentPositionDistribution)) {
      contentPositionDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      contentPositionDistributionChart = (
        <Wrapper>
          <DonutPieChart
            chartId={"contentPositionDistribution"}
            chartClass={"section_card_chart"}
            chartData={contentPositionDistribution}
            showLegend={true}
            showLabels={true}
            showVerticalLabels={true}
          />
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (contentPositionDistributionerror) {
    contentPositionDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={contentPositionDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isContentPositionDistributionLoading) {
    contentPositionDistributionChart = (
      <Wrapper>
        {" "}
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // APP URL DISTRIBUTION
  const [isAppUrlDistributionOpen, setIsAppUrlDistributionOpen] = useState(false)
  const [appUrlDistributionDisabledvalues, setAppUrlDistributionDisabledvalues] = useState([])

  const fetchAppUrlDistributionHandler = async () => {
    setAppUrlDistributionIsLoading(true);
    setAppUrlDistributionIsLoaded(false);
    setAppUrlDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    let appUrlDistributionHandlerAPI;

    if (companyID === HALEON_COMPANY_ID) {
      appUrlDistributionHandlerAPI = "performance/dv360/video/app-wise-bar-url-distribution/"
    } else {
      appUrlDistributionHandlerAPI = "performance/dv360/video/app-url-wise-distribution/"
    }


    try {
      const res = await axios.get(appUrlDistributionHandlerAPI, config);
      setAppUrlDistribution(res?.data?.data?.complete_views ?? []);
      setAppUrlDistributionLabels(res?.data?.label?.complete_views ?? []);
      setAppUrlDistributionAllData(res?.data?.data ?? []);
      setAppUrlDistributionAllLabels(res?.data?.label ?? []);

      setAppUrlDistributionIsLoading(false);
      setAppUrlDistributionIsLoaded(true);
      setAppUrlDistributionError(null);
    } catch (error) {
      setAppUrlDistributionAllLabels([]);
      setAppUrlDistributionAllData([]);
      setAppUrlDistribution([]);
      setAppUrlDistributionLabels([]);
      setAppUrlDistributionIsLoading(false);
      setAppUrlDistributionIsLoaded(false);
      setAppUrlDistributionError(error.code);
    }
  };

  let appUrlDistributionChart = (
    <Wrapper>
      <Loader loaderType={companyID == HALEON_COMPANY_ID ? "barChartLoader" : "singleLineChartLoader"} />
    </Wrapper>
  );

  let appUrlDistributionChart2 = (
    <Wrapper>
      <Loader loaderType={companyID == HALEON_COMPANY_ID ? "barChartLoader" : "singleLineChartLoader"} />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAppUrlDistributionLoaded && !isAppUrlDistributionLoading) {
    if (
      NoDataAvailableChecker(appUrlDistribution) ||
      NoDataAvailableChecker(appUrlDistributionLabels)
    ) {
      appUrlDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType={companyID == HALEON_COMPANY_ID ? "barChartType" : "lineChartType"} />
        </Wrapper>
      );

      appUrlDistributionChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType={companyID == HALEON_COMPANY_ID ? "barChartType" : "lineChartType"} />
        </Wrapper>
      );
    } else {


      if (isMultiLineCluster) {
        appUrlDistributionChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              {companyID == HALEON_COMPANY_ID ?
                <BarChart
                  chartId="social_twitter_profile_clickss"
                  chartClass="section_card_chart"
                  chartData={appUrlDistribution}
                  chartLabels={appUrlDistributionLabels}
                />
                :
                <LineChart
                  chartId="social_twitter_profile_clickss"
                  chartClass="section_card_chart"
                  chartData={appUrlDistribution}
                  chartLabels={appUrlDistributionLabels}
                  showLegend={true}
                  showLabels={true}
                  disabledLegendArray={appUrlDistributionDisabledvalues}
                  setDisabledValues={setAppUrlDistributionDisabledvalues}
                />
              }
            </ErrorBoundary>
          </Wrapper>
        );

        appUrlDistributionChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={appUrlDistribution}
                chartId="app_url_trend_2"
                chartLabels={appUrlDistributionLabels}
                showLegend={true}
                disabledLegendArray={appUrlDistributionDisabledvalues}
                setDisabledValues={setAppUrlDistributionDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        )
      } else {
        appUrlDistributionChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              {companyID == HALEON_COMPANY_ID ?
                <BarChart
                  chartId="social_twitter_profile_clickss"
                  chartClass="section_card_chart"
                  chartData={appUrlDistribution}
                  chartLabels={appUrlDistributionLabels}
                />
                :
                <LineChart
                  chartId="social_twitter_profile_clickss"
                  chartClass="section_card_chart"
                  chartData={appUrlDistribution}
                  chartLabels={appUrlDistributionLabels}
                  showLegend={true}
                  showLabels={true}
                />
              }
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (appUrlDistributionerror) {
    appUrlDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader chartType={companyID == HALEON_COMPANY_ID ? "barChartType" : "lineChartType"} error={appUrlDistributionerror} />
      </Wrapper>
    );

    appUrlDistributionChart2 = (
      <Wrapper>
        <ServerErrorsLoader chartType={companyID == HALEON_COMPANY_ID ? "barChartType" : "lineChartType"} error={appUrlDistributionerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAppUrlDistributionLoading) {
    appUrlDistributionChart = (
      <Wrapper>
        <Loader loaderType={companyID == HALEON_COMPANY_ID ? "barChartLoader" : "singleLineChartLoader"} />
      </Wrapper>
    );

    appUrlDistributionChart2 = (
      <Wrapper>
        <Loader loaderType={companyID == HALEON_COMPANY_ID ? "barChartLoader" : "singleLineChartLoader"} />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   DEVICE WISE DISTRIBUTION

  let treemapLevel = 1;
  const [treemapDeviceType, setTreemapDeviceType] = useState("");
  const [treemapOS, setTreemapOS] = useState("");

  const [treemapParams, setTreemapParams] = useState({
    brand_id: BRAND_ID,
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    objective: selectedObjectives,
    platform_campaign: selectedIdandPlatform,
    platform: selectedPlatform,
    campaign: selectedCampaigns,
    campaign_tag:
      selectedBucketsFromFilter.length === 0
        ? null
        : selectedBucketsFromFilter.join(","),
    level: 1,
  });

  const chartlevelHandler = (data) => {
    if (data.level === "1") {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        device_type: data.device_type,
        level: 2,
      });
      setTreemapDeviceType(data.device_type);
    } else if (data.level === "2") {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        device_type: treemapDeviceType,
        operating_system: data.operating_system,
        level: 3,
      });
      setTreemapOS(data.operating_system);
    } else {
      alert("No further drill down available");
    }
  };

  const fetchDeviceWiseDistributionHandler = async () => {
    setDeviceWiseDistributionIsLoading(true);
    setDeviceWiseDistributionIsLoaded(false);
    setDeviceWiseDistributionError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/video/device-wise-distribution/", config);

      setDeviceWiseDistribution(res?.data ?? []);
      setDeviceWiseDistributionIsLoading(false);
      setDeviceWiseDistributionIsLoaded(true);
      setDeviceWiseDistributionError(null);
    } catch (error) {
      setDeviceWiseDistribution([]);
      setDeviceWiseDistributionIsLoading(false);
      setDeviceWiseDistributionIsLoaded(false);
      setDeviceWiseDistributionError(error.code);
    }
  };

  let deviceWiseDistributionSection = (
    <Wrapper>
      <div className="section_card_chart_wrapper flex column">
        <Loader loaderType="treeChartLoader" />
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isDeviceWiseDistributionLoaded && !isDeviceWiseDistributionLoading) {
    if (
      NoDataAvailableChecker(deviceWiseDistribution) ||
      (deviceWiseDistribution.length === 1 &&
        deviceWiseDistribution[0].value === 0)
    ) {
      deviceWiseDistributionSection = (
        <Wrapper>
          <div className="section_card_chart_wrapper flex column">
            <NoDataAvailableLoader chartType="treeChartType" />
          </div>
        </Wrapper>
      );
    } else {
      if (treemapLevel > 3) {
        deviceWiseDistributionSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <NoDataAvailableLoader chartType="treeChartType" />
            </div>
          </Wrapper>
        );
      } else {
        deviceWiseDistributionSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <ErrorBoundary chartType="treeChartType">
                <SimpleTreeMap
                  chartId="simpleTreemaptest"
                  chartClass="section_card_chart"
                  chartData={deviceWiseDistribution}
                  levelHandler={chartlevelHandler}
                  /* colorChangeParameter={"growth_percentage"} */
                  chartLabels={[
                    {
                      name: "name",
                      value: "value",
                      toolTipvalue1: "complete_views",
                      toolTipvalue1Label: "Complete Views",
                      toolTipvalue2: "spends",
                      toolTipvalue2Label: "Spends",
                    },
                  ]}
                />
              </ErrorBoundary>
            </div>
            {/*  {isDeviceWiseDistributionLoaded &&
              !isDeviceWiseDistributionLoading &&
              Object.keys(deviceWiseDistribution).length !== 0 && (
                <div className="w_100">
                  <ul className="icz-rilist-inline">
                    <li>
                      <div className="legend-circle circleGreen"></div> Growth
                    </li>
                    <li>
                      <div className="legend-circle circleRed"></div> Degrowth
                    </li>
                  </ul>
                </div>
              )} */}
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceWiseDistributionerror) {
    deviceWiseDistributionSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <ServerErrorsLoader
            chartType="treeChartType"
            error={deviceWiseDistributionerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceWiseDistributionLoading) {
    deviceWiseDistributionSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <Loader loaderType="treeChartLoader" />
        </div>
      </Wrapper>
    );
  }

  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchDeviceWiseDistributionHandler();
  }, [treemapParams]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // STATE MAP

  const fetchStateHandler = async () => {
    setStateDataIsLoading(true);
    setStateDataIsLoaded(false);
    setStateDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/audience-distribution-state/",
        config
      );

      setStateData(res?.data ?? []);

      setStateDataIsLoading(false);
      setStateDataIsLoaded(true);
      setStateDataError(null);
    } catch (error) {
      setStateData([]);
      setStateDataIsLoading(false);
      setStateDataIsLoaded(false);
      setStateDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: STATE
  let audienceDistributionState = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By State</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isStateDataLoaded && !isStateDataLoading) {
    if (
      NoDataAvailableChecker(stateData) ||
      NoDataAvailableChecker(stateTableDataHeaders)
    ) {
      audienceDistributionState = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By State</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionState = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By State"}
              isDynamicFlagVisible={true}
              tableHeader={stateTableDataHeaders}
              tableData={stateData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search State"}
              tableID="audience_distribution_country_state_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_state_section"}
                  sectionName={"DvStateTableCoke_Audience Distribution By State"}
                  sectionData={stateData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateDataerror) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateDataLoading) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/audience-distribution-city/",
        config
      );

      setCityData(res?.data ?? []);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCity = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(cityTableDataHeaders)
    ) {
      audienceDistributionCity = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCity = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By City"}
              isDynamicFlagVisible={true}
              tableHeader={cityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
              tableID="audience_distribution_country_city_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_city_section"}
                  sectionName={"Audience Distribution By City"}
                  sectionData={cityData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY TABLE

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/audience-distribution-country/",
        config
      );

      setCountryData(res?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCountry = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(countryTableDataHeaders)
    ) {
      audienceDistributionCountry = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCountry = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country"}
              isCountryFlagVisible={true}
              tableHeader={countryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableID="audience_distribution_country_country_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_country_section"}
                  sectionName={"Audience Distribution - Country"}
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }



  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AD TYPE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchAdTypeDistributionHandler = async () => {
    setAdTypeDistributionIsLoading(true);
    setAdTypeDistributionIsLoaded(false);
    setAdTypeDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/youtube-ad-type-distribution/",
        config
      );
      setAdTypeDistribution(res?.data?.data?.complete_views ?? []);
      setAdTypeDistributionAllDropdownsData(res?.data?.data ?? []);

      setAdTypeDistributionIsLoading(false);
      setAdTypeDistributionIsLoaded(true);
      setAdTypeDistributionError(null);
    } catch (error) {
      setAdTypeDistributionAllDropdownsData([]);
      setAdTypeDistribution([]);
      setAdTypeDistributionIsLoading(false);
      setAdTypeDistributionIsLoaded(false);
      setAdTypeDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let adTypeDistributionChart = (
    <Wrapper>
      <NoDataAvailableLoader chartType="donutChartType" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isAdTypeDistributionLoaded && !isAdTypeDistributionLoading) {
    if (NoDataAvailableChecker(adTypeDistribution)) {
      adTypeDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      adTypeDistributionChart = (
        <Wrapper>
          <DonutPieChart
            chartId={"adTypeDistribution"}
            chartClass={"section_card_chart"}
            chartData={adTypeDistribution}
            showLegend={true}
            showLabels={true}
            showVerticalLabels={true}
          />
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (adTypeDistributionerror) {
    adTypeDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={adTypeDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAdTypeDistributionLoading) {
    adTypeDistributionChart = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AD TYPE PERFORMANCE
  const [isAdTypePerformanceOpen, setIsAdTypePerformanceOpen] = useState(false)

  let defaultAdTypePerformanceDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultAdTypePerformanceDisabledvalues = []
  } else {
    defaultAdTypePerformanceDisabledvalues = ["Masthead ad", "Bumper ad"]
  }

  const [adTypePerformanceDisabledvalues, setAdTypePerformanceDisabledvalues] = useState(defaultAdTypePerformanceDisabledvalues)

  const fetchAdTypePerformanceHandler = async () => {
    setAdTypePerformanceIsLoading(true);
    setAdTypePerformanceIsLoaded(false);
    setAdTypePerformanceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/youtube-performance-by-ad/",
        config
      );

      setAdTypePerformance(res?.data?.data?.complete_views ?? []);
      setAdTypePerformanceLabels(res?.data?.label?.complete_views ?? []);
      setAdTypePerformanceAllData(res?.data?.data ?? []);
      setAdTypePerformanceAllLabels(res?.data?.label ?? []);

      setAdTypePerformanceIsLoading(false);
      setAdTypePerformanceIsLoaded(true);
      setAdTypePerformanceError(null);
    } catch (error) {
      setAdTypePerformanceAllLabels([]);
      setAdTypePerformanceAllData([]);
      setAdTypePerformance([]);
      setAdTypePerformanceLabels([]);
      setAdTypePerformanceIsLoading(false);
      setAdTypePerformanceIsLoaded(false);
      setAdTypePerformanceError(error.code);
    }
  };

  let adTypePerformanceChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );
  let adTypePerformanceChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAdTypePerformanceLoaded && !isAdTypePerformanceLoading) {
    if (
      NoDataAvailableChecker(adTypePerformance) ||
      NoDataAvailableChecker(adTypePerformanceLabels)
    ) {
      adTypePerformanceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      adTypePerformanceChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        adTypePerformanceChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="adTypePerformance"
                chartClass="section_card_chart"
                chartData={adTypePerformance}
                chartLabels={adTypePerformanceLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={adTypePerformanceDisabledvalues}
                setDisabledValues={setAdTypePerformanceDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        adTypePerformanceChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="adTypePerformance_2"
                chartClass="section_card_chart"
                chartData={adTypePerformance}
                chartLabels={adTypePerformanceLabels}
                showLegend={true}
                disabledLegendArray={adTypePerformanceDisabledvalues}
                setDisabledValues={setAdTypePerformanceDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        adTypePerformanceChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="adTypePerformance"
                chartClass="section_card_chart"
                chartData={adTypePerformance}
                chartLabels={adTypePerformanceLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (adTypePerformanceerror) {
    adTypePerformanceChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={adTypePerformanceerror}
        />
      </Wrapper>
    );

    adTypePerformanceChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={adTypePerformanceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAdTypePerformanceLoading) {
    adTypePerformanceChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    adTypePerformanceChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VTR TREND ANALYSIS
  const [isVtrTrendOpen, setIsVtrTrendOpen] = useState(false)

  let defaultVtrTrendDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVtrTrendDisabledvalues = []
  } else {
    defaultVtrTrendDisabledvalues = ["Midpoint VTR", "Complete VTR"]
  }

  const [VtrTrendDisabledvalues, setVtrTrendDisabledvalues] = useState(defaultVtrTrendDisabledvalues)

  const fetchVtrTrendAnalysisHandler = async () => {
    setVtrTrendAnalysisIsLoading(true);
    setVtrTrendAnalysisIsLoaded(false);
    setVtrTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/youtube-vtr-trend-analysis/",
        config
      );
      setVtrTrendAnalysis(res?.data?.data?.Skippable ?? []);
      setVtrTrendAnalysisLabels(res?.data?.label?.Skippable ?? []);
      setVtrTrendAnalysisAllData(res?.data?.data ?? []);
      setVtrTrendAnalysisAllLabels(res?.data?.label ?? []);

      setVtrTrendAnalysisIsLoading(false);
      setVtrTrendAnalysisIsLoaded(true);
      setVtrTrendAnalysisError(null);
    } catch (error) {
      setVtrTrendAnalysisAllLabels([]);
      setVtrTrendAnalysisAllData([]);
      setVtrTrendAnalysis([]);
      setVtrTrendAnalysisLabels([]);
      setVtrTrendAnalysisIsLoading(false);
      setVtrTrendAnalysisIsLoaded(false);
      setVtrTrendAnalysisError(error.code);
    }
  };

  let vtrTrendAnalysisChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let vtrTrendAnalysisChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isVtrTrendAnalysisLoaded && !isVtrTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(vtrTrendAnalysis) ||
      NoDataAvailableChecker(vtrTrendAnalysisLabels)
    ) {
      vtrTrendAnalysisChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      vtrTrendAnalysisChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        vtrTrendAnalysisChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="vtrTrendAnalysis"
                chartClass="section_card_chart"
                chartData={vtrTrendAnalysis}
                chartLabels={vtrTrendAnalysisLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={VtrTrendDisabledvalues}
                setDisabledValues={setVtrTrendDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        vtrTrendAnalysisChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="vtrTrendAnalysis_2"
                chartClass="section_card_chart"
                chartData={vtrTrendAnalysis}
                chartLabels={vtrTrendAnalysisLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={VtrTrendDisabledvalues}
                setDisabledValues={setVtrTrendDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        vtrTrendAnalysisChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="vtrTrendAnalysis"
                chartClass="section_card_chart"
                chartData={vtrTrendAnalysis}
                chartLabels={vtrTrendAnalysisLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (vtrTrendAnalysiserror) {
    vtrTrendAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={vtrTrendAnalysiserror}
        />
      </Wrapper>
    );

    vtrTrendAnalysisChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={vtrTrendAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVtrTrendAnalysisLoading) {
    vtrTrendAnalysisChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    vtrTrendAnalysisChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // YOUTUBE AD TYPE
  const [isYoutubeAdTypeOpen, setIsYoutubeAdTypeOpen] = useState(false)

  let defaultYoutubeAdTypeDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultYoutubeAdTypeDisabledvalues = []
  } else {
    defaultYoutubeAdTypeDisabledvalues = ["VTR"]
  }

  const [youtubeAdTypeDisabledvalues, setYoutubeAdTypeDisabledvalues] = useState(defaultYoutubeAdTypeDisabledvalues)

  const fetchYoutubeAdTypeHandler = async () => {
    setYoutubeAdTypeIsLoading(true);
    setYoutubeAdTypeIsLoaded(false);
    setYoutubeAdTypeError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        youtube_ad_type: firstYoutubeAdTypeDropdown.value,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    let youtubeAdTypeHandlerAPI;

    if (companyID === HALEON_COMPANY_ID) {
      youtubeAdTypeHandlerAPI = "performance/dv360/video/youtube-ad-type-wise-performance/"
    } else {
      youtubeAdTypeHandlerAPI = "performance/dv360/video/youtube-ad-wise-benchmarking/"
    }

    try {
      const res = await axios.get(youtubeAdTypeHandlerAPI, config);

      setYoutubeAdType(res?.data?.data?.vtr ?? []);
      setYoutubeAdTypeLabels(res?.data?.label?.vtr ?? []);
      setYoutubeAdTypeAllData(res?.data?.data ?? []);
      setYoutubeAdTypeAllLabels(res?.data?.label ?? []);

      setYoutubeAdTypeIsLoading(false);
      setYoutubeAdTypeIsLoaded(true);
      setYoutubeAdTypeError(null);
    } catch (error) {
      setYoutubeAdTypeAllLabels([]);
      setYoutubeAdTypeAllData([]);
      setYoutubeAdType([]);
      setYoutubeAdTypeLabels([]);
      setYoutubeAdTypeIsLoading(false);
      setYoutubeAdTypeIsLoaded(false);
      setYoutubeAdTypeError(error.code);
    }
  };

  let youtubeAdTypeChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let youtubeAdTypeChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isYoutubeAdTypeLoaded && !isYoutubeAdTypeLoading) {
    if (
      NoDataAvailableChecker(youtubeAdType) ||
      NoDataAvailableChecker(youtubeAdTypeLabels)
    ) {
      youtubeAdTypeChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      youtubeAdTypeChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      if (isMultiLineCluster) {

        youtubeAdTypeChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="youtubeAdType"
                chartClass="section_card_chart"
                chartData={youtubeAdType}
                chartLabels={youtubeAdTypeLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={youtubeAdTypeDisabledvalues}
                setDisabledValues={setYoutubeAdTypeDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        youtubeAdTypeChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="youtubeAdType_2"
                chartClass="section_card_chart"
                chartData={youtubeAdType}
                chartLabels={youtubeAdTypeLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={youtubeAdTypeDisabledvalues}
                setDisabledValues={setYoutubeAdTypeDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        youtubeAdTypeChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="youtubeAdType"
                chartClass="section_card_chart"
                chartData={youtubeAdType}
                chartLabels={youtubeAdTypeLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (youtubeAdTypeerror) {
    youtubeAdTypeChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={youtubeAdTypeerror}
        />
      </Wrapper>
    );

    youtubeAdTypeChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={youtubeAdTypeerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isYoutubeAdTypeLoading) {
    youtubeAdTypeChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    youtubeAdTypeChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  const tabData = companyID === HALEON_COMPANY_ID ? ["By Country", "By State", "By City"] : ["By State", "By City"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);


  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit(
      "Marketing Intelligence",
      "Campaign",
      "Dv360",
      "Video",
      event
    );
  };


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/audience-demographic-gender/",
        config
      );
      setTotalMaleCount(res?.data?.male_count?.trueview_views ?? []);
      setMaleCount(res?.data?.male_list?.data?.trueview_views ?? []);
      setMaleCountLabels(res?.data?.dimension?.trueview_views ?? []);

      setTotalFemaleCount(res?.data?.female_count?.trueview_views ?? []);
      setFemaleCount(res?.data?.female_list?.data?.trueview_views ?? []);
      setFemaleCountLabels(res?.data?.dimension?.trueview_views ?? []);

      setTotalUnknownCount(res?.data?.unknown_count?.trueview_views ?? []);
      setUnknownCount(res?.data?.unknown_list?.data?.trueview_views ?? []);
      setUnknownCountLabels(res?.data?.dimension?.trueview_views ?? []);

      setMaleCountAllDropdownsData(res?.data?.male_list?.data ?? []);

      setMaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalMaleCountAllDropdownsData(res?.data?.male_count ?? []);

      setFemaleCountAllDropdownsData(res?.data?.female_list?.data ?? []);

      setFemaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);

      setTotalFemaleCountAllDropdownsData(res?.data?.female_count ?? []);

      setUnknownCountAllDropdownsData(res?.data?.unknown_list?.data ?? []);

      setUnknownCountAllDropdownsDataLabels(res?.data?.dimension ?? []);

      setTotalUnknownCountAllDropdownsData(res?.data?.unknown_count ?? []);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCount([]);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCount([]);

      setUnknownCountAllDropdownsData([]);
      setUnknownCountAllDropdownsDataLabels([]);
      setTotalUnknownCount([]);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>
  let maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }




    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader error={maleCounterror} />
      </Wrapper>
    );

    maleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={maleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  let femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }



    if (NoDataAvailableChecker(totalFemaleCountPercentage)) {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
      </Wrapper>
    );
    femaleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  let unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (
      NoDataAvailableChecker(unknownCount) ||
      NoDataAvailableChecker(unknownCountLabels)
    ) {
      unknownAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_unknown"
              chartClass="section_card_chart"
              chartData={unknownCount}
              chartLabels={unknownCountLabels}
              showLegend={false}
              /*  showLabels={true}
              showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalUnknownCountPercentage)) {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalUnknownCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
    unknownAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  var maleFemaleDataCSV = {
    "Total Count": [{ [`Male - ${selectedDropdownOption.label}`]: totalMaleCount, [`Female - ${selectedDropdownOption.label}`]: totalFemaleCount, [`Unknown - ${selectedDropdownOption.label}`]: totalUnknownCount, }],
    [`AgeBarChartCampaignDynalabels_Audience Distribution-Male_${selectedDropdownOption.label}`]: maleCount,
    [`AgeBarChartCampaignDynalabels_Audience Distribution-Female_${selectedDropdownOption.label}`]: femaleCount,
    [`AgeBarChartCampaignDynalabels_Audience Distribution-Unknown_${selectedDropdownOption.label}`]: unknownCount,
  };



  var allData;

  companyID === HALEON_COMPANY_ID ? allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    "Video Views": videoViews,
    [`DvTrendAnalysis_Trend Analysis_${trendAnalysisDropdown.label}`]: trendAnalysis,
    [`DateFirstFormatter_KPI Trend Analysis`]: haleonKpiTrendAnalysisData,
    [`DonutWithActiveDropdown_Content Position Wise Dist_${selectedContentPositionDistributionDropdownOption.label}`]: contentPositionDistribution,
    [`AppUrl_App - Url Wise Dist`]: appUrlDistribution,

    "DvVideoTreemap_Device Wise Distribution": deviceWiseDistribution,
    "Audience Distribution - State": stateData,
    "Audience Distribution - City": cityData,
    "Audience Distribution - Country": countryData,
    [`DonutWithActiveDropdown_Ad Type Wise Distribution_${selectedAdTypeDistributionDropdownOption.label}`]: adTypeDistribution,
    [`DateFirstActiveDropdownLabels_Performance By Ad Type_${adTypePerformanceDropdown.label}`]: adTypePerformance,
    [`DateFirstActiveDropdownLabels_VTR Trend Analysis_${vtrTrendAnalysisDropdown.label}`]: vtrTrendAnalysis,
    [`DateFirstActiveDropdownLabels_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + ":" + secondYoutubeAdTypeDropdown.label}`]: youtubeAdType,
    ...maleFemaleDataCSV

  }
    : allData = {
      ...topCardData,
      "SummaryCard_Summary Card": metricCards,
      "Video Views": videoViews,
      [`DvTrendAnalysis_Trend Analysis_${trendAnalysisDropdown.label}`]: trendAnalysis,
      [`DvTrendAnalysis_KPI Trend Analysis_${secondKpiTrendAnalysisDropdown.label}`]: kpiTrendAnalysisData,
      [`DonutWithActiveDropdown_Content Position Wise Dist_${selectedContentPositionDistributionDropdownOption.label}`]: contentPositionDistribution,
      [`DateFirstActiveDropdownLabels_App - Url Wise Dist_${appUrlDistributionDropdown.label}`]: appUrlDistribution,
      "DvVideoTreemap_Device Wise Distribution": deviceWiseDistribution,
      "Audience Distribution - State": stateData,
      "Audience Distribution - City": cityData,
      [`DonutWithActiveDropdown_Ad Type Wise Distribution_${selectedAdTypeDistributionDropdownOption.label}`]: adTypeDistribution,
      [`DateFirstActiveDropdownLabels_Performance By Ad Type_${adTypePerformanceDropdown.label}`]: adTypePerformance,
      [`DateFirstActiveDropdownLabels_VTR Trend Analysis_${vtrTrendAnalysisDropdown.label}`]: vtrTrendAnalysis,
      [`DvYTAdType_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + "&" + secondYoutubeAdTypeDropdown.value}`]: youtubeAdType
    };
  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    videoViews,
    trendAnalysis,
    kpiTrendAnalysisData,
    contentPositionDistribution,
    appUrlDistribution,
    deviceWiseDistribution,
    stateData,
    cityData,
    adTypeDistribution,
    adTypePerformance,
    vtrTrendAnalysis,
    youtubeAdType,
    haleonKpiTrendAnalysisData,
    countryData,
    maleCount,
    femaleCount,
    unknownCount
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (videoViewserror !== null ? true : isContentAnalysisLoaded && (isContentAnalysisLoading === false)) &&
      (trendAnalysiserror !== null ? true : isTrendAnalysisLoaded && (isTrendAnalysisLoading === false)) &&
      (kpiTrendAnalysisError !== null ? true : isKpiTrendAnalysisLoaded && (isKpiTrendAnalysisLoading === false)) &&
      (contentPositionDistributionerror !== null ? true : isContentPositionDistributionLoaded && (isContentPositionDistributionLoading === false)) &&
      (appUrlDistributionerror !== null ? true : isAppUrlDistributionLoaded && (isAppUrlDistributionLoading === false)) &&
      (deviceWiseDistributionerror !== null ? true : isDeviceWiseDistributionLoaded && (isDeviceWiseDistributionLoading === false)) &&
      (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
      (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
      (adTypeDistributionerror !== null ? true : isAdTypeDistributionLoaded && (isAdTypeDistributionLoading === false)) &&
      (adTypePerformanceerror !== null ? true : isAdTypePerformanceLoaded && (isAdTypePerformanceLoading === false)) &&
      (vtrTrendAnalysiserror !== null ? true : isVtrTrendAnalysisLoaded && (isVtrTrendAnalysisLoading === false)) &&
      (youtubeAdTypeerror !== null ? true : isYoutubeAdTypeLoaded && (isYoutubeAdTypeLoading === false)) &&
      (haleonKpiTrendAnalysisError !== null ? true : isHaleonKpiTrendAnalysisLoaded && (isHaleonKpiTrendAnalysisLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
      (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
      (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
      (unknownCounterror !== null ? true : isUnknownCountLoaded && (isUnknownCountLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    videoViewserror, isContentAnalysisLoaded, isContentAnalysisLoading,
    trendAnalysiserror, isTrendAnalysisLoaded, isTrendAnalysisLoading,
    kpiTrendAnalysisError, isKpiTrendAnalysisLoaded, isKpiTrendAnalysisLoading,
    contentPositionDistributionerror, isContentPositionDistributionLoaded, isContentPositionDistributionLoading,
    appUrlDistributionerror, isAppUrlDistributionLoaded, isAppUrlDistributionLoading,
    deviceWiseDistributionerror, isDeviceWiseDistributionLoaded, isDeviceWiseDistributionLoading,
    stateDataerror, isStateDataLoaded, isStateDataLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
    adTypeDistributionerror, isAdTypeDistributionLoaded, isAdTypeDistributionLoading,
    adTypePerformanceerror, isAdTypePerformanceLoaded, isAdTypePerformanceLoading,
    vtrTrendAnalysiserror, isVtrTrendAnalysisLoaded, isVtrTrendAnalysisLoading,
    youtubeAdTypeerror, isYoutubeAdTypeLoaded, isYoutubeAdTypeLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading,
    maleCounterror, isMaleCountLoaded, isMaleCountLoading,
    femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
    unknownCounterror, isUnknownCountLoaded, isUnknownCountLoading,
    (companyID === HALEON_COMPANY_ID && haleonKpiTrendAnalysisError, isHaleonKpiTrendAnalysisLoaded, isHaleonKpiTrendAnalysisLoading),
  ])

  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="video_view_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Views  </h2>
              <SectionDefinationsBadge
                title={"Video Views"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_view_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={"Video Views"}
                sectionData={videoViews}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart}</div>
          </div>
        </div>
      </div>

      <div
        id="trend_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              Trend Analysis
              {trendAnalysisDropdown && " : " + trendAnalysisDropdown.label}
            </h2> <SectionDefinationsBadge
                title="Trend Analysis"
                module="campaign"
                platform="Dv360"
                section="Video"
              />
            </div>
            <Modal
              open={isTrendAnalysisOpen}
              setOpen={setIsTrendAnalysisOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"trendAnalysisChart_2"}
                  sectionName={`DvTrendAnalysis_Trend Analysis_${trendAnalysisDropdown.label}`}
                  sectionData={trendAnalysis}
                />}
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={trendAnalysisDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={trendAnalysisDropdown}
                  selectedOptions={trendAnalysisDropdown}
                  setStatedropdown={trendAnalysisClickHandler}
                />
              }
              parentId={"trend_analysis_section"}
              title={`Trend Analysis ${trendAnalysisDropdown && " : " + trendAnalysisDropdown.label}`}
              chart={trendAnalysisChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsTrendAnalysisOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={trendAnalysisDropdownOptions}
                className="form_dropdown section_dropdown ml_20"
                value={trendAnalysisDropdown}
                selectedOptions={trendAnalysisDropdown}
                setStatedropdown={trendAnalysisClickHandler}
              />

              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"trend_analysis_section"}
                sectionName={`DvTrendAnalysis_Trend Analysis_${trendAnalysisDropdown.label}`}
                sectionData={trendAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {trendAnalysisChart}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom">

        {companyID === HALEON_COMPANY_ID ?

          <div id="kpi_trend_analysis_haleon" className="grid col_span_2 section_card">
            <div className="section_card_header ">
              <div className="section_info">  <h2 className="section_card_title">
                KPI Trend Analysis
                {haleonKpiTrendAnalysisDropdown && " : " + haleonKpiTrendAnalysisDropdown.label}
              </h2> <SectionDefinationsBadge
                  title={"KPI Trend Analysis"}
                  module="campaign"
                  platform="Dv360"
                  section="Video"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={haleonKpiTrendAnalysisOptions}
                  className="form_dropdown section_dropdown grid_margin_right w_50"
                  value={haleonKpiTrendAnalysisDropdown}
                  selectedOptions={haleonKpiTrendAnalysisDropdown}
                  setStatedropdown={haleonKpiTrendAnalysisClickHandler}
                />
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"kpi_trend_analysis_haleon"}
                  sectionName={`DateFirstFormatter_KPI Trend Analysis`}
                  sectionData={haleonKpiTrendAnalysisData}
                />
              </div>
            </div>
            <div className="grid grid_cols_1 section_card_body grid_row_gap_0">
              <div className="section_card_chart_wrapper">
                {haleonKpiTrendAnalysisLineChart}
              </div>
            </div>
          </div>
          :
          <div id="kpi_trend_analysis" className="grid col_span_2 section_card">
            <div className="section_card_header column_heading flex align_start">
              <div className="section_info">
                <h2 className="section_card_title section_title_overflow">
                  KPI Trend Analysis
                  {firstKpiTrendAnalysisDropdown && " : " + firstKpiTrendAnalysisDropdown.label}
                  {secondKpiTrendAnalysisDropdown && " : " + secondKpiTrendAnalysisDropdown.label}
                </h2>
                <SectionDefinationsBadge
                  title={"KPI Trend Analysis"}
                  module="campaign"
                  platform="Dv360"
                  section="Video"
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={firstKpiTrendAnalysisDropdownOptions}
                  className="form_dropdown section_dropdown grid_margin_right"
                  value={firstKpiTrendAnalysisDropdown}
                  selectedOptions={firstKpiTrendAnalysisDropdown}
                  setStatedropdown={firstKpiTrendAnalysisClickHandler}
                />

                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={secondKpiTrendAnalysisDropdownOptions}
                  className="form_dropdown section_dropdown"
                  value={secondKpiTrendAnalysisDropdown}
                  selectedOptions={secondKpiTrendAnalysisDropdown}
                  setStatedropdown={secondKpiTrendAnalysisClickHandler}
                />
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"kpi_trend_analysis"}
                  sectionName={`DvTrendAnalysis_KPI Trend Analysis_${secondKpiTrendAnalysisDropdown.label}`}
                  sectionData={kpiTrendAnalysisData}
                />
              </div>
            </div>
            <div className="grid grid_cols_1 section_card_body grid_row_gap_0">
              <div className="section_card_chart_wrapper">
                {kpiTrendAnalysisLineChart}
              </div>
              {Object.keys(kpiTrendAnalysisData).length !== 0 && (
                <div className="w_100 grid_margin_bottom">
                  <ul className="icz-rilist-inline">
                    <li>
                      <div className="legend-circle circleGreen"></div>{" "}
                      Above Benchmark
                    </li>
                    <li>
                      <div className="legend-circle circleRed"></div>{" "}
                      Below Benchmark
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

        }


        <div
          id="content_position_wise_distribution"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info w_50" >
              <h2 className="section_card_title w_unset">
                Content Position Wise Distribution
                {selectedContentPositionDistributionDropdownOption && " : " + selectedContentPositionDistributionDropdownOption.label}
              </h2> <SectionDefinationsBadge
                title={"Content Position Wise Distribution"}
                module="campaign"
                platform="Dv360"
                section="Video"
              />
            </div>
            <div data-html2canvas-ignore={true} >
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={contentPositionDistributionDropdownOptions}
                className="form_dropdown section_dropdown"
                value={selectedContentPositionDistributionDropdownOption}
                selectedOptions={
                  selectedContentPositionDistributionDropdownOption
                }
                setStatedropdown={
                  contentPositionDistributionDropdownSelectionHandler
                }
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"content_position_wise_distribution"}
                sectionName={`DonutWithActiveDropdown_Content Position Wise Dist_${selectedContentPositionDistributionDropdownOption.label}`}
                sectionData={contentPositionDistribution}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {contentPositionDistributionChart}
            </div>
          </div>
        </div>
      </div>



      <div
        id="app_url_wise_distribution_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              App/Url Wise Distribution
              {appUrlDistributionDropdown && " : " + appUrlDistributionDropdown.label}
            </h2> <SectionDefinationsBadge
                title="App/Url Wise Distribution"
                module="campaign"
                platform="Dv360"
                section="Video"
              />
            </div>
            <Modal
              open={isAppUrlDistributionOpen}
              setOpen={setIsAppUrlDistributionOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"app_url_trend_2"}
                  sectionName={companyID === HALEON_COMPANY_ID ? "AppUrl_App - Url Wise Dist" : `DateFirstActiveDropdownLabels_App - Url Wise Dist_${appUrlDistributionDropdown.label}`}
                  sectionData={appUrlDistribution}
                />
              }
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={appUrlDistributionDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={appUrlDistributionDropdown}
                  selectedOptions={appUrlDistributionDropdown}
                  setStatedropdown={appUrlDistributionClickHandler}
                />
              }
              parentId={"app_url_wise_distribution_section"}
              title={`App/Url Wise Distribution ${appUrlDistributionDropdown && " : " + appUrlDistributionDropdown.label}`}
              chart={appUrlDistributionChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsAppUrlDistributionOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={appUrlDistributionDropdownOptions}
                className="form_dropdown section_dropdown ml_20"
                value={appUrlDistributionDropdown}
                selectedOptions={appUrlDistributionDropdown}
                setStatedropdown={appUrlDistributionClickHandler}
              />

              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"app_url_wise_distribution_section"}
                sectionName={companyID === HALEON_COMPANY_ID ? "AppUrl_App - Url Wise Dist" : `DateFirstActiveDropdownLabels_App - Url Wise Dist_${appUrlDistributionDropdown.label}`}
                sectionData={appUrlDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {appUrlDistributionChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="device_wise_distribution_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header ">
            <div className="flex column align_start">
              <div className="section_info">   <h2 className="section_card_title">
                Device Wise Distribution
              </h2> <SectionDefinationsBadge
                  title="Device Wise Distribution"
                  module="campaign"
                  platform="Dv360"
                  section="Video"
                />
              </div>
              {isDeviceWiseDistributionLoading === false && isDeviceWiseDistributionLoaded === true && (
                <div className="treemap_breadcrumb">
                  <span
                    onClick={() => {
                      setTreemapParams({
                        brand_id: BRAND_ID,
                        start_date: selectedDates.startDate,
                        end_date: selectedDates.endDate,
                        previous_start_date: selectedDates.prevStartDate,
                        previous_end_date: selectedDates.prevEndDate,
                        level: 1,
                      });
                      setTreemapDeviceType("");
                      setTreemapOS("");
                    }}
                  >
                    Home
                  </span>
                  {treemapDeviceType && (
                    <span
                      onClick={() => {
                        setTreemapParams({
                          brand_id: BRAND_ID,
                          start_date: selectedDates.startDate,
                          end_date: selectedDates.endDate,
                          previous_start_date: selectedDates.prevStartDate,
                          previous_end_date: selectedDates.prevEndDate,
                          device_type: treemapDeviceType,
                          level: 2,
                        });
                        setTreemapOS("");
                      }}
                    >
                      {" / "}
                      <TextFormatter string={treemapDeviceType} />
                    </span>
                  )}

                  {treemapOS && (
                    <span>
                      {" / "}
                      <TextFormatter string={treemapOS} />{" "}
                    </span>
                  )}
                </div>
              )}
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_wise_distribution_section"}
                sectionName={"DvVideoTreemap_Device Wise Distribution"}
                sectionData={deviceWiseDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            {deviceWiseDistributionSection}
          </div>
        </div>
      </div>

      <div id="audience_distri_country_city_section">
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">
                Audience Distribution
              </h2> <SectionDefinationsBadge
                  title={"Audience Distribution"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />

              </div>
            </div>
          </div>
        </div>

        <div className="grid  grid_margin_bottom ">
          <Tab
            varient={"primary"}
            tabData={tabData}
            activeTab={selectedTab}
            handleCallback={(event) => handleCallback(event)}
          />
        </div>

        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          {(companyID === HALEON_COMPANY_ID && selectedTab === "By Country") && (
            <Wrapper>
              {audienceDistributionCountry}
            </Wrapper>
          )}
          {selectedTab === "By State" && (
            <Wrapper>
              {audienceDistributionState}
            </Wrapper>
          )}
          {selectedTab === "By City" && (
            <Wrapper>
              {audienceDistributionCity}
            </Wrapper>
          )}

        </div>
      </div>

      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              YouTube Ad-Type (Skippable/Non Skippable) At Ad-Group Level
            </h2>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="ad_type_wise_distribution_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info w_50">
              <h2 className="section_card_title w_unset">
                Ad Type Wise Distribution
                {selectedAdTypeDistributionDropdownOption && " : " + selectedAdTypeDistributionDropdownOption.label}
              </h2> <SectionDefinationsBadge
                title={"Ad Type Wise Distribution"}
                module="campaign"
                platform="Dv360"
                section="Video"
              />
            </div>
            <div data-html2canvas-ignore={true} >
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={adTypeDistributionDropdownOptions}
                className="form_dropdown section_dropdown"
                value={selectedAdTypeDistributionDropdownOption}
                selectedOptions={selectedAdTypeDistributionDropdownOption}
                setStatedropdown={adTypeDistributionDropdownSelectionHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"ad_type_wise_distribution_section"}
                sectionName={`DonutWithActiveDropdown_Ad Type Wise Distribution_${selectedAdTypeDistributionDropdownOption.label}`}
                sectionData={adTypeDistribution}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {adTypeDistributionChart}
            </div>
          </div>
        </div>
        <div
          id="performance_by_ad_type_section"
          className="grid col_span_2 section_card"
        >
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info w_50">
                <h2 className="section_card_title w_unset">
                  Performance By Ad Type
                  {adTypePerformanceDropdown && " : " + adTypePerformanceDropdown.label}
                </h2><SectionDefinationsBadge
                  title="Performance By Ad Type"
                  module="campaign"
                  platform="Dv360"
                  section="Video"
                /></div>
              <Modal
                open={isAdTypePerformanceOpen}
                setOpen={setIsAdTypePerformanceOpen}
                exportDropdown={
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"adTypePerformance_2"}
                    sectionName={`DateFirstActiveDropdownLabels_Performance By Ad Type_${adTypePerformanceDropdown.label}`}
                    sectionData={adTypePerformance}
                  />}

                dropdown={
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={adTypePerformanceDropdownOptions}
                    className="form_dropdown section_dropdown ml_20"
                    value={adTypePerformanceDropdown}
                    selectedOptions={adTypePerformanceDropdown}
                    setStatedropdown={adTypePerformanceClickHandler}
                  />
                }
                parentId={"performance_by_ad_type_section"}
                title={`Performance By Ad Type ${adTypePerformanceDropdown && " : " + adTypePerformanceDropdown.label}`}
                chart={adTypePerformanceChart2}
              />
              <div data-html2canvas-ignore={true} >
                {isMultiLineCluster && <button onClick={() => setIsAdTypePerformanceOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                  <ExpandIcon />
                </button>}
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={adTypePerformanceDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={adTypePerformanceDropdown}
                  selectedOptions={adTypePerformanceDropdown}
                  setStatedropdown={adTypePerformanceClickHandler}
                />

                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"performance_by_ad_type_section"}
                  sectionName={`DateFirstActiveDropdownLabels_Performance By Ad Type_${adTypePerformanceDropdown.label}`}
                  sectionData={adTypePerformance}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {adTypePerformanceChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="vtr_trend_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                VTR Trend Analysis
                {vtrTrendAnalysisDropdown && " : " + vtrTrendAnalysisDropdown.label}
              </h2> <SectionDefinationsBadge
                title="VTR Trend Analysis"
                module="campaign"
                platform="Dv360"
                section="Video"
              />
            </div>
            <Modal
              open={isVtrTrendOpen}
              setOpen={setIsVtrTrendOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"vtrTrendAnalysis_2"}
                  sectionName={`DateFirstActiveDropdownLabels_VTR Trend Analysis_${vtrTrendAnalysisDropdown.label}`}
                  sectionData={vtrTrendAnalysis}
                />}
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={vtrTrendAnalysisDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={vtrTrendAnalysisDropdown}
                  selectedOptions={vtrTrendAnalysisDropdown}
                  setStatedropdown={vtrTrendAnalysisClickHandler}
                />
              }
              parentId={"vtr_trend_analysis_section"}
              title={`VTR Trend Analysis ${vtrTrendAnalysisDropdown && " : " + vtrTrendAnalysisDropdown.label}`}
              chart={vtrTrendAnalysisChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsVtrTrendOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={vtrTrendAnalysisDropdownOptions}
                className="form_dropdown section_dropdown ml_20"
                value={vtrTrendAnalysisDropdown}
                selectedOptions={vtrTrendAnalysisDropdown}
                setStatedropdown={vtrTrendAnalysisClickHandler}
              />

              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"vtr_trend_analysis_section"}
                sectionName={`DateFirstActiveDropdownLabels_VTR Trend Analysis_${vtrTrendAnalysisDropdown.label}`}
                sectionData={vtrTrendAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {vtrTrendAnalysisChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="youtube_ad_type_wise_section"
        className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info long_section_info">
              <h2 className="section_card_title w_auto">
                {companyID === HALEON_COMPANY_ID ? "YouTube Ad-Type Wise Performance At Ad-Group Level" : "YouTube Ad-Type Wise Benchmarking At Ad-Group Level"}
                {firstYoutubeAdTypeDropdown && " : " + firstYoutubeAdTypeDropdown.label}
                {secondYoutubeAdTypeDropdown && " : " + secondYoutubeAdTypeDropdown.label}
              </h2>
              <SectionDefinationsBadge
                title="YouTube Ad-Type Wise Benchmarking At Ad-Group Level"
                module="campaign"
                platform="Dv360"
                section="Video"
              />
            </div>
            <Modal
              open={isYoutubeAdTypeOpen}
              setOpen={setIsYoutubeAdTypeOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"youtubeAdType_2"}
                  sectionName={companyID === HALEON_COMPANY_ID ? `DateFirstActiveDropdownLabels_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + ":" + secondYoutubeAdTypeDropdown.label}` : `DvYTAdType_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + "&" + secondYoutubeAdTypeDropdown.value}`}
                  sectionData={youtubeAdType}
                />}

              dropdown={
                <>
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={firstYoutubeAdTypeDropdownOptions}
                    className="form_dropdown section_dropdown grid_margin_right ml_20"
                    value={firstYoutubeAdTypeDropdown}
                    selectedOptions={firstYoutubeAdTypeDropdown}
                    setStatedropdown={firstYoutubeAdTypeClickHandler}
                  />
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={secondYoutubeAdTypeDropdownOptions}
                    className="form_dropdown section_dropdown w_50"
                    value={secondYoutubeAdTypeDropdown}
                    selectedOptions={secondYoutubeAdTypeDropdown}
                    setStatedropdown={secondYoutubeAdTypeClickHandler}
                  />
                </>
              }
              parentId={"youtube_ad_type_wise_section"}
              title={`${companyID === HALEON_COMPANY_ID ? "YouTube Ad-Type Wise Performance At Ad-Group Level" : "YouTube Ad-Type Wise Benchmarking At Ad-Group Level"}
              ${firstYoutubeAdTypeDropdown && " : " + firstYoutubeAdTypeDropdown.label}
              ${secondYoutubeAdTypeDropdown && " : " + secondYoutubeAdTypeDropdown.label}`}
              chart={youtubeAdTypeChart2}
            />
            <div data-html2canvas-ignore={true} className="pl_50">
              {isMultiLineCluster && <button onClick={() => setIsYoutubeAdTypeOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={firstYoutubeAdTypeDropdownOptions}
                className="form_dropdown section_dropdown grid_margin_right ml_20"
                value={firstYoutubeAdTypeDropdown}
                selectedOptions={firstYoutubeAdTypeDropdown}
                setStatedropdown={firstYoutubeAdTypeClickHandler}
              />
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={secondYoutubeAdTypeDropdownOptions}
                className="form_dropdown section_dropdown w_50"
                value={secondYoutubeAdTypeDropdown}
                selectedOptions={secondYoutubeAdTypeDropdown}
                setStatedropdown={secondYoutubeAdTypeClickHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"youtube_ad_type_wise_section"}
                sectionName={companyID === HALEON_COMPANY_ID ? `DateFirstActiveDropdownLabels_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + ":" + secondYoutubeAdTypeDropdown.label}` : `DvYTAdType_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + "&" + secondYoutubeAdTypeDropdown.value}`}
                sectionData={youtubeAdType}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {youtubeAdTypeChart}
            </div>
          </div>
        </div>
      </div>


      {companyID === HALEON_COMPANY_ID &&
        <Wrapper>
          <div
            id="audience_distribution_male_female_section"
            className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
          >
            <div className="grid grid_cols_1">
              <div className="section_card_header">
                <div className="section_info">   <h2 className="section_card_title">
                  Youtube Ad-type Wise Demographic: {selectedDropdownOption.label}
                </h2>  <SectionDefinationsBadge
                    title={"Youtube Ad-type Wise Demographic"}
                    module="campaign"
                    platform="Dv360"
                    section="video"
                  /></div>

                <div data-html2canvas-ignore={true}>
                  <Dropdown
                    className={"form_dropdown section_dropdown"}
                    placeholder={"Select"}
                    isClearable={false}
                    options={audienceDistributionOptions}
                    value={selectedDropdownOption}
                    selectedOptions={selectedDropdownOption}
                    setStatedropdown={dropdownSelectionHandler}
                  />
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"audience_distribution_male_female_section"}
                    sectionName={"Youtube Ad-type Demographic"}
                    sectionData={maleFemaleDataCSV}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid_cols_3">
              <div className="grid col_span_1 card_border_right flex column">
                <div className="section_card_header">
                  <h2 className="section_card_title">Male</h2>
                </div>
                <div className="grid grid_cols_4 section_card_body">
                  <div className="grid col_span_1 flex column justify_center align_center">
                    {maleAudienceDistributionIcon}
                  </div>
                  <div className="grid col_span_3 section_card_chart_wrapper">
                    {maleAudienceDistribution}
                  </div>
                </div>
              </div>
              <div className="grid col_span_1 card_border_right flex column">
                <div className="grid col_span_4 section_card_header">
                  <h2 className="section_card_title">Female</h2>
                </div>
                <div className="grid grid_cols_4 section_card_body">
                  <div className="grid col_span_1 flex column justify_center align_center">
                    {femaleAudienceDistributionIcon}
                  </div>
                  <div className="grid col_span_3 section_card_chart_wrapper">
                    {femaleAudienceDistribution}
                  </div>
                </div>
              </div>
              <div className="grid col_span_1 flex column">
                <div className="grid col_span_4 section_card_header">
                  <h2 className="section_card_title">Unknown</h2>
                </div>
                <div className="grid grid_cols_4 section_card_body">
                  <div className="grid col_span_1 flex column justify_center align_center">
                    {unknownAudienceDistributionIocn}
                  </div>
                  <div className="grid col_span_3 section_card_chart_wrapper">
                    {unknownAudienceDistribution}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      }
    </Wrapper>
  );
};

export const CampaignDv360VideoCoke = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData === undefined || userData.length === 0 ? "" :
    userData.company === undefined || userData.company.length === 0 ? "" :
      userData.company.id === undefined || userData.company.id === null ? "" :
        userData.company.id;

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedBuckets
  );
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence";
  let pageSubModule = "Campaign";
  let pagePlatform = "Dv360";
  let pagePrimaryTab = "Video";

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // VIDEO VIEWS ANALYSIS
  const [videoViews, setVideoViews] = useState([]);
  const [isContentAnalysisLoading, setVideoViewsIsLoading] = useState(false);
  const [isContentAnalysisLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // TREND ANALYSIS
  const [trendAnalysis, setTrendAnalysis] = useState([]);
  const [trendAnalysisLabels, setTrendAnalysisLabels] = useState([]);
  const [isTrendAnalysisLoading, setTrendAnalysisIsLoading] = useState(false);
  const [isTrendAnalysisLoaded, setTrendAnalysisIsLoaded] = useState(false);
  const [trendAnalysiserror, setTrendAnalysisError] = useState(null);

  const [trendAnalysisAllData, setTrendAnalysisAllData] = useState([]);
  const [trendAnalysisAllLabels, setTrendAnalysisAllLabels] = useState([]);

  const trendAnalysisDropdownOptions = [
    { value: "overall", label: "Overall" },
    { value: "0_to_1_minutes", label: "0 to 1 minutes" },
    { value: "1_to_5_minutes", label: "1 to 5 minutes" },
    { value: "5_to_15_minutes", label: "5 to 15 minutes" },
    { value: "15_to_30_minutes", label: "15 to 30 minutes" },
    { value: "30_to_60_minutes", label: "30 to 60 minutes" },
    { value: "over_60_minutes", label: "Over 60 minutes" },
    { value: "unknown", label: "Unknown" },
  ];

  const [trendAnalysisDropdown, setTrendAnalysisDropdown] = useState(
    trendAnalysisDropdownOptions[0]
  );

  const trendAnalysisClickHandler = (e) => {
    if (e === null) {
      setTrendAnalysisDropdown("");
    } else {
      setTrendAnalysisDropdown(e);
    }
  };

  const fetchTrendAnalysisDropdownHandler = () => {
    if (trendAnalysisDropdown.value === "overall") {
      setTrendAnalysis(trendAnalysisAllData.overall);
      setTrendAnalysisLabels(trendAnalysisAllLabels.overall);
    } else if (trendAnalysisDropdown.value === "0_to_1_minutes") {
      setTrendAnalysis(trendAnalysisAllData["0_to_1_minutes"]);
      setTrendAnalysisLabels(trendAnalysisAllLabels["0_to_1_minutes"]);
    } else if (trendAnalysisDropdown.value === "1_to_5_minutes") {
      setTrendAnalysis(trendAnalysisAllData["1_to_5_minutes"]);
      setTrendAnalysisLabels(trendAnalysisAllLabels["1_to_5_minutes"]);
    } else if (trendAnalysisDropdown.value === "5_to_15_minutes") {
      setTrendAnalysis(trendAnalysisAllData["5_to_15_minutes"]);
      setTrendAnalysisLabels(trendAnalysisAllLabels["5_to_15_minutes"]);
    } else if (trendAnalysisDropdown.value === "15_to_30_minutes") {
      setTrendAnalysis(trendAnalysisAllData["15_to_30_minutes"]);
      setTrendAnalysisLabels(trendAnalysisAllLabels["15_to_30_minutes"]);
    } else if (trendAnalysisDropdown.value === "30_to_60_minutes") {
      setTrendAnalysis(trendAnalysisAllData["30_to_60_minutes"]);
      setTrendAnalysisLabels(trendAnalysisAllLabels["30_to_60_minutes"]);
    } else if (trendAnalysisDropdown.value === "over_60_minutes") {
      setTrendAnalysis(trendAnalysisAllData["Over_60_minutes"]);
      setTrendAnalysisLabels(trendAnalysisAllLabels["Over_60_minutes"]);
    } else if (trendAnalysisDropdown.value === "unknown") {
      setTrendAnalysis(trendAnalysisAllData.Unknown);
      setTrendAnalysisLabels(trendAnalysisAllLabels.Unknown);
    } else {
      setTrendAnalysis([]);
      setTrendAnalysisLabels([]);
    }
  };

  useEffect(() => {
    fetchTrendAnalysisDropdownHandler();
  }, [
    BRAND_ID,
    trendAnalysisDropdown,
    selectedDates,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  // SPENDS TREND
  const [kpiTrendAnalysisData, setKpiTrendAnalysisData] = useState([]);
  const [kpiTrendAnalysisDataLabels, setKpiTrendAnalysisDataLabels] = useState([]);
  const [kpiTrendAnalysisBenchmark, setKpiTrendAnalysisBenchmark] = useState([]);
  const [isKpiTrendAnalysisLoading, setKpiTrendAnalysisIsLoading] = useState(false);
  const [isKpiTrendAnalysisLoaded, setKpiTrendAnalysisIsLoaded] = useState(false);
  const [kpiTrendAnalysisError, setKpiTrendAnalysisError] = useState(null);

  const [kpiTrendAnalysisAllData, setKpiTrendAnalysisAllData] = useState([]);
  const [kpiTrendAnalysisAllLabels, setKpiTrendAnalysisAllLabels] = useState([]);
  const [kpiTrendAnalysisAllBenchmark, setKpiTrendAnalysisAllBenchmark] = useState([]);

  const firstKpiTrendAnalysisDropdownOptions = [
    { value: "vtr", label: "VTR" },
    { value: "cvtr", label: "CVTR" },
    { value: "cpv", label: "CPV" },
    { value: "cpcv", label: "CPCV" },

  ];

  const [firstKpiTrendAnalysisDropdown, setFirstKpiTrendAnalysisDropdown] =
    useState(firstKpiTrendAnalysisDropdownOptions[0]);

  const firstKpiTrendAnalysisClickHandler = (e) => {
    if (e === null) {
      setFirstKpiTrendAnalysisDropdown("");
    } else {
      setFirstKpiTrendAnalysisDropdown(e);
    }
  };

  const secondKpiTrendAnalysisDropdownOptions = [
    { value: "average", label: "Benchmarking 1:Average" },
    { value: "campaign_start_date", label: "Benchmarking 2:Campaign Start Date" },
  ];

  const [secondKpiTrendAnalysisDropdown, setSecondKpiTrendAnalysisDropdown] = useState(secondKpiTrendAnalysisDropdownOptions[0]);

  const secondKpiTrendAnalysisClickHandler = (e) => {
    if (e === null) {
      setSecondKpiTrendAnalysisDropdown("");
    } else {
      setSecondKpiTrendAnalysisDropdown(e);
    }
  };

  const fetchFirstKpiTrendAnalysisDropdownHandler = () => {
    let firstVal = firstKpiTrendAnalysisDropdown.value;

    setKpiTrendAnalysisData(
      kpiTrendAnalysisAllData === undefined ||
        kpiTrendAnalysisAllData.length === 0
        ? []
        : kpiTrendAnalysisAllData[firstVal] === undefined ||
          kpiTrendAnalysisAllData[firstVal].length === 0

          ? []
          : kpiTrendAnalysisAllData[firstVal]
    );

    setKpiTrendAnalysisDataLabels(

      kpiTrendAnalysisAllLabels === undefined ||
        kpiTrendAnalysisAllLabels.length === 0
        ? []
        : kpiTrendAnalysisAllLabels[firstVal] === undefined ||
          kpiTrendAnalysisAllLabels[firstVal].length === 0
          ? []
          : kpiTrendAnalysisAllLabels[firstVal]
    );
    setKpiTrendAnalysisBenchmark(

      kpiTrendAnalysisAllBenchmark === undefined || kpiTrendAnalysisAllBenchmark.length === 0 ? [] :
        kpiTrendAnalysisAllBenchmark[firstVal] === undefined || kpiTrendAnalysisAllBenchmark[firstVal].length === 0 ? [] :
          kpiTrendAnalysisAllBenchmark[firstVal][0]
    );
  };
  useEffect(() => {
    setFirstKpiTrendAnalysisDropdown(firstKpiTrendAnalysisDropdownOptions[0]);
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    secondKpiTrendAnalysisDropdown
  ]);
  useEffect(() => {
    fetchFirstKpiTrendAnalysisDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    firstKpiTrendAnalysisDropdown
  ]);


  // KPI TREND ANALYSIS HALEON
  const [haleonKpiTrendAnalysisData, setHaleonKpiTrendAnalysisData] = useState([]);
  const [haleonKpiTrendAnalysisDataLabels, setHaleonKpiTrendAnalysisDataLabels] = useState([]);
  const [haleonKpiTrendAnalysisAllData, setHaleonKpiTrendAnalysisAllData] = useState([]);
  const [haleonKpiTrendAnalysisAllLabels, setHaleonKpiTrendAnalysisAllLabels] = useState([]);
  const [isHaleonKpiTrendAnalysisLoading, setHaleonKpiTrendAnalysisIsLoading] = useState(false);
  const [isHaleonKpiTrendAnalysisLoaded, setHaleonKpiTrendAnalysisIsLoaded] = useState(false);
  const [haleonKpiTrendAnalysisError, setHaleonKpiTrendAnalysisError] = useState(null);

  var haleonKpiTrendAnalysisOptions = [
    { value: "vtr", label: "VTR" },
    { value: "cvtr", label: "CVTR" },
    { value: "cpv", label: "CPV" },
    { value: "cpcv", label: "CPCV" },
  ]

  const [haleonKpiTrendAnalysisDropdown, setHaleonKpiTrendAnalysisDropdown] = useState(haleonKpiTrendAnalysisOptions[0])

  const haleonKpiTrendAnalysisClickHandler = (e) => {
    if (e === null) {
      setHaleonKpiTrendAnalysisDropdown("");
    } else {
      setHaleonKpiTrendAnalysisDropdown(e);
    }
  };

  const haleonKPIdropdownHandler = () => {
    let firstVal = haleonKpiTrendAnalysisDropdown.value;

    setHaleonKpiTrendAnalysisData(
      haleonKpiTrendAnalysisAllData === undefined || haleonKpiTrendAnalysisAllData.length === 0 ? [] :
        haleonKpiTrendAnalysisAllData[firstVal] === undefined || haleonKpiTrendAnalysisAllData[firstVal].length === 0 ? [] :
          haleonKpiTrendAnalysisAllData[firstVal]
    );

    setHaleonKpiTrendAnalysisDataLabels(
      haleonKpiTrendAnalysisAllLabels === undefined || haleonKpiTrendAnalysisAllLabels.length === 0 ? [] :
        haleonKpiTrendAnalysisAllLabels[firstVal] === undefined || haleonKpiTrendAnalysisAllLabels[firstVal].length === 0 ? [] :
          haleonKpiTrendAnalysisAllLabels[firstVal]
    );

  }


  useEffect(() => {
    haleonKPIdropdownHandler();
  }, [haleonKpiTrendAnalysisDropdown])



  // CONTENT POSITION WISE DISTRIBUTION
  const [contentPositionDistribution, setContentPositionDistribution] =
    useState([]);
  const [
    contentPositionDistributionAllDropdownsData,
    setContentPositionDistributionAllDropdownsData,
  ] = useState([]);
  const [
    isContentPositionDistributionLoading,
    setContentPositionDistributionIsLoading,
  ] = useState(false);
  const [
    isContentPositionDistributionLoaded,
    setContentPositionDistributionIsLoaded,
  ] = useState(false);
  const [
    contentPositionDistributionerror,
    setContentPositionDistributionError,
  ] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const contentPositionDistributionDropdownOptions = [
    { value: "complete_views", label: "Complete Views" },
    { value: "views", label: "Views" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedContentPositionDistributionDropdownOption,
    setSelectedContentPositionDistributionDropdownOption,
  ] = useState(contentPositionDistributionDropdownOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const contentPositionDistributionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedContentPositionDistributionDropdownOption("");
    } else {
      setSelectedContentPositionDistributionDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Facebook",
      "Overall",
      null,
      "Performance By KPI",
      e.label
    );
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchContentPositionDistributionDropdownHandler = async () => {
    if (
      selectedContentPositionDistributionDropdownOption.value ===
      "complete_views"
    ) {
      setContentPositionDistribution(
        contentPositionDistributionAllDropdownsData.length === 0
          ? []
          : contentPositionDistributionAllDropdownsData.complete_views
      );
    } else if (
      selectedContentPositionDistributionDropdownOption.value === "views"
    ) {
      setContentPositionDistribution(
        contentPositionDistributionAllDropdownsData.length === 0
          ? []
          : contentPositionDistributionAllDropdownsData.views
      );
    } else {
      setContentPositionDistribution([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchContentPositionDistributionDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    selectedContentPositionDistributionDropdownOption,
  ]);

  // APP URL DISTRIBUTION
  const [appUrlDistribution, setAppUrlDistribution] = useState([]);
  const [appUrlDistributionLabels, setAppUrlDistributionLabels] = useState([]);
  const [isAppUrlDistributionLoading, setAppUrlDistributionIsLoading] =
    useState(false);
  const [isAppUrlDistributionLoaded, setAppUrlDistributionIsLoaded] =
    useState(false);
  const [appUrlDistributionerror, setAppUrlDistributionError] = useState(null);

  const [appUrlDistributionAllData, setAppUrlDistributionAllData] = useState(
    []
  );
  const [appUrlDistributionAllLabels, setAppUrlDistributionAllLabels] =
    useState([]);

  const appUrlDistributionDropdownOptions = [
    { value: "complete_views", label: "Complete Views" },
    { value: "views", label: "Views" },
  ];

  const [appUrlDistributionDropdown, setAppUrlDistributionDropdown] = useState(
    appUrlDistributionDropdownOptions[0]
  );

  const appUrlDistributionClickHandler = (e) => {
    if (e === null) {
      setAppUrlDistributionDropdown("");
    } else {
      setAppUrlDistributionDropdown(e);
    }
  };

  const fetchAppUrlDistributionDropdownHandler = () => {
    if (appUrlDistributionDropdown.value === "complete_views") {
      setAppUrlDistribution(appUrlDistributionAllData.complete_views);
      setAppUrlDistributionLabels(appUrlDistributionAllLabels.complete_views);
    } else if (appUrlDistributionDropdown.value === "views") {
      setAppUrlDistribution(appUrlDistributionAllData.views);
      setAppUrlDistributionLabels(appUrlDistributionAllLabels.views);
    } else {
      setAppUrlDistribution([]);
      setAppUrlDistributionLabels([]);
    }
  };

  useEffect(() => {
    fetchAppUrlDistributionDropdownHandler();
  }, [

    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    appUrlDistributionDropdown

  ]);

  //  STATE DATA
  const [stateData, setStateData] = useState([]);
  const [isStateDataLoading, setStateDataIsLoading] = useState(false);
  const [isStateDataLoaded, setStateDataIsLoaded] = useState(false);
  const [stateDataerror, setStateDataError] = useState(null);

  let stateTableDataHeaders = []

  let defaultStateTableDataHeaders = [
    { name: "State", field: "state" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  let haleonStateTableDataHeaders = [
    { name: "State", field: "state" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "First-Quartile Views", field: "first_quartile_views" },
    { name: "Midpoint Views", field: "mid_quartile_views" },
    { name: "Third-Quartile Views", field: "third_quartile_views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    stateTableDataHeaders = haleonStateTableDataHeaders
  } else {
    stateTableDataHeaders = defaultStateTableDataHeaders
  }

  // CITY DATA
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  let cityTableDataHeaders = [];

  let defaultCityTableDataHeaders = [
    { name: "City", field: "city" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  const haleonCityTableDataHeaders = [
    { name: "City", field: "city" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "First-Quartile Views", field: "first_quartile_views" },
    { name: "Midpoint Views", field: "mid_quartile_views" },
    { name: "Third-Quartile Views", field: "third_quartile_views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    cityTableDataHeaders = haleonCityTableDataHeaders
  } else {
    cityTableDataHeaders = defaultCityTableDataHeaders
  }

  // COUNTRY DATA
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  let countryTableDataHeaders = []

  let defaultCountryTableDataHeaders = [
    { name: "Country", field: "country" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  let haleonCountryTableDataHeaders = [
    { name: "Country", field: "country" },
    { name: "Spends", field: "spends" },
    { name: "Complete Views", field: "complete_views" },
    { name: "Views", field: "views" },
    { name: "First-Quartile Views", field: "first_quartile_views" },
    { name: "Midpoint Views", field: "mid_quartile_views" },
    { name: "Third-Quartile Views", field: "third_quartile_views" },
    { name: "VTR", field: "vtr" },
    { name: "CVTR", field: "cvtr" },
    { name: "CPV", field: "cpv" },
    { name: "CPCV", field: "cpcv" },
    { name: "Impression", field: "impression" },
  ];

  if (companyID === HALEON_COMPANY_ID) {
    countryTableDataHeaders = haleonCountryTableDataHeaders
  } else {
    countryTableDataHeaders = defaultCountryTableDataHeaders
  }

  // BRANDWISE PERFORMANCE
  const [deviceWiseDistribution, setDeviceWiseDistribution] = useState([]);
  const [isDeviceWiseDistributionLoading, setDeviceWiseDistributionIsLoading] =
    useState(false);
  const [isDeviceWiseDistributionLoaded, setDeviceWiseDistributionIsLoaded] =
    useState(false);
  const [deviceWiseDistributionerror, setDeviceWiseDistributionError] =
    useState(null);

  // AD TYPE PERFORMANCE
  const [adTypePerformance, setAdTypePerformance] = useState([]);
  const [adTypePerformanceLabels, setAdTypePerformanceLabels] = useState([]);
  const [isAdTypePerformanceLoading, setAdTypePerformanceIsLoading] =
    useState(false);
  const [isAdTypePerformanceLoaded, setAdTypePerformanceIsLoaded] =
    useState(false);
  const [adTypePerformanceerror, setAdTypePerformanceError] = useState(null);

  const [adTypePerformanceAllData, setAdTypePerformanceAllData] = useState([]);
  const [adTypePerformanceAllLabels, setAdTypePerformanceAllLabels] = useState(
    []
  );

  const adTypePerformanceDropdownOptions = [
    { value: "complete_views", label: "Complete Views" },
    { value: "views", label: "Views" },
    { value: "first_quartile", label: "First-Quartile Views (Video)" },
    { value: "mid_point", label: "Mid-Quartile Views (Video)" },
    { value: "third_quartile", label: "Third-Quartile Views (Video)" },
  ];

  const [adTypePerformanceDropdown, setAdTypePerformanceDropdown] = useState(
    adTypePerformanceDropdownOptions[0]
  );

  const adTypePerformanceClickHandler = (e) => {
    if (e === null) {
      setAdTypePerformanceDropdown("");
    } else {
      setAdTypePerformanceDropdown(e);
    }
  };

  const fetchAdTypePerformanceDropdownHandler = () => {
    if (adTypePerformanceDropdown.value === "complete_views") {
      setAdTypePerformance(adTypePerformanceAllData.complete_views);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels.complete_views);
    } else if (adTypePerformanceDropdown.value === "views") {
      setAdTypePerformance(adTypePerformanceAllData.views);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels.views);
    } else if (adTypePerformanceDropdown.value === "first_quartile") {
      setAdTypePerformance(adTypePerformanceAllData.first_quartile);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels.first_quartile);
    } else if (adTypePerformanceDropdown.value === "mid_point") {
      setAdTypePerformance(adTypePerformanceAllData.mid_point);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels.mid_point);
    } else if (adTypePerformanceDropdown.value === "third_quartile") {
      setAdTypePerformance(adTypePerformanceAllData.third_quartile);
      setAdTypePerformanceLabels(adTypePerformanceAllLabels.third_quartile);
    } else {
      setAdTypePerformance([]);
      setAdTypePerformanceLabels([]);
    }
  };

  useEffect(() => {
    fetchAdTypePerformanceDropdownHandler();
  }, [

    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    adTypePerformanceDropdown

  ]);

  // VTR TREND ANALYSIS
  const [vtrTrendAnalysis, setVtrTrendAnalysis] = useState([]);
  const [vtrTrendAnalysisLabels, setVtrTrendAnalysisLabels] = useState([]);
  const [isVtrTrendAnalysisLoading, setVtrTrendAnalysisIsLoading] =
    useState(false);
  const [isVtrTrendAnalysisLoaded, setVtrTrendAnalysisIsLoaded] =
    useState(false);
  const [vtrTrendAnalysiserror, setVtrTrendAnalysisError] = useState(null);

  const [vtrTrendAnalysisAllData, setVtrTrendAnalysisAllData] = useState([]);
  const [vtrTrendAnalysisAllLabels, setVtrTrendAnalysisAllLabels] = useState(
    []
  );

  const vtrTrendAnalysisDropdownOptions = [
    { value: "skippable", label: "Skippable" },
    { value: "bumper_ad", label: "Bumper ad" },
    {
      value: "non_skippable",
      label: "Non-skippable",
    },
    { value: "masthead_ad", label: "Masthead ad" },
  ];

  const [vtrTrendAnalysisDropdown, setVtrTrendAnalysisDropdown] = useState(
    vtrTrendAnalysisDropdownOptions[0]
  );

  const vtrTrendAnalysisClickHandler = (e) => {
    if (e === null) {
      setVtrTrendAnalysisDropdown("");
    } else {
      setVtrTrendAnalysisDropdown(e);
    }
  };

  const fetchVtrTrendAnalysisDropdownHandler = () => {
    if (vtrTrendAnalysisDropdown.value === "skippable") {
      setVtrTrendAnalysis(vtrTrendAnalysisAllData.Skippable);
      setVtrTrendAnalysisLabels(vtrTrendAnalysisAllLabels.Skippable);
    } else if (vtrTrendAnalysisDropdown.value === "bumper_ad") {
      setVtrTrendAnalysis(vtrTrendAnalysisAllData.Bumper_ad);
      setVtrTrendAnalysisLabels(vtrTrendAnalysisAllLabels.Bumper_ad);
    } else if (vtrTrendAnalysisDropdown.value === "non_skippable") {
      setVtrTrendAnalysis(vtrTrendAnalysisAllData["Non-skippable"]);
      setVtrTrendAnalysisLabels(vtrTrendAnalysisAllLabels["Non-skippable"]);
    } else if (vtrTrendAnalysisDropdown.value === "masthead_ad") {
      setVtrTrendAnalysis(vtrTrendAnalysisAllData.Masthead_ad);
      setVtrTrendAnalysisLabels(vtrTrendAnalysisAllLabels.Masthead_ad);
    } else {
      setVtrTrendAnalysis([]);
      setVtrTrendAnalysisLabels([]);
    }
  };

  useEffect(() => {
    fetchVtrTrendAnalysisDropdownHandler();
  }, [

    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    vtrTrendAnalysisDropdown,

  ]);

  // YOUTUBE AD TYPE
  const [youtubeAdType, setYoutubeAdType] = useState([]);
  const [youtubeAdTypeLabels, setYoutubeAdTypeLabels] = useState([]);
  const [isYoutubeAdTypeLoading, setYoutubeAdTypeIsLoading] = useState(false);
  const [isYoutubeAdTypeLoaded, setYoutubeAdTypeIsLoaded] = useState(false);
  const [youtubeAdTypeerror, setYoutubeAdTypeError] = useState(null);

  const [youtubeAdTypeAllData, setYoutubeAdTypeAllData] = useState([]);
  const [youtubeAdTypeAllLabels, setYoutubeAdTypeAllLabels] = useState([]);

  const firstYoutubeAdTypeDropdownOptions = [
    { value: "Skippable", label: "Skippable" },
    { value: "Bumper ad", label: "Bumper Ad" },
    { value: "Non-skippable", label: "Non-Skippable" },
    { value: "Masthead ad", label: "Masthead Ad" },
  ];

  const [firstYoutubeAdTypeDropdown, setFirstYoutubeAdTypeDropdown] = useState(
    firstYoutubeAdTypeDropdownOptions[0]
  );

  const firstYoutubeAdTypeClickHandler = (e) => {
    if (e === null) {
      setFirstYoutubeAdTypeDropdown("");
    } else {
      setFirstYoutubeAdTypeDropdown(e);
    }
  };

  const secondYoutubeAdTypeDropdownOptions = [
    { value: "vtr", label: "VTR" },
    { value: "cpm", label: "CPM" },
    { value: "cvtr", label: "CVTR" },
    { value: "cpv", label: "CPV" },
    { value: "cpcv", label: "CPCV" },
  ];

  const [secondYoutubeAdTypeDropdown, setSecondYoutubeAdTypeDropdown] =
    useState(secondYoutubeAdTypeDropdownOptions[0]);

  const secondYoutubeAdTypeClickHandler = (e) => {
    if (e === null) {
      setSecondYoutubeAdTypeDropdown("");
    } else {
      setSecondYoutubeAdTypeDropdown(e);
    }
  };

  const fetchFirstYoutubeAdTypeDropdownHandler = () => {
    let secondVal = secondYoutubeAdTypeDropdown.value;

    setYoutubeAdType(
      youtubeAdTypeAllData === undefined || youtubeAdTypeAllData.length === 0 ? [] :
        youtubeAdTypeAllData[secondVal] === undefined || youtubeAdTypeAllData[secondVal].length === 0 ? [] :
          youtubeAdTypeAllData[secondVal]
    );

    setYoutubeAdTypeLabels(
      youtubeAdTypeAllLabels === undefined || youtubeAdTypeAllLabels.length === 0 ? [] :
        youtubeAdTypeAllLabels[secondVal] === undefined || youtubeAdTypeAllLabels[secondVal].length === 0 ? [] :
          youtubeAdTypeAllLabels[secondVal]
    );
  };
  useEffect(() => {
    setSecondYoutubeAdTypeDropdown(secondYoutubeAdTypeDropdownOptions[0]);
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    firstYoutubeAdTypeDropdown]);

  useEffect(() => {
    fetchFirstYoutubeAdTypeDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    secondYoutubeAdTypeDropdown]);
  // AD TYPE DISTRIBUTION
  const [adTypeDistribution, setAdTypeDistribution] = useState([]);
  const [
    adTypeDistributionAllDropdownsData,
    setAdTypeDistributionAllDropdownsData,
  ] = useState([]);
  const [isAdTypeDistributionLoading, setAdTypeDistributionIsLoading] =
    useState(false);
  const [isAdTypeDistributionLoaded, setAdTypeDistributionIsLoaded] =
    useState(false);
  const [adTypeDistributionerror, setAdTypeDistributionError] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const adTypeDistributionDropdownOptions = [
    { value: "complete_views", label: "Complete Views" },
    { value: "views", label: "Views" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedAdTypeDistributionDropdownOption,
    setSelectedAdTypeDistributionDropdownOption,
  ] = useState(adTypeDistributionDropdownOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const adTypeDistributionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedAdTypeDistributionDropdownOption("");
    } else {
      setSelectedAdTypeDistributionDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Marketing Intelligence",
      "Campaigns",
      "Facebook",
      "Overall",
      null,
      "Performance By KPI",
      e.label
    );
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchAdTypeDistributionDropdownHandler = async () => {
    if (selectedAdTypeDistributionDropdownOption.value === "complete_views") {
      setAdTypeDistribution(
        adTypeDistributionAllDropdownsData.length === 0
          ? []
          : adTypeDistributionAllDropdownsData.complete_views
      );
    } else if (selectedAdTypeDistributionDropdownOption.value === "views") {
      setAdTypeDistribution(
        adTypeDistributionAllDropdownsData.length === 0
          ? []
          : adTypeDistributionAllDropdownsData.views
      );
    } else {
      setAdTypeDistribution([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchAdTypeDistributionDropdownHandler();
  }, [

    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    selectedAdTypeDistributionDropdownOption,

  ]);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [femaleCountAllDropdownsDataLabels, setFemaleCountAllDropdownsDataLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [totalFemaleCountAllDropdownsData, setTotalFemaleCountAllDropdownsData] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [unknownCount, setUnknownCount] = useState([]);
  const [unknownCountAllDropdownsData, setUnknownCountAllDropdownsData] = useState([]);
  const [unknownCountLabels, setUnknownCountLabels] = useState([]);
  const [unknownCountAllDropdownsDataLabels, setUnknownCountAllDropdownsDataLabels] = useState([]);
  const [totalUnknownCount, setTotalUnknownCount] = useState([]);
  const [totalUnknownCountAllDropdownsData, setTotalUnknownCountAllDropdownsData] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  const audienceDistributionOptions = [
    { value: "trueview_views", label: "Views" },
    { value: "complete_views", label: "Complete Views" },
  ];

  // DROPDOWN STATE
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(
    audienceDistributionOptions[0]
  );

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }

    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Facebook", "Audience", null, "Audience Distribution: Male / Female / Unknown", e.label)
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const audienceDistributionMaleFemaleDropdownHandler = async () => {
    if (selectedDropdownOption.value === "trueview_views") {
      setMaleCount(
        maleCountAllDropdownsData === undefined || maleCountAllDropdownsData.length === 0 ? [] :
          maleCountAllDropdownsData.trueview_views === undefined || maleCountAllDropdownsData.trueview_views.length === 0 ? [] :
            maleCountAllDropdownsData.trueview_views
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels === undefined || maleCountAllDropdownsDataLabels.length === 0 ? [] :
          maleCountAllDropdownsDataLabels.trueview_views === undefined || maleCountAllDropdownsDataLabels.trueview_views.length === 0 ? [] :
            maleCountAllDropdownsDataLabels.trueview_views
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData === undefined || totalMaleCountAllDropdownsData.length === 0 ? [] :
          totalMaleCountAllDropdownsData.trueview_views === undefined || totalMaleCountAllDropdownsData.trueview_views.length === 0 ? [] :
            totalMaleCountAllDropdownsData.trueview_views
      );
      setFemaleCount(
        femaleCountAllDropdownsData === undefined || femaleCountAllDropdownsData.length === 0 ? [] :
          femaleCountAllDropdownsData.trueview_views === undefined || femaleCountAllDropdownsData.trueview_views.length === 0 ? [] :
            femaleCountAllDropdownsData.trueview_views
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels === undefined || femaleCountAllDropdownsDataLabels.length === 0 ? [] :
          femaleCountAllDropdownsDataLabels.trueview_views === undefined || femaleCountAllDropdownsDataLabels.trueview_views.length === 0 ? [] :
            femaleCountAllDropdownsDataLabels.trueview_views
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData === undefined || totalFemaleCountAllDropdownsData.length === 0 ? [] :
          totalFemaleCountAllDropdownsData.trueview_views === undefined || totalFemaleCountAllDropdownsData.trueview_views.length === 0 ? [] :
            totalFemaleCountAllDropdownsData.trueview_views
      );

      setUnknownCount(
        unknownCountAllDropdownsData === undefined || unknownCountAllDropdownsData.length === 0 ? [] :
          unknownCountAllDropdownsData.trueview_views === undefined || unknownCountAllDropdownsData.trueview_views.length === 0 ? [] :
            unknownCountAllDropdownsData.trueview_views
      );
      setUnknownCountLabels(
        unknownCountAllDropdownsDataLabels === undefined ||
          unknownCountAllDropdownsDataLabels.length === 0
          ? []
          : unknownCountAllDropdownsDataLabels.trueview_views === undefined ||
            unknownCountAllDropdownsDataLabels.trueview_views.length === 0
            ? []
            : unknownCountAllDropdownsDataLabels.trueview_views
      );
      setTotalUnknownCount(
        totalUnknownCountAllDropdownsData === undefined ||
          totalUnknownCountAllDropdownsData.length === 0
          ? []
          : totalUnknownCountAllDropdownsData.trueview_views === undefined ||
            totalUnknownCountAllDropdownsData.trueview_views.length === 0
            ? []
            : totalUnknownCountAllDropdownsData.trueview_views
      );
    } else if (selectedDropdownOption.value === "complete_views") {
      setMaleCount(
        maleCountAllDropdownsData === undefined ||
          maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.complete_views === undefined ||
            maleCountAllDropdownsData.complete_views.length === 0
            ? []
            : maleCountAllDropdownsData.complete_views
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels === undefined ||
          maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.complete_views === undefined ||
            maleCountAllDropdownsDataLabels.complete_views.length === 0
            ? []
            : maleCountAllDropdownsDataLabels.complete_views
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData === undefined ||
          totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.complete_views === undefined ||
            totalMaleCountAllDropdownsData.complete_views.length === 0
            ? []
            : totalMaleCountAllDropdownsData.complete_views
      );
      setFemaleCount(
        femaleCountAllDropdownsData === undefined ||
          femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.complete_views === undefined ||
            femaleCountAllDropdownsData.complete_views.length === 0
            ? []
            : femaleCountAllDropdownsData.complete_views
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels === undefined ||
          femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.complete_views === undefined ||
            femaleCountAllDropdownsDataLabels.complete_views.length === 0
            ? []
            : femaleCountAllDropdownsDataLabels.complete_views
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData === undefined ||
          totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.complete_views === undefined ||
            totalFemaleCountAllDropdownsData.complete_views.length === 0
            ? []
            : totalFemaleCountAllDropdownsData.complete_views
      );

      setUnknownCount(
        unknownCountAllDropdownsData === undefined ||
          unknownCountAllDropdownsData.length === 0
          ? []
          : unknownCountAllDropdownsData.complete_views === undefined ||
            unknownCountAllDropdownsData.complete_views.length === 0
            ? []
            : unknownCountAllDropdownsData.complete_views
      );

      setUnknownCountLabels(
        unknownCountAllDropdownsDataLabels === undefined ||
          unknownCountAllDropdownsDataLabels.length === 0
          ? []
          : unknownCountAllDropdownsDataLabels.complete_views === undefined ||
            unknownCountAllDropdownsDataLabels.complete_views.length === 0
            ? []
            : unknownCountAllDropdownsDataLabels.complete_views
      );

      setTotalUnknownCount(
        totalUnknownCountAllDropdownsData === undefined ||
          totalUnknownCountAllDropdownsData.length === 0
          ? []
          : totalUnknownCountAllDropdownsData.complete_views === undefined ||
            totalUnknownCountAllDropdownsData.complete_views.length === 0
            ? []
            : totalUnknownCountAllDropdownsData.complete_views
      );
    } else {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
      setUnknownCount([]);
      setUnknownCountLabels([]);
      setTotalUnknownCount([]);
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    audienceDistributionMaleFemaleDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedDropdownOption]);



  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchVideoViewsHandler();
    fetchTrendAnalysisHandler();
    fetchContentPositionDistributionHandler();
    fetchStateHandler()
    fetchCityHandler();
    companyID === HALEON_COMPANY_ID && fetchCountryHandler()
    fetchAppUrlDistributionHandler();
    fetchDeviceWiseDistributionHandler();
    fetchAdTypeDistributionHandler();
    fetchAdTypePerformanceHandler();
    fetchVtrTrendAnalysisHandler();
    companyID === HALEON_COMPANY_ID && fetchAudienceDistributionHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter
  ]);

  useEffect(() => {
    fetchKpiTrendAnalysisHandler();
    companyID === HALEON_COMPANY_ID && fetchHaleonKpiTrendAnalysisHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    secondKpiTrendAnalysisDropdown,
  ]);

  useEffect(() => {
    fetchYoutubeAdTypeHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
    selectedCampaigns,
    selectedBucketsFromFilter,
    firstYoutubeAdTypeDropdown]);




  // PLANNED VS DELIVERED TABLE

  /*   const plannedVsDeliveredTableHeaders = [
      { name: "Campaign Name", field: "campaign_names" },
      { name: "Start date", field: "start_date" },
      { name: "End date", field: "end_date" },
  
      { name: "Market/TG", field: "type" },
      { name: "Markets(State)", field: "region" }, 
  
      { name: "Planned Reach", field: "planned_reach" },
      { name: "Delivered Reach", field: "delivered_reach" },
      { name: "% achieved", field: "achieved_reach" },
  
      { name: "Planned Impressions", field: "planned_impressions" },
      { name: "Delivered Impressions", field: "delivered_impressions" },
      { name: "% achieved", field: "achieved_impressions" },
  
      { name: "Planned Frequency", field: "planned_frequency" },
      { name: "Delivered Frequency", field: "delivered_frequency" },
      { name: "% achieved", field: "achieved_frequency" },
  
      { name: "Planned CTR %", field: "planned_ctr" },
      { name: "Delivered CTR %", field: "delivered_ctr" },
      { name: "% achieved", field: "achieved_ctr" },
  
      { name: "Planned VTR %", field: "planned_vtr" },
      { name: "Delivered VTR %", field: "delivered_vtr" },
      { name: "% achieved", field: "achieved_vtr" },
  
      { name: "Planned Spends", field: "planned_spends" },
      { name: "Delivered Spends", field: "delivered_vtr" },
      { name: "% achieved", field: "achieved_spend" },
    ];
   */




  // PLANNED VS DELIVERED METRICS DATA
  const [plannedVsDeliveredMetricsData, setPlannedVsDeliveredMetricsData] =
    useState([]);
  const [
    plannedVsDeliveredMetricsDataLabels,
    setPlannedVsDeliveredMetricsDataLabels,
  ] = useState([]);

  const [
    isplannedVsDeliveredMetricsLoading,
    setPlannedVsDeliveredMetricsIsLoading,
  ] = useState(false);
  const [
    isplannedVsDeliveredMetricsLoaded,
    setPlannedVsDeliveredMetricsIsLoaded,
  ] = useState(false);
  const [plannedVsDeliveredMetricsError, setPlannedVsDeliveredMetricsError] =
    useState(null);

  const [plannedVsDeliveredTableData, setPlannedVsDeliveredTableData] = useState([]);
  const [plannedVsDeliveredTableDataTotal, setPlannedVsDeliveredTableDataTotal] = useState([]);
  const [plannedVsDeliveredTableDataHeaders, setPlannedVsDeliveredTableDataHaeders] = useState([]);
  const [isPlannedVsDeliveredTableDataLoading, setPlannedVsDeliveredTableDataIsLoading] = useState(false);
  const [isPlannedVsDeliveredTableDataLoaded, setPlannedVsDeliveredTableDataIsLoaded] = useState(false);
  const [plannedVsDeliveredTableDataerror, setPlannedVsDeliveredTableDataError] = useState(null);

  let dropdownOptionsMarketTg = [
    { value: "market", label: "Market" },
    { value: "tg", label: "TG" }
  ];

  let tgDropdownOptions = [
    { value: "18-34 male female", label: "18-34 Male / Female" },
    // { value: "18-64 male female", label: "18-64 Male / Female" },
  ]

  // DROPDOWN STATE
  const [selectedMarketTgDropdownOption, setSelectedMarketTgDropdownOption] =
    useState(dropdownOptionsMarketTg[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMainSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMarketTgDropdownOption("");
    } else {
      setSelectedMarketTgDropdownOption(e);
    }
  };


  const [regionDropdownOptionsOnLoad, setRegionDropdownOptionsOnLoad] =
    useState([]);
  const [regionDropdownOptions, setRegionDropdownOptions] = useState([]);
  const [regionDropdownOptionsIsLoading, setRegionDropdownOptionsIsLoading] =
    useState(true);
  const [regionDropdownOptionsIsLoaded, setRegionDropdownOptionsIsLoaded] =
    useState(false);




  const fetchRegionDropdownHandler = async () => {
    setRegionDropdownOptionsIsLoading(true);
    setRegionDropdownOptionsIsLoaded(false);

    let getDropdownType = selectedMarketTgDropdownOption.value === 'tg' ? 'tg' : 'market';
    let getIsTg = selectedMarketTgDropdownOption.value === 'tg' ? true : false;

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        drop_down_type: getDropdownType,
        is_tg: getIsTg
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-dropdown/",
        config
      );

      const regionsDDOpt = res?.data.map((item) => ({
        label: item.planned_label,
        value: item.mapping_id
      }))

      setRegionDropdownOptions(regionsDDOpt ?? []);
      setRegionDropdownOptionsOnLoad(regionsDDOpt ?? []);
      setSelectedRegionDropdownOption(regionsDDOpt[0] ?? []);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(true);

    } catch (error) {
      setRegionDropdownOptions([]);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(false);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);

      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  useEffect(() => {
    fetchRegionDropdownHandler();
  }, [
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform,
    selectedMarketTgDropdownOption]);

  // DROPDOWN STATE
  const [selectedRegionDropdownOption, setSelectedRegionDropdownOption] =
    useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownRegionSelectionHandler = (e) => {
    if (e === null) {
      setSelectedRegionDropdownOption("");
    } else {
      setSelectedRegionDropdownOption(e);
    }

    IAEvent_Dropdown_Visit(
      pageModule,
      pageSubModule,
      pagePlatform,
      pagePrimaryTab,
      null,
      "PlannedVsDelivered",
      e.label
    );
  };

  // DROPDOWN STATE

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  // const fetchMarketTgDropdownHandler = () => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   } else {
  //     setRegionDropdownOptionsOnLoad([]);
  //   }
  // };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  // useEffect(() => {
  //   fetchMarketTgDropdownHandler();
  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform,
  //   selectedMarketTgDropdownOption
  // ]);

  // useEffect(() => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   }
  // }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOption]);

  useEffect(() => {
    if (!regionDropdownOptionsIsLoading && regionDropdownOptionsIsLoaded) {
      fetchPlannedVsDeliveredMetricsHandler();
      fetchPlannedVsDeliveredTableDataHandler();
    }
  }, [
    regionDropdownOptionsIsLoaded,
    regionDropdownOptionsIsLoading,
    selectedRegionDropdownOption
  ]);

  // useEffect(() => {
  //   setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOption(regionDropdownOptions[0]);

  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform
  // ]);


  // useEffect(() => {
  //   setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   if (selectedMarketTgDropdownOption !== undefined) {
  //     if ((selectedMarketTgDropdownOption.value === "market") && selectedRegionDropdownOption.value === "India") {
  //       fetchPlannedVsDeliveredMetricsHandler();
  //       fetchPlannedVsDeliveredTableDataHandler();
  //     }
  //   }

  // }, [selectedBucketsFromFilter,
  //   selectedObjectives,
  //   selectedIdandPlatform,
  //   selectedCampaigns,
  //   selectedPlatform]);






  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/metric-card/",
        config
      );
      setMetricCards(res?.data?.metric_card ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <ErrorBoundary>
          <Slider sliderTitle={"Summary"}>
            {metricCards.map((data, index) => (
              <SwiperSlide key={index}>
                <Card
                  module="campaign"
                  platform="dv360"
                  section="video"
                  cardTitle={data.label}
                  currentValue={data.currentValue}
                  previousValue={data.previousValue}
                  rowOneTitle={"Previous"}
                  numberVariant={"number"}
                />
              </SwiperSlide>
            ))}
          </Slider>
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader error={metricCardserror} />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS

  // TO FETCH DATA FROM API
  const fetchVideoViewsHandler = async () => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/video-funnel/",
        config
      );
      setVideoViews(res?.data ?? []);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = (
    <Wrapper>
      <Loader loaderType="funnelChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      videoViews.every((val, i, arr) => val.value === arr[0].value) === true
    ) {
      videoViewsChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="funnelChartType" />
        </Wrapper>
      );
    } else {
      videoViewsChart = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={videoViews}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={videoViewserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    videoViewsChart = (
      <Wrapper>
        <Loader loaderType="funnelChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TREND ANALYSIS

  const [isTrendAnalysisOpen, setIsTrendAnalysisOpen] = useState(false)

  let defaultTrendAnalysisDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultTrendAnalysisDisabledvalues = []
  } else {
    defaultTrendAnalysisDisabledvalues = ["Mid Point", "Third Quartile"]
  }

  const [trendAnalysisDisabledvalues, setTrendAnalysisDisabledvalues] = useState(defaultTrendAnalysisDisabledvalues)

  const fetchTrendAnalysisHandler = async () => {
    setTrendAnalysisIsLoading(true);
    setTrendAnalysisIsLoaded(false);
    setTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/trend-analysis/",
        config
      );

      setTrendAnalysis(res?.data?.data["overall"] ?? []);
      setTrendAnalysisLabels(res?.data?.label["overall"] ?? []);
      setTrendAnalysisAllData(res?.data?.data ?? []);
      setTrendAnalysisAllLabels(res?.data?.label ?? []);

      setTrendAnalysisIsLoading(false);
      setTrendAnalysisIsLoaded(true);
      setTrendAnalysisError(null);
    } catch (error) {
      setTrendAnalysisAllLabels([]);
      setTrendAnalysisAllData([]);
      setTrendAnalysis([]);
      setTrendAnalysisLabels([]);
      setTrendAnalysisIsLoading(false);
      setTrendAnalysisIsLoaded(false);
      setTrendAnalysisError(error.code);
    }
  };

  let trendAnalysisChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );


  let trendAnalysisChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isTrendAnalysisLoaded && !isTrendAnalysisLoading) {
    if (NoDataAvailableChecker(trendAnalysis) || NoDataAvailableChecker(trendAnalysisLabels)) {
      trendAnalysisChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      trendAnalysisChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {


      if (isMultiLineCluster) {

        trendAnalysisChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="social_twitter_profile_clicks"
                chartClass="section_card_chart"
                chartData={trendAnalysis}
                chartLabels={trendAnalysisLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={trendAnalysisDisabledvalues}
                setDisabledValues={setTrendAnalysisDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        trendAnalysisChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={trendAnalysis}
                chartId="trendAnalysisChart_2"
                chartLabels={trendAnalysisLabels}
                showLegend={true}
                disabledLegendArray={trendAnalysisDisabledvalues}
                setDisabledValues={setTrendAnalysisDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        )

      } else {
        trendAnalysisChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="social_twitter_profile_clicks"
                chartClass="section_card_chart"
                chartData={trendAnalysis}
                chartLabels={trendAnalysisLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trendAnalysiserror) {
    trendAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={trendAnalysiserror}
        />
      </Wrapper>
    );

    trendAnalysisChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={trendAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTrendAnalysisLoading) {
    trendAnalysisChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    trendAnalysisChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // KPI TREND ANALYSIS

  // TO FETCH METRIC CARDS FROM API
  const fetchKpiTrendAnalysisHandler = async () => {
    setKpiTrendAnalysisIsLoading(true);
    setKpiTrendAnalysisIsLoaded(false);
    setKpiTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        benchmark: secondKpiTrendAnalysisDropdown.value,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(

        "performance/dv360/video/kpi-trend-analysis/",
        config
      );
      setKpiTrendAnalysisData(res?.data?.data?.vtr ?? []);
      setKpiTrendAnalysisDataLabels(res?.data?.label?.vtr ?? []);
      setKpiTrendAnalysisBenchmark(res?.data?.benchmark?.vtr[0] ?? []);
      setKpiTrendAnalysisAllData(res?.data?.data ?? []);
      setKpiTrendAnalysisAllLabels(res?.data?.label ?? []);
      setKpiTrendAnalysisAllBenchmark(res?.data?.benchmark ?? []);

      setKpiTrendAnalysisIsLoading(false);
      setKpiTrendAnalysisIsLoaded(true);
      setKpiTrendAnalysisError(null);
    } catch (error) {
      setKpiTrendAnalysisData([]);
      setKpiTrendAnalysisDataLabels([]);
      setKpiTrendAnalysisBenchmark([]);
      setKpiTrendAnalysisAllData([]);
      setKpiTrendAnalysisAllLabels([]);
      setKpiTrendAnalysisAllBenchmark([]);
      setKpiTrendAnalysisIsLoading(false);
      setKpiTrendAnalysisIsLoaded(false);
      setKpiTrendAnalysisError(error.code);
    }
  };

  let kpiTrendAnalysisLineChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isKpiTrendAnalysisLoaded && !isKpiTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(kpiTrendAnalysisData) ||
      NoDataAvailableChecker(kpiTrendAnalysisDataLabels) ||
      NoDataAvailableChecker(kpiTrendAnalysisBenchmark)
    ) {
      kpiTrendAnalysisLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      kpiTrendAnalysisLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="kpiTrendAnalysis"
              chartClass="section_card_chart"
              showLegend={false}
              chartData={kpiTrendAnalysisData}
              chartLabels={kpiTrendAnalysisDataLabels}
              showTarget={true}
              targetValue={kpiTrendAnalysisBenchmark}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (kpiTrendAnalysisError) {
    kpiTrendAnalysisLineChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={kpiTrendAnalysisError}
          chartType="lineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isKpiTrendAnalysisLoading) {
    kpiTrendAnalysisLineChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // HALEON KPI TREND ANALYSIS
  const fetchHaleonKpiTrendAnalysisHandler = async () => {
    setHaleonKpiTrendAnalysisIsLoading(true);
    setHaleonKpiTrendAnalysisIsLoaded(false);
    setHaleonKpiTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        benchmark: secondKpiTrendAnalysisDropdown.value,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/video/kpi-trend-analysis-without-benchmark/", config);
      setHaleonKpiTrendAnalysisData(res?.data?.data?.vtr ?? []);
      setHaleonKpiTrendAnalysisDataLabels(res?.data?.label?.vtr ?? []);
      setHaleonKpiTrendAnalysisAllData(res?.data?.data ?? []);
      setHaleonKpiTrendAnalysisAllLabels(res?.data?.label ?? []);


      setHaleonKpiTrendAnalysisIsLoading(false);
      setHaleonKpiTrendAnalysisIsLoaded(true);
      setHaleonKpiTrendAnalysisError(null);
    } catch (error) {
      setHaleonKpiTrendAnalysisData([]);
      setHaleonKpiTrendAnalysisDataLabels([]);
      // setHaleonKpiTrendAnalysisBenchmark([]);
      setHaleonKpiTrendAnalysisAllData([]);
      setHaleonKpiTrendAnalysisAllLabels([]);
      // setHaleonKpiTrendAnalysisAllBenchmark([]);
      setHaleonKpiTrendAnalysisIsLoading(false);
      setHaleonKpiTrendAnalysisIsLoaded(false);
      setHaleonKpiTrendAnalysisError(error.code);
    }
  };

  let haleonKpiTrendAnalysisLineChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isHaleonKpiTrendAnalysisLoaded && !isHaleonKpiTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(haleonKpiTrendAnalysisData) || NoDataAvailableChecker(haleonKpiTrendAnalysisDataLabels)) {
      haleonKpiTrendAnalysisLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      haleonKpiTrendAnalysisLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="haleonKpiTrendAnalysis"
              chartClass="section_card_chart"
              showLegend={false}
              chartData={haleonKpiTrendAnalysisData}
              chartLabels={haleonKpiTrendAnalysisDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (haleonKpiTrendAnalysisError) {
    haleonKpiTrendAnalysisLineChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={haleonKpiTrendAnalysisError}
          chartType="lineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHaleonKpiTrendAnalysisLoading) {
    haleonKpiTrendAnalysisLineChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CONTENT POSITION WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchContentPositionDistributionHandler = async () => {
    setContentPositionDistributionIsLoading(true);
    setContentPositionDistributionIsLoaded(false);
    setContentPositionDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/content-position-wise-distribution/",
        config
      );
      setContentPositionDistribution(res?.data?.data?.complete_views ?? []);
      setContentPositionDistributionAllDropdownsData(res?.data?.data ?? []);

      setContentPositionDistributionIsLoading(false);
      setContentPositionDistributionIsLoaded(true);
      setContentPositionDistributionError(null);
    } catch (error) {
      setContentPositionDistributionAllDropdownsData([]);
      setContentPositionDistribution([]);
      setContentPositionDistributionIsLoading(false);
      setContentPositionDistributionIsLoaded(false);
      setContentPositionDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let contentPositionDistributionChart = (
    <Wrapper>
      <NoDataAvailableLoader chartType="donutChartType" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isContentPositionDistributionLoaded &&
    !isContentPositionDistributionLoading
  ) {
    if (NoDataAvailableChecker(contentPositionDistribution)) {
      contentPositionDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      contentPositionDistributionChart = (
        <Wrapper>
          <DonutPieChart
            chartId={"contentPositionDistribution"}
            chartClass={"section_card_chart"}
            chartData={contentPositionDistribution}
            showLegend={true}
            showLabels={true}
            showVerticalLabels={true}
          />
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (contentPositionDistributionerror) {
    contentPositionDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={contentPositionDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isContentPositionDistributionLoading) {
    contentPositionDistributionChart = (
      <Wrapper>
        {" "}
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // APP URL DISTRIBUTION
  const [isAppUrlDistributionOpen, setIsAppUrlDistributionOpen] = useState(false)
  const [appUrlDistributionDisabledvalues, setAppUrlDistributionDisabledvalues] = useState([])

  const fetchAppUrlDistributionHandler = async () => {
    setAppUrlDistributionIsLoading(true);
    setAppUrlDistributionIsLoaded(false);
    setAppUrlDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    let appUrlDistributionHandlerAPI;

    if (companyID === HALEON_COMPANY_ID) {
      appUrlDistributionHandlerAPI = "performance/dv360/video/app-wise-bar-url-distribution/"
    } else {
      appUrlDistributionHandlerAPI = "performance/dv360/video/app-url-wise-distribution/"
    }

    // let isHaleonAPI = companyID == HALEON_COMPANY_ID ? "performance/dv360/video/app-wise-bar-url-distribution/" : "performance/dv360/video/app-url-wise-distribution/";

    try {
      const res = await axios.get(appUrlDistributionHandlerAPI, config);

      setAppUrlDistribution(res?.data?.data?.complete_views ?? []);
      setAppUrlDistributionLabels(res?.data?.label?.complete_views ?? []);
      setAppUrlDistributionAllData(res?.data?.data ?? []);
      setAppUrlDistributionAllLabels(res?.data?.label ?? []);


      setAppUrlDistributionIsLoading(false);
      setAppUrlDistributionIsLoaded(true);
      setAppUrlDistributionError(null);
    } catch (error) {
      setAppUrlDistributionAllLabels([]);
      setAppUrlDistributionAllData([]);
      setAppUrlDistribution([]);
      setAppUrlDistributionLabels([]);
      setAppUrlDistributionIsLoading(false);
      setAppUrlDistributionIsLoaded(false);
      setAppUrlDistributionError(error.code);
    }
  };

  let appUrlDistributionChart = (
    <Wrapper>
      <Loader loaderType={companyID == HALEON_COMPANY_ID ? "barChartLoader" : "singleLineChartLoader"} />
    </Wrapper>
  );

  let appUrlDistributionChart2 = (
    <Wrapper>
      <Loader loaderType={companyID == HALEON_COMPANY_ID ? "barChartLoader" : "singleLineChartLoader"} />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAppUrlDistributionLoaded && !isAppUrlDistributionLoading) {
    if (
      NoDataAvailableChecker(appUrlDistribution) ||
      NoDataAvailableChecker(appUrlDistributionLabels)
    ) {
      appUrlDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType={companyID == HALEON_COMPANY_ID ? "barChartType" : "lineChartType"} />
        </Wrapper>
      );

      appUrlDistributionChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType={companyID == HALEON_COMPANY_ID ? "barChartType" : "lineChartType"} />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        appUrlDistributionChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              {companyID == HALEON_COMPANY_ID ?
                <BarChart
                  chartId="social_twitter_profile_clickss"
                  chartClass="section_card_chart"
                  chartData={appUrlDistribution}
                  chartLabels={appUrlDistributionLabels}
                />
                :
                <LineChart
                  chartId="social_twitter_profile_clickss"
                  chartClass="section_card_chart"
                  chartData={appUrlDistribution}
                  chartLabels={appUrlDistributionLabels}
                  showLegend={true}
                  showLabels={true}
                  disabledLegendArray={appUrlDistributionDisabledvalues}
                  setDisabledValues={setAppUrlDistributionDisabledvalues}
                />
              }
            </ErrorBoundary>
          </Wrapper>
        );

        appUrlDistributionChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={appUrlDistribution}
                chartId="app_url_trend_2"
                chartLabels={appUrlDistributionLabels}
                showLegend={true}
                disabledLegendArray={appUrlDistributionDisabledvalues}
                setDisabledValues={setAppUrlDistributionDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        )

      } else {
        appUrlDistributionChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              {companyID == HALEON_COMPANY_ID ?
                <BarChart
                  chartId="social_twitter_profile_clickss"
                  chartClass="section_card_chart"
                  chartData={appUrlDistribution}
                  chartLabels={appUrlDistributionLabels}
                />
                :
                <LineChart
                  chartId="social_twitter_profile_clickss"
                  chartClass="section_card_chart"
                  chartData={appUrlDistribution}
                  chartLabels={appUrlDistributionLabels}
                  showLegend={true}
                  showLabels={true}
                />
              }
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (appUrlDistributionerror) {
    appUrlDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader chartType={companyID == HALEON_COMPANY_ID ? "barChartType" : "lineChartType"} error={appUrlDistributionerror} />
      </Wrapper>
    );

    appUrlDistributionChart2 = (
      <Wrapper>
        <ServerErrorsLoader chartType={companyID == HALEON_COMPANY_ID ? "barChartType" : "lineChartType"} error={appUrlDistributionerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAppUrlDistributionLoading) {
    appUrlDistributionChart = (
      <Wrapper>
        <Loader loaderType={companyID == HALEON_COMPANY_ID ? "barChartLoader" : "singleLineChartLoader"} />
      </Wrapper>
    );


    appUrlDistributionChart2 = (
      <Wrapper>
        <Loader loaderType={companyID == HALEON_COMPANY_ID ? "barChartLoader" : "singleLineChartLoader"} />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   DEVICE WISE DISTRIBUTION

  let treemapLevel = 1;
  const [treemapDeviceType, setTreemapDeviceType] = useState("");
  const [treemapOS, setTreemapOS] = useState("");

  const [treemapParams, setTreemapParams] = useState({
    brand_id: BRAND_ID,
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    objective: selectedObjectives,
    platform_campaign: selectedIdandPlatform,
    platform: selectedPlatform,
    campaign: selectedCampaigns,
    campaign_tag:
      selectedBucketsFromFilter.length === 0
        ? null
        : selectedBucketsFromFilter.join(","),
    level: 1,
  });

  const chartlevelHandler = (data) => {
    if (data.level === "1") {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        device_type: data.device_type,
        level: 2,
      });
      setTreemapDeviceType(data.device_type);
    } else if (data.level === "2") {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        device_type: treemapDeviceType,
        operating_system: data.operating_system,
        level: 3,
      });
      setTreemapOS(data.operating_system);
    } else {
      alert("No further drill down available");
    }
  };

  const fetchDeviceWiseDistributionHandler = async () => {
    setDeviceWiseDistributionIsLoading(true);
    setDeviceWiseDistributionIsLoaded(false);
    setDeviceWiseDistributionError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/device-wise-distribution/",
        config
      );

      setDeviceWiseDistribution(res?.data ?? []);
      setDeviceWiseDistributionIsLoading(false);
      setDeviceWiseDistributionIsLoaded(true);
      setDeviceWiseDistributionError(null);
    } catch (error) {
      setDeviceWiseDistribution([]);
      setDeviceWiseDistributionIsLoading(false);
      setDeviceWiseDistributionIsLoaded(false);
      setDeviceWiseDistributionError(error.code);
    }
  };

  let deviceWiseDistributionSection = (
    <Wrapper>
      <div className="section_card_chart_wrapper flex column">
        <Loader loaderType="treeChartLoader" />
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isDeviceWiseDistributionLoaded && !isDeviceWiseDistributionLoading) {
    if (
      NoDataAvailableChecker(deviceWiseDistribution) ||
      (deviceWiseDistribution.length === 1 &&
        deviceWiseDistribution[0].value === 0)
    ) {
      deviceWiseDistributionSection = (
        <Wrapper>
          <div className="section_card_chart_wrapper flex column">
            <NoDataAvailableLoader chartType="treeChartType" />
          </div>
        </Wrapper>
      );
    } else {
      if (treemapLevel > 3) {
        deviceWiseDistributionSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <NoDataAvailableLoader chartType="treeChartType" />
            </div>
          </Wrapper>
        );
      } else {
        deviceWiseDistributionSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <ErrorBoundary chartType="treeChartType">
                <SimpleTreeMap
                  chartId="simpleTreemaptest"
                  chartClass="section_card_chart"
                  chartData={deviceWiseDistribution}
                  levelHandler={chartlevelHandler}
                  /* colorChangeParameter={"growth_percentage"} */
                  chartLabels={[
                    {
                      name: "name",
                      value: "value",
                      toolTipvalue1: "complete_views",
                      toolTipvalue1Label: "Complete Views",
                      toolTipvalue2: "spends",
                      toolTipvalue2Label: "Spends",
                    },
                  ]}
                />
              </ErrorBoundary>
            </div>
            {/*  {isDeviceWiseDistributionLoaded &&
              !isDeviceWiseDistributionLoading &&
              Object.keys(deviceWiseDistribution).length !== 0 && (
                <div className="w_100">
                  <ul className="icz-rilist-inline">
                    <li>
                      <div className="legend-circle circleGreen"></div> Growth
                    </li>
                    <li>
                      <div className="legend-circle circleRed"></div> Degrowth
                    </li>
                  </ul>
                </div>
              )} */}
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceWiseDistributionerror) {
    deviceWiseDistributionSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <ServerErrorsLoader
            chartType="treeChartType"
            error={deviceWiseDistributionerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceWiseDistributionLoading) {
    deviceWiseDistributionSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <Loader loaderType="treeChartLoader" />
        </div>
      </Wrapper>
    );
  }

  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchDeviceWiseDistributionHandler();
  }, [treemapParams]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // STATE MAP

  const fetchStateHandler = async () => {
    setStateDataIsLoading(true);
    setStateDataIsLoaded(false);
    setStateDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/audience-distribution-state/",
        config
      );

      setStateData(res?.data ?? []);

      setStateDataIsLoading(false);
      setStateDataIsLoaded(true);
      setStateDataError(null);
    } catch (error) {
      setStateData([]);
      setStateDataIsLoading(false);
      setStateDataIsLoaded(false);
      setStateDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: STATE
  let audienceDistributionState = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By State</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isStateDataLoaded && !isStateDataLoading) {
    if (
      NoDataAvailableChecker(stateData) ||
      NoDataAvailableChecker(stateTableDataHeaders)
    ) {
      audienceDistributionState = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By State</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionState = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By State"}
              isDynamicFlagVisible={true}
              tableHeader={stateTableDataHeaders}
              tableData={stateData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search State"}
              tableID="audience_distribution_country_state_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_state_section"}
                  sectionName={companyID === HALEON_COMPANY_ID ? "Audience Distribution By State" : "DvStateTableCoke_Audience Distribution By State"}
                  sectionData={stateData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateDataerror) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={stateDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateDataLoading) {
    audienceDistributionState = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By State</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/audience-distribution-city/",
        config
      );

      setCityData(res?.data ?? []);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCity = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By City</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(cityTableDataHeaders)
    ) {
      audienceDistributionCity = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By City</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCity = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By City"}
              isDynamicFlagVisible={true}
              tableHeader={cityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
              tableID="audience_distribution_country_city_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_city_section"}
                  sectionName={companyID === HALEON_COMPANY_ID ? "Audience Distribution By City" : "DvCityTableCoke_Audience Distribution By City"}
                  sectionData={cityData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    audienceDistributionCity = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY TABLE

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/audience-distribution-country/",
        config
      );

      setCountryData(res?.data ?? []);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let audienceDistributionCountry = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(countryTableDataHeaders)
    ) {
      audienceDistributionCountry = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      audienceDistributionCountry = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={"By Country"}
              isCountryFlagVisible={true}
              tableHeader={countryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableID="audience_distribution_country_country_section"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"audience_distribution_country_country_section"}
                  sectionName={"Audience Distribution - Country"}
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    audienceDistributionCountry = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }



  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AD TYPE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchAdTypeDistributionHandler = async () => {
    setAdTypeDistributionIsLoading(true);
    setAdTypeDistributionIsLoaded(false);
    setAdTypeDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/youtube-ad-type-distribution/",
        config
      );
      setAdTypeDistribution(res?.data?.data?.complete_views ?? []);
      setAdTypeDistributionAllDropdownsData(res?.data?.data ?? []);

      setAdTypeDistributionIsLoading(false);
      setAdTypeDistributionIsLoaded(true);
      setAdTypeDistributionError(null);
    } catch (error) {
      setAdTypeDistributionAllDropdownsData([]);
      setAdTypeDistribution([]);
      setAdTypeDistributionIsLoading(false);
      setAdTypeDistributionIsLoaded(false);
      setAdTypeDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let adTypeDistributionChart = (
    <Wrapper>
      <NoDataAvailableLoader chartType="donutChartType" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isAdTypeDistributionLoaded && !isAdTypeDistributionLoading) {
    if (NoDataAvailableChecker(adTypeDistribution)) {
      adTypeDistributionChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      adTypeDistributionChart = (
        <Wrapper>
          <DonutPieChart
            chartId={"adTypeDistribution"}
            chartClass={"section_card_chart"}
            chartData={adTypeDistribution}
            showLegend={true}
            showLabels={true}
            showVerticalLabels={true}
          />
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (adTypeDistributionerror) {
    adTypeDistributionChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={adTypeDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAdTypeDistributionLoading) {
    adTypeDistributionChart = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AD TYPE PERFORMANCE
  const [isAdTypePerformanceOpen, setIsAdTypePerformanceOpen] = useState(false)

  let defaultAdTypePerformanceDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultAdTypePerformanceDisabledvalues = []
  } else {
    defaultAdTypePerformanceDisabledvalues = ["Masthead ad", "Bumper ad"]
  }

  const [adTypePerformanceDisabledvalues, setAdTypePerformanceDisabledvalues] = useState(defaultAdTypePerformanceDisabledvalues)

  const fetchAdTypePerformanceHandler = async () => {
    setAdTypePerformanceIsLoading(true);
    setAdTypePerformanceIsLoaded(false);
    setAdTypePerformanceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/youtube-performance-by-ad/",
        config
      );

      setAdTypePerformance(res?.data?.data?.complete_views ?? []);
      setAdTypePerformanceLabels(res?.data?.label?.complete_views ?? []);
      setAdTypePerformanceAllData(res?.data?.data ?? []);
      setAdTypePerformanceAllLabels(res?.data?.label ?? []);

      setAdTypePerformanceIsLoading(false);
      setAdTypePerformanceIsLoaded(true);
      setAdTypePerformanceError(null);
    } catch (error) {
      setAdTypePerformanceAllLabels([]);
      setAdTypePerformanceAllData([]);
      setAdTypePerformance([]);
      setAdTypePerformanceLabels([]);
      setAdTypePerformanceIsLoading(false);
      setAdTypePerformanceIsLoaded(false);
      setAdTypePerformanceError(error.code);
    }
  };

  let adTypePerformanceChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let adTypePerformanceChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );


  // IF DATA IS AVAILABLE
  if (isAdTypePerformanceLoaded && !isAdTypePerformanceLoading) {
    if (
      NoDataAvailableChecker(adTypePerformance) ||
      NoDataAvailableChecker(adTypePerformanceLabels)
    ) {
      adTypePerformanceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      adTypePerformanceChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        adTypePerformanceChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="adTypePerformance"
                chartClass="section_card_chart"
                chartData={adTypePerformance}
                chartLabels={adTypePerformanceLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={adTypePerformanceDisabledvalues}
                setDisabledValues={setAdTypePerformanceDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        adTypePerformanceChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="adTypePerformance_2"
                chartClass="section_card_chart"
                chartData={adTypePerformance}
                chartLabels={adTypePerformanceLabels}
                showLegend={true}
                disabledLegendArray={adTypePerformanceDisabledvalues}
                setDisabledValues={setAdTypePerformanceDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        adTypePerformanceChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="adTypePerformance"
                chartClass="section_card_chart"
                chartData={adTypePerformance}
                chartLabels={adTypePerformanceLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (adTypePerformanceerror) {
    adTypePerformanceChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={adTypePerformanceerror}
        />
      </Wrapper>
    );

    adTypePerformanceChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={adTypePerformanceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAdTypePerformanceLoading) {
    adTypePerformanceChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    adTypePerformanceChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VTR TREND ANALYSIS
  const [isVtrTrendOpen, setIsVtrTrendOpen] = useState(false)

  let defaultVtrTrendDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVtrTrendDisabledvalues = []
  } else {
    defaultVtrTrendDisabledvalues = ["Midpoint VTR", "Complete VTR"]
  }

  const [VtrTrendDisabledvalues, setVtrTrendDisabledvalues] = useState(defaultVtrTrendDisabledvalues)

  const fetchVtrTrendAnalysisHandler = async () => {
    setVtrTrendAnalysisIsLoading(true);
    setVtrTrendAnalysisIsLoaded(false);
    setVtrTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/youtube-vtr-trend-analysis/",
        config
      );

      setVtrTrendAnalysis(res?.data?.data?.Skippable ?? []);
      setVtrTrendAnalysisLabels(res?.data?.label?.Skippable ?? []);
      setVtrTrendAnalysisAllData(res?.data?.data ?? []);
      setVtrTrendAnalysisAllLabels(res?.data?.label ?? []);


      setVtrTrendAnalysisIsLoading(false);
      setVtrTrendAnalysisIsLoaded(true);
      setVtrTrendAnalysisError(null);
    } catch (error) {
      setVtrTrendAnalysisAllLabels([]);
      setVtrTrendAnalysisAllData([]);
      setVtrTrendAnalysis([]);
      setVtrTrendAnalysisLabels([]);
      setVtrTrendAnalysisIsLoading(false);
      setVtrTrendAnalysisIsLoaded(false);
      setVtrTrendAnalysisError(error.code);
    }
  };

  let vtrTrendAnalysisChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let vtrTrendAnalysisChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isVtrTrendAnalysisLoaded && !isVtrTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(vtrTrendAnalysis) ||
      NoDataAvailableChecker(vtrTrendAnalysisLabels)
    ) {
      vtrTrendAnalysisChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      vtrTrendAnalysisChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {

        vtrTrendAnalysisChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="vtrTrendAnalysis"
                chartClass="section_card_chart"
                chartData={vtrTrendAnalysis}
                chartLabels={vtrTrendAnalysisLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={VtrTrendDisabledvalues}
                setDisabledValues={setVtrTrendDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );


        vtrTrendAnalysisChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={vtrTrendAnalysis}
                chartId="vtrTrendAnalysis_2"
                chartLabels={vtrTrendAnalysisLabels}
                showLegend={true}
                disabledLegendArray={VtrTrendDisabledvalues}
                setDisabledValues={setVtrTrendDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        vtrTrendAnalysisChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="vtrTrendAnalysis"
                chartClass="section_card_chart"
                chartData={vtrTrendAnalysis}
                chartLabels={vtrTrendAnalysisLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (vtrTrendAnalysiserror) {
    vtrTrendAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={vtrTrendAnalysiserror}
        />
      </Wrapper>
    );

    vtrTrendAnalysisChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={vtrTrendAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVtrTrendAnalysisLoading) {
    vtrTrendAnalysisChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    vtrTrendAnalysisChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // YOUTUBE AD TYPE
  const [isYoutubeAdTypeOpen, setIsYoutubeAdTypeOpen] = useState(false)

  let defaultYoutubeAdTypeDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultYoutubeAdTypeDisabledvalues = []
  } else {
    defaultYoutubeAdTypeDisabledvalues = ["VTR"]
  }

  const [youtubeAdTypeDisabledvalues, setYoutubeAdTypeDisabledvalues] = useState(defaultYoutubeAdTypeDisabledvalues)

  const fetchYoutubeAdTypeHandler = async () => {
    setYoutubeAdTypeIsLoading(true);
    setYoutubeAdTypeIsLoaded(false);
    setYoutubeAdTypeError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        youtube_ad_type: firstYoutubeAdTypeDropdown.value,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    let youtubeAdTypeHandlerAPI;

    if (companyID === HALEON_COMPANY_ID) {
      youtubeAdTypeHandlerAPI = "performance/dv360/video/youtube-ad-type-wise-performance/"
    } else {
      youtubeAdTypeHandlerAPI = "performance/dv360/video/youtube-ad-wise-benchmarking/"
    }

    try {
      const res = await axios.get(youtubeAdTypeHandlerAPI, config);

      setYoutubeAdType(res?.data?.data?.vtr ?? []);
      setYoutubeAdTypeLabels(res?.data?.label?.vtr ?? []);
      setYoutubeAdTypeAllData(res?.data?.data ?? []);
      setYoutubeAdTypeAllLabels(res?.data?.label ?? []);

      setYoutubeAdTypeIsLoading(false);
      setYoutubeAdTypeIsLoaded(true);
      setYoutubeAdTypeError(null);
    } catch (error) {
      setYoutubeAdTypeAllLabels([]);
      setYoutubeAdTypeAllData([]);
      setYoutubeAdType([]);
      setYoutubeAdTypeLabels([]);
      setYoutubeAdTypeIsLoading(false);
      setYoutubeAdTypeIsLoaded(false);
      setYoutubeAdTypeError(error.code);
    }
  };

  let youtubeAdTypeChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let youtubeAdTypeChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isYoutubeAdTypeLoaded && !isYoutubeAdTypeLoading) {
    if (
      NoDataAvailableChecker(youtubeAdType) ||
      NoDataAvailableChecker(youtubeAdTypeLabels)
    ) {
      youtubeAdTypeChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      youtubeAdTypeChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {

        youtubeAdTypeChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="youtubeAdType"
                chartClass="section_card_chart"
                chartData={youtubeAdType}
                chartLabels={youtubeAdTypeLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={youtubeAdTypeDisabledvalues}
                setDisabledValues={setYoutubeAdTypeDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        youtubeAdTypeChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartId="youtubeAdType_2"
                chartClass="section_card_chart"
                chartData={youtubeAdType}
                chartLabels={youtubeAdTypeLabels}
                showLegend={true}
                showLabels={true}
                disabledLegendArray={youtubeAdTypeDisabledvalues}
                setDisabledValues={setYoutubeAdTypeDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        youtubeAdTypeChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="youtubeAdType"
                chartClass="section_card_chart"
                chartData={youtubeAdType}
                chartLabels={youtubeAdTypeLabels}
                showLegend={true}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (youtubeAdTypeerror) {
    youtubeAdTypeChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={youtubeAdTypeerror}
        />
      </Wrapper>
    );

    youtubeAdTypeChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={youtubeAdTypeerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isYoutubeAdTypeLoading) {
    youtubeAdTypeChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    youtubeAdTypeChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  const tabData = companyID === HALEON_COMPANY_ID ? ["By Country", "By State", "By City"] : ["By State", "By City"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);


  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit(
      "Marketing Intelligence",
      "Campaign",
      "Dv360",
      "Video",
      event
    );
  };


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/video/audience-demographic-gender/",
        config
      );

      setTotalMaleCount(res?.data?.male_count?.trueview_views ?? []);
      setMaleCount(res?.data?.male_list?.data?.trueview_views ?? []);
      setMaleCountLabels(res?.data?.dimension?.trueview_views ?? []);
      setTotalFemaleCount(res?.data?.female_count?.trueview_views ?? []);
      setFemaleCount(res?.data?.female_list?.data?.trueview_views ?? []);
      setFemaleCountLabels(res?.data?.dimension?.trueview_views ?? []);

      setTotalUnknownCount(res?.data?.unknown_count?.trueview_views ?? []);
      setUnknownCount(res?.data?.unknown_list?.data?.trueview_views ?? []);
      setUnknownCountLabels(res?.data?.dimension?.trueview_views ?? []);

      setMaleCountAllDropdownsData(res?.data?.male_list?.data ?? []);
      setMaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalMaleCountAllDropdownsData(res?.data?.male_count ?? []);

      setFemaleCountAllDropdownsData(res?.data?.female_list?.data ?? []);
      setFemaleCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalFemaleCountAllDropdownsData(res?.data?.female_count ?? []);

      setUnknownCountAllDropdownsData(res?.data?.unknown_list?.data ?? []);
      setUnknownCountAllDropdownsDataLabels(res?.data?.dimension ?? []);
      setTotalUnknownCountAllDropdownsData(res?.data?.unknown_count ?? []);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCount([]);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCount([]);

      setUnknownCountAllDropdownsData([]);
      setUnknownCountAllDropdownsDataLabels([]);
      setTotalUnknownCount([]);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>
  let maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }




    if (NoDataAvailableChecker(totalMaleCountPercentage)) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader error={maleCounterror} />
      </Wrapper>
    );

    maleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={maleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    maleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  let femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }



    if (NoDataAvailableChecker(totalFemaleCountPercentage)) {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
      </Wrapper>
    );
    femaleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    femaleAudienceDistributionIcon = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
  let unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (
      NoDataAvailableChecker(unknownCount) ||
      NoDataAvailableChecker(unknownCountLabels)
    ) {
      unknownAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_unknown"
              chartClass="section_card_chart"
              chartData={unknownCount}
              chartLabels={unknownCountLabels}
              showLegend={false}
              /*  showLabels={true}
              showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }

    if (NoDataAvailableChecker(totalUnknownCountPercentage)) {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="p_20">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalUnknownCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
    unknownAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }




  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLANNED VS METRICS

  // TO FETCH METRIC DATA FROM API
  const fetchPlannedVsDeliveredMetricsHandler = async () => {
    setPlannedVsDeliveredMetricsIsLoading(true);
    setPlannedVsDeliveredMetricsIsLoaded(false);
    setPlannedVsDeliveredMetricsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {

      const res = await axios.get("performance/summary/planned-delivered-chart/v3/dv360_video", config);

      setPlannedVsDeliveredMetricsData(res?.data?.data ?? []);
      setPlannedVsDeliveredMetricsDataLabels(res?.data?.label ?? []);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(true);
      setPlannedVsDeliveredMetricsError(null);
    } catch (error) {
      setPlannedVsDeliveredMetricsData([]);
      setPlannedVsDeliveredMetricsDataLabels([]);
      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);
    }
  };

  let plannedVsDeliveredMetricsBarChart = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (
    isplannedVsDeliveredMetricsLoaded &&
    !isplannedVsDeliveredMetricsLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredMetricsData) ||
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabels)
    ) {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="plannedDeliveredBarChart"
              chartClass="section_card_chart"
              chartData={plannedVsDeliveredMetricsData}
              chartLabels={plannedVsDeliveredMetricsDataLabels}
              chartColorSet={BlueColorStart500}
              showLabels={true}
              minimumGridDistance={0}
              showLegend={false}
              percentageTooltip={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredMetricsError) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={plannedVsDeliveredMetricsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isplannedVsDeliveredMetricsLoading) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE

  // TO FETCH METRIC  FROM API
  const fetchPlannedVsDeliveredTableDataHandler = async () => {
    setPlannedVsDeliveredTableDataIsLoading(true);
    setPlannedVsDeliveredTableDataIsLoaded(false);
    setPlannedVsDeliveredTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0 ||
            selectedBucketsFromFilter === undefined
            ? null
            : selectedBucketsFromFilter.join(","),
        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption.value.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };


    try {

      const res = await axios.get("performance/summary/planned-delivered-table/v3/dv360_video", config);

      setPlannedVsDeliveredTableData(res?.data?.data ?? []);
      setPlannedVsDeliveredTableDataHaeders(res?.data?.label ?? []);
      setPlannedVsDeliveredTableDataTotal(res?.data?.row_summary ?? [])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(true);
      setPlannedVsDeliveredTableDataError(null);
    } catch (error) {
      setPlannedVsDeliveredTableData([]);
      setPlannedVsDeliveredTableDataHaeders([]);
      setPlannedVsDeliveredTableDataTotal([])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  let plannedVsDeliveredTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Campaign wise Planned v/s Delivered table
                {selectedMarketTgDropdownOption &&
                  " : " + selectedMarketTgDropdownOption.label}
                {selectedRegionDropdownOption &&
                  " : " + selectedRegionDropdownOption.label}
              </h2>
              <SectionDefinationsBadge
                title={"Campaign wise Planned v/s Delivered table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isPlannedVsDeliveredTableDataLoaded &&
    !isPlannedVsDeliveredTableDataLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredTableData) ||
      NoDataAvailableChecker(plannedVsDeliveredTableDataHeaders)
    ) {
      plannedVsDeliveredTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">
                    Campaign wise Planned v/s Delivered table
                    {selectedMarketTgDropdownOption &&
                      " : " + selectedMarketTgDropdownOption.label}
                    {selectedRegionDropdownOption &&
                      " : " + selectedRegionDropdownOption.label}
                  </h2>
                  <SectionDefinationsBadge
                    title={"Campaign wise Planned v/s Delivered table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      plannedVsDeliveredTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Campaign wise Planned v/s Delivered table" +
                `${selectedMarketTgDropdownOption &&
                " : " + selectedMarketTgDropdownOption.label
                }
            ${selectedRegionDropdownOption &&
                " : " + selectedRegionDropdownOption.label
                }`
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={plannedVsDeliveredTableDataHeaders}
              tableData={plannedVsDeliveredTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="planned_vs_delivered_table"
              showTotalRow={plannedVsDeliveredTableDataTotal}
              showFixedSpend={true}
              calculateTotal={true}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"planned_vs_delivered_table"}
                  sectionName={`TableDynaLabels_Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
                    } ${selectedRegionDropdownOption !== undefined &&
                      selectedRegionDropdownOption !== null &&
                      selectedRegionDropdownOption !== ""
                      ? `: ` + selectedRegionDropdownOption.label
                      : ""
                    }`}
                  sectionData={concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal)}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredTableDataerror) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}
                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={plannedVsDeliveredTableDataerror}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlannedVsDeliveredTableDataLoading) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}
                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }




  var maleFemaleDataCSV = {
    "Total Count": [{ [`Male - ${selectedDropdownOption.label}`]: totalMaleCount, [`Female - ${selectedDropdownOption.label}`]: totalFemaleCount, [`Unknown - ${selectedDropdownOption.label}`]: totalUnknownCount, }],
    [`AgeBarChartCampaignDynalabels_Audience Distribution-Male_${selectedDropdownOption.label}`]: maleCount,
    [`AgeBarChartCampaignDynalabels_Audience Distribution-Female_${selectedDropdownOption.label}`]: femaleCount,
    [`AgeBarChartCampaignDynalabels_Audience Distribution-Unknown_${selectedDropdownOption.label}`]: unknownCount,
  };


  var barChartCSV = {
    [`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `: ` + selectedRegionDropdownOption.label
        : ""
      }`]: plannedVsDeliveredMetricsData,
  };

  var tableCSV = {
    [`TableDynaLabels_PvsD Table_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `: ` + selectedRegionDropdownOption.label
        : ""
      }`]: concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal),
  };

  var allData;

  companyID === HALEON_COMPANY_ID ? allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    "Video Views": videoViews,
    [`DvTrendAnalysis_Trend Analysis_${trendAnalysisDropdown.label}`]: trendAnalysis,
    [`DateFirstFormatter_KPI Trend Analysis`]: haleonKpiTrendAnalysisData,
    [`DonutWithActiveDropdown_Content Position Wise Dist_${selectedContentPositionDistributionDropdownOption.label}`]: contentPositionDistribution,
    [`AppUrl_App - Url Wise Dist`]: appUrlDistribution,

    "DvVideoTreemap_Device Wise Distribution": deviceWiseDistribution,
    "Audience Distribution - State": stateData,
    "Audience Distribution - City": cityData,
    "Audience Distribution - Country": countryData,
    [`DonutWithActiveDropdown_Ad Type Wise Distribution_${selectedAdTypeDistributionDropdownOption.label}`]: adTypeDistribution,
    [`DateFirstActiveDropdownLabels_Performance By Ad Type_${adTypePerformanceDropdown.label}`]: adTypePerformance,
    [`DateFirstActiveDropdownLabels_VTR Trend Analysis_${vtrTrendAnalysisDropdown.label}`]: vtrTrendAnalysis,
    [`DateFirstActiveDropdownLabels_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + ":" + secondYoutubeAdTypeDropdown.label}`]: youtubeAdType,
    ...maleFemaleDataCSV,


  }
    : allData = {
      ...topCardData,
      "SummaryCard_Summary Card": metricCards,
      "Video Views": videoViews,
      [`DvTrendAnalysis_Trend Analysis_${trendAnalysisDropdown.label}`]: trendAnalysis,
      [`DvTrendAnalysis_KPI Trend Analysis_${secondKpiTrendAnalysisDropdown.label}`]: kpiTrendAnalysisData,
      [`DonutWithActiveDropdown_Content Position Wise Dist_${selectedContentPositionDistributionDropdownOption.label}`]: contentPositionDistribution,
      [`DateFirstActiveDropdownLabels_App - Url Wise Dist_${appUrlDistributionDropdown.label}`]: appUrlDistribution,
      "DvVideoTreemap_Device Wise Distribution": deviceWiseDistribution,
      "Audience Distribution - State": stateData,
      "Audience Distribution - City": cityData,
      [`DonutWithActiveDropdown_Ad Type Wise Distribution_${selectedAdTypeDistributionDropdownOption.label}`]: adTypeDistribution,
      [`DateFirstActiveDropdownLabels_Performance By Ad Type_${adTypePerformanceDropdown.label}`]: adTypePerformance,
      [`DateFirstActiveDropdownLabels_VTR Trend Analysis_${vtrTrendAnalysisDropdown.label}`]: vtrTrendAnalysis,
      [`DvYTAdType_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + "&" + secondYoutubeAdTypeDropdown.value}`]: youtubeAdType,
      ...barChartCSV,
      ...tableCSV
    };
  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    videoViews,
    trendAnalysis,
    kpiTrendAnalysisData,
    contentPositionDistribution,
    appUrlDistribution,
    deviceWiseDistribution,
    stateData,
    cityData,
    adTypeDistribution,
    adTypePerformance,
    vtrTrendAnalysis,
    youtubeAdType,
    haleonKpiTrendAnalysisData,
    countryData,
    maleCount,
    femaleCount,
    unknownCount,
    plannedVsDeliveredMetricsData,
    plannedVsDeliveredTableData,
    plannedVsDeliveredTableDataTotal
  ]);


  useEffect(() => {
    if (companyID === HALEON_COMPANY_ID) {
      if (
        (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
        (videoViewserror !== null ? true : isContentAnalysisLoaded && (isContentAnalysisLoading === false)) &&
        (trendAnalysiserror !== null ? true : isTrendAnalysisLoaded && (isTrendAnalysisLoading === false)) &&
        (haleonKpiTrendAnalysisError !== null ? true : isHaleonKpiTrendAnalysisLoaded && (isHaleonKpiTrendAnalysisLoading === false)) &&
        (contentPositionDistributionerror !== null ? true : isContentPositionDistributionLoaded && (isContentPositionDistributionLoading === false)) &&
        (appUrlDistributionerror !== null ? true : isAppUrlDistributionLoaded && (isAppUrlDistributionLoading === false)) &&
        (deviceWiseDistributionerror !== null ? true : isDeviceWiseDistributionLoaded && (isDeviceWiseDistributionLoading === false)) &&
        (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
        (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
        (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false)) &&
        (adTypeDistributionerror !== null ? true : isAdTypeDistributionLoaded && (isAdTypeDistributionLoading === false)) &&
        (adTypePerformanceerror !== null ? true : isAdTypePerformanceLoaded && (isAdTypePerformanceLoading === false)) &&
        (vtrTrendAnalysiserror !== null ? true : isVtrTrendAnalysisLoaded && (isVtrTrendAnalysisLoading === false)) &&
        (youtubeAdTypeerror !== null ? true : isYoutubeAdTypeLoaded && (isYoutubeAdTypeLoading === false)) &&
        (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
        (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
        (unknownCounterror !== null ? true : isUnknownCountLoaded && (isUnknownCountLoading === false))
      ) {
        setIsExportDisabled(false)
      } else {
        setIsExportDisabled(true)
      }

    } else {
      if (
        (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
        (videoViewserror !== null ? true : isContentAnalysisLoaded && (isContentAnalysisLoading === false)) &&
        (trendAnalysiserror !== null ? true : isTrendAnalysisLoaded && (isTrendAnalysisLoading === false)) &&
        (kpiTrendAnalysisError !== null ? true : isKpiTrendAnalysisLoaded && (isKpiTrendAnalysisLoading === false)) &&
        (contentPositionDistributionerror !== null ? true : isContentPositionDistributionLoaded && (isContentPositionDistributionLoading === false)) &&
        (appUrlDistributionerror !== null ? true : isAppUrlDistributionLoaded && (isAppUrlDistributionLoading === false)) &&
        (deviceWiseDistributionerror !== null ? true : isDeviceWiseDistributionLoaded && (isDeviceWiseDistributionLoading === false)) &&
        (stateDataerror !== null ? true : isStateDataLoaded && (isStateDataLoading === false)) &&
        (cityDataerror !== null ? true : isCityDataLoaded && (isCityDataLoading === false)) &&
        (adTypeDistributionerror !== null ? true : isAdTypeDistributionLoaded && (isAdTypeDistributionLoading === false)) &&
        (adTypePerformanceerror !== null ? true : isAdTypePerformanceLoaded && (isAdTypePerformanceLoading === false)) &&
        (vtrTrendAnalysiserror !== null ? true : isVtrTrendAnalysisLoaded && (isVtrTrendAnalysisLoading === false)) &&
        (youtubeAdTypeerror !== null ? true : isYoutubeAdTypeLoaded && (isYoutubeAdTypeLoading === false)) &&
        (plannedVsDeliveredMetricsError !== null ? true : isplannedVsDeliveredMetricsLoaded && (isplannedVsDeliveredMetricsLoading === false)) &&
        (plannedVsDeliveredTableDataerror !== null ? true : isPlannedVsDeliveredTableDataLoaded && (isPlannedVsDeliveredTableDataLoading === false))
      ) {
        setIsExportDisabled(false)
      } else {
        setIsExportDisabled(true)
      }
    }



  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    videoViewserror, isContentAnalysisLoaded, isContentAnalysisLoading,
    trendAnalysiserror, isTrendAnalysisLoaded, isTrendAnalysisLoading,
    kpiTrendAnalysisError, isKpiTrendAnalysisLoaded, isKpiTrendAnalysisLoading,
    contentPositionDistributionerror, isContentPositionDistributionLoaded, isContentPositionDistributionLoading,
    appUrlDistributionerror, isAppUrlDistributionLoaded, isAppUrlDistributionLoading,
    deviceWiseDistributionerror, isDeviceWiseDistributionLoaded, isDeviceWiseDistributionLoading,
    stateDataerror, isStateDataLoaded, isStateDataLoading,
    cityDataerror, isCityDataLoaded, isCityDataLoading,
    adTypeDistributionerror, isAdTypeDistributionLoaded, isAdTypeDistributionLoading,
    adTypePerformanceerror, isAdTypePerformanceLoaded, isAdTypePerformanceLoading,
    vtrTrendAnalysiserror, isVtrTrendAnalysisLoaded, isVtrTrendAnalysisLoading,
    youtubeAdTypeerror, isYoutubeAdTypeLoaded, isYoutubeAdTypeLoading,
    plannedVsDeliveredMetricsError, isplannedVsDeliveredMetricsLoaded, isplannedVsDeliveredMetricsLoading,
    plannedVsDeliveredTableDataerror, isPlannedVsDeliveredTableDataLoaded, isPlannedVsDeliveredTableDataLoading,
    (companyID === HALEON_COMPANY_ID && isHaleonKpiTrendAnalysisLoaded, haleonKpiTrendAnalysisError, isHaleonKpiTrendAnalysisLoading),
    (companyID === HALEON_COMPANY_ID && countryDataerror, isCountryDataLoaded, isCountryDataLoading),
    (companyID === HALEON_COMPANY_ID && maleCounterror, isMaleCountLoaded, isMaleCountLoading),
    (companyID === HALEON_COMPANY_ID && unknownCounterror, isUnknownCountLoaded, isUnknownCountLoading),

  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="video_view_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Video Views  </h2>
              <SectionDefinationsBadge
                title={"Video Views"}
                module={"campaign"}
                platform={"Dv360"}
                section={"video"}
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_view_section"}
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionName={"Video Views"}
                sectionData={videoViews}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart}</div>
          </div>
        </div>
      </div>

      <div
        id="trend_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              Trend Analysis
              {trendAnalysisDropdown && " : " + trendAnalysisDropdown.label}
            </h2> <SectionDefinationsBadge
                title="Trend Analysis"
                module={"campaign"}
                platform={"Dv360"}
                section={"video"}
              />
            </div>

            <Modal
              open={isTrendAnalysisOpen}
              setOpen={setIsTrendAnalysisOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"trendAnalysisChart_2"}
                  sectionName={`DvTrendAnalysis_Trend Analysis_${trendAnalysisDropdown.label}`}
                  sectionData={trendAnalysis}
                />}
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={trendAnalysisDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={trendAnalysisDropdown}
                  selectedOptions={trendAnalysisDropdown}
                  setStatedropdown={trendAnalysisClickHandler}
                />
              }
              parentId={"trend_analysis_section"}
              title={`Trend Analysis ${trendAnalysisDropdown && " : " + trendAnalysisDropdown.label}`}
              chart={trendAnalysisChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsTrendAnalysisOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={trendAnalysisDropdownOptions}
                className="form_dropdown section_dropdown ml_20"
                value={trendAnalysisDropdown}
                selectedOptions={trendAnalysisDropdown}
                setStatedropdown={trendAnalysisClickHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"trend_analysis_section"}
                sectionName={`DvTrendAnalysis_Trend Analysis_${trendAnalysisDropdown.label}`}
                sectionData={trendAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {trendAnalysisChart}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom">

        {companyID === HALEON_COMPANY_ID ?

          <div id="kpi_trend_analysis_haleon" className="grid col_span_2 section_card">
            <div className="section_card_header ">
              <div className="section_info">  <h2 className="section_card_title">
                KPI Trend Analysis
                {haleonKpiTrendAnalysisDropdown && " : " + haleonKpiTrendAnalysisDropdown.label}
              </h2> <SectionDefinationsBadge
                  title={"KPI Trend Analysis"}
                  module={"campaign"}
                  platform={"Dv360"}
                  section={"video"}
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={haleonKpiTrendAnalysisOptions}
                  className="form_dropdown section_dropdown grid_margin_right w_50"
                  value={haleonKpiTrendAnalysisDropdown}
                  selectedOptions={haleonKpiTrendAnalysisDropdown}
                  setStatedropdown={haleonKpiTrendAnalysisClickHandler}
                />
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"kpi_trend_analysis_haleon"}
                  sectionName={`DateFirstFormatter_KPI Trend Analysis`}
                  sectionData={haleonKpiTrendAnalysisData}
                />
              </div>
            </div>
            <div className="grid grid_cols_1 section_card_body grid_row_gap_0">
              <div className="section_card_chart_wrapper">
                {haleonKpiTrendAnalysisLineChart}
              </div>
            </div>
          </div>
          :
          <div id="kpi_trend_analysis" className="grid col_span_2 section_card">
            <div className="section_card_header column_heading flex align_start">
              <div className="section_info">
                <h2 className="section_card_title section_title_overflow">
                  KPI Trend Analysis
                  {firstKpiTrendAnalysisDropdown && " : " + firstKpiTrendAnalysisDropdown.label}
                  {secondKpiTrendAnalysisDropdown && " : " + secondKpiTrendAnalysisDropdown.label}
                </h2>
                <SectionDefinationsBadge
                  title={"KPI Trend Analysis"}
                  module={"campaign"}
                  platform={"Dv360"}
                  section={"video"}
                />
              </div>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={firstKpiTrendAnalysisDropdownOptions}
                  className="form_dropdown section_dropdown grid_margin_right"
                  value={firstKpiTrendAnalysisDropdown}
                  selectedOptions={firstKpiTrendAnalysisDropdown}
                  setStatedropdown={firstKpiTrendAnalysisClickHandler}
                />

                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={secondKpiTrendAnalysisDropdownOptions}
                  className="form_dropdown section_dropdown"
                  value={secondKpiTrendAnalysisDropdown}
                  selectedOptions={secondKpiTrendAnalysisDropdown}
                  setStatedropdown={secondKpiTrendAnalysisClickHandler}
                />
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"kpi_trend_analysis"}
                  sectionName={`DvTrendAnalysis_KPI Trend Analysis_${secondKpiTrendAnalysisDropdown.label}`}
                  sectionData={kpiTrendAnalysisData}
                />
              </div>
            </div>
            <div className="grid grid_cols_1 section_card_body grid_row_gap_0">
              <div className="section_card_chart_wrapper">
                {kpiTrendAnalysisLineChart}
              </div>
              {Object.keys(kpiTrendAnalysisData).length !== 0 && (
                <div className="w_100 grid_margin_bottom">
                  <ul className="icz-rilist-inline">
                    <li>
                      <div className="legend-circle circleGreen"></div>{" "}
                      Above Benchmark
                    </li>
                    <li>
                      <div className="legend-circle circleRed"></div>{" "}
                      Below Benchmark
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

        }


        <div
          id="content_position_wise_distribution"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info w_50" >
              <h2 className="section_card_title w_unset">
                Content Position Wise Distribution
                {selectedContentPositionDistributionDropdownOption && " : " + selectedContentPositionDistributionDropdownOption.label}
              </h2> <SectionDefinationsBadge
                title={"Content Position Wise Distribution"}
                module={"campaign"}
                platform={"Dv360"}
                section={"video"}
              />
            </div>
            <div data-html2canvas-ignore={true} >
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={contentPositionDistributionDropdownOptions}
                className="form_dropdown section_dropdown"
                value={selectedContentPositionDistributionDropdownOption}
                selectedOptions={
                  selectedContentPositionDistributionDropdownOption
                }
                setStatedropdown={
                  contentPositionDistributionDropdownSelectionHandler
                }
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"content_position_wise_distribution"}
                sectionName={`DonutWithActiveDropdown_Content Position Wise Dist_${selectedContentPositionDistributionDropdownOption.label}`}
                sectionData={contentPositionDistribution}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {contentPositionDistributionChart}
            </div>
          </div>
        </div>
      </div>



      <div
        id="app_url_wise_distribution_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              App/Url Wise Distribution
              {appUrlDistributionDropdown && " : " + appUrlDistributionDropdown.label}
            </h2> <SectionDefinationsBadge
                title="App/Url Wise Distribution"
                module={"campaign"}
                platform={"Dv360"}
                section={"video"}
              />
            </div>
            <Modal
              open={isAppUrlDistributionOpen}
              setOpen={setIsAppUrlDistributionOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"app_url_wise_distribution_section"}
                  sectionName={companyID === HALEON_COMPANY_ID ? "AppUrl_App - Url Wise Dist" : `DateFirstActiveDropdownLabels_App - Url Wise Dist_${appUrlDistributionDropdown.label}`}
                  sectionData={appUrlDistribution}
                />
              }
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={appUrlDistributionDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={appUrlDistributionDropdown}
                  selectedOptions={appUrlDistributionDropdown}
                  setStatedropdown={appUrlDistributionClickHandler}
                />
              }
              parentId={"app_url_wise_distribution_section"}
              title={`App/Url Wise Distribution ${appUrlDistributionDropdown && " : " + appUrlDistributionDropdown.label}`}
              chart={appUrlDistributionChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsAppUrlDistributionOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={appUrlDistributionDropdownOptions}
                className="form_dropdown section_dropdown ml_20"
                value={appUrlDistributionDropdown}
                selectedOptions={appUrlDistributionDropdown}
                setStatedropdown={appUrlDistributionClickHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"app_url_wise_distribution_section"}
                sectionName={companyID === HALEON_COMPANY_ID ? "AppUrl_App - Url Wise Dist" : `DateFirstActiveDropdownLabels_App - Url Wise Dist_${appUrlDistributionDropdown.label}`}
                sectionData={appUrlDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {appUrlDistributionChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="device_wise_distribution_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header ">
            <div className="flex column align_start">
              <div className="section_info">   <h2 className="section_card_title">
                Device Wise Distribution
              </h2> <SectionDefinationsBadge
                  title="Device Wise Distribution"
                  module={"campaign"}
                  platform={"Dv360"}
                  section={"video"}
                />
              </div>
              {isDeviceWiseDistributionLoading === false && isDeviceWiseDistributionLoaded === true && (
                <div className="treemap_breadcrumb">
                  <span
                    onClick={() => {
                      setTreemapParams({
                        brand_id: BRAND_ID,
                        start_date: selectedDates.startDate,
                        end_date: selectedDates.endDate,
                        previous_start_date: selectedDates.prevStartDate,
                        previous_end_date: selectedDates.prevEndDate,
                        level: 1,
                      });
                      setTreemapDeviceType("");
                      setTreemapOS("");
                    }}
                  >
                    Home
                  </span>
                  {treemapDeviceType && (
                    <span
                      onClick={() => {
                        setTreemapParams({
                          brand_id: BRAND_ID,
                          start_date: selectedDates.startDate,
                          end_date: selectedDates.endDate,
                          previous_start_date: selectedDates.prevStartDate,
                          previous_end_date: selectedDates.prevEndDate,
                          device_type: treemapDeviceType,
                          level: 2,
                        });
                        setTreemapOS("");
                      }}
                    >
                      {" / "}
                      <TextFormatter string={treemapDeviceType} />
                    </span>
                  )}

                  {treemapOS && (
                    <span>
                      {" / "}
                      <TextFormatter string={treemapOS} />{" "}
                    </span>
                  )}
                </div>
              )}
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"device_wise_distribution_section"}
                sectionName={"DvVideoTreemap_Device Wise Distribution"}
                sectionData={deviceWiseDistribution}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            {deviceWiseDistributionSection}
          </div>
        </div>
      </div>

      <div id="audience_distri_country_city_section">
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <div className="section_info">   <h2 className="section_card_title">
                Audience Distribution
              </h2> <SectionDefinationsBadge
                  title={"Audience Distribution"}
                  module={"campaign"}
                  platform={"Dv360"}
                  section={"video"}
                />

              </div>
            </div>
          </div>
        </div>

        <div className="grid  grid_margin_bottom ">
          <Tab
            varient={"primary"}
            tabData={tabData}
            activeTab={selectedTab}
            handleCallback={(event) => handleCallback(event)}
          />
        </div>

        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          {(companyID === HALEON_COMPANY_ID && selectedTab === "By Country") && (
            <Wrapper>
              {audienceDistributionCountry}
            </Wrapper>
          )}
          {selectedTab === "By State" && (
            <Wrapper>
              {audienceDistributionState}
            </Wrapper>
          )}
          {selectedTab === "By City" && (
            <Wrapper>
              {audienceDistributionCity}
            </Wrapper>
          )}

        </div>
      </div>

      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              YouTube Ad-Type (Skippable/Non Skippable) At Ad-Group Level
            </h2>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="ad_type_wise_distribution_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info w_50">
              <h2 className="section_card_title w_unset">
                Ad Type Wise Distribution
                {selectedAdTypeDistributionDropdownOption && " : " + selectedAdTypeDistributionDropdownOption.label}
              </h2> <SectionDefinationsBadge
                title={"Ad Type Wise Distribution"}
                module={"campaign"}
                platform={"Dv360"}
                section={"video"}
              />
            </div>
            <div data-html2canvas-ignore={true} >
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={adTypeDistributionDropdownOptions}
                className="form_dropdown section_dropdown"
                value={selectedAdTypeDistributionDropdownOption}
                selectedOptions={selectedAdTypeDistributionDropdownOption}
                setStatedropdown={adTypeDistributionDropdownSelectionHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"ad_type_wise_distribution_section"}
                sectionName={`DonutWithActiveDropdown_Ad Type Wise Distribution_${selectedAdTypeDistributionDropdownOption.label}`}
                sectionData={adTypeDistribution}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {adTypeDistributionChart}
            </div>
          </div>
        </div>
        <div
          id="performance_by_ad_type_section"
          className="grid col_span_2 section_card"
        >
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info w_50">
                <h2 className="section_card_title w_unset">
                  Performance By Ad Type
                  {adTypePerformanceDropdown && " : " + adTypePerformanceDropdown.label}
                </h2><SectionDefinationsBadge
                  title="Performance By Ad Type"
                  module={"campaign"}
                  platform={"Dv360"}
                  section={"video"}
                /></div>
              <Modal
                open={isAdTypePerformanceOpen}
                setOpen={setIsAdTypePerformanceOpen}
                exportDropdown={
                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"adTypePerformance_2"}
                    sectionName={`DateFirstActiveDropdownLabels_Performance By Ad Type_${adTypePerformanceDropdown.label}`}
                    sectionData={adTypePerformance}
                  />}

                dropdown={
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={adTypePerformanceDropdownOptions}
                    className="form_dropdown section_dropdown ml_20"
                    value={adTypePerformanceDropdown}
                    selectedOptions={adTypePerformanceDropdown}
                    setStatedropdown={adTypePerformanceClickHandler}
                  />
                }
                parentId={"performance_by_ad_type_section"}
                title={`Performance By Ad Type ${adTypePerformanceDropdown && " : " + adTypePerformanceDropdown.label}`}
                chart={adTypePerformanceChart2}
              />
              <div data-html2canvas-ignore={true} >
                {isMultiLineCluster && <button onClick={() => setIsAdTypePerformanceOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                  <ExpandIcon />
                </button>}
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={adTypePerformanceDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={adTypePerformanceDropdown}
                  selectedOptions={adTypePerformanceDropdown}
                  setStatedropdown={adTypePerformanceClickHandler}
                />

                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"performance_by_ad_type_section"}
                  sectionName={`DateFirstActiveDropdownLabels_Performance By Ad Type_${adTypePerformanceDropdown.label}`}
                  sectionData={adTypePerformance}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {adTypePerformanceChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="vtr_trend_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                VTR Trend Analysis
                {vtrTrendAnalysisDropdown && " : " + vtrTrendAnalysisDropdown.label}
              </h2> <SectionDefinationsBadge
                title="VTR Trend Analysis"
                module={"campaign"}
                platform={"Dv360"}
                section={"video"}
              />
            </div>
            <Modal
              open={isVtrTrendOpen}
              setOpen={setIsVtrTrendOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"vtrTrendAnalysis_2"}
                  sectionName={`DateFirstActiveDropdownLabels_VTR Trend Analysis_${vtrTrendAnalysisDropdown.label}`}
                  sectionData={vtrTrendAnalysis}
                />}
              dropdown={
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={vtrTrendAnalysisDropdownOptions}
                  className="form_dropdown section_dropdown ml_20"
                  value={vtrTrendAnalysisDropdown}
                  selectedOptions={vtrTrendAnalysisDropdown}
                  setStatedropdown={vtrTrendAnalysisClickHandler}
                />
              }
              parentId={"vtr_trend_analysis_section"}
              title={`VTR Trend Analysis ${vtrTrendAnalysisDropdown && " : " + vtrTrendAnalysisDropdown.label}`}
              chart={vtrTrendAnalysisChart2}
            />
            <div data-html2canvas-ignore={true}>
              {isMultiLineCluster && <button onClick={() => setIsVtrTrendOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={vtrTrendAnalysisDropdownOptions}
                className="form_dropdown section_dropdown ml_20"
                value={vtrTrendAnalysisDropdown}
                selectedOptions={vtrTrendAnalysisDropdown}
                setStatedropdown={vtrTrendAnalysisClickHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"vtr_trend_analysis_section"}
                sectionName={`DateFirstActiveDropdownLabels_VTR Trend Analysis_${vtrTrendAnalysisDropdown.label}`}
                sectionData={vtrTrendAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {vtrTrendAnalysisChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="youtube_ad_type_wise_section"
        className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom"
      >
        <div className="grid col_span_4">
          <div className="section_card_header ">
            <div className="section_info long_section_info">
              <h2 className="section_card_title w_auto">
                {companyID === HALEON_COMPANY_ID ? "YouTube Ad-Type Wise Performance At Ad-Group Level" : "YouTube Ad-Type Wise Benchmarking At Ad-Group Level"}
                {firstYoutubeAdTypeDropdown && " : " + firstYoutubeAdTypeDropdown.label}
                {secondYoutubeAdTypeDropdown && " : " + secondYoutubeAdTypeDropdown.label}
              </h2>
              <SectionDefinationsBadge
                title="YouTube Ad-Type Wise Benchmarking At Ad-Group Level"
                module={"campaign"}
                platform={"Dv360"}
                section={"video"}
              />
            </div>
            <Modal
              open={isYoutubeAdTypeOpen}
              setOpen={setIsYoutubeAdTypeOpen}
              exportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"youtubeAdType_2"}
                  sectionName={companyID === HALEON_COMPANY_ID ? `DateFirstActiveDropdownLabels_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + ":" + secondYoutubeAdTypeDropdown.label}` : `DvYTAdType_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + "&" + secondYoutubeAdTypeDropdown.value}`}
                  sectionData={youtubeAdType}
                />}

              dropdown={
                <>
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={firstYoutubeAdTypeDropdownOptions}
                    className="form_dropdown section_dropdown grid_margin_right ml_20"
                    value={firstYoutubeAdTypeDropdown}
                    selectedOptions={firstYoutubeAdTypeDropdown}
                    setStatedropdown={firstYoutubeAdTypeClickHandler}
                  />
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={secondYoutubeAdTypeDropdownOptions}
                    className="form_dropdown section_dropdown w_50"
                    value={secondYoutubeAdTypeDropdown}
                    selectedOptions={secondYoutubeAdTypeDropdown}
                    setStatedropdown={secondYoutubeAdTypeClickHandler}
                  />
                </>
              }
              parentId={"youtube_ad_type_wise_section"}
              title={`${companyID === HALEON_COMPANY_ID ? "YouTube Ad-Type Wise Performance At Ad-Group Level" : "YouTube Ad-Type Wise Benchmarking At Ad-Group Level"}
              ${firstYoutubeAdTypeDropdown && " : " + firstYoutubeAdTypeDropdown.label}
              ${secondYoutubeAdTypeDropdown && " : " + secondYoutubeAdTypeDropdown.label}`}
              chart={youtubeAdTypeChart2}
            />

            <div data-html2canvas-ignore={true} className="pl_50">
              {isMultiLineCluster && <button onClick={() => setIsYoutubeAdTypeOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                <ExpandIcon />
              </button>}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={firstYoutubeAdTypeDropdownOptions}
                className="form_dropdown section_dropdown grid_margin_right ml_20"
                value={firstYoutubeAdTypeDropdown}
                selectedOptions={firstYoutubeAdTypeDropdown}
                setStatedropdown={firstYoutubeAdTypeClickHandler}
              />
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={secondYoutubeAdTypeDropdownOptions}
                className="form_dropdown section_dropdown w_50"
                value={secondYoutubeAdTypeDropdown}
                selectedOptions={secondYoutubeAdTypeDropdown}
                setStatedropdown={secondYoutubeAdTypeClickHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"youtube_ad_type_wise_section"}
                sectionName={companyID === HALEON_COMPANY_ID ? `DateFirstActiveDropdownLabels_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + ":" + secondYoutubeAdTypeDropdown.label}` : `DvYTAdType_YouTube Ad-Type - Ad-Group_${firstYoutubeAdTypeDropdown.label + "&" + secondYoutubeAdTypeDropdown.value}`}
                sectionData={youtubeAdType}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {youtubeAdTypeChart}
            </div>
          </div>
        </div>
      </div>


      {companyID === HALEON_COMPANY_ID &&
        <Wrapper>
          <div
            id="audience_distribution_male_female_section"
            className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
          >
            <div className="grid grid_cols_1">
              <div className="section_card_header">
                <div className="section_info">   <h2 className="section_card_title">
                  Youtube Ad-type Wise Demographic: {selectedDropdownOption.label}
                </h2>  <SectionDefinationsBadge
                    title={"Audience Distribution: " + selectedDropdownOption.label}
                    module={"campaign"}
                    platform={"Dv360"}
                    section={"video"}
                  /></div>

                <div data-html2canvas-ignore={true}>
                  <Dropdown
                    className={"form_dropdown section_dropdown"}
                    placeholder={"Select"}
                    isClearable={false}
                    options={audienceDistributionOptions}
                    value={selectedDropdownOption}
                    selectedOptions={selectedDropdownOption}
                    setStatedropdown={dropdownSelectionHandler}
                  />

                  <ExportDropdown
                    moduleName={pageModule}
                    subModuleName={pageSubModule}
                    platform={pagePlatform}
                    tab={pagePrimaryTab}
                    sectionId={"audience_distribution_male_female_section"}
                    sectionName={"Youtube Ad-type Demographic"}
                    sectionData={maleFemaleDataCSV}

                  />
                </div>
              </div>
            </div>
            <div className="grid grid_cols_3">
              <div className="grid col_span_1 card_border_right flex column">
                <div className="section_card_header">
                  <h2 className="section_card_title">Male</h2>
                </div>
                <div className="grid grid_cols_4 section_card_body">
                  <div className="grid col_span_1 flex column justify_center align_center">
                    {maleAudienceDistributionIcon}
                  </div>
                  <div className="grid col_span_3 section_card_chart_wrapper">
                    {maleAudienceDistribution}
                  </div>
                </div>
              </div>
              <div className="grid col_span_1 card_border_right flex column">
                <div className="grid col_span_4 section_card_header">
                  <h2 className="section_card_title">Female</h2>
                </div>
                <div className="grid grid_cols_4 section_card_body">
                  <div className="grid col_span_1 flex column justify_center align_center">
                    {femaleAudienceDistributionIcon}
                  </div>
                  <div className="grid col_span_3 section_card_chart_wrapper">
                    {femaleAudienceDistribution}
                  </div>
                </div>
              </div>
              <div className="grid col_span_1 flex column">
                <div className="grid col_span_4 section_card_header">
                  <h2 className="section_card_title">Unknown</h2>
                </div>
                <div className="grid grid_cols_4 section_card_body">
                  <div className="grid col_span_1 flex column justify_center align_center">
                    {unknownAudienceDistributionIocn}
                  </div>
                  <div className="grid col_span_3 section_card_chart_wrapper">
                    {unknownAudienceDistribution}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      }



      <Wrapper>
        <div className="section_card section_dropdown_header">
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Planned Vs Delivered
              </h2>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={dropdownOptionsMarketTg}
                  className="form_dropdown section_dropdown grid_margin_right"
                  value={selectedMarketTgDropdownOption}
                  selectedOptions={selectedMarketTgDropdownOption}
                  setStatedropdown={dropdownMainSelectionHandler}

                />
                <Dropdown
                  ismulti={false}
                  dropdownLoading={regionDropdownOptionsIsLoading}
                  placeholder={"Select"}
                  options={regionDropdownOptionsOnLoad}
                  className="form_dropdown section_dropdown"
                  value={selectedRegionDropdownOption}
                  selectedOptions={selectedRegionDropdownOption}
                  setStatedropdown={dropdownRegionSelectionHandler}

                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom">
          <div className="section_card_body">
            <div id="planned_vs_delivered_metrics_section">
              <div className="grid  section_card">
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Planned v/s Delivered Metrics Performance
                      {selectedMarketTgDropdownOption &&
                        " : " + selectedMarketTgDropdownOption.label}
                      {selectedRegionDropdownOption &&
                        " : " + selectedRegionDropdownOption.label}
                    </h2>
                    <SectionDefinationsBadge
                      title={"Planned vs Delivered Metrics Performance"}
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pagePrimaryTab}
                    />
                  </div>
                  <div data-html2canvas-ignore={true}>
                    <ExportDropdown
                      moduleName={pageModule}
                      subModuleName={pageSubModule}

                      sectionId={"planned_vs_delivered_metrics_section"}
                      sectionName={`dvBar_PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
                        } ${selectedRegionDropdownOption !== undefined &&
                          selectedRegionDropdownOption !== null &&
                          selectedRegionDropdownOption !== ""
                          ? `: ` + selectedRegionDropdownOption.label
                          : ""
                        }`}
                      sectionData={barChartCSV}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {plannedVsDeliveredMetricsBarChart}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {plannedVsDeliveredTable}
      </Wrapper>

    </Wrapper>
  );
};
export default CampaignDv360Video;
