import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import axios from "../../../../components/helpers/axios";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Datatable from "../../../../components/ui/tables/Datatable";

// CHARTS
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import BarChart from "../../../../components/ui/charts/BarChart";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

const CampaignTwitterVideo = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Twitter"
  let pagePrimaryTab = "Video"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }
  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // MID SECTION DROPDOWN
  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "video_content_starts", label: "Video Starts" },
    { value: "video_6s_views", label: "6s Video Views" },
    { value: "video_views_50", label: "50% Video Views" },
    { value: "video_views_100", label: "100% Video Views" },
    // { value: "media_engagements", label: "Media Engagements" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState(midSectionDropdonwOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Twitter", "Video", null, "Performance By KPI", e.label)

  };

  // AGE COUNT
  const [ageCount, setAgeCount] = useState([]);
  const [ageCountAllDropdownsData, setAgeCountAllDropdownsData] = useState([]);
  const [ageCountLabels, setAgeCountLabels] = useState([]);
  const [ageCountAllDropdownsDataLabels, setAgeCountAllDropdownsDataLabels] =
    useState([]);
  const [isAgeCountLoading, setAgeCountIsLoading] = useState(false);
  const [isAgeCountLoaded, setAgeCountIsLoaded] = useState(false);
  const [ageCounterror, setAgeCountError] = useState(null);

  // // GENDER COUNT
  // const [genderCount, setGenderCount] = useState([]);
  // const [genderCountAllDropdownsData, setGenderCountAllDropdownsData] = useState([]);
  // const [genderCountLabels, setGenderCountLabels] = useState([]);
  // const [genderCountAllDropdownsDataLabels, setGenderCountAllDropdownsDataLabels] = useState([]);
  // const [isGenderCountLoading, setGenderCountIsLoading] = useState(false);
  // const [isGenderCountLoaded, setGenderCountIsLoaded] = useState(false);
  // const [genderCounterror, setGenderCountError] = useState(null);

  // MALE COUNT
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [totalUnknownCount, setTotalUnknownCount] = useState(0);
  const [
    totalUnknownCountAllDropdownsData,
    setTotalUnknownCountAllDropdownsData,
  ] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // // CITY
  // const [cityData, setCityData] = useState([]);
  // const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
  // const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  // const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  // const [cityDataerror, setCityDataError] = useState(null);

  // const CityTableDataHeaders = [
  //   { name: "City", field: "name" },
  //   { name: selectedMidSectionDropdownOption.label, field: "value" },
  //   { name: "Change", field: "percentage_growth" },
  // ];

  // VIDEO VIEWS ANALYSIS
  const [videoViewsAnalysis, setVideoViewsAnalysis] = useState([]);
  const [isVideoViewsAnalysisIsLoading, setVideoViewsAnalysisIsLoading] =
    useState(false);
  const [isVideoViewsAnalysisIsLoaded, setVideoViewsAnalysisIsLoaded] =
    useState(false);
  const [videoViewsAnalysiserror, setVideoViewsAnalysisError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchAudienceDistributionAgeHandler();
    //fetchAudienceDistributionGenderHandler();
    fetchCountryHandler();
    // fetchCityHandler();
    fetchVideoViewsAnalysisHandler();
    fetchAudienceDistributionHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "video_content_starts") {

      setAgeCount(ageCountAllDropdownsData?.video_content_starts ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.video_content_starts ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.video_content_starts ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.video_content_starts ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.video_content_starts ?? []);
      setCountryData(countryAllDropdownsData?.video_content_starts ?? []);

    } else if (selectedMidSectionDropdownOption.value === "video_6s_views") {

      setAgeCount(ageCountAllDropdownsData?.video_6s_views ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.video_6s_views ?? []);
      setCountryData(countryAllDropdownsData?.video_6s_views ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.video_6s_views ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.video_6s_views ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.video_6s_views ?? []);

    } else if (selectedMidSectionDropdownOption.value === "video_views_50") {

      setAgeCount(ageCountAllDropdownsData?.video_views_50 ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.video_views_50 ?? []);
      setCountryData(countryAllDropdownsData?.video_views_50 ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.video_views_50 ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.video_views_50 ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.video_views_50 ?? []);

    } else if (selectedMidSectionDropdownOption.value === "video_views_100") {

      setAgeCount(ageCountAllDropdownsData?.video_views_100 ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.video_views_100 ?? []);
      setCountryData(countryAllDropdownsData?.video_views_100 ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.video_views_100 ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.video_views_100 ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.video_views_100 ?? []);

    } else if (selectedMidSectionDropdownOption.value === "media_engagements") {

      setAgeCount(ageCountAllDropdownsData?.media_engagements ?? []);
      setAgeCountLabels(ageCountAllDropdownsDataLabels?.media_engagements ?? []);
      setCountryData(countryAllDropdownsData?.media_engagements ?? []);
      setTotalMaleCount(totalMaleCountAllDropdownsData?.media_engagements ?? []);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData?.media_engagements ?? []);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData?.media_engagements ?? []);

    } else {
      setAgeCount([]);
      setAgeCountLabels([]);
      // setGenderCount([]);
      // setGenderCountLabels([]);
      setCountryData([]);
      // setCityData([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedIdandPlatform, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/twitter/video-campaign-metric-card/",
        config
      );
      setMetricCards(res?.data?.data ?? []);

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="twitter"
                    section="video"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: AGE
  const fetchAudienceDistributionAgeHandler = async () => {
    setAgeCountIsLoading(true);
    setAgeCountIsLoaded(false);
    setAgeCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/twitter/age-video/", config);

      setAgeCount(res?.data?.data?.video_content_starts ?? [])
      setAgeCountLabels(res?.data?.label?.video_content_starts ?? [])
      setAgeCountAllDropdownsData(res?.data?.data ?? []);
      setAgeCountAllDropdownsDataLabels(res?.data?.label ?? []);

      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(true);
      setAgeCountError(null);
    } catch (error) {
      setAgeCountAllDropdownsData([]);
      setAgeCountAllDropdownsDataLabels([]);
      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(false);
      setAgeCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let ageAudienceDistribution = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAgeCountLoaded && !isAgeCountLoading) {
    if (
      NoDataAvailableChecker(ageCount) ||
      NoDataAvailableChecker(ageCountLabels)
    ) {
      ageAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      ageAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_age"
              chartClass="section_card_chart"
              chartData={ageCount}
              chartLabels={ageCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (ageCounterror) {
    ageAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={ageCounterror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAgeCountLoading) {
    ageAudienceDistribution = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: GENDER
  /*  const fetchAudienceDistributionGenderHandler = async () => {
     setGenderCountIsLoading(true);
     setGenderCountIsLoaded(false);
     setGenderCountError(null);
 
     const config = {
       params: {
         brand_id: BRAND_ID,
         start_date: selectedDates.startDate,
         end_date: selectedDates.endDate,
         previous_start_date: selectedDates.prevStartDate,
         previous_end_date: selectedDates.prevEndDate,
         objective: selectedObjectives,
        platform_campaign:selectedIdandPlatform, 
         platform: selectedPlatform,
         campaign: selectedCampaigns,
           campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
       },
       headers: {
         "Content-Type": "application/json",
         Authorization: "Bearer " + authCtx.token,
       },
     };
 
     try {
       const res = await axios.get("performance/twitter/gender-video/", config);
       setGenderCount(
         res.data.length === 0 || res.data === undefined ? [] :
         res.data.data.length === 0 || res.data.data === undefined ? [] : 
         res.data.data.video_content_starts.length === 0 || res.data.data.video_content_starts === undefined ? [] : 
         res.data.data.video_content_starts
       )
  
 
       setGenderCountLabels(
         res.data.length === 0 || res.data === undefined ? [] :
         res.data.label.length === 0 || res.data.label === undefined ? [] : 
         res.data.label.video_content_starts.length === 0 || res.data.label.video_content_starts === undefined ? [] : 
         res.data.label.video_content_starts
       )
   
       setGenderCountAllDropdownsData(res.data.length === 0 || res.data === undefined ? [] : 
         res.data.data.length === 0 || res.data.data === undefined ? [] : res.data.data);
     
  
       setGenderCountAllDropdownsDataLabels(res.data.length === 0 || res.data === undefined ? [] : 
         res.data.label.length === 0 || res.data.label === undefined ? [] : res.data.label);
     
   
 
       setGenderCountIsLoading(false);
       setGenderCountIsLoaded(true);
       setGenderCountError(null);
     } catch (error) {
       setGenderCountAllDropdownsData([]);
       setGenderCountAllDropdownsDataLabels([]);
       setGenderCountIsLoading(false);
       setGenderCountIsLoaded(false);
       setGenderCountError(error.code);
     }
   };
   let genderAudienceDistribution = (
     <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>
   );
 
   // IF DATA IS AVAILABLE
   if (isGenderCountLoaded && !isGenderCountLoading) {
     if (
       NoDataAvailableChecker(genderCount) ||
       NoDataAvailableChecker(genderCountLabels)
     ) {
       genderAudienceDistribution = (
         <Wrapper>  <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
       );
     } else {
       genderAudienceDistribution = (
         <Wrapper> 
            <ErrorBoundary chartType="barChartType">
           <BarChart
             chartId="audience_female"
             chartClass="section_card_chart"
             chartData={genderCount}
             chartLabels={genderCountLabels}
             showLegend={false}
             
             showVerticalLabels={true}
             minimumGridDistance={0}
           />
         </ErrorBoundary>
         </Wrapper>
       );
     }
   }
 
   // IF THERE IS SOME ERROR FROM SERVER
   if (genderCounterror) {
     genderAudienceDistribution = (
       <Wrapper> <ServerErrorsLoader chartType="barChartType" error={genderCounterror} /></Wrapper>
     );
   }
 
   // IF DATA LOADING
   if (isGenderCountLoading) {
     genderAudienceDistribution =  <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
   } */

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE / UNKNOWN

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/twitter/gender-video/", config);

      setTotalMaleCount(res?.data?.male_count?.impressions ?? [])
      setTotalFemaleCount(res?.data?.female_count?.impressions ?? [])
      setTotalUnknownCount(res?.data?.unknown?.impressions ?? [])
      setTotalMaleCountAllDropdownsData(res?.data?.male_count ?? [])

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);


      setTotalFemaleCountAllDropdownsData(res?.data?.female_count ?? [])

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
      setTotalUnknownCountAllDropdownsData(res?.data?.unknown ?? [])

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {

      setTotalMaleCount(0);
      setTotalFemaleCount(0);
      setTotalUnknownCount(0);
      // setMaleCountAllDropdownsData([]);
      // setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCountAllDropdownsData([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      // setFemaleCountAllDropdownsData([]);
      // setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCountAllDropdownsData([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setTotalUnknownCountAllDropdownsData([]);
      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (NoDataAvailableChecker(totalMaleCount)) {
      maleAudienceDistributionIcon = (
        <Wrapper>  <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalMaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Male</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistributionIcon = (
      <Wrapper>  <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistributionIcon = (
    <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (NoDataAvailableChecker(totalFemaleCount)) {
      femaleAudienceDistributionIcon = (
        <Wrapper>  <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalFemaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Female</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          error={femaleCounterror}
          chartType="genderChartType"
        />
      </Wrapper>
    );

    // unknownAudienceDistributionIcon = (
    //   <ServerErrorsLoader error={femaleCounterror} />
    // );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // AUDIENCE DISTRIBUTION: UNKNOWN
  let unknownAudienceDistributionIcon = (
    <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (NoDataAvailableChecker(totalUnknownCount)) {
      unknownAudienceDistributionIcon = (
        <Wrapper> <NoDataAvailableLoader chartType="genderChartType" /></Wrapper>
      );
    } else {
      unknownAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalUnknownCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Others</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistributionIcon = (
      <Wrapper> <ServerErrorsLoader
        chartType="genderChartType"
        error={unknownCounterror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistributionIcon = <Wrapper> <Loader loaderType="genderLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/twitter/video-country-distribution/", config);

      setCountryData(res?.data?.data?.video_content_starts ?? []);
      setCountryAllDropdownsData(res?.data?.data ?? []);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card ">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card ">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">By Country</h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="By Country"
              hideBoxShadow={true}
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card ">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  /* // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
       platform_campaign:selectedIdandPlatform, 
        platform: selectedPlatform,
        campaign: selectedCampaigns,
          campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/social/twitter/overall/poll/", config);

      setCityData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data.video_content_starts === undefined || res.data.data.video_content_starts.length === 0 ? [] :
              res.data.data.video_content_starts
      );

      setCityAllDropdownsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityAllDropdownsData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap =  <Wrapper>  <Loader loaderType="smallTableLoader" /></Wrapper>;

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }
 */
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchVideoViewsAnalysisHandler = async () => {
    setVideoViewsAnalysisIsLoading(true);
    setVideoViewsAnalysisIsLoaded(false);
    setVideoViewsAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/twitter/video-impression-funnel-chart/",
        config
      );

      setVideoViewsAnalysis(res?.data ?? []);

      setVideoViewsAnalysisIsLoading(false);
      setVideoViewsAnalysisIsLoaded(true);
      setVideoViewsAnalysisError(null);
    } catch (error) {
      setVideoViewsAnalysis([]);
      setVideoViewsAnalysisIsLoading(false);
      setVideoViewsAnalysisIsLoaded(false);
      setVideoViewsAnalysisError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsAnalysisChart = (
    <Loader loaderType="funnelChartLoader" />
  );

  // IF DATA IS LOADED
  if (isVideoViewsAnalysisIsLoaded && !isVideoViewsAnalysisIsLoading) {
    if (NoDataAvailableChecker(videoViewsAnalysis) || videoViewsAnalysis.every((val, i, arr) => val.value === arr[0].value) === true) {
      videoViewsAnalysisChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="funnelChartType" /></Wrapper>
      );
    } else {
      videoViewsAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={videoViewsAnalysis}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewsAnalysiserror) {
    videoViewsAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={videoViewsAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoViewsAnalysisIsLoading) {
    videoViewsAnalysisChart = <Wrapper>  <Loader loaderType="funnelChartLoader" /></Wrapper>;
  }

  var cityCountryData = {
    [`CountryChartDynaLabels_Audience Distribution - Country_${selectedMidSectionDropdownOption.label.toLowerCase()}`]: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var maleFemaleDataCSV = {
    "Total Audience Count": [
      {
        [`Male - ${selectedMidSectionDropdownOption.label}`]: totalMaleCount,
        [`Female - ${selectedMidSectionDropdownOption.label}`]: totalFemaleCount,
        [`Unknown - ${selectedMidSectionDropdownOption.label}`]: totalUnknownCount,
      },
    ],
    [`AgeBarChartCampaignDynalabels_Audience Distribution - Age_${selectedMidSectionDropdownOption.label}`]: NoDataAvailableChecker(ageCount) ? [] : ageCount,
  };

  var allData = {
    ...topCardData,
    "SummaryCard_Summary Card": metricCards,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    "Video View Analysis": videoViewsAnalysis,
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    metricCards,
    videoViewsAnalysis,
    ageCount,
    totalFemaleCount,
    // cityData,
    countryData,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (videoViewsAnalysiserror !== null ? true : isVideoViewsAnalysisIsLoaded && (isVideoViewsAnalysisIsLoading === false)) &&
      (ageCounterror !== null ? true : isAgeCountLoaded && (isAgeCountLoading === false)) &&
      (maleCounterror !== null ? true : isMaleCountLoaded && (isMaleCountLoading === false)) &&
      (femaleCounterror !== null ? true : isFemaleCountLoaded && (isFemaleCountLoading === false)) &&
      (countryDataerror !== null ? true : isCountryDataLoaded && (isCountryDataLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }

  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    videoViewsAnalysiserror, isVideoViewsAnalysisIsLoaded, isVideoViewsAnalysisIsLoading,
    ageCounterror, isAgeCountLoaded, isAgeCountLoading,
    maleCounterror, isMaleCountLoaded, isMaleCountLoading,
    femaleCounterror, isFemaleCountLoaded, isFemaleCountLoading,
    countryDataerror, isCountryDataLoaded, isCountryDataLoading
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Performance By KPI
            </h2>
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={midSectionDropdonwOptions}
              className="form_dropdown section_dropdown"
              value={selectedMidSectionDropdownOption}
              selectedOptions={selectedMidSectionDropdownOption}
              setStatedropdown={midSectionDropdownSelectionHandler}
            />
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_age_gender"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">
              Audience Distribution : {selectedMidSectionDropdownOption.label}
            </h2><SectionDefinationsBadge
                title={"Audience Distribution By Gender"}
                module="campaign"
                platform="twitter"
                section="video"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_age_gender"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}

              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Age</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_chart_wrapper">
                {ageAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Gender</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_chart_wrapper">
                <div className="grid col_span_1 flex row justify_center align_center">
                  <div className="grid grid_cols_1">{maleAudienceDistributionIcon}</div>
                  <div className="grid grid_cols_1">{femaleAudienceDistributionIcon}</div>
                  <div className="grid grid_cols_1">{unknownAudienceDistributionIcon}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_place"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Audience Distribution : {selectedMidSectionDropdownOption.label}
            </h2>  <SectionDefinationsBadge
                title={"Audience Distribution By Country"}
                module="campaign"
                platform="twitter"
                section="video"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}

              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          {/* <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
          </div>
          <div className="grid grid_cols_1 section_card_body"> */}
          {countryMap}
          {/* </div> */}
          {/* <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {cityMap}
            </div>
          </div> */}
        </div>
      </div>
      <div
        id="video_views"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">  <h2 className="section_card_title">Video Views Analysis
            </h2> <SectionDefinationsBadge
                title={"Video Views Analysis"}
                module="campaign"
                platform="twitter"
                section="video"
              />
            </div>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_views"}
                sectionName={"Video Views Analysis"}
                sectionData={videoViewsAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewsAnalysisChart}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CampaignTwitterVideo;
