import { useEffect, useState, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

import Wrapper from "../../components/helpers/Wrapper";
import Slider from "../../components/ui/Slider";
import BarChart from "./charts/BarChart";
//import DefaultPostImg from "../../components/ui/tables/Datatable";
import {
  DefaultPostImage,
  DonutChartColorSet,
  DonutChartColorSet2,
  DonutChartColorSet3,
} from "../../constants/constants";

import DonutPieChart from "../../components/ui/charts/DonutPieChart";
import Button from "../../components/ui/Button";
import ComparePostCard from "../../components/ui/ComparePostCard";
import NumberFormatter from "../../utils/NumberFormatter";

import NoDataAvailableLoader from "./loaders/NoDataAvailable";
import ServerErrorsLoader from "./loaders/ServerErrosLoader";
import Loader from "./loaders/Loader";
import AuthContext from "../../store/authContext";
import axios from "../helpers/axios";
import NumberSeparator from "../../utils/NumberSeparator";
import ErrorBoundary from "../../utils/ErrorBoundary";
import LineChart from "./charts/LineChart";
import ExportDropdown from "./dropdown/ExportDropdown";
import SectionDefinationsBadge from "./SectionDefinationsBadge";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";

const CompareSalesBrands = ({
  products,
  Title,
  isPaid,
  isSales,
  social_platform,
  start_date,
  end_date,
  tabClear,
  salesBrand,
  compare_platform,
  mapZone,
  mapStateCode,
  hasExportDropdown,
  exportHeaders,
  compareType
}) => {
  // PAGE CONSTANTS
  let pageModule = "Retail Intelligence"
  let pageSubModule = "Sales"
  const [productsList, setProductsList] = useState(products);

  const [isComapreBtnDisabled, setIsComapreBtnDisabled] = useState(true);

  const [productsListTitle, setProductsListTitle] = useState("top_5");

  const [selectedProductsToCompare, setSelectedProductsToCompare] = useState(
    []
  );
  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [noOfElements, setNoOfElements] = useState(4);

  const [postIdentity, setPostIdentity] = useState([]);

  const [paidData, setPaidData] = useState([]);

  const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);
  const [viewMoreClicked, setViewMoreClicked] = useState("");

  // TO GET THE TOP POST FROM POST GRPS
  const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  let topPostStartDate;
  let topPostEndDate;

  if (topPostSelectedDate !== "") {
    topPostStartDate = topPostSelectedDate;
    topPostEndDate = topPostSelectedDate;
  } else {
    topPostStartDate = selectedDates.startDate;
    topPostEndDate = selectedDates.endDate;
  }

  // TO GET THE DATE FROM LINECHART ONCLICK
  const bulletClicksHandler = (data) => {
    setTopPostSelectedDate(data);
  };

  function formatMoney(n) {
    return (Math.round(n * 100) / 100).toLocaleString();
  }

  // const [checkedComparedItemList, setCheckedComparedItemList] = useState([]);

  const scollToRef = useRef();

  let filtered = [];
  let newSelectedProductsToCompare = [];

  // TO HANDLE THE CHECKBOX SELECTION
  const checkboxEventHandler = (childData, event) => {
    if (salesBrand === true) {
      filtered = productsList.filter((a) => a.isChecked === true);

      if (postIdentity.includes(childData.id)) {
        let index = postIdentity.indexOf(childData.id);
        postIdentity.splice(index, 1);
      } else {
        postIdentity.push(childData.id);
      }

      setPostIdentity([...postIdentity]);

      if (
        paidData.some(
          (paidData) =>
            paidData.is_company === childData.is_company &&
            paidData.id === childData.brand_id
        )
      ) {
        paidData.splice(
          paidData.findIndex((a) => a.id === childData.brand_id),
          1
        );
        setPaidData([...paidData]);
      } else {
        paidData.push({
          is_company: childData.is_company,
          id: childData.brand_id,
          name: childData.brand_name
        });
        setPaidData([...paidData]);
      }

      if (filtered.length <= 2 || event.target.checked == false) {
        event.target.checked === true
          ? (childData.isChecked = true)
          : (childData.isChecked = false);

        if (filtered.length > 1 && event.target.checked == true) {
          productsList.map(function (product) {
            product.isChecked === true
              ? (product.isDisabled = false)
              : (product.isDisabled = true);
          });
        } else if (filtered.length >= 3 && event.target.checked == false) {
          productsList.map(function (product) {
            product.isChecked === true
              ? (product.isDisabled = false)
              : (product.isDisabled = false);
          });
        }
        setProductsList([...productsList]);
      }

      // TO MAKE COMPARE BUTTON ENABLED AND DISABLED
      if (filtered.length > 0 && event.target.checked == true) {
        setIsComapreBtnDisabled(false);
      } else if (filtered.length <= 2 && event.target.checked == false) {
        setIsComapreBtnDisabled(true);
      }
    } else {
      filtered = productsList.filter((a) => a.isChecked === true);

      if (postIdentity.includes(childData.state_code)) {
        let index = postIdentity.indexOf(childData.state_code);
        postIdentity.splice(index, 1);
      } else {
        postIdentity.push(childData.state_code);
      }

      setPostIdentity([...postIdentity]);
      if (filtered.length <= 2 || event.target.checked == false) {
        event.target.checked === true
          ? (childData.isChecked = true)
          : (childData.isChecked = false);

        if (filtered.length > 1 && event.target.checked == true) {
          productsList.map(function (product) {
            product.isChecked === true
              ? (product.isDisabled = false)
              : (product.isDisabled = true);
          });
        } else if (filtered.length >= 3 && event.target.checked == false) {
          productsList.map(function (product) {
            product.isChecked === true
              ? (product.isDisabled = false)
              : (product.isDisabled = false);
          });
        }
        setProductsList([...productsList]);
      }

      // TO MAKE COMPARE BUTTON ENABLED AND DISABLED
      if (filtered.length > 0 && event.target.checked == true) {
        setIsComapreBtnDisabled(false);
      } else if (filtered.length <= 2 && event.target.checked == false) {
        setIsComapreBtnDisabled(true);
      }
    }
  };

  // FOR SHOWING TOP 5 OR ALL POSTS
  const productListHandler = () => {
    if (productsListTitle === "top_5") {
      setProductsListTitle("view_all");
      closeCompare();
    } else {
      setProductsListTitle("top_5");
      closeCompare();
    }
  };

  // TO ADD PRODUCTS IN COMPARE SECTION
  const MainCompare = () => {
    newSelectedProductsToCompare = productsList.filter(
      (a) => a.isChecked === true
    );
    fetchPaidDataHandler();
    setSelectedProductsToCompare([...newSelectedProductsToCompare]);
    setTimeout(() => {
      scollToRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 500);
  };

  // TO ADD PRODUCTS IN COMPARE SECTION
  const MainCompareGeography = () => {
    newSelectedProductsToCompare = productsList.filter(
      (a) => a.isChecked === true
    );
    fetchPaidDataHandler();
    setSelectedProductsToCompare([...newSelectedProductsToCompare]);
    setTimeout(() => {
      scollToRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 500);
  };

  /*  useEffect(() => {
     fetchPaidDataHandler();
   }, [viewMoreClicked, postIdentity]); */

  // TO SHOW ALL PRODUCTS
  const slice = productsList.slice(0, noOfElements);
  const loadMore = () => {
    if (slice.length >= productsList.length) {
      setNoOfElements(4);
    } else {
      setNoOfElements(4 + noOfElements);
    }
  };

  // TO SHOW ONLY 4 PRODUCTS IN VIEW ALL PRODUCTS
  const showLess = () => {
    setNoOfElements(4);
  };

  // TO SHOW AND HIDE VIEW MORE SECTION
  const viewMoreOnclickHandler = (viewMoreSelected) => {
    if (viewMoreClicked === viewMoreSelected) {
      setIsViewMoreClicked(false);
      setViewMoreClicked("");
    } else {
      setIsViewMoreClicked(true);
      setViewMoreClicked(viewMoreSelected);
    }
  };

  // TO CLOSE THE VIEW MORE SECTION
  const closeViewMore = () => {
    setIsViewMoreClicked(false);
    setViewMoreClicked("");
  };

  // TO CLOSE THE COMPARE SECTION
  const closeCompare = () => {
    setSelectedProductsToCompare((selectedProductsToCompare.length === 0));
    productsList.map(function (product) {
      if (product.isChecked === true) {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      } else {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      }
    });

    setPaidData([]);
    //setProductsList([...productsList]);
  };

  // TO AUTO ADD AND AUTO REMOVE PRODUCTS FROM COMPARE
  useEffect(() => {
    if (selectedProductsToCompare.length > 1) {
      MainCompare();
      MainCompareGeography();
    }
  }, [productsList]);

  const [compareParams, setCompareParams] = useState(
    {
      //brand_id: BRAND_ID,
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      previous_start_date: selectedDates.prevStartDate,
      previous_end_date: selectedDates.prevEndDate,
      // post_id: isViewMoreClicked === true ? viewMoreClicked.id : postIdentity.join(","),
      //state_code: postIdentity.join(","),
      compare: JSON.stringify(paidData),
    }
  );


  const [salesValue, setSalesValue] = useState([]);
  const [salesValueLabel, setSalesValueLabel] = useState([]);
  const [salesValueTitle, setSalesValueTitle] = useState([]);


  const [salesVolume, setSalesVolume] = useState([]);
  const [salesVolumeLabel, setSalesVolumeLabel] = useState([]);
  const [salesVolumeTitle, setSalesVolumeTitle] = useState([]);


  const [salesGrp, setSalesGrp] = useState([]);
  const [salesGrpLabel, setSalesGrpLabel] = useState([]);
  const [salesGrpTitle, setSalesGrpTitle] = useState([]);


  const [salesImpression, setSalesImpression] = useState([]);
  const [salesImpressionLabel, setSalesImpressionLabel] = useState([]);
  const [salesImpressionTitle, setSalesImpressionTitle] = useState([]);


  const [allData, setAllData] = useState([])
  const [isPaidDataLoaded, setIsPaidDataLoaded] = useState(false);
  const [isPaidDataLoading, setIsPaidDataLoading] = useState(false);
  const [paidDataError, setPaidDataError] = useState(false);

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // AGE WISE

  const fetchPaidDataHandler = async () => {
    setIsPaidDataLoading(true);
    setIsPaidDataLoaded(false);
    setPaidDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        ...(salesBrand === true ? {
          zone: mapZone,
          state_code: mapStateCode,
          compare: JSON.stringify(paidData),
        } : {
          zone: mapZone,
          state_code: postIdentity.join(","),
        }),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(`ri/sales/${compare_platform}/`, config);

      // const res = await axios.get("ri/sales/compare-states-trend-line/", config);
      setAllData(res.data)

      setSalesVolume(res.data.sales_volume[0].data);
      setSalesVolumeLabel(res.data.sales_volume[0].dimension);
      setSalesVolumeTitle(res.data.sales_volume[0].title)
      //setSalesVolumeLabel(res.data.grp.data);

      setSalesValue(res.data.sales_value[0].data);
      setSalesValueLabel(res.data.sales_value[0].dimension);
      setSalesValueTitle(res.data.sales_value[0].title)

      setSalesGrp(res.data.grp[0].data);
      setSalesGrpLabel(res.data.grp[0].dimension);
      setSalesGrpTitle(res.data.sales_volume[0].title)

      setSalesImpression(res.data.impressions[0].data);
      setSalesImpressionLabel(res.data.impressions[0].dimension);
      setSalesImpressionTitle(res.data.sales_volume[0].title)


      setIsPaidDataLoading(false);
      setIsPaidDataLoaded(true);
      setPaidDataError(null);
    } catch (error) {
      setSalesVolume([]);
      setSalesVolumeLabel([]);
      setIsPaidDataLoading(false);
      setIsPaidDataLoaded(false);
      setPaidDataError(error.code);
    }
  };


  let salesValueChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPaidDataLoaded && !isPaidDataLoading) {
    if (
      salesValue === [] ||
      salesValue === undefined ||
      salesValue.length === 0
    ) {
      salesValueChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      salesValueChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            {Object.values(salesValue).map((data, index) =>
              Object.values(salesValueLabel).map((label, num) =>
                index === num ? (
                  <Wrapper>
                    {Object.keys(data).length === 0 || label.length === 0 ? (
                      <NoDataAvailableLoader chartType="lineChartType" />
                    ) : (
                      <div
                        className="grid grid_cols_1 card_border_right"
                        key={index}
                      >
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper">
                            <ErrorBoundary chartType="lineChartType">
                              <LineChart
                                chartId={"line1" + Math.random()}
                                chartClass="section_card_chart"
                                chartData={Object.values(data)[0]}
                                chartLabels={Object.values(label)[0]}
                                showLegend={true}
                                showLabels={true}
                                showBullets={false}
                                onBulletsClick={bulletClicksHandler}
                              />
                            </ErrorBoundary>
                          </div>
                          <div className="footer_title_wrapper">
                            <div className="footer_title">{salesValueTitle[index].name}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Wrapper>
                ) : (
                  ""
                )
              )
            )}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (paidDataError) {
    salesValueChart = (
      <div className="flex justify_center col_span_4 compare_loader_height">
        <ServerErrorsLoader chartType="lineChartType" error={paidDataError} />
      </div>
    );
  }

  // IF DATA LOADING
  if (isPaidDataLoading) {
    salesValueChart = (
      <div className="flex justify_center col_span_4 compare_loader_height">
        <Loader loaderType="singleLineChartLoader" />
      </div>
    );
  }

  let salesVolumeChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPaidDataLoaded && !isPaidDataLoading) {
    if (
      salesVolume === [] ||
      salesVolume === undefined ||
      salesVolume.length === 0
    ) {
      salesVolumeChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      salesVolumeChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            {Object.values(salesVolume).map((data, index) =>
              Object.values(salesVolumeLabel).map((label, num) =>
                index === num ? (
                  <Wrapper>
                    {Object.keys(data).length === 0 || label.length === 0 ? (
                      <NoDataAvailableLoader chartType="lineChartType" />
                    ) : (
                      <div
                        className="grid grid_cols_1 card_border_right"
                        key={index}
                      >
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper">
                            <ErrorBoundary chartType="lineChartType">
                              <LineChart
                                chartId={"line1" + Math.random()}
                                chartClass="section_card_chart"
                                chartData={Object.values(data)[0]}
                                chartLabels={Object.values(label)[0]}
                                showLegend={true}
                                showLabels={true}
                                showBullets={false}
                                onBulletsClick={bulletClicksHandler}
                              />
                            </ErrorBoundary>
                          </div>
                          <div className="footer_title_wrapper">
                            <div className="footer_title">{salesVolumeTitle[index].name}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Wrapper>
                ) : (
                  ""
                )
              )
            )}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (paidDataError) {
    salesVolumeChart = (
      <div className="flex justify_center col_span_4 compare_loader_height">
        <ServerErrorsLoader chartType="lineChartType" error={paidDataError} />
      </div>
    );
  }

  // IF DATA LOADING
  if (isPaidDataLoading) {
    salesVolumeChart = (
      <div className="flex justify_center col_span_4 compare_loader_height">
        <Loader loaderType="singleLineChartLoader" />
      </div>
    );
  }

  let salesGrpChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPaidDataLoaded && !isPaidDataLoading) {
    if (salesGrp === [] || salesGrp === undefined || salesGrp.length === 0) {
      salesGrpChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      salesGrpChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            {Object.values(salesGrp).map((data, index) =>
              Object.values(salesGrpLabel).map((label, num) =>
                index === num ? (
                  <Wrapper>
                    {Object.keys(data).length === 0 || label.length === 0 ? (
                      <NoDataAvailableLoader chartType="lineChartType" />
                    ) : (
                      <div
                        className="grid grid_cols_1 card_border_right"
                        key={index}
                      >
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper">
                            <ErrorBoundary chartType="lineChartType">
                              <LineChart
                                chartId={"line1" + Math.random()}
                                chartClass="section_card_chart"
                                chartData={Object.values(data)[0]}
                                chartLabels={Object.values(label)[0]}
                                showLegend={true}
                                showLabels={true}
                                showBullets={false}
                                onBulletsClick={bulletClicksHandler}
                              />
                            </ErrorBoundary>
                          </div>
                          <div className="footer_title_wrapper">
                            <div className="footer_title">{salesGrpTitle[index].name}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Wrapper>
                ) : (
                  ""
                )
              )
            )}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (paidDataError) {
    salesGrpChart = (
      <div className="flex justify_center col_span_4 compare_loader_height">
        <ServerErrorsLoader chartType="lineChartType" error={paidDataError} />
      </div>
    );
  }

  // IF DATA LOADING
  if (isPaidDataLoading) {
    salesGrpChart = (
      <div className="flex justify_center col_span_4 compare_loader_height">
        <Loader loaderType="singleLineChartLoader" />
      </div>
    );
  }

  let salesImpressionChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPaidDataLoaded && !isPaidDataLoading) {
    if (
      salesImpression === [] ||
      salesImpression === undefined ||
      salesImpression.length === 0
    ) {
      salesImpressionChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      salesImpressionChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            {Object.values(salesImpression).map((data, index) =>
              Object.values(salesImpressionLabel).map((label, num) =>
                index === num ? (
                  <Wrapper>
                    {Object.keys(data).length === 0 || label.length === 0 ? (
                      <NoDataAvailableLoader chartType="lineChartType" />
                    ) : (
                      <div
                        className="grid grid_cols_1 card_border_right"
                        key={index}
                      >
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper">
                            <ErrorBoundary chartType="lineChartType">
                              <LineChart
                                chartId={"line1" + Math.random()}
                                chartClass="section_card_chart"
                                chartData={Object.values(data)[0]}
                                chartLabels={Object.values(label)[0]}
                                showLegend={true}
                                showLabels={true}
                                showBullets={false}
                                onBulletsClick={bulletClicksHandler}
                              />
                            </ErrorBoundary>
                          </div>
                          <div className="footer_title_wrapper">
                            <div className="footer_title">{salesImpressionTitle[index].name}</div>
                          </div>
                        </div>

                      </div>
                    )}
                  </Wrapper>
                ) : (
                  ""
                )
              )
            )}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (paidDataError) {
    salesImpressionChart = (
      <div className="flex justify_center col_span_4 compare_loader_height">
        <ServerErrorsLoader chartType="lineChartType" error={paidDataError} />
      </div>
    );
  }

  // IF DATA LOADING
  if (isPaidDataLoading) {
    salesImpressionChart = (
      <div className="flex justify_center col_span_4 compare_loader_height">
        <Loader loaderType="singleLineChartLoader" />
      </div>
    );
  }

  useEffect(() => {
    closeCompare();
    setProductsList([...productsList]);
    setPostIdentity([]);
  }, [tabClear]);


  function SalesCompareHandler(arr) {
    if (arr === undefined || null || arr.length === 0) return []


    var title = Object.values(arr).map((a) => a[0].title);
    var data = Object.values(arr).map((a) => a[0].data).flat();
    var dimension = Object.values(arr).map((a) => a[0].dimension).flat();
    var dims = dimension.flat().map((a) => Object.values(a).map((b) => b[0].value)).flat();
    var len = Object.values(data[0])[0].length;
    // var head = [...new Set(dims)];
    var head = ["GRPs", "Impressions", "Sales Value", "Sales Volume"];
    var flatData = data.flat();

    var mainArr = {};

    for (var i = 0; i < head.length; i++) {
      var arr1 = [];
      for (var j = 0; j < len; j++) {
        var obj = {};
        obj["Date"] = Object.values(flatData[0]).flat()[j].label;
        for (var k = 0; k < dims.length; k++) {
          if (dims.length === 12) {
            if (
              (k === 0 && i === 0) ||
              (k === 3 && i === 1) ||
              (k === 6 && i === 2) ||
              (k === 9 && i === 3)
            ) {
              var store =
                Object.values(data.flat()[k])[0][j] === undefined || Object.values(data.flat()[k])[0][j] === null ? 0 :
                  Object.values(data.flat()[k])[0][j][dims[k]] === undefined || Object.values(data.flat()[k])[0][j][dims[k]] === null ? 0 :
                    Object.values(data.flat()[k])[0][j][dims[k]];

              obj[title.flat()[0].name] = store === undefined || store === null ? 0 : store;
            } else if (
              (k === 1 && i === 0) ||
              (k === 4 && i === 1) ||
              (k === 7 && i === 2) ||
              (k === 10 && i === 3)
            ) {
              var store =
                Object.values(data.flat()[k])[0][j] === undefined || Object.values(data.flat()[k])[0][j] === null ? 0 :
                  Object.values(data.flat()[k])[0][j][dims[k]] === undefined || Object.values(data.flat()[k])[0][j][dims[k]] === null ? 0 :
                    Object.values(data.flat()[k])[0][j][dims[k]];


              obj[title.flat()[1].name] = store === undefined || store === null ? 0 : store;
            } else if (
              (k === 2 && i === 0) ||
              (k === 5 && i === 1) ||
              (k === 8 && i === 2) ||
              (k === 11 && i === 3)
            ) {
              var store =
                Object.values(data.flat()[k])[0][j] === undefined || Object.values(data.flat()[k])[0][j] === null ? 0 :
                  Object.values(data.flat()[k])[0][j][dims[k]] === undefined || Object.values(data.flat()[k])[0][j][dims[k]] === null ? 0 :
                    Object.values(data.flat()[k])[0][j][dims[k]];


              obj[title.flat()[2].name] = store === undefined || store === null ? 0 : store;
            }
          } else if (dims.length === 8) {
            if (
              (k === 0 && i === 0) ||
              (k === 2 && i === 1) ||
              (k === 4 && i === 2) ||
              (k === 6 && i === 3)
            ) {
              var store =
                Object.values(data.flat()[k])[0][j] === undefined || Object.values(data.flat()[k])[0][j] === null ? 0 :
                  Object.values(data.flat()[k])[0][j][dims[k]] === undefined || Object.values(data.flat()[k])[0][j][dims[k]] === null ? 0 :
                    Object.values(data.flat()[k])[0][j][dims[k]];
              obj[title.flat()[0].name] = store === undefined || store === null ? 0 : store
            } else if (
              (k === 1 && i === 0) ||
              (k === 3 && i === 1) ||
              (k === 5 && i === 2) ||
              (k === 7 && i === 3)
            ) {
              // var store = Object.values(data.flat()[k])[0][j][dims[k]]
              var store =
                Object.values(data.flat()[k])[0][j] === undefined || Object.values(data.flat()[k])[0][j] === null ? 0 :
                  Object.values(data.flat()[k])[0][j][dims[k]] === undefined || Object.values(data.flat()[k])[0][j][dims[k]] === null ? 0 :
                    Object.values(data.flat()[k])[0][j][dims[k]];
              obj[title.flat()[1].name] = store === undefined || store === null ? 0 : store;
            }
          }
        }
        arr1.push(obj);
      }
      mainArr[head[i]] = arr1;
    }


    return mainArr;
  }



  const exportProductsData = products.map(a => ({ ...a }));
  // const exportSelectedProductsData = selectedProductsToCompare.map(a => ({...a}));

  return (
    <Wrapper>
      <div className="grid grid_cols_1 section_card_header">
        {!isSales && (
          <h2 className="grid col_span_2 section_card_title">
            {productsListTitle === "top_5" && "Top Brands "}
          </h2>
        )}
        <div className="grid col_span_2 flex justify_end">
          <div
            className="btn_wrapper width_fit_content"
            style={{ width: "150px" }}
          >
            <div data-html2canvas-ignore={true} style={{ width: "100%" }}>
              {isSales === true ? (
                <Button
                  handleClick={() => MainCompare()}
                  disabled={isComapreBtnDisabled}
                  className="primary_btn"
                  type="button">
                  Compare
                </Button>
              ) : (
                <Button
                  handleClick={() => MainCompareGeography()}
                  disabled={isComapreBtnDisabled}
                  className="primary_btn"
                  type="button">
                  Compare
                </Button>
              )}
              {hasExportDropdown && (
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  sectionId={Title + social_platform}
                  sectionName={`${compareType}_Top` + Title}
                  sectionData={exportProductsData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {productsList.length > 0 ? (
        <Wrapper>
          {productsListTitle === "top_5" && (
            <div className="card_wrapper grid_padding">
              <Slider
                sliderTitle={""}
                slidestoshow={3}
                breakpoints={{
                  0: {
                    slidesPerView: "auto",
                  },
                  426: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1300: {
                    slidesPerView: 3,
                  },
                  2100: {
                    slidesPerView: 3,
                  },
                }}
              >
                {productsList.map((post, index) => (
                  <SwiperSlide key={index}>
                    <ComparePostCard
                      postData={post}
                      checkboxEventHandler={checkboxEventHandler}
                      viewMoreOnclickHandler={viewMoreOnclickHandler}
                      viewBrandInfo={true}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </div>
          )}

          {/* FOR SELECTED POSTS TO COMPARE */}
          {selectedProductsToCompare.length > 1 && (
            <Wrapper>
              <div ref={scollToRef} className="grid grid_cols_1 grid_padding grid_margin_bottom compare_sec">
                <div className="section_card fullpage_section_card grid">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">Compare Results
                      </h2>
                      <SectionDefinationsBadge
                        title={"Compare Table"}
                        module="sales compare"
                        platform="overall"
                        section="overall"
                      />
                    </div>
                    <div
                      className="smallBtn flex"
                      style={{ width: "100px" }}
                    >
                      <Button
                        handleClick={() => closeCompare()}
                        className="secondary_btn"
                        type="button"

                      >
                        Close
                      </Button>
                      {hasExportDropdown && (
                        <div data-html2canvas-ignore={true}>
                          <ExportDropdown
                            moduleName={pageModule}
                            subModuleName={pageSubModule}
                            sectionId={social_platform + Title}
                            sectionName={"Top" + Title}
                            sectionData={selectedProductsToCompare}

                            sectionChart={"topProductCompare"}
                          />
                        </div>
                      )}
                    </div>

                    {/* <div className="btn_wrapper" style={{ width: "150px" }}>
                      <Button
                        handleClick={() => closeCompare()}
                        className="secondary_btn"
                        type="button"
                      >
                        Close
                      </Button>
                    </div> */}
                  </div>
                  <div className="section_card_body brand-comp-table">
                    <div className="section_card_table_wrapper">
                      <div className="grid grid_cols_3  grid_margin_bottom">
                        {selectedProductsToCompare.map((product, index) => (
                          <div
                            key={index}
                            className="grid grid_cols_1 card_border_right"
                          >
                            <div className="table_wrapper">
                              <table className="table table_striped table_bordered wrapped-table">
                                <tbody>
                                  <tr>
                                    <td colSpan={2}>
                                      <div className="tableCard_wrap">
                                        <img
                                          src={
                                            product.brand_logo === "" ||
                                              product.brand_logo === undefined ||
                                              product.brand_logo === null
                                              ? DefaultPostImage
                                              : product.brand_logo
                                          }
                                          alt="img"
                                          onError={(e) =>
                                            (e.target.src = DefaultPostImage)
                                          }
                                          className="tableCard_img"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                  {product.metrics_data.map((metric, index) => (
                                    <tr key={index}>
                                      <td className="">
                                        <span className="bold">
                                          {metric.label}
                                        </span>
                                      </td>
                                      <td className="align_right value_td">
                                        <ErrorBoundary>
                                          {/*   <NumberSeparator
                                          number={metric.value}
                                          title={metric.label}
                                        /> */}

                                          <NumberFormatter
                                            title={metric.label}
                                            number={metric.value}
                                          />
                                        </ErrorBoundary>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ))}

                        {/*  <table className="table table_striped table_bordered">
                          <tbody>
                            <tr>
                              <td className="col_md_10">
                                <em className="bold">Image</em>
                              </td>
                              {selectedProductsToCompare.map((el, index) => (
                                <td
                                  className="col_md_2 align_right value_td"
                                  key={index}
                                >
                                  <img
                                    src={
                                      // el.image.match(
                                      //   "https://scontent.fbom23-1.fna.fbcdn.net/"
                                      // ) ||
                                      el.brand_logo === "" ||
                                        el.brand_logo === undefined ||
                                        el.brand_logo === null
                                        ? DefaultPostImage
                                        : el.brand_logo
                                    }
                                    onError={(e) => (e.target.src = DefaultPostImage)}
                                  />
                                </td>
                              ))}
                            </tr>
                            {Object.keys(
                              selectedProductsToCompare[0]["metrics_data"]
                            ).map((el, index) => (
                              <tr key={index}>
                                <td className="col-md-10">
                                  <em className="bold">
                                    {
                                      selectedProductsToCompare[0][
                                      "metrics_data"
                                      ][el]["label"]
                                    }
                                  </em>
                                </td>
                                {selectedProductsToCompare.map((info, wer) => (
                                  <td
                                    key={wer}
                                    className="col-md-2 align_right value_td"
                                  >
                                    <em>
                                      <ErrorBoundary>
                                        <NumberSeparator
                                          number={
                                            info["metrics_data"][el]["value"]
                                          }
                                          title={info["metrics_data"][el]["label"]}
                                        />
                                      </ErrorBoundary>
                                    </em>
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isSales && (
                <div className="grid grid_cols_1 grid_padding grid_margin_bottom card_border_top">
                  <div className=" compareCard_barchart grid_margin_bottom ">
                    <div className="section_card_header grid grid_cols_2">
                      <div className="section_info">
                        <h2 className="section_card_title grid_margin_bottom">
                          Sales Volume
                        </h2>
                        <SectionDefinationsBadge
                          title={"Sales Volume"}
                          module="sales compare"
                          platform="overall"
                          section="overall"
                        />
                      </div>
                      <div data-html2canvas-ignore={true} style={{ width: "100%" }}>
                        <ExportDropdown
                          moduleName={pageModule}
                          subModuleName={pageSubModule}
                          sectionId={Title + social_platform}
                          sectionName={"Top" + Title}
                          sectionData={SalesCompareHandler(allData)}
                          sectionChart={"SalesCompareChart"}
                        />
                      </div>
                    </div>
                    <div className="grid grid_cols_3">{salesVolumeChart}</div>
                  </div>

                  <div className="compareCard_barchart grid_margin_bottom ">
                    <div className="section_card_header grid grid_cols_2">
                      <div className="section_info">
                        <h2 className="section_card_title grid_margin_bottom">
                          Sales Value
                        </h2>
                        <SectionDefinationsBadge
                          title={"Sales Value"}
                          module="sales compare"
                          platform="overall"
                          section="overall"
                        />
                      </div>
                    </div>
                    <div className="grid grid_cols_3">{salesValueChart}</div>
                  </div>

                  <div className=" compareCard_barchart grid_margin_bottom ">
                    <div className="section_card_header grid grid_cols_2">
                      <div className="section_info">
                        <h2 className="section_card_title grid_margin_bottom">
                          Grp
                        </h2>
                        <SectionDefinationsBadge
                          title={"Grp"}
                          module="sales compare"
                          platform="overall"
                          section="overall"
                        />
                      </div>
                    </div>
                    <div className="grid grid_cols_3">{salesGrpChart}</div>
                  </div>

                  <div className=" compareCard_barchart grid_margin_bottom ">
                    <div className="section_card_header grid grid_cols_2">
                      <div className="section_info">
                        <h2 className="section_card_title grid_margin_bottom">
                          Impressions
                        </h2>
                        <SectionDefinationsBadge
                          title={"Impressions"}
                          module="sales compare"
                          platform="overall"
                          section="overall"
                        />
                      </div>
                    </div>
                    <div className="grid grid_cols_3">
                      {salesImpressionChart}
                    </div>
                  </div>

                  {/* <div className=" compareCard_genderSplit grid_margin_bottom card_border_top">
                    <h2 className="section_card_title grid_margin_bottom">
                      Premium & Non Premium  GRPs
                    </h2>
                    <div className="grid grid_cols_3">{gprsPnpChart}</div>
                  </div> */}
                </div>
              )}
            </Wrapper>
          )}
        </Wrapper>
      ) : (
        <div className="grid-cols-1">
          <div className="grid grid-cols-1 table-content">
            <div className="flex justify_center">
              <h4>No Data Available</h4>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CompareSalesBrands;
