import { useContext, useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../../pages/Dashboard";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import LoginPage from "../../pages/LoginPage";
import RegistrationPage from "../../pages/RegistrationPage";

import Wrapper from "./Wrapper";

import AuthContext from "../../store/authContext";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import Campaign from "../../pages/mi/campaign/Campaign";
import Social from "../../pages/mi/social/Social";
import Hashtag from "../../pages/mi/Hashtag";
import Video from "../../pages/mi/video/Video";
import Website from "../../pages/mi/website/Website";
import Compare from "../../pages/mi/compare/Compare";
import DashboardTest from "../../pages/DashboardTest";
import Influencers from "../../pages/mi/Influencers";

import TV from "../../pages/ri/TV";
// import Sales from "../../pages/ri/sales/Sales";
import RiSales from "../../pages/ri/Sales";
import CampaignAdmin from "../../pages/admin/campaign/CampaignAdmin";
import CreateBucket from "../../pages/admin/campaign/bucketLists/CreateBucket";
import { useSelector } from "react-redux";
import CokeSummary from "../../pages/CokeSummary";
import { COKE_COMPANY_ID, HALEON_COMPANY_ID, IDFC_COMPANY_ID } from "../../constants/constants";
import getBuildDate from "../../utils/BuildDate";
import packageJson from "../../../package.json";
import Orm from "../../pages/mi/socialListening/orm";
import Market from "../../pages/ri/Market";
import TVHaleon from "../../pages/ri/TVHaleon";
import MarketIDFC from "../../pages/ri/MarketIDFC";
import CrCokeSummary from "../../pages/CrCokesummary";
import SSOLoginPage from "../../pages/SSOLoginPage";
import SSOAuth from "../../pages/SSOAuth";



export default function RouteUrls() {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  // PERSISTS STATES
  let store = useSelector((state) => state);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  // SIDEBAR DATA
  const sidebarData = useSelector((state) => state.sidebar.sidebarData);

  // TO CHECK WHICH MODULE IS DEFAULT
  let defaultModule = sidebarData[0]?.code ?? "";

  var filterMI = sidebarData.filter((x) => x.code.toLowerCase() === 'mi')
  let miDefaultSubModule = filterMI[0]?.sub_module[0]?.code ?? '';



  if (
    store.campaignAdmin === undefined ||
    store.campaignModuleFilter === undefined ||
    store.date === undefined ||
    store.globalSelectedBrandIds === undefined ||
    store.riSalesBrandsCategory === undefined ||
    store.sidebar === undefined ||
    store.tvBrandsCamapaigns === undefined ||
    store.userData === undefined
  ) {
    authCtx.isLoggedIn = false
  }

  return (
    <Wrapper>
      <Routes>
        <Route path={"/login"} element={authCtx.isLoggedIn !== true ? <LoginPage /> : <Navigate to="/" />} />
        <Route path={"/enterprise/login"} element={authCtx.isLoggedIn !== true ? <SSOLoginPage /> : <Navigate to="/" />} />

        {/* <Route path={"/api/auth/login/azure/"} element={authCtx.isLoggedIn !== true ? <SSOAuth /> : <Navigate to="/" />} /> */}

        <Route path={"/register"} element={authCtx.isLoggedIn !== true ? (<RegistrationPage />) : (<Navigate to="/" />)} />

        <Route path={"/forgot-password"} element={authCtx.isLoggedIn !== true ? (<ForgotPasswordPage />) : (<Navigate to="/" />)} />

        <Route path={"/"} element={
          authCtx.isLoggedIn === true ?
            defaultModule.toLowerCase() === "home" ? companyID !== COKE_COMPANY_ID ? <Dashboard /> : <CokeSummary /> :
              defaultModule.toLowerCase() === "mi" ? <Navigate to="/mi" replace state={{ from: location }} /> :
                defaultModule.toLowerCase() === "ri" ? <Navigate to="/ri" replace state={{ from: location }} /> :
                  <Dashboard /> :
            <Navigate to="/login" />
        } />

        <Route path={"/mi"} >
          <Route path={"/mi"} exact index={true} element={authCtx.isLoggedIn === true ? <Navigate to={`/mi/${miDefaultSubModule}`} replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />

          <Route path={"campaigns"} index={true} element={authCtx.isLoggedIn === true ? (<Campaign />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"social"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)}>
            <Route path={":platform"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
          </Route>

          <Route path={"video"} index={true} element={authCtx.isLoggedIn === true ? (<Video />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"hashtag"} index={true} element={authCtx.isLoggedIn === true ? (<Hashtag />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"compare"} index={true} element={authCtx.isLoggedIn === true ? (<Compare />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"website"} index={true} element={authCtx.isLoggedIn === true ? (<Website />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"influencers"} index={true} element={authCtx.isLoggedIn === true ? (<Influencers />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"tv"} index={true} element={authCtx.isLoggedIn === true ? companyID === HALEON_COMPANY_ID ? <TVHaleon /> : <TV /> : <Navigate to="/login" />} />

          <Route path={"social-listening"} index={true} element={authCtx.isLoggedIn === true ? <Orm /> : <Navigate to="/login" />} />
        </Route>

        <Route path={"/ri"} >
          {companyID === IDFC_COMPANY_ID || companyID === HALEON_COMPANY_ID ?
            <Route path={"/ri"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/ri/market" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
            :
            <Route path={"/ri"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/ri/sales" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
          }
          {/* <Route path={"/ri"} index={true} element={authCtx.isLoggedIn === true ? companyID === IDFC_COMPANY_ID ? <Navigate to="/ri/market" replace state={{ from: location }} /> : <Navigate to="/ri/sales" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} /> */}

          <Route path={"market"} index={true} element={authCtx.isLoggedIn === true ? companyID === IDFC_COMPANY_ID ? <MarketIDFC /> : <Market /> : <Navigate to="/login" />} />

          <Route path={"sales"} index={true} element={authCtx.isLoggedIn === true ? <RiSales /> : <Navigate to="/login" replace state={{ from: location }} />} />

          {/* <Route path={"tv"} index={true} element={authCtx.isLoggedIn === true ? companyID === HALEON_COMPANY_ID ? <TVHaleon /> : <TV /> : <Navigate to="/login" />} /> */}
          {/* <Route path={"market"} index={true} element={authCtx.isLoggedIn === true ? <Market /> : <Navigate to="/login" />} /> */}


          {/* <Route path={"/ri/sales/test"} index={true} element={authCtx.isLoggedIn === true ? (<RiSales />) : (<Navigate to="/login" replace state={{ from: location }} />)} /> */}
        </Route>

        {/*   <Route path={"/sl"} >
          <Route path={"/sl"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/sl/orm" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />
          <Route path={"/sl/orm"} index={true} element={authCtx.isLoggedIn === true ? <Orm /> : <Navigate to="/login" replace state={{ from: location }} />} />

          </Route> */}



        <Route path={"/admin"} >
          <Route path={"/admin"} index={true} element={authCtx.isLoggedIn === true ? (<Navigate to="/admin/campaign" replace state={{ from: location }} />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign"} index={true} element={authCtx.isLoggedIn === true ? (<CampaignAdmin />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign/create-bucket"} index={true} element={authCtx.isLoggedIn === true ? (<CreateBucket />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign/edit-bucket/:bucketId"} index={true} element={authCtx.isLoggedIn === true ? (<CreateBucket />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
        </Route>

        <Route path={"/test"} element={authCtx.isLoggedIn === true ? (<DashboardTest />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        <Route path={"/dev"} element={authCtx.isLoggedIn === true ? 'Build date: ' + getBuildDate(packageJson.buildDate) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        <Route path="*" element={<Navigate to="/" replace state={{ from: location }} />} />

      </Routes>
    </Wrapper >
  );
}
