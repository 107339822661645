import React, { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

//STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Slider from "../../../../components/ui/Slider";
import Card from "../../../../components/ui/Card";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import BarChart from "../../../../components/ui/charts/BarChart";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Hashtag_Visit, IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

import PartitionedCombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import CombinedBarLineChart from "../../../../components/ui/charts/CombinedBarLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";

const SocialTwitterPostOrganic = ({ pageDropdownObject, topCardData }) => {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Social"
  let pagePlatform = "Twitter"
  let pagePrimaryTab = "Post"
  let pageSecondaryTab = "Organic"


  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setExportPageName, setIsExportDisabled } = useContext(
    FullPageExportContext
  );

  let tweetTypeDropdown = pageDropdownObject.length === 0 ? "" : " : " + pageDropdownObject.label
  let pageDropdown = pageDropdownObject.length === 0 ? null : pageDropdownObject.value

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // const PostType = "Organic";

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();
  // const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // POST IMPRESSIONS
  const [postImpressions, setPostImpressions] = useState([]);
  const [postImpressionsDataLabels, setPostImpressionsDataLabels] = useState(
    []
  );
  const [isPostImpressionsLoading, setPostImpressionsIsLoading] =
    useState(false);
  const [isPostImpressionsLoaded, setPostImpressionsIsLoaded] = useState(false);
  const [postImpressionserror, setPostImpressionsError] = useState(null);

  // VIDEO VIEWS
  const [videoViews, setVideoViews] = useState([]);
  const [videoViewsDataLabels, setVideoViewsDataLabels] = useState([]);
  const [isVideoViewsLoading, setVideoViewsIsLoading] = useState(false);
  const [isVideoViewsLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // VIDEO VIEWS QUARTILES
  const [videoViewQuartiles, setVideoViewQuartiles] = useState([]);
  const [videoViewQuartilesDataLabels, setVideoViewQuartilesDataLabels] =
    useState([]);
  const [isVideoViewQuartilesLoading, setVideoViewQuartilesIsLoading] =
    useState(false);
  const [isVideoViewQuartilesLoaded, setVideoViewQuartilesIsLoaded] =
    useState(false);
  const [videoViewQuartileserror, setVideoViewQuartilesError] = useState(null);

  // POLL VOTES
  const [pollVotes, setPollVotes] = useState([]);
  const [pollVotesDataLabels, setPollVotesDataLabels] = useState([]);
  const [isPollVotesLoading, setPollVotesIsLoading] = useState(false);
  const [isPollVotesLoaded, setPollVotesIsLoaded] = useState(false);
  const [pollVoteserror, setPollVotesError] = useState(null);

  // TWEET ENGAGEMENT
  const [tweetEngagement, setTweetEngagement] = useState([]);
  const [tweetEngagementDataLabels, setTweetEngagementDataLabels] = useState(
    []
  );
  const [isTweetEngagementLoading, setTweetEngagementIsLoading] =
    useState(false);
  const [isTweetEngagementLoaded, setTweetEngagementIsLoaded] = useState(false);
  const [tweetEngagementerror, setTweetEngagementError] = useState(null);

  const [tweetEngagementAllData, setTweetEngagementAllData] = useState([]);
  const [tweetEngagementAllLabels, setTweetEngagementAllLabels] = useState([]);

  // DROPDOWN OPTIONS
  const tweetEngagementOptions = [
    { value: "typewise", label: "Typewise" },
    { value: "total_engagement", label: "Total" },
  ];
  //DROPDOWN STATE
  const [tweetEngagementDropdownOption, setTweetEngagementDropdownOption] =
    useState(tweetEngagementOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const tweetEngagementSelectionHandler = (e) => {
    if (e === null) {
      setTweetEngagementDropdownOption("");
    } else {
      setTweetEngagementDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Twitter", "Post", "Oraganic", "Tweet Engagement", e.label)

  };

  const FetchTweetEngagementDropdownHandler = () => {
    if (tweetEngagementDropdownOption.value === "typewise") {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    } else if (tweetEngagementDropdownOption.value === "total_engagement") {
      setTweetEngagement(tweetEngagementAllData.total_engagement);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.total_engagement);
    } else {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    }
  };

  useEffect(() => {
    FetchTweetEngagementDropdownHandler();
  }, [BRAND_ID, tweetEngagementDropdownOption, selectedDates]);
  // PROFILE CLICKS
  const [userProfileClick, setUserProfileClick] = useState([]);
  const [userProfileClickLabel, setUserProfileClickLabel] = useState([]);
  const [userProfileClickLoading, setUserProfileClickLoading] = useState(false);
  const [userProfileClickLoaded, setUserProfileClickloaded] = useState(false);
  const [userProfileClickerror, setUserProfileClickError] = useState(null);

  // TOP TWEETS

  const [topTweets, setTopTweets] = useState([]);
  // const [topTweetsLabel, setTopTweetsLabel] = useState([]);
  const [topTweetsLoading, settopTweetsLoading] = useState(false);
  const [topTweetsLoaded, setTopTweetsloaded] = useState(false);
  const [topTweetserror, setTopTweetsError] = useState(null);

  // // Top Tweets dropdown
  // const [topTweetsDropdown, setTopTweetsDropdown] = useState({
  //   value: "recency",
  //   label: "Recency",
  // });

  // // DROPDOWN OPTIONS
  // const topTweetsDropdownOptions = [
  //   { value: "recency", label: "Recency" },
  //   { value: "engagement", label: "Engagements" },
  // ];

  // const [topTweetsAllData, setTopTweetsAllData] = useState([]);

  // const topTweetsDropdownSelectionHandler = (e) => {
  //   if (e === null) {
  //     setTopTweetsDropdown("");
  //   } else {
  //     setTopTweetsDropdown(e);
  //   }
  // };

  // const fetchTopTweetSelectionHandler = () => {
  //   if (topTweetsDropdown.value === "recency") {
  //     setTopTweets(topTweetsAllData.recency);
  //   } else if (topTweetsDropdown.value === "engagement") {
  //     setTopTweets(topTweetsAllData.engagement);
  //   } else {
  //     setTopTweets(topTweetsAllData.recency);
  //   }
  // };
  // useEffect(() => {
  //   fetchTopTweetSelectionHandler();
  // }, [BRAND_ID, topTweetsDropdown, selectedDates]);

  // LOAD DATA ON FIRST PAGE LOAD OR DATE IS CHANGED
  useEffect(() => {
    // fetchHashtagListHandler();
    fetchMetricCardsHandler();
    fetchPostImpressionsChartHandler();
    fetchVideoViewQuartilesHandler();
    fetchVideoViewsChartHandler();
    fetchTweetEngagementHandler();
    fetchPollVotesHandler();
    fetchTopTweetsHandler();
    fetchUserProfileClickHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag, pageDropdown]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, pageDropdown]);

  // DROPDOWN STATE

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/hashtag/", config);
      setHashtagList(res?.data ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Social", "Twitter", "Post", "Organic", badge.hashtag)
  };

  let topTrendingHashtagsList = <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>;

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <Wrapper> <NoDataAvailableLoader chartType="hashChartType" /></Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>  <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag ? selectedHashtag : "");
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/overview/", config);
      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">

          <div className="section_info">  <h2 className="section_card_title">Summary {tweetTypeDropdown}
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary {tweetTypeDropdown}
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="twitter"
                    section="organic"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary {tweetTypeDropdown}
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary {tweetTypeDropdown}
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST IMPRESSIONS

  // TO FETCH DATA FROM API
  const fetchPostImpressionsChartHandler = async () => {
    setPostImpressionsIsLoading(true);
    setPostImpressionsIsLoaded(false);
    setPostImpressionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/organic/impressions/",
        config
      );

      setPostImpressions(res?.data?.data ?? []);
      setPostImpressionsDataLabels(res?.data?.Dimensions ?? []);
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(true);
      setPostImpressionsError(null);
    } catch (error) {
      setPostImpressions([]);
      setPostImpressionsDataLabels([]);
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(false);
      setPostImpressionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postImpressionsChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isPostImpressionsLoaded && !isPostImpressionsLoading) {
    if (
      NoDataAvailableChecker(postImpressions) ||
      NoDataAvailableChecker(postImpressionsDataLabels)
    ) {
      postImpressionsChart = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      postImpressionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="post_impressions"
              chartClass="section_card_chart"
              chartData={postImpressions}
              chartLabels={postImpressionsDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postImpressionserror) {
    postImpressionsChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={postImpressionserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPostImpressionsLoading) {
    postImpressionsChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS
  const [isVideoViewsOpen, setIsVideoViewsOpen] =
  useState(false);

let defaultVideoViewsOpenDisabledValues = [];

if (showAllLegendsinMultiLineCluster) {
  defaultVideoViewsOpenDisabledValues = [];
} else {
  defaultVideoViewsOpenDisabledValues = ["Sales Volume"];
}

const [
  videoViewsOpenDisabledValues,
  setVideoViewsOpenDisabledValues,
] = useState(defaultVideoViewsOpenDisabledValues);

  // TO FETCH DATA FROM API
  const fetchVideoViewsChartHandler = async () => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/video-views", config);

      setVideoViews(res?.data?.data ?? []);
      setVideoViewsDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.Dimensions === undefined || res.data.Dimensions.length === 0 ? [] :
            res.data.Dimensions[0].linechartLabels === undefined || res.data.Dimensions[0].linechartLabels.length === 0 ? [] :
              res.data.Dimensions[0].barchartLabels === undefined || res.data.Dimensions[0].barchartLabels.length === 0 ? [] :
                res.data.Dimensions
      );
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsDataLabels([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  let videoViewsChart2 = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isVideoViewsLoaded && !isVideoViewsLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      NoDataAvailableChecker(videoViewsDataLabels)
    ) {
      videoViewsChart = (
        <Wrapper> <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
      videoViewsChart2 = (
        <Wrapper> <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        videoViewsChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="social_twitter_video_views_1"
                chartData={videoViews}
                chartLabels={videoViewsDataLabels}
                showLegend={true}
                disabledLegendArray={videoViewsOpenDisabledValues}
                setDisabledValues={setVideoViewsOpenDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        videoViewsChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="social_twitter_video_views_2"
                chartData={videoViews}
                chartLabels={videoViewsDataLabels}
                showLegend={true}
                disabledLegendArray={videoViewsOpenDisabledValues}
                setDisabledValues={setVideoViewsOpenDisabledValues}
                showLabels={true}
                showSecondY={false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
      else{
      videoViewsChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_video_views"
              chartClass="section_card_chart"
              chartData={videoViews}
              chartLabels={videoViewsDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      />
      </Wrapper>
    );
    videoViewsChart2 = (
      <Wrapper> <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoViewsLoading) {
    videoViewsChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
    videoViewsChart2 = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video View Quartiles

  const fetchVideoViewQuartilesHandler = async () => {
    setVideoViewQuartilesIsLoading(true);
    setVideoViewQuartilesIsLoaded(false);
    setVideoViewQuartilesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/organic/total-video-quartiles/",
        config
      );

      setVideoViewQuartiles(res?.data?.data ?? []);
      setVideoViewQuartilesDataLabels(res?.data?.label_list ?? []);
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(true);
      setVideoViewQuartilesError(null);
    } catch (error) {
      setVideoViewQuartiles([]);
      setVideoViewQuartilesDataLabels([]);
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(false);
      setVideoViewQuartilesError(error.code);
    }
  };

  let videoViewQuartilesChart = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isVideoViewQuartilesLoaded && !isVideoViewQuartilesLoading) {
    if (
      NoDataAvailableChecker(videoViewQuartiles) ||
      NoDataAvailableChecker(videoViewQuartilesDataLabels)
    ) {
      videoViewQuartilesChart = (
        <Wrapper> <NoDataAvailableLoader chartType="barChartType" /></Wrapper>
      );
    } else {
      videoViewQuartilesChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="social_twitter_quartiles"
              chartClass="section_card_chart"
              chartData={videoViewQuartiles}
              chartLabels={videoViewQuartilesDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewQuartileserror) {
    videoViewQuartilesChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="barChartType"
        error={videoViewQuartileserror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoViewQuartilesLoading) {
    videoViewQuartilesChart = <Wrapper> <Loader loaderType="barChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POLL VOTES
  const [isPollVotesOpen, setIsPollVotesOpen] =
  useState(false);

let defaultPollVotesDisabledValues = [];

if (showAllLegendsinMultiLineCluster) {
  defaultPollVotesDisabledValues = [];
} else {
  defaultPollVotesDisabledValues = ["Sales Volume"];
}
const [
  pollVotesDisabledValues,
  setPollVotesDisabledValues,
] = useState(defaultPollVotesDisabledValues);


  const fetchPollVotesHandler = async () => {
    setPollVotesIsLoading(true);
    setPollVotesIsLoaded(false);
    setPollVotesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/poll/", config);

      setPollVotes(res?.data?.typewise?.data ?? []);
      setPollVotesDataLabels(res?.data?.typewise?.dimension ?? []);
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(true);
      setPollVotesError(null);
    } catch (error) {
      setPollVotes([]);
      setPollVotesDataLabels([]);
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(false);
      setPollVotesError(error.code);
    }
  };

  let pollVotesChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  let pollVotesChart2 = <Wrapper><Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isPollVotesLoaded && !isPollVotesLoading) {
    if (
      NoDataAvailableChecker(pollVotes) ||
      NoDataAvailableChecker(pollVotesDataLabels)
    ) {
      pollVotesChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
      pollVotesChart2 = (
        <Wrapper>  <NoDataAvailableLoader chartType="combinedBarLineChartType" /></Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        pollVotesChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="social_twitter_poll_votes_1"
                chartData={pollVotes}
                chartLabels={pollVotesDataLabels}
                showLegend={true}
                disabledLegendArray={pollVotesDisabledValues}
                setDisabledValues={setPollVotesDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        pollVotesChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="social_twitter_poll_votes_2"
                chartData={pollVotes}
                chartLabels={pollVotesDataLabels}
                showLegend={true}
                disabledLegendArray={pollVotesDisabledValues}
                setDisabledValues={setPollVotesDisabledValues}
                showLabels={true}
                showSecondY={false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
      pollVotesChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_poll_votes"
              chartClass="section_card_chart"
              chartData={pollVotes}
              chartLabels={pollVotesDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pollVoteserror) {
    pollVotesChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={pollVoteserror}
        />
      </Wrapper>
    );
    pollVotesChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={pollVoteserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPollVotesLoading) {
    pollVotesChart = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
    pollVotesChart2 = <Wrapper> <Loader loaderType="combinedBarLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET ENGAGEMENT

  const fetchTweetEngagementHandler = async () => {
    setTweetEngagementIsLoading(true);
    setTweetEngagementIsLoaded(false);
    setTweetEngagementError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/engagement/", config);

      setTweetEngagement(res?.data?.data?.typewise ?? []);
      setTweetEngagementDataLabels(res?.data?.dimension?.typewise ?? []);

      setTweetEngagementAllData(res?.data?.data ?? []);
      setTweetEngagementAllLabels(res?.data?.dimension ?? []);

      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(true);
      setTweetEngagementError(null);
    } catch (error) {
      setTweetEngagementAllData([]);
      setTweetEngagementAllLabels([]);
      setTweetEngagement([]);
      setTweetEngagementDataLabels([]);
      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(false);
      setTweetEngagementError(error.code);
    }
  };

  let tweetEngagementChart = <Wrapper><Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (isTweetEngagementLoaded && !isTweetEngagementLoading) {
    if (
      NoDataAvailableChecker(tweetEngagement) ||
      NoDataAvailableChecker(tweetEngagementDataLabels)
    ) {
      tweetEngagementChart = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      tweetEngagementChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_twitter_engagement_tweet"
              chartClass="section_card_chart"
              chartData={tweetEngagement}
              chartLabels={tweetEngagementDataLabels}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetEngagementerror) {
    tweetEngagementChart = (
      <Wrapper> <ServerErrorsLoader
        chartType="lineChartType"
        error={tweetEngagementerror}
      />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTweetEngagementLoading) {
    tweetEngagementChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // USER PROFILE CLICK
  const fetchUserProfileClickHandler = async () => {
    setUserProfileClickLoading(true);
    setUserProfileClickloaded(false);
    setUserProfileClickError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/organic/profile-click/",
        config
      );

      setUserProfileClick(res?.data?.data ?? []);
      setUserProfileClickLabel(res?.data?.dimension ?? []);
      setUserProfileClickLoading(false);
      setUserProfileClickloaded(true);
      setUserProfileClickError(null);
    } catch (error) {
      setUserProfileClick([]);
      setUserProfileClickLabel([]);
      setUserProfileClickLoading(false);
      setUserProfileClickloaded(false);
      setUserProfileClickError(error.code);
    }
  };

  // User Profile clicks
  let userProfileClickChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;

  // IF DATA IS LOADED
  if (userProfileClickLoaded && !userProfileClickLoading) {
    if (
      NoDataAvailableChecker(userProfileClick) ||
      NoDataAvailableChecker(userProfileClickLabel)
    ) {
      userProfileClickChart = (
        <Wrapper> <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {
      userProfileClickChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="profile_clicks"
              chartClass="section_card_chart"
              chartData={userProfileClick}
              chartLabels={userProfileClickLabel}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (userProfileClickerror) {
    userProfileClickChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={userProfileClickerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (userProfileClickLoading) {
    userProfileClickChart = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  //  TOP TWEETS
  const fetchTopTweetsHandler = async () => {
    settopTweetsLoading(true);
    setTopTweetsloaded(false);
    setTopTweetsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/top-tweets/", config);

      setTopTweets(res?.data?.engagement ?? []);
      settopTweetsLoading(false);
      setTopTweetsloaded(true);
      setTopTweetsError(null);
    } catch (error) {
      setTopTweets([]);
      settopTweetsLoading(false);
      setTopTweetsloaded(false);
      setTopTweetsError(error.code);
    }
  };

  // Top Tweets Chart
  let topTweetsChart =
    <Wrapper>
      <div className="section_card fullpage_section_card grid">
        <div className="grid col_span_4 grid_margin_bottom section_card_header">
          <div className="section_info">    <h2 className="section_card_title">Top 5 Tweets {tweetTypeDropdown}
          </h2> <SectionDefinationsBadge
              title="Top 5 Tweets"
              module="social"
              platform="twitter"
              section="organic"
            />
          </div>
        </div>
        <div className="grid col_span_4 ">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS LOADED
  if (topTweetsLoaded && !topTweetsLoading) {
    if (NoDataAvailableChecker(topTweets)) {
      topTweetsChart =
        <Wrapper>
          <div className="section_card fullpage_section_card grid">
            <div className="grid col_span_4 grid_margin_bottom section_card_header">
              <div className="section_info">   <h2 className="section_card_title">Top 5 Tweets {tweetTypeDropdown}
              </h2> <SectionDefinationsBadge
                  title="Top 5 Tweets"
                  module="social"
                  platform="twitter"
                  section="organic"
                />
              </div>
            </div>
            <div className="grid col_span_4 ">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
    } else {
      topTweetsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="social"
              social_platform="Twitter"
              module_section="organic"
              Title="Tweets"
              products={topTweets}
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topTweetserror) {
    topTweetsChart = (

      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 grid_margin_bottom section_card_header">
            <div className="section_info">     <h2 className="section_card_title">Top 5 Tweets {tweetTypeDropdown}
            </h2> <SectionDefinationsBadge
                title="Top 5 Tweets"
                module="social"
                platform="twitter"
                section="organic"
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={topTweetserror}
            />
          </div>
        </div>

      </Wrapper>


    );
  }

  // IF DATA LOADING
  if (topTweetsLoading) {
    topTweetsChart =
      <Wrapper>
        <div className="section_card fullpage_section_card grid">
          <div className="grid col_span_4 grid_margin_bottom section_card_header">
            <div className="section_info">    <h2 className="section_card_title">Top 5 Tweets {tweetTypeDropdown}
            </h2> <SectionDefinationsBadge
                title="Top 5 Tweets"
                module="social"
                platform="twitter"
                section="organic"
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>

  }

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var tweetTypeExport = pageDropdownObject === "" ? "" : pageDropdownObject.label === undefined ? "" : ": " + pageDropdownObject.label;

  var allData = {
    ...topCardData,
    [`SummaryCardDynaLabels_Summary Card_${tweetTypeExport}`]: metricCards,
    [`DateFirstNonActiveDropdownLabels_Post Impressions_${tweetTypeExport}`]: postImpressions,
    [`DateFirstNonActiveDropdownLabels_Video Views_${tweetTypeExport}`]: combineCSV(videoViews),
    [`DonutWithActiveDropdown_Video Views Quartiles_${pageDropdownObject === "" ? "Video View Quartiles" : pageDropdownObject.label === undefined ? "Video View Quartiles" : "Video View Quartiles :" + pageDropdownObject.label}`]: videoViewQuartiles,
    "Poll Votes": pollVotes,
    [`SocialTweetEngage_Tweet Engagements_${tweetEngagementDropdownOption.label} ${tweetTypeExport}`]: tweetEngagement,
    [`DateFirstNonActiveDropdownLabels_User Profile Clicks_${tweetTypeExport}`]: userProfileClick,
    "Compare_Top 5 Tweet": topTweets,
  };

  useEffect(() => {

    setExportPageName('Social Twitter Post Organic');
    setFullPageExport(allData);

  }, [
    metricCards,
    postImpressions,
    videoViews,
    videoViewQuartiles,
    pollVotes,
    tweetEngagement,
    userProfileClick,
    topTweets,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (postImpressionserror !== null ? true : isPostImpressionsLoaded && (isPostImpressionsLoading === false)) &&
      (videoViewserror !== null ? true : isVideoViewsLoaded && (isVideoViewsLoading === false)) &&
      (videoViewQuartileserror !== null ? true : isVideoViewQuartilesLoaded && (isVideoViewQuartilesLoading === false)) &&
      (pollVoteserror !== null ? true : isPollVotesLoaded && (isPollVotesLoading === false)) &&
      (tweetEngagementerror !== null ? true : isTweetEngagementLoaded && (isTweetEngagementLoading === false)) &&
      (userProfileClickerror !== null ? true : userProfileClickLoaded && (userProfileClickLoading === false)) &&
      (topTweetserror !== null ? true : topTweetsLoaded && (topTweetsLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    postImpressionserror, isPostImpressionsLoaded, isPostImpressionsLoading,
    videoViewserror, isVideoViewsLoaded, isVideoViewsLoading,
    videoViewQuartileserror, isVideoViewQuartilesLoaded, isVideoViewQuartilesLoading,
    pollVoteserror, isPollVotesLoaded, isPollVotesLoading,
    tweetEngagementerror, isTweetEngagementLoaded, isTweetEngagementLoading,
    userProfileClickerror, userProfileClickLoaded, userProfileClickLoading,
    topTweetserror, topTweetsLoaded, topTweetsLoading,
  ])


  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">Trending Hashtags {tweetTypeDropdown}
          </h2> <SectionDefinationsBadge
              title="Trending Hashtags"
              module="social"
              platform="twitter"
              section="organic"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      {metricCardsSection}
      <div
        id="post_impressions_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Post Impressions {tweetTypeDropdown}
              {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Post Impressions"
                module="social"
                platform="twitter"
                section="organic"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"post_impressions_section"}
                sectionName={`DateFirstNonActiveDropdownLabels_Post Impressions_${tweetTypeExport}`}
                sectionData={postImpressions}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {postImpressionsChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="video_views_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Video Views {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Video views"
                module="social"
                platform="twitter"
                section="organic"
              />
            </div>
            <Modal
            open={isVideoViewsOpen}
            setOpen={setIsVideoViewsOpen}
            exportDropdown={
              <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
                sectionId={
                  "social_twitter_video_views_2" //chart2 id
                }
                sectionName={`DateFirstNonActiveDropdownLabels_Video Views_${tweetTypeExport}`}
                sectionData={combineCSV(videoViews)}
              />
            }
            parentId={"video_views_section"} // div section id
            title={`Video Views  ${tweetTypeDropdown ? ` : ${tweetTypeDropdown}` : ''}  ${selectedHashtagSectionHeader ? ` : ${selectedHashtagSectionHeader}` : ''}`}
            chart={videoViewsChart2} //chart2 data
          />
            <div data-html2canvas-ignore={true}>
            {isMultiLineCluster && (
              <button
                onClick={() => setIsVideoViewsOpen(true)}
                className="icon_btn btn-small-secondary expand_btn"
              >
                <ExpandIcon />
              </button>
            )}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"video_views_section"}
                sectionName={`DateFirstNonActiveDropdownLabels_Video Views_${tweetTypeExport}`}
                sectionData={combineCSV(videoViews)}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart} </div>
          </div>
        </div>
        <div
          id="video_views_quartiles_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <div className="section_info">     <h2 className="section_card_title">
              Video Views (Quartiles) {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2><SectionDefinationsBadge
                title="Video Views (Quartiles)"
                module="social"
                platform="twitter"
                section="organic"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"video_views_quartiles_section"}
                sectionName={`DonutWithActiveDropdown_Video Views Quartiles_${pageDropdownObject === "" ? "Video View Quartiles" : pageDropdownObject.label === undefined ? "Video View Quartiles" : "Video View Quartiles :" + pageDropdownObject.label}`}
                sectionData={videoViewQuartiles}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewQuartilesChart}
            </div>
          </div>
        </div>
      </div>
      <div
        id="pull_votes_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">    <h2 className="section_card_title">
              Poll Votes {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Poll Votes"
                module="social"
                platform="twitter"
                section="organic"
              />
            </div>
            <Modal
            open={isPollVotesOpen}
            setOpen={setIsPollVotesOpen}
           
            exportDropdown={
              <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
                sectionId={
                  "social_twitter_poll_votes_2" //chart2 id
                }
                sectionName={"Poll Votes"}
              sectionData={pollVotes}
              />
            }
            parentId={"pull_votes_section"} // div section id
            title={`Poll Votes`}
            chart={pollVotesChart2} //chart2 data
          />

          <div data-html2canvas-ignore={true}>
          {isMultiLineCluster && (
              <button
                onClick={() => setIsPollVotesOpen(true)}
                className="icon_btn btn-small-secondary expand_btn"
              >
                <ExpandIcon />
              </button>
            )}
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"pull_votes_section"}
                sectionName={"Pull Votes"}
                sectionData={combineCSV(pollVotes)}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">{pollVotesChart}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="tweet_engagement_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              Tweet Engagement {tweetTypeDropdown} {" : " + tweetEngagementDropdownOption.label} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="Tweet Engagement"
                module="social"
                platform="twitter"
                section="organic"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={tweetEngagementOptions}
                className="form_dropdown section_dropdown"
                value={tweetEngagementDropdownOption}
                selectedOptions={tweetEngagementDropdownOption}
                setStatedropdown={tweetEngagementSelectionHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"tweet_engagement_section"}
                sectionName={`SocialTweetEngage_Tweet Engagements_${tweetEngagementDropdownOption.label} ${tweetTypeExport}`}
                sectionData={tweetEngagement}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetEngagementChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="user_profile_clicks_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">
              User Profile Clicks {tweetTypeDropdown} {selectedHashtagSectionHeader}
            </h2> <SectionDefinationsBadge
                title="User profile clicks"
                module="social"
                platform="twitter"
                section="organic"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                subTab={pageSecondaryTab}
                sectionId={"user_profile_clicks_section"}
                sectionName={`DateFirstNonActiveDropdownLabels_User Profile Clicks_${tweetTypeExport}`}
                sectionData={userProfileClick}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {userProfileClickChart}
              </div>
            </div>
          </div>
        </div>
      </div>


      {topTweetsChart}

    </Wrapper>
  );
};

export default SocialTwitterPostOrganic;
