import { useEffect, useState, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import { Link, useParams, useSearchParams } from "react-router-dom";
// import axioss from "axios";

// STORAGE
import AuthContext from "../store/authContext";
import FullPageExportContext from "../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../components/helpers/Wrapper";
import PageHeader from "../components/layout/pageHeader";
import axios from "../components/helpers/axios";
import Card from "../components/ui/Card";
import Slider from "../components/ui/Slider";
import ExportDropdown from "../components/ui/dropdown/ExportDropdown";
import Dropdown from "../components/ui/dropdown/Dropdown";
import { formatDateDisplay } from "../components/ui/Datepicker";
import SectionDefinationsBadge from "../components/ui/SectionDefinationsBadge";

// LOADERS
import NoDataAvailableLoader from "../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../components/ui/loaders/ServerErrosLoader";
import Loader from "../components/ui/loaders/Loader";

// CHARTS
import LineChart from "../components/ui/charts/LineChart";
import StackedColumnChart from "../components/ui/charts/StackedColumnChart";
import MultiAxisMultiLine from "../components/ui/charts/MultiAxisMultiLine";
import TraceableSankeyChart from "../components/ui/charts/TraceableSankeyChart";
import CombinedBarLineMultipleAxesChart from "../components/ui/charts/CombinedBarLineChart";
import LayeredColumnChart from "../components/ui/charts/LayeredColumnChart";

// UTILS
import ErrorBoundary from "../utils/ErrorBoundary";
import NumberFormatter from "../utils/NumberFormatter";
import NoDataAvailableChecker from "../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit } from "../utils/IAEvents";
import BarChart from "../components/ui/charts/BarChart";
import { BlueColorStart500, COKE_COMPANY_ID } from "../constants/constants";
import Datatable from "../components/ui/tables/Datatable";
import Tab from "../components/ui/Tab";
import MultiAxisBarChart from "../components/ui/charts/MultiAxisBarChart";
import PartitionedLineChart from "../components/ui/charts/PartitionedLineChart";
import DateLineChart from "../components/ui/charts/DateLineChart";
import PartitionedDateLineChart from "../components/ui/charts/PartitionedDateLineChart";
import ExpandIcon from "../assets/icons/ExpandIcon";
import Modal from "../components/ui/Modal";
import CokeCompare from "../components/ui/CokeCompare";
import PartitionedCombinedBarLineMultipleAxesChart from "../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import CombinedBarLineChart from "../components/ui/charts/CombinedBarLineChart";
import MultiBarChart from "../components/ui/charts/MultiBarChart";
import PartitionedMultiAxisBarChart from "../components/ui/charts/PartitionedMultiAxisBarChart";
import { concateTotals } from "../utils/Utils";

const CokeSummary = () => {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  let activeBrands = searchParams.get("brands");



  // PAGE CONSTANTS
  let pageModule = "Coke Summary";
  let pageSubModule = "Coke Summary";
  let pagePlatform = "Overall";
  let pageSection = "Overall";
  let pagePrimaryTab = "Overall";

  const authCtx = useContext(AuthContext);
  const {
    setFullPageExport,
    setExportPageName,
    setexportSummary,
    exportSummary,
    setIsExportDisabled
  } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  const userData = useSelector((state) => state.userData.user[0]);

  let companyID = userData?.company?.id ?? '';

  let BRAND_ID = null;
  if (
    globalSelectedBrandIds.length === 0 ||
    globalSelectedBrandIds === undefined
  ) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);
  // TO ADD PLATFORMS SEARCH QUERRY IN URL
  useEffect(() => {
    setSearchParams({
      platform: "overall",
      brands: selectedBrands.length === 0
        ? "overall"
        : [...new Set(selectedBrands.map((item) => item.value))].join(",")
    });
  }, []);

  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)

  // BRANDS DATA MIN MAX DATES
  const brandMinMaxDataDates = useSelector((state) => state.moduleData.module);

  // TO GET THE MIN AND MAX DATE OF TV DATA AVAILABLE
  let tvMinDatesArr = [];
  let tvMaxDatesArr = [];
  let tvMinDate = "";
  let tvMaxDate = "";

  if (brandMinMaxDataDates["mi"] !== undefined &&
    brandMinMaxDataDates["mi"]["tv"] !== undefined &&
    brandMinMaxDataDates["mi"]["tv"]["tv"] !== undefined
  ) {
    let tvData = Object.values(brandMinMaxDataDates["mi"]["tv"]["tv"]);

    tvData.map(
      (brand) => (
        tvMinDatesArr.push(new Date(brand.min_date)),
        tvMaxDatesArr.push(new Date(brand.max_date))
      )
    );

    tvMinDate = new Date(Math.min(...tvMinDatesArr));
    tvMaxDate = new Date(Math.max(...tvMaxDatesArr));
  }

  // TO GET THE MIN AND MAX DATE OF SALES DATA AVAILABLE
  let salesMinDatesArr = [];
  let salesMaxDatesArr = [];
  let salesMinDate = "";
  let salesMaxDate = "";

  if (brandMinMaxDataDates["ri"] !== undefined &&
    brandMinMaxDataDates["ri"]["sales"] !== undefined &&
    brandMinMaxDataDates["ri"]["sales"]["sales"] !== undefined
  ) {
    let tvData = Object.values(brandMinMaxDataDates["ri"]["sales"]["sales"]);

    tvData.map(
      (brand) => (
        salesMinDatesArr.push(new Date(brand.min_date)),
        salesMaxDatesArr.push(new Date(brand.max_date))
      )
    );

    salesMinDate = new Date(Math.min(...salesMinDatesArr));
    salesMaxDate = new Date(Math.max(...salesMaxDatesArr));
  }

  // OVERALL SUMMARY METRIC CARDS
  const [campaignSummaryData, setCampaignSummaryData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryError, setCampaignSummaryError] = useState(null);

  // META ORGANIC REACH METRIC CARDS
  const [metaOrganicReachMetricCard, setMetaOrganicReachMetricCard] = useState(
    []
  );
  const [metaOrganicReachMetricCardStatus, setMetaOrganicReachMetricCardStatus] = useState({
    isLoading: false,
    isLoaded: false,
    error: null,
  });

  // META PAID REACH METRIC CARDS
  const [metaPaidReachMetricCard, setMetaPaidReachMetricCard] = useState([]);
  const [metaPaidReachMetricCardStatus, setMetaPaidReachMetricCardStatus] = useState({
    isLoading: false,
    isLoaded: false,
    error: null,
  });

  // OVERALL SUMMARY BY IMPRESSION
  const [overallSummaryByImpressionData, setOverallSummaryByImpressionData] =
    useState([]);
  const [
    isOverallSummaryByImpressionLoading,
    setOverallSummaryByImpressionIsLoading,
  ] = useState(false);
  const [
    isOverallSummaryByImpressionLoaded,
    setOverallSummaryByImpressionIsLoaded,
  ] = useState(false);
  const [overallSummaryByImpressionError, setOverallSummaryByImpressionError] =
    useState(null);

  // OVERALL SUMMARY TABLE
  const [overallSummaryTable, setOverallSummaryTable] = useState([]);
  const [overallSummaryTableHeader, setOverallSummaryTableHeader] = useState(
    []
  );
  const [isOverallSummaryTableLoading, setOverallSummaryTableIsLoading] =
    useState(false);
  const [isOverallSummaryTableLoaded, setOverallSummaryTableIsLoaded] =
    useState(false);
  const [overallSummaryTableError, setOverallSummaryTableError] =
    useState(null);

  const [showOverallSummaryTable, setShowOverallSummaryTable] = useState(false);

  // DIGITAL CAMPAIGNS SUMMARY
  const [digitalCampaignsSummaryData, setDigitalCampaignsSummaryData] =
    useState([]);
  const [
    digitalCampaignsAllDropdownsData,
    setDigitalCampaignsSummaryAllDropdownsData,
  ] = useState([]);
  const [digitalCampaignsDataLabels, setDigitalCampaignsSummaryDataLabels] =
    useState([]);
  const [
    digitalCampaignsAllDropdownsLabels,
    setDigitalCampaignsSummaryAllDropdownsLabels,
  ] = useState([]);
  const [isDigitalCampaignsLoading, setDigitalCampaignsSummaryIsLoading] =
    useState(false);
  const [isDigitalCampaignsLoaded, setDigitalCampaignsSummaryIsLoaded] =
    useState(false);
  const [digitalCampaignsError, setDigitalCampaignsSummaryError] =
    useState(null);

  // DIGITAL CAMPAIGNS SUMMARY DETAILS
  const [digitalCampaignsSummaryDetails, setDigitalCampaignsSummaryDetails] = useState([]);
  const [digitalCampaignsSummaryDetailsAllDropdownsData, setDigitalCampaignsSummaryDetailsAllDropdownsData] = useState([]);
  const [digitalCampaignsSummaryDetailsTotal, setDigitalCampaignsSummaryDetailsTotal] = useState([]);
  const [digitalCampaignsSummaryDetailsTotalAllDropdownsData, setDigitalCampaignsSummaryDetailsTotalAllDropdownsData] = useState([]);

  // PLANNED VS ACTUAL
  /* const [plannedVSActualData, setPlannedVSActualData] = useState([]);
  const [plannedVSActualAllDropdownData, setPlannedVSActualAllDropdownData] =
    useState([]);
  const [plannedVSActualDataLabels, setPlannedVSActualDataLabels] = useState(
    []
  );
  const [
    plannedVSActualAllDropdownDataLabels,
    setPlannedVSActualAllDropdownDataLabels,
  ] = useState([]);
  const [isPlannedVSActualLoading, setPlannedVSActualIsLoading] =
    useState(false);
  const [isPlannedVSActualLoaded, setPlannedVSActualIsLoaded] = useState(false);
  const [plannedVSActualError, setPlannedVSActualError] = useState(null); */

  // PLANNED VS ACTUAL DROPDOWN
  // DROPDOWN OPTIONS
  /* const plannedVSActualDropdownOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "spends", label: "Spends" },
  ];
 */
  // INITIAL STATE
  /* const [
    selectedPlannedVSActualDropdownOption,
    setPlannedVSActualSelectedDropdownOption,
  ] = useState({ value: "impressions", label: "Impressions" });

  // DROPDOWN OPTIONS SELECTION HANDLER
  const plannedVSActualDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPlannedVSActualSelectedDropdownOption("");
    } else {
      setPlannedVSActualSelectedDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "Planned Vs Actual",
      e.label
    );
  }; */

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  /* const plannedVSActualDropdownHandler = async () => {
    if (selectedPlannedVSActualDropdownOption.value === "impressions") {
      setPlannedVSActualData(plannedVSActualAllDropdownData.impressions);
      setPlannedVSActualDataLabels(
        plannedVSActualAllDropdownDataLabels.impressions
      );
    } else if (selectedPlannedVSActualDropdownOption.value === "spends") {
      setPlannedVSActualData(plannedVSActualAllDropdownData.spends);
      setPlannedVSActualDataLabels(plannedVSActualAllDropdownDataLabels.spends);
    } else {
      setPlannedVSActualData([]);
      setPlannedVSActualDataLabels([]);
    }
  };
 */
  // TO CHANGE THE DATA WHEN DROPDOWN CHANGES
  /* useEffect(() => {
    plannedVSActualDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedPlannedVSActualDropdownOption]); */

  // Paid Reach Monthly Trendline
  const [paidReachMonthlyTrendlineData, setPaidReachMonthlyTrendlineData] =
    useState([]);
  const [
    paidReachMonthlyTrendlineAllDropdownData,
    setPaidReachMonthlyTrendlineAllDropdownData,
  ] = useState([]);
  const [
    paidReachMonthlyTrendlineDataLabels,
    setPaidReachMonthlyTrendlineDataLabels,
  ] = useState([]);
  const [
    paidReachMonthlyTrendlineAllDropdownDataLabels,
    setPaidReachMonthlyTrendlineAllDropdownDataLabels,
  ] = useState([]);
  const [
    isPaidReachMonthlyTrendlineLoading,
    setPaidReachMonthlyTrendlineIsLoading,
  ] = useState(false);
  const [
    isPaidReachMonthlyTrendlineLoaded,
    setPaidReachMonthlyTrendlineIsLoaded,
  ] = useState(false);
  const [paidReachMonthlyTrendlineError, setPaidReachMonthlyTrendlineError] =
    useState(null);

  // DROPDOWN OPTIONS
  const paidReachMonthlyTrendlineOptions = [
    { value: "dv_reach", label: "DV360" },
    { value: "meta_reach", label: "Meta" },
    { value: "meta_dv_reach", label: "DV360 + Meta" },
  ];

  // DROPDOWN STATE
  const [
    selectedPaidReachMonthlyTrendlineDropdownOption,
    setSelectedPaidReachMonthlyTrendlineDropdownOption,
  ] = useState({ value: "dv_reach", label: "DV360" });

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownPaidReachMonthlyTrendline = (e) => {
    if (e === null) {
      setSelectedPaidReachMonthlyTrendlineDropdownOption("");
    } else {
      setSelectedPaidReachMonthlyTrendlineDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "Paid Reach Monthly Trendline",
      e.label
    );
  };

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  const fetchPaidReachMonthlyTrendlineDropdownHandler = async () => {
    if (selectedPaidReachMonthlyTrendlineDropdownOption.value === "dv_reach") {
      setPaidReachMonthlyTrendlineData(paidReachMonthlyTrendlineAllDropdownData.dv_reach);
      setPaidReachMonthlyTrendlineDataLabels(paidReachMonthlyTrendlineAllDropdownDataLabels.dv_reach);
    } else if (
      selectedPaidReachMonthlyTrendlineDropdownOption.value === "meta_reach"
    ) {
      setPaidReachMonthlyTrendlineData(paidReachMonthlyTrendlineAllDropdownData.meta_reach);
      setPaidReachMonthlyTrendlineDataLabels(paidReachMonthlyTrendlineAllDropdownDataLabels.meta_reach);
    } else if (
      selectedPaidReachMonthlyTrendlineDropdownOption.value === "meta_dv_reach"
    ) {
      setPaidReachMonthlyTrendlineData(paidReachMonthlyTrendlineAllDropdownData.meta_dv_reach);
      setPaidReachMonthlyTrendlineDataLabels(paidReachMonthlyTrendlineAllDropdownDataLabels.meta_dv_reach);
    } else {
      setPaidReachMonthlyTrendlineData([]);
      setPaidReachMonthlyTrendlineDataLabels([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchPaidReachMonthlyTrendlineDropdownHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedPaidReachMonthlyTrendlineDropdownOption,
  ]);

  // SOCIAL SUMMARY
  const [socialSummaryData, setSocialSummaryData] = useState([]);
  const [socialSummaryAllDropdownData, setSocialSummaryAllDropdownData] = useState([]);
  const [socialSummaryDataLabels, setSocialSummaryDataLabels] = useState([]);
  const [socialSummaryAllDropdownDataLabels, setSocialSummaryAllDropdownDataLabels] = useState([]);
  const [isSocialSummaryLoading, setSocialSummaryIsLoading] = useState(false);
  const [isSocialSummaryLoaded, setSocialSummaryIsLoaded] = useState(false);
  const [socialSummaryError, setSocialSummaryError] = useState(null);

  // DROPDOWN OPTIONS
  const socialSummaryOptions = [
    { value: "overall", label: "Overall" },
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
  ];

  // DROPDOWN STATE
  const [selectedSocialDropdownOption, setSelectedSocialDropdownOption] =
    useState({ value: "overall", label: "Overall" });

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSocialSelectionHandler = (e) => {
    if (e === null) {
      setSelectedSocialDropdownOption("");
    } else {
      setSelectedSocialDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "Social Summary",
      e.label
    );
  };

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  const fetchSocialSummaryDropdownHandler = async () => {
    if (selectedSocialDropdownOption.value === "overall") {
      setSocialSummaryData(socialSummaryAllDropdownData.overall);
      setSocialSummaryDataLabels(socialSummaryAllDropdownDataLabels.overall);
    } else if (selectedSocialDropdownOption.value === "facebook") {
      setSocialSummaryData(socialSummaryAllDropdownData.facebook);
      setSocialSummaryDataLabels(socialSummaryAllDropdownDataLabels.facebook);
    } else if (selectedSocialDropdownOption.value === "instagram") {
      setSocialSummaryData(socialSummaryAllDropdownData.instagram);
      setSocialSummaryDataLabels(socialSummaryAllDropdownDataLabels.instagram);
    } else {
      setSocialSummaryData([]);
      setSocialSummaryDataLabels([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchSocialSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedSocialDropdownOption]);



  // VIDEO SUMMARY
  const [videoSummaryData, setVideoSummaryData] = useState([]);
  const [videoSummaryDataLabels, setVideoSummaryDataLabels] = useState([]);
  const [isVideoSummaryLoading, setVideoSummaryIsLoading] = useState(false);
  const [isVideoSummaryLoaded, setVideoSummaryIsLoaded] = useState(false);
  const [videoSummaryError, setVideoSummaryError] = useState(null);


  //VIDEO ANALYSIS
  const [videoAnalysisTableData, setVideoAnalysisTableData] = useState([]);
  const [videoAnalysisTableDataHeaders, setVideoAnalysisTableDataHaeders] = useState([]);
  const [isVideoAnalysisTableDataLoading, setVideoAnalysisTableDataIsLoading] = useState(false);
  const [isVideoAnalysisTableDataLoaded, setVideoAnalysisTableDataIsLoaded] = useState(false);
  const [videoAnalysisTableDataError, setVideoAnalysisTableDataError] = useState(null);


  // DROPDOWN OPTIONS
  const videoAnalysisOptions = [
    { value: "All VG", label: "All VG" },
    { value: "VG 0-100", label: "VG 0-100" },
    { value: "VG 100-500", label: "VG 100-500" },
    { value: "VG 500-1000", label: "VG 500-1000" },
    { value: "VG 1000+", label: "VG 1000+" },
  ];

  // DROPDOWN STATE
  const [selectedVideoAnalysisDropdownOption, setSelectedVideoAnalysisDropdownOption] =
    useState({ value: "All VG", label: "All VG" });

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownVideoAnalysisSelectionHandler = (e) => {
    if (e === null) {
      setSelectedVideoAnalysisDropdownOption("");
    } else {
      setSelectedVideoAnalysisDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "Video Analysis",
      e.label
    );
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES

  useEffect(() => {
    fetchVideoAnalysisHandler();
    fetchVideoAnalysisTableDataHandler();
  }, [selectedVideoAnalysisDropdownOption]);

  useEffect(() => {
    setSelectedVideoAnalysisDropdownOption({ value: "All VG", label: "All VG" });

  }, [BRAND_ID, selectedDates]);



  // VIDEO ANALYSIS
  const [videoAnalysisData, setVideoAnalysisData] = useState([]);
  const [videoAnalysisDataLabels, setVideoAnalysisDataLabels] = useState([]);
  const [isVideoAnalysisLoading, setVideoAnalysisIsLoading] = useState(false);
  const [isVideoAnalysisLoaded, setVideoAnalysisIsLoaded] = useState(false);
  const [videoAnalysisError, setVideoAnalysisError] = useState(null);

  // HASHTAG SUMMARY
  const [hashtagSummaryAllDropdownData, setHashtagSummaryAllDropdownData] = useState([]);
  const [hashtagSummaryAllDropdownDataLabels, setHashtagSummaryAllDropdownDataLabels] = useState([]);
  const [hashtagSummaryData, setHashtagSummaryData] = useState([]);
  const [hashtagSummaryDataTopTen, setHashtagSummaryDataTopTen] = useState([]);
  const [hashtagSummaryLabels, setHashtagSummaryDataLabels] = useState([]);
  const [isHashtagSummaryLoading, setHashtagSummaryIsLoading] = useState(false);
  const [isHashtagSummaryLoaded, setHashtagSummaryIsLoaded] = useState(false);
  const [hashtagSummaryerror, setHashtagSummaryError] = useState(null);

  // DROPDOWN OPTIONS
  const hashtagSummaryOptions = [
    { value: "engagement", label: "Engagements" },
    { value: "impressions", label: "Impressions" },
    { value: "no_of_posts", label: "No of Posts" },
  ];

  // DROPDOWN STATE
  const [selectedHashtagDropdownOption, setSelectedHashtagDropdownOption] =
    useState({ value: "engagement", label: "Engagements" });

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownHashtagSelectionHandler = (e) => {
    if (e === null) {
      setSelectedHashtagDropdownOption("");
    } else {
      setSelectedHashtagDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "Hashtag Summary",
      e.label
    );
  };

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  const fetchHashtagSummaryDropdownHandler = async () => {
    if (selectedHashtagDropdownOption.value === "engagement") {
      setHashtagSummaryData(hashtagSummaryAllDropdownData.engagement);
      setHashtagSummaryDataTopTen(
        hashtagSummaryAllDropdownData.engagement !== undefined
          ? hashtagSummaryAllDropdownData.engagement.slice(0, 10)
          : ""
      );
      setHashtagSummaryDataLabels(
        hashtagSummaryAllDropdownDataLabels.engagement
      );
      /* setHashtagSummaryTable(hashtagSummaryTableAllDropdownsData.engagement); */
    } else if (selectedHashtagDropdownOption.value === "impressions") {
      setHashtagSummaryData(hashtagSummaryAllDropdownData.impressions);
      setHashtagSummaryDataTopTen(
        hashtagSummaryAllDropdownData !== undefined
          ? hashtagSummaryAllDropdownData.impressions.slice(0, 10)
          : ""
      );
      setHashtagSummaryDataLabels(
        hashtagSummaryAllDropdownDataLabels.impressions
      );
      /* setHashtagSummaryTable(hashtagSummaryTableAllDropdownsData.impressions); */
    } else if (selectedHashtagDropdownOption.value === "no_of_posts") {
      setHashtagSummaryData(hashtagSummaryAllDropdownData.no_of_posts);
      setHashtagSummaryDataTopTen(
        hashtagSummaryAllDropdownData !== undefined
          ? hashtagSummaryAllDropdownData.no_of_posts.slice(0, 10)
          : ""
      );
      setHashtagSummaryDataLabels(
        hashtagSummaryAllDropdownDataLabels.no_of_posts
      );
      /* setHashtagSummaryTable(hashtagSummaryTableAllDropdownsData.no_of_posts); */
    } else {
      setHashtagSummaryData([]);
      setHashtagSummaryDataLabels([]);
      setHashtagSummaryDataTopTen([]);
      /* setHashtagSummaryTable([]); */
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchHashtagSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedHashtagDropdownOption]);

  // HASHTAG SUMMARY TABLE
  const [hashtagSummaryTable, setHashtagSummaryTable] = useState([]);
  const [hashtagSummaryTableHeader, setHashtagSummaryTableHeader] = useState(
    []
  );
  const [isHashtagSummaryTableLoading, setHashtagSummaryTableIsLoading] =
    useState(false);
  const [isHashtagSummaryTableLoaded, setHashtagSummaryTableIsLoaded] =
    useState(false);
  const [hashtagSummaryTableError, setHashtagSummaryTableError] =
    useState(null);

  const [showHashtagSummaryTable, setShowHashtagSummaryTable] = useState(false);

  // WEBSITE SUMMARY
  /* 	const [websiteSummaryAllDropdownData, setWebsiteSummaryAllDropdownData] =
      useState([]);
    const [
      websiteSummaryAllDropdownDataLabels,
      setWebsiteSummaryAllDropdownDataLabels,
    ] = useState([]);
    const [websiteSummaryData, setWebsiteSummaryData] = useState([]);
    const [websiteSummaryDataLabel, setWebsiteSummaryDataLabel] = useState([]);
    const [isWebsiteSummaryLoading, setWebsiteSummaryIsLoading] = useState(false);
    const [isWebsiteSummaryLoaded, setWebsiteSummaryIsLoaded] = useState(false);
    const [websiteSummaryError, setWebsiteSummaryError] = useState(null);
   */
  // DROPDOWN OPTIONS
  /* const websiteSummaryDropdownOptions = [
    { value: "bounceRate_Vs_sessions", label: "Bounce Rate & Sessions" },
    {
      value: "uniqueUsers_Vs_returningUsers",
      label: "Unique Users & Returning Users",
    },
    {
      value: "pageViews_Vs_avgTimeSpent",
      label: "Page Views & Avg Time Spent",
    },
    {
      value: "pageViews_Vs_avgSessionDuration",
      label: "Page Views & Avg Session Duration",
    },
  ]; */

  // DROPDOWN STATE
  /*	const [
        selectedWebsiteSummaryDropdownOption,
      setSelectedWebsiteSummaryDropdownOption,
    ] = useState({
      value: "bounceRate_Vs_sessions",
      label: "Bounce Rate & Sessions",
    });
   */
  // DROPDOWN OPTIONS SELECTION HANDLER
  /* const dropdownWebsiteSummarySelectionHandler = (e) => {
    if (e === null) {
      setSelectedWebsiteSummaryDropdownOption("");
    } else {
      setSelectedWebsiteSummaryDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "Website Summary",
      e.label
    );
  }; */

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  /* const fetchWebsiteSummaryDropdownHandler = async () => {
    if (
      selectedWebsiteSummaryDropdownOption.value === "bounceRate_Vs_sessions"
    ) {
      setWebsiteSummaryData(
        websiteSummaryAllDropdownData === undefined ||
          websiteSummaryAllDropdownData === null ||
          websiteSummaryAllDropdownData.length === 0
          ? []
          : websiteSummaryAllDropdownData.bounceRate_Vs_sessions ===
            undefined ||
            websiteSummaryAllDropdownData.bounceRate_Vs_sessions === null ||
            websiteSummaryAllDropdownData.bounceRate_Vs_sessions.length === 0
            ? []
            : websiteSummaryAllDropdownData.bounceRate_Vs_sessions.data ===
              undefined ||
              websiteSummaryAllDropdownData.bounceRate_Vs_sessions.data ===
              null ||
              websiteSummaryAllDropdownData.bounceRate_Vs_sessions.data.length ===
              0
              ? []
              : websiteSummaryAllDropdownData.bounceRate_Vs_sessions.data
      );
      setWebsiteSummaryDataLabel(
        websiteSummaryAllDropdownData === undefined ||
          websiteSummaryAllDropdownData === null ||
          websiteSummaryAllDropdownData.length === 0
          ? []
          : websiteSummaryAllDropdownData.bounceRate_Vs_sessions ===
            undefined ||
            websiteSummaryAllDropdownData.bounceRate_Vs_sessions === null ||
            websiteSummaryAllDropdownData.bounceRate_Vs_sessions.length === 0
            ? []
            : websiteSummaryAllDropdownData.bounceRate_Vs_sessions.Dimensions ===
              undefined ||
              websiteSummaryAllDropdownData.bounceRate_Vs_sessions.Dimensions ===
              null ||
              websiteSummaryAllDropdownData.bounceRate_Vs_sessions.Dimensions
                .length === 0
              ? []
              : websiteSummaryAllDropdownDataLabels.bounceRate_Vs_sessions
                .Dimensions
      );
    } else if (
      selectedWebsiteSummaryDropdownOption.value ===
      "uniqueUsers_Vs_returningUsers"
    ) {
      setWebsiteSummaryData(
        websiteSummaryAllDropdownData === undefined ||
          websiteSummaryAllDropdownData === null ||
          websiteSummaryAllDropdownData.length === 0
          ? []
          : websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers ===
            undefined ||
            websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers ===
            null ||
            websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers
              .length === 0
            ? []
            : websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers.data ===
              undefined ||
              websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers.data ===
              null ||
              websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers.data
                .length === 0
              ? []
              : websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers.data
      );
      setWebsiteSummaryDataLabel(
        websiteSummaryAllDropdownData === undefined ||
          websiteSummaryAllDropdownData === null ||
          websiteSummaryAllDropdownData.length === 0
          ? []
          : websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers ===
            undefined ||
            websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers ===
            null ||
            websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers
              .length === 0
            ? []
            : websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers
              .Dimensions === undefined ||
              websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers
                .Dimensions === null ||
              websiteSummaryAllDropdownData.uniqueUsers_Vs_returningUsers
                .Dimensions.length === 0
              ? []
              : websiteSummaryAllDropdownDataLabels.uniqueUsers_Vs_returningUsers
                .Dimensions
      );
    } else if (
      selectedWebsiteSummaryDropdownOption.value === "pageViews_Vs_avgTimeSpent"
    ) {
      setWebsiteSummaryData(
        websiteSummaryAllDropdownData === undefined ||
          websiteSummaryAllDropdownData === null ||
          websiteSummaryAllDropdownData.length === 0
          ? []
          : websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent ===
            undefined ||
            websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent === null ||
            websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent.length === 0
            ? []
            : websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent.data ===
              undefined ||
              websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent.data ===
              null ||
              websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent.data
                .length === 0
              ? []
              : websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent.data
      );
      setWebsiteSummaryDataLabel(
        websiteSummaryAllDropdownData === undefined ||
          websiteSummaryAllDropdownData === null ||
          websiteSummaryAllDropdownData.length === 0
          ? []
          : websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent ===
            undefined ||
            websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent === null ||
            websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent.length === 0
            ? []
            : websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent
              .Dimensions === undefined ||
              websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent
                .Dimensions === null ||
              websiteSummaryAllDropdownData.pageViews_Vs_avgTimeSpent.Dimensions
                .length === 0
              ? []
              : websiteSummaryAllDropdownDataLabels.pageViews_Vs_avgTimeSpent
                .Dimensions
      );
    } else if (
      selectedWebsiteSummaryDropdownOption.value ===
      "pageViews_Vs_avgSessionDuration"
    ) {
      setWebsiteSummaryData(
        websiteSummaryAllDropdownData === undefined ||
          websiteSummaryAllDropdownData === null ||
          websiteSummaryAllDropdownData.length === 0
          ? []
          : websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration ===
            undefined ||
            websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration ===
            null ||
            websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration
              .length === 0
            ? []
            : websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration
              .data === undefined ||
              websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration
                .data === null ||
              websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration.data
                .length === 0
              ? []
              : websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration.data
      );
      setWebsiteSummaryDataLabel(
        websiteSummaryAllDropdownData === undefined ||
          websiteSummaryAllDropdownData === null ||
          websiteSummaryAllDropdownData.length === 0
          ? []
          : websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration ===
            undefined ||
            websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration ===
            null ||
            websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration
              .length === 0
            ? []
            : websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration
              .Dimensions === undefined ||
              websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration
                .Dimensions === null ||
              websiteSummaryAllDropdownData.pageViews_Vs_avgSessionDuration
                .Dimensions.length === 0
              ? []
              : websiteSummaryAllDropdownDataLabels.pageViews_Vs_avgSessionDuration
                .Dimensions
      );
    } else {
      setWebsiteSummaryData([]);
      setWebsiteSummaryDataLabel([]);
    }
  }; */

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  /* 	useEffect(() => {
      fetchWebsiteSummaryDropdownHandler();
    }, [BRAND_ID, selectedDates, selectedWebsiteSummaryDropdownOption]);
   */
  // TV & SALES SECTION DROPDOWN

  let durationDropdownOptions = [
    { value: "week", label: "Weekly" },
    { value: "month", label: "Monthly" },
  ];

  // DROPDOWN STATE
  const [selectedDurationDropdownOption, setSelectedDurationDropdownOption] =
    useState(durationDropdownOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const durationDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDurationDropdownOption("");
    } else {
      setSelectedDurationDropdownOption(e);
    }
  };

  // GLOBAL DROPDOWN
  const [dropdownValue, setDropdownValue] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isDropdownOptionsLoading, setDropdownOptionsIsLoading] =
    useState(false);
  const [isDropdownOptionsLoaded, setDropdownOptionsIsLoaded] = useState(false);
  // DROPDOWN TOGGLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setDropdownValue("");
    } else {
      setDropdownValue(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "TV & Sales Summary",
      e.label
    );
  };

  const fetchDropdownHandler = async () => {
    setDropdownOptionsIsLoading(true);
    setDropdownOptionsIsLoaded(false);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/overall-state-filter/", config);

      res.data !== undefined &&
        res.data.map(
          (data) =>
            data.label.toLowerCase() === "india" && setDropdownValue(data)
        );

      setDropdownOptions(res?.data ?? []);

      setDropdownOptionsIsLoading(false);
      setDropdownOptionsIsLoaded(true);
    } catch (error) {
      setDropdownOptions([]);
      setDropdownValue([]);
      setDropdownOptionsIsLoading(false);
      setDropdownOptionsIsLoaded(false);

      setBrandWiseData([]);
      setBrandWiseDataLabels([]);
      setBrandWiseIsLoading(false);
      setBrandWiseIsLoaded(false);
      setBrandWiseError(error.code);

      setTrendLineAnalysis([]);
      setTrendLineAnalysisLabel([]);
      setTrendLineAnalysisIsLoading(false);
      setTrendLineAnalysisIsLoaded(false);
      setTrendLineAnalysisError(error.code);

      setTrendAnalysisTableData([]);
      setTrendAnalysisTableDataHaeders([]);
      setTrendAnalysisTableDataIsLoading(false);
      setTrendAnalysisTableDataIsLoaded(false);
      setTrendAnalysisTableDataError(error.code);

      setTvDigitalSummaryData([]);
      setTvDigitalSummaryLabel([]);
      setTvDigitalSummaryIsLoading(false);
      setTvDigitalSummaryIsLoaded(false);
      setTvDigitalSummaryError(error.code);

      setPremiumPositionGRPSData([]);
      setPremiumPositionGRPSDataLabels([]);
      setPremiumPositionGRPSIsLoading(false);
      setPremiumPositionGRPSIsLoaded(false);
      setPremiumPositionGRPSError(error.code);

      setPrimetTimeGRPSData([]);
      setPrimeTimeGRPSDataLabels([]);
      setPrimeTimeGRPSIsLoading(false);
      setPrimeTimeGRPSIsLoaded(false);
      setPrimeTimeGRPSError(error.code);
    }
  };

  useEffect(() => {
    fetchDropdownHandler();
  }, []);

  useEffect(() => {
    setSelectedBrandWiseMarketShareTrendOption(
      brandWiseMarketShareTrendOptions[0]
    );
    setSelectedDurationDropdownOption(durationDropdownOptions[0]);
  }, [BRAND_ID, selectedDates]);

  const PlannedVsDeliveredTabOptions = ["Meta", "DV360"];
  //const PlannedVsDeliveredTabOptions = ["Meta"];
  const [selectedPlannedVsDeliveredTab, setselectedPlannedVsDeliveredTab] =
    useState(PlannedVsDeliveredTabOptions[0]);
  const [unCheck, setUnCheck] = useState([false]);
  const tabsOnClickHandler = (event) => {
    setselectedPlannedVsDeliveredTab(event);
    setUnCheck(!unCheck);
  };

  // PLANNED VS DELIVERED METRICS DATA
  const [plannedVsDeliveredMetricsData, setPlannedVsDeliveredMetricsData] =
    useState([]);
  const [
    plannedVsDeliveredMetricsDataLabels,
    setPlannedVsDeliveredMetricsDataLabels,
  ] = useState([]);

  const [
    isplannedVsDeliveredMetricsLoading,
    setPlannedVsDeliveredMetricsIsLoading,
  ] = useState(false);
  const [
    isplannedVsDeliveredMetricsLoaded,
    setPlannedVsDeliveredMetricsIsLoaded,
  ] = useState(false);
  const [plannedVsDeliveredMetricsError, setPlannedVsDeliveredMetricsError] =
    useState(null);

  const [plannedVsDeliveredTableData, setPlannedVsDeliveredTableData] = useState([]);
  const [plannedVsDeliveredTableDataTotal, setPlannedVsDeliveredTableDataTotal] = useState([]);
  const [plannedVsDeliveredTableDataHeaders, setPlannedVsDeliveredTableDataHaeders,] = useState([]);
  const [isPlannedVsDeliveredTableDataLoading, setPlannedVsDeliveredTableDataIsLoading,] = useState(false);
  const [isPlannedVsDeliveredTableDataLoaded, setPlannedVsDeliveredTableDataIsLoaded,] = useState(false);
  const [plannedVsDeliveredTableDataerror, setPlannedVsDeliveredTableDataError,] = useState(null);

  let dropdownOptionsMarketTg = [
    { value: "market", label: "Market" },
    { value: "tg", label: "TG" },
  ];

  let tgDropdownOptions = [
    { value: "18-34 male female", label: "18-34 Male / Female" },
    // { value: "18-64 male female", label: "18-64 Male / Female" },
  ]

  // DROPDOWN STATE
  const [selectedMarketTgDropdownOption, setSelectedMarketTgDropdownOption] =
    useState(dropdownOptionsMarketTg[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMainSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMarketTgDropdownOption("");
    } else {
      setSelectedMarketTgDropdownOption(e);
    }
  };

  // PLANNED VS DELIVERED METRICS DATA
  const [plannedVsDeliveredMetricsDataDv, setPlannedVsDeliveredMetricsDataDv] =
    useState([]);
  const [
    plannedVsDeliveredMetricsDataLabelsDv,
    setPlannedVsDeliveredMetricsDataLabelsDv,
  ] = useState([]);

  const [
    isplannedVsDeliveredMetricsLoadingDv,
    setPlannedVsDeliveredMetricsIsLoadingDv,
  ] = useState(false);
  const [
    isplannedVsDeliveredMetricsLoadedDv,
    setPlannedVsDeliveredMetricsIsLoadedDv,
  ] = useState(false);
  const [
    plannedVsDeliveredMetricsErrorDv,
    setPlannedVsDeliveredMetricsErrorDv,
  ] = useState(null);

  const [plannedVsDeliveredTableDataDv, setPlannedVsDeliveredTableDataDv] =
    useState([]);
  const [plannedVsDeliveredTableDataHeadersDv, setPlannedVsDeliveredTableDataHaedersDv,] = useState([]);
  const [plannedVsDeliveredTableDataTotalDv, setPlannedVsDeliveredTableDataTotalDv,] = useState([]);

  const [isPlannedVsDeliveredTableDataLoadingDv, setPlannedVsDeliveredTableDataIsLoadingDv,] = useState(false);
  const [isPlannedVsDeliveredTableDataLoadedDv, setPlannedVsDeliveredTableDataIsLoadedDv,] = useState(false);
  const [plannedVsDeliveredTableDataerrorDv, setPlannedVsDeliveredTableDataErrorDv,] = useState(null);

  let dropdownOptionsMarketTgDv = [
    { value: "market", label: "Market" },
    { value: "tg", label: "TG" },
  ];

  // DROPDOWN STATE
  const [
    selectedMarketTgDropdownOptionDv,
    setSelectedMarketTgDropdownOptionDv,
  ] = useState(dropdownOptionsMarketTgDv[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMainSelectionHandlerDv = (e) => {
    if (e === null) {
      setSelectedMarketTgDropdownOptionDv("");
    } else {
      setSelectedMarketTgDropdownOptionDv(e);
    }
  };

  const [regionDropdownOptionsOnLoad, setRegionDropdownOptionsOnLoad] =
    useState([]);
  const [regionDropdownOptions, setRegionDropdownOptions] = useState([]);
  const [regionDropdownOptionsIsLoading, setRegionDropdownOptionsIsLoading] =
    useState(true);
  const [regionDropdownOptionsIsLoaded, setRegionDropdownOptionsIsLoaded] =
    useState(false);

  const [regionDropdownOptionsOnLoadDv, setRegionDropdownOptionsOnLoadDv] =
    useState([]);
  const [regionDropdownOptionsDv, setRegionDropdownOptionsDv] = useState([]);
  const [
    regionDropdownOptionsIsLoadingDv,
    setRegionDropdownOptionsIsLoadingDv,
  ] = useState(true);
  const [regionDropdownOptionsIsLoadedDv, setRegionDropdownOptionsIsLoadedDv] =
    useState(false);

  const fetchRegionDropdownHandler = async () => {
    setRegionDropdownOptionsIsLoading(true);
    setRegionDropdownOptionsIsLoaded(false);

    let getDropdownType = selectedMarketTgDropdownOption.value === 'tg' ? 'tg' : 'market';
    let getIsTg = selectedMarketTgDropdownOption.value === 'tg' ? true : false;

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        drop_down_type: getDropdownType,
        is_tg: getIsTg
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-dropdown/",
        config
      );

      const regionsDDOpt = res?.data.map((item) => ({
        label: item.planned_label,
        value: item.mapping_id
      }))

      setRegionDropdownOptions(regionsDDOpt ?? []);
      setRegionDropdownOptionsOnLoad(regionsDDOpt ?? []);
      setSelectedRegionDropdownOption(regionsDDOpt[0] ?? []);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(true);

    } catch (error) {
      setRegionDropdownOptions([]);
      setRegionDropdownOptionsIsLoading(false);
      setRegionDropdownOptionsIsLoaded(false);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);

      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  const fetchRegionDropdownHandlerDv = async () => {
    setRegionDropdownOptionsIsLoadingDv(true);
    setRegionDropdownOptionsIsLoadedDv(false);

    let getDropdownType = selectedMarketTgDropdownOptionDv.value === 'tg' ? 'tg' : 'market';
    let getIsTg = selectedMarketTgDropdownOptionDv.value === 'tg' ? true : false;

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        drop_down_type: getDropdownType,
        is_tg: getIsTg
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-dropdown/",
        config
      );

      const regionsDDOpt = res?.data.map((item) => ({
        label: item.planned_label,
        value: item.mapping_id
      }))



      setRegionDropdownOptionsDv(regionsDDOpt ?? []);
      setRegionDropdownOptionsOnLoadDv(regionsDDOpt ?? []);
      setSelectedRegionDropdownOptionDv(regionsDDOpt[0] ?? []);
      setRegionDropdownOptionsIsLoadingDv(false);
      setRegionDropdownOptionsIsLoadedDv(true);
    } catch (error) {
      setRegionDropdownOptionsDv([]);
      setRegionDropdownOptionsIsLoadingDv(false);
      setRegionDropdownOptionsIsLoadedDv(false);

      setPlannedVsDeliveredMetricsIsLoadingDv(false);
      setPlannedVsDeliveredMetricsIsLoadedDv(false);
      setPlannedVsDeliveredMetricsErrorDv(error.code);

      setPlannedVsDeliveredTableDataIsLoadingDv(false);
      setPlannedVsDeliveredTableDataIsLoadedDv(false);
      setPlannedVsDeliveredTableDataErrorDv(error.code);
    }
  };

  useEffect(() => {
    fetchRegionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOption]);

  useEffect(() => {
    fetchRegionDropdownHandlerDv();
  }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOptionDv]);

  // DROPDOWN STATE
  const [selectedRegionDropdownOption, setSelectedRegionDropdownOption] =
    useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownRegionSelectionHandler = (e) => {
    if (e === null) {
      setSelectedRegionDropdownOption("");
    } else {
      setSelectedRegionDropdownOption(e);
    }

    IAEvent_Dropdown_Visit(
      pageModule,
      pageSubModule,
      pagePlatform,
      pagePrimaryTab,
      null,
      "PlannedVsDelivered Region Meta",
      e.label
    );
  };

  // DROPDOWN STATE

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  // const fetchMarketTgDropdownHandler = () => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   } else {
  //     setRegionDropdownOptionsOnLoad([]);
  //   }
  // };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  // useEffect(() => {
  //   fetchMarketTgDropdownHandler();
  // }, [
  //   BRAND_ID,
  //   selectedDates,
  //   selectedMarketTgDropdownOption,
  // ]);

  // useEffect(() => {
  //   if (selectedMarketTgDropdownOption.value === "market") {
  //     setRegionDropdownOptionsOnLoad(regionDropdownOptions);
  //     setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   } else if (selectedMarketTgDropdownOption.value === "tg") {
  //     setRegionDropdownOptionsOnLoad(tgDropdownOptions);
  //     setSelectedRegionDropdownOption(tgDropdownOptions[0]);
  //   }
  // }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOption]);

  // DROPDOWN STATE
  const [selectedRegionDropdownOptionDv, setSelectedRegionDropdownOptionDv] =
    useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownRegionSelectionHandlerDv = (e) => {
    if (e === null) {
      setSelectedRegionDropdownOptionDv("");
    } else {
      setSelectedRegionDropdownOptionDv(e);
    }

    IAEvent_Dropdown_Visit(
      pageModule,
      pageSubModule,
      pagePlatform,
      pagePrimaryTab,
      null,
      "PlannedVsDelivered Region DV",
      e.label
    );
  };

  // DROPDOWN STATE

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  // const fetchMarketTgDropdownHandlerDv = () => {
  //   if (selectedMarketTgDropdownOptionDv.value === "market") {
  //     setRegionDropdownOptionsOnLoadDv(regionDropdownOptionsDv);
  //     setSelectedRegionDropdownOptionDv(regionDropdownOptionsDv[0]);
  //   } else if (selectedMarketTgDropdownOptionDv.value === "tg") {
  //     setRegionDropdownOptionsOnLoadDv(tgDropdownOptions);
  //     setSelectedRegionDropdownOptionDv(tgDropdownOptions[0]);
  //   } else {
  //     setRegionDropdownOptionsOnLoadDv([]);
  //   }
  // };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  // useEffect(() => {
  //   fetchMarketTgDropdownHandlerDv();
  // }, [BRAND_ID, selectedDates, selectedMarketTgDropdownOptionDv]);
  // useEffect(() => {
  //   if (selectedMarketTgDropdownOptionDv.value === "market") {
  //     setRegionDropdownOptionsOnLoadDv(regionDropdownOptionsDv);
  //   } else if (selectedMarketTgDropdownOptionDv.value === "tg") {
  //     setRegionDropdownOptionsOnLoadDv(tgDropdownOptions);
  //     setSelectedRegionDropdownOptionDv(tgDropdownOptions[0]);
  //   }
  // }, [selectedMarketTgDropdownOptionDv]);

  useEffect(() => {
    if (!regionDropdownOptionsIsLoadingDv && regionDropdownOptionsIsLoadedDv) {
      fetchPlannedVsDeliveredMetricsHandlerDv();
      fetchPlannedVsDeliveredTableDataHandlerDv();
    }
  }, [
    regionDropdownOptionsIsLoadedDv,
    regionDropdownOptionsIsLoadingDv,
    selectedRegionDropdownOptionDv,
  ]);

  useEffect(() => {
    if (!regionDropdownOptionsIsLoading && regionDropdownOptionsIsLoaded) {
      fetchPlannedVsDeliveredMetricsHandler();
      fetchPlannedVsDeliveredTableDataHandler();
    }
  }, [
    regionDropdownOptionsIsLoaded,
    regionDropdownOptionsIsLoading,
    selectedRegionDropdownOption,
  ]);





  // useEffect(() => {
  //   setSelectedMarketTgDropdownOption({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOption(regionDropdownOptions[0]);
  //   if (
  //     selectedMarketTgDropdownOption.value === "market" &&
  //     selectedRegionDropdownOption.value === "overall"
  //   ) {
  //     fetchPlannedVsDeliveredMetricsHandler();
  //     fetchPlannedVsDeliveredTableDataHandler();
  //   }
  // }, [BRAND_ID, selectedDates]);

  // useEffect(() => {

  //   setSelectedMarketTgDropdownOptionDv({ value: "market", label: "Market" });
  //   setSelectedRegionDropdownOptionDv(regionDropdownOptions[0]);
  //   if (
  //     selectedMarketTgDropdownOptionDv.value === "market" &&
  //     selectedRegionDropdownOptionDv.value === "overall"
  //   ) {
  //     fetchPlannedVsDeliveredMetricsHandlerDv();
  //     fetchPlannedVsDeliveredTableDataHandlerDv();
  //   }
  // }, [BRAND_ID, selectedDates]);




  // BRAND WISE
  const [brandWiseData, setBrandWiseData] = useState([]);
  const [allDropdownsBrandWiseData, setAllDropdownsBrandWiseData] = useState(
    []
  );
  const [allDropdownsBrandWiseDataLabels, setAllDropdownsBrandWiseDataLabels] =
    useState([]);
  const [brandWiseDataLabels, setBrandWiseDataLabels] = useState([]);
  const [isBrandWiseLoading, setBrandWiseIsLoading] = useState(false);
  const [isBrandWiseLoaded, setBrandWiseIsLoaded] = useState(false);
  const [brandWiseError, setBrandWiseError] = useState(null);

  // BRAND WISE MARKET SHARE TREND
  // DROPDOWN OPTIONS
  const brandWiseMarketShareTrendOptions = [
    { value: "all_coke_brands", label: "All Coke Brands" },
    { value: "all_pepsi_brands", label: "All Pepsi Brands" },
    { value: "all_parle_agro_brands", label: "All Parle Agro Brands" },
    { value: "all_brands", label: "All Brands" },
  ];

  // INITIAL STATE
  const [
    selectedBrandWiseMarketShareTrendOption,
    setSelectedBrandWiseMarketShareTrendOption,
  ] = useState(brandWiseMarketShareTrendOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const brandWiseMarketShareTrendDropdownValueSelectionHandler = (e) => {
    if (e === null) {
      setSelectedBrandWiseMarketShareTrendOption("");
    } else {
      setSelectedBrandWiseMarketShareTrendOption(e);
    }
  };

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  const brandWiseMarketShareTrendDropdownDataSelectionHandler = async () => {
    if (
      allDropdownsBrandWiseData !== undefined
    ) {
      if (selectedBrandWiseMarketShareTrendOption.value === "all_coke_brands") {
        setBrandWiseData(
          allDropdownsBrandWiseData["COCA COLA INDIA PRIVATE LTD"]
        );
        setBrandWiseDataLabels(
          allDropdownsBrandWiseDataLabels["COCA COLA INDIA PRIVATE LTD"]
        );
      } else if (
        selectedBrandWiseMarketShareTrendOption.value === "all_pepsi_brands"
      ) {
        setBrandWiseData(allDropdownsBrandWiseData["PEPSI FOODS (G)"]);
        setBrandWiseDataLabels(
          allDropdownsBrandWiseDataLabels["PEPSI FOODS (G)"]
        );
      } else if (
        selectedBrandWiseMarketShareTrendOption.value ===
        "all_parle_agro_brands"
      ) {
        setBrandWiseData(allDropdownsBrandWiseData["PARLE (G)"]);
        setBrandWiseDataLabels(allDropdownsBrandWiseDataLabels["PARLE (G)"]);
      } else if (
        selectedBrandWiseMarketShareTrendOption.value === "all_brands"
      ) {
        setBrandWiseData(allDropdownsBrandWiseData["ALL COMPANY"]);
        setBrandWiseDataLabels(allDropdownsBrandWiseDataLabels["ALL COMPANY"]);
      } else {
        setBrandWiseData([]);
        setBrandWiseDataLabels([]);
      }
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHANGES
  useEffect(() => {
    brandWiseMarketShareTrendDropdownDataSelectionHandler();
  }, [
    BRAND_ID,
    dropdownValue,
    selectedDates,
    selectedBrandWiseMarketShareTrendOption,
  ]);

  // PRIME TIME GRPS
  const [primetTimeGRPSData, setPrimetTimeGRPSData] = useState([]);
  const [primetTimeGRPSDataLabels, setPrimeTimeGRPSDataLabels] = useState([]);
  const [isPrimetTimeGRPSLoading, setPrimeTimeGRPSIsLoading] = useState(false);
  const [isPrimetTimeGRPSLoaded, setPrimeTimeGRPSIsLoaded] = useState(false);
  const [primetTimeGRPSError, setPrimeTimeGRPSError] = useState(null);

  // PREMIUM POSITION GRPS
  const [premiumPositionGRPSData, setPremiumPositionGRPSData] = useState([]);
  const [premiumPositionGRPSDataLabels, setPremiumPositionGRPSDataLabels] =
    useState([]);
  const [isPremiumPositionGRPSLoading, setPremiumPositionGRPSIsLoading] =
    useState(false);
  const [isPremiumPositionGRPSLoaded, setPremiumPositionGRPSIsLoaded] =
    useState(false);
  const [premiumPositionGRPSError, setPremiumPositionGRPSError] =
    useState(null);

  //SALES SUMMARY
  const [trendLineAnalysis, setTrendLineAnalysis] = useState([]);
  const [trendLineAnalysisLabel, setTrendLineAnalysisLabel] = useState([]);
  const [isTrendLineAnalysisLoading, setTrendLineAnalysisIsLoading] =
    useState(false);
  const [isTrendLineAnalysisLoaded, setTrendLineAnalysisIsLoaded] =
    useState(false);
  const [trendLineAnalysiserror, setTrendLineAnalysisError] = useState(null);

  //SALES SUMMARY TABLE
  const [trendAnalysisTableData, setTrendAnalysisTableData] = useState([]);
  const [trendAnalysisTableDataHeaders, setTrendAnalysisTableDataHaeders] =
    useState([]);
  const [isTrendAnalysisTableDataLoading, setTrendAnalysisTableDataIsLoading] =
    useState(false);
  const [isTrendAnalysisTableDataLoaded, setTrendAnalysisTableDataIsLoaded] =
    useState(false);
  const [trendAnalysisTableDataerror, setTrendAnalysisTableDataError] =
    useState(null);

  //TV + DIGITAL SUMMARY
  const [tvDigitalSummaryData, setTvDigitalSummaryData] = useState([]);
  const [tvDigitalSummaryLabel, setTvDigitalSummaryLabel] = useState([]);
  const [isTvDigitalSummaryIsLoading, setTvDigitalSummaryIsLoading] =
    useState(false);
  const [isTvDigitalSummaryIsLoaded, setTvDigitalSummaryIsLoaded] =
    useState(false);
  const [tvDigitalSummaryError, setTvDigitalSummaryError] = useState(null);

  // MID SECTION DROPDOWN
  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "overall", label: "Overall" },
    { value: "facebook", label: "Facebook" },
    { value: "instagram", label: "Instagram" },
    { value: "dv360", label: "DV 360" },
  ];
  // DROPDOWN DEFAULT STATE

  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState({ value: "overall", label: "Overall" });

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "Digital Campaigns Summary",
      e.label
    );
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "overall") {
      setDigitalCampaignsSummaryData(
        digitalCampaignsAllDropdownsData === undefined ||
          digitalCampaignsAllDropdownsData.length === 0
          ? []
          : digitalCampaignsAllDropdownsData.overall
      );
      setDigitalCampaignsSummaryDataLabels(
        digitalCampaignsAllDropdownsLabels === undefined ||
          digitalCampaignsAllDropdownsLabels.length === 0
          ? []
          : digitalCampaignsAllDropdownsLabels.overall
      );
      setDigitalCampaignsSummaryDetails(
        digitalCampaignsSummaryDetailsAllDropdownsData === undefined ||
          digitalCampaignsSummaryDetailsAllDropdownsData.length === 0
          ? []
          : digitalCampaignsSummaryDetailsAllDropdownsData.overall
      );
      setDigitalCampaignsSummaryDetailsTotal(
        digitalCampaignsSummaryDetailsTotalAllDropdownsData === undefined ||
          digitalCampaignsSummaryDetailsTotalAllDropdownsData.length === 0
          ? 0
          : digitalCampaignsSummaryDetailsTotalAllDropdownsData.overall
            .metric_total
      );
    } else if (selectedMidSectionDropdownOption.value === "facebook") {
      setDigitalCampaignsSummaryData(
        digitalCampaignsAllDropdownsData === undefined ||
          digitalCampaignsAllDropdownsData.length === 0
          ? []
          : digitalCampaignsAllDropdownsData.facebook
      );
      setDigitalCampaignsSummaryDataLabels(
        digitalCampaignsAllDropdownsLabels === undefined ||
          digitalCampaignsAllDropdownsLabels.length === 0
          ? []
          : digitalCampaignsAllDropdownsLabels.facebook
      );
      setDigitalCampaignsSummaryDetails(
        digitalCampaignsSummaryDetailsAllDropdownsData === undefined ||
          digitalCampaignsSummaryDetailsAllDropdownsData.length === 0
          ? []
          : digitalCampaignsSummaryDetailsAllDropdownsData.facebook
      );
      setDigitalCampaignsSummaryDetailsTotal(
        digitalCampaignsSummaryDetailsTotalAllDropdownsData === undefined ||
          digitalCampaignsSummaryDetailsTotalAllDropdownsData.length === 0
          ? 0
          : digitalCampaignsSummaryDetailsTotalAllDropdownsData.facebook
            .metric_total
      );
    } else if (selectedMidSectionDropdownOption.value === "instagram") {
      setDigitalCampaignsSummaryData(
        digitalCampaignsAllDropdownsData === undefined ||
          digitalCampaignsAllDropdownsData.length === 0
          ? []
          : digitalCampaignsAllDropdownsData.instagram
      );
      setDigitalCampaignsSummaryDataLabels(
        digitalCampaignsAllDropdownsLabels === undefined ||
          digitalCampaignsAllDropdownsLabels.length === 0
          ? []
          : digitalCampaignsAllDropdownsLabels.instagram
      );
      setDigitalCampaignsSummaryDetails(
        digitalCampaignsSummaryDetailsAllDropdownsData === undefined ||
          digitalCampaignsSummaryDetailsAllDropdownsData.length === 0
          ? []
          : digitalCampaignsSummaryDetailsAllDropdownsData.instagram
      );
      setDigitalCampaignsSummaryDetailsTotal(
        digitalCampaignsSummaryDetailsTotalAllDropdownsData === undefined ||
          digitalCampaignsSummaryDetailsTotalAllDropdownsData.length === 0
          ? 0
          : digitalCampaignsSummaryDetailsTotalAllDropdownsData.instagram
            .metric_total
      );
    } else if (selectedMidSectionDropdownOption.value === "dv360") {
      setDigitalCampaignsSummaryData(
        digitalCampaignsAllDropdownsData === undefined ||
          digitalCampaignsAllDropdownsData.length === 0
          ? []
          : digitalCampaignsAllDropdownsData.dv360
      );
      setDigitalCampaignsSummaryDataLabels(
        digitalCampaignsAllDropdownsLabels === undefined ||
          digitalCampaignsAllDropdownsLabels.length === 0
          ? []
          : digitalCampaignsAllDropdownsLabels.dv360
      );
      setDigitalCampaignsSummaryDetails(
        digitalCampaignsSummaryDetailsAllDropdownsData === undefined ||
          digitalCampaignsSummaryDetailsAllDropdownsData.length === 0
          ? []
          : digitalCampaignsSummaryDetailsAllDropdownsData.dv360
      );
      setDigitalCampaignsSummaryDetailsTotal(
        digitalCampaignsSummaryDetailsTotalAllDropdownsData === undefined ||
          digitalCampaignsSummaryDetailsTotalAllDropdownsData.length === 0
          ? 0
          : digitalCampaignsSummaryDetailsTotalAllDropdownsData.dv360
            .metric_total
      );
    } else {
      setDigitalCampaignsSummaryData([]);
      setDigitalCampaignsSummaryDataLabels([]);
      setDigitalCampaignsSummaryDetails([]);
      setDigitalCampaignsSummaryDetailsTotal([]);
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption]);



  //STATE WISE KPI
  const [stateWiseKpiTableData, setStateWiseKpiTableData] = useState([]);
  const [stateWiseKpiTableDataHeaders, setStateWiseKpiTableDataHaeders] =
    useState([]);
  const [isStateWiseKpiTableDataLoading, setStateWiseKpiTableDataIsLoading] =
    useState(false);
  const [isStateWiseKpiTableDataLoaded, setStateWiseKpiTableDataIsLoaded] =
    useState(false);
  const [stateWiseKpiTableDataError, setStateWiseKpiTableDataError] =
    useState(null);





  //BRAND WISE TOP POSTS COMPARE
  const [cokeBrandPostCompare, setCokeBrandPostCompare] = useState([]);
  const [isCokeBrandPostCompareLoading, setCokeBrandPostCompareIsLoading] = useState(false);
  const [isCokeBrandPostCompareLoaded, setCokeBrandPostCompareIsLoaded] = useState(false);
  const [cokeBrandPostCompareError, setCokeBrandPostCompareError] = useState(null);




  const [firstBrandOptions, setFirstBrandOptions] = useState([]);
  const [firstMonthOptions, setFirstMonthOptions] = useState([]);

  const [secondBrandOptions, setSecondBrandOptions] = useState([]);
  const [secondMonthOptions, setSecondMonthOptions] = useState([]);

  const [brandIsLoading, setFirstBrandIsLoading] = useState(true);
  const [brandIsLoaded, setFirstBrandIsLoaded] = useState(false);

  const [selectedFirstBrandOption, setSelectedFirstBrandOption] = useState([]);
  const [selectedFirstMonthOption, setSelectedFirstMonthOption] = useState([]);

  const [selectedSecondBrandOption, setSelectedSecondBrandOption] = useState(
    []
  );
  const [selectedSecondMonthOption, setSelectedSecondMonthOption] = useState(
    []
  );

  const fretchBrandMonthDropdownHandler = async () => {
    setFirstBrandIsLoading(true);
    setFirstBrandIsLoaded(false);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        //view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/brand-month-wise-filter/", config);

      setFirstBrandOptions(res?.data?.brand ?? []);
      setFirstMonthOptions(res?.data?.month ?? []);

      setSecondBrandOptions(res?.data?.brand ?? []);
      setSecondMonthOptions(res?.data?.month ?? []);

      setSelectedFirstBrandOption(
        res?.data?.brand[0] ?? []
      );
      setSelectedFirstMonthOption(res?.data?.month[0] ?? []);

      setSelectedSecondBrandOption(res?.data?.brand[0] ?? []);
      setSelectedSecondMonthOption(res?.data?.month[0] ?? []);

      setFirstBrandIsLoading(false);
      setFirstBrandIsLoaded(true);
    } catch (error) {
      setFirstBrandOptions([]);
      setFirstMonthOptions([]);

      setSecondBrandOptions([]);
      setSecondMonthOptions([]);

      setFirstTableDataIsLoading(false);
      setFirstTableDataIsLoaded(false);
      setFirstTableDataError(error.code);

      setSecondTableDataIsLoading(false);
      setSecondTableDataIsLoaded(false);
      setSecondTableDataError(error.code);

      setFirstBrandIsLoading(false);
      setFirstBrandIsLoaded(false);


    }
  };

  useEffect(() => {
    fretchBrandMonthDropdownHandler();
  }, []);

  //DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownBrandFirstSectionHandler = (e) => {
    if (e === null) {
      setSelectedFirstBrandOption("");
    } else {
      setSelectedFirstBrandOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "TV Reach First Brand ",
      e.label
    );
  };

  //DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMonthFirstSectionHandler = (e) => {
    if (e === null) {
      setSelectedFirstMonthOption("");
    } else {
      setSelectedFirstMonthOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "TV Reach First Month",
      e.label
    );
  };

  //DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownBrandSecondSectionHandler = (e) => {
    if (e === null) {
      setSelectedSecondBrandOption("");
    } else {
      setSelectedSecondBrandOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "TV Reach Second Brand",
      e.label
    );
  };

  //DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownMonthSecondSectionHandler = (e) => {
    if (e === null) {
      setSelectedSecondMonthOption("");
    } else {
      setSelectedSecondMonthOption(e);
    }
    IAEvent_Dropdown_Visit(
      "Coke Summary",
      null,
      null,
      null,
      null,
      "TV Reach Second Month",
      e.label
    );
  };

  // CAMPAIGN SUMMARY
  const [firstTableData, setFirstTableData] = useState([]);
  const [firstTableHeaderData, setFirstTableHeaderData] = useState([]);
  const [isFirstTableDataLoading, setFirstTableDataIsLoading] = useState(false);
  const [isFirstTableDataLoaded, setFirstTableDataIsLoaded] = useState(false);
  const [firstTableDataerror, setFirstTableDataError] = useState(null);

  const [secondTableData, setSecondTableData] = useState([]);
  const [secondTableHeaderData, setSecondTableHeaderData] = useState([]);
  const [isSecondTableDataLoading, setSecondTableDataIsLoading] =
    useState(false);
  const [isSecondTableDataLoaded, setSecondTableDataIsLoaded] = useState(false);
  const [secondTableDataerror, setSecondTableDataError] = useState(null);


  const [allTableData, setAllTableData] = useState([]);


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Overall Summary
  // TO FETCH DATA FROM API
  const fetchMetricCardsHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/metric-card/", config);
      setCampaignSummaryData(res?.data ?? []);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummaryData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  // TO FETCH META ORGANIC REACH METRIC CARDS FROM API
  const fetchMetaOrganicReachMetricCardsHandler = async () => {
    setMetaOrganicReachMetricCard({});
    setMetaOrganicReachMetricCardStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: true,
      isLoaded: false,
      error: null,
    }));

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/facebook/meta_summary_organic_reach/", config);
      setMetaOrganicReachMetricCard({
        currentValue: res?.data?.data?.[0]?.currentValue ?? 0,
        previousValue: res?.data?.data?.[0]?.previousValue ?? 0,
        rowTitle: "Organic Reach",
      });
      setMetaOrganicReachMetricCardStatus((prevStatus) => ({
        ...prevStatus,
        isLoading: false,
        isLoaded: true,
        error: null,
      }));
    } catch (error) {
      setMetaOrganicReachMetricCard({});
      setMetaOrganicReachMetricCardStatus((prevStatus) => ({
        ...prevStatus,
        isLoading: false,
        isLoaded: false,
        error: error.code,
      }));
    }
  };

  // TO FETCH META PAID REACH METRIC CARDS FROM API
  const fetchMetaPaidReachMetricCardsHandler = async () => {
    setMetaOrganicReachMetricCard([]);
    setMetaPaidReachMetricCardStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: true,
      isLoaded: false,
      error: null,
    }));

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/meta-paid-summary-reach/", config);

      setMetaPaidReachMetricCard({
        currentValue: res.data?.data?.[0]?.currentValue || 0,
        previousValue: res.data?.data?.[0]?.previousValue || 0,
        rowTitle: "Paid Reach",
      });
      setMetaPaidReachMetricCardStatus((prevStatus) => ({
        ...prevStatus,
        isLoading: false,
        isLoaded: true,
        error: null,
      }));
    } catch (error) {
      setMetaPaidReachMetricCard([]);
      setMetaPaidReachMetricCardStatus((prevStatus) => ({
        ...prevStatus,
        isLoading: false,
        isLoaded: false,
        error: error.code,
      }));
    }
  };

  let static_data = [
    {
      cardTitle: "Meta Reach",
      currentValue: "loading",
      previousValue: "loading",
      rowOneTitle: "Paid Reach",
      second_currentValue: "loading",
      second_previousValue: "loading",
      rowTwoTitle: "Organic Reach",
    },
  ];

  let error_static_data = [
    {
      cardTitle: "Meta Reach",
      currentValue: "error",
      previousValue: "error",
      rowOneTitle: "Organic Reach",
      second_currentValue: "error",
      second_previousValue: "error",
      rowTwoTitle: "Paid Reach",
    },
  ];

  let combineMetricCardsData = useMemo(() => {
    let temp = [];

    if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
      temp = campaignSummaryData.concat(static_data);

      if (
        !metaOrganicReachMetricCardStatus.isLoaded &&
        metaOrganicReachMetricCardStatus.isLoading &&
        !metaOrganicReachMetricCardStatus.isLoaded &&
        metaOrganicReachMetricCardStatus.isLoading
      ) {
        temp = campaignSummaryData.concat(static_data);
      } else if (
        metaOrganicReachMetricCardStatus.error ||
        metaOrganicReachMetricCardStatus.error
      ) {
        if (metaOrganicReachMetricCardStatus.error && !metaOrganicReachMetricCardStatus.error) {
          temp = campaignSummaryData.concat({
            cardTitle: "Meta Reach",
            currentValue: metaPaidReachMetricCard.currentValue,
            previousValue: metaPaidReachMetricCard.previousValue,
            rowOneTitle: metaPaidReachMetricCard.rowTitle,
            second_currentValue: "error",
            second_previousValue: "error",
            rowTwoTitle: "Paid Reach",
          });
        } else if (
          !metaOrganicReachMetricCardStatus.error &&
          metaOrganicReachMetricCardStatus.error
        ) {
          temp = campaignSummaryData.concat({
            cardTitle: "Meta Reach",
            currentValue: "error",
            previousValue: "error",
            rowOneTitle: "Organic Reach",
            second_currentValue: metaOrganicReachMetricCard.currentValue,
            second_previousValue: metaOrganicReachMetricCard.previousValue,
            rowTwoTitle: metaOrganicReachMetricCard.rowTitle,
          });
        } else {
          temp = campaignSummaryData.concat(error_static_data);
        }
      } else {
        if (
          metaOrganicReachMetricCardStatus.isLoaded &&
          !metaOrganicReachMetricCardStatus.isLoading &&
          !metaOrganicReachMetricCardStatus.isLoaded &&
          metaOrganicReachMetricCardStatus.isLoading
        ) {
          temp = campaignSummaryData.concat({
            cardTitle: "Meta Reach",
            currentValue: "loading",
            previousValue: "loading",
            rowOneTitle: "Organic Reach",
            second_currentValue: metaOrganicReachMetricCard.currentValue,
            second_previousValue: metaOrganicReachMetricCard.previousValue,
            rowTwoTitle: metaOrganicReachMetricCard.rowTitle,
          });
        } else if (
          !metaOrganicReachMetricCardStatus.isLoaded &&
          metaOrganicReachMetricCardStatus.isLoading &&
          metaOrganicReachMetricCardStatus.isLoaded &&
          !metaOrganicReachMetricCardStatus.isLoading
        ) {
          temp = campaignSummaryData.concat({
            cardTitle: "Meta Reach",
            currentValue: metaPaidReachMetricCard.currentValue,
            previousValue: metaPaidReachMetricCard.previousValue,
            rowOneTitle: metaPaidReachMetricCard.rowTitle,
            second_currentValue: "loading",
            second_previousValue: "loading",
            rowTwoTitle: "Paid Reach",
          });
        } else {
          temp = campaignSummaryData.concat({
            cardTitle: "Meta Reach",
            currentValue: metaPaidReachMetricCard.currentValue,
            previousValue: metaPaidReachMetricCard.previousValue,
            rowOneTitle: metaPaidReachMetricCard.rowTitle,
            second_currentValue: metaOrganicReachMetricCard.currentValue,
            second_previousValue: metaOrganicReachMetricCard.previousValue,
            rowTwoTitle: metaOrganicReachMetricCard.rowTitle,
          });
        }
      }
    }

    return temp;
  }, [
    campaignSummaryData,
    isCampaignSummaryLoaded,
    isCampaignSummaryLoading,
    metaOrganicReachMetricCard,
    metaOrganicReachMetricCardStatus.isLoading,
    metaOrganicReachMetricCardStatus.isLoaded,
    metaOrganicReachMetricCardStatus.error,
    metaPaidReachMetricCard,
    metaOrganicReachMetricCardStatus.isLoading,
    metaOrganicReachMetricCardStatus.isLoaded,
    metaOrganicReachMetricCardStatus.error,
  ]);

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let campaignSummary = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">Overall Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    if (NoDataAvailableChecker(combineMetricCardsData)) {
      campaignSummary = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">
                <h2 className="section_card_title">Overall Summary</h2>
              </div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      campaignSummary = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Overall Summary"}>
              {combineMetricCardsData.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="coke summary"
                    platform="overall"
                    section="overall"
                    key={index}
                    variant={"compare"}
                    cardTitle={data.cardTitle}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={data.rowOneTitle}
                    second_currentValue={data.second_currentValue}
                    second_previousValue={data.second_previousValue}
                    rowTwoTitle={data.rowTwoTitle}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryError) {
    campaignSummary = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              {" "}
              <h2 className="section_card_title">Overall Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              error={campaignSummaryError}
              chartType="cardChartType"
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummary = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              {" "}
              <h2 className="section_card_title">Overall Summary</h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // OVERALL SUMMARY BY IMPRESSION
  // TO FETCH DATA FROM API
  const fetchOverallSummaryByImpressionHandler = async () => {
    setOverallSummaryByImpressionIsLoading(true);
    setOverallSummaryByImpressionIsLoaded(false);
    setOverallSummaryByImpressionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/summary-by-impressions/", config);

      setOverallSummaryByImpressionData(res?.data ?? []);
      setOverallSummaryByImpressionIsLoading(false);
      setOverallSummaryByImpressionIsLoaded(true);
      setOverallSummaryByImpressionError(null);
    } catch (error) {
      setOverallSummaryByImpressionData([]);
      setOverallSummaryByImpressionIsLoading(false);
      setOverallSummaryByImpressionIsLoaded(false);
      setOverallSummaryByImpressionError(error.code);
    }
  };

  // INITIAL STATE
  let campaignOverviewChart = (
    <Wrapper>
      <Loader loaderType="sankeyChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isOverallSummaryByImpressionLoaded &&
    !isOverallSummaryByImpressionLoading
  ) {
    if (NoDataAvailableChecker(overallSummaryByImpressionData)) {
      campaignOverviewChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="sankeyChartType" />
        </Wrapper>
      );
    } else {
      campaignOverviewChart = (
        <Wrapper>
          <ErrorBoundary chartType="sankeyChartType">
            <TraceableSankeyChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={overallSummaryByImpressionData}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (overallSummaryByImpressionError) {
    campaignOverviewChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={overallSummaryByImpressionError}
          chartType="sankeyChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isOverallSummaryByImpressionLoading) {
    campaignOverviewChart = (
      <Wrapper>
        <Loader loaderType="sankeyChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // OVERALL SUMMARY TABLE

  // TO FETCH DATA FROM API
  const fetchOverallSummaryTableHandler = async () => {
    setOverallSummaryTableIsLoading(true);
    setOverallSummaryTableIsLoaded(false);
    setOverallSummaryTableError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "summary/overall-summary-table-impressions/",
        config
      );
      setOverallSummaryTable(res?.data?.data ?? []);
      setOverallSummaryTableHeader(res?.data?.label ?? []);

      setOverallSummaryTableIsLoading(false);
      setOverallSummaryTableIsLoaded(true);
      setOverallSummaryTableError(null);
    } catch (error) {
      setOverallSummaryTable([]);
      setOverallSummaryTableHeader([]);
      setOverallSummaryTableIsLoading(false);
      setOverallSummaryTableIsLoaded(false);
      setOverallSummaryTableError(error.code);
    }
  };

  let overallSummaryTableSection = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Overall Summary by Impressions Table
              <SectionDefinationsBadge
                title={"Overall Summary Impression Table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isOverallSummaryTableLoaded && !isOverallSummaryTableLoading) {
    if (
      NoDataAvailableChecker(overallSummaryTable) ||
      NoDataAvailableChecker(overallSummaryTableHeader)
    ) {
      overallSummaryTableSection = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Overall Summary by Impressions Table
                  <SectionDefinationsBadge
                    title={"Overall Summary by Impressions Table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      overallSummaryTableSection = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="Overall Summary by Impressions Table"
              tablePagePlatform={pagePlatform}
              tablePageSubModule={pageSubModule}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={overallSummaryTableHeader}
              tableData={overallSummaryTable}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID={"overall_summary_table"}
              showTotal={true}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  platform={pagePlatform}
                  subModuleName={pageSubModule}
                  sectionId={"overall_summary_table"}
                  sectionName={"Overall Summary Impression Tbl"}
                  sectionData={overallSummaryTable}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (overallSummaryTableError) {
    overallSummaryTableSection = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Overall Summary by Impressions Table
                <SectionDefinationsBadge
                  title={"Overall Summary by Impressions Table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={overallSummaryTableError}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isOverallSummaryTableLoading) {
    overallSummaryTableSection = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Overall Summary by Impressions Table
                <SectionDefinationsBadge
                  title={"Overall Summary Impression Table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DIGITAL CAMPAIGNS SUMMARY
  // TO FETCH DATA FROM API
  const fetchDigitalCampaignsSummaryHandler = async () => {
    setDigitalCampaignsSummaryIsLoading(true);
    setDigitalCampaignsSummaryIsLoaded(false);
    setDigitalCampaignsSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/digital-campaign-summary/", config);

      setDigitalCampaignsSummaryData(res?.data?.graph_metrics?.data?.overall ?? []);
      setDigitalCampaignsSummaryDataLabels(res?.data?.graph_metrics?.dimension?.overall ?? []);
      setDigitalCampaignsSummaryDetails(res?.data?.side_metrics?.overall ?? []);

      setDigitalCampaignsSummaryAllDropdownsData(res?.data?.graph_metrics?.data ?? []);
      setDigitalCampaignsSummaryAllDropdownsLabels(res?.data?.graph_metrics?.dimension ?? []);
      setDigitalCampaignsSummaryDetailsAllDropdownsData(res?.data?.side_metrics ?? []);
      setDigitalCampaignsSummaryDetailsTotal(res?.data?.side_total_metrics?.facebook?.metric_total ?? []);
      setDigitalCampaignsSummaryDetailsTotalAllDropdownsData(res?.data?.side_total_metrics ?? []);
      setDigitalCampaignsSummaryIsLoading(false);
      setDigitalCampaignsSummaryIsLoaded(true);
      setDigitalCampaignsSummaryError(null);
    } catch (error) {
      setDigitalCampaignsSummaryAllDropdownsData([]);
      setDigitalCampaignsSummaryAllDropdownsLabels([]);
      setDigitalCampaignsSummaryDetailsAllDropdownsData([]);
      setDigitalCampaignsSummaryDetailsTotalAllDropdownsData([]);
      setDigitalCampaignsSummaryIsLoading(false);
      setDigitalCampaignsSummaryIsLoaded(false);
      setDigitalCampaignsSummaryError(error.code);
    }
  };

  let defaultDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultDisabledValues = []
  } else {
    defaultDisabledValues = [
      "Clicks",
      "CPC",
      "Cost Per Video View",
      "CTR"
    ]
  }

  const [disabledValues, setDisabledValues] = useState(defaultDisabledValues)

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceBySourceChart = (
    <Wrapper>
      <Loader chartType="multiAxisMultiLineChartLoader" />
    </Wrapper>
  );
  let performanceBySourceChart2 = (
    <Wrapper>
      <Loader chartType="multiAxisMultiLineChartLoader" />
    </Wrapper>
  );

  if (isDigitalCampaignsLoaded && !isDigitalCampaignsLoading) {
    if (
      NoDataAvailableChecker(digitalCampaignsSummaryData) ||
      NoDataAvailableChecker(digitalCampaignsAllDropdownsLabels)
    ) {
      performanceBySourceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="multiAxisMultiLineChartType" />
        </Wrapper>
      );
      performanceBySourceChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="multiAxisMultiLineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {

        performanceBySourceChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <DateLineChart
                chartClass="section_card_chart"
                chartData={digitalCampaignsSummaryData}
                chartId={`performance_by_source`}
                chartLabels={digitalCampaignsDataLabels}
                showLegend={true}
                disabledLegendArray={disabledValues}
                setDisabledValues={setDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        performanceBySourceChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedDateLineChart
                chartClass="section_card_chart"
                chartData={digitalCampaignsSummaryData}
                chartId="performance_by_source_partitioned"
                chartLabels={digitalCampaignsDataLabels}
                showLegend={true}
                disabledLegendArray={disabledValues}
                setDisabledValues={setDisabledValues}
                showLabels={true}

              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        performanceBySourceChart =
          (<Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <MultiAxisMultiLine
                chartClass="section_card_chart"
                chartData={digitalCampaignsSummaryData}
                chartId="performance_by_source"
                chartLabels={digitalCampaignsDataLabels}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>)
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (digitalCampaignsError) {
    performanceBySourceChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="multiAxisMultiLineChartType"
          error={digitalCampaignsError}
        />
      </Wrapper>
    );
    performanceBySourceChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="multiAxisMultiLineChartType"
          error={digitalCampaignsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDigitalCampaignsLoading) {
    performanceBySourceChart = (
      <Wrapper>
        <Loader loaderType="multiAxisMultiLineChartLoader" />
      </Wrapper>
    );
    performanceBySourceChart2 = (
      <Wrapper>
        <Loader loaderType="multiAxisMultiLineChartLoader" />
      </Wrapper>
    );
  }


  const [isPaidReachMonthlyOpen, setIsPaidReachMonthlyOpen] = useState(false)

  let defaultPaidReachMonthlyDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPaidReachMonthlyDisabledvalues = []
  } else {
    defaultPaidReachMonthlyDisabledvalues = ["SPRITE"]
  }

  const [paidReachMonthlyDisabledvalues, setPaidReachMonthlyDisabledvalues] = useState(defaultPaidReachMonthlyDisabledvalues)


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Paid Reach Monthly Trendline
  // TO FETCH DATA FROM API
  const fetchPaidReachMonthlyTrendlineHandler = async () => {
    setPaidReachMonthlyTrendlineIsLoading(true);
    setPaidReachMonthlyTrendlineIsLoaded(false);
    setPaidReachMonthlyTrendlineError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "summary/paid-reach-monthly-trend-line/",
        config
      );

      setPaidReachMonthlyTrendlineAllDropdownData(res?.data?.data ?? []);
      setPaidReachMonthlyTrendlineAllDropdownDataLabels(res?.data?.label ?? []);

      setPaidReachMonthlyTrendlineData(res?.data?.data?.dv_reach ?? []);
      setPaidReachMonthlyTrendlineDataLabels(res?.data?.label?.dv_reach ?? []);

      setPaidReachMonthlyTrendlineIsLoading(false);
      setPaidReachMonthlyTrendlineIsLoaded(true);
      setPaidReachMonthlyTrendlineError(null);
    } catch (error) {
      setPaidReachMonthlyTrendlineData([]);
      setPaidReachMonthlyTrendlineDataLabels([]);
      setPaidReachMonthlyTrendlineAllDropdownData([]);
      setPaidReachMonthlyTrendlineAllDropdownDataLabels([]);
      setPaidReachMonthlyTrendlineIsLoading(false);
      setPaidReachMonthlyTrendlineIsLoaded(false);
      setPaidReachMonthlyTrendlineError(error.code);
    }
  };

  let paidReachMonthlyTrendline = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let paidReachMonthlyTrendline2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (
    isPaidReachMonthlyTrendlineLoaded &&
    !isPaidReachMonthlyTrendlineLoading
  ) {
    if (NoDataAvailableChecker(paidReachMonthlyTrendlineData)) {
      paidReachMonthlyTrendline = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      paidReachMonthlyTrendline2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {

        paidReachMonthlyTrendline = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={paidReachMonthlyTrendlineData}
                chartId="paidReachMonthlyTrendline"
                chartLabels={paidReachMonthlyTrendlineDataLabels}
                showLegend={true}
                disabledLegendArray={paidReachMonthlyDisabledvalues}
                setDisabledValues={setPaidReachMonthlyDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        )
        paidReachMonthlyTrendline2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={paidReachMonthlyTrendlineData}
                chartId="paidReachMonthlyTrendline_2"
                chartLabels={paidReachMonthlyTrendlineDataLabels}
                showLegend={true}
                disabledLegendArray={paidReachMonthlyDisabledvalues}
                setDisabledValues={setPaidReachMonthlyDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        paidReachMonthlyTrendline = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={paidReachMonthlyTrendlineData}
                chartId="paidReachMonthlyTrendline"
                chartLabels={paidReachMonthlyTrendlineDataLabels}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>
        )
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (paidReachMonthlyTrendlineError) {
    paidReachMonthlyTrendline = (
      <Wrapper>
        <ServerErrorsLoader
          error={paidReachMonthlyTrendlineError}
          chartType="lineChartType"
        />
      </Wrapper>
    );

    paidReachMonthlyTrendline2 = (
      <Wrapper>
        <ServerErrorsLoader
          error={paidReachMonthlyTrendlineError}
          chartType="lineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPaidReachMonthlyTrendlineLoading) {
    paidReachMonthlyTrendline = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    paidReachMonthlyTrendline2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

  }

  const [isSocialSummaryOpen, setIsSocialSummaryOpen] = useState(false)

  let defaultSocialSummaryDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultSocialSummaryDisabledValues = []
  } else {
    defaultSocialSummaryDisabledValues = ["Reach", "Engagements", "Engagement Rate"]
  }

  const [socialSummaryDisabledValues, setSocialSummaryDisabledValues] = useState(defaultSocialSummaryDisabledValues)

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // SOCIAL SUMMARY
  // TO FETCH DATA FROM API
  const fetchSocialSummaryHandler = async () => {
    setSocialSummaryIsLoading(true);
    setSocialSummaryIsLoaded(false);
    setSocialSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/social-summary-chart/", config);

      setSocialSummaryAllDropdownData(res?.data?.data ?? []);
      setSocialSummaryAllDropdownDataLabels(res?.data?.dimension ?? []);

      setSocialSummaryData(res?.data?.data?.overall ?? []);
      setSocialSummaryDataLabels(res?.data?.dimension?.overall ?? []);

      setSocialSummaryIsLoading(false);
      setSocialSummaryIsLoaded(true);
      setSocialSummaryError(null);
    } catch (error) {
      setSocialSummaryData([]);
      setSocialSummaryDataLabels([]);
      setSocialSummaryAllDropdownData([]);
      setSocialSummaryAllDropdownDataLabels([]);
      setSocialSummaryIsLoading(false);
      setSocialSummaryIsLoaded(false);
      setSocialSummaryError(error.code);
    }
  };

  let socialSummary = (
    <Wrapper>
      <Loader loaderType="multiAxisMultiLineChartLoader" />
    </Wrapper>
  );

  let socialSummary2 = (
    <Wrapper>
      <Loader chartType="multiAxisMultiLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isSocialSummaryLoaded && !isSocialSummaryLoading) {
    if (NoDataAvailableChecker(socialSummaryData)) {
      socialSummary = (
        <Wrapper>
          <NoDataAvailableLoader chartType="multiAxisMultiLineChartType" />
        </Wrapper>
      );

      socialSummary2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="multiAxisMultiLineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        socialSummary = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <DateLineChart
                chartClass="section_card_chart"
                chartData={socialSummaryData}
                chartId={`platformWiseSpend`}
                chartLabels={socialSummaryDataLabels}
                showLegend={true}
                disabledLegendArray={socialSummaryDisabledValues}
                setDisabledValues={setSocialSummaryDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        socialSummary2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedDateLineChart
                chartClass="section_card_chart"
                chartData={socialSummaryData}
                chartId="platformWiseSpend_2"
                chartLabels={socialSummaryDataLabels}
                showLegend={true}
                disabledLegendArray={socialSummaryDisabledValues}
                setDisabledValues={setSocialSummaryDisabledValues}
                showLabels={true}

              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        socialSummary = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <MultiAxisMultiLine
                chartClass="section_card_chart"
                chartData={socialSummaryData}
                chartId="platformWiseSpend"
                chartLabels={socialSummaryDataLabels}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }


    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (socialSummaryError) {
    socialSummary = (
      <Wrapper>
        <ServerErrorsLoader
          error={socialSummaryError}
          chartType="multiAxisMultiLineChartType"
        />
      </Wrapper>
    );

    socialSummary2 = (
      <Wrapper>
        <ServerErrorsLoader
          error={socialSummaryError}
          chartType="multiAxisMultiLineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isSocialSummaryLoading) {
    socialSummary = (
      <Wrapper>
        <Loader loaderType="multiAxisMultiLineChartLoader" />
      </Wrapper>
    );

    socialSummary2 = (
      <Wrapper>
        <Loader loaderType="multiAxisMultiLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO SUMMARY
  // TO FETCH DATA FROM API
  const [isVideoSummaryOpen, setIsVideoSummaryOpen] = useState(false)

  let defaultVideoSummaryDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVideoSummaryDisabledValues = []
  } else {
    defaultVideoSummaryDisabledValues = ["Reach"]
  }

  const [videoSummaryDisabledValues, setVideoSummaryDisabledValues] = useState(defaultVideoSummaryDisabledValues)

  // TO FETCH METRIC CARDS FROM API
  const fetchVideoSummaryHandler = async () => {
    setVideoSummaryIsLoading(true);
    setVideoSummaryIsLoaded(false);
    setVideoSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/video-summary/", config);
      setVideoSummaryData(res?.data?.data ?? []);
      setVideoSummaryDataLabels(res?.data?.dimension ?? []);
      setVideoSummaryIsLoading(false);
      setVideoSummaryIsLoaded(true);
      setVideoSummaryError(null);
    } catch (error) {
      setVideoSummaryData([]);
      setVideoSummaryDataLabels([]);
      setVideoSummaryIsLoading(false);
      setVideoSummaryIsLoaded(false);
      setVideoSummaryError(error.code);
    }
  };

  let videoSummaryStackedColumnChart = (
    <Wrapper>
      <Loader loaderType="stackedColumnChartLoader" />
    </Wrapper>
  );
  let videoSummaryStackedColumnChart2 = (
    <Wrapper>
      <Loader loaderType="stackedColumnChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isVideoSummaryLoaded && !isVideoSummaryLoading) {
    if (
      NoDataAvailableChecker(videoSummaryData) ||
      NoDataAvailableChecker(videoSummaryDataLabels)
    ) {
      videoSummaryStackedColumnChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
      videoSummaryStackedColumnChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        videoSummaryStackedColumnChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <MultiBarChart
                chartId="videoSummary_1"
                chartClass="section_card_chart"
                chartData={videoSummaryData}
                chartLabels={videoSummaryDataLabels}
                showLegend={true}
                showLabels={true}
                tooltipMaxWidth={"auto"}
                disabledLegendArray={videoSummaryDisabledValues}
                setDisabledValues={setVideoSummaryDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        videoSummaryStackedColumnChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="barChartType">
              <PartitionedMultiAxisBarChart
                chartId="videoSummary_2"
                chartClass="section_card_chart"
                chartData={videoSummaryData}
                chartLabels={videoSummaryDataLabels}
                showLegend={true}
                showLabels={true}
                tooltipMaxWidth={"auto"}
                disabledLegendArray={videoSummaryDisabledValues}
                setDisabledValues={setVideoSummaryDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        videoSummaryStackedColumnChart = (
          <Wrapper>
            <ErrorBoundary chartType="barChartType">
              <MultiAxisBarChart
                chartId="videoSummary"
                chartClass="section_card_chart"
                chartData={videoSummaryData}
                chartLabels={videoSummaryDataLabels}
                showLegend={true}
                showLabels={true}

              />

            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoSummaryError) {
    videoSummaryStackedColumnChart = (
      <Wrapper>
        <ServerErrorsLoader
          error={videoSummaryError}
          chartType="barChartType"
        />
      </Wrapper>
    );
    videoSummaryStackedColumnChart2 = (
      <Wrapper>
        <ServerErrorsLoader
          error={videoSummaryError}
          chartType="barChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoSummaryLoading) {
    videoSummaryStackedColumnChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
    videoSummaryStackedColumnChart2 = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO SALES SUMMARY
  // TO FETCH DATA FROM API

  const [isVideoAnalysisOpen, setIsVideoAnalysisOpen] = useState(false)

  let defaultVideoAnalysisDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultVideoAnalysisDisabledValues = []
  } else {
    defaultVideoAnalysisDisabledValues = ["No of Videos"]
  }

  const [videoAnalysisDisabledValues, setVideoAnalysisDisabledValues] = useState(defaultVideoAnalysisDisabledValues)



  const fetchVideoAnalysisHandler = async () => {
    setVideoAnalysisIsLoading(true);
    setVideoAnalysisIsLoaded(false);
    setVideoAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        drop_down_value: /* selectedVideoAnalysisDropdownOption */
          selectedVideoAnalysisDropdownOption === undefined ||
            selectedVideoAnalysisDropdownOption === null ||
            selectedVideoAnalysisDropdownOption === ""
            ? ""
            : selectedVideoAnalysisDropdownOption.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/video-summary-analysis/", config);

      // const res = await axioss.get("https://jsonware.com/api/v1/json/66a9384a-1c40-499b-9218-3fed20308314")

      setVideoAnalysisData(res?.data?.data ?? []);
      setVideoAnalysisDataLabels(res?.data?.Dimensions ?? []);
      setVideoAnalysisIsLoading(false);
      setVideoAnalysisIsLoaded(true);
      setVideoAnalysisError(null);
    } catch (error) {
      setVideoAnalysisData([]);
      setVideoAnalysisDataLabels([]);
      setVideoAnalysisIsLoading(false);
      setVideoAnalysisIsLoaded(false);
      setVideoAnalysisError(error.code);
    }
  };

  let VideoAnalysis = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );
  let VideoAnalysis2 = (
    <Wrapper>
      <Loader chartType="combinedBarLineChartLoader" />
    </Wrapper>
  );
  // IF DATA IS AVAILABLE
  if (isVideoAnalysisLoaded && !isVideoAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoAnalysisData) ||
      NoDataAvailableChecker(videoAnalysisDataLabels)
    ) {
      VideoAnalysis = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
      VideoAnalysis2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        VideoAnalysis = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartData={videoAnalysisData}
                chartId="video_analysis_1"
                chartLabels={videoAnalysisDataLabels}
                showLegend={true}
                disabledLegendArray={videoAnalysisDisabledValues}
                setDisabledValues={setVideoAnalysisDisabledValues}

              />
            </ErrorBoundary>
          </Wrapper>
        );
        VideoAnalysis2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartData={videoAnalysisData}
                chartId="video_analysis_2"
                chartLabels={videoAnalysisDataLabels}
                showLegend={true}
                disabledLegendArray={videoAnalysisDisabledValues}
                setDisabledValues={setVideoAnalysisDisabledValues}
                showLabels={true}
                showSecondY={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        VideoAnalysis =
          (<Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartData={videoAnalysisData}
                chartId="video_analysis_1"
                chartLabels={videoAnalysisDataLabels}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>)
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoAnalysisError) {
    VideoAnalysis = (
      <Wrapper>
        <ServerErrorsLoader
          error={videoAnalysisError}
          chartType="combinedBarLineChartType"
        />
      </Wrapper>
    );
    VideoAnalysis2 = (
      <Wrapper>
        <ServerErrorsLoader
          error={videoAnalysisError}
          chartType="combinedBarLineChartType"
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoAnalysisLoading) {
    VideoAnalysis = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
    VideoAnalysis2 = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO ANALYSIS TABLE

  // TO FETCH METRIC  FROM API
  const fetchVideoAnalysisTableDataHandler = async () => {
    setVideoAnalysisTableDataIsLoading(true);
    setVideoAnalysisTableDataIsLoaded(false);
    setVideoAnalysisTableDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        brand_id: BRAND_ID,
        drop_down_value: /* selectedVideoAnalysisDropdownOption */
          selectedVideoAnalysisDropdownOption === undefined ||
            selectedVideoAnalysisDropdownOption === null ||
            selectedVideoAnalysisDropdownOption === ""
            ? ""
            : selectedVideoAnalysisDropdownOption.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/video-analysis-summary-table/", config);
      setVideoAnalysisTableData(res?.data?.data ?? []);
      setVideoAnalysisTableDataHaeders(res?.data?.label ?? []);

      setVideoAnalysisTableDataIsLoading(false);
      setVideoAnalysisTableDataIsLoaded(true);
      setVideoAnalysisTableDataError(null);
    } catch (error) {
      setVideoAnalysisTableData([]);
      setVideoAnalysisTableDataHaeders([]);
      setVideoAnalysisTableDataIsLoading(false);
      setVideoAnalysisTableDataIsLoaded(false);
      setVideoAnalysisTableDataError(error.code);
    }
  };

  let videoAnalysisTable = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Analysis Summary table

              {selectedVideoAnalysisDropdownOption && selectedVideoAnalysisDropdownOption.label}
              <SectionDefinationsBadge
                title={"Video Analysis Summary table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isVideoAnalysisTableDataLoaded && !isVideoAnalysisTableDataLoading) {
    if (
      NoDataAvailableChecker(videoAnalysisTableData) ||
      NoDataAvailableChecker(videoAnalysisTableDataHeaders)
    ) {
      videoAnalysisTable = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Video Analysis Summary table
                  {selectedVideoAnalysisDropdownOption && ' : ' + selectedVideoAnalysisDropdownOption.label}
                  <SectionDefinationsBadge
                    title={"Video Analysis Summary table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      videoAnalysisTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Video Analysis Summary table" +
                `${selectedVideoAnalysisDropdownOption && ' : ' + selectedVideoAnalysisDropdownOption.label}
			  `
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={videoAnalysisTableDataHeaders}
              tableData={videoAnalysisTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="video_analysis"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  platform={pagePlatform}
                  subModuleName={pageSubModule}
                  sectionId={"video_analysis"}
                  sectionName={`TableDynaLabels_Video Analysis table_${` - ` + selectedVideoAnalysisDropdownOption.label} `}
                  sectionData={videoAnalysisTableData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoAnalysisTableDataError) {
    videoAnalysisTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Video Analysis Summary table
                {selectedVideoAnalysisDropdownOption && ' : ' + selectedVideoAnalysisDropdownOption.label}
                <SectionDefinationsBadge
                  title={"Video Analysis Summary table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={videoAnalysisTableDataError}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoAnalysisTableDataLoading) {
    videoAnalysisTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Video Analysis Summary table

                {selectedVideoAnalysisDropdownOption && ' : ' + selectedVideoAnalysisDropdownOption.label}
                <SectionDefinationsBadge
                  title={"Video Analysis Summary table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // HASHTAG SUMMARY
  // TO FETCH DATA FROM API

  const fetchHashtagSummaryHandler = async () => {
    setHashtagSummaryIsLoading(true);
    setHashtagSummaryIsLoaded(false);
    setHashtagSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/hashtag-summary/", config);

      setHashtagSummaryData(res?.data?.data?.engagement ?? []);
      setHashtagSummaryDataTopTen(res?.data?.data?.engagement?.slice(0, 10) ?? []);

      setHashtagSummaryDataLabels(res?.data?.dimension?.engagement ?? []);

      setHashtagSummaryAllDropdownData(res?.data?.data ?? []);
      setHashtagSummaryAllDropdownDataLabels(res?.data?.dimension ?? []);

      setHashtagSummaryIsLoading(false);
      setHashtagSummaryIsLoaded(true);
      setHashtagSummaryError(null);
    } catch (error) {
      setHashtagSummaryData([]);
      setHashtagSummaryDataLabels([]);
      setHashtagSummaryDataTopTen([]);
      setHashtagSummaryIsLoading(false);
      setHashtagSummaryIsLoaded(false);
      setHashtagSummaryError(error.code);
    }
  };

  let hashtagSummary = <Loader loaderType="stackedColumnChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isHashtagSummaryLoaded && !isHashtagSummaryLoading) {
    if (
      NoDataAvailableChecker(hashtagSummaryData) ||
      NoDataAvailableChecker(hashtagSummaryLabels)
    ) {
      hashtagSummary = (
        <NoDataAvailableLoader chartType="stackedColumnChartType" />
      );
    } else {
      hashtagSummary = (
        <ErrorBoundary chartType="stackedColumnChartType">
          <StackedColumnChart
            chartId="hashtag_summary_chart"
            chartClass="section_card_chart"
            chartData={hashtagSummaryDataTopTen}
            chartLabels={hashtagSummaryLabels}
            showLegend={true}
            showLabels={true}
            tooltipMaxWidth={"auto"}
            showShortVerticalLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagSummaryerror) {
    hashtagSummary = (
      <ServerErrorsLoader
        chartType="stackedColumnChartType"
        error={hashtagSummaryerror}
      />
    );
  }

  // IF DATA LOADING
  if (isHashtagSummaryLoading) {
    hashtagSummary = <Loader loaderType="stackedColumnChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // HASHTAG SUMMARY TABLE

  // TO FETCH DATA FROM API
  const fetchHashtagSummaryTableHandler = async () => {
    setHashtagSummaryTableIsLoading(true);
    setHashtagSummaryTableIsLoaded(false);
    setHashtagSummaryTableError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/summary-hashtag-table/", config);
      setHashtagSummaryTable(res?.data?.data ?? []);
      setHashtagSummaryTableHeader(res?.data?.label ?? []);

      setHashtagSummaryTableIsLoading(false);
      setHashtagSummaryTableIsLoaded(true);
      setHashtagSummaryTableError(null);
    } catch (error) {
      setHashtagSummaryTable([]);
      setHashtagSummaryTableHeader([]);
      setHashtagSummaryTableIsLoading(false);
      setHashtagSummaryTableIsLoaded(false);
      setHashtagSummaryTableError(error.code);
    }
  };

  let hashtagSummaryTableSection = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Hashtag Summary Table
              <SectionDefinationsBadge
                title={"Hashtag Summary Table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isHashtagSummaryTableLoaded && !isHashtagSummaryTableLoading) {
    if (
      NoDataAvailableChecker(hashtagSummaryTable) ||
      NoDataAvailableChecker(hashtagSummaryTableHeader)
    ) {
      hashtagSummaryTableSection = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Hashtag Summary Table
                  <SectionDefinationsBadge
                    title={"Hashtag Summary Table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      hashtagSummaryTableSection = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle="Hashtag Summary Table"
              tablePagePlatform={pagePlatform}
              tablePageSubModule={pageSubModule}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={hashtagSummaryTableHeader}
              tableData={hashtagSummaryTable}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search Hashtag"}
              tableID={"summary_table"}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  platform={pagePlatform}
                  subModuleName={pageSubModule}
                  sectionId={"summary_table"}
                  sectionName={"Hashtag Summary Table"}
                  sectionData={hashtagSummaryTable}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagSummaryTableError) {
    hashtagSummaryTableSection = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Hashtag Summary Table
                <SectionDefinationsBadge
                  title={"Hashtag Summary Table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={hashtagSummaryTableError}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagSummaryTableLoading) {
    hashtagSummaryTableSection = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Hashtag Summary Table
                <SectionDefinationsBadge
                  title={"Hashtag Summary Table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // WEBSITE SUMMARY
  // TO FETCH DATA FROM API
  /* 	const fetchWebsiteSummaryHandler = async () => {
      setWebsiteSummaryIsLoading(true);
      setWebsiteSummaryIsLoaded(false);
      setWebsiteSummaryError(null);
	
    // 	const config = {
    // 		params: {
    // 			start_date: selectedDates.startDate,
    // 			end_date: selectedDates.endDate,
    // 			brand_id: BRAND_ID,
    // 			previous_start_date: selectedDates.prevStartDate,
    // 			previous_end_date: selectedDates.prevEndDate,
    // 		},
    // 		headers: {
    // 			"Content-Type": "application/json",
    // 			Authorization: "Bearer " + authCtx.token,
    // 		},
    // 	};
	
      try {
        const res = await axios.get("summary/website-analysis-chart/", config);
	
        setWebsiteSummaryData(
          res.data === undefined || res.data.length === 0
            ? []
            : res.data.bounceRate_Vs_sessions === undefined ||
              res.data.bounceRate_Vs_sessions.length === 0
              ? []
              : res.data.bounceRate_Vs_sessions.data === undefined ||
                res.data.bounceRate_Vs_sessions.data.length === 0
                ? []
                : res.data.bounceRate_Vs_sessions.data
        );
        setWebsiteSummaryDataLabel(
          res.data === undefined || res.data.length === 0
            ? []
            : res.data.bounceRate_Vs_sessions === undefined ||
              res.data.bounceRate_Vs_sessions.length === 0
              ? []
              : res.data.bounceRate_Vs_sessions.Dimensions === undefined ||
                res.data.bounceRate_Vs_sessions.Dimensions.length === 0
                ? []
                : res.data.bounceRate_Vs_sessions.Dimensions
        );
        setWebsiteSummaryAllDropdownData(
          res.data === undefined || res.data.length === 0 ? [] : res.data
        );
        setWebsiteSummaryAllDropdownDataLabels(
          res.data === undefined || res.data.length === 0 ? [] : res.data
        );
        setWebsiteSummaryIsLoading(false);
        setWebsiteSummaryIsLoaded(true);
        setWebsiteSummaryError(null);
      } catch (error) {
        setWebsiteSummaryData([]);
        setWebsiteSummaryDataLabel([]);
        setWebsiteSummaryIsLoading(false);
        setWebsiteSummaryIsLoaded(false);
        setWebsiteSummaryError(error.code);
      }
    };
	
    // let websiteSummary = (
    // 	<NoDataAvailableLoader chartType="combinedBarLineChartType" />
    // );
	
    // // IF DATA IS LOADED
    // if (isWebsiteSummaryLoaded && !isWebsiteSummaryLoading) {
    // 	if (
    // 		NoDataAvailableChecker(websiteSummaryData) ||
    // 		NoDataAvailableChecker(websiteSummaryDataLabel)
    // 	) {
    // 		websiteSummary = (
    // 			<NoDataAvailableLoader chartType="combinedBarLineChartType" />
    // 		);
    // 	} else {
    // 		websiteSummary = (
    // 			<Wrapper>
    // 				<ErrorBoundary chartType="combinedBarLineChartType">
    // 					<CombinedBarLineMultipleAxesChart
    // 						chartClass="section_card_chart"
    // 						chartData={websiteSummaryData}
    // 						chartId="websiteSummaryData"
    // 						chartLabels={websiteSummaryDataLabel}
    // 					/>
    // 				</ErrorBoundary>
    // 			</Wrapper>
    // 		);
    // 	}
    // }
	
    // // IF THERE IS SOME ERROR FROM SERVER
    // if (websiteSummaryError) {
    // 	websiteSummary = (
    // 		<ServerErrorsLoader
    // 			error={websiteSummaryError}
    // 			chartType="combinedBarLineChartType"
    // 		/>
    // 	);
    // }
	
    // IF DATA LOADING
    if (isWebsiteSummaryLoading) {
      websiteSummary = <Loader loaderType="combinedBarLineChartLoader" />;
    } */

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // WEBSITE SUMMARY
  // // TO FETCH DATA FROM API
  // const fetchORMSummaryHandler = async () => {
  //   setORMSummaryIsLoading(true);
  //   setORMSummaryIsLoaded(false);
  //   setORMSummaryError(null);

  //   const config = {
  //     params: {
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       brand_id: BRAND_ID,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "summary/orm-summary/",
  //       config
  //     );

  //     setORMSummaryData(res.data.length === 0 ? [] : res.data.data);
  //     setORMSummaryDataLabel(
  //       res.data.length === 0 ? [] : res.data.Dimensions
  //     );
  //     setORMSummaryIsLoading(false);
  //     setORMSummaryIsLoaded(true);
  //     setORMSummaryError(null);
  //   } catch (error) {
  //     setORMSummaryData([]);
  //     setORMSummaryDataLabel([]);
  //     setORMSummaryIsLoading(false);
  //     setORMSummaryIsLoaded(false);
  //     setORMSummaryError(error.code);
  //   }
  // };

  // let ormSummary = (
  //   <NoDataAvailableLoader chartType="combinedBarLineChartType" />
  // );

  // // IF DATA IS LOADED
  // if (isORMSummaryLoaded && !isORMSummaryLoading) {
  //   if (
  //     NoDataAvailableChecker(oRMSummaryData) ||
  //     NoDataAvailableChecker(ORMSummaryDataLabel)
  //   ) {
  //     ormSummary = (
  //       <NoDataAvailableLoader chartType="combinedBarLineChartType" />
  //     );
  //   } else {
  //     ormSummary = (
  //       <Wrapper>
  //         <ErrorBoundary chartType="combinedBarLineChartType">
  //           <CombinedBarLineMultipleAxesChart
  //             chartClass="section_card_chart"
  //             chartData={oRMSummaryData}
  //             showSecondY={true}
  //             chartId="ormSummaryData"
  //             chartLabels={ORMSummaryDataLabel}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (oRMSummaryError) {
  //   ormSummary = (
  //     <ServerErrorsLoader
  //       error={oRMSummaryError}
  //       chartType="combinedBarLineChartType"
  //     />
  //   );
  // }

  // // IF DATA LOADING
  // if (isORMSummaryLoading) {
  //   ormSummary = <Loader loaderType="combinedBarLineChartLoader" />;
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND WISE

  const [isBrandWiseMarketShareOpen, setIsBrandWiseMarketShareOpen] = useState(false)

  let defaultBrandWiseMarketShareDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultBrandWiseMarketShareDisabledvalues = []
  } else {
    defaultBrandWiseMarketShareDisabledvalues = ["SPRITE"]
  }
  const [brandWiseMarketShareDisabledvalues, setBrandWiseMarketShareDisabledvalues] = useState(defaultBrandWiseMarketShareDisabledvalues)

  const fetchBrandWiseMarketShareTrendHandler = async () => {
    setBrandWiseIsLoading(true);
    setBrandWiseIsLoaded(false);
    setBrandWiseError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        state_code: dropdownValue.lenght === 0 ? null : dropdownValue.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/brand-wise-market-share/", config);

      setBrandWiseData(res?.data?.data?.data["COCA COLA INDIA PRIVATE LTD"] ?? []);
      setBrandWiseDataLabels(res?.data?.data?.dimension["COCA COLA INDIA PRIVATE LTD"] ?? []);

      setAllDropdownsBrandWiseData(res?.data?.data?.data ?? []);
      setAllDropdownsBrandWiseDataLabels(res?.data?.data?.dimension ?? []);

      setBrandWiseIsLoading(false);
      setBrandWiseIsLoaded(true);
      setBrandWiseError(null);
    } catch (error) {
      setBrandWiseData([]);
      setBrandWiseDataLabels([]);
      setBrandWiseIsLoading(false);
      setBrandWiseIsLoaded(false);
      setBrandWiseError(error.code);
    }
  };

  let brandWiseLineChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  let brandWiseLineChart2 = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isBrandWiseLoaded && !isBrandWiseLoading) {
    if (
      NoDataAvailableChecker(brandWiseData) ||
      NoDataAvailableChecker(brandWiseDataLabels)
    ) {
      brandWiseLineChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );

      brandWiseLineChart2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        brandWiseLineChart = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <LineChart
                chartId="brandWise"
                chartClass="section_card_chart"
                chartData={brandWiseData}
                chartLabels={brandWiseDataLabels}
                showLegend={true}
                disabledLegendArray={brandWiseMarketShareDisabledvalues}
                setDisabledValues={setBrandWiseMarketShareDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        )
        brandWiseLineChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={brandWiseData}
                chartId="brandWise_2"
                chartLabels={brandWiseDataLabels}
                showLegend={true}
                disabledLegendArray={brandWiseMarketShareDisabledvalues}
                setDisabledValues={setBrandWiseMarketShareDisabledvalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );


      } else {
        brandWiseLineChart = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartId="brandWise"
                chartClass="section_card_chart"
                chartData={brandWiseData}
                chartLabels={brandWiseDataLabels}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandWiseError) {
    brandWiseLineChart = (
      <Wrapper>
        <ServerErrorsLoader error={brandWiseError} chartType="lineChartType" />
      </Wrapper>
    );

    brandWiseLineChart2 = (
      <Wrapper>
        <ServerErrorsLoader error={brandWiseError} chartType="lineChartType" />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isBrandWiseLoading) {
    brandWiseLineChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );

    brandWiseLineChart2 = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE GROWTH
  // TO FETCH DATA FROM API
  /* 	const fetchPlannedVSActualHandler = async () => {
      setPlannedVSActualIsLoading(true);
      setPlannedVSActualIsLoaded(false);
      setPlannedVSActualError(null);
	
      const config = {
        params: {
          brand_id: BRAND_ID,
          start_date: selectedDates.startDate,
          end_date: selectedDates.endDate,
          previous_start_date: selectedDates.prevStartDate,
          previous_end_date: selectedDates.prevEndDate,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      };
	
      try {
        const res = await axios.get(
          "summary/campaign-planned-vs-actual/",
          config
        );
	
        setPlannedVSActualAllDropdownData(
          res.data === undefined || res.data.length === 0
            ? []
            : res.data.data === undefined || res.data.data.length === 0
              ? []
              : res.data.data
        );
        setPlannedVSActualAllDropdownDataLabels(
          res.data === undefined || res.data.length === 0
            ? []
            : res.data.dimension === undefined || res.data.dimension.length === 0
              ? []
              : res.data.dimension
        );
	
        setPlannedVSActualData(
          res.data === undefined || res.data.length === 0
            ? []
            : res.data.data === undefined || res.data.data.length === 0
              ? []
              : res.data.data.impressions === undefined ||
                res.data.data.impressions.length === 0
                ? []
                : res.data.data.impressions
        );
        setPlannedVSActualDataLabels(
          res.data === undefined || res.data.length === 0
            ? []
            : res.data.dimension === undefined || res.data.dimension.length === 0
              ? []
              : res.data.dimension.impressions === undefined ||
                res.data.dimension.impressions.length === 0
                ? []
                : res.data.dimension.impressions
        );
	
        setPlannedVSActualIsLoading(false);
        setPlannedVSActualIsLoaded(true);
        setPlannedVSActualError(null);
      } catch (error) {
        setPlannedVSActualData([]);
        setPlannedVSActualDataLabels([]);
        setPlannedVSActualAllDropdownData([]);
        setPlannedVSActualAllDropdownDataLabels([]);
        setPlannedVSActualIsLoading(false);
        setPlannedVSActualIsLoaded(false);
        setPlannedVSActualError(error.code);
      }
    };
	
    let plannedVSActual = <Loader loaderType="barChartLoader" />;
	
    // IF DATA IS AVAILABLE
    if (isPlannedVSActualLoaded && !isPlannedVSActualLoading) {
      if (
        NoDataAvailableChecker(plannedVSActualData) ||
        NoDataAvailableChecker(plannedVSActualDataLabels)
      ) {
        plannedVSActual = <NoDataAvailableLoader chartType="barChartType" />;
      } else {
        plannedVSActual = (
          <ErrorBoundary chartType="barChartType">
            <LayeredColumnChart
              chartId="planned_vs_actual"
              chartClass="section_card_chart"
              chartData={plannedVSActualData}
              chartLabels={plannedVSActualDataLabels}
            />
          </ErrorBoundary>
        );
      }
    }
	
    // IF THERE IS SOME ERROR FROM SERVER
    if (plannedVSActualError) {
      plannedVSActual = (
        <ServerErrorsLoader
          chartType="barChartType"
          error={plannedVSActualError}
        />
      );
    }
	
    // IF DATA LOADING
    if (isPlannedVSActualLoading) {
      plannedVSActual = <Loader loaderType="barChartLoader" />;
    }
   */
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceBySourceDetailsData = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isDigitalCampaignsLoaded && !isDigitalCampaignsLoading) {
    if (NoDataAvailableChecker(digitalCampaignsSummaryDetails)) {
      performanceBySourceDetailsData = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      performanceBySourceDetailsData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="main_wrapper">
              <div className="body">
                {digitalCampaignsSummaryDetails &&
                  digitalCampaignsSummaryDetails.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">{details.label}</div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter title={details.label} number={details.value} />
                            </ErrorBoundary>
                            {details.metric && (
                              <div className="metrics_wrapper">
                                <div className="title_wrapper">
                                  {details.metric[0].label + " : "}
                                </div>
                                <div className="value_wrapper">
                                  <ErrorBoundary>
                                    <NumberFormatter
                                      title={details.metric[0].label}
                                      number={details.metric[0].value}
                                    />
                                  </ErrorBoundary>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Wrapper>
                    )
                  )}
              </div>
              <div className="footer">
                <div className="title_wrapper">Total</div>
                <div className="value_wrapper">
                  <NumberFormatter
                    number={
                      digitalCampaignsSummaryDetailsTotal === undefined
                        ? 0
                        : digitalCampaignsSummaryDetailsTotal
                    }
                  />
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>

        // <Wrapper>
        //     <table className="table table_striped table_bordered">
        //         <tbody>
        //             {digitalCampaignsSummaryDetails.map(
        //                 (details, postImpressionIndex) => {
        //                     return (
        //                         <tr key={postImpressionIndex}>
        //                             <td>{details.label}</td>
        //                             <td className="bold">
        //                                 <ErrorBoundary>
        //                                     <NumberFormatter number={details.value} />
        //                                 </ErrorBoundary>
        //                             </td>
        //                             <td className="span">
        //                                 <div className="subtitle">
        //                                     <div>
        //                                         {details.metric.length === 0
        //                                             ? "Label"
        //                                             : details.metric[0].label}
        //                                         :{" "}
        //                                         <ErrorBoundary>
        //                                             <NumberFormatter
        //                                                 number={
        //                                                     details.metric.length === 0
        //                                                         ? 0
        //                                                         : details.metric[0].value
        //                                                 }
        //                                             />
        //                                         </ErrorBoundary>
        //                                     </div>
        //                                 </div>
        //                             </td>
        //                         </tr>
        //                     );
        //                 }
        //             )}
        //             <tr>
        //                 <td colSpan="2" className="bold">
        //                     Total
        //                 </td>
        //                 <td colSpan="2" className="bold flex justify_center">
        //                     <ErrorBoundary>
        //                         <NumberFormatter
        //                             number={
        //                                 digitalCampaignsSummaryDetailsTotal.length === 0 ? 0 : digitalCampaignsSummaryDetailsTotal
        //                             }
        //                         />
        //                     </ErrorBoundary>
        //                 </td>
        //             </tr>
        //         </tbody>
        //     </table>
        // </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (digitalCampaignsError) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={digitalCampaignsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDigitalCampaignsLoading) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PRIME TIME GRPS / NON PRIME TIME GRPS

  const [isPrimeTimeNonPrimeTimeOpen, setIsPrimeTimeNonPrimeTimeOpen] = useState(false)

  let defaultPrimeTimeNonPrimeTimeDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPrimeTimeNonPrimeTimeDisabledvalues = []
  } else {
    defaultPrimeTimeNonPrimeTimeDisabledvalues = ["Benchmark"]
  }

  const [primeTimeNonPrimeTimeDisabledvalues, setPrimeTimeNonPrimeTimevalues] = useState(defaultPrimeTimeNonPrimeTimeDisabledvalues)

  // TO FETCH DATA FROM API
  const fetchPrimeTimeNonPrimeTimeGRPsHandler = async () => {
    setPrimeTimeGRPSIsLoading(true);
    setPrimeTimeGRPSIsLoaded(false);
    setPrimeTimeGRPSError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        state_code: dropdownValue.length === 0 ? null : dropdownValue.value,

        filter_by:
          selectedDurationDropdownOption === undefined ||
            selectedDurationDropdownOption === null ||
            selectedDurationDropdownOption === ""
            ? ""
            : selectedDurationDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/pt-grp-percent-chart/", config);

      setPrimetTimeGRPSData(res?.data?.data ?? []);
      setPrimeTimeGRPSDataLabels(res?.data?.dimension ?? []);

      setPrimeTimeGRPSIsLoading(false);
      setPrimeTimeGRPSIsLoaded(true);
      setPrimeTimeGRPSError(null);
    } catch (error) {
      setPrimetTimeGRPSData([]);
      setPrimeTimeGRPSDataLabels([]);

      setPrimeTimeGRPSIsLoading(false);
      setPrimeTimeGRPSIsLoaded(false);
      setPrimeTimeGRPSError(error.code);
    }
  };

  // PRIME TIME
  let PrimeTimeChart = <Loader loaderType="singleLineChartLoader" />;

  let PrimeTimeChart2 = <Loader loaderType="singleLineChartLoader" />;


  // IF DATA IS AVAILABLE
  if (isPrimetTimeGRPSLoaded && !isPrimetTimeGRPSLoading) {
    if (
      NoDataAvailableChecker(primetTimeGRPSData) ||
      NoDataAvailableChecker(primetTimeGRPSDataLabels)
    ) {
      PrimeTimeChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );

      PrimeTimeChart2 = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {

      if (isMultiLineCluster) {
        PrimeTimeChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={primetTimeGRPSData}
              chartId="primeTime"
              chartLabels={primetTimeGRPSDataLabels}
              showLegend={true}
              disabledLegendArray={primeTimeNonPrimeTimeDisabledvalues}
              setDisabledValues={setPrimeTimeNonPrimeTimevalues}
            />
          </ErrorBoundary>
        );

        PrimeTimeChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={primetTimeGRPSData}
                chartId="primeTime_2"
                chartLabels={primetTimeGRPSDataLabels}
                showLegend={true}
                disabledLegendArray={primeTimeNonPrimeTimeDisabledvalues}
                setDisabledValues={setPrimeTimeNonPrimeTimevalues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

      } else {
        PrimeTimeChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={primetTimeGRPSData}
              chartId="primeTime"
              chartLabels={primetTimeGRPSDataLabels}
              showLegend={true}
            />
          </ErrorBoundary>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (primetTimeGRPSError) {
    PrimeTimeChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={primetTimeGRPSError}
      />
    );
  }

  // IF DATA LOADING
  if (isPrimetTimeGRPSLoading) {
    PrimeTimeChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PREMIUM POSITION GRPS


  const [isPremiumPositionOpen, setIsPremiumPositionOpen] = useState(false)

  let defaultPremiumPositionDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultPremiumPositionDisabledvalues = []
  } else {
    defaultPremiumPositionDisabledvalues = ["Benchmark"]
  }

  const [premiumPositionDisabledvalues, setPremiumPositionDisabledValues] = useState(defaultPremiumPositionDisabledvalues)

  // TO FETCH DATA FROM API
  const fetchPremiumPositionGRPsHandler = async () => {
    setPremiumPositionGRPSIsLoading(true);
    setPremiumPositionGRPSIsLoaded(false);
    setPremiumPositionGRPSError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        state_code: dropdownValue.length === 0 ? null : dropdownValue.value,
        filter_by:
          selectedDurationDropdownOption === undefined ||
            selectedDurationDropdownOption === null ||
            selectedDurationDropdownOption === ""
            ? ""
            : selectedDurationDropdownOption.value.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "summary/position-grp-percent-chart/",
        config
      );

      setPremiumPositionGRPSData(res?.data?.data ?? []);
      setPremiumPositionGRPSDataLabels(res?.data?.dimension ?? []);

      setPremiumPositionGRPSIsLoading(false);
      setPremiumPositionGRPSIsLoaded(true);
      setPremiumPositionGRPSError(null);
    } catch (error) {
      setPremiumPositionGRPSData([]);
      setPremiumPositionGRPSDataLabels([]);

      setPremiumPositionGRPSIsLoading(false);
      setPremiumPositionGRPSIsLoaded(false);
      setPremiumPositionGRPSError(error.code);
    }
  };

  // PREMIUM POSITION
  let premiumPositionChart = (
    <Loader loaderType="singleLineChartLoader" />
  );

  let premiumPositionChart2 = (
    <Loader loaderType="singleLineChartLoader" />
  );

  // IF DATA IS AVAILABLE
  if (isPremiumPositionGRPSLoaded && !isPremiumPositionGRPSLoading) {
    if (
      NoDataAvailableChecker(premiumPositionGRPSData) ||
      NoDataAvailableChecker(premiumPositionGRPSDataLabels)
    ) {
      premiumPositionChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );

      premiumPositionChart2 = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {

      if (isMultiLineCluster) {
        premiumPositionChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={premiumPositionGRPSData}
              chartId="premiumPosition"
              chartLabels={premiumPositionGRPSDataLabels}
              showLegend={true}
              disabledLegendArray={premiumPositionDisabledvalues}
              setDisabledValues={setPremiumPositionDisabledValues}
            />
          </ErrorBoundary>
        );

        premiumPositionChart2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={premiumPositionGRPSData}
                chartId="premiumPosition_2"
                chartLabels={premiumPositionGRPSDataLabels}
                showLegend={true}
                disabledLegendArray={premiumPositionDisabledvalues}
                setDisabledValues={setPremiumPositionDisabledValues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        )

      } else {
        premiumPositionChart = (
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={premiumPositionGRPSData}
              chartId="premiumPosition"
              chartLabels={premiumPositionGRPSDataLabels}
              showLegend={true}
            />
          </ErrorBoundary>
        );
      }




    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (premiumPositionGRPSError) {
    premiumPositionChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={premiumPositionGRPSError}
      />
    );

    premiumPositionChart2 = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={premiumPositionGRPSError}
      />
    );
  }

  // IF DATA LOADING
  if (isPremiumPositionGRPSLoading) {
    premiumPositionChart = (
      <Loader loaderType="singleLineChartLoader" />
    );

    premiumPositionChart2 = (
      <Loader loaderType="singleLineChartLoader" />
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Retention Analysis
  let defaultTvSummaryDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultTvSummaryDisabledValues = []
  } else {
    defaultTvSummaryDisabledValues = ["Sales Volume", "GRPs"]
  }

  const [tvSummaryDisabledValues, setTvSummaryDisabledValues] = useState(defaultTvSummaryDisabledValues)

  const fetchTVSalesSummaryHandler = async () => {
    setTrendLineAnalysisIsLoading(true);
    setTrendLineAnalysisIsLoaded(false);
    setTrendLineAnalysisError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        state_code: dropdownValue.length === 0 ? null : dropdownValue.value,
        filter_by:
          selectedDurationDropdownOption === undefined ||
            selectedDurationDropdownOption === null ||
            selectedDurationDropdownOption === ""
            ? ""
            : selectedDurationDropdownOption.value.toLowerCase(),
        brand_id: BRAND_ID,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/sales-trend-v2/", config);

      setTrendLineAnalysis(res?.data?.data ?? []);
      setTrendLineAnalysisLabel(res?.data?.dimension ?? []);
      setTrendLineAnalysisIsLoading(false);
      setTrendLineAnalysisIsLoaded(true);
      setTrendLineAnalysisError(null);
    } catch (error) {
      setTrendLineAnalysis([]);
      setTrendLineAnalysisLabel([]);
      setTrendLineAnalysisIsLoading(false);
      setTrendLineAnalysisIsLoaded(false);
      setTrendLineAnalysisError(error.code);
    }
  };

  let trendLineAnalysisSection = (
    <Loader loaderType="multiAxisMultiLineChartLoader" />
  );


  let trendLineAnalysisSection2 = (
    <Loader loaderType="multiAxisMultiLineChartLoader" />
  );

  // IF DATA IS LOADED
  if (isTrendLineAnalysisLoaded && !isTrendLineAnalysisLoading) {
    if (
      NoDataAvailableChecker(trendLineAnalysis) ||
      NoDataAvailableChecker(trendLineAnalysisLabel)
    ) {
      trendLineAnalysisSection = (
        <NoDataAvailableLoader chartType="multiAxisMultiLineChartType" />
      );
    } else {

      if (isMultiLineCluster) {
        trendLineAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={trendLineAnalysis}
                chartId={`trendLineAnalysis`}
                chartLabels={trendLineAnalysisLabel}
                showLegend={true}
                disabledLegendArray={tvSummaryDisabledValues}
                setDisabledValues={setTvSummaryDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        )

        trendLineAnalysisSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={trendLineAnalysis}
                chartId="trendLineAnalysis_lineChart"
                chartLabels={trendLineAnalysisLabel}
                showLegend={true}
                disabledLegendArray={tvSummaryDisabledValues}
                setDisabledValues={setTvSummaryDisabledValues}
                showLabels={true}
              />
            </ErrorBoundary>
          </Wrapper>
        )
      } else {
        trendLineAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <MultiAxisMultiLine
                chartClass="section_card_chart"
                chartData={trendLineAnalysis}
                chartId="trendLineAnalysis"
                chartLabels={trendLineAnalysisLabel}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        trendLineAnalysisSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <MultiAxisMultiLine
                chartClass="section_card_chart"
                chartData={trendLineAnalysis}
                chartId="trendLineAnalysis"
                chartLabels={trendLineAnalysisLabel}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trendLineAnalysiserror) {
    trendLineAnalysisSection = (
      <ServerErrorsLoader
        chartType="multiAxisMultiLineChartType"
        error={trendLineAnalysiserror}
      />
    );

    trendLineAnalysisSection2 = (
      <ServerErrorsLoader
        chartType="multiAxisMultiLineChartType"
        error={trendLineAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isTrendLineAnalysisLoading) {
    trendLineAnalysisSection = (
      <Loader loaderType="multiAxisMultiLineChartLoader" />
    );

    trendLineAnalysisSection2 = (
      <Loader loaderType="multiAxisMultiLineChartLoader" />
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // SALES SUMMARY TABLE

  // TO FETCH METRIC  FROM API
  const fetchTrendAnalysisTableDataHandler = async () => {
    setTrendAnalysisTableDataIsLoading(true);
    setTrendAnalysisTableDataIsLoaded(false);
    setTrendAnalysisTableDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        state_code: dropdownValue.length === 0 ? null : dropdownValue.value,
        filter_by:
          selectedDurationDropdownOption === undefined ||
            selectedDurationDropdownOption === null ||
            selectedDurationDropdownOption === ""
            ? ""
            : selectedDurationDropdownOption.value.toLowerCase(),
        brand_id: BRAND_ID,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/sales-trend-table/", config);
      setTrendAnalysisTableData(res?.data?.data ?? []);
      setTrendAnalysisTableDataHaeders(res?.data?.label ?? []);

      setTrendAnalysisTableDataIsLoading(false);
      setTrendAnalysisTableDataIsLoaded(true);
      setTrendAnalysisTableDataError(null);
    } catch (error) {
      setTrendAnalysisTableData([]);
      setTrendAnalysisTableDataHaeders([]);
      setTrendAnalysisTableDataIsLoading(false);
      setTrendAnalysisTableDataIsLoaded(false);
      setTrendAnalysisTableDataError(error.code);
    }
  };

  let trendAnalysisTable = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Summary Table
              {selectedDurationDropdownOption &&
                " : " + selectedDurationDropdownOption.label}
              {dropdownValue.label !== undefined &&
                dropdownValue.label !== "" &&
                dropdownValue.label !== null &&
                " : " + dropdownValue.label}
              <SectionDefinationsBadge
                title={"Summary Table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isTrendAnalysisTableDataLoaded && !isTrendAnalysisTableDataLoading) {
    if (
      NoDataAvailableChecker(trendAnalysisTableData) ||
      NoDataAvailableChecker(trendAnalysisTableDataHeaders)
    ) {
      trendAnalysisTable = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Summary Table
                  {selectedDurationDropdownOption &&
                    " : " + selectedDurationDropdownOption.label}
                  {dropdownValue.label !== undefined &&
                    dropdownValue.label !== "" &&
                    dropdownValue.label !== null &&
                    " : " + dropdownValue.label}
                  <SectionDefinationsBadge
                    title={"Summary Table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      var dropdVal = " : " + selectedDurationDropdownOption.label + " : " + dropdownValue.label

      trendAnalysisTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Summary Table" +
                dropdVal
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={trendAnalysisTableDataHeaders}
              tableData={trendAnalysisTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="tv_summary_table"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  platform={pagePlatform}
                  subModuleName={pageSubModule}
                  sectionId={"tv_summary_table"}
                  sectionName={`DateFirstActiveDropdownLabels_TV & Sales Summary Table_${selectedDurationDropdownOption !== undefined &&
                    selectedDurationDropdownOption !== null &&
                    selectedDurationDropdownOption !== ""
                    ? selectedDurationDropdownOption.label
                    : ""
                    }${`- ` + dropdownValue.label} `}
                  sectionData={trendLineAnalysis}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trendAnalysisTableDataerror) {
    trendAnalysisTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary Table
                {selectedDurationDropdownOption &&
                  " : " + selectedDurationDropdownOption.label}
                {dropdownValue.label !== undefined &&
                  dropdownValue.label !== "" &&
                  dropdownValue.label !== null &&
                  " : " + dropdownValue.label}
                <SectionDefinationsBadge
                  title={"Summary Table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={trendAnalysisTableDataerror}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTrendAnalysisTableDataLoading) {
    trendAnalysisTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Summary Table
                {selectedDurationDropdownOption &&
                  " : " + selectedDurationDropdownOption.label}
                {dropdownValue.label !== undefined &&
                  dropdownValue.label !== "" &&
                  dropdownValue.label !== null &&
                  " : " + dropdownValue.label}
                <SectionDefinationsBadge
                  title={"Summary Table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Retention Analysis


  const [isTvDigitalOpen, setIsTvDigitalOpen] = useState(false)

  let defaultTvDigitalDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultTvDigitalDisabledvalues = []
  } else {
    defaultTvDigitalDisabledvalues = ["Spends"]
  }

  const [tvDigitalDisabledvalues, setTvDigitalDisabledvalues] = useState(defaultTvDigitalDisabledvalues)



  const fetchTVDigitalSummaryHandler = async () => {
    setTvDigitalSummaryIsLoading(true);
    setTvDigitalSummaryIsLoaded(false);
    setTvDigitalSummaryError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        state_code: dropdownValue.length === 0 ? null : dropdownValue.value,
        brand_id: BRAND_ID,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/tv-digital-analysis/", config);

      setTvDigitalSummaryData(res?.data?.data ?? []);
      setTvDigitalSummaryLabel(res?.data?.dimension ?? []);
      setTvDigitalSummaryIsLoading(false);
      setTvDigitalSummaryIsLoaded(true);
      setTvDigitalSummaryError(null);
    } catch (error) {
      setTvDigitalSummaryData([]);
      setTvDigitalSummaryLabel([]);
      setTvDigitalSummaryIsLoading(false);
      setTvDigitalSummaryIsLoaded(false);
      setTvDigitalSummaryError(error.code);
    }
  };

  let tvDigitalSummarySection = (
    <Loader loaderType="multiAxisMultiLineChartLoader" />
  );

  let tvDigitalSummarySection2 = (
    <Loader loaderType="multiAxisMultiLineChartLoader" />
  );
  // IF DATA IS LOADED
  if (isTvDigitalSummaryIsLoaded && !isTvDigitalSummaryIsLoading) {
    if (
      NoDataAvailableChecker(tvDigitalSummaryData) ||
      NoDataAvailableChecker(tvDigitalSummaryLabel)
    ) {
      tvDigitalSummarySection = (
        <NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" />
      );

      tvDigitalSummarySection2 = (
        <NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" />
      );
    } else {

      if (isMultiLineCluster) {
        tvDigitalSummarySection = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <DateLineChart
                chartClass="section_card_chart"
                chartData={tvDigitalSummaryData}
                chartId={`tvDigitalSummary`}
                chartLabels={tvDigitalSummaryLabel}
                showLegend={true}
                disabledLegendArray={tvDigitalDisabledvalues}
                setDisabledValues={setTvDigitalDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        )

        tvDigitalSummarySection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedDateLineChart
                chartClass="section_card_chart"
                chartData={tvDigitalSummaryData}
                chartId="tvDigitalSummary_2"
                chartLabels={tvDigitalSummaryLabel}
                showLegend={true}
                disabledLegendArray={tvDigitalDisabledvalues}
                setDisabledValues={setTvDigitalDisabledvalues}
                showLabels={true}

              />
            </ErrorBoundary>
          </Wrapper>
        );


      } else {
        tvDigitalSummarySection = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <MultiAxisMultiLine
                chartClass="section_card_chart"
                chartData={tvDigitalSummaryData}
                chartId="tvDigitalSummary"
                chartLabels={tvDigitalSummaryLabel}
                showLegend={true}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }

    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tvDigitalSummaryError) {
    tvDigitalSummarySection = (
      <ServerErrorsLoader
        chartType="multiAxisMultiLineChartType"
        error={tvDigitalSummaryError}
      />
    );
  }

  // IF DATA LOADING
  if (isTvDigitalSummaryIsLoading) {
    tvDigitalSummarySection = (
      <Loader loaderType="multiAxisMultiLineChartLoader" />
    );
  }




  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // STATE WISE KPI
  const [allStates, setAllStates] = useState([]);
  // TO FETCH METRIC  FROM API
  const fetchStateWiseKpiTableDataHandler = async () => {
    setStateWiseKpiTableDataIsLoading(true);
    setStateWiseKpiTableDataIsLoaded(false);
    setStateWiseKpiTableDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // state_code: dropdownValue === [] ? null : dropdownValue.value,
        brand_id: BRAND_ID,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("summary/state-wise-kpi-table/", config);
      setAllStates(res?.data?.data ?? []);
      setStateWiseKpiTableData(res?.data?.data ?? []);
      setStateWiseKpiTableDataHaeders(res?.data?.label ?? []);

      setStateWiseKpiTableDataIsLoading(false);
      setStateWiseKpiTableDataIsLoaded(true);
      setStateWiseKpiTableDataError(null);
    } catch (error) {
      setStateWiseKpiTableData([]);
      setStateWiseKpiTableDataHaeders([]);
      setStateWiseKpiTableDataIsLoading(false);
      setStateWiseKpiTableDataIsLoaded(false);
      setStateWiseKpiTableDataError(error.code);
    }
  };

  let stateWiseKpiTable = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              State wise KPI's

              {dropdownValue.label !== undefined &&
                dropdownValue.label !== "" &&
                dropdownValue.label !== null &&
                " : " + dropdownValue.label}
              <SectionDefinationsBadge
                title={"State wise KPI's"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isStateWiseKpiTableDataLoaded && !isStateWiseKpiTableDataLoading) {
    if (
      NoDataAvailableChecker(stateWiseKpiTableData) ||
      NoDataAvailableChecker(stateWiseKpiTableDataHeaders)
    ) {
      stateWiseKpiTable = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  State wise KPI's

                  {dropdownValue.label !== undefined &&
                    dropdownValue.label !== "" &&
                    dropdownValue.label !== null &&
                    " : " + dropdownValue.label}
                  <SectionDefinationsBadge
                    title={"State wise KPI's"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      stateWiseKpiTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "State wise KPI's" +
                `${dropdownValue.label !== undefined &&
                dropdownValue.label !== "" &&
                dropdownValue.label !== null &&
                " : " + dropdownValue.label
                }
			  `
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={stateWiseKpiTableDataHeaders}
              tableData={stateWiseKpiTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="state_wise_table"
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  platform={pagePlatform}
                  subModuleName={pageSubModule}
                  sectionId={"state_wise_table"}
                  sectionName={`GeographyFirstActiveDropdownLabels_State wise KPIs_${dropdownValue.label} `}
                  sectionData={stateWiseKpiTableData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (stateWiseKpiTableDataError) {
    stateWiseKpiTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                State wise KPI's

                {dropdownValue.label !== undefined &&
                  dropdownValue.label !== "" &&
                  dropdownValue.label !== null &&
                  " : " + dropdownValue.label}
                <SectionDefinationsBadge
                  title={"State wise KPI's"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={stateWiseKpiTableDataError}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isStateWiseKpiTableDataLoading) {
    stateWiseKpiTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                State wise KPI's

                {dropdownValue.label !== undefined &&
                  dropdownValue.label !== "" &&
                  dropdownValue.label !== null &&
                  " : " + dropdownValue.label}
                <SectionDefinationsBadge
                  title={"State wise KPI's"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }


  useEffect(() => {
    if (!isDropdownOptionsLoading && isDropdownOptionsLoaded) {

      fetchStateWiseKpiTableDataHandler();

    }
  }, [isDropdownOptionsLoading, isDropdownOptionsLoaded, selectedBrands, selectedDates]);

  useEffect(() => {
    if (!isDropdownOptionsLoading && isDropdownOptionsLoaded) {
      if (dropdownValue.label != "India") {
        var selectedStateOption = dropdownValue.length === 0 ? null : dropdownValue.label;
        var state_filter = ["India", selectedStateOption];
        var filtered = allStates.filter(function (item) {
          return state_filter.indexOf(item.geography) !== -1;
        });

        setStateWiseKpiTableData(filtered);
      }
      else {
        setStateWiseKpiTableData(allStates);
      }
    }
  }, [
    isDropdownOptionsLoading,
    isDropdownOptionsLoaded,
    dropdownValue,
  ]);




  useEffect(() => {
    if (campaignSummaryData !== undefined && campaignSummaryData.length !== 0) {
      fetchMetaOrganicReachMetricCardsHandler();
      fetchMetaPaidReachMetricCardsHandler();
    }
  }, [BRAND_ID, selectedDates, campaignSummaryData]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES

  useEffect(() => {
    fetchMetricCardsHandler();
    fetchOverallSummaryByImpressionHandler();
    fetchOverallSummaryTableHandler();
    fetchDigitalCampaignsSummaryHandler();
    /* fetchPlannedVSActualHandler(); */
    fetchPaidReachMonthlyTrendlineHandler();
    fetchSocialSummaryHandler();
    fetchVideoSummaryHandler();

    fetchHashtagSummaryHandler();
    fetchHashtagSummaryTableHandler();
    /* fetchWebsiteSummaryHandler(); */
    /* setPlannedVSActualSelectedDropdownOption({
      value: "impressions",
      label: "Impressions",
    }); */
    setSelectedPaidReachMonthlyTrendlineDropdownOption({
      value: "dv_reach",
      label: "DV360",
    });
    setSelectedSocialDropdownOption({ value: "overall", label: "Overall" });
    setSelectedHashtagDropdownOption({
      value: "engagement",
      label: "Engagements",
    });
    /* setSelectedWebsiteSummaryDropdownOption({
      value: "bounceRate_Vs_sessions",
      label: "Bounce Rate & Sessions",
    }); */
    setSelectedMidSectionDropdownDropdownOption({
      value: "overall",
      label: "Overall",
    });
    /* */
  }, [BRAND_ID, selectedDates]);

  /* 	useEffect(() => {
      fetchTVSalesSummaryHandler();
      fetchTrendAnalysisTableDataHandler();
      fetchPrimeTimeNonPrimeTimeGRPsHandler();
      fetchPremiumPositionGRPsHandler();
    }, [selectedDurationDropdownOption]);
   
   
   */





  // SALES CHANGES ONLY ON DROPDOWN CHANGES
  useEffect(() => {
    if (!isDropdownOptionsLoading && isDropdownOptionsLoaded) {
      fetchTVSalesSummaryHandler();
      fetchTrendAnalysisTableDataHandler();
      fetchPrimeTimeNonPrimeTimeGRPsHandler();
      fetchPremiumPositionGRPsHandler();
    }
  }, [
    isDropdownOptionsLoading,
    isDropdownOptionsLoaded,
    dropdownValue,
    selectedDurationDropdownOption,
  ]);

  //SALES CHANGES ONLY ON BRAND ID AND SELECTED DATES
  useEffect(() => {
    setSelectedDurationDropdownOption({
      value: "week",
      label: "Weekly",
    });
    setDropdownValue(
      dropdownOptions != [] && dropdownOptions != "" && dropdownOptions[0]
    );
  }, [BRAND_ID, selectedDates]);

  // DIGITAL AND BRAND ON REGION DROPDOWN / BRAND ID / SELECTED DATES
  useEffect(() => {
    if (!isDropdownOptionsLoading && isDropdownOptionsLoaded) {

      fetchTVDigitalSummaryHandler();
      fetchBrandWiseMarketShareTrendHandler();
    }
  }, [BRAND_ID, selectedDates, isDropdownOptionsLoading, isDropdownOptionsLoaded, dropdownValue]);




  /* useEffect(() => {
    setSelectedDurationDropdownOption(durationDropdownOptions[0]);
  }, [dropdownValue]); */

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLANNED VS METRICS

  // TO FETCH METRIC DATA FROM API
  const fetchPlannedVsDeliveredMetricsHandler = async () => {
    setPlannedVsDeliveredMetricsIsLoading(true);
    setPlannedVsDeliveredMetricsIsLoaded(false);
    setPlannedVsDeliveredMetricsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,

        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption?.value?.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption?.value?.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-chart/v3/summary",
        config
      );

      setPlannedVsDeliveredMetricsData(res?.data?.data ?? []);
      setPlannedVsDeliveredMetricsDataLabels(res?.data?.label ?? []);

      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(true);
      setPlannedVsDeliveredMetricsError(null);
    } catch (error) {
      setPlannedVsDeliveredMetricsData([]);
      setPlannedVsDeliveredMetricsDataLabels([]);
      setPlannedVsDeliveredMetricsIsLoading(false);
      setPlannedVsDeliveredMetricsIsLoaded(false);
      setPlannedVsDeliveredMetricsError(error.code);
    }
  };

  let plannedVsDeliveredMetricsBarChart = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (
    isplannedVsDeliveredMetricsLoaded &&
    !isplannedVsDeliveredMetricsLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredMetricsData) ||
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabels)
    ) {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      plannedVsDeliveredMetricsBarChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="plannedDeliveredBarChart"
              chartClass="section_card_chart"
              chartData={plannedVsDeliveredMetricsData}
              chartLabels={plannedVsDeliveredMetricsDataLabels}
              chartColorSet={BlueColorStart500}
              showLabels={true}
              minimumGridDistance={0}
              showLegend={false}
              percentageTooltip={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredMetricsError) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={plannedVsDeliveredMetricsError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isplannedVsDeliveredMetricsLoading) {
    plannedVsDeliveredMetricsBarChart = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE

  // TO FETCH METRIC  FROM API
  const fetchPlannedVsDeliveredTableDataHandler = async () => {
    setPlannedVsDeliveredTableDataIsLoading(true);
    setPlannedVsDeliveredTableDataIsLoaded(false);
    setPlannedVsDeliveredTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,

        drop_down_type:
          selectedMarketTgDropdownOption === undefined ||
            selectedMarketTgDropdownOption === null ||
            selectedMarketTgDropdownOption === ""
            ? ""
            : selectedMarketTgDropdownOption?.value?.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOption === undefined ||
            selectedRegionDropdownOption === null ||
            selectedRegionDropdownOption === ""
            ? ""
            : selectedRegionDropdownOption?.value?.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-table/v3/summary",
        config
      );
      setPlannedVsDeliveredTableData(res?.data?.data ?? []);
      setPlannedVsDeliveredTableDataHaeders(res?.data?.label ?? []);
      setPlannedVsDeliveredTableDataTotal(res?.data?.row_summary ?? [])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(true);
      setPlannedVsDeliveredTableDataError(null);
    } catch (error) {
      setPlannedVsDeliveredTableData([]);
      setPlannedVsDeliveredTableDataHaeders([]);
      setPlannedVsDeliveredTableDataTotal([])
      setPlannedVsDeliveredTableDataIsLoading(false);
      setPlannedVsDeliveredTableDataIsLoaded(false);
      setPlannedVsDeliveredTableDataError(error.code);
    }
  };

  let plannedVsDeliveredTable = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Campaign wise Planned v/s Delivered table
                {selectedMarketTgDropdownOption &&
                  " : " + selectedMarketTgDropdownOption.label}
                {selectedRegionDropdownOption &&
                  " : " + selectedRegionDropdownOption.label}
              </h2>
              <SectionDefinationsBadge
                title={"Campaign wise Planned v/s Delivered table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isPlannedVsDeliveredTableDataLoaded &&
    !isPlannedVsDeliveredTableDataLoading
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredTableData) ||
      NoDataAvailableChecker(plannedVsDeliveredTableDataHeaders)
    ) {
      plannedVsDeliveredTable = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">
                    Campaign wise Planned v/s Delivered table
                    {selectedMarketTgDropdownOption &&
                      " : " + selectedMarketTgDropdownOption.label}
                    {selectedRegionDropdownOption &&
                      " : " + selectedRegionDropdownOption.label}
                  </h2>
                  <SectionDefinationsBadge
                    title={"Campaign wise Planned v/s Delivered table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      plannedVsDeliveredTable = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Campaign wise Planned v/s Delivered table" +
                `${selectedMarketTgDropdownOption &&
                " : " + selectedMarketTgDropdownOption.label
                }
            ${selectedRegionDropdownOption &&
                " : " + selectedRegionDropdownOption.label
                }`
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={plannedVsDeliveredTableDataHeaders}
              tableData={plannedVsDeliveredTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="planned_vs_delivered_table"
              showTotalRow={plannedVsDeliveredTableDataTotal}
              showFixedSpend={true}
              calculateTotal={true}
              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  platform={pagePlatform}
                  subModuleName={pageSubModule}
                  sectionId={"planned_vs_delivered_table"}
                  sectionName={`TableDynaLabels_Meta Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
                    } ${selectedRegionDropdownOption !== undefined &&
                      selectedRegionDropdownOption !== null &&
                      selectedRegionDropdownOption !== ""
                      ? `- ` + selectedRegionDropdownOption.label
                      : ""
                    }`}
                  sectionData={concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal)}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }


  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredTableDataerror) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}
                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={plannedVsDeliveredTableDataerror}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlannedVsDeliveredTableDataLoading) {
    plannedVsDeliveredTable = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOption &&
                    " : " + selectedMarketTgDropdownOption.label}
                  {selectedRegionDropdownOption &&
                    " : " + selectedRegionDropdownOption.label}
                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PLANNED VS METRICS 2

  // TO FETCH METRIC DATA FROM API
  const fetchPlannedVsDeliveredMetricsHandlerDv = async () => {
    setPlannedVsDeliveredMetricsIsLoadingDv(true);
    setPlannedVsDeliveredMetricsIsLoadedDv(false);
    setPlannedVsDeliveredMetricsErrorDv(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,

        drop_down_type:
          selectedMarketTgDropdownOptionDv === undefined ||
            selectedMarketTgDropdownOptionDv === null ||
            selectedMarketTgDropdownOptionDv === ""
            ? ""
            : selectedMarketTgDropdownOptionDv?.value?.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOptionDv === undefined ||
            selectedRegionDropdownOptionDv === null ||
            selectedRegionDropdownOptionDv === ""
            ? ""
            : selectedRegionDropdownOptionDv?.value?.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-chart/v3/dv360",
        config
      );

      setPlannedVsDeliveredMetricsDataDv(res?.data?.data ?? []);
      setPlannedVsDeliveredMetricsDataLabelsDv(res?.data?.label ?? []);

      setPlannedVsDeliveredMetricsIsLoadingDv(false);
      setPlannedVsDeliveredMetricsIsLoadedDv(true);
      setPlannedVsDeliveredMetricsErrorDv(null);
    } catch (error) {
      setPlannedVsDeliveredMetricsDataDv([]);
      setPlannedVsDeliveredMetricsDataLabelsDv([]);
      setPlannedVsDeliveredMetricsIsLoadingDv(false);
      setPlannedVsDeliveredMetricsIsLoadedDv(false);
      setPlannedVsDeliveredMetricsErrorDv(error.code);
    }
  };

  let plannedVsDeliveredMetricsBarChartDv = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (
    isplannedVsDeliveredMetricsLoadedDv &&
    !isplannedVsDeliveredMetricsLoadingDv
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataDv) ||
      NoDataAvailableChecker(plannedVsDeliveredMetricsDataLabelsDv)
    ) {
      plannedVsDeliveredMetricsBarChartDv = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      plannedVsDeliveredMetricsBarChartDv = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="plannedDeliveredBarChart"
              chartClass="section_card_chart"
              chartData={plannedVsDeliveredMetricsDataDv}
              chartLabels={plannedVsDeliveredMetricsDataLabelsDv}
              chartColorSet={BlueColorStart500}
              showLabels={true}
              minimumGridDistance={0}
              showLegend={false}
              percentageTooltip={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredMetricsErrorDv) {
    plannedVsDeliveredMetricsBarChartDv = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={plannedVsDeliveredMetricsErrorDv}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isplannedVsDeliveredMetricsLoadingDv) {
    plannedVsDeliveredMetricsBarChartDv = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE

  // TO FETCH METRIC  FROM API
  const fetchPlannedVsDeliveredTableDataHandlerDv = async () => {
    setPlannedVsDeliveredTableDataIsLoadingDv(true);
    setPlannedVsDeliveredTableDataIsLoadedDv(false);
    setPlannedVsDeliveredTableDataErrorDv(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,

        drop_down_type:
          selectedMarketTgDropdownOptionDv === undefined ||
            selectedMarketTgDropdownOptionDv === null ||
            selectedMarketTgDropdownOptionDv === ""
            ? ""
            : selectedMarketTgDropdownOptionDv?.value?.toLowerCase(),

        drop_down_value:
          selectedRegionDropdownOptionDv === undefined ||
            selectedRegionDropdownOptionDv === null ||
            selectedRegionDropdownOptionDv === ""
            ? ""
            : selectedRegionDropdownOptionDv?.value?.toLowerCase(),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/planned-delivered-table/v3/dv360",
        config
      );
      setPlannedVsDeliveredTableDataDv(res?.data?.data ?? []);
      setPlannedVsDeliveredTableDataHaedersDv(res?.data?.label ?? []);
      setPlannedVsDeliveredTableDataTotalDv(res?.data?.row_summary ?? [])
      setPlannedVsDeliveredTableDataIsLoadingDv(false);
      setPlannedVsDeliveredTableDataIsLoadedDv(true);
      setPlannedVsDeliveredTableDataErrorDv(null);
    } catch (error) {
      setPlannedVsDeliveredTableDataDv([]);
      setPlannedVsDeliveredTableDataHaedersDv([]);
      setPlannedVsDeliveredTableDataTotalDv([])
      setPlannedVsDeliveredTableDataIsLoadingDv(false);
      setPlannedVsDeliveredTableDataIsLoadedDv(false);
      setPlannedVsDeliveredTableDataErrorDv(error.code);
    }
  };

  let plannedVsDeliveredTableDv = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Campaign wise Planned v/s Delivered table
                {selectedMarketTgDropdownOptionDv &&
                  " : " + selectedMarketTgDropdownOptionDv.label}
                {selectedRegionDropdownOptionDv &&
                  " : " + selectedRegionDropdownOptionDv.label}
              </h2>
              <SectionDefinationsBadge
                title={"Campaign wise Planned v/s Delivered table"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4 section_card_body">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (
    isPlannedVsDeliveredTableDataLoadedDv &&
    !isPlannedVsDeliveredTableDataLoadingDv
  ) {
    if (
      NoDataAvailableChecker(plannedVsDeliveredTableDataDv) ||
      NoDataAvailableChecker(plannedVsDeliveredTableDataHeadersDv)
    ) {
      plannedVsDeliveredTableDv = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <div className="section_info">
                  <h2 className="section_card_title">
                    Campaign wise Planned v/s Delivered table
                    {selectedMarketTgDropdownOptionDv &&
                      " : " + selectedMarketTgDropdownOptionDv.label}
                    {selectedRegionDropdownOptionDv &&
                      " : " + selectedRegionDropdownOptionDv.label}
                  </h2>
                  <SectionDefinationsBadge
                    title={"Campaign wise Planned v/s Delivered table"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </div>
              </div>
            </div>
            <div className="grid col_span_4 section_card_body">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      plannedVsDeliveredTableDv = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableTitle={
                "Campaign wise Planned v/s Delivered Table" +
                `${selectedMarketTgDropdownOptionDv &&
                " : " + selectedMarketTgDropdownOptionDv.label
                }
            ${selectedRegionDropdownOptionDv &&
                " : " + selectedRegionDropdownOptionDv.label
                }`
              }
              tablePageSubModule={pageSubModule}
              tablePagePlatform={pagePlatform}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={plannedVsDeliveredTableDataHeadersDv}
              tableData={plannedVsDeliveredTableDataDv}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search table"}
              tableID="planned_vs_delivered_table_dv"
              showFixedSpend={true}
              calculateTotal={true}
              showTotalRow={plannedVsDeliveredTableDataTotalDv}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"planned_vs_delivered_table_dv"}
                  sectionName={`TableDynaLabels_Meta Planned vs Delivered Table_${` : ` + selectedMarketTgDropdownOption.label
                    } ${selectedRegionDropdownOptionDv !== undefined &&
                      selectedRegionDropdownOptionDv !== null &&
                      selectedRegionDropdownOptionDv !== ""
                      ? `- ` + selectedRegionDropdownOptionDv.label
                      : ""
                    }`}
                  sectionData={concateTotals(plannedVsDeliveredTableDataDv, plannedVsDeliveredTableDataTotalDv)}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (plannedVsDeliveredTableDataerrorDv) {
    plannedVsDeliveredTableDv = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOptionDv &&
                    " : " + selectedMarketTgDropdownOptionDv.label}
                  {selectedRegionDropdownOptionDv &&
                    " : " + selectedRegionDropdownOptionDv.label}
                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <ServerErrorsLoader
                error={plannedVsDeliveredTableDataerrorDv}
                chartType="tableChartType"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPlannedVsDeliveredTableDataLoadingDv) {
    plannedVsDeliveredTableDv = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign wise Planned v/s Delivered table
                  {selectedMarketTgDropdownOptionDv &&
                    " : " + selectedMarketTgDropdownOptionDv.label}
                  {selectedRegionDropdownOptionDv &&
                    " : " + selectedRegionDropdownOptionDv.label}
                </h2>
                <SectionDefinationsBadge
                  title={"Campaign wise Planned v/s Delivered table"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API


  const fetchFirstTableDataHandler = async () => {
    setFirstTableDataIsLoading(true);
    setFirstTableDataIsLoaded(false);
    setFirstTableDataError(null);
    setSecondTableDataIsLoading(true);
    setSecondTableDataIsLoaded(false);
    setSecondTableDataError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      let brand = selectedFirstBrandOption.value.toLowerCase();
      let month = selectedFirstMonthOption.value;
      let secondBrand = selectedSecondBrandOption.value.toLowerCase();
      let secondMonth = selectedSecondMonthOption.value


      const res = await axios.get(
        "summary/brand-wise-monthly-tv-reach-summary-table/",
        config
      );

      setAllTableData(res?.data?.data ?? []);

      setFirstTableData(res?.data?.data[`${brand.toString()}`][`${month}`] ?? []);

      setFirstTableHeaderData(res?.data?.label ?? []);


      setSecondTableData(res?.data?.data[`${secondBrand.toString()}`][`${secondMonth}`] ?? []);

      setSecondTableHeaderData(res?.data?.label ?? []);


      setFirstTableDataIsLoading(false);
      setFirstTableDataIsLoaded(true);
      setFirstTableDataError(null);
      setSecondTableDataIsLoading(false);
      setSecondTableDataIsLoaded(true);
      setSecondTableDataError(null);
    } catch (error) {
      setFirstTableDataIsLoading(false);
      setFirstTableDataIsLoaded(false);
      setFirstTableDataError(error.code);

      setSecondTableDataIsLoading(false);
      setSecondTableDataIsLoaded(false);
      setSecondTableDataError(error.code);
    }
  };

  let firstTableDataTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Monthly TV Reach Summary
              {selectedFirstBrandOption.label !== undefined &&
                selectedFirstBrandOption.label !== "" &&
                selectedFirstBrandOption.label !== null &&
                " : " + selectedFirstBrandOption.label}

              {selectedFirstMonthOption.label !== undefined &&
                selectedFirstMonthOption.label !== "" &&
                selectedFirstMonthOption.label !== null &&
                " : " + selectedFirstMonthOption.label}
              <SectionDefinationsBadge
                title={"Monthly TV Reach Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>

        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isFirstTableDataLoaded && !isFirstTableDataLoading) {
    if (
      NoDataAvailableChecker(firstTableData) ||
      NoDataAvailableChecker(firstTableHeaderData)
    ) {
      firstTableDataTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Monthly TV Reach Summary
                  {selectedFirstBrandOption.label !== undefined &&
                    selectedFirstBrandOption.label !== "" &&
                    selectedFirstBrandOption.label !== null &&
                    " : " + selectedFirstBrandOption.label}

                  {selectedFirstMonthOption.label !== undefined &&
                    selectedFirstMonthOption.label !== "" &&
                    selectedFirstMonthOption.label !== null &&
                    " : " + selectedFirstMonthOption.label}
                  <SectionDefinationsBadge
                    title={"Monthly TV Reach Summary"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>

            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      firstTableDataTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable

              tableTitle={
                "Monthly TV Reach Summary" + `${selectedFirstBrandOption !== undefined &&
                  selectedFirstBrandOption !== null &&
                  selectedFirstBrandOption !== ""
                  ? `- ` + selectedFirstBrandOption.label
                  : ""
                } ${selectedFirstMonthOption !== undefined &&
                  selectedFirstMonthOption !== null &&
                  selectedFirstMonthOption !== ""
                  ? `- ` + selectedFirstMonthOption.label
                  : ""
                }`
              }


              tablePagePlatform={pagePlatform}
              tablePageSubModule={pageSubModule}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={firstTableHeaderData}
              tableData={firstTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search"}
              tableID={"first_table"}

              tableExportDropdown={
                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"first_table"}
                  sectionName={`BrandWiseFirstActiveDropdownLabels_Reach ${selectedFirstBrandOption !== undefined &&
                    selectedFirstBrandOption !== null &&
                    selectedFirstBrandOption !== ""
                    ? selectedFirstBrandOption.label
                    : ""
                    } B1_${selectedFirstBrandOption !== undefined &&
                      selectedFirstBrandOption !== null &&
                      selectedFirstBrandOption !== ""
                      ? `- ` + selectedFirstBrandOption.label
                      : ""
                    } ${selectedFirstMonthOption !== undefined &&
                      selectedFirstMonthOption !== null &&
                      selectedFirstMonthOption !== ""
                      ? `- ` + selectedFirstMonthOption.label
                      : ""
                    }`}
                  sectionData={firstTableData}
                />
              }



            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (firstTableDataerror) {
    firstTableDataTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Monthly TV Reach Summary
                {selectedFirstBrandOption.label !== undefined &&
                  selectedFirstBrandOption.label !== "" &&
                  selectedFirstBrandOption.label !== null &&
                  " : " + selectedFirstBrandOption.label}

                {selectedFirstMonthOption.label !== undefined &&
                  selectedFirstMonthOption.label !== "" &&
                  selectedFirstMonthOption.label !== null &&
                  " : " + selectedFirstMonthOption.label}
                <SectionDefinationsBadge
                  title={"Monthly TV Reach Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>

          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={firstTableDataerror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFirstTableDataLoading) {
    firstTableDataTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Monthly TV Reach Summary
                {selectedFirstBrandOption.label !== undefined &&
                  selectedFirstBrandOption.label !== "" &&
                  selectedFirstBrandOption.label !== null &&
                  " : " + selectedFirstBrandOption.label}

                {selectedFirstMonthOption.label !== undefined &&
                  selectedFirstMonthOption.label !== "" &&
                  selectedFirstMonthOption.label !== null &&
                  " : " + selectedFirstMonthOption.label}
                <SectionDefinationsBadge
                  title={"Monthly TV Reach Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>

          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }


  let secondTableDataTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">

        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Monthly TV Reach Summary
              {selectedSecondBrandOption.label !== undefined &&
                selectedSecondBrandOption.label !== "" &&
                selectedSecondBrandOption.label !== null &&
                " : " + selectedSecondBrandOption.label}

              {selectedSecondMonthOption.label !== undefined &&
                selectedSecondMonthOption.label !== "" &&
                selectedSecondMonthOption.label !== null &&
                " : " + selectedSecondMonthOption.label}
              <SectionDefinationsBadge
                title={"Monthly TV Reach Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>

        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isSecondTableDataLoaded && !isSecondTableDataLoading) {
    if (
      NoDataAvailableChecker(secondTableData) ||
      NoDataAvailableChecker(secondTableHeaderData)
    ) {
      secondTableDataTable = (
        <Wrapper>
          <div className="grid grid_cols_4 fullpage_section_card section_card">
            <div className="grid col_span_4">
              <div className="section_card_header">
                <h2 className="section_card_title">
                  Monthly TV Reach Summary
                  {selectedSecondBrandOption.label !== undefined &&
                    selectedSecondBrandOption.label !== "" &&
                    selectedSecondBrandOption.label !== null &&
                    " : " + selectedSecondBrandOption.label}

                  {selectedSecondMonthOption.label !== undefined &&
                    selectedSecondMonthOption.label !== "" &&
                    selectedSecondMonthOption.label !== null &&
                    " : " + selectedSecondMonthOption.label}
                  <SectionDefinationsBadge
                    title={"Monthly TV Reach Summary"}
                    module={pageSubModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                  />
                </h2>
              </div>
            </div>

            <div className="grid col_span_4">
              <div className="section_card_body">
                <NoDataAvailableLoader chartType="tableChartType" />
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      secondTableDataTable = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableTitle={
                "Monthly TV Reach Summary" + `${selectedSecondBrandOption !== undefined &&
                  selectedSecondBrandOption !== null &&
                  selectedSecondBrandOption !== ""
                  ? `- ` + selectedSecondBrandOption.label
                  : ""
                } ${selectedSecondMonthOption !== undefined &&
                  selectedSecondMonthOption !== null &&
                  selectedSecondMonthOption !== ""
                  ? `- ` + selectedSecondMonthOption.label
                  : ""
                }`
              }
              tablePagePlatform={pagePlatform}
              tablePageSubModule={pageSubModule}
              tablePagePrimaryTab={pagePrimaryTab}
              tableHeader={secondTableHeaderData}
              tableData={secondTableData}
              tableLength={10}
              isFooterShow={true}
              searchPlaceHolder={"Search"}
              tableID={"second_table"}
              tableExportDropdown={


                <ExportDropdown
                  moduleName={pageModule}
                  subModuleName={pageSubModule}
                  platform={pagePlatform}
                  tab={pagePrimaryTab}
                  sectionId={"second_table"}
                  sectionName={`BrandWiseFirstActiveDropdownLabels_Reach ${selectedSecondBrandOption !== undefined &&
                    selectedSecondBrandOption !== null &&
                    selectedSecondBrandOption !== ""
                    ? selectedSecondBrandOption.label
                    : ""
                    } B2_${selectedSecondBrandOption !== undefined &&
                      selectedSecondBrandOption !== null &&
                      selectedSecondBrandOption !== ""
                      ? `- ` + selectedSecondBrandOption.label
                      : ""
                    } ${selectedSecondMonthOption !== undefined &&
                      selectedSecondMonthOption !== null &&
                      selectedSecondMonthOption !== ""
                      ? `- ` + selectedSecondMonthOption.label
                      : ""
                    }`}
                  sectionData={secondTableData}
                />
              }
            />


          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (secondTableDataerror) {
    secondTableDataTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Monthly TV Reach Summary
                {selectedSecondBrandOption.label !== undefined &&
                  selectedSecondBrandOption.label !== "" &&
                  selectedSecondBrandOption.label !== null &&
                  " : " + selectedSecondBrandOption.label}

                {selectedSecondMonthOption.label !== undefined &&
                  selectedSecondMonthOption.label !== "" &&
                  selectedSecondMonthOption.label !== null &&
                  " : " + selectedSecondMonthOption.label}
                <SectionDefinationsBadge
                  title={"Monthly TV Reach Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>

          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader
                chartType="tableChartType"
                error={secondTableDataerror}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isSecondTableDataLoading) {
    secondTableDataTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Monthly TV Reach Summary
                {selectedSecondBrandOption.label !== undefined &&
                  selectedSecondBrandOption.label !== "" &&
                  selectedSecondBrandOption.label !== null &&
                  " : " + selectedSecondBrandOption.label}

                {selectedSecondMonthOption.label !== undefined &&
                  selectedSecondMonthOption.label !== "" &&
                  selectedSecondMonthOption.label !== null &&
                  " : " + selectedSecondMonthOption.label}
                <SectionDefinationsBadge
                  title={"Monthly TV Reach Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>

          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  useEffect(() => {
    if (!brandIsLoading && brandIsLoaded) {
      fetchFirstTableDataHandler();
    }
  }, [

    brandIsLoading,
    brandIsLoaded
  ]);


  useEffect(() => {
    if (!brandIsLoading && brandIsLoaded) {
      if (allTableData.length === 0 || Object.values(allTableData).length === 0) {
        setFirstTableData([]);
      }
      else {
        if (allTableData[`${selectedFirstBrandOption !== undefined &&
          selectedFirstBrandOption !== null &&
          selectedFirstBrandOption !== ""
          ? selectedFirstBrandOption.value.toLowerCase()
          : ""}`] === undefined || allTableData[`${selectedFirstBrandOption !== undefined &&
            selectedFirstBrandOption !== null &&
            selectedFirstBrandOption !== ""
            ? selectedFirstBrandOption.value.toLowerCase()
            : ""}`][`${selectedFirstMonthOption !== undefined &&
              selectedFirstMonthOption !== null &&
              selectedFirstMonthOption !== ""
              ? selectedFirstMonthOption.value.toLowerCase()
              : ""}`] === undefined) {
          setFirstTableData([]);
        }
        else {
          setFirstTableData(allTableData[`${selectedFirstBrandOption !== undefined &&
            selectedFirstBrandOption !== null &&
            selectedFirstBrandOption !== ""
            ? selectedFirstBrandOption.value.toLowerCase()
            : ""}`][`${selectedFirstMonthOption !== undefined &&
              selectedFirstMonthOption !== null &&
              selectedFirstMonthOption !== ""
              ? selectedFirstMonthOption.value.toLowerCase()
              : ""}`]);
        }
      }

    }
  }, [
    brandIsLoading,
    brandIsLoaded,
    selectedFirstBrandOption,
    selectedFirstMonthOption,
  ]);



  useEffect(() => {
    if (!brandIsLoading && brandIsLoaded) {
      if (allTableData.length === 0 || Object.values(allTableData).length === 0) {
        setSecondTableData([]);
      }
      else {
        if (allTableData[`${selectedSecondBrandOption !== undefined &&
          selectedSecondBrandOption !== null &&
          selectedSecondBrandOption !== ""
          ? selectedSecondBrandOption.value.toLowerCase()
          : ""}`] === undefined || allTableData[`${selectedSecondBrandOption !== undefined &&
            selectedSecondBrandOption !== null &&
            selectedSecondBrandOption !== ""
            ? selectedSecondBrandOption.value.toLowerCase()
            : ""}`][`${selectedSecondMonthOption !== undefined &&
              selectedSecondMonthOption !== null &&
              selectedSecondMonthOption !== ""
              ? selectedSecondMonthOption.value.toLowerCase()
              : ""}`] === undefined) {
          setSecondTableData([]);
        }
        else {
          setSecondTableData(allTableData[`${selectedSecondBrandOption !== undefined &&
            selectedSecondBrandOption !== null &&
            selectedSecondBrandOption !== ""
            ? selectedSecondBrandOption.value.toLowerCase()
            : ""}`][`${selectedSecondMonthOption !== undefined &&
              selectedSecondMonthOption !== null &&
              selectedSecondMonthOption !== ""
              ? selectedSecondMonthOption.value.toLowerCase()
              : ""}`]);
        }
      }

    }
  }, [
    brandIsLoading,
    brandIsLoaded,
    selectedSecondBrandOption,
    selectedSecondMonthOption,
  ]);


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND WISE TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchCokeBrandPostCompareHandler = async () => {
    setCokeBrandPostCompareIsLoading(true);
    setCokeBrandPostCompareIsLoaded(false);
    setCokeBrandPostCompareError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/summary/summary-top-brands/", config);
      setCokeBrandPostCompare(res?.data ?? []);
      setCokeBrandPostCompareIsLoading(false);
      setCokeBrandPostCompareIsLoaded(true);
      setCokeBrandPostCompareError(null);
    } catch (error) {
      setCokeBrandPostCompare([]);
      setCokeBrandPostCompareIsLoading(false);
      setCokeBrandPostCompareIsLoaded(false);
      setCokeBrandPostCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let cokeBrandPostCompareFeat = (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_top">
        <div className="section_card_header">
          <h2 className="section_card_title">
            Top Brands
            <SectionDefinationsBadge
              title={"Top Brands"}
              module={pageSubModule}
              platform={pagePlatform}
              section={pagePrimaryTab}
            />
          </h2>

        </div>
        <div className="grid col_span_4">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    </Wrapper>
  );



  // IF DATA IS LOADED
  if (isCokeBrandPostCompareLoaded && !isCokeBrandPostCompareLoading) {
    if (NoDataAvailableChecker(cokeBrandPostCompare)) {
      cokeBrandPostCompareFeat = (
        <Wrapper>
          <div className="section_card fullpage_section_card grid grid_margin_top">

            <div className="section_card_header">
              <h2 className="section_card_title">
                Top Brands
                <SectionDefinationsBadge
                  title={"Top Brands"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>


            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      cokeBrandPostCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <CokeCompare
              products={cokeBrandPostCompare}

              Title={"Top Brands"}
              tabClear={unCheck}
              salesBrand={true}

              hasExportDropdown={true}


            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cokeBrandPostCompareError) {
    cokeBrandPostCompareFeat = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid grid_margin_top">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Top Brands
              <SectionDefinationsBadge
                title={"Top Brands"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>

          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={cokeBrandPostCompareError}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCokeBrandPostCompareLoading) {
    cokeBrandPostCompareFeat = (
      <Wrapper>
        <div className="section_card fullpage_section_card grid grid_margin_top">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Top Brands
              <SectionDefinationsBadge
                title={"Top Brands"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>

          <div className="grid col_span_4">
            <Loader loaderType="cardLoader" variantType="compare" />
          </div>
        </div>
      </Wrapper>
    );
  }


  useEffect(() => {
    fetchCokeBrandPostCompareHandler();

  }, [
    selectedDates
  ]);

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };


  var barChartCSV = {
    [`dvBar_Meta PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `- ` + selectedRegionDropdownOption.label
        : ""
      }`]: plannedVsDeliveredMetricsData,
  };




  var brandWiseMonthlyTvReach = {
    [`BrandWiseFirstActiveDropdownLabels_Reach ${selectedFirstBrandOption !== undefined &&
      selectedFirstBrandOption !== null &&
      selectedFirstBrandOption !== ""
      ? selectedFirstBrandOption.label
      : ""
      } B1_${selectedFirstBrandOption !== undefined &&
        selectedFirstBrandOption !== null &&
        selectedFirstBrandOption !== ""
        ? `- ` + selectedFirstBrandOption.label
        : ""
      } ${selectedFirstMonthOption !== undefined &&
        selectedFirstMonthOption !== null &&
        selectedFirstMonthOption !== ""
        ? `- ` + selectedFirstMonthOption.label
        : ""
      }`]: firstTableData,

    [`BrandWiseFirstActiveDropdownLabels_Reach ${selectedSecondBrandOption !== undefined &&
      selectedSecondBrandOption !== null &&
      selectedSecondBrandOption !== ""
      ? selectedSecondBrandOption.label
      : ""
      } B2_${selectedSecondBrandOption !== undefined &&
        selectedSecondBrandOption !== null &&
        selectedSecondBrandOption !== ""
        ? `- ` + selectedSecondBrandOption.label
        : ""
      } ${selectedSecondMonthOption !== undefined &&
        selectedSecondMonthOption !== null &&
        selectedSecondMonthOption !== ""
        ? `- ` + selectedSecondMonthOption.label
        : ""
      }`]: secondTableData,
  };




  var tableCSV = {
    [`TableDynaLabels_Meta PvsD Table_${` : ` + selectedMarketTgDropdownOption.label
      } ${selectedRegionDropdownOption !== undefined &&
        selectedRegionDropdownOption !== null &&
        selectedRegionDropdownOption !== ""
        ? `- ` + selectedRegionDropdownOption.label
        : ""
      }`]: concateTotals(plannedVsDeliveredTableData, plannedVsDeliveredTableDataTotal),
  };

  var barChartCSVDv = {
    [`dvBar_Dv PvsD BarChart_${` : ` + selectedMarketTgDropdownOptionDv.label
      } ${selectedRegionDropdownOptionDv !== undefined &&
        selectedRegionDropdownOptionDv !== null &&
        selectedRegionDropdownOptionDv !== ""
        ? `- ` + selectedRegionDropdownOptionDv.label
        : ""
      }`]: plannedVsDeliveredMetricsDataDv,
  };

  var tableCSVDv = {
    [`TableDynaLabels_Dv PvsD Table_${` : ` + selectedMarketTgDropdownOptionDv.label
      } ${selectedRegionDropdownOptionDv !== undefined &&
        selectedRegionDropdownOptionDv !== null &&
        selectedRegionDropdownOptionDv !== ""
        ? `- ` + selectedRegionDropdownOptionDv.label
        : ""
      }`]: concateTotals(plannedVsDeliveredTableDataDv, plannedVsDeliveredTableDataTotalDv),
  };

  var DigitalCampaignExport = {
    [`DateFirstActiveDropdownLabels_Digital Campaign_${selectedMidSectionDropdownOption.label}`]:
      digitalCampaignsSummaryData,
    [`CokeSummaryDigitalDetails_Digital Campaign Details_${selectedMidSectionDropdownOption.label.replace(
      "_",
      "-"
    )}`]: digitalCampaignsSummaryDetails,
  };

  var PTPPExport = {
    [`DateFirstActiveDropdownLabels_Prime Time GRPs_${selectedDurationDropdownOption !== undefined &&
      selectedDurationDropdownOption !== null &&
      selectedDurationDropdownOption !== ""
      ? selectedDurationDropdownOption.label
      : ""
      } ${`- ` + dropdownValue.label} `]: primetTimeGRPSData,
    [`DateFirstActiveDropdownLabels_Premium Position GRPs_${selectedDurationDropdownOption !== undefined &&
      selectedDurationDropdownOption !== null &&
      selectedDurationDropdownOption !== ""
      ? selectedDurationDropdownOption.label
      : ""
      } ${`- ` + dropdownValue.label} `]: premiumPositionGRPSData,
  };

  var allData = {
    Summary: exportSummary,
    "PlatformCards_Overall Summary": combineMetricCardsData,
    [`DateFirstActiveDropdownLabels_TV & Sales Summary_${selectedDurationDropdownOption !== undefined &&
      selectedDurationDropdownOption !== null &&
      selectedDurationDropdownOption !== ""
      ? selectedDurationDropdownOption.label
      : ""
      } ${`- ` + dropdownValue.label} `]: trendLineAnalysis,
    ...PTPPExport,
    [`DateFirstActiveDropdownLabels_TV + Digital Summary_${dropdownValue.label}`]:
      combineCSV(tvDigitalSummaryData),
    [`DateFirstActiveDropdownLabels_Brand Wise Market_${selectedBrandWiseMarketShareTrendOption.label + "- " + dropdownValue.label
      }`]: brandWiseData,
    [`GeographyFirstActiveDropdownLabels_State wise KPIs_${dropdownValue.label}`]:
      stateWiseKpiTableData,

    ...brandWiseMonthlyTvReach,
    "CokeSummaryOverallImpressions_Overall Summary Impressions":
      overallSummaryByImpressionData,
    [`Overall Summary Impression Tbl`]: overallSummaryTable,
    ...DigitalCampaignExport,
    ...barChartCSV,
    ...tableCSV,
    ...barChartCSVDv,
    ...tableCSVDv,


    /* "CokeSummaryPlannedvsActual_Planned vs Actual": plannedVSActualData, */

    [`DateFirstActiveDropdownLabels_Paid Reach Monthly Trendline_${selectedPaidReachMonthlyTrendlineDropdownOption.label}`]:
      paidReachMonthlyTrendlineData,
    [`DateFirstActiveDropdownLabels_Social Summary_${selectedSocialDropdownOption.label}`]:
      socialSummaryData,
    "CokeSummaryVideoSummary_Video Summary": videoSummaryData,
    [`DateFirstActiveDropdownLabels_Video Analysis_${selectedVideoAnalysisDropdownOption.label}`]: DateIntToStd(videoAnalysisData),
    [`TableDynaLabels_Video Analysis table_${` -` + selectedVideoAnalysisDropdownOption.label}`]: videoAnalysisTableData,
    [`CokeSummaryHashtag_Hashtag Summary_${selectedHashtagDropdownOption.label}`]:
      hashtagSummaryData,
    [`Hashtag Summary Table`]: hashtagSummaryTable,
    /* 	"DateFirstFormatter_Website Summary": DateIntToStd(websiteSummaryData), */
    "Compare_Top Brand": cokeBrandPostCompare,
  };

  var brandsExport = (function () {
    if (selectedBrands === undefined || selectedBrands.length === 0) {
      return { Brand: "" };
    } else {
      var obj = {};
      for (var i = 0; i < selectedBrands.length; i++) {
        if (selectedBrands.length === 1) {
          obj["Brand"] = selectedBrands[i].label;
        } else {
          obj["Brand " + (i + 1)] = selectedBrands[i].label;
        }
      }
      return obj;
    }
  })();

  useEffect(() => {
    setexportSummary([selectedDates, brandsExport]);
    setExportPageName("Coke Summary");
    setFullPageExport(allData);
  }, [
    combineMetricCardsData,
    overallSummaryByImpressionData,
    digitalCampaignsSummaryData,
    digitalCampaignsSummaryDetails,
    /* 	plannedVSActualData, */
    socialSummaryData,
    videoSummaryData,
    videoAnalysisData,
    hashtagSummaryData,
    hashtagSummaryTable,
    /* 	websiteSummaryData, */
    trendLineAnalysis,
    tvDigitalSummaryData,
    brandWiseData,
    firstTableData,
    secondTableData,
    primetTimeGRPSData,
    /* 	nonPrimetTimeGRPSData, */
    selectedDates,
    selectedBrands,
    // tvDigitalSummaryData,
    plannedVsDeliveredMetricsData,
    plannedVsDeliveredTableData,
    plannedVsDeliveredMetricsDataDv,
    plannedVsDeliveredTableDataDv,
    cokeBrandPostCompare,
    plannedVsDeliveredTableDataTotal,
    plannedVsDeliveredTableDataTotalDv
  ]);

  useEffect(() => {
    if (
      (campaignSummaryError !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false)) &&
      (overallSummaryByImpressionError !== null ? true : isOverallSummaryByImpressionLoaded && (isOverallSummaryByImpressionLoading === false)) &&
      (overallSummaryTableError !== null ? true : isOverallSummaryTableLoaded && (isOverallSummaryTableLoading === false)) &&
      (digitalCampaignsError !== null ? true : isDigitalCampaignsLoaded && (isDigitalCampaignsLoading === false)) &&
      (socialSummaryError !== null ? true : isSocialSummaryLoaded && (isSocialSummaryLoading === false)) &&
      (videoSummaryError !== null ? true : isVideoSummaryLoaded && (isVideoSummaryLoading === false)) &&
      (videoAnalysisError !== null ? true : isVideoAnalysisLoaded && (isVideoAnalysisLoading === false)) &&
      (hashtagSummaryerror !== null ? true : isHashtagSummaryLoaded && (isHashtagSummaryLoading === false)) &&
      (hashtagSummaryTableError !== null ? true : isHashtagSummaryTableLoaded && (isHashtagSummaryTableLoading === false)) &&
      (trendLineAnalysiserror !== null ? true : isTrendLineAnalysisLoaded && (isTrendLineAnalysisLoading === false)) &&
      (tvDigitalSummaryError !== null ? true : isTvDigitalSummaryIsLoaded && (isTvDigitalSummaryIsLoading === false)) &&
      (brandWiseError !== null ? true : isBrandWiseLoaded && (isBrandWiseLoading === false)) &&
      (stateWiseKpiTableDataError !== null ? true : isStateWiseKpiTableDataLoaded && (isStateWiseKpiTableDataLoading === false)) &&
      (firstTableDataerror !== null ? true : isFirstTableDataLoaded && (isFirstTableDataLoading === false)) &&
      (secondTableDataerror !== null ? true : isSecondTableDataLoaded && (isSecondTableDataLoading === false)) &&
      (primetTimeGRPSError !== null ? true : isPrimetTimeGRPSLoaded && (isPrimetTimeGRPSLoading === false)) &&
      (premiumPositionGRPSError !== null ? true : isPremiumPositionGRPSLoaded && (isPremiumPositionGRPSLoading === false)) &&
      (plannedVsDeliveredTableDataerror !== null ? true : isPlannedVsDeliveredTableDataLoaded && (isPlannedVsDeliveredTableDataLoading === false)) &&
      (plannedVsDeliveredMetricsError !== null ? true : isplannedVsDeliveredMetricsLoaded && (isplannedVsDeliveredMetricsLoading === false)) &&
      (plannedVsDeliveredTableDataerror !== null ? true : isPlannedVsDeliveredTableDataLoadedDv && (isPlannedVsDeliveredTableDataLoading === false)) &&
      (plannedVsDeliveredMetricsErrorDv !== null ? true : isplannedVsDeliveredMetricsLoadedDv && (isplannedVsDeliveredMetricsLoadingDv === false)) &&
      (cokeBrandPostCompareError !== null ? true : isCokeBrandPostCompareLoaded && (isCokeBrandPostCompareLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }

  }, [
    campaignSummaryError, isCampaignSummaryLoaded, isCampaignSummaryLoading,
    overallSummaryByImpressionError, isOverallSummaryByImpressionLoaded, isOverallSummaryByImpressionLoading,
    overallSummaryTableError, isOverallSummaryTableLoaded, isOverallSummaryTableLoading,
    digitalCampaignsError, isDigitalCampaignsLoaded, isDigitalCampaignsLoading,
    socialSummaryError, isSocialSummaryLoaded, isSocialSummaryLoading,
    videoSummaryError, isVideoSummaryLoaded, isVideoSummaryLoading,
    videoAnalysisError, isVideoAnalysisLoaded, isVideoAnalysisLoading,
    hashtagSummaryerror, isHashtagSummaryLoaded, isHashtagSummaryLoading,
    hashtagSummaryTableError, isHashtagSummaryTableLoaded, isHashtagSummaryTableLoading,
    trendLineAnalysiserror, isTrendLineAnalysisLoaded, isTrendLineAnalysisLoading,
    tvDigitalSummaryError, isTvDigitalSummaryIsLoaded, isTvDigitalSummaryIsLoading,
    brandWiseError, isBrandWiseLoaded, isBrandWiseLoading,
    stateWiseKpiTableDataError, isStateWiseKpiTableDataLoaded, isStateWiseKpiTableDataLoading,
    firstTableDataerror, isFirstTableDataLoaded, isFirstTableDataLoading,
    secondTableDataerror, isSecondTableDataLoaded, isSecondTableDataLoading,
    primetTimeGRPSError, isPrimetTimeGRPSLoaded, isPrimetTimeGRPSLoading,
    premiumPositionGRPSError, isPremiumPositionGRPSLoaded, isPremiumPositionGRPSLoading,
    plannedVsDeliveredTableDataerror, isPlannedVsDeliveredTableDataLoaded, isPlannedVsDeliveredTableDataLoading,
    plannedVsDeliveredMetricsError, isplannedVsDeliveredMetricsLoaded, isplannedVsDeliveredMetricsLoading,
    plannedVsDeliveredTableDataerror, isPlannedVsDeliveredTableDataLoadedDv, isPlannedVsDeliveredTableDataLoading,
    plannedVsDeliveredMetricsErrorDv, isplannedVsDeliveredMetricsLoadedDv, isplannedVsDeliveredMetricsLoadingDv,
    cokeBrandPostCompareError, isCokeBrandPostCompareLoaded, isCokeBrandPostCompareLoading,
  ])

  function DateIntToStd(data) {
    var temp =
      data === undefined || data === null
        ? []
        : data[0] === undefined
          ? []
          : typeof data[0].label === "number"
            ? Object.values(data).map((item) => {
              var date = new Date(item.label);
              return { ...item, label: date.toLocaleDateString("en-GB") };
            })
            : data;

    return temp;
  }


  const [isOpen, setIsOpen] = useState(false);
  const [isTvSummaryOpen, setIsTvSummaryOpen] = useState(false)


  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <PageHeader pageTitle={"Summary"} />
          <div className="page_content">
            <div className="dashboard_home">


              {campaignSummary}

              {/*                    ------- 0.00/<NumberFormatter number={0.00} /><br/>
                             ------- 0.0870432/<NumberFormatter number={0.0870432} /><br/>
      
                            ------- 0.000870432/<NumberFormatter number={0.000870432} variant={"money"} title={"digital spends"}/><br/>
                            -------  0.0000876804922861094/<NumberFormatter number={ 0.0000876804922861094} /><br/>
                            -------  0.00000476804922861094/<NumberFormatter number={ 0.00000476804922861094} /><br/>
                            ------- 0.000012345/<NumberFormatter number={0.000012345} /><br/>
                            ------- 0.146451762523191/<NumberFormatter number={0.146451762523191} /><br/> */}


              <div className="section_card section_dropdown_header">
                <div className="grid grid_cols_1">
                  <div className="section_card_header">
                    <div className="grid grid_cols_2">
                      <div className="grid cols_span_1">
                        <div className="section_info">
                          <h2 className="section_card_title">
                            TV & Sales Summary

                          </h2>
                        </div>
                      </div>
                      <div className="grid cols_span_3 flex justify_end">
                        <Dropdown
                          ismulti={false}
                          placeholder={"Select Week/Month"}
                          options={durationDropdownOptions}
                          value={selectedDurationDropdownOption}
                          selectedOptions={selectedDurationDropdownOption}
                          setStatedropdown={durationDropdownSelectionHandler}
                          className="form_dropdown section_dropdown"
                        />
                        <Dropdown
                          ismulti={false}
                          dropdownLoading={isDropdownOptionsLoading}
                          placeholder={"Select Region"}
                          options={dropdownOptions}
                          value={dropdownValue}
                          selectedOptions={dropdownValue}
                          setStatedropdown={dropdownSelectionHandler}
                          className="form_dropdown section_dropdown"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="tv_sales_summary_section"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
              >
                <div className="grid col_span_4">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        Summary
                        {selectedDurationDropdownOption &&
                          " : " + selectedDurationDropdownOption.label}
                        {dropdownValue.label !== undefined &&
                          dropdownValue.label !== "" &&
                          dropdownValue.label !== null &&
                          " : " + dropdownValue.label}
                      </h2>
                      <SectionDefinationsBadge
                        title="Summary"
                        module={pageSubModule}
                        platform={pagePlatform}
                        section={pageSection}
                      />
                    </div>

                    <Modal
                      open={isTvSummaryOpen}
                      setOpen={setIsTvSummaryOpen}
                      exportDropdown={
                        <ExportDropdown
                          moduleName={pageModule}
                          platform={pagePlatform}
                          subModuleName={pageSubModule}
                          sectionId="trendLineAnalysis_lineChart"
                          sectionName={`DateFirstActiveDropdownLabels_TV & Sales Summary_
												${selectedDurationDropdownOption !== undefined &&
                              selectedDurationDropdownOption !== null &&
                              selectedDurationDropdownOption !== ""
                              ? selectedDurationDropdownOption.label
                              : ""
                            }${`- ` + dropdownValue.label}`}
                          sectionData={trendLineAnalysis}
                        />
                      }
                      parentId={"tv_sales_summary_section"}
                      title={`Summary ${selectedDurationDropdownOption && " : " + selectedDurationDropdownOption.label} 
                      ${dropdownValue.label !== undefined &&
                        dropdownValue.label !== "" &&
                        dropdownValue.label !== null &&
                        " : " + dropdownValue.label}`}
                      chart={trendLineAnalysisSection2}
                    />
                    <div data-html2canvas-ignore={true}>
                      {isMultiLineCluster && <button onClick={() => setIsTvSummaryOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                        <ExpandIcon />
                      </button>}
                      <ExportDropdown
                        moduleName={pageModule}
                        platform={pagePlatform}
                        subModuleName={pageSubModule}
                        sectionId="tv_sales_summary_section"
                        sectionName={`DateFirstActiveDropdownLabels_TV & Sales Summary_
												${selectedDurationDropdownOption !== undefined &&
                            selectedDurationDropdownOption !== null &&
                            selectedDurationDropdownOption !== ""
                            ? selectedDurationDropdownOption.label
                            : ""
                          }${`- ` + dropdownValue.label}`}
                        sectionData={trendLineAnalysis}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_chart_wrapper">
                      {trendLineAnalysisSection}
                    </div>
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link to="/ri/sales" className="btn-small" target="_blank">
                    Know More
                  </Link>
                </div>
              </div>
              <div className="grid_margin_bottom">{trendAnalysisTable}</div>

              <div
                id="prime_time_grps_section"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
              >
                <div className="grid grid_cols_4">
                  <div className="grid col_span_2 card_border_right flex column">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">
                          Prime Time GRP's %
                          {selectedDurationDropdownOption &&
                            " : " + selectedDurationDropdownOption.label}
                          {dropdownValue.label !== undefined &&
                            dropdownValue.label !== "" &&
                            dropdownValue.label !== null &&
                            " : " + dropdownValue.label}
                        </h2>
                        <SectionDefinationsBadge
                          title="Prime Time GRP's %"
                          module={pageSubModule}
                          platform={pagePlatform}
                          section={pageSection}
                        />
                      </div>
                      <Modal
                        open={isPrimeTimeNonPrimeTimeOpen}
                        setOpen={setIsPrimeTimeNonPrimeTimeOpen}
                        exportDropdown={
                          <ExportDropdown
                            moduleName={pageModule}
                            platform={pagePlatform}
                            subModuleName={pageSubModule}
                            sectionId={"primeTime_2"}
                            sectionName={"PT & PP GRPs"}
                            sectionData={PTPPExport}
                          />
                        }
                        parentId={"prime_time_grps_section"}
                        title={`Prime Time GRP's % ${selectedDurationDropdownOption &&
                          " : " + selectedDurationDropdownOption.label}
                        ${dropdownValue.label !== undefined &&
                          dropdownValue.label !== "" &&
                          dropdownValue.label !== null &&
                          " : " + dropdownValue.label}`}
                        chart={PrimeTimeChart2}
                      />
                      <div data-html2canvas-ignore={true}>
                        {isMultiLineCluster && <button onClick={() => setIsPrimeTimeNonPrimeTimeOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                          <ExpandIcon />
                        </button>}
                      </div>
                    </div>
                    <div className="grid grid_cols_1 section_card_body">
                      <div className="section_card_chart_wrapper">
                        {PrimeTimeChart}
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_2 flex column">
                    <div className="section_card_header">
                      <div className="section_info">
                        <h2 className="section_card_title">
                          Premium Position GRP's %
                          {selectedDurationDropdownOption &&
                            " : " + selectedDurationDropdownOption.label}
                          {dropdownValue.label !== undefined &&
                            dropdownValue.label !== "" &&
                            dropdownValue.label !== null &&
                            " : " + dropdownValue.label}
                        </h2>
                        <SectionDefinationsBadge
                          title="Premium Position GRP's %"
                          module={pageSubModule}
                          platform={pagePlatform}
                          section={pageSection}
                        />
                      </div>
                      <Modal
                        open={isPremiumPositionOpen}
                        setOpen={setIsPremiumPositionOpen}
                        exportDropdown={
                          <ExportDropdown
                            moduleName={pageModule}
                            platform={pagePlatform}
                            subModuleName={pageSubModule}
                            sectionId={"premiumPosition_2"}
                            sectionName={"PT & PP GRPs"}
                            sectionData={PTPPExport}
                          />
                        }

                        parentId={"prime_time_grps_section"}
                        title={`Premium Position GRP's %
                        ${selectedDurationDropdownOption &&
                          " : " + selectedDurationDropdownOption.label}
                        ${dropdownValue.label !== undefined &&
                          dropdownValue.label !== "" &&
                          dropdownValue.label !== null &&
                          " : " + dropdownValue.label}`}
                        chart={premiumPositionChart2}
                      />


                      <div data-html2canvas-ignore={true}>
                        {isMultiLineCluster && <button onClick={() => setIsPremiumPositionOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                          <ExpandIcon />
                        </button>}
                        <ExportDropdown
                          moduleName={pageModule}
                          platform={pagePlatform}
                          subModuleName={pageSubModule}
                          sectionId={"prime_time_grps_section"}
                          sectionName={"PT & PP GRPs"}
                          sectionData={PTPPExport}
                        />
                      </div>
                    </div>
                    <div className="grid grid_cols_1 section_card_body">
                      <div className="section_card_chart_wrapper">
                        {premiumPositionChart}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link to="/mi/tv" className="btn-small" target="_blank">
                    Know More
                  </Link>
                </div>
              </div>
              {/* TV + DIGITAL Summary */}
              <div
                id="tv_digital_summary_section"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
              >
                <div className="grid col_span_4">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        TV + Digital Summary
                        {dropdownValue.label !== undefined &&
                          dropdownValue.label !== "" &&
                          dropdownValue.label !== null &&
                          " : " + dropdownValue.label}
                      </h2>
                      <SectionDefinationsBadge
                        title="TV + Digital Summary"
                        module={pageSubModule}
                        platform={pagePlatform}
                        section={pageSection}
                      />
                    </div>

                    <Modal
                      open={isTvDigitalOpen}
                      setOpen={setIsTvDigitalOpen}
                      exportDropdown={
                        <ExportDropdown
                          moduleName={pageModule}
                          platform={pagePlatform}
                          subModuleName={pageSubModule}
                          sectionId="tvDigitalSummary_2"
                          sectionName={`DateFirstActiveDropdownLabels_TV + Digital Summary_${dropdownValue.label}`}
                          sectionData={combineCSV(tvDigitalSummaryData)}
                        />
                      }
                      parentId={"tv_digital_summary_section"}
                      title={` TV + Digital Summary
                      ${dropdownValue.label !== undefined &&
                        dropdownValue.label !== "" &&
                        dropdownValue.label !== null &&
                        " : " + dropdownValue.label}`}
                      chart={tvDigitalSummarySection2}
                    />


                    <div data-html2canvas-ignore={true}>

                      {isMultiLineCluster && <button onClick={() => setIsTvDigitalOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                        <ExpandIcon />
                      </button>}
                      <ExportDropdown
                        moduleName={pageModule}
                        platform={pagePlatform}
                        subModuleName={pageSubModule}
                        sectionId="tv_digital_summary_section"
                        sectionName={`DateFirstActiveDropdownLabels_TV + Digital Summary_${dropdownValue.label}`}
                        sectionData={combineCSV(tvDigitalSummaryData)}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_chart_wrapper">
                      {tvDigitalSummarySection}
                    </div>
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link to="/ri/tv" className="btn-small" target="_blank">
                    Know More
                  </Link>
                </div>
              </div>
              <div
                id="brand_wise_market_share_trend_section"
                className="grid grid_cols_4 grid_margin_bottom section_card"
              >
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Brand Wise Market Share Trend
                      {selectedBrandWiseMarketShareTrendOption &&
                        " : " + selectedBrandWiseMarketShareTrendOption.label}
                      {dropdownValue.label !== undefined &&
                        dropdownValue.label !== "" &&
                        dropdownValue.label !== null &&
                        " : " + dropdownValue.label}
                    </h2>
                    <SectionDefinationsBadge
                      title="Brand Wise Market Share Trend"
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pageSection}
                    />
                  </div>
                  <Modal
                    open={isBrandWiseMarketShareOpen}
                    setOpen={setIsBrandWiseMarketShareOpen}
                    exportDropdown={
                      <ExportDropdown
                        moduleName={pageModule}
                        platform={pagePlatform}
                        subModuleName={pageSubModule}
                        sectionId={"brandWise_2"}
                        sectionName={`DateFirstActiveDropdownLabels_Brand Wise Market_${selectedBrandWiseMarketShareTrendOption.label + ":" + dropdownValue.label}`}
                        sectionData={brandWiseData}
                      />
                    }
                    dropdown={
                      <Dropdown
                        ismulti={false}
                        placeholder={"Select"}
                        options={brandWiseMarketShareTrendOptions}
                        className="form_dropdown section_dropdown ml_20"
                        value={selectedBrandWiseMarketShareTrendOption}
                        selectedOptions={selectedBrandWiseMarketShareTrendOption}
                        setStatedropdown={
                          brandWiseMarketShareTrendDropdownValueSelectionHandler
                        }
                      />
                    }
                    parentId={"brand_wise_market_share_trend_section"}
                    title={`Brand Wise Market Share Trend ${selectedBrandWiseMarketShareTrendOption &&
                      " : " + selectedBrandWiseMarketShareTrendOption.label}
                      ${dropdownValue.label !== undefined &&
                      dropdownValue.label !== "" &&
                      dropdownValue.label !== null &&
                      " : " + dropdownValue.label}`}
                    chart={brandWiseLineChart2}
                  />


                  <div data-html2canvas-ignore={true}>

                    {isMultiLineCluster && <button onClick={() => setIsBrandWiseMarketShareOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                      <ExpandIcon />
                    </button>}
                    <Dropdown
                      ismulti={false}
                      placeholder={"Select"}
                      options={brandWiseMarketShareTrendOptions}
                      className="form_dropdown section_dropdown ml_20"
                      value={selectedBrandWiseMarketShareTrendOption}
                      selectedOptions={selectedBrandWiseMarketShareTrendOption}
                      setStatedropdown={
                        brandWiseMarketShareTrendDropdownValueSelectionHandler
                      }
                    />
                    <ExportDropdown
                      moduleName={pageModule}
                      platform={pagePlatform}
                      subModuleName={pageSubModule}
                      sectionId={"brand_wise_market_share_trend_section"}
                      sectionName={`DateFirstActiveDropdownLabels_Brand Wise Market_${selectedBrandWiseMarketShareTrendOption.label + ":" + dropdownValue.label}`}
                      sectionData={brandWiseData}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {brandWiseLineChart}
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link to="/ri/sales" className="btn-small" target="_blank">
                    Know More
                  </Link>
                </div>
              </div>

              <div className="grid_margin_bottom">{stateWiseKpiTable}</div>

              <div className="section_card section_dropdown_header">
                <div className="grid grid_cols_1">

                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        Brand Wise Monthly TV Reach Compare
                      </h2>
                      <SectionDefinationsBadge
                        title="Overall Summary by Impressions"
                        module={pageSubModule}
                        platform={pagePlatform}
                        section={pageSection}
                      />
                    </div>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}
                        platform={pagePlatform}
                        subModuleName={pageSubModule}
                        sectionId={"monthlytv"}
                        sectionName={
                          "Brand Wise Monthly Tv Reach"
                        }
                        sectionData={brandWiseMonthlyTvReach}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div id="monthlytv" className="grid grid_cols_4 grid_margin_bottom">
                <div className="grid col_span_2 card_border_right flex column">
                  {/* <div className="grid col_span_2 flex column"> */}
                  {/* <div className="grid cols_span_3 flex justify_end"> */}
                  <div className="section_card_header column_heading column_heading_baseline">

                    <div data-html2canvas-ignore={true} className="col_span_4 ">
                      <Dropdown
                        dropdownLoading={brandIsLoading}
                        ismulti={false}
                        placeholder={"Select"}
                        options={firstBrandOptions}
                        className="form_dropdown section_dropdown"
                        value={selectedFirstBrandOption}
                        selectedOptions={selectedFirstBrandOption}
                        setStatedropdown={
                          dropdownBrandFirstSectionHandler
                        }
                      />

                      <Dropdown
                        dropdownLoading={brandIsLoading}
                        ismulti={false}
                        placeholder={"Select"}
                        options={firstMonthOptions}
                        className="form_dropdown section_dropdown grid_margin_left"
                        value={selectedFirstMonthOption}
                        selectedOptions={selectedFirstMonthOption}
                        setStatedropdown={dropdownMonthFirstSectionHandler}
                      />




                    </div>
                  </div>
                  {/* </div> */}
                  {/*  </div> */}
                  {firstTableDataTable}
                </div>
                {/*  <div className="grid col_span_2 flex column"> */}
                <div className="grid col_span_2 card_border_right flex column">
                  {/*  <div className="grid cols_span_3 flex justify_end"> */}

                  <div className="section_card_header column_heading column_heading_baseline">

                    <div data-html2canvas-ignore={true}>
                      <Dropdown
                        dropdownLoading={brandIsLoading}
                        ismulti={false}
                        placeholder={"Select"}
                        options={secondBrandOptions}
                        className="form_dropdown section_dropdown"
                        value={selectedSecondBrandOption}
                        selectedOptions={selectedSecondBrandOption}
                        setStatedropdown={dropdownBrandSecondSectionHandler}
                      />

                      <Dropdown
                        dropdownLoading={brandIsLoading}
                        ismulti={false}
                        placeholder={"Select"}
                        options={secondMonthOptions}
                        className="form_dropdown section_dropdown grid_margin_left"
                        value={selectedSecondMonthOption}
                        selectedOptions={selectedSecondMonthOption}
                        setStatedropdown={dropdownMonthSecondSectionHandler}
                      />

                    </div>
                  </div>

                  {/* </div> */}

                  {secondTableDataTable}
                </div></div>
              {/* </div> */}


              <div
                id="overall_summary_by_mpressions_section"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
              >
                <div className="grid col_span_4">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        Overall Summary by Impressions
                      </h2>
                      <SectionDefinationsBadge
                        title="Overall Summary by Impressions"
                        module={pageSubModule}
                        platform={pagePlatform}
                        section={pageSection}
                      />
                    </div>
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        moduleName={pageModule}
                        platform={pagePlatform}
                        subModuleName={pageSubModule}
                        sectionId={"overall_summary_by_mpressions_section"}
                        sectionName={
                          "CokeSummaryOverallImpressions_Overall Summary Impressions"
                        }
                        sectionData={overallSummaryByImpressionData}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_chart_wrapper">
                      {campaignOverviewChart}
                    </div>
                  </div>
                </div>
                {/*  <a target="_blank" className="btn-small" href="">Know More</a> */}
                <div className="know-more-footer">
                  <button
                    className="secondary_btn btn-small-secondary"
                    onClick={() =>
                      setShowOverallSummaryTable(!showOverallSummaryTable)
                    }
                  >
                    {showOverallSummaryTable ? "Hide Table" : "Show Table"}
                  </button>
                </div>
              </div>
              {showOverallSummaryTable && (
                <div className="grid_margin_bottom">
                  {overallSummaryTableSection}
                </div>
              )}

              <div
                id="digital_campaigns_summary_section"
                className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
              >
                <div className="grid col_span_4">
                  <div className="section_card_header">
                    <div className="section_info">
                      <h2 className="section_card_title">
                        Digital Campaigns Summary{" "}
                        {": " + selectedMidSectionDropdownOption.label}
                      </h2>
                      <SectionDefinationsBadge
                        title="Digital Campaigns Summary"
                        module={pageSubModule}
                        platform={pagePlatform}
                        section={pageSection}
                      />
                    </div>

                    <Modal
                      open={isOpen}
                      setOpen={setIsOpen}
                      dropdown={
                        <Dropdown
                          ismulti={false}
                          placeholder={"Select"}
                          options={midSectionDropdonwOptions}
                          className="form_dropdown section_dropdown ml_20"
                          value={selectedMidSectionDropdownOption}
                          selectedOptions={selectedMidSectionDropdownOption}
                          setStatedropdown={midSectionDropdownSelectionHandler}
                        />
                      }
                      exportDropdown={
                        <ExportDropdown
                          moduleName={pageModule}
                          platform={pagePlatform}
                          subModuleName={pageSubModule}
                          sectionId={"performance_by_source_partitioned"}
                          sectionName={"Digital Campaign Summary"}
                          sectionData={DigitalCampaignExport}
                        />}
                      parentId={"digital_campaigns_summary_section"}
                      title={`Digital Campaigns Summary : ${selectedMidSectionDropdownOption.label}`}
                      chart={performanceBySourceChart2}
                    />

                    <div data-html2canvas-ignore={true}>

                      {isMultiLineCluster && <button onClick={() => setIsOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
                        <ExpandIcon />
                      </button>}
                      <Dropdown
                        ismulti={false}
                        placeholder={"Select"}
                        options={midSectionDropdonwOptions}
                        className="form_dropdown section_dropdown ml_20"
                        value={selectedMidSectionDropdownOption}
                        selectedOptions={selectedMidSectionDropdownOption}
                        setStatedropdown={midSectionDropdownSelectionHandler}
                      />
                      <ExportDropdown
                        moduleName={pageModule}
                        platform={pagePlatform}
                        subModuleName={pageSubModule}
                        sectionId={"digital_campaigns_summary_section"}
                        sectionName={"Digital Campaign Summary"}
                        sectionData={DigitalCampaignExport}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid col_span_4">
                  <div className="grid col_span_3">
                    <div className="section_card_body">
                      <div className="section_card_chart_wrapper">
                        {performanceBySourceChart}
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_1">
                    <div className="section_card_body">
                      <div className="chart_details_wrapper">
                        {performanceBySourceDetailsData}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="know-more-footer">
                  <br />
                  <Link
                    to="/mi/campaigns"
                    className="btn-small"
                    target="_blank"
                  >
                    Know More
                  </Link>
                </div>
                {/* 
                    </>
                )} */}


              </div>

              <div className="grid grid_margin_bottom">
                <Tab
                  varient={"primary"}
                  tabData={PlannedVsDeliveredTabOptions}
                  activeTab={selectedPlannedVsDeliveredTab}
                  handleCallback={(event) => tabsOnClickHandler(event)}
                />
              </div>
              {selectedPlannedVsDeliveredTab === "Meta" ? (
                <Wrapper>
                  <div className="section_card section_dropdown_header" key={'meta-header'}>
                    <div className="grid grid_cols_1">
                      <div className="section_card_header">
                        <h2 className="section_card_title">
                          {selectedPlannedVsDeliveredTab} Planned Vs Delivered
                        </h2>
                        <div data-html2canvas-ignore={true}>
                          <Dropdown
                            ismulti={false}
                            placeholder={"Select"}
                            options={dropdownOptionsMarketTg}
                            className="form_dropdown section_dropdown grid_margin_right"
                            value={selectedMarketTgDropdownOption}
                            selectedOptions={selectedMarketTgDropdownOption}
                            setStatedropdown={dropdownMainSelectionHandler}
                            secondaryTabName={selectedPlannedVsDeliveredTab}
                          />
                          <Dropdown
                            ismulti={false}
                            dropdownLoading={regionDropdownOptionsIsLoading}
                            placeholder={"Select"}
                            options={regionDropdownOptionsOnLoad}
                            className="form_dropdown section_dropdown"
                            value={selectedRegionDropdownOption}
                            selectedOptions={selectedRegionDropdownOption}
                            setStatedropdown={dropdownRegionSelectionHandler}
                            secondaryTabName={selectedPlannedVsDeliveredTab}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom" key={'meta-barchart'}>
                    <div className="section_card_body">
                      <div id="planned_vs_delivered_metrics_section">
                        <div className="grid  section_card">
                          <div className="section_card_header">
                            <div className="section_info">
                              <h2 className="section_card_title">
                                Planned v/s Delivered Metrics Performance
                                {selectedMarketTgDropdownOption &&
                                  " : " + selectedMarketTgDropdownOption.label}
                                {selectedRegionDropdownOption &&
                                  " : " + selectedRegionDropdownOption.label}
                              </h2>
                              <SectionDefinationsBadge
                                title={
                                  "Planned vs Delivered Metrics Performance"
                                }
                                module={pageSubModule}
                                platform={pagePlatform}
                                section={pagePrimaryTab}
                              />
                            </div>
                            <div data-html2canvas-ignore={true}>
                              <ExportDropdown
                                moduleName={pageModule}
                                subModuleName={pageSubModule}
                                secondaryTabName={selectedPlannedVsDeliveredTab}
                                sectionId={
                                  "planned_vs_delivered_metrics_section"
                                }
                                sectionName={`dvBar_Meta PvsD BarChart_${` : ` + selectedMarketTgDropdownOption.label
                                  } ${selectedRegionDropdownOption !== undefined &&
                                    selectedRegionDropdownOption !== null &&
                                    selectedRegionDropdownOption !== ""
                                    ? `- ` + selectedRegionDropdownOption.label
                                    : ""
                                  }`}
                                sectionData={barChartCSV}
                              />
                            </div>
                          </div>
                          <div className="grid grid_cols_1 section_card_body">
                            <div className="section_card_chart_wrapper">
                              {plannedVsDeliveredMetricsBarChart}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid_margin_bottom" key={'meta-table'}>
                    {plannedVsDeliveredTable}
                  </div>
                </Wrapper>
              ) : (
                <Wrapper>
                  <div className="section_card section_dropdown_header" key={'dv-header'}>
                    <div className="grid grid_cols_1">
                      <div className="section_card_header">
                        <h2 className="section_card_title">
                          {selectedPlannedVsDeliveredTab} Planned Vs Delivered
                        </h2>
                        <div data-html2canvas-ignore={true}>
                          <Dropdown
                            ismulti={false}
                            placeholder={"Select"}
                            options={dropdownOptionsMarketTgDv}
                            className="form_dropdown section_dropdown grid_margin_right"
                            value={selectedMarketTgDropdownOptionDv}
                            selectedOptions={selectedMarketTgDropdownOptionDv}
                            setStatedropdown={dropdownMainSelectionHandlerDv}
                            secondaryTabName={selectedPlannedVsDeliveredTab}
                          />
                          <Dropdown
                            ismulti={false}
                            dropdownLoading={regionDropdownOptionsIsLoadingDv}
                            placeholder={"Select"}
                            options={regionDropdownOptionsOnLoadDv}
                            className="form_dropdown section_dropdown"
                            value={selectedRegionDropdownOptionDv}
                            selectedOptions={selectedRegionDropdownOptionDv}
                            setStatedropdown={dropdownRegionSelectionHandlerDv}
                            secondaryTabName={selectedPlannedVsDeliveredTab}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid_cols_4 fullpage_section_card section_card grid_margin_bottom" key={'dev-barchart'}>
                    <div className="section_card_body">
                      <div id="planned_vs_delivered_metrics_section">
                        <div className="grid  section_card">
                          <div className="section_card_header">
                            <div className="section_info">
                              <h2 className="section_card_title">
                                Planned v/s Delivered Metrics Performance
                                {selectedMarketTgDropdownOptionDv &&
                                  " : " +
                                  selectedMarketTgDropdownOptionDv.label}
                                {selectedRegionDropdownOptionDv &&
                                  " : " + selectedRegionDropdownOptionDv.label}
                              </h2>
                              <SectionDefinationsBadge
                                title={
                                  "Planned vs Delivered Metrics Performance"
                                }
                                module={pageSubModule}
                                platform={pagePlatform}
                                section={pagePrimaryTab}
                              />
                            </div>
                            <div data-html2canvas-ignore={true}>
                              <ExportDropdown
                                moduleName={pageModule}
                                subModuleName={pageSubModule}
                                secondaryTabName={selectedPlannedVsDeliveredTab}
                                sectionId={
                                  "planned_vs_delivered_metrics_section"
                                }
                                sectionName={`dvBar_Dv PvsD BarChart_${` : ` + selectedMarketTgDropdownOptionDv.label
                                  } ${selectedRegionDropdownOptionDv !==
                                    undefined &&
                                    selectedRegionDropdownOptionDv !== null &&
                                    selectedRegionDropdownOptionDv !== ""
                                    ? `- ` +
                                    selectedRegionDropdownOptionDv.label
                                    : ""
                                  }`}
                                sectionData={barChartCSVDv}
                              />
                            </div>
                          </div>
                          <div className="grid grid_cols_1 section_card_body">
                            <div className="section_card_chart_wrapper">
                              {plannedVsDeliveredMetricsBarChartDv}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid_margin_bottom" key={'dv-table'}>
                    {plannedVsDeliveredTableDv}
                  </div>
                </Wrapper>
              )}

              {/* 	<div
								id="planned_vs_actual_section"
								className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
							>
								<div className="section_card_header">
									<div className="section_info">
										<h2 className="section_card_title">
											Planned V/s Actual
											{": " + selectedPlannedVSActualDropdownOption.label}
										</h2>
										<SectionDefinationsBadge
											title="Planned V/s Actual"
											module={pageSubModule}
											platform={pagePlatform}
											section={pageSection}
										/>
									</div>
									<div data-html2canvas-ignore={true}>
										<Dropdown
											ismulti={false}
											placeholder={"Select"}
											options={plannedVSActualDropdownOptions}
											className="form_dropdown section_dropdown"
											value={selectedPlannedVSActualDropdownOption}
											selectedOptions={selectedPlannedVSActualDropdownOption}
											setStatedropdown={plannedVSActualDropdownSelectionHandler}
										/>
										<ExportDropdown
											moduleName={pageModule}
											platform={pagePlatform}
											subModuleName={pageSubModule}
											
											sectionId={"planned_vs_actual_section"}
											sectionName={`CokeSummaryPlannedvsActual_Planned vs Actual`}
											sectionData={plannedVSActualData}
										/>
									</div>
								</div>
								<div className="grid grid_cols_1 section_card_body">
									<div className="section_card_chart_wrapper">
										{plannedVSActual}
									</div>
								</div>
								<div className="know-more-footer">
									<Link
										to="/mi/campaigns"
										className="btn-small"
										target="_blank"
									>
										Know More
									</Link>
								</div>
							</div>
 */}



              <div
                id="paid_reach_monthly_trendline"
                className="grid grid_cols_4 grid_margin_bottom section_card"
              >
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Paid Reach Monthly Trendline{" "}
                      {": " +
                        selectedPaidReachMonthlyTrendlineDropdownOption.label}
                    </h2>
                    <SectionDefinationsBadge
                      title="Paid Reach Monthly Trendline"
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pageSection}
                    />
                  </div>
                  <Modal
                    open={isPaidReachMonthlyOpen}
                    setOpen={setIsPaidReachMonthlyOpen}
                    exportDropdown={
                      <ExportDropdown
                        sectionId={"paidReachMonthlyTrendline_2"}
                        sectionName={`DateFirstActiveDropdownLabels_Paid Reach Monthly Trendline_${selectedPaidReachMonthlyTrendlineDropdownOption.label}`}
                        sectionData={paidReachMonthlyTrendlineData}
                      />
                    }
                    dropdown={
                      <Dropdown
                        ismulti={false}
                        placeholder={"Select"}
                        options={paidReachMonthlyTrendlineOptions}
                        className="form_dropdown section_dropdown ml_20"
                        value={selectedPaidReachMonthlyTrendlineDropdownOption}
                        selectedOptions={
                          selectedPaidReachMonthlyTrendlineDropdownOption
                        }
                        setStatedropdown={dropdownPaidReachMonthlyTrendline}
                      />
                    }
                    parentId={"paid_reach_monthly_trendline"}
                    title={`Paid Reach Monthly Trendline : ${selectedPaidReachMonthlyTrendlineDropdownOption.label}`}
                    chart={paidReachMonthlyTrendline2}
                  />
                  <div data-html2canvas-ignore={true}>
                    {isMultiLineCluster && <button onClick={() => setIsPaidReachMonthlyOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                      <ExpandIcon />
                    </button>}
                    <Dropdown
                      ismulti={false}
                      placeholder={"Select"}
                      options={paidReachMonthlyTrendlineOptions}
                      className="form_dropdown section_dropdown ml_20"
                      value={selectedPaidReachMonthlyTrendlineDropdownOption}
                      selectedOptions={
                        selectedPaidReachMonthlyTrendlineDropdownOption
                      }
                      setStatedropdown={dropdownPaidReachMonthlyTrendline}
                    />
                    <ExportDropdown
                      sectionId={"paid_reach_monthly_trendline"}
                      sectionName={`DateFirstActiveDropdownLabels_Paid Reach Monthly Trendline_${selectedPaidReachMonthlyTrendlineDropdownOption.label}`}
                      sectionData={paidReachMonthlyTrendlineData}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {paidReachMonthlyTrendline}
                  </div>
                </div>
              </div>

              <div
                id="social_summary_section"
                className="grid grid_cols_4 grid_margin_bottom section_card"
              >
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Social Summary {": " + selectedSocialDropdownOption.label}
                    </h2>
                    <SectionDefinationsBadge
                      title="Social Summary"
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pageSection}
                    />
                  </div>
                  <Modal
                    open={isSocialSummaryOpen}
                    setOpen={setIsSocialSummaryOpen}
                    dropdown={
                      <Dropdown
                        ismulti={false}
                        placeholder={"Select"}
                        options={socialSummaryOptions}
                        className="form_dropdown section_dropdown ml_20"
                        value={selectedSocialDropdownOption}
                        selectedOptions={selectedSocialDropdownOption}
                        setStatedropdown={dropdownSocialSelectionHandler}
                      />
                    }
                    exportDropdown={
                      <ExportDropdown
                        sectionId={"platformWiseSpend_2"}
                        sectionName={`DateFirstActiveDropdownLabels_Social Summary_${selectedSocialDropdownOption.label}`}
                        sectionData={combineCSV(socialSummaryData)}
                      />}
                    parentId={"social_summary_section"}
                    title={`Social Summary : ${selectedSocialDropdownOption.label}`}
                    chart={socialSummary2}
                  />



                  <div data-html2canvas-ignore={true}>

                    {isMultiLineCluster &&
                      (<button onClick={() => setIsSocialSummaryOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                        <ExpandIcon />
                      </button>)}
                    <Dropdown
                      ismulti={false}
                      placeholder={"Select"}
                      options={socialSummaryOptions}
                      className="form_dropdown section_dropdown ml_20"
                      value={selectedSocialDropdownOption}
                      selectedOptions={selectedSocialDropdownOption}
                      setStatedropdown={dropdownSocialSelectionHandler}
                    />
                    <ExportDropdown
                      sectionId={"social_summary_section"}
                      sectionName={`DateFirstActiveDropdownLabels_Social Summary_${selectedSocialDropdownOption.label}`}
                      sectionData={combineCSV(socialSummaryData)}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {socialSummary}
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link
                    to="/mi/social/"
                    className="btn-small"
                    target="_blank"
                  >
                    Know More
                  </Link>
                </div>
              </div>
              <div
                id="video_summary_section"
                className="grid grid_cols_4 grid_margin_bottom section_card"
              >
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">Video Summary</h2>
                    <SectionDefinationsBadge
                      title="Video Summary"
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pageSection}
                    />
                  </div>
                  <Modal
                    open={isVideoSummaryOpen}
                    setOpen={setIsVideoSummaryOpen}
                    exportDropdown={
                      <ExportDropdown
                        sectionId={"videoSummary_2"}
                        sectionName={"CokeSummaryVideoSummary_Video Summary"}
                        sectionData={videoSummaryData}
                      />}
                    parentId={"video_summary_section"}
                    title={`Video Summary`}
                    chart={videoSummaryStackedColumnChart2}
                  />



                  <div data-html2canvas-ignore={true}>

                    {isMultiLineCluster &&
                      (<button onClick={() => setIsVideoSummaryOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                        <ExpandIcon />
                      </button>)}
                    <ExportDropdown
                      sectionId={"video_summary_section"}
                      sectionName={"CokeSummaryVideoSummary_Video Summary"}
                      sectionData={videoSummaryData}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {videoSummaryStackedColumnChart}
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link to="/mi/video" className="btn-small" target="_blank">
                    Know More
                  </Link>
                </div>
                {/*  <a target="_blank" className="btn-small" href="/coke/mi/video">Know More</a> */}
              </div>

              {/*   <div
                id="video_analysis_section"
                className="grid grid_cols_4 grid_margin_bottom section_card"
              >
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">Video Analysis  {selectedVideoAnalysisDropdownOption && ': ' + selectedVideoAnalysisDropdownOption.label}</h2>
                    <SectionDefinationsBadge
                      title="Video Analysis"
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pageSection}
                    />
                  </div>

                  <div data-html2canvas-ignore={true}>
                    <Dropdown
                      ismulti={false}
                      placeholder={"Select"}
                      options={videoAnalysisOptions}
                      className="form_dropdown section_dropdown"
                      value={selectedVideoAnalysisDropdownOption}
                      selectedOptions={selectedVideoAnalysisDropdownOption}
                      setStatedropdown={dropdownVideoAnalysisSelectionHandler}
                    />
                    <ExportDropdown
                      sectionId={"video_analysis_section"}
                      sectionName={`DateFirstActiveDropdownLabels_Video Analysis_${selectedVideoAnalysisDropdownOption.label}`}
                      sectionData={DateIntToStd(videoAnalysisData)}
                    />
                  </div>


                  
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {VideoAnalysis}
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link to="/mi/video" className="btn-small" target="_blank">
                    Know More
                  </Link>
                </div>
              </div> */}



              <div
                id="video_analysis_section"
                className="grid grid_cols_4 grid_margin_bottom section_card"
              >
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Video Analysis {": " + selectedVideoAnalysisDropdownOption.label}
                    </h2>
                    <SectionDefinationsBadge
                      title="Video Analysis"
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pageSection}
                    />
                  </div>
                  <Modal
                    open={isVideoAnalysisOpen}
                    setOpen={setIsVideoAnalysisOpen}
                    dropdown={
                      <Dropdown
                        ismulti={false}
                        placeholder={"Select"}
                        options={videoAnalysisOptions}
                        className="form_dropdown section_dropdown ml_20"
                        value={selectedVideoAnalysisDropdownOption}
                        selectedOptions={selectedVideoAnalysisDropdownOption}
                        setStatedropdown={dropdownVideoAnalysisSelectionHandler}
                      />
                    }
                    exportDropdown={
                      <ExportDropdown
                        sectionId={"video_analysis_2"}
                        sectionName={`DateFirstActiveDropdownLabels_Video Analysis_${selectedVideoAnalysisDropdownOption.label}`}
                        sectionData={DateIntToStd(videoAnalysisData)}
                      />}
                    parentId={"video_analysis_section"}
                    title={`Video Analysis : ${selectedVideoAnalysisDropdownOption.label}`}
                    chart={VideoAnalysis2}
                  />



                  <div data-html2canvas-ignore={true}>

                    {isMultiLineCluster &&
                      (<button onClick={() => setIsVideoAnalysisOpen(true)} className="icon_btn btn-small-secondary expand_btn">
                        <ExpandIcon />
                      </button>)}
                    <Dropdown
                      ismulti={false}
                      placeholder={"Select"}
                      options={videoAnalysisOptions}
                      className="form_dropdown section_dropdown ml_20"
                      value={selectedVideoAnalysisDropdownOption}
                      selectedOptions={selectedVideoAnalysisDropdownOption}
                      setStatedropdown={dropdownVideoAnalysisSelectionHandler}
                    />
                    <ExportDropdown
                      sectionId={"video_analysis_section"}
                      sectionName={`DateFirstActiveDropdownLabels_Video Analysis_${selectedVideoAnalysisDropdownOption.label}`}
                      sectionData={DateIntToStd(videoAnalysisData)}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {VideoAnalysis}
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link to="/mi/video" className="btn-small" target="_blank">
                    Know More
                  </Link>
                </div>
              </div>


              <div className="grid_margin_bottom">
                {videoAnalysisTable}
              </div>
              <div
                id="hashtag_summary_section2"
                className="grid grid_cols_4 fullpage_section_card section_card"
              >
                <div className="section_card_header">
                  <div className="section_info">
                    <h2 className="section_card_title">
                      Hashtag Summary{" "}
                      {": " + selectedHashtagDropdownOption.label}
                    </h2>
                    <SectionDefinationsBadge
                      title="Hashtag Summary"
                      module={pageSubModule}
                      platform={pagePlatform}
                      section={pageSection}
                    />
                  </div>
                  <div data-html2canvas-ignore={true}>
                    <Dropdown
                      ismulti={false}
                      placeholder={"Select"}
                      options={hashtagSummaryOptions}
                      className="form_dropdown section_dropdown"
                      value={selectedHashtagDropdownOption}
                      selectedOptions={selectedHashtagDropdownOption}
                      setStatedropdown={dropdownHashtagSelectionHandler}
                    />
                    <ExportDropdown
                      moduleName={pageModule}
                      platform={pagePlatform}
                      subModuleName={pageSubModule}
                      sectionId={"hashtag_summary_section2"}
                      sectionName={`CokeSummaryHashtag_Hashtag Summary_${selectedHashtagDropdownOption.label}`}
                      sectionData={hashtagSummaryData}
                    />
                  </div>
                </div>
                <div className="grid grid_cols_1 section_card_body">
                  <div className="section_card_chart_wrapper">
                    {hashtagSummary}
                  </div>
                </div>
                <div className="know-more-footer">
                  <Link to="/mi/hashtag" className="btn-small" target="_blank">
                    Know More
                  </Link>

                  <button
                    className="secondary_btn btn-small-secondary"
                    onClick={() =>
                      setShowHashtagSummaryTable(!showHashtagSummaryTable)
                    }
                  >
                    {showHashtagSummaryTable ? "Hide Table" : "Show Table"}
                  </button>
                </div>
              </div>

              {showHashtagSummaryTable && (
                <div className="grid_margin_bottom grid_margin_top">
                  {hashtagSummaryTableSection}
                </div>
              )}

              {/* 	<div
								id="website_summary_section"
								className="grid grid_cols_4 fullpage_section_card section_card"
							>
								<div className="section_card_header">
									<div className="section_info">
										<h2 className="section_card_title">
											Website Summary{" "}
											{": " + selectedWebsiteSummaryDropdownOption.label}
										</h2>
										<SectionDefinationsBadge
											title="Website Summary"
											module={pageSubModule}
											platform={pagePlatform}
											section={pageSection}
										/>
									</div>
									<div data-html2canvas-ignore={true}>
										<Dropdown
											ismulti={false}
											placeholder={"Select"}
											options={websiteSummaryDropdownOptions}
											className="form_dropdown section_dropdown"
											value={selectedWebsiteSummaryDropdownOption}
											selectedOptions={selectedWebsiteSummaryDropdownOption}
											setStatedropdown={dropdownWebsiteSummarySelectionHandler}
										/>
										<ExportDropdown
											moduleName={pageModule}
											platform={pagePlatform}
											subModuleName={pageSubModule}
											sectionId={"website_summary_section"}
											sectionName={"DateFirstFormatter_Website Summary"}
											sectionData={DateIntToStd(websiteSummaryData)}
										/>
									</div>
								</div>
								<div className="section_card_body">
									<div className="grid grid_cols_4 grid_margin_bottom">
										<div className="grid col_span_4">
											<div className="section_card_body">
												<div className="section_card_chart_wrapper">
													{websiteSummary}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="know-more-footer">
									<Link to="/mi/website" className="btn-small" target="_blank">
										Know More
									</Link>
								</div>
							</div> */}
              {cokeBrandPostCompareFeat}
              <div className="page_footer">
                TV data available from{" "}
                {" " + formatDateDisplay(tvMinDate) + " "} to{" "}
                {" " + formatDateDisplay(tvMaxDate) + " "} & Sales data
                available from {" " + formatDateDisplay(salesMinDate) + " "} to{" "}
                {" " + formatDateDisplay(salesMaxDate)}. Digital data refresh schedule T-1 day and is available from April 2022.
              </div>
            </div>
          </div >
        </div >
      </div >
    </Wrapper >
  );
};
export default CokeSummary;
