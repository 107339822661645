import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";

const WebsiteTechnologyBrowserUsed = ({ viewId }) => {
    const authCtx = useContext(AuthContext);
    const { setFullPageExport, setExportPageName, exportSummary, setIsExportDisabled } = useContext(
        FullPageExportContext
    );

    // SELECTED BRANDS FROM DROPDOWN
    const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

    let BRAND_ID = null;
    if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
        BRAND_ID = null
    } else {
        BRAND_ID = globalSelectedBrandIds.join(',')
    }

    // currentDate / endDate / prevCurrentDate / prevEndDate
    const selectedDates = useSelector((state) => state.date)

    const [apiData, setApiData] = useState({})
    const [apiDataLoaded, setApiDataLoaded] = useState(false)
    const [apiDataLoading, setApiDataLoading] = useState(false)
    const [apiDataError, setApiDataError] = useState(null)

    // TO FETCH DATA FROM API
    const fetchMetricsListHandler = async () => {
        setApiDataLoaded(false)
        setApiDataLoading(true)
        setApiDataError(null)

        const config = {
            params: {
                brand_id: BRAND_ID,
                start_date: selectedDates.startDate,
                end_date: selectedDates.endDate,
                previous_start_date: selectedDates.prevStartDate,
                previous_end_date: selectedDates.prevEndDate,
                view_id: viewId
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
        };

        try {
            const res = await axios.get(
                "/website/ga-browser-brand/",
                config
            );

            setApiData(res?.data ?? []);
            setApiDataLoaded(true);
            setApiDataLoading(false);
            setApiDataError(null);
        } catch (error) {
            setApiDataLoaded(true);
            setApiDataLoading(false);
            setApiDataError(error.code);
        }
    };

    // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
    useEffect(() => {
        viewId === undefined ?
            <NoDataAvailableLoader chartType="cardChartType" /> :
            viewId.length === 0 ?
                <NoDataAvailableLoader chartType="cardChartType" /> :
                fetchMetricsListHandler();
    }, [BRAND_ID, viewId, selectedDates]);

    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // OVERVIEW METRICS CARDS SLIDER

    let overviewData =
        <Wrapper>
            <div className="grid grid_cols_4 ">
                <div className="grid col_span_4 section_header">
                    <div className="section_info">  <h2 className="section_card_title">Browser Used
                    </h2></div>
                </div>
                <div className="grid col_span_4 grid_margin_bottom">
                    {viewId === undefined ? (
                        <NoDataAvailableLoader chartType="cardChartType" />
                    ) : viewId.length === 0 ? (
                        <NoDataAvailableLoader chartType="cardChartType" />
                    ) : (
                        <Loader loaderType="cardLoader" />
                    )}
                </div>
            </div>
        </Wrapper>

    // IF DATA IS LOADED
    if (apiDataLoaded && !apiDataLoading) {
        if (NoDataAvailableChecker(apiData)) {
            overviewData =
                <Wrapper>
                    <div className="grid grid_cols_4 grid_margin_bottom">
                        <div className="grid col_span_4 section_header">
                            <div className="section_info">  <h2 className="section_card_title">Browser Used
                            </h2></div>
                        </div>
                        <div className="grid col_span_4 grid_margin_bottom">
                            <NoDataAvailableLoader chartType="cardChartType" />
                        </div>
                    </div>
                </Wrapper>
        } else {
            overviewData = (
                <Wrapper>
                    <ErrorBoundary chartType="cardChartType">
                        {apiData.map((data, dataIndex) => (
                            <Slider key={dataIndex} sliderTitle={data.title}>
                                {data.sub_data.map((data, index) => (
                                    <ErrorBoundary>
                                        <SwiperSlide key={index}>
                                            <Card
                                                module="website"
                                                platform="technology"
                                                section="browser_used"
                                                cardTitle={data.label}
                                                currentValue={data.currentValue}
                                                rowOneTitle={"Previous"}
                                                previousValue={data.previousValue}
                                                numberVariant={"number"}
                                            />
                                        </SwiperSlide>
                                    </ErrorBoundary>
                                ))}
                            </Slider>
                        ))}
                    </ErrorBoundary>
                </Wrapper >
            );
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (apiDataError) {
        overviewData =
            <Wrapper>
                <div className="grid grid_cols_4 grid_margin_bottom">
                    <div className="grid col_span_4 section_header">
                        <div className="section_info">  <h2 className="section_card_title">Browser Used
                        </h2></div>
                    </div>
                    <div className="grid col_span_4 grid_margin_bottom">
                        <ServerErrorsLoader error={apiDataError} />
                    </div>
                </div>
            </Wrapper>
    }

    // IF DATA LOADING
    if (apiDataLoading) {
        overviewData =
            <Wrapper>
                <div className="grid grid_cols_4 grid_margin_bottom">
                    <div className="grid col_span_4 section_header">
                        <div className="section_info">  <h2 className="section_card_title">Browser Used
                        </h2></div>



                    </div>
                    <div className="grid col_span_4 grid_margin_bottom">
                        {viewId === undefined ? (
                            <NoDataAvailableLoader chartType="cardChartType" />
                        ) : viewId.length === 0 ? (
                            <NoDataAvailableLoader chartType="cardChartType" />
                        ) : (
                            <Loader loaderType="cardLoader" />
                        )}
                    </div>
                </div>
            </Wrapper>
    }

    var allData = { "Summary": exportSummary, "WebsiteCards_Browser Used": apiData }

    useEffect(() => {
        setExportPageName('Website Technology Browser Used')
        setFullPageExport(allData);
    }, [apiData])

    useEffect(() => {
        if (apiDataError !== null ? true : apiDataLoaded && (apiDataLoading === false)) {
            setIsExportDisabled(false)
        } else {
            setIsExportDisabled(true)
        }
    }, [apiDataError, apiDataLoaded, apiDataLoading])


    return (
        <Wrapper>
            {overviewData}
        </Wrapper>
    );
};

export default WebsiteTechnologyBrowserUsed;
