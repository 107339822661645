import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

//STORAGE
import AuthContext from "../../../../store/authContext";
import axios from "../../../../components/helpers/axios";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";

//CHARTS
import FunnelChart from "../../../../components/ui/charts/FunnelChart";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";

const CampaignGoogleVideo = ({ topCardData }) => {

  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Campaign"
  let pagePlatform = "Google"
  let pagePrimaryTab = "Video"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // SELECTED CAMPAIGNS
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0 || selectedCampaignPlatformsFromFilter === undefined) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0 || selectedCampaignObjectivesFromFilter === undefined) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0 || selectedCampaignsFromFilter === undefined) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }


  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // VIDEO VIEWS ANALYSIS
  const [videoViewsAnalysis, setVideoViewsAnalysis] = useState([]);
  const [isVideoVewsAnalysisLoading, setVideoViewsAnalysisIsLoading] =
    useState(false);
  const [isVideoViewsAnalysisLoaded, setVideoViewsAnalysisIsLoaded] =
    useState(false);
  const [videoViewsAnalysiserror, setVideoViewsAnalysisError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchVideoViewsAnalysisHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/video-metrics/", config);

      setMetricCards(res?.data?.data ?? []);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">  <h2 className="section_card_title">Summary
              </h2></div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType={"cardChartType"} />
            </div>
          </div>
        </Wrapper>
      )
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="google"
                    section="video"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEW ANALYSIS


  // TO FETCH DATA FROM API
  const fetchVideoViewsAnalysisHandler = async () => {
    setVideoViewsAnalysisIsLoading(true);
    setVideoViewsAnalysisIsLoaded(false);
    setVideoViewsAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/video-metric-list/",
        config
      );
      setVideoViewsAnalysis(res?.data?.funnel_items ?? []);
      setVideoViewsAnalysisIsLoading(false);
      setVideoViewsAnalysisIsLoaded(true);
      setVideoViewsAnalysisError(null);
    } catch (error) {
      setVideoViewsAnalysis([]);
      setVideoViewsAnalysisIsLoading(false);
      setVideoViewsAnalysisIsLoaded(false);
      setVideoViewsAnalysisError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsAnalysisChart = (
    <Wrapper> <Loader loaderType="funnelChartLoader" /></Wrapper>
  );

  // IF DATA IS LOADED
  if (isVideoViewsAnalysisLoaded && !isVideoVewsAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoViewsAnalysis) || videoViewsAnalysis.every((val, i, arr) => val.value === arr[0].value) === true
    ) {
      videoViewsAnalysisChart = (
        <Wrapper>  <NoDataAvailableLoader chartType="funnelChartType" /></Wrapper>
      );
    } else {
      videoViewsAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={videoViewsAnalysis}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewsAnalysiserror) {
    videoViewsAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={videoViewsAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoVewsAnalysisLoading) {
    videoViewsAnalysisChart = <Wrapper> <Loader loaderType="funnelChartLoader" /></Wrapper>;
  }

  var allData = { ...topCardData, "SummaryCard_Summary Card": metricCards, "Video View Analysis": videoViewsAnalysis }

  useEffect(() => {
    setFullPageExport(allData);

  }, [metricCards, videoViewsAnalysis]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (videoViewsAnalysiserror !== null ? true : isVideoViewsAnalysisLoaded && (isVideoVewsAnalysisLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    videoViewsAnalysiserror, isVideoViewsAnalysisLoaded, isVideoVewsAnalysisLoading,
  ])


  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="video_views"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">   <h2 className="section_card_title">Video Views Analysis
            </h2> <SectionDefinationsBadge
                title={"Video Views Analysis"}
                module="campaign"
                platform="google"
                section="video"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_views"}
                sectionName={"Video Views Analysis"}
                sectionData={videoViewsAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewsAnalysisChart}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CampaignGoogleVideo;
