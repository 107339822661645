import React from "react";
import { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import axios from "../../../../components/helpers/axios";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
/* import TopProductCompare from "../../../../components/ui/TopProductCompare"; */
/* import WorldMap from "../../../../components/ui/map/WorldMap"; */


// CHARTS
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import SolidGauge from "../../../../components/ui/charts/SolidGauge";
/* import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import BarChart from "../../../../components/ui/charts/BarChart"; */

// ASSETS
/* import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import MaleIcon from "../../../../assets/icons/MaleIcon"; */

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit, IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

// SAMPLE DATA
/* import {
  BarChartDataLabelssample,
  BarChartDataSample,
  CombinedBarLineMultipleAxesChartDataLabelsSample,
  CombinedBarLineMultipleAxesChartDataSample,
  DonutPieChartDataSample,
} from "../../../../data/chartsDataSamples";
import {
  facebookVideoHastags,
  facebookMetricApi,
  videoFacebookOverallHeatmapData,
  videoFacebookOverallHeatmapXaxisLabel,
  videoFacebookOverallHeatmapYaxisLabel,
  mapdata,
} from "../../../../data/mi/video/facebook/facebookVideosSampleData"; */

/* import NumberFormatter from "../../../../utils/NumberFormatter"; */
import PartitionedCombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/PartitionedCombinedBarLineMultipleAxesChart";
import CombinedBarLineChart from "../../../../components/ui/charts/CombinedBarLineChart";
import ExpandIcon from "../../../../assets/icons/ExpandIcon";
import Modal from "../../../../components/ui/Modal";
import PartitionedLineChart from "../../../../components/ui/charts/PartitionedLineChart";
import { COKE_COMPANY_ID } from "../../../../constants/constants";

export default function ThumbnailFacebookVideoPaid({
  videoIdentity,
  videoDurationType,
  topCardData,
}) {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence"
  let pageSubModule = "Video"
  let pagePlatform = "Facebook Thumbnail"
  let pagePrimaryTab = "Paid"

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled, exportSummary } = useContext(
    FullPageExportContext
  );
  
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0 || globalSelectedBrandIds === undefined) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);
  const isMultiLineCluster = useSelector((state) => state.multiLineClustered.user)
  const showAllLegendsinMultiLineCluster = useSelector((state) => state.multiLineClustered.allLegendsActive)
  const userData = useSelector((state) => state.userData.user[0]);

  let companyID = userData?.company?.id ?? '';

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // View Trend Analysis
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] =
    useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] =
    useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // Daily trend line
  const [dailyTrendLine, setDailyTrendLine] = useState([]);
  const [dailyTrendLineLabel, setDailyTrendLineLabel] = useState([]);
  const [isDailyTrendLineLoading, setDailyTrendLineIsLoading] = useState(false);
  const [isDailyTrendLineLoaded, setDailyTrendLineIsLoaded] = useState(false);
  const [dailyTrendLineError, setDailyTrendLineError] = useState(null);

  // Unique View Analysis
  const [uniqueViewAnalysis, setUniqueViewAnalysis] = useState([]);
  const [uniqueViewAnalysisLabel, setUniqueViewAnalysisLabel] = useState([]);
  const [isUniqueViewAnalysisLoading, setUniqueViewAnalysisIsLoading] =
    useState(false);
  const [isUniqueViewAnalysisLoaded, setUniqueViewAnalysisIsLoaded] =
    useState(false);
  const [uniqueViewAnalysisError, setUniqueViewAnalysisError] = useState(null);


  // DROPDOWN OPTIONS
  const UniqueViewAnalysisDropdownOptions = [
    { value: "total_video_views", label: "3 second Views" },
    { value: "total_video_10s_views", label: "10 second Views" },
    { value: "total_video_30s_views", label: "30 second Views" },
    { value: "total_video_complete_views", label: "Complete Views" },
  ];

  // Unique Viewer Analysis Page DROPDOWN STATE
  const [uniqueViewAnalysisDropdownValue, setUniqueViewAnalysisDropdownValue] =
    useState(UniqueViewAnalysisDropdownOptions[0]);
  const [uniqueViewAnalysisAllData, setUniqueViewAnalysisAllData] = useState(
    []
  );
  const [uniqueViewAnalysisAllLabel, setUniqueViewAnalysisAllLabel] = useState(
    []
  );

  // DROPDOWN OPTIONS SELECTION HANDLER
  const UniqueViewAnalysisDropdownSelectionHandler = (e) => {
    if (e === null) {
      setUniqueViewAnalysisDropdownValue("");
    } else {
      setUniqueViewAnalysisDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Video", "Facebook Thumbail", "Paid", null, "Unique View Analysis", e.label)
  };

  const fetchUniqueViewAnalysisDropdownHandler = async () => {
    if (uniqueViewAnalysisDropdownValue.value === "total_video_views") {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData?.total_video_views ?? []);
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_views[0].linechartLabels === undefined || uniqueViewAnalysisAllLabel.total_video_views[0].linechartLabels.length === 0 ? [] :
          uniqueViewAnalysisAllLabel.total_video_views[0].barchartLabels === undefined || uniqueViewAnalysisAllLabel.total_video_views[0].barchartLabels.length === 0 ? [] :
            uniqueViewAnalysisAllLabel.total_video_views
      );
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_10s_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData?.total_video_10s_views ?? []);
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_10s_views[0].linechartLabels === undefined || uniqueViewAnalysisAllLabel.total_video_10s_views[0].linechartLabels.length === 0 ? [] :
          uniqueViewAnalysisAllLabel.total_video_10s_views[0].barchartLabels === undefined || uniqueViewAnalysisAllLabel.total_video_10s_views[0].barchartLabels.length === 0 ? [] :
            uniqueViewAnalysisAllLabel.total_video_10s_views
      );
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_30s_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData?.total_video_30s_views ?? []);
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_30s_views[0].linechartLabels === undefined || uniqueViewAnalysisAllLabel.total_video_30s_views[0].linechartLabels.length === 0 ? [] :
          uniqueViewAnalysisAllLabel.total_video_30s_views[0].barchartLabels === undefined || uniqueViewAnalysisAllLabel.total_video_30s_views[0].barchartLabels.length === 0 ? [] :
            uniqueViewAnalysisAllLabel.total_video_30s_views
      );
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_complete_views"
    ) {
      setUniqueViewAnalysis(
        uniqueViewAnalysisAllData?.total_video_complete_views ?? []
      );
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_complete_views[0].linechartLabels === undefined || uniqueViewAnalysisAllLabel.total_video_complete_views[0].linechartLabels.length === 0 ? [] :
          uniqueViewAnalysisAllLabel.total_video_complete_views[0].barchartLabels === undefined || uniqueViewAnalysisAllLabel.total_video_complete_views[0].barchartLabels.length === 0 ? [] :
            uniqueViewAnalysisAllLabel.total_video_complete_views
      );
    } else {
      setUniqueViewAnalysis([]);
      setUniqueViewAnalysisLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchUniqueViewAnalysisDropdownHandler();
  }, [uniqueViewAnalysisDropdownValue]);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // WHEN TOP POST IMPRESSION DATE IS SELELCTED
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchViewTrendAnalysisHandler();
    fetchDailyTrendLineHandler();
    fetchUniqueViewAnalysisHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoIdentity,
    videoDurationType,
  ]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoIdentity, videoDurationType]);

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Facebook Thumbnail", null, "Paid", badge.hashtag)

  };

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/facebook/paid/hashtag_list/", config);

      setHashtagList(res?.data?.metrics ?? []);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (
      NoDataAvailableChecker(filteredHashtagList) ||
      filteredHashtagList[0] === null
    ) {
      topTrendingHashtagsList = (
        <Wrapper>  <NoDataAvailableLoader chartType="hashChartType" /></Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>  <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} /></Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Wrapper> <Loader loaderType="hashLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/facebook/paid/metrics-card/", config);

      setMetricCards(res?.data?.metrics?.data ?? []);

      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary {selectedHashtagSectionHeader && " : " + selectedHashtagSectionHeader}
            </h2></div>   </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      </Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="video"
                    platform="facebook"
                    section="paid single"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">  <h2 className="section_card_title">Summary
            </h2></div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">  <h2 className="section_card_title">Summary
          </h2></div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View Trend Analysis
  const fetchViewTrendAnalysisHandler = async () => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/paid/view-trend-analysis/",
        config
      );
      setViewTrendAnalysis(res?.data ?? []);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = (
    <Wrapper> <Loader loaderType="solidGaugeChartLoader" /></Wrapper>
  );
  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (NoDataAvailableChecker(viewTrendAnalysis)) {
      viewTrendAnalysisSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="solidGaugeChartType" /></Wrapper>
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="solidGaugeChartType">
            <SolidGauge
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="solidGaugeChartType"
          error={viewTrendAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection = <Wrapper> <Loader loaderType="solidGaugeChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DAILY TREND LINE

  let defaultdailyTrendLineDisabledvalues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultdailyTrendLineDisabledvalues = []
  } else {
    defaultdailyTrendLineDisabledvalues = []
  }

  const [isDailyTrendLineOpen, setIsDailyTrendLineOpen] = useState(false)
  const [dailyTrendLineDisabledvalues, setDailyTrendLineDisabledvalues] = useState(defaultdailyTrendLineDisabledvalues)

  const fetchDailyTrendLineHandler = async () => {
    setDailyTrendLineIsLoading(true);
    setDailyTrendLineIsLoaded(false);
    setDailyTrendLineError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/paid/daily-trend_line/",
        config
      );
      setDailyTrendLine(res?.data?.data ?? []);
      setDailyTrendLineLabel(res?.data?.dimension ?? []);


      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(true);
      setDailyTrendLineError(null);
    } catch (error) {
      setDailyTrendLine([]);
      setDailyTrendLineLabel([]);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(false);
      setDailyTrendLineError(error.code);
    }
  };

  let dailyTrendLineSection = (
    <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  let dailyTrendLineSection2 = (
    <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );


  // IF DATA IS LOADED
  if (isDailyTrendLineLoaded && !isDailyTrendLineLoading) {
    if (
      NoDataAvailableChecker(dailyTrendLine) ||
      NoDataAvailableChecker(dailyTrendLineLabel)
    ) {
      dailyTrendLineSection = (
        <Wrapper>   <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );

      dailyTrendLineSection2 = (
        <Wrapper>   <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
    } else {

      if (isMultiLineCluster) {
        dailyTrendLineSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={dailyTrendLine}
                chartId="dailyTrendLine"
                chartLabels={dailyTrendLineLabel}
                onBulletsClick={() => { }}
                tooltipWidth="auto"
                disabledLegendArray={dailyTrendLineDisabledvalues}
                setDisabledValues={setDailyTrendLineDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );

        dailyTrendLineSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <PartitionedLineChart
                chartClass="section_card_chart"
                chartData={dailyTrendLine}
                chartId="dailyTrendLine_2"
                chartLabels={dailyTrendLineLabel}
                onBulletsClick={() => { }}
                tooltipWidth="auto"
                disabledLegendArray={dailyTrendLineDisabledvalues}
                setDisabledValues={setDailyTrendLineDisabledvalues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        dailyTrendLineSection = (
          <Wrapper>
            <ErrorBoundary chartType="lineChartType">
              <LineChart
                chartClass="section_card_chart"
                chartData={dailyTrendLine}
                chartId="dailyTrendLine"
                chartLabels={dailyTrendLineLabel}
                onBulletsClick={() => { }}
                tooltipWidth="auto"
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (dailyTrendLineError) {
    dailyTrendLineSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={dailyTrendLineError}
        />
      </Wrapper>
    );

    dailyTrendLineSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={dailyTrendLineError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDailyTrendLineLoading) {
    dailyTrendLineSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
    dailyTrendLineSection2 = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Unique View Analysis

  const [isUniqueViewAnalysisOpen, setIsUniqueViewAnalysisOpen] =
    useState(false);

  let defaultUniqueViewAnalysisDisabledValues = [];

  if (showAllLegendsinMultiLineCluster) {
    defaultUniqueViewAnalysisDisabledValues = [];
  } else {
    defaultUniqueViewAnalysisDisabledValues = ["Sales Volume"];
  }

  const [
    uniqueViewAnalysisDisabledValues,
    setUniqueViewAnalysisDisabledValues,
  ] = useState(defaultUniqueViewAnalysisDisabledValues);

  const fetchUniqueViewAnalysisHandler = async () => {
    setUniqueViewAnalysisIsLoading(true);
    setUniqueViewAnalysisIsLoaded(false);
    setUniqueViewAnalysisError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/paid/unique_viewer_analysis/",
        config
      );

      setUniqueViewAnalysis(res?.data?.data?.total_video_complete_views ?? []);

      setUniqueViewAnalysisLabel(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
          ? []
          : res.data.label.total_video_complete_views === undefined ||
            res.data.label.total_video_complete_views.length === 0
          ? []
          : res.data.label.total_video_complete_views[0].linechartLabels ===
              undefined ||
            res.data.label.total_video_complete_views[0].linechartLabels
              .length === 0
          ? []
          : res.data.label.total_video_complete_views[0].barchartLabels ===
              undefined ||
            res.data.label.total_video_complete_views[0].barchartLabels
              .length === 0
          ? []
          : res.data.label.total_video_complete_views
      );

      setUniqueViewAnalysisAllData(res?.data?.data ?? []);
      setUniqueViewAnalysisAllLabel(res?.data?.label ?? []);

      setUniqueViewAnalysisIsLoading(false);
      setUniqueViewAnalysisIsLoaded(true);
      setUniqueViewAnalysisError(null);
    } catch (error) {
      setUniqueViewAnalysis([]);
      setUniqueViewAnalysisLabel([]);
      setUniqueViewAnalysisAllData([]);
      setUniqueViewAnalysisAllLabel([]);
      setUniqueViewAnalysisIsLoading(false);
      setUniqueViewAnalysisIsLoaded(false);
      setUniqueViewAnalysisError(error.code);
    }
  };

  let uniqueViewAnalysisSection = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );
  let uniqueViewAnalysisSection2 = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED

  if (isUniqueViewAnalysisLoaded && !isUniqueViewAnalysisLoading) {
    if (
      NoDataAvailableChecker(uniqueViewAnalysis) ||
      NoDataAvailableChecker(uniqueViewAnalysisLabel)
    ) {
      uniqueViewAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
      uniqueViewAnalysisSection2 = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
    } else {
      if (isMultiLineCluster) {
        uniqueViewAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <CombinedBarLineChart
                chartClass="section_card_chart"
                chartId="uniqueViewAnalysis_1"
                chartData={uniqueViewAnalysis}
                chartLabels={uniqueViewAnalysisLabel}
                showLegend={true}
                disabledLegendArray={uniqueViewAnalysisDisabledValues}
                setDisabledValues={setUniqueViewAnalysisDisabledValues}
              />
            </ErrorBoundary>
          </Wrapper>
        );
        uniqueViewAnalysisSection2 = (
          <Wrapper>
            <ErrorBoundary chartType="multiAxisMultiLineChartType">
              <PartitionedCombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="uniqueViewAnalysis_2"
                chartData={uniqueViewAnalysis}
                chartLabels={uniqueViewAnalysisLabel}
                showLegend={true}
                disabledLegendArray={uniqueViewAnalysisDisabledValues}
                setDisabledValues={setUniqueViewAnalysisDisabledValues}
                showLabels={true}
                showSecondY={false}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      } else {
        uniqueViewAnalysisSection = (
          <Wrapper>
            <ErrorBoundary chartType="combinedBarLineChartType">
              <CombinedBarLineMultipleAxesChart
                chartClass="section_card_chart"
                chartId="uniqueViewAnalysis"
                chartData={uniqueViewAnalysis}
                chartLabels={uniqueViewAnalysisLabel}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (uniqueViewAnalysisError) {
    uniqueViewAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={uniqueViewAnalysisError}
        />
      </Wrapper>
    );
    uniqueViewAnalysisSection2 = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={uniqueViewAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUniqueViewAnalysisLoading) {
    uniqueViewAnalysisSection = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
    uniqueViewAnalysisSection2 = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
  }

  var viewTrendAnalysisCSV = {
    "View Trend Analysis": viewTrendAnalysis,
    "Daily Trend Line Analysis": dailyTrendLine,
  };

  var combinedLineChartCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    "VideoSummaryCard_Summary Card": metricCards,
    ...viewTrendAnalysisCSV,
    [`VideoUniqueView_Unique View Analysis_${uniqueViewAnalysisDropdownValue.label}`]: combinedLineChartCSV(uniqueViewAnalysis),
  };

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    exportSummary,
    metricCards,
    dailyTrendLine,
    viewTrendAnalysis,
    uniqueViewAnalysis,
  ]);

  useEffect(() => {
    if (
      (metricCardserror !== null ? true : isMetricCardsLoaded && (isMetricCardsLoading === false)) &&
      (dailyTrendLineError !== null ? true : isDailyTrendLineLoaded && (isDailyTrendLineLoading === false)) &&
      (viewTrendAnalysisError !== null ? true : isViewTrendAnalysisLoaded && (isViewTrendAnalysisLoading === false)) &&
      (uniqueViewAnalysisError !== null ? true : isUniqueViewAnalysisLoaded && (isUniqueViewAnalysisLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    metricCardserror, isMetricCardsLoaded, isMetricCardsLoading,
    dailyTrendLineError, isDailyTrendLineLoaded, isDailyTrendLineLoading,
    viewTrendAnalysisError, isViewTrendAnalysisLoaded, isViewTrendAnalysisLoading,
    uniqueViewAnalysisError, isUniqueViewAnalysisLoaded, isUniqueViewAnalysisLoading,
  ])

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <div className="section_info">    <h2 className="section_card_title">Trending Hashtags
          </h2>  <SectionDefinationsBadge
              title={"Trending Hashtags"}
              module="video"
              platform="facebook"
              section="paid single"
            />
          </div>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"

                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>

      <div className="grid grid_margin_bottom">
        <div className="card_wrapper">{metricCardsSection}</div>
      </div>

      <div
        id="view_trend_analysis"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">
            View Trend Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2> <SectionDefinationsBadge
              title={"View Trend Analysis"}
              module="video"
              platform="facebook"
              section="paid single"
            />
          </div>
          <Modal
            open={isDailyTrendLineOpen}
            setOpen={setIsDailyTrendLineOpen}
            exportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"dailyTrendLine_2"}
                sectionName={"View Trend Analysis"}
                sectionData={viewTrendAnalysisCSV}
              />}
            parentId={"view_trend_analysis"}
            title={`View Trend Analysis ${selectedHashtag ? ":" + selectedHashtag : ""}`}
            chart={dailyTrendLineSection2}
          />
          <div data-html2canvas-ignore={true}>
            {isMultiLineCluster && <button onClick={() => setIsDailyTrendLineOpen(true)} className="icon_btn btn-small-secondary expand_btn mr_20">
              <ExpandIcon />
            </button>}
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"view_trend_analysis"}
              sectionName={"View Trend Analysis"}
              sectionData={viewTrendAnalysisCSV}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_1 ">
              <div className="grid grid_cols_1 ">
                <div className="section_card_chart_wrapper">
                  {viewTrendAnalysisSection}
                </div>
              </div>
            </div>
            <div className="grid col_span_3 ">
              <div className="grid grid_cols_1 ">
                <div className="section_card_chart_wrapper">
                  {dailyTrendLineSection}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="unique_view_analysis"
        className="section_card fullpage_section_card grid"
      >
        <div className="section_card_header">
          <div className="section_info">   <h2 className="section_card_title">
            Unique View Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2> <SectionDefinationsBadge
              title={"Unique View Analysis"}
              module="video"
              platform="facebook"
              section="paid single"
            />
          </div>
          <Modal
            open={isUniqueViewAnalysisOpen}
            setOpen={setIsUniqueViewAnalysisOpen}
            dropdown={ <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={UniqueViewAnalysisDropdownOptions}
              className="form_dropdown section_dropdown ml_20"
              value={uniqueViewAnalysisDropdownValue}
              selectedOptions={uniqueViewAnalysisDropdownValue}
              setStatedropdown={UniqueViewAnalysisDropdownSelectionHandler}
            />}
            exportDropdown={
              <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
                sectionId={
                  "uniqueViewAnalysis_2" //chart2 id
                }
                sectionName={`VideoUniqueView_Unique View Analysis_${uniqueViewAnalysisDropdownValue.label}`}
                sectionData={combinedLineChartCSV(uniqueViewAnalysis)}
              />
            }
            parentId={"unique_view_analysis"} // div section id
            title={`Video View Analysis ${selectedHashtag ? ` : ${selectedHashtag}` : ''}`}
            chart={uniqueViewAnalysisSection2} //chart2 data
          />

          <div data-html2canvas-ignore={true}>
          {isMultiLineCluster && (
              <button
                onClick={() => setIsUniqueViewAnalysisOpen(true)}
                className="icon_btn btn-small-secondary expand_btn"
              >
                <ExpandIcon />
              </button>
            )}
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={UniqueViewAnalysisDropdownOptions}
              className="form_dropdown section_dropdown ml_20"
              value={uniqueViewAnalysisDropdownValue}
              selectedOptions={uniqueViewAnalysisDropdownValue}
              setStatedropdown={UniqueViewAnalysisDropdownSelectionHandler}
            />
            <ExportDropdown
              moduleName={pageModule}
              subModuleName={pageSubModule}
              platform={pagePlatform}
              tab={pagePrimaryTab}
              sectionId={"unique_view_analysis"}
              sectionName={`VideoUniqueView_Unique View Analysis_${uniqueViewAnalysisDropdownValue.label}`}
              sectionData={combinedLineChartCSV(uniqueViewAnalysis)}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper">
            {uniqueViewAnalysisSection}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
