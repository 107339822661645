import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers } from "@reduxjs/toolkit";

import dateReducer from './dateSlice';
import sidebarReducer from './sidebarSlice';
import tvBrandsCampaignReducer from './tvBrandsCampaignsSlice';
import riSalesBrandsReducer from './riSalesBrandsSlice'
import globalSelectedBrandIdsReducer from './globalBrandsDropdownSlice'
import campaignsFilterSliceReducer from './campaignsGlobalFilterSlice'

// ADMIN
import campaignAdminReducer from "./admin/campaignAdminSlice";

// USER
import userReducer from "./userSlice";
import userPermissionReducer from "./userPermissionSlice";


// MODULE
import moduleReducer from "./moduleSlice";
import tvHaleonBrandsReducer from './tvHaleonBrandsSlice';
import multiLineClusteredReducer from './multiLineClusteredSlice';

// SSO LOGIN
import ssoLoginReducer from "./ssoLogin"

const persistConfig = {
    key: "root",
    version: 1,
    storage
};

const reducer = combineReducers({
    date: dateReducer,
    sidebar: sidebarReducer,
    tvBrandsCamapaigns: tvBrandsCampaignReducer,
    tvHaleonBrands: tvHaleonBrandsReducer,
    riSalesBrandsCategory: riSalesBrandsReducer,
    globalSelectedBrandIds: globalSelectedBrandIdsReducer,
    campaignModuleFilter: campaignsFilterSliceReducer,
    campaignAdmin: campaignAdminReducer,
    userData: userReducer,
    userPermissionData: userPermissionReducer,
    moduleData: moduleReducer,
    multiLineClustered: multiLineClusteredReducer,
    ssoLogin: ssoLoginReducer
});

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export default store;