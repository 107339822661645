import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Slider from "../../../../components/ui/Slider";
import Card from "../../../../components/ui/Card";
import SectionDefinationsBadge from "../../../../components/ui/SectionDefinationsBadge";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import axios from "../../../../components/helpers/axios";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import SimpleTreeMap from "../../../../components/ui/charts/SimpleTreeMap";
import ZoomableBubbleChart from "../../../../components/ui/charts/ZoomableBubbleChart";
import Datatable from "../../../../components/ui/tables/Datatable";

// UTILS
import { HALEON_COMPANY_ID } from "../../../../constants/constants";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NumberFormatter from "../../../../utils/NumberFormatter";
import TextFormatter from "../../../../utils/TextFormatter";

export default function CampaignMarketplaceAmazon({ topCardData }) {
  // PAGE CONSTANTS
  let pageModule = "Marketing Intelligence";
  let pageSubModule = "Campaign";
  let pagePlatform = "Marketplace";
  let pagePrimaryTab = "Amazon";

  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setIsExportDisabled } = useContext(FullPageExportContext);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? '';

  let AudienceDistributionSectionTitle = "";
  if (companyID === HALEON_COMPANY_ID) {
    AudienceDistributionSectionTitle = "Audience Distribution - Meta level";
  } else {
    AudienceDistributionSectionTitle = "Audience Distribution";
  }

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (
    globalSelectedBrandIds.length === 0 ||
    globalSelectedBrandIds === undefined
  ) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedBuckets
  );
  const selectedCampaignIdandPlatform = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignIDandPlatform
  );

  let selectedIdandPlatform = null;
  if (selectedCampaignIdandPlatform === undefined || selectedCampaignIdandPlatform.length === 0) {
    selectedIdandPlatform = null;
  } else {
    selectedIdandPlatform = JSON.stringify(selectedCampaignIdandPlatform);
  }

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (
    selectedCampaignPlatformsFromFilter.length === 0 ||
    selectedCampaignPlatformsFromFilter === undefined
  ) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (
    selectedCampaignObjectivesFromFilter.length === 0 ||
    selectedCampaignObjectivesFromFilter === undefined
  ) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (
    selectedCampaignsFromFilter.length === 0 ||
    selectedCampaignsFromFilter === undefined
  ) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // PAGE DROPDOWN
  const [pageDropdownOptions, setPageDropdownOptions] = useState([]);
  const [isPageDropdownOptionsLoading, setPageDropdownOptionsLoading] = useState(true);

  // DROPDOWN DEFAULT STATE
  const [pageDropdownValue, setPageDropdownDropdownValue] = useState("");
  let selectedPageDropdownValue = pageDropdownValue === undefined || pageDropdownValue === null || pageDropdownValue === "" ? "" :
    pageDropdownValue.map(e => e.value).join(',')
  let selectedPageDropdownLabel = pageDropdownValue === "" || pageDropdownValue === undefined || pageDropdownValue === null ? "" : pageDropdownValue[0] === undefined ? "" :
    " : " +
    pageDropdownValue.map(e => e.label).join(',')

  // DROPDOWN OPTIONS SELECTION HANDLER
  const pageDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPageDropdownDropdownValue("");
    } else {
      setPageDropdownDropdownValue(e);
    }
  };


  const fetchPageDropdownHandler = async () => {
    setPageDropdownOptionsLoading(true);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag:
          selectedBucketsFromFilter.length === 0
            ? null
            : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/amazon/product-filter/", config);
      setPageDropdownOptions(res?.data ?? []);
      setPageDropdownOptionsLoading(false);
    } catch {
      setPageDropdownOptions([]);
      setPageDropdownOptionsLoading(true);
    }
  };

  // METRIC CARDS
  const [performanceMetricCards, setPerformanceMetricCards] = useState([]);
  const [isPerformanceMetricCardsLoading, setPerformanceMetricCardsIsLoading] = useState(false);
  const [isPerformanceMetricCardsLoaded, setPerformanceMetricCardsIsLoaded] = useState(false);
  const [performanceMetricCardserror, setPerformanceMetricCardsError] = useState(null);

  //  CAMPAIGN TYPE WISE DISTRIBUTION (DONUT)
  const [donutChartPlatformWise, setDonutChartPlatformWise] = useState([]);
  const [platformWiseDonutSliceToggle, setPlatformWiseDonutSliceToggle] = useState(null);
  const [donutChartPlatformWiseLoading, setDonutChartPlatformWiseLoading] = useState([]);
  const [donutChartPlatformWiseLoaded, setDonutChartPlatformWiseLoaded] = useState([]);
  const [donutChartPlatformWiseError, setDonutChartPlatformWiseError] = useState([]);

  //  CAMPAIGN TYPE WISE DISTRIBUTION (LINECHART)
  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState([]);
  const [lineChartCampaignObjectiveDetails, setLineChartCampaignObjectiveDetails] = useState([]);
  const [lineChartCampaignObjectiveAllData, setLineChartCampaignObjectiveAllData] = useState([]);
  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] = useState([]);
  const [lineChartCampaignObjectiveLoading, setLineChartCampaignObjectiveLoading] = useState([]);
  const [lineChartCampaignObjectiveLoaded, setLineChartCampaignObjectiveLoaded] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] = useState([]);

  const fetchLineChartDonutSliceHandler = () => {
    if (platformWiseDonutSliceToggle === "overall" || platformWiseDonutSliceToggle === undefined) {
      setLineChartCampaignObjective(lineChartCampaignObjectiveAllData?.overall?.data ?? [])
      setLineChartCampaignObjectiveLabel(lineChartCampaignObjectiveAllData?.overall?.dimension ?? [])
      setLineChartCampaignObjectiveDetails(lineChartCampaignObjectiveAllData?.overall?.table_data ?? [])
    } else if (platformWiseDonutSliceToggle === "search") {
      setLineChartCampaignObjective(lineChartCampaignObjectiveAllData?.search?.data ?? [])
      setLineChartCampaignObjectiveLabel(lineChartCampaignObjectiveAllData?.search?.dimension ?? [])
      setLineChartCampaignObjectiveDetails(lineChartCampaignObjectiveAllData?.search?.table_data ?? [])
    }
    else if (platformWiseDonutSliceToggle === "product") {
      setLineChartCampaignObjective(lineChartCampaignObjectiveAllData?.product?.data ?? [])
      setLineChartCampaignObjectiveLabel(lineChartCampaignObjectiveAllData?.product?.dimension ?? [])
      setLineChartCampaignObjectiveDetails(lineChartCampaignObjectiveAllData?.product?.table_data ?? [])
    }
    else if (platformWiseDonutSliceToggle === "display") {
      setLineChartCampaignObjective(lineChartCampaignObjectiveAllData?.display?.data ?? [])
      setLineChartCampaignObjectiveLabel(lineChartCampaignObjectiveAllData?.display?.dimension ?? [])
      setLineChartCampaignObjectiveDetails(lineChartCampaignObjectiveAllData?.display?.table_data ?? [])
    }

    else {
      setLineChartCampaignObjective([])
      setLineChartCampaignObjectiveLabel([])
      setLineChartCampaignObjectiveDetails([])
    }
  }

  useEffect(() => {
    fetchLineChartDonutSliceHandler()
  }, [platformWiseDonutSliceToggle])

  // VIDEO VIEWS ANALYSIS
  const [consumerSalesFunnel, setConsumerSalesFunnel] = useState([]);
  const [consumerSalesFunnelAllData, setConsumerSalesFunnelAllData] = useState([]);
  const [isConsumerSalesFunnelLoading, setConsumerSalesFunnelIsLoading] = useState(false);
  const [isConsumerSalesFunnelLoaded, setConsumerSalesFunnelIsLoaded] = useState(false);
  const [consumerSalesFunnelerror, setConsumerSalesFunnelError] = useState(null);

  const consumerSalesFunnelOptions = [
    { value: "overall", label: "Overall" },
    { value: "search", label: "Search" },
    { value: "product", label: "Product" },
    { value: "display", label: "Display" },
  ]

  const [consumerSalesFunnelDropdownValue, setConsumerSalesFunnelDropdownValue] = useState(consumerSalesFunnelOptions[0])

  const consumerSalesFunnelHandler = (e) => {
    if (e === null) {
      setConsumerSalesFunnelDropdownValue("");
    } else {
      setConsumerSalesFunnelDropdownValue(e);
    }
  }

  const fetchConsumerSalesFunnelDropdownValue = () => {
    if (consumerSalesFunnelDropdownValue.value === "overall") {
      setConsumerSalesFunnel(consumerSalesFunnelAllData.overall);
    } else if (consumerSalesFunnelDropdownValue.value === "search") {
      setConsumerSalesFunnel(consumerSalesFunnelAllData.search);
    } else if (consumerSalesFunnelDropdownValue.value === "product") {
      setConsumerSalesFunnel(consumerSalesFunnelAllData.product);
    } else if (consumerSalesFunnelDropdownValue.value === "display") {
      setConsumerSalesFunnel(consumerSalesFunnelAllData.display);
    } else {
      setConsumerSalesFunnel([]);
    }
  }

  useEffect(() => {
    fetchConsumerSalesFunnelDropdownValue()
  }, [consumerSalesFunnelDropdownValue])

  // BRANDWISE PERFORMANCE
  const [campaignDeepdive, setCampaignDeepdive] = useState([]);
  const [campaignDeepdiveAllData, setCampaignDeepdiveAllData] = useState([]);
  const [isCampaignDeepdiveLoading, setCampaignDeepdiveIsLoading] = useState(false);
  const [isCampaignDeepdiveLoaded, setCampaignDeepdiveIsLoaded] = useState(false);
  const [campaignDeepdiveerror, setCampaignDeepdiveError] = useState(null);

  const campaignDeepdiveOptions = [
    { value: "sales", label: "Sales" },
    { value: "spends", label: "Spends" },
  ]

  const [campaignDeepdiveDropdownValue, setCampaignDeepdiveDropdownValue] = useState(campaignDeepdiveOptions[0])

  const campaignDeepdiveHandler = (e) => {
    if (e === null) {
      setCampaignDeepdiveDropdownValue("");
    } else {
      setCampaignDeepdiveDropdownValue(e);
    }
  }

  const fetchCampaignDeepdiveDropdownValue = () => {
    if (campaignDeepdiveDropdownValue.value === "sales") {
      setCampaignDeepdive(campaignDeepdiveAllData.sales);
    } else if (campaignDeepdiveDropdownValue.value === "spends") {
      setCampaignDeepdive(campaignDeepdiveAllData.spends);
    } else {
      setCampaignDeepdive([]);
    }
  }

  useEffect(() => {
    fetchCampaignDeepdiveDropdownValue()
  }, [campaignDeepdiveDropdownValue])

  // COMPARE ENGAGEMENT IMPRESSIONS
  const [campaignPerformance, setCampaignPerformance] = useState([]);
  const [campaignPerformanceDataLabels, setCampaignPerformanceDataLabels] = useState([]);
  const [isCampaignPerformanceLoading, setCampaignPerformanceIsLoading] = useState(false);
  const [isCampaignPerformanceLoaded, setCampaignPerformanceIsLoaded] = useState(false);
  const [campaignPerformanceerror, setCampaignPerformanceError] = useState(null);
  const [campaignPerformanceAllData, setcampaignPerformanceAllData] = useState([]);
  const [campaignPerformanceAllLabel, setcampaignPerformanceAllLabel] = useState([]);

  // DROPDOWN OPTIONS
  const campaignPerformanceOptions = [
    { value: "overall", label: "Overall" },
    { value: "search", label: "Search" },
    { value: "product", label: "Product / SKU" },
    { value: "display", label: "Display" },
  ];

  const [campaignPerformanceDropdown, setcampaignPerformanceDropdown] = useState(campaignPerformanceOptions[0]);

  const campaignPerformanceSelectionHandler = (e) => {
    if (e === null) {
      setcampaignPerformanceDropdown("");
    } else {
      setcampaignPerformanceDropdown(e);
    }
  };

  const fetchCampaignPerformanceDropdownHandler = () => {
    if (campaignPerformanceDropdown.value === "overall") {
      setCampaignPerformance(campaignPerformanceAllData?.overall ?? []);
      setCampaignPerformanceDataLabels(campaignPerformanceAllLabel?.overall ?? []);
    } else if (campaignPerformanceDropdown.value === "search") {
      setCampaignPerformance(campaignPerformanceAllData?.search ?? []);
      setCampaignPerformanceDataLabels(campaignPerformanceAllLabel?.search ?? []);
    } else if (campaignPerformanceDropdown.value === "product") {
      setCampaignPerformance(campaignPerformanceAllData?.product ?? []);
      setCampaignPerformanceDataLabels(campaignPerformanceAllLabel?.product ?? []);
    } else if (campaignPerformanceDropdown.value === "display") {
      setCampaignPerformance(campaignPerformanceAllData?.display ?? []);
      setCampaignPerformanceDataLabels(campaignPerformanceAllLabel?.display ?? []);
    } else {
      setCampaignPerformance([]);
      setCampaignPerformanceDataLabels([]);
    }
  };

  useEffect(() => {
    fetchCampaignPerformanceDropdownHandler();
  }, [BRAND_ID, campaignPerformanceDropdown, selectedDates]);


  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] = useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);
  const [campaignSummaryTableHeaders, setCampaignSummaryTableHeaders] = useState([])

  const overAllHeaders = [
    { name: "Brands", field: "sub_brand" },
    { name: "Campaign Type", field: "campaign_type" },
    { name: "Campaign Name", field: "campaign_name" },
    { name: "Spends", field: "spends" },
    { name: "Sales", field: "sales" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Orders", field: "orders" },
    { name: "CTR", field: "ctr" },
    { name: "ACOS", field: "acos" },
  ]

  const searchHeaders = [
    { name: "Brand Name", field: "sub_brand" },
    { name: "Campaign Type", field: "campaign_type" },
    { name: "Targeting", field: "targeting" },
    { name: "Sub-Targeting", field: "sub_targeting" },
    { name: "Campaign Name", field: "campaign_name" },
    { name: "Spends", field: "spends" },
    { name: "Sales", field: "sales" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Orders", field: "orders" },
    { name: "Units", field: "units" },
    { name: "CTR", field: "ctr" },
    { name: "CPC", field: "cpc" },
    { name: "ACOS", field: "acos" },
    { name: "Conversion Rate", field: "conversion_rate" },
  ]

  const productHeaders = [
    { name: "Brand Name", field: "sub_brand" },
    { name: "Campaign Name", field: "campaign_name" },
    { name: "Spends", field: "spends" },
    { name: "Sales", field: "sales" },
    { name: "Impressions", field: "impression" },
    { name: "Clicks", field: "clicks" },
    { name: "Orders", field: "orders" },
    { name: "CTR", field: "ctr" },
    { name: "CPC", field: "cpc" },
    { name: "ACOS", field: "acos" },
    { name: "Conversion Rate", field: "conversion_rate" },
  ]

  const displayHeaders = [
    { name: "Brand Name", field: "sub_brand" },
    { name: "Campaign Type", field: "campaign_type" },
    { name: "Campaign Name", field: "campaign_name" },
    { name: "Sales", field: "sales" },
    { name: "Spends", field: "spends" },
    { name: "Impressions", field: "impression" },
    { name: "Click Throughs", field: "click_throughs" },
    { name: "CTR", field: "ctr" },
    { name: "Purchases", field: "purchases" },
    { name: "Units Sold", field: "units_sold" },
    { name: "DPV", field: "dpv" },
    { name: "ATC", field: "atc" },
    { name: "ATCR", field: "atcr" },
    { name: "ROAS", field: "roas" },
    { name: "ACOS", field: "acos" },
  ]

  // CAMPAIGN OVERVIEW DROPDOWN
  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "overall", label: "Overall" },
    { value: "search", label: "Search" },
    { value: "product", label: "Product / SKU" },
    { value: "display", label: "Display" },
  ];

  // CAMPAIGN OVERVIEW DROPDOWN DEFAULT STATE
  const [selectedCampaignSummaryDropdownOption, setSelectedCampaignSummaryDropdownOption] = useState(campaignSummaryOptions[0]);

  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
    }

  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "overall") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.overall ?? []);
      setCampaignSummaryTableHeaders(overAllHeaders);

    } else if (selectedCampaignSummaryDropdownOption.value === "search") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.search ?? []);
      setCampaignSummaryTableHeaders(searchHeaders);

    } else if (selectedCampaignSummaryDropdownOption.value === "product") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.product ?? []);
      setCampaignSummaryTableHeaders(productHeaders);

    }
    else if (selectedCampaignSummaryDropdownOption.value === "display") {

      setCampaignSummary(campaignSummaryAllDropdownsData?.display ?? []);
      setCampaignSummaryTableHeaders(displayHeaders);

    }
    else {
      setCampaignSummary([]);
      setCampaignSummaryTableHeaders([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID,
    selectedDates,
    selectedCampaignSummaryDropdownOption,
    selectedBucketsFromFilter,
    selectedBucketsFromFilter,
    selectedObjectives,
    selectedIdandPlatform,
    selectedCampaigns,
    selectedPlatform
  ]);



  useEffect(() => {
    fetchPageDropdownHandler();
    fetchPerformanceMetricCardsHandler();
    fetchDonutChartPlatformWiseHandler();
    fetchLineChartCampaignHandler();
    fetchConsumerSalesFunnelHandler();
    fetchCampaignPerformanceHandler();
    fetchCampaignSummaryHandler()
  }, [
    BRAND_ID,
    pageDropdownValue,
    selectedDates,
    selectedBucketsFromFilter,
    selectedCampaigns,
    selectedObjectives,
    selectedIdandPlatform,
    selectedPlatform,
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchPerformanceMetricCardsHandler = async () => {
    setPerformanceMetricCardsIsLoading(true);
    setPerformanceMetricCardsIsLoaded(false);
    setPerformanceMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        sub_brand: selectedPageDropdownValue
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/amazon/metric-card/", config);

      setPerformanceMetricCards(res?.data?.data ?? []);
      setPerformanceMetricCardsIsLoading(false);
      setPerformanceMetricCardsIsLoaded(true);
      setPerformanceMetricCardsError(null);
    } catch (error) {
      setPerformanceMetricCards([]);
      setPerformanceMetricCardsIsLoading(false);
      setPerformanceMetricCardsIsLoaded(false);
      setPerformanceMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceMetricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_info">
            <h2 className="section_card_title">Performance Metrics</h2>
          </div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceMetricCardsLoaded && !isPerformanceMetricCardsLoading) {
    if (NoDataAvailableChecker(performanceMetricCards)) {
      performanceMetricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_info">
                <h2 className="section_card_title">Performance Metrics</h2>
              </div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      performanceMetricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Performance Metrics"}>
              {performanceMetricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module={pageModule}
                    platform={pagePlatform}
                    section={pagePrimaryTab}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceMetricCardserror) {
    performanceMetricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">Performance Metrics</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={performanceMetricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceMetricCardsLoading) {
    performanceMetricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_info">
              <h2 className="section_card_title">Performance Metrics</h2>
            </div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //  CAMPAIGN TYPE WISE DISTRIBUTION (DONUT)
  const fetchDonutChartPlatformWiseHandler = async () => {
    setDonutChartPlatformWiseLoading(true);
    setDonutChartPlatformWiseLoaded(false);
    setDonutChartPlatformWiseError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        sub_brand: selectedPageDropdownValue
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/amazon/campaign-typewise-distribution-piechart/",
        config
      );

      setDonutChartPlatformWise(res?.data ?? []);
      setDonutChartPlatformWiseLoading(false);
      setDonutChartPlatformWiseLoaded(true);
      setDonutChartPlatformWiseError(null);
    } catch (error) {
      setDonutChartPlatformWise([]);
      setDonutChartPlatformWiseLoading(false);
      setDonutChartPlatformWiseLoaded(false);
      setDonutChartPlatformWiseError(error.code);
    }
  };

  let donutChartCampaignSection = (<Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>);

  // IF DATA IS LOADED
  if (donutChartPlatformWiseLoaded && !donutChartPlatformWiseLoading) {
    if (NoDataAvailableChecker(donutChartPlatformWise)) {
      donutChartCampaignSection = <Wrapper><NoDataAvailableLoader chartType="donutChartType" /></Wrapper>;
    } else {
      donutChartCampaignSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={donutChartPlatformWise}
              chartId="CampaignDonut12"
              onSliceClick={(data) =>
                setPlatformWiseDonutSliceToggle(data.label)
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (donutChartPlatformWiseError) {
    donutChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={donutChartPlatformWiseError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (donutChartPlatformWiseLoading) {
    donutChartCampaignSection = <Wrapper><Loader loaderType="donutChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //  CAMPAIGN TYPE WISE DISTRIBUTION (LINECHART)
  const fetchLineChartCampaignHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        objective: platformWiseDonutSliceToggle,
        sub_brand: selectedPageDropdownValue
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/amazon/campaign-typewise-distribution-linechart/",
        config
      );

      setLineChartCampaignObjective(res?.data?.overall?.data ?? [])
      setLineChartCampaignObjectiveLabel(res?.data?.overall?.dimension ?? [])

      setLineChartCampaignObjectiveDetails(res?.data?.overall?.table_data ?? [])

      setLineChartCampaignObjectiveAllData(res?.data ?? [])

      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLabel([])
      setLineChartCampaignObjectiveDetails([])
      setLineChartCampaignObjectiveAllData([])
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };



  let lineChartCampaignSection = (
    <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>
  );

  let lineChartCampaignDetailsData = <Wrapper> <Loader loaderType="smallTableLoader" /></Wrapper>

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      NoDataAvailableChecker(lineChartCampaignObjective) ||
      NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
    ) {
      lineChartCampaignSection = (
        <Wrapper>  <NoDataAvailableLoader chartType="lineChartType" /></Wrapper>
      );
      lineChartCampaignDetailsData = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>

    } else {
      lineChartCampaignSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={lineChartCampaignObjective}
              chartId="multiLineChart"
              chartLabels={lineChartCampaignObjectiveLabel}
              onBulletsClick={function noRefCheck() { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );

      lineChartCampaignDetailsData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {lineChartCampaignObjectiveDetails && lineChartCampaignObjectiveDetails.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                            {details.metric &&
                              <div className="metrics_wrapper">
                                <div className="title_wrapper">
                                  {details.metric[0].label + " : "}
                                </div>
                                <div className="value_wrapper">
                                  <ErrorBoundary>
                                    <NumberFormatter
                                      number={details.metric[0].value}
                                    />
                                  </ErrorBoundary>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      )
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={lineChartCampaignObjectiveError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Wrapper> <Loader loaderType="singleLineChartLoader" /></Wrapper>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CONSUMER SALES FUNNEL

  // TO FETCH DATA FROM API
  const fetchConsumerSalesFunnelHandler = async () => {
    setConsumerSalesFunnelIsLoading(true);
    setConsumerSalesFunnelIsLoaded(false);
    setConsumerSalesFunnelError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        sub_brand: selectedPageDropdownValue

      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/amazon/consumer-sales-funnel/",
        config
      );

      setConsumerSalesFunnel(res?.data?.overall ?? []);
      setConsumerSalesFunnelAllData(res?.data ?? [])

      setConsumerSalesFunnelIsLoading(false);
      setConsumerSalesFunnelIsLoaded(true);
      setConsumerSalesFunnelError(null);
    } catch (error) {
      setConsumerSalesFunnel([]);
      setConsumerSalesFunnelAllData([])
      setConsumerSalesFunnelIsLoading(false);
      setConsumerSalesFunnelIsLoaded(false);
      setConsumerSalesFunnelError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let consumerSalesFunnelChart = (
    <Wrapper><Loader loaderType="funnelChartLoader" /></Wrapper>
  );

  let consumerSalesFunnelDetailsData = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>


  // IF DATA IS LOADED
  if (isConsumerSalesFunnelLoaded && !isConsumerSalesFunnelLoading) {
    if (NoDataAvailableChecker(consumerSalesFunnel) || consumerSalesFunnel.every((val, i, arr) => val.value === arr[0].value)) {
      consumerSalesFunnelChart = <Wrapper><NoDataAvailableLoader chartType="funnelChartType" /></Wrapper>;
      consumerSalesFunnelDetailsData = <Wrapper><NoDataAvailableLoader chartType="tableChartType" /></Wrapper>
    } else {
      consumerSalesFunnelChart = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={consumerSalesFunnel}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      consumerSalesFunnelDetailsData = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <div className="chart_details_wrapper">
              <div className="main_wrapper">
                <div className="body">
                  {consumerSalesFunnel && consumerSalesFunnel.map(
                    (details, postImpressionIndex) => (
                      <Wrapper key={postImpressionIndex}>
                        <div className="row">
                          <div className="title_wrapper">
                            {details.label}
                          </div>
                          <div className="value_wrapper">
                            <ErrorBoundary>
                              <NumberFormatter number={details.value} />
                            </ErrorBoundary>
                            {details.metric &&
                              <div className="metrics_wrapper">
                                <div className="title_wrapper">
                                  {details.metric[0].label + " : "}
                                </div>
                                <div className="value_wrapper">
                                  <ErrorBoundary>
                                    <NumberFormatter
                                      number={details.metric[0].value}
                                    />
                                  </ErrorBoundary>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </Wrapper>
                    ))}
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </Wrapper>
      )
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (consumerSalesFunnelerror) {
    consumerSalesFunnelChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={consumerSalesFunnelerror}
        />
      </Wrapper>
    );
    consumerSalesFunnelDetailsData = <Wrapper><ServerErrorsLoader chartType="tableChartType" error={consumerSalesFunnelerror} /></Wrapper>
  }

  // IF DATA LOADING
  if (isConsumerSalesFunnelLoading) {
    consumerSalesFunnelChart = <Wrapper><Loader loaderType="funnelChartLoader" /></Wrapper>
    consumerSalesFunnelDetailsData = <Wrapper><Loader loaderType="smallTableLoader" /></Wrapper>
  }


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   DEVICE WISE DISTRIBUTION

  let treemapLevel = 1;
  const [treemapSubBrand, setTreemapSubBrand] = useState("");
  const [treemapBrandName, setTreemapBrandName] = useState("");

  const [treemapCategory, setTreemapCategory] = useState("");



  const [treemapParams, setTreemapParams] = useState({
    brand_id: BRAND_ID,
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    objective: selectedObjectives,
    platform_campaign: selectedIdandPlatform,
    platform: selectedPlatform,
    campaign: selectedCampaigns,
    campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
    sub_brand: selectedPageDropdownValue === "" ? null : selectedPageDropdownValue,
    level: 1,
  });

  const chartlevelHandler = (data) => {
    if (data.level === 1) {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        sub_brand: data.sub_brand,
        level: 2,

      });
      setTreemapSubBrand(data.sub_brand);
      setTreemapBrandName(data.name)
    } else if (data.level === 2) {
      setTreemapParams({
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        sub_brand: treemapSubBrand,
        category: data.name,
        level: 3,
      });
      setTreemapCategory(data.name);
    } else {
      alert("No further drill down available");
    }
  };

  const fetchCampaignDeepdiveHandler = async () => {
    setCampaignDeepdiveIsLoading(true);
    setCampaignDeepdiveIsLoaded(false);
    setCampaignDeepdiveError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/amazon/campaign-deepdive/",
        config
      );

      setCampaignDeepdive(res?.data?.sales ?? []);
      setCampaignDeepdiveAllData(res?.data ?? [])
      setCampaignDeepdiveIsLoading(false);
      setCampaignDeepdiveIsLoaded(true);
      setCampaignDeepdiveError(null);
    } catch (error) {
      setCampaignDeepdive([]);
      setCampaignDeepdiveAllData([])
      setCampaignDeepdiveIsLoading(false);
      setCampaignDeepdiveIsLoaded(false);
      setCampaignDeepdiveError(error.code);
    }
  };

  let campaignDeepdiveSection = (
    <Wrapper>
      <div className="section_card_chart_wrapper flex column">
        <Loader loaderType="treeChartLoader" />
      </div>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCampaignDeepdiveLoaded && !isCampaignDeepdiveLoading) {
    if (
      NoDataAvailableChecker(campaignDeepdive) ||
      (campaignDeepdive.length === 1 &&
        campaignDeepdive[0].value === 0)
    ) {
      campaignDeepdiveSection = (
        <Wrapper>
          <div className="section_card_chart_wrapper flex column">
            <NoDataAvailableLoader chartType="treeChartType" />
          </div>
        </Wrapper>
      );
    } else {
      if (treemapLevel > 3) {
        campaignDeepdiveSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <NoDataAvailableLoader chartType="treeChartType" />
            </div>
          </Wrapper>
        );
      } else {
        campaignDeepdiveSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <ErrorBoundary chartType="treeChartType">
                <SimpleTreeMap
                  chartId="simpleTreemaptest"
                  chartClass="section_card_chart"
                  chartData={campaignDeepdive}
                  levelHandler={chartlevelHandler}
                  /* colorChangeParameter={"growth_percentage"} */
                  chartLabels={[
                    {
                      name: "name",
                      value: "value",
                      toolTipvalue1: "total_campaign",
                      toolTipvalue1Label: "No. of Campaigns",
                      toolTipvalue2: "sales",
                      toolTipvalue2Label: "Sales",
                      toolTipvalue3: "spends",
                      toolTipvalue3Label: "Spends",

                    },
                  ]}
                />
              </ErrorBoundary>
            </div>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignDeepdiveerror) {
    campaignDeepdiveSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <ServerErrorsLoader
            chartType="treeChartType"
            error={campaignDeepdiveerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignDeepdiveLoading) {
    campaignDeepdiveSection = (
      <Wrapper>
        <div className="section_card_chart_wrapper flex column">
          <Loader loaderType="treeChartLoader" />
        </div>
      </Wrapper>
    );
  }

  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchCampaignDeepdiveHandler();
  }, [treemapParams, BRAND_ID, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedDates, selectedPlatform]);

  useEffect(() => {
    setTreemapSubBrand("")
    setTreemapBrandName("")
    setTreemapCategory("")
    setTreemapParams({
      brand_id: BRAND_ID,
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      previous_start_date: selectedDates.prevStartDate,
      previous_end_date: selectedDates.prevEndDate,
      objective: selectedObjectives,
      platform_campaign: selectedIdandPlatform,
      platform: selectedPlatform,
      campaign: selectedCampaigns,
      campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      sub_brand: selectedPageDropdownValue === "" ? null : selectedPageDropdownValue,
      level: 1,
    })
  }, [pageDropdownValue, BRAND_ID, selectedBucketsFromFilter, selectedObjectives, selectedIdandPlatform, selectedCampaigns, selectedDates, selectedPlatform])


  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // ENGAGEMENT VS IMPRESSION

  const fetchCampaignPerformanceHandler = async () => {
    setCampaignPerformanceIsLoading(true);
    setCampaignPerformanceIsLoaded(false);
    setCampaignPerformanceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        sub_brand: selectedPageDropdownValue
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/amazon/campaign-performance/",
        config
      );

      setcampaignPerformanceAllData(res?.data?.data[0] ?? []);
      setcampaignPerformanceAllLabel(res?.data?.dimension ?? []);

      setCampaignPerformance(res?.data?.data[0]?.overall ?? []);

      setCampaignPerformanceDataLabels(res?.data?.dimension?.overall ?? []);
      setCampaignPerformanceIsLoading(false);
      setCampaignPerformanceIsLoaded(true);
      setCampaignPerformanceError(null);
    } catch (error) {
      setcampaignPerformanceAllData([]);
      setcampaignPerformanceAllLabel([]);
      setCampaignPerformance([]);
      setCampaignPerformanceDataLabels([]);
      setCampaignPerformanceIsLoading(false);
      setCampaignPerformanceIsLoaded(false);
      setCampaignPerformanceError(error.code);
    }
  };

  let campaignPerformanceChart = (
    <Wrapper>
      <Loader loaderType="bubbleChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignPerformanceLoaded && !isCampaignPerformanceLoading) {
    if (
      NoDataAvailableChecker(campaignPerformance) ||
      NoDataAvailableChecker(campaignPerformanceDataLabels)
    ) {
      campaignPerformanceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="bubbleChartType" />
        </Wrapper>
      );
    } else {
      campaignPerformanceChart = (
        <Wrapper>
          <ErrorBoundary chartType="bubbleChartType">
            <ZoomableBubbleChart
              chartClass="section_card_chart"
              chartData={campaignPerformance}
              chartId="compareEngagement"
              chartLabels={campaignPerformanceDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignPerformanceerror) {
    campaignPerformanceChart = (
      <Wrapper>

        <ServerErrorsLoader
          chartType="bubbleChartType"
          error={campaignPerformanceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignPerformanceLoading) {
    campaignPerformanceChart = (
      <Wrapper>
        <Loader loaderType="bubbleChartLoader" />
      </Wrapper>
    );
  }

  let campaignPerformanceChartLegends

  if (isCampaignPerformanceLoaded && !isCampaignPerformanceLoading) {
    if (NoDataAvailableChecker(NoDataAvailableChecker(campaignPerformance) ||
      NoDataAvailableChecker(campaignPerformanceDataLabels))) {
      campaignPerformanceChartLegends =
        <Wrapper>
          <div className="w_100 p_b_10">
            <ul className="icz-rilist-inline">
              <li>
                Size = Impressions
                <div
                  className="legend-circle"
                  style={{ background: "transparent" }}
                ></div>
              </li>
              <li>
                <div
                  className="legend-circle"
                  style={{ background: "#5a75f9" }}
                ></div>
                Search
              </li>
              <li>
                <div
                  className="legend-circle"
                  style={{ background: "#ff9931" }}
                ></div>
                Product SKU
              </li>
              <li>
                <div
                  className="legend-circle"
                  style={{ background: "#07C180" }}
                ></div>
                Display
              </li>


            </ul>
          </div>
        </Wrapper>
    }
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH CAMPAIGN SUMMARY FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform_campaign: selectedIdandPlatform,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 || selectedBucketsFromFilter === undefined ? null : selectedBucketsFromFilter.join(","),
        sub_brand: selectedPageDropdownValue
      },
      headers: {
        "Content-Type": "application/json",

        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/amazon/summary-table/", config);

      setCampaignSummary(res?.data?.overall ?? []);
      setCampaignSummaryTableHeaders(overAllHeaders)
      setCampaignSummaryAllDropdownsData(res?.data ?? []);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummary([]);
      setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable = (
    <Wrapper>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Campaign Summary
              <SectionDefinationsBadge
                title={"Campaign Summary"}
                module={pageSubModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableTitle={"Campaign Summary : " + (selectedCampaignSummaryDropdownOption && selectedCampaignSummaryDropdownOption.label)}
            tablePagePlatform={pagePlatform}
            tablePageSubModule={pageSubModule}
            tablePagePrimaryTab={pagePrimaryTab}
            tableHeader={campaignSummaryTableHeaders}
            tableData={campaignSummary}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder={"Search Campaign"}
            tableDropdown={
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignSummaryOptions}
                className="form_dropdown section_dropdown"
                value={selectedCampaignSummaryDropdownOption}
                selectedOptions={selectedCampaignSummaryDropdownOption}
                setStatedropdown={campaignSummaryDropdownSelectionHandler}
              />
            }
            tableID={"search_campaign_section"}
            tableExportDropdown={
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"search_campaign_section"}
                sectionName={`AmazonTableDynaLabels_Campaign Summary_${selectedCampaignSummaryDropdownOption.label + selectedPageDropdownLabel}`}
                sectionData={campaignSummary}
              />
            }
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Campaign Summary
                <SectionDefinationsBadge
                  title={"Campaign Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <ServerErrorsLoader chartType="tableChartType" error={campaignSummaryerror} />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <div className="grid grid_cols_4 fullpage_section_card section_card">
          <div className="grid col_span_4">
            <div className="section_card_header">
              <h2 className="section_card_title">Campaign Summary
                <SectionDefinationsBadge
                  title={"Campaign Summary"}
                  module={pageSubModule}
                  platform={pagePlatform}
                  section={pagePrimaryTab}
                />
              </h2>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <Loader loaderType="smallTableLoader" />
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  let sliceVal = platformWiseDonutSliceToggle === null || platformWiseDonutSliceToggle === undefined || platformWiseDonutSliceToggle === "" ? "" : " : " + platformWiseDonutSliceToggle
  var campaignObjectCSV = {
    [`AmazonDonut_Campaign Type Wise Donut_${selectedPageDropdownLabel}`]: donutChartPlatformWise,
    [`AmazonCampaignType_Campaign Type Wise Line_${sliceVal + " " + selectedPageDropdownLabel}`]: lineChartCampaignObjective,
    [`AmazonCampaignTable_Campaign Type Wise Table_${sliceVal + " " + selectedPageDropdownLabel}`]: lineChartCampaignObjectiveDetails
  }

  var allData = {
    ...topCardData,
    [`SummaryCardDynaLabels_Platform Summary_${selectedPageDropdownLabel}`]: performanceMetricCards,
    ...campaignObjectCSV,
    [`DonutWithActiveDropdown_Consumer Sales Funnel_${consumerSalesFunnelDropdownValue.label + selectedPageDropdownLabel}`]: consumerSalesFunnel,
    [`AmazonCampDeepdive_Campaign Deepdive_${campaignDeepdiveDropdownValue.label + selectedPageDropdownLabel}`]: campaignDeepdive,
    [`AmazonCampaignBubble_Campaign Performance_${campaignPerformanceDropdown.label + selectedPageDropdownLabel}`]: campaignPerformance,
    [`AmazonTableDynaLabels_Campaign Summary_${selectedCampaignSummaryDropdownOption.label + selectedPageDropdownLabel}`]: campaignSummary

  }

  useEffect(() => {
    setFullPageExport(allData);
  }, [
    performanceMetricCards,
    donutChartPlatformWise,
    platformWiseDonutSliceToggle,
    consumerSalesFunnel,
    campaignDeepdive,
    campaignPerformance,
    campaignSummary
  ])

  useEffect(() => {
    if (
      (performanceMetricCardserror !== null ? true : isPerformanceMetricCardsLoaded && (isPerformanceMetricCardsLoading === false)) &&
      (donutChartPlatformWiseError !== null ? true : donutChartPlatformWiseLoaded && (donutChartPlatformWiseLoading === false)) &&
      (consumerSalesFunnelerror !== null ? true : isConsumerSalesFunnelLoaded && (isConsumerSalesFunnelLoading === false)) &&
      (campaignDeepdiveerror !== null ? true : isCampaignDeepdiveLoaded && (isCampaignDeepdiveLoading === false)) &&
      (campaignPerformanceerror !== null ? true : isCampaignPerformanceLoaded && (isCampaignPerformanceLoading === false)) &&
      (campaignSummaryerror !== null ? true : isCampaignSummaryLoaded && (isCampaignSummaryLoading === false))
    ) {
      setIsExportDisabled(false)
    } else {
      setIsExportDisabled(true)
    }
  }, [
    performanceMetricCardserror, isPerformanceMetricCardsLoaded, isPerformanceMetricCardsLoading,
    donutChartPlatformWiseError, donutChartPlatformWiseLoaded, donutChartPlatformWiseLoading,
    consumerSalesFunnelerror, isConsumerSalesFunnelLoaded, isConsumerSalesFunnelLoading,
    campaignDeepdiveerror, isCampaignDeepdiveLoaded, isCampaignDeepdiveLoading,
    campaignPerformanceerror, isCampaignPerformanceLoaded, isCampaignPerformanceLoading,
    campaignSummaryerror, isCampaignSummaryLoaded, isCampaignSummaryLoading,
  ])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <div className="grid grid_cols_2">
          <div className="grid col_span_1">
            <div className="section_card_header">
              <div className="section_card_title">Summary</div>
            </div>
          </div>
          <div className="grid col_span_1 flex justify_end">
            <Dropdown
              ismulti={true}
              isClearable={true}
              placeholder={"Select Products"}
              options={pageDropdownOptions}
              className="form_dropdown section_dropdown"
              value={pageDropdownValue}
              selectedOptions={pageDropdownValue}
              setStatedropdown={pageDropdownSelectionHandler}
              dropdownLoading={isPageDropdownOptionsLoading}
            />
          </div>
        </div>
      </div>

      {performanceMetricCardsSection}

      <div id="Platform_Objective_Wise_distribution_Section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Campaign Type Wise Distribution</h2>
              <SectionDefinationsBadge
                title={"Campaign Type Wise Distribution"}
                module={pageModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"Platform_Objective_Wise_distribution_Section"}
                sectionName={"Campaign Type Wise Distribution"}
                sectionData={campaignObjectCSV}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {donutChartCampaignSection}
              </div>
            </div>
          </div>
          <div className="grid col_span_2">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
          <div className="grid col_span_1">
            <div className="section_card_body">
              {lineChartCampaignDetailsData}
            </div>
          </div>
        </div>
      </div>

      <div id="video_views" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">Consumer Sales Funnel: {consumerSalesFunnelDropdownValue && consumerSalesFunnelDropdownValue.label}</h2>
              <SectionDefinationsBadge
                title={"Consumer Sales Funnel"}
                module="campaign"
                platform="facebook"
                section="video"
              />
            </div>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                isClearable={false}
                placeholder={"Select Source"}
                options={consumerSalesFunnelOptions}
                className="form_dropdown section_dropdown"
                value={consumerSalesFunnelDropdownValue}
                selectedOptions={consumerSalesFunnelDropdownValue}
                setStatedropdown={consumerSalesFunnelHandler}
              // dropdownLoading={isConsumerSalesFunnelLoading}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"video_views"}
                sectionName={`DonutWithActiveDropdown_Consumer Sales Funnel_${consumerSalesFunnelDropdownValue.label + selectedPageDropdownLabel}`}
                sectionData={consumerSalesFunnel}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {consumerSalesFunnelChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_1">
            <div className="section_card_body">
              {consumerSalesFunnelDetailsData}
            </div>
          </div>
        </div>
      </div>

      <div id="campaign_deepdive" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header ">
            <div className="flex column align_start">
              <div className="section_info">
                <h2 className="section_card_title">
                  Campaign Deepdive: {campaignDeepdiveDropdownValue && campaignDeepdiveDropdownValue.label}
                </h2>
                <SectionDefinationsBadge
                  title="Campaign Deepdive"
                  module="campaign"
                  platform="Dv360"
                  section="Video"
                />
              </div>
              {isCampaignDeepdiveLoading === false && isCampaignDeepdiveLoaded === true && (
                <div className="treemap_breadcrumb">
                  <span
                    onClick={() => {
                      setTreemapParams({
                        brand_id: BRAND_ID,
                        start_date: selectedDates.startDate,
                        end_date: selectedDates.endDate,
                        previous_start_date: selectedDates.prevStartDate,
                        previous_end_date: selectedDates.prevEndDate,
                        objective: selectedObjectives,
                        platform_campaign: selectedIdandPlatform,
                        platform: selectedPlatform,
                        campaign: selectedCampaigns,
                        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
                        sub_brand: selectedPageDropdownValue,
                        level: 1,
                      });
                      setTreemapSubBrand("");
                      setTreemapCategory("");
                    }}
                  >
                    Home
                  </span>
                  {treemapSubBrand && (
                    <span
                      onClick={() => {
                        setTreemapParams({
                          brand_id: BRAND_ID,
                          start_date: selectedDates.startDate,
                          end_date: selectedDates.endDate,
                          previous_start_date: selectedDates.prevStartDate,
                          previous_end_date: selectedDates.prevEndDate,
                          objective: selectedObjectives,
                          platform_campaign: selectedIdandPlatform,
                          platform: selectedPlatform,
                          campaign: selectedCampaigns,
                          campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
                          sub_brand: treemapSubBrand,
                          level: 2,
                        });
                        setTreemapCategory("");
                      }}
                    >
                      {" / "}
                      <TextFormatter string={treemapBrandName} />
                    </span>
                  )}

                  {treemapCategory && (
                    <span>
                      {" / "}
                      <TextFormatter string={treemapCategory} />{" "}
                    </span>
                  )}
                </div>
              )}
            </div>

            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                isClearable={false}
                placeholder={"Select Source"}
                options={campaignDeepdiveOptions}
                className="form_dropdown section_dropdown"
                value={campaignDeepdiveDropdownValue}
                selectedOptions={campaignDeepdiveDropdownValue}
                setStatedropdown={campaignDeepdiveHandler}
              // dropdownLoading={isCampaignDeepdiveLoading}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"campaign_deepdive"}
                sectionName={`AmazonCampDeepdive_Campaign Deepdive_${campaignDeepdiveDropdownValue.label + selectedPageDropdownLabel}`}
                sectionData={campaignDeepdive}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            {campaignDeepdiveSection}
          </div>
        </div>
      </div>

      <div id="engagement_impressions_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="section_info">
              <h2 className="section_card_title">
                Campaign Performance: {campaignPerformanceDropdown && campaignPerformanceDropdown.label}
              </h2> <SectionDefinationsBadge
                title={"Campaign Performance"}
                module={pageModule}
                platform={pagePlatform}
                section={pagePrimaryTab}
              />
            </div>
            <div data-html2canvas-ignore={true}>

              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignPerformanceOptions}
                className="form_dropdown section_dropdown"
                value={campaignPerformanceDropdown}
                selectedOptions={campaignPerformanceDropdown}
                setStatedropdown={campaignPerformanceSelectionHandler}
              />
              <ExportDropdown
                moduleName={pageModule}
                subModuleName={pageSubModule}
                platform={pagePlatform}
                tab={pagePrimaryTab}
                sectionId={"engagement_impressions_section"}
                sectionName={`AmazonCampaignBubble_Campaign Performance_${campaignPerformanceDropdown.label + selectedPageDropdownLabel}`}
                sectionData={campaignPerformance}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {campaignPerformanceChart}
              </div>
              {campaignPerformanceChartLegends}
            </div>
          </div>
        </div>
      </div>
      {campaignSummaryTable}

    </Wrapper>
  );
}
